@font-face {
  font-family: "AvenirNextLTPro";
  src: url("../assets/fonts/AvenirNext_LT_Pro.woff");
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextLTPro-Bold";
  src: url("../assets/fonts/AvenirNextLTPro-Bold.woff");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("../assets/fonts/helvetica.woff");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/opensans.woff");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("../assets/fonts/opensans-Bold.woff");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("../assets/fonts/opensans-SemiBold.woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/roboto.woff");
  font-display: swap;
}

@font-face {
  font-family: "RobotoBlack";
  src: url("../assets/fonts/robotoblack.woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/fonts/roboto-Bold.woff");
  font-display: swap;
}

@font-face {
  font-family: "NunitoSans-Regular";
  src: url("../assets/fonts/NunitoSans-Regular.woff");
  font-display: swap;
}

@font-face {
  font-family: "NunitoSans-Black";
  src: url("../assets/fonts/NunitoSans-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "NunitoSans-Bold";
  src: url("../assets/fonts/NunitoSans-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Alexandria";
  font-weight: 100;
  src: url("../assets/fonts/Alexandria-Thin.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Alexandria";
  font-weight: 200;
  src: url("../assets/fonts/Alexandria-ExtraLight.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Alexandria";
  font-weight: 300;
  src: url("../assets/fonts/Alexandria-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Alexandria";
  font-weight: 400;
  src: url("../assets/fonts/Alexandria.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Alexandria";
  font-weight: 500;
  src: url("../assets/fonts/Alexandria-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Alexandria";
  font-weight: 600;
  src: url("../assets/fonts/Alexandria-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Alexandria";
  font-weight: 700;
  src: url("../assets/fonts/Alexandria-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Alexandria";
  font-weight: 800;
  src: url("../assets/fonts/Alexandria-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Alexandria";
  font-weight: 900;
  src: url("../assets/fonts/Alexandria-Black.ttf");
  font-display: swap;
}

.fw-400 {
  font-family: "Alexandria";
  font-weight: 400;
  font-display: swap;
}

.fs-14 {
  font-size: .14rem;
}

@media (max-width: 768px) {
  .fs-14 {
    font-size: .4043rem;
  }
}

.hidden {
  display: none;
}

.border-none,
.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child .sort-item,
.slots .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child .sort-item,
.sort-box li:last-child .sort-item {
  border: none !important;
}

.icon-back {
  display: inline-block;
  background: url("../assets/back.png") no-repeat;
  width: 0.66rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-restore {
  display: inline-block;
  width: 0.28rem;
  height: 0.29rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-lighting {
  display: inline-block;
  background: url("../assets/lighting.png") no-repeat;
  width: 0.63rem;
  height: 0.53rem;
  background-size: 100% 100%;
}

.icon-logo {
  display: inline-block;
  max-width: 2.28rem;
  max-height: 0.8rem;
  width: 100%;
  height: auto;
}

.icon-logo-min {
  display: inline-block;
  background: url("../assets/logo.png") no-repeat;
  width: 0.36rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-close {
  display: inline-block;
  width: 0.31rem;
  height: 0.31rem;
  background-size: 100% 100%;
  cursor: pointer;
  fill: #fff;
}

.icon-close2 {
  display: inline-block;
  background: url("../assets/close2.png") no-repeat;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-pngo-banner {
  display: inline-block;
  background: url("../assets/pngo_banner.png") no-repeat;
  width: 1.87rem;
  height: 0.51rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-sg-logo {
  display: inline-block;
  background: url("../assets/sg_logo.png") no-repeat;
  width: 1.95rem;
  height: 0.33rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-chat {
  display: inline-block;
  background: url("../assets/chat.png") no-repeat;
  width: 0.37rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-chat-white {
  display: inline-block;
  background: url("../assets/chat_white.png") no-repeat;
  width: 0.37rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-lan {
  display: inline-block;
  background: url("../assets/language.png") no-repeat;
  width: 0.22rem;
  height: 0.23rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-lan-white {
  display: inline-block;
  background: url("../assets/language_white.png") no-repeat;
  width: 0.22rem;
  height: 0.23rem;
  background-size: 100% 100%;
}

.icon-flag {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.lang-choice>div:last-child .divide {
  display: none;
}

.icon-en {
  display: inline-block;
  background: url("../assets/malysia.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-thai {
  display: inline-block;
  background: url("../assets/thai.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-idr {
  display: inline-block;
  background: url("../assets/idr.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-game-menu {
  display: inline-block;
  width: 0.28rem;
  height: 0.16rem;
  background-size: 100% 100%;
  fill: #dabc6f;
}

.icon-raffle {
  display: inline-block;
  width: 0.45rem;
  height: 0.29rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-evo {
  display: inline-block;
  width: 0.8rem;
  height: 0.39rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-download {
  display: inline-block;
  width: 0.25rem;
  height: 0.36rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-contact {
  display: inline-block;
  width: 0.33rem;
  height: 0.33rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-arrow-down {
  display: inline-block;
  width: 0.09rem;
  height: 0.06rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-arrow-big {
  display: inline-block;
  background-image: url("../assets/arrow_big.svg");
  background-repeat: no-repeat;
  width: 0.53rem;
  height: 0.53rem;
  background-size: 100% 100%;
  pointer-events: auto;
  cursor: pointer;
  background-color: rgba(240,240,240,.9);
  border-radius: 50%;
  padding: 0.18rem;
}

.icon-arrow-big.left {
  transform: rotate(180deg);
}

.icon-love {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-love-on {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-love2 {
  display: inline-block;
  background: url("../assets/love.png") no-repeat;
  width: 0.38rem;
  height: 0.32rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-SG {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-PT,
.icon-PT3,
.icon-PT2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-YBL {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ybl.png") no-repeat;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-lucky {
  display: inline-block;
  background: url("../assets/lucky_bomber.png") no-repeat;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-TTG {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-SA {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-GP {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-BINL2,
.icon-BINS2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-PP,
.icon-PP-slot {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-AG,
.icon-AG2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-UG,
.icon-AES,
.icon-AES2 {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ug.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-EVO {
  display: inline-block;
  background: url("/public/html/images/home_img/096-evo.svg") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-EBET {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ebet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-MGP {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-JKR {
  display: inline-block;
  width: 0.4rem;
  height: 0.09rem;
  background-size: 100% 100%;
}

.icon-NS,
.icon-NS-slot {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-PGS,
.icon-FG,
.icon-MT,
.icon-YGR,
.icon-V8,
.icon-KM,
.icon-KM2 {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-PG {
  display: inline-block;
  width: 0.36rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-UL {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ul.png") no-repeat;
  width: 0.42rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-SCR2 {
  display: inline-block;
  background: url("/public/html/images/home_img/096-scr.png") no-repeat;
  width: 0.4rem;
  height: 0.18rem;
  background-size: 100% 100%;
}

.icon-JL {
  display: inline-block;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-PSY8 {
  display: inline-block;
  width: 0.4rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-MEGA {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-HC {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-HB {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-NT {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-RT {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-CQ9 {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-JDB,
.icon-JDB2 {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-YGG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-AP {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-DS,
.icon-DS-slot,
.icon-EP,
.icon-SW,
.icon-R88,
.icon-R88-slot,
.icon-WESLT,
.icon-WESLT-slot {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-SPX {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-DRG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-RG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-PS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-FC {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-SPB {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-T1G,
.icon-VP,
.icon-MTV {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-EXS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-FS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-hot {
  display: inline-block;
  background: url("../assets/hot.png") no-repeat;
  width: 0.67rem;
  height: 0.68rem;
  background-size: 100% 100%;
}

.icon-new {
  display: inline-block;
  background: url("../assets/new.png") no-repeat;
  width: 0.67rem;
  height: 0.68rem;
  background-size: 100% 100%;
}

.icon-new-green {
  display: inline-block;
  background: url("../assets/new_green.png") no-repeat;
  width: 0.79rem;
  height: 0.79rem;
  background-size: 100% 100%;
}

.icon-transfer-918kiss {
  display: inline-block;
  background: url("../assets/transfer/918kiss.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ae_sexy {
  display: inline-block;
  background: url("../assets/transfer/ae_sexy.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ag_gaming {
  display: inline-block;
  background: url("../assets/transfer/ag_gaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-allbet {
  display: inline-block;
  background: url("../assets/transfer/allbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-allbet2 {
  display: inline-block;
  background: url("../assets/transfer/allbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-betrader {
  display: inline-block;
  background: url("../assets/transfer/betrader.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bk8 {
  display: inline-block;
  background: url("../assets/transfer/bk8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-cmd368 {
  display: inline-block;
  background: url("../assets/transfer/cmd368.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-m8 {
  display: inline-block;
  background: url("../assets/transfer/m8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-kingmaker {
  display: inline-block;
  background: url("../assets/transfer/kingmaker.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-dreamgaming {
  display: inline-block;
  background: url("../assets/transfer/dreamgaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ebet {
  display: inline-block;
  background: url("../assets/transfer/ebet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-evo {
  display: inline-block;
  background: url("../assets/transfer/evo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gameplay {
  display: inline-block;
  background: url("../assets/transfer/gameplay.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gd {
  display: inline-block;
  background: url("../assets/transfer/gd.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jkr {
  display: inline-block;
  background: url("../assets/transfer/096-jkr-icon.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-hongchow {
  display: inline-block;
  background: url("../assets/transfer/hongchow.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-funkygames {
  display: inline-block;
  background: url("../assets/transfer/funkygames.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-minited {
  display: inline-block;
  background: url("../assets/transfer/minited.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pgsoft {
  display: inline-block;
  background: url("../assets/transfer/pgsoft.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-yesgetrich {
  display: inline-block;
  background: url("../assets/transfer/yesgetrich.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jili {
  display: inline-block;
  background: url("../assets/transfer/jili.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gg_gaming {
  display: inline-block;
  background: url("../assets/transfer/gg_gaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-idn_poker {
  display: inline-block;
  background: url("../assets/transfer/idn_poker.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-im {
  display: inline-block;
  background: url("../assets/transfer/im.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bti {
  display: inline-block;
  background: url("../assets/transfer/bti.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-maxbet {
  display: inline-block;
  background: url("../assets/transfer/maxbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-drg {
  display: inline-block;
  background: url("../assets/transfer/drg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-w {
  display: inline-block;
  background: url("../assets/transfer/w.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-we {
  display: inline-block;
  background: url("../assets/transfer/we.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-we.icon-custom-transfer-Maxim88Casino {
  background: url("../assets/transfer/we_rebrand.png") no-repeat;
  background-size: 100% 100%;
}

.icon-transfer-we.icon-custom-transfer-Maxim88 {
  background: url("../assets/transfer/we_rebrand.png") no-repeat;
  background-size: 100% 100%;
}

.icon-transfer-mega888 {
  display: inline-block;
  background: url("../assets/transfer/mega888.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-mg {
  display: inline-block;
  background: url("../assets/transfer/mg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ybl {
  display: inline-block;
  background: url("../assets/transfer/ybl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-PP {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-PPL {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-psy8 {
  display: inline-block;
  background: url("../assets/transfer/psy8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ns {
  display: inline-block;
  background: url("../assets/transfer/nextspin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-nextspin {
  display: inline-block;
  background: url("../assets/transfer/nextspin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pngo {
  display: inline-block;
  background: url("../assets/transfer/pngo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pp {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pt_logo {
  display: inline-block;
  background: url("../assets/transfer/pt_logo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-qq {
  display: inline-block;
  background: url("../assets/transfer/qq.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-winwinlottery {
  display: inline-block;
  background: url("../assets/transfer/wwl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-shicai {
  display: inline-block;
  background: url("../assets/transfer/gw.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tfg {
  display: inline-block;
  background: url("../assets/transfer/tf.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-v8poker {
  display: inline-block;
  background: url("../assets/transfer/v8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sa {
  display: inline-block;
  background: url("../assets/transfer/sa.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sbo {
  display: inline-block;
  background: url("../assets/transfer/sbo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-hb {
  display: inline-block;
  background: url("../assets/transfer/hb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spade {
  display: inline-block;
  background: url("../assets/transfer/spade.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ttg {
  display: inline-block;
  background: url("../assets/transfer/ttg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ultimate {
  display: inline-block;
  background: url("../assets/transfer/ultimate.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-wm {
  display: inline-block;
  background: url("../assets/transfer/wm.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-rt {
  display: inline-block;
  background: url("../assets/transfer/rt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-nt {
  display: inline-block;
  background: url("../assets/transfer/nt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-cq9 {
  display: inline-block;
  background: url("../assets/transfer/cq9.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-fachai {
  display: inline-block;
  background: url("../assets/transfer/fc.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-granddragon {
  display: inline-block;
  background: url("../assets/transfer/gdl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-digmaan {
  display: inline-block;
  background: url("../assets/transfer/dig.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tcg {
  display: inline-block;
  background: url("../assets/transfer/tcg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tcga {
  display: inline-block;
  background: url("../assets/transfer/tcg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ws {
  display: inline-block;
  background: url("../assets/transfer/ws.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-binl2 {
  display: inline-block;
  background: url("../assets/transfer/bbin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bins2 {
  display: inline-block;
  background: url("../assets/transfer/bbin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spb {
  display: inline-block;
  background: url("../assets/transfer/spb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-t1g {
  display: inline-block;
  background: url("../assets/transfer/t1g.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-mtv {
  display: inline-block;
  background: url("../assets/transfer/mtv.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bg {
  display: inline-block;
  background: url("../assets/transfer/bg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jdb {
  display: inline-block;
  background: url("../assets/transfer/jdb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jdb2 {
  display: inline-block;
  background: url("../assets/transfer/jdb2.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ds {
  display: inline-block;
  background: url("../assets/transfer/ds.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ep {
  display: inline-block;
  background: url("../assets/transfer/ep.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-rg {
  display: inline-block;
  background: url("../assets/transfer/rg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ps {
  display: inline-block;
  background: url("../assets/transfer/ps.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ygg {
  display: inline-block;
  background: url("../assets/transfer/ygg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ap {
  display: inline-block;
  background: url("../assets/transfer/ap.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spx {
  display: inline-block;
  background: url("../assets/transfer/spx.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sw {
  display: inline-block;
  background: url("../assets/transfer/sw.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-r88 {
  display: inline-block;
  background: url("../assets/transfer/r88.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-weslt {
  display: inline-block;
  background: url("../assets/transfer/weslt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-exs {
  display: inline-block;
  background: url("../assets/transfer/exs.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-vp {
  display: inline-block;
  background: url("../assets/transfer/vp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-vc {
  display: inline-block;
  background: url("../assets/transfer/vc.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-fs {
  display: inline-block;
  background: url("../assets/transfer/fs.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-box.bg-white {
  width: 0.3rem;
  height: 0.3rem;
  background: #fff;
  border-radius: 50%;
}

.icon-box>i {
  overflow: visible;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-logout {
  display: inline-block;
  background: url("../assets/menu/login.png") no-repeat;
  width: 0.28rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-home {
  display: inline-block;
  width: 0.33rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-918kiss {
  display: inline-block;
  background: url("../assets/menu/918kiss.png") no-repeat;
  width: 0.45rem;
  height: 0.24rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.icon-slots {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-casino {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-favourite {
  display: inline-block;
  width: 0.3rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.icon-fishing {
  display: inline-block;
  width: 0.36rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-poker {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-e-sport {
  display: inline-block;
  width: 0.36rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-promotion {
  display: inline-block;
  width: 0.36rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-sports {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-vip {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-meda {
  display: inline-block;
  background: url("../assets/meda.png") no-repeat;
  width: 0.32rem;
  height: 0.47rem;
  background-size: 100% 100%;
}

.icon-curaccao {
  display: inline-block;
  background: url("../assets/brand/curaccao-gray.svg") no-repeat;
  width: 1rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-curaccao:hover {
  background: url("../assets/brand/curaccao.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-pagcor {
  display: inline-block;
  background: url("../assets/brand/pagcor-gray.png") no-repeat;
  width: 1.25rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-pagcor:hover {
  background: url("../assets/brand/pagcor.png") no-repeat;
  background-size: 100% 100%;
}

.icon-iovation {
  display: inline-block;
  background: url("../assets/brand/iovation-gray.png") no-repeat;
  width: 1.19rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-iovation:hover {
  background: url("../assets/brand/iovation.png") no-repeat;
  background-size: 100% 100%;
}

.icon-tm {
  display: inline-block;
  background: url("../assets/brand/threatmetrix-gray.svg") no-repeat;
  width: 1.19rem;
  height: 0.32rem;
  background-size: 100% 100%;
}

.icon-tm:hover {
  background: url("../assets/brand/threatmetrix.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-bmm {
  display: inline-block;
  background: url("../assets/brand/bmm-gray.png") no-repeat;
  width: 0.55rem;
  height: 0.17rem;
  background-size: 100% 100%;
}

.icon-bmm:hover {
  background: url("../assets/brand/bmm.png") no-repeat;
  background-size: 100% 100%;
}

.icon-itechlab {
  display: inline-block;
  background: url("../assets/brand/itechlab-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.39rem;
  background-size: 100% 100%;
}

.icon-itechlab:hover {
  background: url("../assets/brand/itechlab.png") no-repeat;
  background-size: 100% 100%;
}

.icon-global {
  display: inline-block;
  background: url("../assets/brand/global-gray.png") no-repeat;
  width: 0.48rem;
  height: 0.48rem;
  background-size: 100% 100%;
}

.icon-global:hover {
  background: url("../assets/brand/global.png") no-repeat;
  background-size: 100% 100%;
}

.icon-godaddy {
  display: inline-block;
  background: url("../assets/brand/godaddy-gray.png") no-repeat;
  width: 1.35rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-godaddy:hover {
  background: url("../assets/brand/godaddy.png") no-repeat;
  background-size: 100% 100%;
}

.icon-payouts {
  display: inline-block;
  background: url("../assets/brand/payouts-gray.png") no-repeat;
  width: 0.83rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-payouts:hover {
  background: url("../assets/brand/payouts.png") no-repeat;
  background-size: 100% 100%;
}

.icon-youtube {
  display: inline-block;
  background: url("../assets/brand/youtube-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-youtube:hover {
  background: url("../assets/brand/youtube.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-eeziepay {
  display: inline-block;
  background: url("../assets/brand/eeziepay-gray.png") no-repeat;
  width: 0.77rem;
  height: 0.32rem;
  background-size: 100% 100%;
  margin: 0 0.05rem;
}

.icon-eeziepay:hover {
  background: url("../assets/brand/eeziepay.png") no-repeat;
  background-size: 100% 100%;
}

.icon-paytrust {
  display: inline-block;
  background: url("../assets/brand/paytrust-gray.png") no-repeat;
  width: 0.9rem;
  height: 0.19rem;
  background-size: 100% 100%;
}

.icon-paytrust:hover {
  background: url("../assets/brand/paytrust.png") no-repeat;
  background-size: 100% 100%;
}

.icon-help2pay {
  display: inline-block;
  background: url("../assets/brand/help2pay-gray.png") no-repeat;
  width: 0.4rem;
  height: 0.3rem;
  background-size: 100% 100%;
  margin: 0 0.05rem;
}

.icon-help2pay:hover {
  background: url("../assets/brand/help2pay.png") no-repeat;
  background-size: 100% 100%;
}

.icon-bitcoin {
  display: inline-block;
  background: url("../assets/brand/bitcoin-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-bitcoin:hover {
  background: url("../assets/brand/bitcoin.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-usdt {
  display: inline-block;
  background: url("../assets/brand/usdt-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-usdt:hover {
  background: url("../assets/brand/usdt.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-paynow {
  display: inline-block;
  background: url("../assets/brand/paynow-gray.svg") no-repeat;
  width: 0.86rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-paynow:hover {
  background: url("../assets/brand/paynow.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-paynow-v2 {
  display: inline-block;
  background: url("../assets/brand/paynow-gray-v2.svg") no-repeat;
  width: 0.6rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-paynow-v2:hover {
  background: url("../assets/brand/paynow-v2.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-eth {
  display: inline-block;
  background: url("../assets/brand/eth-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-eth:hover {
  background: url("../assets/brand/eth-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-duitnow {
  display: inline-block;
  background: url("../assets/brand/duitnow-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-duitnow:hover {
  background: url("../assets/brand/duitnow-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-instantpay {
  display: inline-block;
  background: url("../assets/brand/instantpay-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-instantpay:hover {
  background: url("../assets/brand/instantpay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-tng {
  display: inline-block;
  background: url("../assets/brand/tng-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-tng:hover {
  background: url("../assets/brand/tng-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-grab {
  display: inline-block;
  background: url("../assets/brand/grabpay-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-grab:hover {
  background: url("../assets/brand/grabpay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-boost {
  display: inline-block;
  background: url("../assets/brand/boost-logo-of.svg") no-repeat;
  width: 0.85rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-boost:hover {
  background: url("../assets/brand/boost-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-shopee {
  display: inline-block;
  background: url("../assets/brand/shopee-logo-of.svg") no-repeat;
  width: 0.75rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-shopee:hover {
  background: url("../assets/brand/shopee-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-truepay {
  display: inline-block;
  background: url("../assets/brand/truepay-logo-of.svg") no-repeat;
  width: 1rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-truepay:hover {
  background: url("../assets/brand/truepay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-surepay {
  display: inline-block;
  background: url("../assets/brand/surepay-logo-of.svg") no-repeat;
  width: 1rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-surepay:hover {
  background: url("../assets/brand/surepay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-notice {
  display: inline-block;
  width: 2.32rem;
  height: 0.2rem;
  background-size: 100% 100%;
  max-width: 0.55rem;
}

.icon-allbet {
  display: inline-block;
  background: url("../assets/casino/allbet.png") no-repeat;
  width: 1.01rem;
  height: 0.59rem;
  background-size: 100% 100%;
}

.icon-asiagaming {
  display: inline-block;
  background: url("../assets/casino/asiagaming.png") no-repeat;
  width: 1.22rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-dreamgaming {
  display: inline-block;
  background: url("../assets/casino/dreamgaming.png") no-repeat;
  width: 0.96rem;
  height: 0.58rem;
  background-size: 100% 100%;
}

.icon-evolution {
  display: inline-block;
  background: url("../assets/casino/evolution.png") no-repeat;
  width: 1.12rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-gameplay {
  display: inline-block;
  background: url("../assets/casino/gameplay.png") no-repeat;
  width: 1.46rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-gd {
  display: inline-block;
  background: url("../assets/casino/gd.png") no-repeat;
  width: 1.08rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-microgaming {
  display: inline-block;
  background: url("../assets/casino/microgaming.png") no-repeat;
  width: 1.53rem;
  height: 0.38rem;
  background-size: 100% 100%;
}

.icon-playtech {
  display: inline-block;
  background: url("../assets/casino/playtech.png") no-repeat;
  width: 1.43rem;
  height: 0.31rem;
  background-size: 100% 100%;
}

.icon-sa-gaming {
  display: inline-block;
  background: url("../assets/casino/sa_gaming.png") no-repeat;
  width: 1.22rem;
  height: 0.46rem;
  background-size: 100% 100%;
}

.icon-sexy {
  display: inline-block;
  background: url("../assets/casino/sexy.png") no-repeat;
  width: 1.01rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.icon-username,
.icon-myprofile {
  display: inline-block;
  width: 0.2rem;
  height: 0.28rem;
  background-size: 100% 100%;
  z-index: 2;
}

.icon-lock {
  display: inline-block;
  width: 0.22rem;
  height: 0.27rem;
  background-size: 100% 100%;
  z-index: 2;
}

.icon-changepassword {
  display: inline-block;
  width: 0.26rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-changegamepassword {
  display: inline-block;
  width: 0.26rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-bankdetail {
  display: inline-block;
  width: 0.35rem;
  height: 0.32rem;
  background-size: 100% 100%;
}

.icon-referral {
  display: inline-block;
  width: 0.35rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-search {
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  background-size: 100% 100%;
}

.icon-arrow-down2 {
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  background-size: 100% 100%;
  fill: #000;
}

.icon-messaging {
  display: inline-block;
  width: 0.28rem;
  height: 0.21rem;
  background-size: 100% 100%;
}

.icon-reload {
  display: inline-block;
  width: 0.25rem;
  height: 0.27rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-reload.start {
  animation: rotation 1s linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.icon-reload2 {
  display: inline-block;
  background: url("../assets/reload2.png") no-repeat;
  width: 0.25rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-reload2.start {
  animation: rotation 1s linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.icon-fund {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-fund2 {
  display: inline-block;
  background: url("../assets/fund_icon2.png") no-repeat;
  background-size: 100% 100%;
}

.icon-deposit {
  display: inline-block;
  width: 0.3rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.icon-report {
  display: inline-block;
  width: 0.26rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.icon-transfer {
  display: inline-block;
  width: 0.35rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-withdraw {
  display: inline-block;
  width: 0.29rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-start {
  display: inline-block;
  background: url("../assets/start.png") no-repeat;
  width: 0.3rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-start2 {
  display: inline-block;
  background: url("../assets/start2.png") no-repeat;
  width: 0.17rem;
  height: 0.15rem;
  background-size: 100% 100%;
}

.icon-on {
  display: inline-block;
  background: url("../assets/on.png") no-repeat;
  width: 0.6rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-off {
  display: inline-block;
  background: url("../assets/off.png") no-repeat;
  width: 0.6rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-sapphire {
  display: inline-block;
  width: 0.63rem;
  height: 0.43rem;
  object-fit: contain;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-ruby {
  display: inline-block;
  background: url("../assets/ruby.png") no-repeat;
  width: 0.71rem;
  height: 0.56rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-diamond {
  display: inline-block;
  background: url("../assets/diamond.png") no-repeat;
  width: 0.71rem;
  height: 0.56rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-transfer-btn {
  display: inline-block;
  background: url("../assets/transfer_btn.png") no-repeat;
  width: 0.2rem;
  height: 0.17rem;
  background-size: 100% 100%;
}

.icon-transfer-to {
  display: inline-block;
  background: url("../assets/transfer_to.png") no-repeat;
  width: 0.17rem;
  height: 0.11rem;
  background-size: 100% 100%;
}

.icon-checked {
  display: inline-block;
  background: url("../assets/checked.png") no-repeat;
  width: 0.24rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-tip {
  display: inline-block;
  background: url("../assets/tip.png") no-repeat;
  width: 0.18rem;
  height: 0.18rem;
  background-size: 100% 100%;
}

.icon-notice-important {
  display: inline-block;
  background: url("../assets/important_notice.svg") no-repeat;
  width: 0.29rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-affin {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-affin-gray.png") no-repeat;
  width: 0.5rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-affin:hover,
.icon-affin.on {
  background: url("/public/html/images/bank_img/096-affin.png") no-repeat;
  background-size: 100% 100%;
}

.icon-alliance {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-alliance-gray.png") no-repeat;
  width: 0.43rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-alliance:hover,
.icon-alliance.on {
  background: url("/public/html/images/bank_img/096-alliance.png") no-repeat;
  background-size: 100% 100%;
}

.icon-bsn {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-bsn-gray.png") no-repeat;
  width: 0.36rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-bsn:hover,
.icon-bsn.on {
  background: url("/public/html/images/bank_img/096-bsn.png") no-repeat;
  background-size: 100% 100%;
}

.icon-cimb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-cimb-gray.png") no-repeat;
  width: 0.34rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-cimb:hover,
.icon-cimb.on {
  background: url("/public/html/images/bank_img/096-cimb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-citi {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-citi-gray.png") no-repeat;
  width: 0.36rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-citi:hover,
.icon-citi.on {
  background: url("/public/html/images/bank_img/096-citi.png") no-repeat;
  background-size: 100% 100%;
}

.icon-hlb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-hlb-gray.png") no-repeat;
  width: 0.28rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-hlb:hover,
.icon-hlb.on {
  background: url("/public/html/images/bank_img/096-hlb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-islam {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-islam-gray.png") no-repeat;
  width: 0.45rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-islam:hover,
.icon-islam.on {
  background: url("/public/html/images/bank_img/096-islam.png") no-repeat;
  background-size: 100% 100%;
}

.icon-mbb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-mbb-gray.png") no-repeat;
  width: 0.33rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-mbb:hover,
.icon-mbb.on {
  background: url("/public/html/images/bank_img/096-mbb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-pbe {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-pbe-gray.png") no-repeat;
  width: 0.26rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.icon-pbe:hover,
.icon-pbe.on {
  background: url("/public/html/images/bank_img/096-pbe.png") no-repeat;
  background-size: 100% 100%;
}

.icon-rhb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-rhb-gray.png") no-repeat;
  width: 0.57rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-rhb:hover,
.icon-rhb.on {
  background: url("/public/html/images/bank_img/096-rhb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-amb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-amb-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-amb:hover,
.icon-amb.on {
  background: url("/public/html/images/bank_img/096-amb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-uob {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-uob-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-uob:hover,
.icon-uob.on {
  background: url("/public/html/images/bank_img/096-uob.png") no-repeat;
  background-size: 100% 100%;
}

.icon-rakyat {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-br-gray.png") no-repeat;
  width: 0.39rem;
  height: 0.42rem;
  background-size: 100% 100%;
}

.icon-rakyat:hover,
.icon-rakyat.on {
  background: url("/public/html/images/bank_img/096-br.png") no-repeat;
  background-size: 100% 100%;
}

.icon-ocbc {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-ocbc-gray.png") no-repeat;
  width: 0.37rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-ocbc:hover,
.icon-ocbc.on {
  background: url("/public/html/images/bank_img/096-ocbc.png") no-repeat;
  background-size: 100% 100%;
}

.icon-hsbc {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-hsbc-gray.png") no-repeat;
  width: 0.47rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-hsbc:hover,
.icon-hsbc.on {
  background: url("/public/html/images/bank_img/096-hsbc.png") no-repeat;
  background-size: 100% 100%;
}

.icon-scb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-scb-gray.png") no-repeat;
  width: 0.47rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-scb:hover,
.icon-scb.on {
  background: url("/public/html/images/bank_img/096-scb.png") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-home {
  background: url("/public/html/images/home_img/home.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-casino {
  background: url("/public/html/images/home_img/casino.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-918kiss {
  background: url("/public/html/images/home_img/918kiss.svg") no-repeat;
  background-size: 100% 100%;
  -webkit-backface-visibility: unset;
          backface-visibility: unset;
}

.footer-bottom .icon-slots {
  background: url("/public/html/images/home_img/slots.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-sports {
  background: url("/public/html/images/home_img/sports.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-e-sport {
  background: url("/public/html/images/home_img/e-sport.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-fishing {
  background: url("/public/html/images/home_img/fishing.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-poker {
  background: url("/public/html/images/home_img/poker.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-favourite {
  background: url("/public/html/images/home_img/favourite.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-promotion {
  background: url("/public/html/images/home_img/promo.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-vip {
  background: url("/public/html/images/home_img/vip.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-raffle {
  background: url("/public/html/images/home_img/raffle.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-download {
  background: url("/public/html/images/home_img/download.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-contact {
  background: url("/public/html/images/home_img/contact.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-lan-white {
  background: url("/public/html/images/home_img/language.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-logout {
  background: url("/public/html/images/home_img/logout.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-home {
  background: url("/public/html/images/home_img/home-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-casino {
  background: url("/public/html/images/home_img/casino-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-918kiss {
  background: url("/public/html/images/home_img/918kiss-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-slots {
  background: url("/public/html/images/home_img/slots-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-sports {
  background: url("/public/html/images/home_img/sports-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-e-sport {
  background: url("/public/html/images/home_img/e-sport-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-fishing {
  background: url("/public/html/images/home_img/fishing-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-poker {
  background: url("/public/html/images/home_img/poker-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-favourite {
  background: url("/public/html/images/home_img/favourite-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-promotion {
  background: url("/public/html/images/home_img/promo-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-vip {
  background: url("/public/html/images/home_img/vip-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-raffle {
  background: url("/public/html/images/home_img/raffle-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-download {
  background: url("/public/html/images/home_img/download-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-contact {
  background: url("/public/html/images/home_img/contact-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-lan-white {
  background: url("/public/html/images/home_img/language-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-logout {
  background: url("/public/html/images/home_img/logout-on.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-wap-menu {
  cursor: pointer;
  display: inline-block;
  width: 0.69rem;
  height: 0.46rem;
  background-size: 100% 100%;
  fill: #dabc6f;
}

.icon-wap-back {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/back.png") no-repeat;
  width: 0.6rem;
  height: 0.42rem;
  background-size: 100% 100%;
}

.icon-wap-eye {
  cursor: pointer;
  display: inline-block;
  width: 0.85rem;
  height: 0.58rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-wap-eye.off {
  background-size: 100% 100%;
  fill: #02532b;
}

.icon-wap-auto {
  border: none;
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/on.png") no-repeat;
  width: 1.54rem;
  height: 0.52rem;
  background-size: 100% 100%;
}

.icon-wap-auto.off {
  background: url("../assets/wap/off.png") no-repeat;
  background-size: 100% 100%;
}

.icon-wap-chat {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/chat.png") no-repeat;
  width: 0.72rem;
  height: 0.72rem;
  background-size: 100% 100%;
}

.icon-wap-message {
  cursor: pointer;
  display: inline-block;
  background-size: 100% 100%;
}

.icon-wap-message svg {
  width: 0.75rem;
  height: 0.58rem;
  fill: #fff;
}

.icon-wap-messaging {
  cursor: pointer;
  display: inline-block;
  width: 0.75rem;
  height: 0.58rem;
  background-size: 100% 100%;
}

.icon-wap-messaging.on {
  background-size: 100% 100%;
}

.icon-wap-profile {
  cursor: pointer;
  display: inline-block;
  width: 0.65rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-profile.on {
  background-size: 100% 100%;
}

.icon-wap-changepassword {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-changepassword.on {
  background-size: 100% 100%;
}

.icon-wap-changegamepassword {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-changegamepassword.on {
  background-size: 100% 100%;
}

.icon-wap-referral {
  cursor: pointer;
  display: inline-block;
  width: 1.1rem;
  height: 0.7rem;
  background-size: 100% 100%;
}

.icon-wap-referral.on {
  background-size: 100% 100%;
}

.icon-wap-bankdetail {
  cursor: pointer;
  display: inline-block;
  width: 0.95rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-bankdetail.on {
  background-size: 100% 100%;
}

.icon-wap-deposit {
  cursor: pointer;
  display: inline-block;
  width: 0.97rem;
  height: 1.08rem;
  background-size: 100% 100%;
}

.icon-wap-transfer {
  cursor: pointer;
  display: inline-block;
  width: 1.14rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-withdraw {
  cursor: pointer;
  display: inline-block;
  width: 0.95rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-report {
  cursor: pointer;
  display: inline-block;
  width: 0.84rem;
  height: 0.82rem;
  background-size: 100% 100%;
}

.icon-wap-home {
  cursor: pointer;
  display: inline-block;
  width: 1.01rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-funds {
  cursor: pointer;
  display: inline-block;
  width: 0.84rem;
  height: 0.76rem;
  background-size: 100% 100%;
}

.icon-wap-promos {
  cursor: pointer;
  display: inline-block;
  width: 1.21rem;
  height: 0.83rem;
  background-size: 100% 100%;
}

.icon-wap-live_chat {
  cursor: pointer;
  display: inline-block;
  width: 0.77rem;
  height: 0.77rem;
  background-size: 100% 100%;
}

.icon-wap-my_account {
  cursor: pointer;
  display: inline-block;
  width: 0.55rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-transfer_btn {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/transfer_btn.png") no-repeat;
  width: 0.53rem;
  height: 0.57rem;
  background-size: 100% 100%;
  border: none;
}

.icon-wap-transfer_btn:disabled,
.icon-wap-transfer_btn[disabled] {
  opacity: .3;
  cursor: not-allowed;
}

.icon-wap-reload {
  cursor: pointer;
  display: inline-block;
  width: 0.59rem;
  height: 0.65rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-wap-arrow {
  cursor: pointer;
  display: inline-block;
  width: 0.37rem;
  height: 0.23rem;
  background-size: 100% 100%;
}

.font-gray {
  color: #19723c;
}

.bg-white {
  background: #fff;
}

.bg-header-t {
  background: green;
}

.bg-header {
  background: green;
}

.border-white {
  border-color: #fff;
}

.border-primary {
  border-color: solid 0.01rem green;
}

.btn-normal,
.btn-long,
.btn-normal-green {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 0.35rem;
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #222;
  font-size: 0.16rem;
  border-radius: 0.02rem;
  cursor: pointer;
  font-family: "Roboto-Bold";
}

html:lang(vi) .btn-normal,
html:lang(vi) .btn-long,
html:lang(vi) .btn-normal-green {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: uppercase;
}

.btn-normal:disabled,
.btn-long:disabled,
.btn-normal-green:disabled {
  filter: grayscale(0.6);
  cursor: not-allowed;
}

.login-button,
.reset-password-button {
  border: none;
}

.btn .btn-ok {
  text-transform: uppercase;
}

.btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
  filter: grayscale(0.7);
  color: rgba(0,0,0,.431372549);
}

.btn-normal-green {
  background: #15743c;
  color: #fff;
  white-space: nowrap;
}

.btn-skew {
  display: inline-block;
  width: 2.28rem;
  height: 0.48rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  font-size: 0.2rem;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #c59b4e 0%, #d2af62 57%, #dec276 100%);
}

.btn-skew>* {
  transform: skew(25deg);
}

.btn-long {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.5rem;
  white-space: nowrap;
}

.text-red {
  color: #e1415d;
  font-size: 0.11rem;
}

.text-yellow,
.slide-menu li,
.header-top .user-info {
  color: #dec276;
  font-size: 0.12rem;
}

.btn-swiper-point,
.content-section .content-recommended .slick-dots li,
.slider>ul.tab li,
.bg-content-section .content-promotions .slick-dots li {
  display: inline-block;
  width: 0.32rem;
  height: 0.08rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  background: #e2dcde;
  margin-left: 0.07rem;
  cursor: pointer;
}

.btn-swiper-point.on,
.content-section .content-recommended .slick-dots li.on,
.slider>ul.tab li.on,
.bg-content-section .content-promotions .slick-dots li.on {
  background: #39b549;
  cursor: default;
}

.bg-gray {
  background: #f5f5f5;
}

.bg-black {
  background: #222;
}

.bg-header-top {
  background: #13242c;
}

.bg-header-top .content-container {
  width: 100%;
  max-width: 20rem;
  padding: 0 0.25rem;
}

.bg-header-bottom {
  background: linear-gradient(to right, #fff 0%, #fff 17%, #19723c 17%, #19723c 100%);
  z-index: 3;
  position: relative;
  height: 0.74rem;
}

.bg-header-bottom .content-container {
  width: 100%;
  max-width: 20rem;
  padding: 0 0.25rem;
}

.bg-footer-bottom {
  background: #ebebeb;
}

.swiper-arrow {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.26rem;
}

.text-banner-green {
  font-size: 0.6rem;
  color: #39b549;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .text-banner-green {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.text-banner-white {
  font-size: 0.42rem;
  color: #fff;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .text-banner-white {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.text-banner-white-small {
  font-family: OpenSans;
  font-style: italic;
  font-size: 0.24rem;
  color: #fff;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.page-enter {
  position: absolute;
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms,transform 300ms;
}

.page-exit {
  position: absolute;
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms,transform 300ms;
}

.page-slide-enter {
  position: absolute;
  right: -100%;
}

.page-slide-enter-active {
  right: 0;
  transition: right 300ms;
}

.page-slide-exit {
  position: absolute;
  right: 0;
}

.page-slide-exit-active {
  right: 100%;
  transition: right 300ms;
}

.slide-right-enter {
  right: -100%;
}

.slide-right-enter-active {
  right: 0;
  transition: right 300ms;
}

.slide-right-exit {
  right: 0;
}

.slide-right-exit-active {
  right: -100%;
  transition: right 300ms;
}

.spread-enter {
  transform-origin: top;
  transform: translateZ(0) scaleY(0);
  opacity: 0;
}

.spread-enter-active {
  opacity: 1;
  transform: translateZ(0) scaleY(1);
  transition: all 300ms;
}

.spread-exit {
  opacity: 1;
  transform: translateZ(0) scaleY(1);
  transform-origin: top;
}

.spread-exit-active {
  opacity: 0;
  transform: translateZ(0) scaleY(0);
  transition: all 300ms;
}

.rotate180-enter {
  transform: rotate(0deg);
}

.rotate180-enter-active {
  transform: rotate(180deg);
  transition: all 300ms;
}

.rotate180-enter-done {
  transform: rotate(180deg);
}

.rotate180-exit {
  transform: rotate(180deg);
}

.rotate180-exit-active {
  transform: rotate(360deg);
  transition: all 300ms;
}

.drop-right-enter {
  transform: translateY(-100%);
}

.drop-right-enter-active {
  transform: translateY(0);
  transition: all .3s;
}

.drop-right-exit {
  transform: translateY(0);
}

.drop-right-exit-active {
  transform: translateY(-100%);
  transition: all .3s;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms,transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms,transform 300ms;
}

.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
}

.icon-message {
  display: inline-block;
  width: 0.28rem;
  height: 0.21rem;
  background-size: 100% 100%;
  fill: #fff;
}

.provider-logo {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.icon-copy {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.new-icon,
.hot-icon,
.promo-icon {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 0.79rem;
  border: none !important;
  outline: none !important;
  outline-offset: unset !important;
  z-index: 2;
}

.tnc-apply {
  color: #0a974b;
  font-size: 0.16rem;
  font-family: "AvenirNextLTPro-Bold";
  border-bottom: 0.01rem solid;
}

html:lang(vi) .tnc-apply {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

@media (min-width: 1700px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 16%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2100px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 17%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2200px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 18%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2300px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 19%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2400px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 20.5%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2500px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 22%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2650px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 23%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2750px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 25%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3000px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 26%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3200px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 27%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3300px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 29%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3600px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 31%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 4000px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 33%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 1441px) {
  .menu-animation-enter {
    transform-origin: top;
    transform: translateZ(0) translateX(-50%) scaleY(0);
    opacity: 0;
  }

  .menu-animation-enter-active {
    opacity: 1;
    transform: translateZ(0) translateX(-50%) scaleY(1);
    transition: all 300ms;
  }

  .menu-animation-exit {
    opacity: 1;
    transform: translateZ(0) translateX(-50%) scaleY(1);
    transform-origin: top;
  }

  .menu-animation-exit-active {
    opacity: 0;
    transform: translateZ(0) translateX(-50%) scaleY(0);
    transition: all 300ms;
  }
}

@media (min-width: 1025px)and (max-width: 1440px) {
  .menu-animation-enter {
    transform-origin: top;
    transform: translateZ(0) scaleY(0);
    opacity: 0;
  }

  .menu-animation-enter-active {
    opacity: 1;
    transform: translateZ(0) scaleY(1);
    transition: all 300ms;
  }

  .menu-animation-exit {
    opacity: 1;
    transform: translateZ(0) scaleY(1);
    transform-origin: top;
  }

  .menu-animation-exit-active {
    opacity: 0;
    transform: translateZ(0) scaleY(0);
    transition: all 300ms;
  }
}

@media (max-width: 1025px) {
  .bg-header-top .content-container {
    max-width: unset;
  }

  .bg-header-bottom {
    height: 100%;
  }

  .bg-header-bottom .content-container {
    max-width: unset;
  }

  .text-red {
    font-size: 0.2rem;
  }

  .new-icon,
  .hot-icon,
  .promo-icon {
    max-width: 1.2rem;
  }

  .tnc-apply {
    font-size: 0.3rem;
  }

  .provider-logo {
    left: 1.8rem;
    bottom: 0.8rem;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .menu-animation-enter {
    right: -4.22rem;
  }

  .menu-animation-enter-active {
    right: 0;
    transition: right 300ms;
  }

  .menu-animation-exit {
    right: 0;
  }

  .menu-animation-exit-active {
    right: -4.22rem;
    transition: right 300ms;
  }

  .icon-lan,
  .icon-lan-white {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-en,
  .icon-thai,
  .icon-idr {
    width: 0.48rem;
    height: 0.26rem;
  }

  .icon-flag {
    width: 0.48rem;
    height: 0.48rem;
  }

  .icon-logo {
    max-width: 4.66rem;
    max-height: 1.5rem;
  }

  .icon-reload {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-fund {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-game-menu {
    width: 0.48rem;
    height: 0.3rem;
  }

  .icon-raffle {
    width: 0.72rem;
    height: 0.5rem;
  }

  .icon-evo {
    width: 0.72rem;
    height: 0.8rem;
  }

  .icon-download {
    width: 0.4rem;
    height: 0.58rem;
  }

  .icon-contact {
    width: 0.53rem;
    height: 0.53rem;
  }

  .icon-notice {
    width: 1.21rem;
    height: 0.44rem;
    max-width: unset;
  }

  .icon-pagcor {
    width: 1.87rem;
    height: 0.42rem;
  }

  .icon-iovation {
    width: 1.785rem;
    height: 0.36rem;
  }

  .icon-bmm {
    width: 0.825rem;
    height: 0.255rem;
  }

  .icon-itechlab {
    width: 0.615rem;
    height: 0.585rem;
  }

  .icon-global {
    width: 0.72rem;
    height: 0.72rem;
  }

  .icon-godaddy {
    width: 2.025rem;
    height: 0.39rem;
  }

  .icon-payouts {
    width: 1.245rem;
    height: 0.435rem;
  }

  .icon-youtube {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-eeziepay {
    width: 1.155rem;
    height: 0.48rem;
  }

  .icon-paytrust {
    width: 1.35rem;
    height: 0.285rem;
  }

  .icon-help2pay {
    width: 0.555rem;
    height: 0.39rem;
  }

  .icon-hot {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-new {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-arrow-big {
    width: 0.6rem;
    height: 0.6rem;
  }

  .icon-meda {
    width: 0.44rem;
    height: 0.66rem;
  }

  .icon-message {
    width: 0.42rem;
    height: 0.32rem;
  }

  .icon-search {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-on,
  .icon-off {
    width: 0.9rem;
    height: 0.3rem;
  }

  .icon-sapphire,
  .icon-ruby,
  .icon-diamond {
    width: 1.06rem;
    height: 0.84rem;
  }

  .icon-notice-important {
    width: 0.44rem;
    height: 0.39rem;
  }

  .icon-tip {
    width: 0.27rem;
    height: 0.27rem;
  }

  .icon-transfer-btn {
    width: 0.3rem;
    height: 0.26rem;
  }

  .icon-checked {
    width: 0.36rem;
    height: 0.36rem;
  }

  .icon-transfer-to {
    width: 0.27rem;
    height: 0.17rem;
  }

  .icon-love2 {
    width: 0.45rem;
    height: 0.38rem;
  }

  .icon-username {
    width: 0.3rem;
    height: 0.46rem;
  }

  .icon-lock {
    width: 0.44rem;
    height: 0.54rem;
  }

  .btn-swiper-point,
  .content-section .content-recommended .slick-dots li,
  .slider>ul.tab li,
  .bg-content-section .content-promotions .slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .text-banner-green {
    font-size: 1.22rem;
  }

  .text-banner-white {
    font-size: 0.56rem;
    line-height: 0.8rem;
  }

  .text-banner-white-small {
    font-size: 0.3rem;
  }

  .btn-skew {
    width: 2.18rem;
    height: 0.58rem;
    font-size: 0.3rem;
  }

  .btn-normal,
  .btn-normal-green,
  .btn-long {
    width: 2.26rem;
    height: 0.54rem;
    font-size: 0.23rem;
  }

  .btn-long {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 0.3rem;
  }
}

@media (max-width: 768px) {
  .bg-header-bottom .content-container {
    padding: unset;
  }

  .icon-copy {
    width: 0.63rem;
    height: 0.7rem;
  }

  .icon-arrow-down {
    fill: #10572c;
  }

  .icon-home {
    height: 0.3rem;
  }

  .icon-casino,
  .icon-poker,
  .icon-vip,
  .icon-slots,
  .icon-sports,
  .icon-favourite {
    width: 0.32rem;
    height: 0.32rem;
  }

  .icon-e-sport,
  .icon-promotion {
    width: 0.39rem;
    height: 0.27rem;
  }

  .icon-fishing {
    width: 0.39rem;
    height: 0.42rem;
  }

  .icon-918kiss {
    width: 0.42rem;
    height: 0.7rem;
  }

  .daily-limit {
    margin-top: 0.2rem;
    color: #41b850;
    font-size: 0.35rem;
  }

  .daily-limit>span>span:first-child {
    margin-left: 0.1rem;
  }

  .provider-logo {
    left: 0.3rem;
    bottom: 0.5rem;
  }

  .swiper-arrow {
    display: none;
  }

  .menu-animation-enter {
    right: -4.14rem;
  }

  .menu-animation-enter-active {
    right: 0;
    transition: right 300ms;
  }

  .menu-animation-exit {
    right: 0;
  }

  .menu-animation-exit-active {
    right: -4.14rem;
    transition: right 300ms;
  }

  .icon-lan,
  .icon-lan-white {
    width: 0.33rem;
    height: 0.35rem;
  }

  .icon-reload {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-fund {
    width: 0.35rem;
    height: 0.35rem;
    fill: #10572c;
  }

  .icon-restore {
    fill: #10572c;
  }

  .icon-en,
  .icon-thai,
  .icon-idr {
    width: 0.41rem;
    height: 0.26rem;
  }

  .icon-flag {
    width: 0.41rem;
    height: 0.41rem;
  }

  .icon-logo {
    max-width: 3.5rem;
    max-height: 1rem;
    margin-right: 0;
    margin-left: 0.3rem;
  }

  .completed-main .icon-logo {
    max-width: 5rem;
    max-height: 3.85rem;
    margin-right: 0;
  }

  .icon-game-menu {
    width: 0.67rem;
    height: 0.43rem;
  }

  .icon-raffle {
    width: 0.72rem;
    height: 0.5rem;
  }

  .icon-download {
    width: 0.4rem;
    height: 0.58rem;
  }

  .icon-contact {
    width: 0.53rem;
    height: 0.53rem;
  }

  .icon-notice {
    width: 1.5rem;
    height: 0.49rem;
    max-width: unset;
  }

  .icon-curaccao {
    width: 2rem;
    height: 0.9rem;
  }

  .icon-pagcor {
    width: 1.87rem;
    height: 0.42rem;
  }

  .icon-iovation {
    width: 1.785rem;
    height: 0.36rem;
  }

  .icon-tm {
    width: 2.43rem;
    height: 0.8rem;
  }

  .icon-bmm {
    width: 0.825rem;
    height: 0.255rem;
  }

  .icon-itechlab {
    width: 0.615rem;
    height: 0.585rem;
  }

  .icon-global {
    width: 0.72rem;
    height: 0.72rem;
  }

  .icon-godaddy {
    width: 2.025rem;
    height: 0.39rem;
  }

  .icon-payouts {
    width: 1.245rem;
    height: 0.435rem;
  }

  .icon-youtube {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-eeziepay {
    width: 1.4rem;
    height: 0.6rem;
    margin: 0 0.1rem;
  }

  .icon-paytrust {
    width: 1.35rem;
    height: 0.285rem;
  }

  .icon-help2pay {
    width: 0.9rem;
    height: 0.6rem;
    margin: 0 0.1rem;
  }

  .icon-bitcoin {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-usdt {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-paynow {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-paynow-v2 {
    width: 1rem;
    height: 0.9rem;
  }

  .icon-eth {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-duitnow {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-instantpay {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-tng {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-grab {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-boost {
    width: 1.65rem;
    height: 0.9rem;
  }

  .icon-shopee {
    width: 1.5rem;
    height: 0.9rem;
  }

  .icon-truepay {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-surepay {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-hot {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-new {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-arrow-big {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-meda {
    width: 0.48rem;
    height: 0.71rem;
  }

  .icon-message {
    width: 0.42rem;
    height: 0.32rem;
  }

  .icon-search {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-on,
  .icon-off {
    width: 0.9rem;
    height: 0.3rem;
  }

  .icon-sapphire,
  .icon-ruby,
  .icon-diamond {
    width: 1.82rem;
    height: 1.19rem;
  }

  .icon-notice-important {
    width: 0.44rem;
    height: 0.39rem;
  }

  .icon-tip {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-transfer-btn {
    width: 0.3rem;
    height: 0.26rem;
  }

  .icon-checked {
    width: 0.36rem;
    height: 0.36rem;
  }

  .icon-transfer-to {
    width: 0.27rem;
    height: 0.17rem;
  }

  .icon-love2 {
    width: 0.37rem;
    height: 0.31rem;
  }

  .icon-username {
    width: 0.4rem;
    height: 0.56rem;
  }

  .icon-lock {
    width: 0.54rem;
    height: 0.64rem;
  }

  .btn-swiper-point,
  .content-section .content-recommended .slick-dots li,
  .slider>ul.tab li,
  .bg-content-section .content-promotions .slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .text-banner-green {
    font-size: 0.7rem;
  }

  .text-banner-white {
    font-size: 0.4rem;
    line-height: 0.6rem;
  }

  .text-banner-white-small {
    font-size: 0.24rem;
  }

  .btn-skew {
    width: 2.2rem;
    height: 0.46rem;
    font-size: 0.24rem;
  }

  .btn-normal,
  .btn-normal-green,
  .btn-long {
    width: 3.05rem;
    height: 0.54rem;
    font-size: 0.38rem;
    border: none;
  }

  .btn-long {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 0.76rem;
  }

  .new-icon,
  .hot-icon,
  .promo-icon {
    max-width: 1.5rem;
  }
}

#root .wap {
  padding-top: 0;
  overflow: hidden;
}

#root .wap .withdraw-item-box {
  margin-bottom: .5rem;
}

#root .wap .react-dropdown-select-dropdown-handle {
  padding: 0rem .55rem !important;
  margin: 0;
}

#root .wap .react-dropdown-select {
  padding: 0;
}

#root .wap .css-148o527-ItemComponent {
  font-size: .42rem !important;
  padding: .28rem .52rem !important;
}

#root .wap .react-dropdown-select-dropdown {
  border-radius: .04rem !important;
  font-size: .42rem !important;
  width: 100%;
}

#root .wap .react-dropdown-select-dropdown .select-noData {
  min-height: 1rem;
  font-size: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}

#root .wap .css-12zlm52-ReactDropdownSelect:focus,
#root .wap .css-12zlm52-ReactDropdownSelect:focus-within {
  box-shadow: 0 0 0 .05rem #41b850 !important;
}

#root .wap .react-dropdown-select-content {
  color: #41b850;
  font-size: .42rem;
  width: 100%;
}

#root .wap .disabled-input {
  background: #e4e4e4;
}

#root .wap .react-datepicker {
  border-radius: .04rem !important;
  font-size: .13rem !important;
  border: .01rem solid #dadada !important;
}

#root .wap .react-datepicker-popper[data-placement^=bottom] {
  margin-top: .1rem;
  margin-left: 0.77rem;
}

#root .wap .react-datepicker__header {
  border-top-left-radius: .04rem !important;
  border-top-right-radius: .04rem !important;
  border-bottom: .01rem solid #dadada !important;
}

#root .wap .react-datepicker__navigation {
  line-height: .08rem !important;
  border: .1rem solid rgba(0,0,0,0) !important;
}

#root .wap .react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
}

#root .wap .react-datepicker__navigation--next {
  border-left-color: #ccc !important;
}

#root .wap .react-datepicker__month-container {
  float: left;
  max-width: 8rem !important;
  min-width: 7rem !important;
}

#root .wap .react-datepicker__time-container {
  width: 2rem;
}

#root .wap .react-datepicker__current-month,
#root .wap .react-datepicker-time__header,
#root .wap .react-datepicker-year-header {
  font-size: 0.36rem !important;
}

#root .wap .react-datepicker__day-name,
#root .wap .react-datepicker__day,
#root .wap .react-datepicker__time-name {
  width: 0.5rem !important;
  line-height: 0.5rem !important;
  margin: 0.15rem !important;
  font-size: 0.36rem;
}

#root .wap .react-datepicker__time-box {
  width: 1.85rem;
}

#root .wap .react-datepicker__time-list-item {
  font-size: 0.36rem;
  height: 0.5rem;
  padding: 0.1rem;
}

#root .wap .react-datepicker__month {
  margin: .13rem !important;
}

#root .wap .react-datepicker__day--selected,
#root .wap .react-datepicker__day--in-selecting-range,
#root .wap .react-datepicker__day--in-range,
#root .wap .react-datepicker__month-text--selected,
#root .wap .react-datepicker__month-text--in-selecting-range,
#root .wap .react-datepicker__month-text--in-range,
#root .wap .react-datepicker__quarter-text--selected,
#root .wap .react-datepicker__quarter-text--in-selecting-range,
#root .wap .react-datepicker__quarter-text--in-range {
  border-radius: .04rem !important;
  background-color: #005329 !important;
  color: #fff;
}

#root .wap .react-datepicker__day:hover,
#root .wap .react-datepicker__month-text:hover,
#root .wap .react-datepicker__quarter-text:hover {
  border-radius: .04rem !important;
}

#root .wap .react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #005329 !important;
  border-radius: .04rem !important;
}

#root .wap .wap-toolbar {
  width: 100%;
  background: #18723c;
  position: fixed;
  top: 0;
  z-index: 2;
}

#root .wap .wap-toolbar .top-tools {
  padding-top: 0.15rem;
  width: 94%;
  height: 1.44rem;
  background: hsla(0,0%,100%,.05);
  border-bottom-left-radius: 0.13rem;
  border-bottom-right-radius: 0.13rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.42rem;
  padding-right: 0.31rem;
  color: #fff;
}

#root .wap .wap-toolbar .top-tools>div {
  display: flex;
  align-items: center;
}

#root .wap .wap-toolbar .top-tools>div.tools-left>span {
  font-weight: bold;
  font-family: "OpenSans";
  margin-left: 0.42rem;
  font-size: 0.42rem;
  text-transform: uppercase;
}

#root .wap .wap .wap-navbar {
  z-index: 2;
  width: 100%;
  position: fixed;
  height: 2.12rem;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 0 0.39rem;
}

#root .wap .wap .wap-navbar ul {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #f3f3f3;
  border-radius: 0.2rem;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

#root .wap .wap .wap-navbar ul li {
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0.45rem;
  width: 2.3rem;
}

#root .wap .wap .wap-navbar ul li.on>span {
  color: #dabb6f;
}

#root .wap .wap .wap-navbar ul li .icon-box {
  height: 0.88rem;
  margin-bottom: 0.14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root .wap .wap .wap-navbar ul li>span {
  color: #4b4b4b;
  font-size: 0.24rem;
  font-family: "OpenSans-SemiBold";
  transition: color .3s;
  text-transform: uppercase;
  white-space: nowrap;
}

#root .wap .wap .wap-pages-out {
  width: 100%;
  overflow: hidden;
  background: #fff;
}

#root .wap .wap .wap-pages-out .fixed-box {
  width: 100%;
  height: 6.3rem;
  background: linear-gradient(180deg, #18723c, #2f8d43 79%, #41b851);
}

#root .wap .wap .wap-pages-out .fixed-box>.title {
  font-weight: bold;
  font-family: "OpenSans";
  color: #fff;
  font-size: 0.42rem;
  position: absolute;
  left: 1.83rem;
  top: -1.6rem;
  text-transform: uppercase;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.83rem;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box>div {
  display: flex;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box>div span {
  font-size: 0.3rem;
  color: #dec276;
  margin-right: 0.25rem;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box>div .auto-transfer {
  display: flex;
  align-items: center;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box .memberReview-date {
  font-size: 0.36rem;
  color: #fff;
  margin-top: 0.1rem;
  margin-right: 0;
  white-space: nowrap;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box {
  height: 2.93rem;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box>div {
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box>div .message-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box>div .message-box .icon-wap-message {
  margin-left: 0.99rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box>div .message-box .icon-wap-message .msg-count-box {
  width: 0.45rem;
  height: 0.45rem;
  border-radius: 50%;
  border: 0.04rem solid #fff;
  background: #ec1c23;
  font-size: 0.23rem;
  color: #fff;
  font-family: "AvenirNextLTPro";
  left: 0.4rem;
  top: -0.2rem;
  position: absolute;
  font-style: normal;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box>div .message-box .icon-wap-message .msg-count-box .tag {
  position: absolute;
  top: -0.01rem;
  left: .002rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .left {
  width: 65%;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info {
  display: flex;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info>div {
  display: flex;
  flex-direction: column;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info .member-name-level {
  align-items: center;
  margin-right: 0.53rem;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info .member-name-level span {
  font-size: 0.48rem;
  font-family: Arial;
  font-weight: 700;
  color: #fff;
  margin-top: 0.57rem;
  margin-right: 0;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info .member-name-info .highlight-text {
  font-size: 0.48rem;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info .member-name-info .info-progress {
  font-size: 0.36rem;
  color: #fff;
  white-space: nowrap;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info .member-name-info .line-out {
  width: 3.92rem;
  height: 0.27rem;
  border: 0.03rem solid #fff;
  border-radius: 1rem;
  margin-top: 0.16rem;
  font-size: 0.13rem;
  color: #000;
  display: flex;
  position: relative;
  align-items: center;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info .member-name-info .line-out .line-inner {
  height: 100%;
  background: #41b850;
  border-radius: 1rem;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info .member-name-info .line-out span {
  position: absolute;
  width: 100%;
  margin: auto;
  text-align: center;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info .member-name-info .rank-condition {
  font-size: 0.36rem;
  color: #fff;
  margin-top: 0.35rem;
}

#root .wap .wap .wap-pages-out .fixed-box .auto-tranfer-box.profile-box .member-info .member-name-info .rank-condition span {
  padding: 0 0.1rem;
  color: #fff;
  white-space: nowrap;
}

#root .wap .wap .wap-pages-out .fixed-box .currency-box {
  display: flex;
  align-items: center;
  padding-left: 0.84rem;
  margin-top: 0.07rem;
}

#root .wap .wap .wap-pages-out .fixed-box .currency-box .num {
  display: flex;
  color: #fff;
  align-items: center;
}

#root .wap .wap .wap-pages-out .fixed-box .currency-box .num>span:nth-child(1) {
  font-size: 0.36rem;
  transform: translateY(-0.11rem);
}

#root .wap .wap .wap-pages-out .fixed-box .currency-box .num>span:nth-child(2) {
  font-size: 0.8rem;
  font-family: "OpenSans-Bold";
  margin-left: 0.24rem;
  margin-right: 0.33rem;
  min-width: 4.8rem;
}

#root .wap .wap .wap-pages-out .fixed-box .restore-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.83rem;
  margin-top: 0.38rem;
}

#root .wap .wap .wap-pages-out .fixed-box .restore-box .btn-restore {
  width: 5.23rem;
  height: 0.98rem;
  background: rgba(0,83,41,.5);
  border-radius: 0.13rem;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .fixed-box .restore-box .message-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

#root .wap .wap .wap-pages-out .fixed-box .restore-box .message-box .icon-wap-message {
  margin-left: 0.99rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
}

#root .wap .wap .wap-pages-out .fixed-box .restore-box .message-box .icon-wap-message .msg-count-box {
  width: 0.45rem;
  height: 0.45rem;
  border-radius: 50%;
  border: 0.04rem solid #fff;
  background: #ec1c23;
  font-size: 0.23rem;
  color: #fff;
  font-family: "AvenirNextLTPro";
  left: 0.4rem;
  top: -0.2rem;
  position: absolute;
  font-style: normal;
}

#root .wap .wap .wap-pages-out .fixed-box .restore-box .message-box .icon-wap-message .msg-count-box .tag {
  position: absolute;
  top: -0.01rem;
  left: .002rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section {
  margin: -1.4rem 0 0.8rem;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .wap-funds-list {
  width: 90%;
  height: auto;
  background: rgba(245,245,245,.92);
  border-radius: 0.13rem;
  margin: 0 auto 0.4rem;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.1rem;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .wap-funds-list.funds-list {
  justify-content: center;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .wap-funds-list li {
  height: 1.9rem;
  width: 20%;
  display: flex;
  transition: background .3s;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .wap-funds-list li:nth-child(4) {
  margin-right: 0;
  order: 5;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .wap-funds-list li.on {
  pointer-events: none;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .wap-funds-list li.on>p>span {
  color: #dec276;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .wap-funds-list li>p {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .wap-funds-list li>p .icon-box {
  height: 1.24rem;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .wap-funds-list li>p>span {
  font-size: 0.3rem;
  color: #858585;
  transition: color .3s;
  font-family: "OpenSans-SemiBold";
  text-align: center;
  width: 2.5rem;
  height: .6rem;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .user-verification-container {
  padding: 0 0.3rem;
  width: 90%;
  margin: auto;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .user-verification-container .container-label {
  font-size: 0.42rem;
  margin-bottom: 0.2rem;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .user-verification-container .icons-and-button-container {
  display: grid;
  grid-template-columns: 68% 30%;
  justify-content: space-between;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .user-verification-container .icons-and-button-container .verification-icons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(245,245,245,.92);
  padding: 0.1rem 0.6rem;
  border-radius: 0.6rem;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .user-verification-container .icons-and-button-container .verification-icons-container .icon-wrapper {
  display: flex;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .user-verification-container .icons-and-button-container .verification-icons-container .icon-wrapper svg {
  width: 0.8rem;
  height: 0.8rem;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .user-verification-container .icons-and-button-container .verify-button-container {
  display: flex;
  justify-content: flex-end;
}

#root .wap .wap .wap-pages-out .st-shortcut-menu-section .user-verification-container .icons-and-button-container .verify-button-container .verify-button {
  text-transform: uppercase;
}

#root .wap .wap .wap-pages-out .wap-pages {
  width: 100%;
  padding-bottom: 4.12rem;
}

#root .wap .wap .wap-pages-out .wap-pages .no-msg-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.48rem;
}

#root .wap .wap .wap-pages-out .wap-pages input {
  font-size: 0.42rem;
  width: 100%;
  height: 100%;
  color: #2f2f2f;
  background: #f3f2f2;
  border: none;
  outline: none;
  padding-left: 0.52rem;
  padding-right: 0.55rem;
  border-radius: 0.13rem;
  height: 0.98rem;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination {
  font-size: 0.35rem;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination svg {
  max-width: 0.35rem;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination li {
  background: #fff;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination li a {
  padding: 0.1rem 0.2rem;
  display: flex;
  align-items: center;
  height: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.active,
#root .wap .wap .wap-pages-out .wap-pages ul.pagination li:hover {
  background: #39b549;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.active a,
#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.active svg,
#root .wap .wap .wap-pages-out .wap-pages ul.pagination li:hover a,
#root .wap .wap .wap-pages-out .wap-pages ul.pagination li:hover svg {
  color: #fff;
  fill: #fff;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.disabled svg {
  fill: #acacac;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.disabled.active,
#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.disabled:hover {
  background: #fff;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.disabled.active a,
#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.disabled.active svg,
#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.disabled:hover a,
#root .wap .wap .wap-pages-out .wap-pages ul.pagination li.disabled:hover svg {
  color: #acacac;
  fill: #acacac;
  cursor: not-allowed;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination .page-item {
  width: 0.8rem;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.01rem solid #f5f5f5;
}

#root .wap .wap .wap-pages-out .wap-pages ul.pagination .page-item button {
  width: 100%;
  height: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages .react-datepicker-wrapper {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages .react-datepicker__close-icon {
  display: none;
}

#root .wap .wap .wap-pages-out .wap-pages .react-dropdown-select {
  width: 100%;
  color: #2f2f2f;
  background: #f3f2f2;
}

#root .wap .wap .wap-pages-out .wap-pages .react-dropdown-select-content {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages .react-dropdown-select-content span {
  position: absolute;
  height: .98rem;
  display: flex;
  align-items: center;
  padding-left: .52rem;
  padding-right: .55rem;
}

#root .wap .wap .wap-pages-out .wap-pages .invalid-feedback {
  font-size: 0.34rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page {
  padding: 0 0.4rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .weekly-turnover {
  margin-top: 0.62rem;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: relative;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .weekly-turnover>.icon-box {
  margin-top: 0.05rem;
  margin-right: 0.82rem;
  width: 0.6rem;
  height: 0.6rem;
  background: #f3f2f2;
  border-radius: 50%;
  margin-left: 0.35rem;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .weekly-turnover>span {
  color: #2f2f2f;
  font-size: 0.42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .weekly-turnover .showWeekly-item {
  z-index: 1;
  position: absolute;
  left: 0.4rem;
  top: calc(100% + 0.2rem);
  width: 10.91rem;
  height: 19.6rem;
  background: linear-gradient(-30deg, #227242 0%, #3c8b4d 56%, #59b766 100%);
  border-radius: 0.13rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .weekly-turnover .showWeekly-item .weekly-turn-total {
  width: 100%;
  height: 1.79rem;
  background: hsla(0,0%,100%,.1);
  padding: 0.43rem 0.56rem 0.38rem 0.49rem;
  display: flex;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .weekly-turnover .showWeekly-item .weekly-turn-total>span {
  font-size: 0.42rem;
  color: #fff;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .weekly-turnover .showWeekly-item .weekly-turn-total>span:nth-child(1) {
  width: 4.72rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .weekly-turnover .showWeekly-item .weekly-turn-total>span:nth-child(2) {
  width: 4.45rem;
  height: 0.98rem;
  background: #48a25b;
  border-radius: 0.13rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.45rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .weekly-turnover .showWeekly-item .weekly-turn-total .icon-wap-reload {
  width: 0.44rem;
  height: 0.48rem;
  margin-left: 0.26rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box {
  padding: 0 0.4rem;
  margin-bottom: 0.37rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.special-code.code-box>div:nth-child(3) {
  width: unset;
  height: unset;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div.title-item {
  font-size: 0.42rem;
  color: #4d4d4d;
  height: 0.6rem;
  margin-bottom: 0.38rem;
  padding-right: 0.53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div.title-item>span {
  font-family: "OpenSans-Bold";
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div.title-item>span .asterisk {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div:nth-child(2) {
  cursor: pointer;
  position: relative;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div:nth-child(2) .icon-wap-arrow {
  position: absolute;
  top: 50%;
  margin-top: -0.11rem;
  right: 0.55rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div:nth-child(2) .showFrom-item,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div:nth-child(2) .showTo-item {
  cursor: default;
  position: absolute;
  left: 0;
  top: 100%;
  width: 10.91rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #252525;
  border-radius: 0.13rem;
  z-index: 1;
  max-height: 13.5rem;
  overflow: scroll;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div:nth-child(2),
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box>div:nth-child(3) {
  width: 10.83rem;
  height: 0.98rem;
  background: #f3f2f2;
  border-radius: 0.13rem;
  font-size: 0.42rem;
  color: #2f2f2f;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div.title-item {
  padding-left: 0.49rem;
  padding-right: 0.21rem;
  color: #0a974b;
  font-size: 0.36rem;
  margin-bottom: 0.08rem;
  height: 0.6rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div.title-item>span,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div.title-item>button {
  font-family: "OpenSans";
  font-weight: normal;
  transform: translateX(0.05rem);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div.title-item>span:nth-child(2),
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div.title-item>button:nth-child(2) {
  text-decoration: underline;
  color: #0077d2;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div.title-item>button {
  text-decoration: underline;
  color: #0077d2;
  cursor: pointer;
  background: unset;
  border: none;
  font-family: "OpenSans";
  font-weight: normal;
  font-size: 0.36rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div.title-item>button[disabled],
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div.title-item>button:disabled {
  color: rgba(179,179,179,.5);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div:nth-child(2) {
  color: #8f8f8f;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div:nth-child(3) {
  background: #e4e4e4;
  color: #2f2f2f;
  margin-top: 0.17rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box.code-box>div:nth-child(3) input {
  font-size: 0.42rem;
  width: 100%;
  height: 100%;
  color: #2f2f2f;
  background: rgba(0,0,0,0);
  border: none;
  outline: none;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-item-box .status-box {
  justify-content: flex-start;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-from-box>div {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-from-box>span {
  padding-left: 0.52rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-to-box>div {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-to-box>span {
  padding-left: 0.52rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .showPromo-btn>div {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box {
  margin-top: 0.85rem;
  width: 11.63rem;
  height: 5.78rem;
  border-radius: 0.13rem;
  padding: 0 0.4rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .top>span {
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #4d4d4d;
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .top>span.btn-all-in {
  width: 3.32rem;
  height: 0.98rem;
  background: #dec276;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #147036;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .center {
  height: 0.98rem;
  border: 1PX solid #0a974b;
  border-radius: 0.13rem;
  padding-left: 0.53rem;
  margin-top: 0.52rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .center input {
  border: none;
  background: none;
  font-size: 0.42rem;
  color: #2f2f2f;
  height: 100%;
  width: 100%;
  padding-left: unset;
  padding-right: unset;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .btns-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.93rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .btns-box>span,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .btns-box .btn-transfer {
  cursor: pointer;
  width: 5.23rem;
  height: 0.98rem;
  background: rgba(47,47,47,0);
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
  background: #0a974b;
  border: none;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .btns-box>span:nth-child(1),
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .btns-box .btn-transfer:nth-child(1) {
  background: #cdcdcd;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .btns-box>span:disabled,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .btns-box>span[disabled],
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .btns-box .btn-transfer:disabled,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .transfer-amount-box .btns-box .btn-transfer[disabled] {
  filter: grayscale(0.6);
  cursor: not-allowed;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list {
  padding: 0 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container {
  border-bottom: 0.01rem solid hsla(0,0%,100%,.3);
  padding-bottom: 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-title {
  padding: 0.6rem 0 0.3rem 0.1rem;
  font-size: 0.35rem;
  color: #ba9b43;
  font-family: "OpenSans";
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list {
  display: flex;
  flex-wrap: wrap;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider {
  width: 32%;
  margin-right: 0.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .item {
  display: flex;
  align-items: center;
  width: 100%;
  background: #2d2d2d;
  border-radius: 0.1rem;
  padding: 0.1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .item .icon-box {
  width: 0.73rem;
  height: 0.71rem;
  margin-right: 0.16rem;
  background: none;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .item .icon-box>i {
  width: 100%;
  height: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .item>div {
  font-size: 0.36rem;
  font-weight: 400;
  color: #fff;
  line-height: 0.3rem;
  display: flex;
  flex-direction: column;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .item>div>span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .item>div>span:nth-child(1) {
  margin-bottom: .1rem;
  color: #bdbdbd;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .item.item-main-wallet {
  height: 1.01rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .turnover-box {
  display: flex;
  align-items: center;
  padding: 0.1rem 0 0.1rem 0.15rem;
  margin-bottom: 0.1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .turnover-box span {
  width: 0.66rem;
  color: #166a31;
  font-size: 0.28rem;
  font-weight: bold;
  line-height: 0.28rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .turnover-box span.turnover-amount {
  margin-left: 0.18rem;
  color: #0a974b;
  line-height: 0;
  font-weight: unset;
  font-size: 0.36rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .btn-box {
  display: flex;
  padding-left: 30%;
  margin-bottom: 0.67rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .btn-box .btn-all-in {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 1.3rem;
  height: 0.5rem;
  margin-right: 0.35rem;
  border: 0.01rem solid #ba9b43;
  border-radius: 0.1rem;
  color: #ba9b43;
  font-size: 0.3rem;
  font-weight: bold;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider .btn-box .btn-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.5rem;
  height: 0.5rem;
  color: #252525;
  background: #484848;
  border-radius: 50%;
  font-size: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 0.05rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container .category-provider-list .category-provider:nth-child(3n) {
  margin-right: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list .provider-category-container:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li {
  padding-left: 0.53rem;
  display: flex;
  flex-direction: column;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li:not(:last-child)::after {
  content: "";
  width: 9.9rem;
  height: 0.02rem;
  background: hsla(0,0%,100%,.3);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>span {
  font-size: 0.35rem;
  color: #ba9b43;
  margin-top: 0.45rem;
  margin-bottom: 0.38rem;
  font-family: "OpenSans";
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .line {
  width: 100%;
  height: 0.67rem;
  position: relative;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .line::before {
  content: "";
  position: absolute;
  left: -0.76rem;
  top: 50%;
  transform: translateY(-50%) scaleY(0.5);
  height: 0.02rem;
  background: hsla(0,0%,100%,.3);
  width: 10.92rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .main-wallet-box .btn-box {
  margin-top: 1.02rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item {
  display: flex;
  align-items: center;
  width: 3.15rem;
  margin-right: 0.25rem;
  background: #2d2d2d;
  border-radius: 0.1rem;
  padding: 0.15rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item[data-group="0"] {
  width: 3.54rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item[data-group="1"] {
  width: 3.38rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item[data-group="2"] {
  width: 3.18rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item .icon-box {
  width: 0.73rem;
  height: 0.71rem;
  margin-right: 0.16rem;
  background: none;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item .icon-box>i {
  width: 100%;
  height: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item>div {
  font-size: 0.36rem;
  font-weight: 400;
  color: #fff;
  line-height: 0.3rem;
  display: flex;
  flex-direction: column;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item>div>span {
  white-space: nowrap;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item>div>span:nth-child(1) {
  margin-bottom: .1rem;
  color: #bdbdbd;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .item.item-main-wallet {
  height: 1.01rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .turnover-box {
  display: flex;
  align-items: center;
  padding: 0.15rem;
  margin-bottom: 0.1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .turnover-box span {
  margin-left: 0.13rem;
  width: 0.68rem;
  color: #166a31;
  font-size: 0.36rem;
  font-weight: bold;
  line-height: 0.35rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .turnover-box span.turnover-amount {
  margin-left: 0.24rem;
  color: #0a974b;
  line-height: 0;
  font-weight: unset;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.67rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .btn-box .btn-all-in {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  width: 1.45rem;
  height: 0.59rem;
  margin-right: 0.47rem;
  border: 0.01rem solid #ba9b43;
  border-radius: 0.1rem;
  color: #ba9b43;
  font-size: 0.36rem;
  font-weight: bold;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-transfer-page .page-content .provider-list>li>div .btn-box .btn-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.59rem;
  height: 0.59rem;
  color: #252525;
  background: #484848;
  border-radius: 50%;
  font-size: 0.6rem;
  font-weight: bold;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box {
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item {
  font-size: .42rem;
  color: #4d4d4d;
  height: .6rem;
  margin-bottom: .38rem;
  padding-right: .53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item.fast-deposit-tab {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  position: relative;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item>span {
  font-family: "OpenSans-Bold";
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item>span .asterisk {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .bank-offline-container .bankoff-btn {
  align-items: center;
  cursor: pointer;
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .bank-offline-container .bankoff-btn .img-container {
  margin: 0 0.1rem 0 0;
  position: relative;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .bank-offline-container .bankoff-btn .img-container img {
  height: 0.7rem;
  width: 0.8rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .bank-offline-container .bankoff-btn .img-container .red-dot {
  background: red;
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  bottom: 55%;
  left: 58%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .bank-offline-container .bankoff-btn span {
  color: #18723c;
  font-size: 0.42rem;
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .floating-bank-offline-container {
  background: #fff;
  border-radius: 0.15rem;
  box-shadow: 0 0.02rem 0.08rem rgba(0,0,0,.3);
  padding: 0.5rem 0;
  position: absolute;
  right: 0;
  top: 165%;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .floating-bank-offline-container .bank-container {
  align-items: center;
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .floating-bank-offline-container .bank-container:not(:last-child) {
  padding: 0 0 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .floating-bank-offline-container .bank-container .img-container {
  margin: 0 0.5rem 0 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .floating-bank-offline-container .bank-container .img-container img {
  height: 1rem;
  width: 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .floating-bank-offline-container .bank-container .bank-detail {
  font-size: 0.42rem;
  line-height: 1.3;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .floating-bank-offline-container .bank-container .bank-detail .title {
  color: #18723c;
  margin: 0;
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .floating-bank-offline-container .bank-container .bank-detail .content {
  color: #858585;
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .title-item .floating-bank-offline-container .bank-container .bank-detail .content p:first-child {
  margin: 0 0.5rem 0 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay .deposit-channel,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p .deposit-channel,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr .deposit-channel,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay .deposit-channel {
  margin: 0 0 0.5rem 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist {
  margin: 0 0 0.5rem 0;
  padding: 0.55rem 0.9rem;
  background: #f3f2f2;
  border-radius: .3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank {
  margin-bottom: 0.27rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank:last-child,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank:last-child,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank:last-child,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank:last-child {
  margin-bottom: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group {
  display: flex;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio],
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio],
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio],
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio] {
  -webkit-appearance: radio;
          appearance: radio;
  display: block;
  opacity: 0;
  height: 0;
  width: 0;
  padding: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark {
  background: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::after {
  content: "";
  border: 0.05rem solid #0a974b;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark {
  position: relative;
  height: 0.5rem;
  width: 0.5rem;
  background-color: #eee;
  border-radius: 50%;
  margin-right: 0.36rem;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::after {
  content: "";
  border: 0.05rem solid #b1b1b1;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark:hover,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark:hover,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark:hover,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark:hover {
  background: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  border: 0.02rem solid #dedcdf;
  border-radius: 0.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div {
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div svg {
  width: 100%;
  max-height: 1rem;
  max-width: 1rem;
  padding: 0.18rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img img {
  height: auto;
  max-height: 1rem;
  max-width: 1rem;
  padding: 0.18rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img svg {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-on,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-on,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-on,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-on {
  border: 0.02rem solid #0a974b;
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  background-position: 101% 106%;
  background-size: 0.5rem 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-on svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-on svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-on svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-on svg {
  filter: grayscale(0%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-name,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-name,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-name,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-name {
  display: flex;
  align-items: center;
  font-size: 0.36rem;
  width: 72%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li:nth-child(2),
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li:nth-child(2),
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li:nth-child(2),
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li:nth-child(2) {
  margin-bottom: 0.99rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-options,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-options,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-options,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-options {
  font-size: .42rem;
  font-family: "OpenSans-Bold";
  font-weight: bold;
  margin-bottom: .3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-options .asterisk,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-options .asterisk,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-options .asterisk,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-options .asterisk {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-qrpay-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-p2p-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-crypto-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-quickpay-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-qrpay-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-p2p-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-crypto-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-quickpay-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-qrpay-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-p2p-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-crypto-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-quickpay-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-qrpay-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-p2p-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-crypto-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-quickpay-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.93rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-qrpay-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-p2p-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-crypto-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-quickpay-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-qrpay-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-p2p-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-crypto-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-quickpay-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-qrpay-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-p2p-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-crypto-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-quickpay-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-qrpay-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-p2p-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-crypto-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-quickpay-button .btn-deposit {
  background: #0a974b;
  border: none;
  cursor: pointer;
  width: 5.23rem;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .amount-select-btn .btn-amount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .amount-select-btn .btn-amount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .amount-select-btn .btn-amount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .amount-select-btn .btn-amount {
  width: 1.9rem;
  height: 1.02rem;
  border-radius: 0.1rem;
  border: 0.05rem solid #707070;
  background: linear-gradient(to top, #e6e6e6, #f4f3f3);
  color: #858585;
  margin-right: 0.18rem;
  cursor: pointer;
  font-size: 0.42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .amount-select-btn .btn-amount:last-child,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .amount-select-btn .btn-amount:last-child,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .amount-select-btn .btn-amount:last-child,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .amount-select-btn .btn-amount:last-child {
  margin-right: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-amount .deposit-amount-input,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-amount .deposit-amount-input,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-amount .deposit-amount-input,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-amount .deposit-amount-input {
  position: relative;
  width: auto;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-amount .deposit-amount-input input,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-amount .deposit-amount-input input,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-amount .deposit-amount-input input,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-amount .deposit-amount-input input {
  padding: 0 1rem 0 .52rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: .2rem;
  width: .65rem;
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button img {
  max-width: 100%;
  width: auto;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .qrpay ul.deposit-bottom li .item .deposit-amount .invalid-feedback,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .p2p ul.deposit-bottom li .item .deposit-amount .invalid-feedback,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .bqr ul.deposit-bottom li .item .deposit-amount .invalid-feedback,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .quickpay ul.deposit-bottom li .item .deposit-amount .invalid-feedback {
  align-self: start;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item {
  margin-bottom: 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item p {
  margin-bottom: 0.38rem;
  padding-right: 0.53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .amount-select-btn .btn-amount {
  width: 1.9rem;
  height: 1.02rem;
  border-radius: 0.1rem;
  border: 0.05rem solid #707070;
  background: linear-gradient(to top, #e6e6e6, #f4f3f3);
  color: #858585;
  margin-right: 0.18rem;
  cursor: pointer;
  font-size: 0.42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .amount-select-btn .btn-amount:last-child {
  margin-right: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .share-box .share-column {
  width: 91%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .share-box .share-column .checkStatus {
  margin-top: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.select-bank .select-member-bank {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.select-bank .select-member-bank div:first-child {
  width: 95%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.select-bank .select-member-bank .btn-addnewbank {
  width: 0.98rem;
  height: 0.98rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #19723c;
  border: 0.05rem solid #19723c;
  border-radius: 0.13rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.select-bank .select-member-bank .btn-addnewbank svg {
  width: 1rem;
  height: 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.button-row {
  margin: 0.6rem 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.button-row .online-transfer-button {
  display: flex;
  justify-content: space-between;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.button-row .online-transfer-button .btn-receipt {
  cursor: pointer;
  width: 5.23rem;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #147036;
  font-weight: bold;
  text-transform: uppercase;
  border: 0;
  margin-right: 0.15rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.button-row .online-transfer-button .btn-deposit {
  cursor: pointer;
  width: 5.23rem;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  background: #0a974b;
  border: 0;
  margin-left: 0.15rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item p {
  font-size: 0.42rem;
  font-weight: bold;
  font-family: "OpenSans-Bold";
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector {
  margin-bottom: 0.5rem;
  padding: 0.55rem 0.9rem;
  background: #f3f2f2;
  border-radius: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank {
  margin-bottom: 0.27rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank:last-child {
  margin-bottom: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label {
  display: flex;
  align-items: flex-start;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div {
  display: flex;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div:first-child {
  margin-bottom: 0.32rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div.bank-details {
  height: 0.98rem;
  width: 7.79rem;
  margin-bottom: 0.2rem;
  padding: 0.31rem 0.23rem 0.37rem 0.33rem;
  background: #e4e4e4;
  border-radius: 0.1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div.bank-details:last-child {
  margin-bottom: 0.28rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div.bank-details input {
  padding: 0;
  background: #e4e4e4;
  height: unset;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  border: 0.02rem solid #dedcdf;
  border-radius: 0.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img div {
  height: 1rem;
  width: 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img div.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img img {
  max-height: 1rem;
  max-width: 1rem;
  padding: 0.18rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img.bank-on {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 101% 106%;
  background-size: 0.5rem 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img.bank-on svg {
  filter: grayscale(0%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img svg {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-name {
  margin-left: 0.5rem;
  font-size: 0.42rem;
  color: #858585;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-name .bank-time {
  color: #858585;
  display: grid;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-name.bank-selected {
  color: #19763c;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label input[type=radio] {
  -webkit-appearance: radio;
          appearance: radio;
  display: block;
  opacity: 0;
  height: 0;
  width: 0;
  background: red;
  padding: 0;
  margin: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label input[type=radio]:checked~.checkmark {
  background: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label input[type=radio]:checked~.checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label input[type=radio]:checked~.checkmark::after {
  content: "";
  border: 0.05rem solid #0a974b;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .checkmark {
  position: relative;
  height: 0.5rem;
  width: 0.55rem;
  background-color: #eee;
  border-radius: 50%;
  margin-right: 0.36rem;
  margin-top: 0.2rem;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .checkmark::after {
  content: "";
  border: 0.05rem solid #b1b1b1;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc label .checkmark:hover {
  background: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc .radio-form .account-holder {
  background-color: #19723c;
  color: #fff;
  padding: 0 0.3rem;
  margin-bottom: 0.1rem;
  min-width: 3rem;
  min-height: 0.9rem;
  font-size: 0.42rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.13rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc .radio-form .account-holder:nth-child(2) {
  margin-bottom: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item .bank-selector .bank-list .small-bank .member-bankacc .radio-form .account-holder svg {
  margin-left: 0.3rem;
  fill: #fff;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.deposit-datetime .deposit-amount .react-datepicker__tab-loop .react-datepicker-popper div .react-datepicker .react-datepicker__navigation--next {
  right: 2.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.deposit-datetime .deposit-amount img {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.bank-qrcode-container {
  margin-bottom: .5rem;
  padding: .55rem;
  background: #f3f2f2;
  border-radius: .3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.bank-qrcode-container .frame {
  width: 5.6rem;
  height: 5.6rem;
  padding: .2rem;
  background: #fff;
  margin: 0 auto;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.bank-qrcode-container .frame img {
  width: 100%;
  height: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.bank-qrcode-container .guide {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: .3rem;
  font-size: .34rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.bank-qrcode-container .guide h5 {
  font-size: .4rem;
  font-weight: 700;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.bank-qrcode-container .guide ul h6 {
  font-size: .4rem;
  font-weight: 700;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.bank-qrcode-container .guide ul li {
  list-style-type: decimal;
  margin-left: .34rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-tab-content .tab-pane .app-onlinetransfer ul li .item.bank-qrcode-container .guide p {
  font-size: .34rem;
  font-weight: 400;
  font-family: "OpenSans";
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item {
  margin-right: 0.675rem;
  text-align: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0.01rem solid #dedcdf;
  border-radius: 0.2rem;
  width: 2.02rem;
  height: 1.26rem;
  margin: auto;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer svg {
  width: 1.35rem;
  height: 0.57rem;
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer img.ip4u,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer svg.ip4u {
  width: 80%;
  height: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer img.h2pay,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer svg.h2pay {
  width: 1.2rem;
  height: 0.86rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer img.fpy,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer svg.fpy {
  height: 0.9rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer img.qrpay,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer img.yespay,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer svg.qrpay,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer svg.yespay {
  width: 1.35rem;
  height: unset;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer img.active,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-banktransfer svg.active {
  filter: grayscale(0%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .depositLink .deposit-options-text {
  font-size: 0.32rem;
  width: 2.02rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .active .deposit-banktransfer {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 101% 106%;
  background-size: 0.6rem 0.6rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .deposit-mobile-item .active .deposit-options-text {
  color: #19723c;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer {
  margin-right: 0.675rem;
  text-align: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer:last-child {
  margin-right: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-banktransfer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0.01rem solid #dedcdf;
  border-radius: 0.2rem;
  width: 2.02rem;
  height: 1.26rem;
  margin: auto;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-banktransfer img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-banktransfer svg {
  width: auto;
  height: 1rem;
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-banktransfer img.crypto,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-banktransfer svg.crypto {
  width: 1.8rem;
  height: 1.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-banktransfer img.bankapp,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-banktransfer svg.bankapp {
  width: 1.5rem;
  height: 1.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-banktransfer img.active,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-banktransfer svg.active {
  filter: grayscale(0%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .depositLink .deposit-options-text {
  font-size: 0.32rem;
  white-space: nowrap;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .active .deposit-banktransfer {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 101% 106%;
  background-size: 0.6rem 0.6rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .mobile-deposit-transfer .active .deposit-options-text {
  color: #19723c;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .deposit-mobile-tab .nav-item:nth-child(4n) {
  margin-right: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .important-notice {
  display: flex;
  color: #41b850;
  margin-top: 0.24rem;
  margin-bottom: 0.38rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .important-notice .icon-tip {
  margin: 0.1rem 0.08rem 0 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit ul {
  display: block;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .qrCode li .item .qr-box {
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .qrCode li .item .qr-box .box-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .qrCode li .item .qr-box .box-image canvas {
  margin-bottom: 0.4rem;
  max-width: 4.25rem;
  max-height: 4.25rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .qrCode li .item .qr-box .box-image span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.24rem;
  height: 0.98rem;
  background: #cdcdcd;
  border-radius: 0.13rem;
  font-size: 0.42rem;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .qrCode li .item .qr-box .box-info p {
  padding-right: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .qrCode li .item .qr-box .box-info p:nth-child(4) {
  margin-top: 0.7rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .share-qrCode .share-box {
  display: flex;
  justify-content: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .share-qrCode .share-box span {
  display: flex;
  align-items: center;
  padding: 0.3rem;
  width: 100%;
  height: 0.98rem;
  background: #0a974b;
  border-radius: 0.13rem;
  font-size: 0.42rem;
  font-weight: bold;
  color: #fff;
  margin-right: 0.2rem;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .share-qrCode .share-box span::-webkit-scrollbar {
  height: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .share-qrCode .share-box .share-btn {
  background: #cdcdcd;
  border-radius: 0.13rem;
  height: 0.98rem;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .remark-box {
  background: #fffeff;
  border: 0.01rem solid #e2e2e2;
  padding: 0.59rem 0.53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .remark-box .remark-content {
  font-size: 0.35rem;
  font-family: Arial;
  margin-left: 0.45rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .remark-box .remark-content li {
  margin-bottom: 0.35rem;
  list-style-type: decimal;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .share-popup .popup .popup-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .share-popup .popup .popup-body>:not(:last-child) {
  margin-right: 0.1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .share-popup .popup .popup-body>:nth-child(n) {
  width: 1.5rem;
  height: 1.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .share-popup .popup .popup-body .icon-copy,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .share-popup .popup .popup-body svg {
  width: 100%;
  height: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .memberVerify-popup .popup .popup-body>div .popup-field.phone-field {
  display: block;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .memberVerify-popup .popup .popup-body>div .popup-field.phone-field .phone-box {
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .memberVerify-popup .popup .popup-body>div .popup-field input {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .memberVerify-popup .popup .popup-body>div .text-red {
  font-size: 0.34rem;
  margin-bottom: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .memberVerify-popup .popup .popup-body>div .text-red strong {
  font-size: 0.34rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .refill-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .deposit-bottom .deposit-crypto-button .btn-deposit,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-deposit-page .deposit-item-box .crypto-deposit .deposit-bottom .deposit-p2p-button .btn-deposit {
  cursor: pointer;
  width: 5.23rem;
  height: .98rem;
  border-radius: .13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
  background: #0a974b;
  border: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page {
  padding: 0 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .btn-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .btn-close svg {
  background: #19723c;
  border-radius: 50%;
  fill: #fff;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .title-item {
  font-size: .42rem;
  color: #4d4d4d;
  height: .6rem;
  margin-bottom: .38rem;
  padding-right: .53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .title-item>span {
  font-family: "OpenSans-Bold";
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .title-item>span .asterisk {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-amount-list .amount-select-btn {
  margin-top: 0.12rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.08rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-amount-list .amount-select-btn .btn-amount {
  width: 1.9rem;
  height: 1.02rem;
  border-radius: .1rem;
  border: .05rem solid #707070;
  background: linear-gradient(to top, #e6e6e6, #f4f3f3);
  color: #858585;
  margin-right: .18rem;
  cursor: pointer;
  font-size: .42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer {
  display: flex;
  margin-right: 0.7rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer:nth-child(4n) {
  margin-right: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer .withdrawLink {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer .withdrawLink .withdraw-banktransfer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0.01rem solid #dedcdf;
  border-radius: 0.2rem;
  width: 2.02rem;
  height: 1.26rem;
  margin: auto;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer .withdrawLink .withdraw-banktransfer img {
  width: 1rem;
  height: 1rem;
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer .withdrawLink .withdraw-banktransfer img.crypto {
  width: 1.8rem;
  height: 1.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer .withdrawLink .withdraw-banktransfer img.active {
  filter: grayscale(0%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer .withdrawLink .withdraw-options-text {
  font-size: 0.32rem;
  white-space: nowrap;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer .active .withdraw-banktransfer {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 101% 106%;
  background-size: 0.6rem 0.6rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-mobile-tab .mobile-withdraw-transfer .active .withdraw-options-text {
  color: #19723c;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.93rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-submit .btn-withdraw {
  cursor: pointer;
  width: 5.23rem;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
  background: #0a974b;
  border: 0;
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-bank-statement button {
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  width: 100%;
  font-weight: 700;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-bank-statement .receipt-image-box {
  display: flex;
  justify-content: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-bank-statement .receipt-image-box img {
  width: 100%;
  height: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-bank-statement .receipt-image-box .pdf-clear {
  margin-left: 0.1rem;
  width: 0.12rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4rem;
  font-family: Arial;
  font-weight: bold;
  color: #fff;
  width: 100%;
  height: 0.98rem;
  background: #0b964e;
  border-radius: 0.1rem;
  border: none;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .submit-btn:disabled,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .submit-btn[disabled] {
  filter: grayscale(0.6);
  cursor: not-allowed;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li.small-bank {
  padding-right: 0.45rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .amount-select-btn {
  margin-bottom: 0.08rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.08rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .amount-select-btn .btn-amount {
  width: 1.9rem;
  height: 1.02rem;
  border-radius: .1rem;
  border: .05rem solid #707070;
  background: linear-gradient(to top, #e6e6e6, #f4f3f3);
  color: #858585;
  margin-right: .18rem;
  cursor: pointer;
  font-size: .42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item {
  margin-bottom: 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item p {
  font-size: 0.42rem;
  font-weight: bold;
  font-family: "OpenSans-Bold";
  margin-bottom: 0.38rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector {
  margin-bottom: 0.5rem;
  padding: 0.55rem 0.7rem;
  background: #f3f2f2;
  border-radius: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label {
  display: flex;
  align-items: flex-start;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label input[type=radio] {
  -webkit-appearance: radio;
          appearance: radio;
  display: block;
  opacity: 0;
  height: 0;
  width: 0;
  padding: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label input[type=radio]:checked~.checkmark {
  background: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label input[type=radio]:checked~.checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label input[type=radio]:checked~.checkmark::after {
  content: "";
  border: 0.05rem solid #0a974b;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .checkmark {
  position: relative;
  height: 0.5rem;
  width: 0.55rem;
  background-color: #eee;
  border-radius: 50%;
  margin-right: 0.36rem;
  margin-top: 0.2rem;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .checkmark::after {
  content: "";
  border: 0.05rem solid #b1b1b1;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border: 0.01rem red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group>div:first-child {
  margin-bottom: 0.32rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group .bank-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  border: 0.02rem solid #dedcdf;
  border-radius: 0.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group .bank-img div.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group .bank-img.bank-on {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 101% 106%;
  background-size: 0.5rem 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group .bank-img.bank-on svg {
  filter: grayscale(0%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group .bank-img svg {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group .bank-img.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group .bank-img img {
  max-height: 1rem;
  max-width: 1rem;
  padding: 0.18rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector .small-bank .member-bankacc label .banklist-group .bank-name {
  font-size: 0.42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector.box-info .bank-list {
  display: flex;
  align-items: center;
  margin-bottom: 0.27rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector.box-info .bank-list .icon-reload {
  fill: #39b549;
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector.box-info .chain-currency-rate-info-container {
  display: unset;
  margin-bottom: 0.27rem;
  align-items: flex-start;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector.box-info .chain-currency-rate-info-container .chain-currency-rate-info {
  display: flex;
  flex-flow: column;
  padding-right: 0.25rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector.box-info .chain-currency-rate-info-container .chain-currency-rate-info p {
  font-size: 0.38rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector.box-info .chain-currency-rate-info-container .chain-guaranteed-time {
  display: flex;
  padding-right: 0.1rem;
  white-space: nowrap;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector.box-info .chain-currency-rate-info-container .chain-guaranteed-time p {
  font-size: 0.38rem;
  padding-right: 0.25rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .bank-selector.box-info .chain-currency-rate-info-container .icon-reload {
  fill: #39b549;
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .btn-withdraw {
  cursor: pointer;
  width: 5.23rem;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
  background: #0a974b;
  border: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .btn-withdraw[disabled],
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul li .item .btn-withdraw:disabled {
  filter: grayscale(0.6);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul.address .deposit-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul.address .deposit-amount div:first-child {
  width: 95%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul.address .deposit-amount .btn-addnewbank {
  width: 0.98rem;
  height: 0.98rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #19723c;
  border: 0.05rem solid #19723c;
  border-radius: 0.13rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul.address .deposit-amount .btn-addnewbank svg {
  width: 1rem;
  height: 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit ul.address .deposit-amount .react-dropdown-select-content span {
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit .remark-box {
  background: #fffeff;
  border: 0.01rem solid #e2e2e2;
  padding: 0.59rem 0.53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit .remark-box .remark-content {
  font-size: 0.35rem;
  font-family: Arial;
  margin-left: 0.45rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content .crypto-deposit .remark-box .remark-content li {
  margin-bottom: 0.35rem;
  list-style-type: decimal;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist {
  margin: 0 0 0.5rem 0;
  padding: 0.55rem 0.9rem;
  background: #f3f2f2;
  border-radius: .3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank {
  margin-bottom: 0.27rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank:last-child,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank:last-child {
  margin-bottom: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio],
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group input[type=radio] {
  -webkit-appearance: radio;
          appearance: radio;
  display: block;
  opacity: 0;
  height: 0;
  width: 0;
  padding: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group input[type=radio]:checked~.checkmark {
  background: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group input[type=radio]:checked~.checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group input[type=radio]:checked~.checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group input[type=radio]:checked~.checkmark::after {
  content: "";
  border: 0.05rem solid #0a974b;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .checkmark {
  position: relative;
  height: 0.5rem;
  width: 0.55rem;
  background-color: #eee;
  border-radius: 50%;
  margin-right: 0.36rem;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .checkmark::after {
  content: "";
  border: 0.05rem solid #b1b1b1;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .checkmark:hover,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .checkmark:hover {
  background: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .bank-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  border: 0.02rem solid #dedcdf;
  border-radius: 0.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .bank-img div {
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .bank-img div.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img div svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .bank-img div svg {
  width: 100%;
  max-height: 1rem;
  max-width: 1rem;
  padding: 0.18rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .bank-img img {
  height: auto;
  max-height: 1rem;
  max-width: 1rem;
  padding: 0.18rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-on,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .bank-img.bank-on {
  border: 0.02rem solid #0a974b;
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  background-position: 101% 106%;
  background-size: 0.5rem 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .bank-img svg {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .bank-img.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .banklist-group .bank-name,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .banklist-group .bank-name {
  display: flex;
  align-items: center;
  font-size: 0.36rem;
  width: 72%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label {
  display: flex;
  align-items: flex-start;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group>div {
  display: flex;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div:first-child,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group>div:first-child {
  margin-bottom: 0.32rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div.bank-details,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group>div.bank-details {
  height: 0.98rem;
  width: 7.79rem;
  margin-bottom: 0.2rem;
  padding: 0.31rem 0.23rem 0.37rem 0.33rem;
  background: #e4e4e4;
  border-radius: 0.1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div.bank-details:last-child,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group>div.bank-details:last-child {
  margin-bottom: 0.28rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group>div.bank-details input,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group>div.bank-details input {
  padding: 0;
  background: #e4e4e4;
  height: unset;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group .bank-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  border: 0.02rem solid #dedcdf;
  border-radius: 0.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img div,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group .bank-img div {
  height: 1rem;
  width: 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img div.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group .bank-img div.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group .bank-img img {
  max-height: 1rem;
  max-width: 1rem;
  padding: 0.18rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img.bank-on,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group .bank-img.bank-on {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 101% 106%;
  background-size: 0.5rem 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-img.bank-off,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group .bank-img.bank-off {
  filter: grayscale(100%);
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .banklist-group .bank-name,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .banklist-group .bank-name {
  font-size: 0.42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label input[type=radio],
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label input[type=radio] {
  -webkit-appearance: radio;
          appearance: radio;
  display: block;
  opacity: 0;
  height: 0;
  width: 0;
  background: red;
  padding: 0;
  margin: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label input[type=radio]:checked~.checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label input[type=radio]:checked~.checkmark {
  background: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label input[type=radio]:checked~.checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label input[type=radio]:checked~.checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label input[type=radio]:checked~.checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label input[type=radio]:checked~.checkmark::after {
  content: "";
  border: 0.05rem solid #0a974b;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .checkmark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .checkmark {
  position: relative;
  height: 0.5rem;
  width: 0.55rem;
  background-color: #eee;
  border-radius: 50%;
  margin-right: 0.36rem;
  margin-top: 0.2rem;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .checkmark::before,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .checkmark::before {
  content: "";
  border: 0.1rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .checkmark::after,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .checkmark::after {
  content: "";
  border: 0.05rem solid #b1b1b1;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc label .checkmark:hover,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc label .checkmark:hover {
  background: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc .radio-form .account-holder,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc .radio-form .account-holder {
  background-color: #19723c;
  color: #fff;
  padding: 0 0.3rem;
  margin-bottom: 0.1rem;
  min-width: 3rem;
  min-height: 0.9rem;
  font-size: 0.42rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.13rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc .radio-form .account-holder:nth-child(2),
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc .radio-form .account-holder:nth-child(2) {
  margin-bottom: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .small-bank .member-bankacc .radio-form .account-holder svg,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank .member-bankacc .radio-form .account-holder svg {
  margin-left: 0.3rem;
  fill: #fff;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: default;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-withdraw-page .withdraw-item-box .withdraw-tab-content ul.small-banklist li .item .bank-selector .bank-list .other-bank>span {
  width: auto;
  display: inline-block;
  cursor: pointer;
  font-size: 0.14rem;
  color: #858585;
  text-decoration: underline;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-filter {
  padding: 0 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-filter .history-formpage ul li .form-history .form-item {
  margin-bottom: 0.37rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-filter .history-formpage ul li .form-history .form-item .top .top-title,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-filter .history-formpage ul li .form-history .form-item .top .to-label {
  font-size: 0.42rem;
  color: #4d4d4d;
  height: 0.6rem;
  margin-bottom: 0.38rem;
  padding-right: 0.53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-filter .history-formpage ul li .form-history .form-item .top .top-title .history-label,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-filter .history-formpage ul li .form-history .form-item .top .to-label .history-label {
  font-weight: bold;
  font-family: "OpenSans-Bold";
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-filter .history-formpage ul li .form-history .form-item:nth-child(4) {
  display: flex;
  justify-content: flex-start;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-filter .history-formpage ul li .form-history .form-item:nth-child(4) .btn-search {
  cursor: pointer;
  width: 5.23rem;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  background: #0a974b;
  border: 0;
  margin-top: 0.4rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-filter .history-formpage ul li .form-history .asterisk {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container {
  overflow: hidden;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header {
  overflow-x: scroll;
  margin: 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details {
  display: flex;
  flex-wrap: nowrap;
  min-height: 1.5rem;
  width: -webkit-max-content;
  width: max-content;
  background: #d7f1db;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div li,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details li {
  display: flex;
  align-items: center;
  padding: 0 0.66rem 0 0;
  color: #075129;
  font-size: 0.4rem;
  justify-content: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-date,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-date {
  width: 5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-gameProvider,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-betCount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-betAmount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-validBetAmount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-bonusTurnover,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-turnoverWithoutBonus,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-totalBonus,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-totalRebate,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-amount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-from,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-to,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-remark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-targetamount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-provider,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-reward,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-promo,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-validto,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-promoto,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-eligibleto,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-gameProvider,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-betCount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-betAmount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-validBetAmount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-bonusTurnover,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-turnoverWithoutBonus,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-totalBonus,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-totalRebate,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-amount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-from,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-to,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-remark,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-targetamount,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-provider,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-reward,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-promo,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-validto,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-promoto,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-eligibleto {
  width: 4rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-type,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-type {
  width: 3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-status,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-status {
  width: 3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div .history-method,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details .history-method {
  width: 5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div.history-freeSpinHistory li,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-details.history-freeSpinHistory li {
  width: 4rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-title-div.history-freeSpinHistory li.history-provider,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header .history-details.history-freeSpinHistory li.history-provider {
  white-space: nowrap;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details {
  background: unset;
  border-bottom: 0.01rem solid #e4e4e4;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-details li {
  color: #000;
  min-height: 0.98rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-rebate .history-provider {
  width: 7rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-rebate .rebate-popup {
  color: #075129;
  text-decoration: underline;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-rebate .rebate-date,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-rebate .rebate-provider,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-rebate .rebate-turnover {
  width: 5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-rebate .rebate-turnover {
  width: 2.7rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-rebate .rebate-promo {
  width: 5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-rebate .rebate-eligible {
  width: 4.8rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-history-page .history-item-box .history-mobile-content .history-container .history-header ul.history-rebate .rebate-amount {
  width: 4.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page {
  padding: 0 0.65rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-item-box {
  padding: 0 0 0.5rem 0;
  border-bottom: 0.01rem solid #dcdcdc;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-item-box .rebate-details li {
  display: grid;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-item-box .rebate-details .rebate-details-title {
  font-weight: 700;
  font-family: opensans-bold;
  font-size: 0.42rem;
  color: #41b850;
  height: 0.6rem;
  margin: 0 0 0.38rem 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-item-box .rebate-details .rebate-details-data {
  border-radius: 0.04rem;
  background: #f3f2f2;
  font-size: 0.42rem;
  padding: 0 0.52rem 0 0.55rem;
  height: 0.98rem;
  display: flex;
  align-items: center;
  color: #2f2f2f;
  margin: 0 0 0.37rem 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-item-box .rebate-details .rebate-claim-btn {
  cursor: pointer;
  width: 100%;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: opensans-bold;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  background: #0a974b;
  border: 0;
  margin: 0.4rem 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-item-box .rebate-details .claim-processing {
  display: flex;
  justify-content: center;
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-provider-box {
  font-size: 0.42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-provider-box .rebate-provider-title {
  font-weight: 700;
  font-family: opensans-bold;
  color: #41b850;
  height: 0.6rem;
  margin: 0.6rem 0 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-provider-box .rebate-provider-table {
  margin: 0 0 0.2rem 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-provider-box .rebate-show-button {
  display: flex;
  justify-content: center;
  padding: 0.2rem;
  color: #858585;
  text-decoration: underline;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-provider-box ul li {
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #f3f2f2;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-provider-box ul li span {
  flex: 0 0 50%;
  border: 0.01rem solid #fff;
  padding: .7em;
  display: flex;
  align-items: flex-end;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-rebase-page .rebate-provider-box ul li .title {
  color: #41b850;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-profile-page {
  padding: 0 0.65rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-profile-page .page-content {
  padding: 0 0.3rem 0.3rem;
  border-radius: 0.13rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-profile-page .page-content .profile-item-box {
  margin-bottom: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-profile-page .page-content .profile-item-box .title-item {
  font-size: 0.42rem;
  color: #4d4d4d;
  height: 0.6rem;
  margin-bottom: 0.38rem;
  padding-right: 0.53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-profile-page .page-content .profile-item-box .title-item span {
  font-family: "OpenSans-Bold";
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-profile-page .page-content .profile-item-box .title-item span .asterisk {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-profile-page .page-content .profile-item-box .profile-details {
  font-size: 0.42rem;
  min-height: 0.98rem;
  padding: 0 0.3rem;
  background-color: #e4e4e4;
  display: flex;
  align-items: center;
  border-radius: 0.13rem;
  justify-content: space-between;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-profile-page .page-content .profile-item-box .profile-details .icon-wrapper {
  width: 0.8rem;
  height: 0.8rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-profile-page .page-content .profile-item-box .profile-details .icon-wrapper svg {
  width: 0.8rem;
  height: 0.8rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page {
  padding: 0 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box {
  margin-bottom: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .title-item {
  font-size: 0.42rem;
  color: #4d4d4d;
  height: 0.6rem;
  margin-bottom: 0.38rem;
  padding-right: 0.53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .title-item span {
  font-family: "OpenSans-Bold";
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .title-item span .asterisk {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .passguide {
  font-size: 0.42rem;
  color: #41b850;
  margin-bottom: 0.38rem;
  padding-right: 0.53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .passguide span {
  font-family: "OpenSans";
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .pass-details {
  position: relative;
  font-size: 0.42rem;
  min-height: 0.98rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0.13rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .pass-details .invalid-feedback,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .pass-details>div:first-child {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .pass-details.changegame-name input {
  background: #e4e4e4;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .pass-details .standard-password-eye {
  width: 0.7rem;
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .pass-details .change-password-form-error-message-status-img {
  width: 0.3rem;
  height: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .changepass-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .changepass-button .btn-mobile-changepass {
  cursor: pointer;
  width: 5.23rem;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  background: #0a974b;
  border: 0;
  margin-top: 0.4rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .changepass-button .btn-mobile-changepass-2 {
  cursor: pointer;
  width: 5.23rem;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  background: #0a974b;
  border: 0;
  margin-top: 2.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .input-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 0.32rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .input-box .icon-box {
  width: 1.2rem;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-changepass-page .page-content .changepass-item-box .input-box .icon-box .icon-copy {
  width: 0.63rem;
  height: 0.7rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page {
  padding: 0 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .dont-show {
  display: none;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .show {
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box {
  margin-bottom: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box:first-child {
  padding-bottom: 1rem;
  margin-bottom: 0.9rem;
  border-bottom: 0.03rem solid #e8e8e8;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .title-item {
  font-size: .42rem;
  color: #4d4d4d;
  height: .6rem;
  margin-bottom: .38rem;
  padding-right: .53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .title-item>span {
  font-family: "OpenSans-Bold";
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .title-item>span .asterisk {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .otp-details {
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .otp-details input {
  width: 6rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .otp-details input::-webkit-outer-spin-button,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .otp-details input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .otp-details input[type=number] {
  -moz-appearance: textfield;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .otp-details button {
  width: 4rem;
  margin-left: 0.5rem;
  background-color: #005329;
  color: #fff;
  cursor: pointer;
  border: 0;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .otp-details button[disabled],
#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .otp-details button:disabled {
  background-color: rgba(0,147,73,.4549019608);
  cursor: not-allowed;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-addacc {
  display: flex;
  justify-content: flex-start;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-addacc .btn-addbank {
  cursor: pointer;
  width: 100%;
  height: 0.98rem;
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.42rem;
  font-family: "OpenSans-Bold";
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  background: #0a974b;
  border: 0;
  margin-top: 0.4rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard {
  display: flex;
  border-radius: 0.13rem;
  background: linear-gradient(0deg, #18723c 0%, #2f8b42 61%, #41b851 100%);
  min-height: 3.3rem;
  color: #fff;
  font-size: 0.42rem;
  margin-bottom: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-left img,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-left svg {
  width: 1.3rem;
  height: 1.3rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-left div {
  font-weight: bold;
  font-family: "OpenSans-Bold";
  text-align: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-left .bank-status.verified {
  color: #fff;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-left .bank-status.pending {
  color: #a5c6ab;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-left .bank-status.rejected {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-right {
  display: flex;
  margin: 0.3rem 0;
  flex-direction: column;
  border-left: 0.01rem solid #fff;
  width: 70%;
  padding: 0 1.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-right .bankcard-right-upper {
  margin-bottom: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-right .bankcard-right-upper div {
  min-height: 0.6rem;
  display: flex;
  align-items: center;
  word-break: break-word;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-right .delete {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-right .delete .btn-deletebank {
  width: 100%;
  font-size: 0.42rem;
  color: #fff;
  background-color: red;
  height: 0.88rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-right .delete .btn-deletebank svg {
  margin-right: 0.15rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-right .delete .delete-bank .btn-cancel {
  width: 50%;
  height: 0.88rem;
  font-size: 0.42rem;
  border: none;
  margin-right: 0.15rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .member-bankcard .bankcard-right .delete .delete-bank .btn-delete {
  width: 50%;
  height: 0.88rem;
  font-size: 0.42rem;
  border: none;
  color: #fff;
  background-color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-statement-details {
  width: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-statement-details button {
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  width: 100%;
  font-weight: 700;
  font-size: 0.42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-statement-details .reminder-note-container {
  display: flex;
  line-height: 1;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-statement-details .reminder-note-container .icon-tip {
  margin-right: 0.1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-statement-details .reminder-note-container .reminder-note {
  font-size: 0.33rem;
  color: #41b850;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-statement-details .receipt-image-box {
  display: flex;
  justify-content: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-statement-details .receipt-image-box img {
  width: 100%;
  height: 100%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box .bank-statement-details .receipt-image-box .pdf-clear {
  margin-left: 0.1rem;
  width: 0.12rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item {
  margin-top: 0.93rem;
  font-size: 0.42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .address-box-title {
  background: #d7f2da;
  color: #005329;
  padding: 0.18rem 0.29rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0.27rem;
  border-bottom: 0.03rem solid #e8e8e8;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address .address-left {
  width: 6rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address .address-left span {
  color: #41b850;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address .address-left div {
  word-wrap: break-word;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address .address-right {
  width: 3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address .address-right .delete .btn-deleteAddress {
  background: unset;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address .address-right .delete .delete-address {
  flex-direction: column;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address .address-right .delete .delete-address button {
  height: 0.88rem;
  font-size: 0.42rem;
  border: none;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address .address-right .delete .delete-address button:not(:last-child) {
  margin-bottom: 0.2rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-bankdetails-page .page-content .bankdetail-item-box.address-item .member-address .address-right .delete .delete-address button.btn-delete {
  color: #fff;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page {
  padding: 0 1rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .show {
  display: flex;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .hide {
  display: none;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page ul.page-numbers li {
  width: 0.8rem;
  height: 0.8rem;
  border: 0.01rem solid #f5f5f5;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .msg-item-box {
  margin-bottom: 0.5rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .msg-item-box .title-item {
  font-size: 0.42rem;
  color: #4d4d4d;
  height: 0.6rem;
  margin-bottom: 0.38rem;
  padding-right: 0.53rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .msg-item-box .title-item>span {
  font-family: "OpenSans-Bold";
  font-weight: bold;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .msg-item-box .title-item>span .asterisk {
  color: red;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .msg-item-box .msg-filter-box {
  font-size: 0.42rem;
  padding: 0.28rem 0.52rem;
  color: #b3b3b3;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .msg-item-box .msg-filter-box.active {
  color: #41b850;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .msg-item-box .react-dropdown-select-content span {
  position: relative;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-header {
  background: #d7f1db;
  min-height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.6rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-header .message-checkbox {
  display: flex;
  align-items: center;
  color: #075129;
  font-size: 0.42rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-header .message-checkbox input {
  min-width: 0.5rem;
  min-height: 0.5rem;
  max-width: 0.5rem;
  max-height: 0.5rem;
  padding: 0;
  background-color: #fff;
  border: 0.01rem solid #29c04a;
  margin-right: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-header .message-checkbox input[type=checkbox]:checked:after {
  background-image: url("/public/html/images/common_img/tick-icon.svg");
  color: #29c04a;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-header .message-checkbox input[type=checkbox]:after {
  content: "";
  color: #fff;
  display: flex;
  align-items: center;
  margin: auto;
  width: 0.35rem;
  height: 0.39rem;
  background-repeat: no-repeat;
  background-position: center 0.05rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-header>div {
  display: flex;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-header>div>div {
  display: flex;
  align-items: center;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-header svg {
  fill: #075129;
  width: 0.6rem;
  height: 0.6rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-header svg:first-child {
  height: 0.55rem;
  margin-right: 0.4rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item {
  padding: 0.5rem 0.6rem;
  display: flex;
  min-height: 1.9rem;
  align-items: center;
  border-bottom: 0.01rem solid #e4e4e4;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msgitem-checkbox {
  width: 15%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msgitem-checkbox input {
  min-width: 0.5rem;
  min-height: 0.5rem;
  max-width: 0.5rem;
  max-height: 0.5rem;
  padding: 0;
  background-color: #fff;
  border: 0.01rem solid #29c04a;
  margin-right: 0.3rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msgitem-checkbox input[type=checkbox]:checked:after {
  background-image: url("/public/html/images/common_img/tick-icon.svg");
  color: #29c04a;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msgitem-checkbox input[type=checkbox]:after {
  content: "";
  color: #fff;
  display: flex;
  align-items: center;
  margin: auto;
  width: 0.35rem;
  height: 0.39rem;
  background-repeat: no-repeat;
  background-position: center 0.05rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msgSubject {
  width: 45%;
  font-size: 0.48rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0a974b;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msgSubject.msg-read {
  opacity: .5;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .flag-delete,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msg-cancel-delete {
  width: 40%;
  align-items: center;
  justify-content: flex-end;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .flag-delete .msg-unflag,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .flag-delete .msg-flagged,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msg-cancel-delete .msg-unflag,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msg-cancel-delete .msg-flagged {
  width: 0.55rem;
  height: 0.75rem;
  margin-right: 0.3rem;
  opacity: .3;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .flag-delete .msg-flagged,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msg-cancel-delete .msg-flagged {
  opacity: 1;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .flag-delete .msg-delete,
#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msg-cancel-delete .msg-delete {
  height: 0.75rem;
  width: 0.7rem;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msg-cancel-delete .btn-cancel {
  height: 0.88rem;
  font-size: 0.36rem;
  border: none;
  margin-right: 0.15rem;
  width: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.wap-message-page .page-content .message-item-box .message-item .msg-cancel-delete .btn-delete {
  background: red;
  height: 0.88rem;
  font-size: 0.36rem;
  border: none;
  color: #fff;
  width: 50%;
}

#root .wap .wap .wap-pages-out .wap-pages>div.page-slide-exit>h1 {
  display: none !important;
}

#root .wap .wap .wap-pages-out .wap-pages #bankbranch,
#root .wap .wap .wap-pages-out .wap-pages #bankName {
  background: #fff;
  border: 0.05rem solid #f3f2f2;
}

#root .wap .wap .wap-pages-out .wap-pages #bankbranch[disabled],
#root .wap .wap .wap-pages-out .wap-pages #bankName[disabled] {
  background: #f3f2f2;
  border: none;
}

@media screen and (min-aspect-ratio: 12/10) {
  html #root .wap {
    font-size: 4.5289857054vw !important;
  }

  html #root .wap .wap-navbar ul {
    justify-content: space-around;
  }

  html #root .wap .wap-pages-out .wap-pages {
    position: relative;
    width: 12.42rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.react-dropdown-select-input {
  margin-left: 0 !important;
}

.bg-transparent {
  background: rgba(0,0,0,0) !important;
}

.specialCodeValid {
  color: #6ac259;
}

.specialCodeInvalid {
  color: #e05161;
}

.test {
  width: 3.54rem;
}

.hidden {
  display: none;
}

.border-none,
.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child .sort-item,
.slots .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child .sort-item,
.sort-box li:last-child .sort-item {
  border: none !important;
}

.icon-back {
  display: inline-block;
  background: url("../assets/back.png") no-repeat;
  width: 0.66rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-restore {
  display: inline-block;
  width: 0.28rem;
  height: 0.29rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-lighting {
  display: inline-block;
  background: url("../assets/lighting.png") no-repeat;
  width: 0.63rem;
  height: 0.53rem;
  background-size: 100% 100%;
}

.icon-logo {
  display: inline-block;
  max-width: 2.28rem;
  max-height: 0.8rem;
  width: 100%;
  height: auto;
}

.icon-logo-min {
  display: inline-block;
  background: url("../assets/logo.png") no-repeat;
  width: 0.36rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-close {
  display: inline-block;
  width: 0.31rem;
  height: 0.31rem;
  background-size: 100% 100%;
  cursor: pointer;
  fill: #fff;
}

.icon-close2 {
  display: inline-block;
  background: url("../assets/close2.png") no-repeat;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-pngo-banner {
  display: inline-block;
  background: url("../assets/pngo_banner.png") no-repeat;
  width: 1.87rem;
  height: 0.51rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-sg-logo {
  display: inline-block;
  background: url("../assets/sg_logo.png") no-repeat;
  width: 1.95rem;
  height: 0.33rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-chat {
  display: inline-block;
  background: url("../assets/chat.png") no-repeat;
  width: 0.37rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-chat-white {
  display: inline-block;
  background: url("../assets/chat_white.png") no-repeat;
  width: 0.37rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-lan {
  display: inline-block;
  background: url("../assets/language.png") no-repeat;
  width: 0.22rem;
  height: 0.23rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-lan-white {
  display: inline-block;
  background: url("../assets/language_white.png") no-repeat;
  width: 0.22rem;
  height: 0.23rem;
  background-size: 100% 100%;
}

.icon-flag {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.lang-choice>div:last-child .divide {
  display: none;
}

.icon-en {
  display: inline-block;
  background: url("../assets/malysia.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-thai {
  display: inline-block;
  background: url("../assets/thai.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-idr {
  display: inline-block;
  background: url("../assets/idr.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-game-menu {
  display: inline-block;
  width: 0.28rem;
  height: 0.16rem;
  background-size: 100% 100%;
  fill: #dabc6f;
}

.icon-raffle {
  display: inline-block;
  width: 0.45rem;
  height: 0.29rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-evo {
  display: inline-block;
  width: 0.8rem;
  height: 0.39rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-download {
  display: inline-block;
  width: 0.25rem;
  height: 0.36rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-contact {
  display: inline-block;
  width: 0.33rem;
  height: 0.33rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-arrow-down {
  display: inline-block;
  width: 0.09rem;
  height: 0.06rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-arrow-big {
  display: inline-block;
  background-image: url("../assets/arrow_big.svg");
  background-repeat: no-repeat;
  width: 0.53rem;
  height: 0.53rem;
  background-size: 100% 100%;
  pointer-events: auto;
  cursor: pointer;
  background-color: rgba(240,240,240,.9);
  border-radius: 50%;
  padding: 0.18rem;
}

.icon-arrow-big.left {
  transform: rotate(180deg);
}

.icon-love {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-love-on {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-love2 {
  display: inline-block;
  background: url("../assets/love.png") no-repeat;
  width: 0.38rem;
  height: 0.32rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-SG {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-PT,
.icon-PT3,
.icon-PT2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-YBL {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ybl.png") no-repeat;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-lucky {
  display: inline-block;
  background: url("../assets/lucky_bomber.png") no-repeat;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-TTG {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-SA {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-GP {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-BINL2,
.icon-BINS2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-PP,
.icon-PP-slot {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-AG,
.icon-AG2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-UG,
.icon-AES,
.icon-AES2 {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ug.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-EVO {
  display: inline-block;
  background: url("/public/html/images/home_img/096-evo.svg") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-EBET {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ebet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-MGP {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-JKR {
  display: inline-block;
  width: 0.4rem;
  height: 0.09rem;
  background-size: 100% 100%;
}

.icon-NS,
.icon-NS-slot {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-PGS,
.icon-FG,
.icon-MT,
.icon-YGR,
.icon-V8,
.icon-KM,
.icon-KM2 {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-PG {
  display: inline-block;
  width: 0.36rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-UL {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ul.png") no-repeat;
  width: 0.42rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-SCR2 {
  display: inline-block;
  background: url("/public/html/images/home_img/096-scr.png") no-repeat;
  width: 0.4rem;
  height: 0.18rem;
  background-size: 100% 100%;
}

.icon-JL {
  display: inline-block;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-PSY8 {
  display: inline-block;
  width: 0.4rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-MEGA {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-HC {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-HB {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-NT {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-RT {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-CQ9 {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-JDB,
.icon-JDB2 {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-YGG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-AP {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-DS,
.icon-DS-slot,
.icon-EP,
.icon-SW,
.icon-R88,
.icon-R88-slot,
.icon-WESLT,
.icon-WESLT-slot {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-SPX {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-DRG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-RG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-PS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-FC {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-SPB {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-T1G,
.icon-VP,
.icon-MTV {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-EXS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-FS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-hot {
  display: inline-block;
  background: url("../assets/hot.png") no-repeat;
  width: 0.67rem;
  height: 0.68rem;
  background-size: 100% 100%;
}

.icon-new {
  display: inline-block;
  background: url("../assets/new.png") no-repeat;
  width: 0.67rem;
  height: 0.68rem;
  background-size: 100% 100%;
}

.icon-new-green {
  display: inline-block;
  background: url("../assets/new_green.png") no-repeat;
  width: 0.79rem;
  height: 0.79rem;
  background-size: 100% 100%;
}

.icon-transfer-918kiss {
  display: inline-block;
  background: url("../assets/transfer/918kiss.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ae_sexy {
  display: inline-block;
  background: url("../assets/transfer/ae_sexy.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ag_gaming {
  display: inline-block;
  background: url("../assets/transfer/ag_gaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-allbet {
  display: inline-block;
  background: url("../assets/transfer/allbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-allbet2 {
  display: inline-block;
  background: url("../assets/transfer/allbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-betrader {
  display: inline-block;
  background: url("../assets/transfer/betrader.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bk8 {
  display: inline-block;
  background: url("../assets/transfer/bk8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-cmd368 {
  display: inline-block;
  background: url("../assets/transfer/cmd368.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-m8 {
  display: inline-block;
  background: url("../assets/transfer/m8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-kingmaker {
  display: inline-block;
  background: url("../assets/transfer/kingmaker.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-dreamgaming {
  display: inline-block;
  background: url("../assets/transfer/dreamgaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ebet {
  display: inline-block;
  background: url("../assets/transfer/ebet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-evo {
  display: inline-block;
  background: url("../assets/transfer/evo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gameplay {
  display: inline-block;
  background: url("../assets/transfer/gameplay.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gd {
  display: inline-block;
  background: url("../assets/transfer/gd.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jkr {
  display: inline-block;
  background: url("../assets/transfer/096-jkr-icon.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-hongchow {
  display: inline-block;
  background: url("../assets/transfer/hongchow.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-funkygames {
  display: inline-block;
  background: url("../assets/transfer/funkygames.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-minited {
  display: inline-block;
  background: url("../assets/transfer/minited.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pgsoft {
  display: inline-block;
  background: url("../assets/transfer/pgsoft.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-yesgetrich {
  display: inline-block;
  background: url("../assets/transfer/yesgetrich.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jili {
  display: inline-block;
  background: url("../assets/transfer/jili.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gg_gaming {
  display: inline-block;
  background: url("../assets/transfer/gg_gaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-idn_poker {
  display: inline-block;
  background: url("../assets/transfer/idn_poker.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-im {
  display: inline-block;
  background: url("../assets/transfer/im.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bti {
  display: inline-block;
  background: url("../assets/transfer/bti.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-maxbet {
  display: inline-block;
  background: url("../assets/transfer/maxbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-drg {
  display: inline-block;
  background: url("../assets/transfer/drg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-w {
  display: inline-block;
  background: url("../assets/transfer/w.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-we {
  display: inline-block;
  background: url("../assets/transfer/we.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-we.icon-custom-transfer-Maxim88Casino {
  background: url("../assets/transfer/we_rebrand.png") no-repeat;
  background-size: 100% 100%;
}

.icon-transfer-we.icon-custom-transfer-Maxim88 {
  background: url("../assets/transfer/we_rebrand.png") no-repeat;
  background-size: 100% 100%;
}

.icon-transfer-mega888 {
  display: inline-block;
  background: url("../assets/transfer/mega888.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-mg {
  display: inline-block;
  background: url("../assets/transfer/mg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ybl {
  display: inline-block;
  background: url("../assets/transfer/ybl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-PP {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-PPL {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-psy8 {
  display: inline-block;
  background: url("../assets/transfer/psy8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ns {
  display: inline-block;
  background: url("../assets/transfer/nextspin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-nextspin {
  display: inline-block;
  background: url("../assets/transfer/nextspin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pngo {
  display: inline-block;
  background: url("../assets/transfer/pngo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pp {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pt_logo {
  display: inline-block;
  background: url("../assets/transfer/pt_logo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-qq {
  display: inline-block;
  background: url("../assets/transfer/qq.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-winwinlottery {
  display: inline-block;
  background: url("../assets/transfer/wwl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-shicai {
  display: inline-block;
  background: url("../assets/transfer/gw.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tfg {
  display: inline-block;
  background: url("../assets/transfer/tf.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-v8poker {
  display: inline-block;
  background: url("../assets/transfer/v8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sa {
  display: inline-block;
  background: url("../assets/transfer/sa.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sbo {
  display: inline-block;
  background: url("../assets/transfer/sbo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-hb {
  display: inline-block;
  background: url("../assets/transfer/hb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spade {
  display: inline-block;
  background: url("../assets/transfer/spade.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ttg {
  display: inline-block;
  background: url("../assets/transfer/ttg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ultimate {
  display: inline-block;
  background: url("../assets/transfer/ultimate.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-wm {
  display: inline-block;
  background: url("../assets/transfer/wm.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-rt {
  display: inline-block;
  background: url("../assets/transfer/rt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-nt {
  display: inline-block;
  background: url("../assets/transfer/nt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-cq9 {
  display: inline-block;
  background: url("../assets/transfer/cq9.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-fachai {
  display: inline-block;
  background: url("../assets/transfer/fc.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-granddragon {
  display: inline-block;
  background: url("../assets/transfer/gdl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-digmaan {
  display: inline-block;
  background: url("../assets/transfer/dig.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tcg {
  display: inline-block;
  background: url("../assets/transfer/tcg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tcga {
  display: inline-block;
  background: url("../assets/transfer/tcg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ws {
  display: inline-block;
  background: url("../assets/transfer/ws.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-binl2 {
  display: inline-block;
  background: url("../assets/transfer/bbin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bins2 {
  display: inline-block;
  background: url("../assets/transfer/bbin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spb {
  display: inline-block;
  background: url("../assets/transfer/spb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-t1g {
  display: inline-block;
  background: url("../assets/transfer/t1g.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-mtv {
  display: inline-block;
  background: url("../assets/transfer/mtv.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bg {
  display: inline-block;
  background: url("../assets/transfer/bg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jdb {
  display: inline-block;
  background: url("../assets/transfer/jdb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jdb2 {
  display: inline-block;
  background: url("../assets/transfer/jdb2.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ds {
  display: inline-block;
  background: url("../assets/transfer/ds.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ep {
  display: inline-block;
  background: url("../assets/transfer/ep.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-rg {
  display: inline-block;
  background: url("../assets/transfer/rg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ps {
  display: inline-block;
  background: url("../assets/transfer/ps.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ygg {
  display: inline-block;
  background: url("../assets/transfer/ygg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ap {
  display: inline-block;
  background: url("../assets/transfer/ap.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spx {
  display: inline-block;
  background: url("../assets/transfer/spx.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sw {
  display: inline-block;
  background: url("../assets/transfer/sw.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-r88 {
  display: inline-block;
  background: url("../assets/transfer/r88.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-weslt {
  display: inline-block;
  background: url("../assets/transfer/weslt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-exs {
  display: inline-block;
  background: url("../assets/transfer/exs.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-vp {
  display: inline-block;
  background: url("../assets/transfer/vp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-vc {
  display: inline-block;
  background: url("../assets/transfer/vc.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-fs {
  display: inline-block;
  background: url("../assets/transfer/fs.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-box.bg-white {
  width: 0.3rem;
  height: 0.3rem;
  background: #fff;
  border-radius: 50%;
}

.icon-box>i {
  overflow: visible;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-logout {
  display: inline-block;
  background: url("../assets/menu/login.png") no-repeat;
  width: 0.28rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-home {
  display: inline-block;
  width: 0.33rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-918kiss {
  display: inline-block;
  background: url("../assets/menu/918kiss.png") no-repeat;
  width: 0.45rem;
  height: 0.24rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.icon-slots {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-casino {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-favourite {
  display: inline-block;
  width: 0.3rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.icon-fishing {
  display: inline-block;
  width: 0.36rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-poker {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-e-sport {
  display: inline-block;
  width: 0.36rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-promotion {
  display: inline-block;
  width: 0.36rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-sports {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-vip {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-meda {
  display: inline-block;
  background: url("../assets/meda.png") no-repeat;
  width: 0.32rem;
  height: 0.47rem;
  background-size: 100% 100%;
}

.icon-curaccao {
  display: inline-block;
  background: url("../assets/brand/curaccao-gray.svg") no-repeat;
  width: 1rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-curaccao:hover {
  background: url("../assets/brand/curaccao.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-pagcor {
  display: inline-block;
  background: url("../assets/brand/pagcor-gray.png") no-repeat;
  width: 1.25rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-pagcor:hover {
  background: url("../assets/brand/pagcor.png") no-repeat;
  background-size: 100% 100%;
}

.icon-iovation {
  display: inline-block;
  background: url("../assets/brand/iovation-gray.png") no-repeat;
  width: 1.19rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-iovation:hover {
  background: url("../assets/brand/iovation.png") no-repeat;
  background-size: 100% 100%;
}

.icon-tm {
  display: inline-block;
  background: url("../assets/brand/threatmetrix-gray.svg") no-repeat;
  width: 1.19rem;
  height: 0.32rem;
  background-size: 100% 100%;
}

.icon-tm:hover {
  background: url("../assets/brand/threatmetrix.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-bmm {
  display: inline-block;
  background: url("../assets/brand/bmm-gray.png") no-repeat;
  width: 0.55rem;
  height: 0.17rem;
  background-size: 100% 100%;
}

.icon-bmm:hover {
  background: url("../assets/brand/bmm.png") no-repeat;
  background-size: 100% 100%;
}

.icon-itechlab {
  display: inline-block;
  background: url("../assets/brand/itechlab-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.39rem;
  background-size: 100% 100%;
}

.icon-itechlab:hover {
  background: url("../assets/brand/itechlab.png") no-repeat;
  background-size: 100% 100%;
}

.icon-global {
  display: inline-block;
  background: url("../assets/brand/global-gray.png") no-repeat;
  width: 0.48rem;
  height: 0.48rem;
  background-size: 100% 100%;
}

.icon-global:hover {
  background: url("../assets/brand/global.png") no-repeat;
  background-size: 100% 100%;
}

.icon-godaddy {
  display: inline-block;
  background: url("../assets/brand/godaddy-gray.png") no-repeat;
  width: 1.35rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-godaddy:hover {
  background: url("../assets/brand/godaddy.png") no-repeat;
  background-size: 100% 100%;
}

.icon-payouts {
  display: inline-block;
  background: url("../assets/brand/payouts-gray.png") no-repeat;
  width: 0.83rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-payouts:hover {
  background: url("../assets/brand/payouts.png") no-repeat;
  background-size: 100% 100%;
}

.icon-youtube {
  display: inline-block;
  background: url("../assets/brand/youtube-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-youtube:hover {
  background: url("../assets/brand/youtube.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-eeziepay {
  display: inline-block;
  background: url("../assets/brand/eeziepay-gray.png") no-repeat;
  width: 0.77rem;
  height: 0.32rem;
  background-size: 100% 100%;
  margin: 0 0.05rem;
}

.icon-eeziepay:hover {
  background: url("../assets/brand/eeziepay.png") no-repeat;
  background-size: 100% 100%;
}

.icon-paytrust {
  display: inline-block;
  background: url("../assets/brand/paytrust-gray.png") no-repeat;
  width: 0.9rem;
  height: 0.19rem;
  background-size: 100% 100%;
}

.icon-paytrust:hover {
  background: url("../assets/brand/paytrust.png") no-repeat;
  background-size: 100% 100%;
}

.icon-help2pay {
  display: inline-block;
  background: url("../assets/brand/help2pay-gray.png") no-repeat;
  width: 0.4rem;
  height: 0.3rem;
  background-size: 100% 100%;
  margin: 0 0.05rem;
}

.icon-help2pay:hover {
  background: url("../assets/brand/help2pay.png") no-repeat;
  background-size: 100% 100%;
}

.icon-bitcoin {
  display: inline-block;
  background: url("../assets/brand/bitcoin-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-bitcoin:hover {
  background: url("../assets/brand/bitcoin.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-usdt {
  display: inline-block;
  background: url("../assets/brand/usdt-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-usdt:hover {
  background: url("../assets/brand/usdt.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-paynow {
  display: inline-block;
  background: url("../assets/brand/paynow-gray.svg") no-repeat;
  width: 0.86rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-paynow:hover {
  background: url("../assets/brand/paynow.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-paynow-v2 {
  display: inline-block;
  background: url("../assets/brand/paynow-gray-v2.svg") no-repeat;
  width: 0.6rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-paynow-v2:hover {
  background: url("../assets/brand/paynow-v2.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-eth {
  display: inline-block;
  background: url("../assets/brand/eth-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-eth:hover {
  background: url("../assets/brand/eth-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-duitnow {
  display: inline-block;
  background: url("../assets/brand/duitnow-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-duitnow:hover {
  background: url("../assets/brand/duitnow-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-instantpay {
  display: inline-block;
  background: url("../assets/brand/instantpay-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-instantpay:hover {
  background: url("../assets/brand/instantpay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-tng {
  display: inline-block;
  background: url("../assets/brand/tng-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-tng:hover {
  background: url("../assets/brand/tng-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-grab {
  display: inline-block;
  background: url("../assets/brand/grabpay-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-grab:hover {
  background: url("../assets/brand/grabpay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-boost {
  display: inline-block;
  background: url("../assets/brand/boost-logo-of.svg") no-repeat;
  width: 0.85rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-boost:hover {
  background: url("../assets/brand/boost-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-shopee {
  display: inline-block;
  background: url("../assets/brand/shopee-logo-of.svg") no-repeat;
  width: 0.75rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-shopee:hover {
  background: url("../assets/brand/shopee-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-truepay {
  display: inline-block;
  background: url("../assets/brand/truepay-logo-of.svg") no-repeat;
  width: 1rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-truepay:hover {
  background: url("../assets/brand/truepay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-surepay {
  display: inline-block;
  background: url("../assets/brand/surepay-logo-of.svg") no-repeat;
  width: 1rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-surepay:hover {
  background: url("../assets/brand/surepay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-notice {
  display: inline-block;
  width: 2.32rem;
  height: 0.2rem;
  background-size: 100% 100%;
  max-width: 0.55rem;
}

.icon-allbet {
  display: inline-block;
  background: url("../assets/casino/allbet.png") no-repeat;
  width: 1.01rem;
  height: 0.59rem;
  background-size: 100% 100%;
}

.icon-asiagaming {
  display: inline-block;
  background: url("../assets/casino/asiagaming.png") no-repeat;
  width: 1.22rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-dreamgaming {
  display: inline-block;
  background: url("../assets/casino/dreamgaming.png") no-repeat;
  width: 0.96rem;
  height: 0.58rem;
  background-size: 100% 100%;
}

.icon-evolution {
  display: inline-block;
  background: url("../assets/casino/evolution.png") no-repeat;
  width: 1.12rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-gameplay {
  display: inline-block;
  background: url("../assets/casino/gameplay.png") no-repeat;
  width: 1.46rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-gd {
  display: inline-block;
  background: url("../assets/casino/gd.png") no-repeat;
  width: 1.08rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-microgaming {
  display: inline-block;
  background: url("../assets/casino/microgaming.png") no-repeat;
  width: 1.53rem;
  height: 0.38rem;
  background-size: 100% 100%;
}

.icon-playtech {
  display: inline-block;
  background: url("../assets/casino/playtech.png") no-repeat;
  width: 1.43rem;
  height: 0.31rem;
  background-size: 100% 100%;
}

.icon-sa-gaming {
  display: inline-block;
  background: url("../assets/casino/sa_gaming.png") no-repeat;
  width: 1.22rem;
  height: 0.46rem;
  background-size: 100% 100%;
}

.icon-sexy {
  display: inline-block;
  background: url("../assets/casino/sexy.png") no-repeat;
  width: 1.01rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.icon-username,
.icon-myprofile {
  display: inline-block;
  width: 0.2rem;
  height: 0.28rem;
  background-size: 100% 100%;
  z-index: 2;
}

.icon-lock {
  display: inline-block;
  width: 0.22rem;
  height: 0.27rem;
  background-size: 100% 100%;
  z-index: 2;
}

.icon-changepassword {
  display: inline-block;
  width: 0.26rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-changegamepassword {
  display: inline-block;
  width: 0.26rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-bankdetail {
  display: inline-block;
  width: 0.35rem;
  height: 0.32rem;
  background-size: 100% 100%;
}

.icon-referral {
  display: inline-block;
  width: 0.35rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-search {
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  background-size: 100% 100%;
}

.icon-arrow-down2 {
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  background-size: 100% 100%;
  fill: #000;
}

.icon-messaging {
  display: inline-block;
  width: 0.28rem;
  height: 0.21rem;
  background-size: 100% 100%;
}

.icon-reload {
  display: inline-block;
  width: 0.25rem;
  height: 0.27rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-reload.start {
  animation: rotation 1s linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.icon-reload2 {
  display: inline-block;
  background: url("../assets/reload2.png") no-repeat;
  width: 0.25rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-reload2.start {
  animation: rotation 1s linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.icon-fund {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-fund2 {
  display: inline-block;
  background: url("../assets/fund_icon2.png") no-repeat;
  background-size: 100% 100%;
}

.icon-deposit {
  display: inline-block;
  width: 0.3rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.icon-report {
  display: inline-block;
  width: 0.26rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.icon-transfer {
  display: inline-block;
  width: 0.35rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-withdraw {
  display: inline-block;
  width: 0.29rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-start {
  display: inline-block;
  background: url("../assets/start.png") no-repeat;
  width: 0.3rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-start2 {
  display: inline-block;
  background: url("../assets/start2.png") no-repeat;
  width: 0.17rem;
  height: 0.15rem;
  background-size: 100% 100%;
}

.icon-on {
  display: inline-block;
  background: url("../assets/on.png") no-repeat;
  width: 0.6rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-off {
  display: inline-block;
  background: url("../assets/off.png") no-repeat;
  width: 0.6rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-sapphire {
  display: inline-block;
  width: 0.63rem;
  height: 0.43rem;
  object-fit: contain;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-ruby {
  display: inline-block;
  background: url("../assets/ruby.png") no-repeat;
  width: 0.71rem;
  height: 0.56rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-diamond {
  display: inline-block;
  background: url("../assets/diamond.png") no-repeat;
  width: 0.71rem;
  height: 0.56rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-transfer-btn {
  display: inline-block;
  background: url("../assets/transfer_btn.png") no-repeat;
  width: 0.2rem;
  height: 0.17rem;
  background-size: 100% 100%;
}

.icon-transfer-to {
  display: inline-block;
  background: url("../assets/transfer_to.png") no-repeat;
  width: 0.17rem;
  height: 0.11rem;
  background-size: 100% 100%;
}

.icon-checked {
  display: inline-block;
  background: url("../assets/checked.png") no-repeat;
  width: 0.24rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-tip {
  display: inline-block;
  background: url("../assets/tip.png") no-repeat;
  width: 0.18rem;
  height: 0.18rem;
  background-size: 100% 100%;
}

.icon-notice-important {
  display: inline-block;
  background: url("../assets/important_notice.svg") no-repeat;
  width: 0.29rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-affin {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-affin-gray.png") no-repeat;
  width: 0.5rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-affin:hover,
.icon-affin.on {
  background: url("/public/html/images/bank_img/096-affin.png") no-repeat;
  background-size: 100% 100%;
}

.icon-alliance {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-alliance-gray.png") no-repeat;
  width: 0.43rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-alliance:hover,
.icon-alliance.on {
  background: url("/public/html/images/bank_img/096-alliance.png") no-repeat;
  background-size: 100% 100%;
}

.icon-bsn {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-bsn-gray.png") no-repeat;
  width: 0.36rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-bsn:hover,
.icon-bsn.on {
  background: url("/public/html/images/bank_img/096-bsn.png") no-repeat;
  background-size: 100% 100%;
}

.icon-cimb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-cimb-gray.png") no-repeat;
  width: 0.34rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-cimb:hover,
.icon-cimb.on {
  background: url("/public/html/images/bank_img/096-cimb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-citi {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-citi-gray.png") no-repeat;
  width: 0.36rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-citi:hover,
.icon-citi.on {
  background: url("/public/html/images/bank_img/096-citi.png") no-repeat;
  background-size: 100% 100%;
}

.icon-hlb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-hlb-gray.png") no-repeat;
  width: 0.28rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-hlb:hover,
.icon-hlb.on {
  background: url("/public/html/images/bank_img/096-hlb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-islam {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-islam-gray.png") no-repeat;
  width: 0.45rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-islam:hover,
.icon-islam.on {
  background: url("/public/html/images/bank_img/096-islam.png") no-repeat;
  background-size: 100% 100%;
}

.icon-mbb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-mbb-gray.png") no-repeat;
  width: 0.33rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-mbb:hover,
.icon-mbb.on {
  background: url("/public/html/images/bank_img/096-mbb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-pbe {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-pbe-gray.png") no-repeat;
  width: 0.26rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.icon-pbe:hover,
.icon-pbe.on {
  background: url("/public/html/images/bank_img/096-pbe.png") no-repeat;
  background-size: 100% 100%;
}

.icon-rhb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-rhb-gray.png") no-repeat;
  width: 0.57rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-rhb:hover,
.icon-rhb.on {
  background: url("/public/html/images/bank_img/096-rhb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-amb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-amb-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-amb:hover,
.icon-amb.on {
  background: url("/public/html/images/bank_img/096-amb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-uob {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-uob-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-uob:hover,
.icon-uob.on {
  background: url("/public/html/images/bank_img/096-uob.png") no-repeat;
  background-size: 100% 100%;
}

.icon-rakyat {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-br-gray.png") no-repeat;
  width: 0.39rem;
  height: 0.42rem;
  background-size: 100% 100%;
}

.icon-rakyat:hover,
.icon-rakyat.on {
  background: url("/public/html/images/bank_img/096-br.png") no-repeat;
  background-size: 100% 100%;
}

.icon-ocbc {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-ocbc-gray.png") no-repeat;
  width: 0.37rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-ocbc:hover,
.icon-ocbc.on {
  background: url("/public/html/images/bank_img/096-ocbc.png") no-repeat;
  background-size: 100% 100%;
}

.icon-hsbc {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-hsbc-gray.png") no-repeat;
  width: 0.47rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-hsbc:hover,
.icon-hsbc.on {
  background: url("/public/html/images/bank_img/096-hsbc.png") no-repeat;
  background-size: 100% 100%;
}

.icon-scb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-scb-gray.png") no-repeat;
  width: 0.47rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-scb:hover,
.icon-scb.on {
  background: url("/public/html/images/bank_img/096-scb.png") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-home {
  background: url("/public/html/images/home_img/home.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-casino {
  background: url("/public/html/images/home_img/casino.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-918kiss {
  background: url("/public/html/images/home_img/918kiss.svg") no-repeat;
  background-size: 100% 100%;
  -webkit-backface-visibility: unset;
          backface-visibility: unset;
}

.footer-bottom .icon-slots {
  background: url("/public/html/images/home_img/slots.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-sports {
  background: url("/public/html/images/home_img/sports.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-e-sport {
  background: url("/public/html/images/home_img/e-sport.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-fishing {
  background: url("/public/html/images/home_img/fishing.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-poker {
  background: url("/public/html/images/home_img/poker.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-favourite {
  background: url("/public/html/images/home_img/favourite.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-promotion {
  background: url("/public/html/images/home_img/promo.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-vip {
  background: url("/public/html/images/home_img/vip.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-raffle {
  background: url("/public/html/images/home_img/raffle.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-download {
  background: url("/public/html/images/home_img/download.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-contact {
  background: url("/public/html/images/home_img/contact.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-lan-white {
  background: url("/public/html/images/home_img/language.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-logout {
  background: url("/public/html/images/home_img/logout.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-home {
  background: url("/public/html/images/home_img/home-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-casino {
  background: url("/public/html/images/home_img/casino-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-918kiss {
  background: url("/public/html/images/home_img/918kiss-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-slots {
  background: url("/public/html/images/home_img/slots-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-sports {
  background: url("/public/html/images/home_img/sports-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-e-sport {
  background: url("/public/html/images/home_img/e-sport-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-fishing {
  background: url("/public/html/images/home_img/fishing-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-poker {
  background: url("/public/html/images/home_img/poker-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-favourite {
  background: url("/public/html/images/home_img/favourite-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-promotion {
  background: url("/public/html/images/home_img/promo-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-vip {
  background: url("/public/html/images/home_img/vip-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-raffle {
  background: url("/public/html/images/home_img/raffle-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-download {
  background: url("/public/html/images/home_img/download-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-contact {
  background: url("/public/html/images/home_img/contact-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-lan-white {
  background: url("/public/html/images/home_img/language-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-logout {
  background: url("/public/html/images/home_img/logout-on.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-wap-menu {
  cursor: pointer;
  display: inline-block;
  width: 0.69rem;
  height: 0.46rem;
  background-size: 100% 100%;
  fill: #dabc6f;
}

.icon-wap-back {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/back.png") no-repeat;
  width: 0.6rem;
  height: 0.42rem;
  background-size: 100% 100%;
}

.icon-wap-eye {
  cursor: pointer;
  display: inline-block;
  width: 0.85rem;
  height: 0.58rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-wap-eye.off {
  background-size: 100% 100%;
  fill: #02532b;
}

.icon-wap-auto {
  border: none;
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/on.png") no-repeat;
  width: 1.54rem;
  height: 0.52rem;
  background-size: 100% 100%;
}

.icon-wap-auto.off {
  background: url("../assets/wap/off.png") no-repeat;
  background-size: 100% 100%;
}

.icon-wap-chat {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/chat.png") no-repeat;
  width: 0.72rem;
  height: 0.72rem;
  background-size: 100% 100%;
}

.icon-wap-message {
  cursor: pointer;
  display: inline-block;
  background-size: 100% 100%;
}

.icon-wap-message svg {
  width: 0.75rem;
  height: 0.58rem;
  fill: #fff;
}

.icon-wap-messaging {
  cursor: pointer;
  display: inline-block;
  width: 0.75rem;
  height: 0.58rem;
  background-size: 100% 100%;
}

.icon-wap-messaging.on {
  background-size: 100% 100%;
}

.icon-wap-profile {
  cursor: pointer;
  display: inline-block;
  width: 0.65rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-profile.on {
  background-size: 100% 100%;
}

.icon-wap-changepassword {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-changepassword.on {
  background-size: 100% 100%;
}

.icon-wap-changegamepassword {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-changegamepassword.on {
  background-size: 100% 100%;
}

.icon-wap-referral {
  cursor: pointer;
  display: inline-block;
  width: 1.1rem;
  height: 0.7rem;
  background-size: 100% 100%;
}

.icon-wap-referral.on {
  background-size: 100% 100%;
}

.icon-wap-bankdetail {
  cursor: pointer;
  display: inline-block;
  width: 0.95rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-bankdetail.on {
  background-size: 100% 100%;
}

.icon-wap-deposit {
  cursor: pointer;
  display: inline-block;
  width: 0.97rem;
  height: 1.08rem;
  background-size: 100% 100%;
}

.icon-wap-transfer {
  cursor: pointer;
  display: inline-block;
  width: 1.14rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-withdraw {
  cursor: pointer;
  display: inline-block;
  width: 0.95rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-report {
  cursor: pointer;
  display: inline-block;
  width: 0.84rem;
  height: 0.82rem;
  background-size: 100% 100%;
}

.icon-wap-home {
  cursor: pointer;
  display: inline-block;
  width: 1.01rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-funds {
  cursor: pointer;
  display: inline-block;
  width: 0.84rem;
  height: 0.76rem;
  background-size: 100% 100%;
}

.icon-wap-promos {
  cursor: pointer;
  display: inline-block;
  width: 1.21rem;
  height: 0.83rem;
  background-size: 100% 100%;
}

.icon-wap-live_chat {
  cursor: pointer;
  display: inline-block;
  width: 0.77rem;
  height: 0.77rem;
  background-size: 100% 100%;
}

.icon-wap-my_account {
  cursor: pointer;
  display: inline-block;
  width: 0.55rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-transfer_btn {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/transfer_btn.png") no-repeat;
  width: 0.53rem;
  height: 0.57rem;
  background-size: 100% 100%;
  border: none;
}

.icon-wap-transfer_btn:disabled,
.icon-wap-transfer_btn[disabled] {
  opacity: .3;
  cursor: not-allowed;
}

.icon-wap-reload {
  cursor: pointer;
  display: inline-block;
  width: 0.59rem;
  height: 0.65rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-wap-arrow {
  cursor: pointer;
  display: inline-block;
  width: 0.37rem;
  height: 0.23rem;
  background-size: 100% 100%;
}

.font-gray {
  color: #19723c;
}

.bg-white {
  background: #fff;
}

.bg-header-t {
  background: green;
}

.bg-header {
  background: green;
}

.border-white {
  border-color: #fff;
}

.border-primary {
  border-color: solid 0.01rem green;
}

.btn-normal,
.btn-normal-green,
.btn-long {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 0.35rem;
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #222;
  font-size: 0.16rem;
  border-radius: 0.02rem;
  cursor: pointer;
  font-family: "Roboto-Bold";
}

html:lang(vi) .btn-normal,
html:lang(vi) .btn-normal-green,
html:lang(vi) .btn-long {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: uppercase;
}

.btn-normal:disabled,
.btn-normal-green:disabled,
.btn-long:disabled {
  filter: grayscale(0.6);
  cursor: not-allowed;
}

.login-button,
.reset-password-button {
  border: none;
}

.btn .btn-ok {
  text-transform: uppercase;
}

.btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
  filter: grayscale(0.7);
  color: rgba(0,0,0,.431372549);
}

.btn-normal-green {
  background: #15743c;
  color: #fff;
  white-space: nowrap;
}

.btn-skew {
  display: inline-block;
  width: 2.28rem;
  height: 0.48rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  font-size: 0.2rem;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #c59b4e 0%, #d2af62 57%, #dec276 100%);
}

.btn-skew>* {
  transform: skew(25deg);
}

.btn-long {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.5rem;
  white-space: nowrap;
}

.text-red {
  color: #e1415d;
  font-size: 0.11rem;
}

.text-yellow,
.slide-menu li,
.header-top .user-info {
  color: #dec276;
  font-size: 0.12rem;
}

.btn-swiper-point,
.content-section .content-recommended .slick-dots li,
.slider>ul.tab li,
.bg-content-section .content-promotions .slick-dots li {
  display: inline-block;
  width: 0.32rem;
  height: 0.08rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  background: #e2dcde;
  margin-left: 0.07rem;
  cursor: pointer;
}

.btn-swiper-point.on,
.content-section .content-recommended .slick-dots li.on,
.slider>ul.tab li.on,
.bg-content-section .content-promotions .slick-dots li.on {
  background: #39b549;
  cursor: default;
}

.bg-gray {
  background: #f5f5f5;
}

.bg-black {
  background: #222;
}

.bg-header-top {
  background: #13242c;
}

.bg-header-top .content-container {
  width: 100%;
  max-width: 20rem;
  padding: 0 0.25rem;
}

.bg-header-bottom {
  background: linear-gradient(to right, #fff 0%, #fff 17%, #19723c 17%, #19723c 100%);
  z-index: 3;
  position: relative;
  height: 0.74rem;
}

.bg-header-bottom .content-container {
  width: 100%;
  max-width: 20rem;
  padding: 0 0.25rem;
}

.bg-footer-bottom {
  background: #ebebeb;
}

.swiper-arrow {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.26rem;
}

.text-banner-green {
  font-size: 0.6rem;
  color: #39b549;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .text-banner-green {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.text-banner-white {
  font-size: 0.42rem;
  color: #fff;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .text-banner-white {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.text-banner-white-small {
  font-family: OpenSans;
  font-style: italic;
  font-size: 0.24rem;
  color: #fff;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.page-enter {
  position: absolute;
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms,transform 300ms;
}

.page-exit {
  position: absolute;
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms,transform 300ms;
}

.page-slide-enter {
  position: absolute;
  right: -100%;
}

.page-slide-enter-active {
  right: 0;
  transition: right 300ms;
}

.page-slide-exit {
  position: absolute;
  right: 0;
}

.page-slide-exit-active {
  right: 100%;
  transition: right 300ms;
}

.slide-right-enter {
  right: -100%;
}

.slide-right-enter-active {
  right: 0;
  transition: right 300ms;
}

.slide-right-exit {
  right: 0;
}

.slide-right-exit-active {
  right: -100%;
  transition: right 300ms;
}

.spread-enter {
  transform-origin: top;
  transform: translateZ(0) scaleY(0);
  opacity: 0;
}

.spread-enter-active {
  opacity: 1;
  transform: translateZ(0) scaleY(1);
  transition: all 300ms;
}

.spread-exit {
  opacity: 1;
  transform: translateZ(0) scaleY(1);
  transform-origin: top;
}

.spread-exit-active {
  opacity: 0;
  transform: translateZ(0) scaleY(0);
  transition: all 300ms;
}

.rotate180-enter {
  transform: rotate(0deg);
}

.rotate180-enter-active {
  transform: rotate(180deg);
  transition: all 300ms;
}

.rotate180-enter-done {
  transform: rotate(180deg);
}

.rotate180-exit {
  transform: rotate(180deg);
}

.rotate180-exit-active {
  transform: rotate(360deg);
  transition: all 300ms;
}

.drop-right-enter {
  transform: translateY(-100%);
}

.drop-right-enter-active {
  transform: translateY(0);
  transition: all .3s;
}

.drop-right-exit {
  transform: translateY(0);
}

.drop-right-exit-active {
  transform: translateY(-100%);
  transition: all .3s;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms,transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms,transform 300ms;
}

.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
}

.icon-message {
  display: inline-block;
  width: 0.28rem;
  height: 0.21rem;
  background-size: 100% 100%;
  fill: #fff;
}

.provider-logo {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.icon-copy {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.new-icon,
.hot-icon,
.promo-icon {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 0.79rem;
  border: none !important;
  outline: none !important;
  outline-offset: unset !important;
  z-index: 2;
}

.tnc-apply {
  color: #0a974b;
  font-size: 0.16rem;
  font-family: "AvenirNextLTPro-Bold";
  border-bottom: 0.01rem solid;
}

html:lang(vi) .tnc-apply {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

@media (min-width: 1700px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 16%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2100px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 17%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2200px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 18%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2300px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 19%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2400px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 20.5%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2500px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 22%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2650px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 23%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2750px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 25%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3000px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 26%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3200px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 27%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3300px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 29%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3600px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 31%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 4000px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 33%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 1441px) {
  .menu-animation-enter {
    transform-origin: top;
    transform: translateZ(0) translateX(-50%) scaleY(0);
    opacity: 0;
  }

  .menu-animation-enter-active {
    opacity: 1;
    transform: translateZ(0) translateX(-50%) scaleY(1);
    transition: all 300ms;
  }

  .menu-animation-exit {
    opacity: 1;
    transform: translateZ(0) translateX(-50%) scaleY(1);
    transform-origin: top;
  }

  .menu-animation-exit-active {
    opacity: 0;
    transform: translateZ(0) translateX(-50%) scaleY(0);
    transition: all 300ms;
  }
}

@media (min-width: 1025px)and (max-width: 1440px) {
  .menu-animation-enter {
    transform-origin: top;
    transform: translateZ(0) scaleY(0);
    opacity: 0;
  }

  .menu-animation-enter-active {
    opacity: 1;
    transform: translateZ(0) scaleY(1);
    transition: all 300ms;
  }

  .menu-animation-exit {
    opacity: 1;
    transform: translateZ(0) scaleY(1);
    transform-origin: top;
  }

  .menu-animation-exit-active {
    opacity: 0;
    transform: translateZ(0) scaleY(0);
    transition: all 300ms;
  }
}

@media (max-width: 1025px) {
  .bg-header-top .content-container {
    max-width: unset;
  }

  .bg-header-bottom {
    height: 100%;
  }

  .bg-header-bottom .content-container {
    max-width: unset;
  }

  .text-red {
    font-size: 0.2rem;
  }

  .new-icon,
  .hot-icon,
  .promo-icon {
    max-width: 1.2rem;
  }

  .tnc-apply {
    font-size: 0.3rem;
  }

  .provider-logo {
    left: 1.8rem;
    bottom: 0.8rem;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .menu-animation-enter {
    right: -4.22rem;
  }

  .menu-animation-enter-active {
    right: 0;
    transition: right 300ms;
  }

  .menu-animation-exit {
    right: 0;
  }

  .menu-animation-exit-active {
    right: -4.22rem;
    transition: right 300ms;
  }

  .icon-lan,
  .icon-lan-white {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-en,
  .icon-thai,
  .icon-idr {
    width: 0.48rem;
    height: 0.26rem;
  }

  .icon-flag {
    width: 0.48rem;
    height: 0.48rem;
  }

  .icon-logo {
    max-width: 4.66rem;
    max-height: 1.5rem;
  }

  .icon-reload {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-fund {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-game-menu {
    width: 0.48rem;
    height: 0.3rem;
  }

  .icon-raffle {
    width: 0.72rem;
    height: 0.5rem;
  }

  .icon-evo {
    width: 0.72rem;
    height: 0.8rem;
  }

  .icon-download {
    width: 0.4rem;
    height: 0.58rem;
  }

  .icon-contact {
    width: 0.53rem;
    height: 0.53rem;
  }

  .icon-notice {
    width: 1.21rem;
    height: 0.44rem;
    max-width: unset;
  }

  .icon-pagcor {
    width: 1.87rem;
    height: 0.42rem;
  }

  .icon-iovation {
    width: 1.785rem;
    height: 0.36rem;
  }

  .icon-bmm {
    width: 0.825rem;
    height: 0.255rem;
  }

  .icon-itechlab {
    width: 0.615rem;
    height: 0.585rem;
  }

  .icon-global {
    width: 0.72rem;
    height: 0.72rem;
  }

  .icon-godaddy {
    width: 2.025rem;
    height: 0.39rem;
  }

  .icon-payouts {
    width: 1.245rem;
    height: 0.435rem;
  }

  .icon-youtube {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-eeziepay {
    width: 1.155rem;
    height: 0.48rem;
  }

  .icon-paytrust {
    width: 1.35rem;
    height: 0.285rem;
  }

  .icon-help2pay {
    width: 0.555rem;
    height: 0.39rem;
  }

  .icon-hot {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-new {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-arrow-big {
    width: 0.6rem;
    height: 0.6rem;
  }

  .icon-meda {
    width: 0.44rem;
    height: 0.66rem;
  }

  .icon-message {
    width: 0.42rem;
    height: 0.32rem;
  }

  .icon-search {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-on,
  .icon-off {
    width: 0.9rem;
    height: 0.3rem;
  }

  .icon-sapphire,
  .icon-ruby,
  .icon-diamond {
    width: 1.06rem;
    height: 0.84rem;
  }

  .icon-notice-important {
    width: 0.44rem;
    height: 0.39rem;
  }

  .icon-tip {
    width: 0.27rem;
    height: 0.27rem;
  }

  .icon-transfer-btn {
    width: 0.3rem;
    height: 0.26rem;
  }

  .icon-checked {
    width: 0.36rem;
    height: 0.36rem;
  }

  .icon-transfer-to {
    width: 0.27rem;
    height: 0.17rem;
  }

  .icon-love2 {
    width: 0.45rem;
    height: 0.38rem;
  }

  .icon-username {
    width: 0.3rem;
    height: 0.46rem;
  }

  .icon-lock {
    width: 0.44rem;
    height: 0.54rem;
  }

  .btn-swiper-point,
  .content-section .content-recommended .slick-dots li,
  .slider>ul.tab li,
  .bg-content-section .content-promotions .slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .text-banner-green {
    font-size: 1.22rem;
  }

  .text-banner-white {
    font-size: 0.56rem;
    line-height: 0.8rem;
  }

  .text-banner-white-small {
    font-size: 0.3rem;
  }

  .btn-skew {
    width: 2.18rem;
    height: 0.58rem;
    font-size: 0.3rem;
  }

  .btn-normal,
  .btn-normal-green,
  .btn-long {
    width: 2.26rem;
    height: 0.54rem;
    font-size: 0.23rem;
  }

  .btn-long {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 0.3rem;
  }
}

@media (max-width: 768px) {
  .bg-header-bottom .content-container {
    padding: unset;
  }

  .icon-copy {
    width: 0.63rem;
    height: 0.7rem;
  }

  .icon-arrow-down {
    fill: #10572c;
  }

  .icon-home {
    height: 0.3rem;
  }

  .icon-casino,
  .icon-poker,
  .icon-vip,
  .icon-slots,
  .icon-sports,
  .icon-favourite {
    width: 0.32rem;
    height: 0.32rem;
  }

  .icon-e-sport,
  .icon-promotion {
    width: 0.39rem;
    height: 0.27rem;
  }

  .icon-fishing {
    width: 0.39rem;
    height: 0.42rem;
  }

  .icon-918kiss {
    width: 0.42rem;
    height: 0.7rem;
  }

  .daily-limit {
    margin-top: 0.2rem;
    color: #41b850;
    font-size: 0.35rem;
  }

  .daily-limit>span>span:first-child {
    margin-left: 0.1rem;
  }

  .provider-logo {
    left: 0.3rem;
    bottom: 0.5rem;
  }

  .swiper-arrow {
    display: none;
  }

  .menu-animation-enter {
    right: -4.14rem;
  }

  .menu-animation-enter-active {
    right: 0;
    transition: right 300ms;
  }

  .menu-animation-exit {
    right: 0;
  }

  .menu-animation-exit-active {
    right: -4.14rem;
    transition: right 300ms;
  }

  .icon-lan,
  .icon-lan-white {
    width: 0.33rem;
    height: 0.35rem;
  }

  .icon-reload {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-fund {
    width: 0.35rem;
    height: 0.35rem;
    fill: #10572c;
  }

  .icon-restore {
    fill: #10572c;
  }

  .icon-en,
  .icon-thai,
  .icon-idr {
    width: 0.41rem;
    height: 0.26rem;
  }

  .icon-flag {
    width: 0.41rem;
    height: 0.41rem;
  }

  .icon-logo {
    max-width: 3.5rem;
    max-height: 1rem;
    margin-right: 0;
    margin-left: 0.3rem;
  }

  .completed-main .icon-logo {
    max-width: 5rem;
    max-height: 3.85rem;
    margin-right: 0;
  }

  .icon-game-menu {
    width: 0.67rem;
    height: 0.43rem;
  }

  .icon-raffle {
    width: 0.72rem;
    height: 0.5rem;
  }

  .icon-download {
    width: 0.4rem;
    height: 0.58rem;
  }

  .icon-contact {
    width: 0.53rem;
    height: 0.53rem;
  }

  .icon-notice {
    width: 1.5rem;
    height: 0.49rem;
    max-width: unset;
  }

  .icon-curaccao {
    width: 2rem;
    height: 0.9rem;
  }

  .icon-pagcor {
    width: 1.87rem;
    height: 0.42rem;
  }

  .icon-iovation {
    width: 1.785rem;
    height: 0.36rem;
  }

  .icon-tm {
    width: 2.43rem;
    height: 0.8rem;
  }

  .icon-bmm {
    width: 0.825rem;
    height: 0.255rem;
  }

  .icon-itechlab {
    width: 0.615rem;
    height: 0.585rem;
  }

  .icon-global {
    width: 0.72rem;
    height: 0.72rem;
  }

  .icon-godaddy {
    width: 2.025rem;
    height: 0.39rem;
  }

  .icon-payouts {
    width: 1.245rem;
    height: 0.435rem;
  }

  .icon-youtube {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-eeziepay {
    width: 1.4rem;
    height: 0.6rem;
    margin: 0 0.1rem;
  }

  .icon-paytrust {
    width: 1.35rem;
    height: 0.285rem;
  }

  .icon-help2pay {
    width: 0.9rem;
    height: 0.6rem;
    margin: 0 0.1rem;
  }

  .icon-bitcoin {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-usdt {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-paynow {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-paynow-v2 {
    width: 1rem;
    height: 0.9rem;
  }

  .icon-eth {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-duitnow {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-instantpay {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-tng {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-grab {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-boost {
    width: 1.65rem;
    height: 0.9rem;
  }

  .icon-shopee {
    width: 1.5rem;
    height: 0.9rem;
  }

  .icon-truepay {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-surepay {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-hot {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-new {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-arrow-big {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-meda {
    width: 0.48rem;
    height: 0.71rem;
  }

  .icon-message {
    width: 0.42rem;
    height: 0.32rem;
  }

  .icon-search {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-on,
  .icon-off {
    width: 0.9rem;
    height: 0.3rem;
  }

  .icon-sapphire,
  .icon-ruby,
  .icon-diamond {
    width: 1.82rem;
    height: 1.19rem;
  }

  .icon-notice-important {
    width: 0.44rem;
    height: 0.39rem;
  }

  .icon-tip {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-transfer-btn {
    width: 0.3rem;
    height: 0.26rem;
  }

  .icon-checked {
    width: 0.36rem;
    height: 0.36rem;
  }

  .icon-transfer-to {
    width: 0.27rem;
    height: 0.17rem;
  }

  .icon-love2 {
    width: 0.37rem;
    height: 0.31rem;
  }

  .icon-username {
    width: 0.4rem;
    height: 0.56rem;
  }

  .icon-lock {
    width: 0.54rem;
    height: 0.64rem;
  }

  .btn-swiper-point,
  .content-section .content-recommended .slick-dots li,
  .slider>ul.tab li,
  .bg-content-section .content-promotions .slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .text-banner-green {
    font-size: 0.7rem;
  }

  .text-banner-white {
    font-size: 0.4rem;
    line-height: 0.6rem;
  }

  .text-banner-white-small {
    font-size: 0.24rem;
  }

  .btn-skew {
    width: 2.2rem;
    height: 0.46rem;
    font-size: 0.24rem;
  }

  .btn-normal,
  .btn-normal-green,
  .btn-long {
    width: 3.05rem;
    height: 0.54rem;
    font-size: 0.38rem;
    border: none;
  }

  .btn-long {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 0.76rem;
  }

  .new-icon,
  .hot-icon,
  .promo-icon {
    max-width: 1.5rem;
  }
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  box-sizing: border-box;
  font-family: "OpenSans";
}

*:lang(vi) {
  font-family: "Alexandria","OpenSans";
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html .Toastify .Toastify__toast {
  border-radius: 0.05rem;
}

html .Toastify__toast--default.error,
html .Toastify__toast--default.warning,
html .Toastify__toast--default.info {
  background: #fccbc7;
  border: 0.01rem solid red;
}

html ::-webkit-scrollbar {
  width: 2PX;
  height: 2PX;
  z-index: 999;
}

html ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1PX rgba(0,0,0,0);
  border-radius: 10PX;
  background-color: rgba(0,0,0,0);
}

html ::-webkit-scrollbar-thumb {
  border-radius: 10PX;
  box-shadow: inset 0 0 6PX rgba(0,0,0,.3);
  background: #dad6d6;
}

html body {
  background: #2a2a2a;
  overflow-x: hidden;
  font-size: 16PX;
  letter-spacing: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-overflow-scrolling: touch;
}

html body a {
  text-decoration: none;
  color: #333;
}

html body a:link {
  text-decoration: none;
}

html body a:visited {
  text-decoration: none;
}

html body a:hover {
  text-decoration: none;
}

html body a:active {
  text-decoration: none;
}

html body input {
  -webkit-appearance: none;
}

html body select::-ms-expand {
  background: rgba(0,0,0,0);
  border: none;
}

#gamelauncher {
  overflow: auto;
}

#gamelauncher.w-100 {
  width: 100%;
}

#gamelauncher.vh-100 {
  height: 100vh;
}

#gamelauncher.h-auto {
  height: auto;
}

#seo-footer-container {
  display: block;
}

#seo-footer-container h5 {
  color: #39b549 !important;
}

#footer-top,
#footer-btm {
  position: static;
}

#root {
  position: relative;
  overflow-x: hidden;
}

.header-section {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.bg-footer-bottom {
  width: 100%;
  left: 0;
  z-index: 2;
}

.bg-footer-bottom.fix-top {
  position: fixed;
  top: 1.05rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 2;
}

.content-container,
#seo-footer {
  width: 14.4rem;
  margin: 0 auto;
}

.content-container#copyright-container-btm,
#seo-footer#copyright-container-btm {
  display: none;
}

.content-container#copyright-container-btm.show,
#seo-footer#copyright-container-btm.show {
  display: block;
}

.seo-footer-img {
  width: 35%;
  margin: auto;
  left: 0;
  right: 0;
  display: block;
  margin-bottom: 0.2rem;
}

#seo-footer {
  justify-content: center;
  flex-flow: wrap;
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.2rem;
  padding: 0.1rem 0.4rem;
}

#seo-footer>div .content-title {
  font-size: 0.16rem;
  color: #009344;
  display: flex;
}

#seo-footer>div .content-text {
  font-size: 0.14rem;
  color: #5f5f5f;
  margin-top: 0.1rem;
}

#seo-footer>div a {
  color: #009344;
}

.content-title {
  font-size: 0.24rem;
  color: #404040;
  text-transform: capitalize;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .content-title {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.content-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 0.14rem 0.25rem;
  margin-bottom: -0.9rem;
}

.content-center-box {
  padding-top: 0.9rem;
}

.content-center-box .provider-landing-submenu-tab-container {
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  background: #f5f5f5;
  margin: 0 0 .15rem;
  padding: 0 .45rem;
}

.content-center-box .provider-landing-submenu-tab-container .arrow-container {
  width: 100%;
}

.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-right-container,
.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-left-container {
  position: absolute;
  height: 0.5rem;
  width: 0.45rem;
  z-index: 2;
}

.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-right-container .arrow-right,
.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-right-container .arrow-left,
.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-left-container .arrow-right,
.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-left-container .arrow-left {
  position: absolute;
  top: 0.13rem;
  z-index: 3;
  cursor: pointer;
}

.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-right-container {
  right: 0%;
}

.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-right-container .arrow-right {
  right: 20%;
}

.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-left-container {
  left: 0%;
}

.content-center-box .provider-landing-submenu-tab-container .arrow-container .arrow-left-container .arrow-left {
  left: 20%;
}

.content-center-box .provider-landing-submenu-tab-container .standard-tab .provider-bar {
  margin-bottom: 0;
}

.content-center-box .provider-bar {
  display: flex;
  margin-bottom: 0.15rem;
}

.content-center-box .provider-bar .maintenance-hover-box {
  background: url(/public/html/images/icon/maintenance-en.svg);
  width: 1.9rem;
  height: 0.4rem;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(245,245,245,.8);
  position: absolute;
  left: 0;
  bottom: 0;
}

.content-center-box .provider-bar .provider {
  transform: skew(-25deg);
  transform-origin: 0 100%;
  padding-top: 0.1rem;
  padding-bottom: 0.05rem;
  cursor: pointer;
}

.content-center-box .provider-bar .provider.active {
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
}

.content-center-box .provider-bar .provider.active svg {
  fill: #fff;
}

.content-center-box .provider-bar .provider svg {
  transform: skew(25deg);
  transform-origin: 0 100%;
  height: 0.25rem;
  width: 1.6rem;
  fill: #838383;
  margin-left: 0.3rem;
}

.content-center-box .provider-bar .provider svg.active {
  fill: #fff;
}

.content-center-box .provider-bar .provider .provider-bar-FG {
  height: 0.3rem;
}

.content-center-box .provider-bar .provider .provider-bar-DS,
.content-center-box .provider-bar .provider .provider-bar-R88,
.content-center-box .provider-bar .provider .provider-bar-WESLT,
.content-center-box .provider-bar .provider .provider-bar-SW {
  height: 0.3rem;
}

.content-center-box .provider-bar .provider.badge::before {
  position: absolute;
  top: 0;
  right: 0.1rem;
  padding: 0 0.04rem;
  font-size: 0.1rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  transform: skew(25deg);
}

.content-center-box .provider-bar .provider.badge-hot::before {
  content: "hot";
  background-color: #ff3d00;
}

.content-center-box .provider-bar .provider.badge-new::before {
  content: "new";
  background-color: #19733d;
}

.header-top {
  height: 0.46rem;
  font-size: 0.14rem;
  color: #49ad57;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 4;
}

.header-top .header-timeLang-box {
  display: flex;
  align-items: center;
}

.header-top .date-time {
  width: 2.25rem;
}

.header-top .lan-select-box {
  display: flex;
  align-items: center;
  position: relative;
}

.header-top .lan-select-box .lan-label {
  font-family: OpenSans;
  margin-left: 0.1rem;
  margin-right: 0.15rem;
}

.header-top .lan-select-box .current-lan-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-top .lan-select-box .current-lan-box .current-lan {
  font-family: OpenSans;
  font-size: 0.14rem;
  color: #fff;
  margin: 0 0.1rem;
  text-align: center;
}

.header-top .lan-select-box .lan-list-box {
  background-color: #182d37;
  position: absolute;
  top: 0.29rem;
  left: -0.15rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  font-size: 0.14rem;
  z-index: 4;
  font-family: OpenSans;
}

.header-top .lan-select-box .lan-list-box ul {
  padding: 0.15rem 0.18rem;
}

.header-top .lan-select-box .lan-list-box ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 0.45rem;
  z-index: 1;
  white-space: nowrap;
}

.header-top .lan-select-box .lan-list-box ul li span {
  margin-left: 0.1rem;
  color: #b2b2b2;
}

.header-top .lan-select-box .lan-list-box ul li span:not(.divide):hover {
  text-decoration: underline;
  cursor: pointer;
}

.header-top .lan-select-box .lan-list-box ul li span.is-active {
  color: #49ad57;
}

.header-top .lan-select-box .lan-list-box ul li span.divide {
  color: #19723c;
}

.header-top .user-info {
  font-family: OpenSans;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 100%;
}

.header-top .user-info>span {
  font-size: 0.14rem;
  font-weight: bold;
}

.header-top .user-info .message {
  margin-left: 0.51rem;
  display: flex;
  align-items: center;
  position: relative;
}

.header-top .user-info .message .icon-message {
  margin-right: 0.1rem;
}

.header-top .user-info .message .msg-count-box {
  position: absolute;
  width: 0.19rem;
  height: 0.19rem;
  border-radius: 50%;
  border: 0.02rem solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #e91d20;
  font-size: 0.09rem;
  font-family: "AvenirNextLTPro";
  left: 0.17rem;
  top: -0.1rem;
}

.header-top .user-info .message .msg-count-box .tag {
  position: absolute;
  top: -0.01rem;
  left: .002rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-top .user-info .message .headerMsg-box {
  transition: transform .5s;
  transform: translateY(0);
  visibility: hidden;
  position: absolute;
  left: -0.45rem;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: default;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}

.header-top .user-info .message .headerMsg-box>span {
  width: 100%;
  white-space: nowrap;
  padding: 0.1rem 0.2rem;
  border-bottom: 0.01rem solid #dec276;
}

.header-top .user-info .message .headerMsg-box>span:last-child {
  border: none;
}

.header-top .user-info .message .headerMsg-box>span:hover {
  cursor: pointer;
  background: #dec276;
  color: #fff;
}

.header-top .user-info:hover .headerMsg-box {
  visibility: visible;
  transform: translateY(0.25rem);
}

.header-top .header-nav-tools {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  font-weight: bold;
  position: relative;
}

.header-top .header-nav-tools>span {
  font-family: "Roboto-Bold";
}

.header-top .header-nav-tools>span.register-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #222;
  width: 1.17rem;
  height: 0.26rem;
  border-radius: 0.02rem;
  line-height: 0;
}

.header-top .header-nav-tools>span.login-btn {
  display: flex;
  align-items: center;
  color: #2be53d;
}

.header-top .header-nav-tools>* {
  margin-left: 0.4rem;
  cursor: pointer;
}

.header-top .header-nav-tools.logined {
  font-family: OpenSans;
  font-weight: normal;
}

.header-top .header-nav-tools.logined>div {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-top .header-nav-tools.logined>div .wallet {
  cursor: default;
  font-weight: normal;
  display: flex;
  align-items: center;
  height: 100%;
}

.header-top .header-nav-tools.logined>div .wallet .reload-box,
.header-top .header-nav-tools.logined>div .wallet .tool-box {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-top .header-nav-tools.logined>div .wallet p,
.header-top .header-nav-tools.logined>div .wallet span.fund-box {
  display: flex;
  align-items: center;
}

.header-top .header-nav-tools.logined>div .wallet p .number,
.header-top .header-nav-tools.logined>div .wallet span.fund-box .number {
  color: #fff;
  font-size: 0.16rem;
  margin-left: 0.18rem;
  margin-right: 0.1rem;
}

.header-top .header-nav-tools.logined>div .wallet p .icon-reload,
.header-top .header-nav-tools.logined>div .wallet span.fund-box .icon-reload {
  transform: scale(0.8);
  cursor: pointer;
}

.header-top .header-nav-tools.logined>div .wallet p .icon-fund,
.header-top .header-nav-tools.logined>div .wallet span.fund-box .icon-fund {
  margin-right: 0.08rem;
}

.header-top .header-nav-tools.logined>div .wallet p .icon-arrow-down,
.header-top .header-nav-tools.logined>div .wallet span.fund-box .icon-arrow-down {
  margin: 0 0.06rem;
  cursor: pointer;
}

.header-top .header-nav-tools.logined>div .wallet .restore-box {
  display: flex;
  align-items: center;
  margin-left: 0.37rem;
  cursor: pointer;
}

.header-top .header-nav-tools.logined>div .wallet .restore-box .icon-restore {
  margin-right: 0.12rem;
}

.header-top .header-nav-tools.logined>div .wallet .fund-box {
  position: relative;
  pointer-events: auto;
  cursor: pointer;
  z-index: 3;
}

.header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread {
  white-space: nowrap;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  pointer-events: none;
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 1.91rem;
  background: linear-gradient(0deg, rgb(233, 233, 233), rgb(255, 255, 255));
  border: 0.01rem solid #b8b8b8;
  left: -0.26rem;
  top: 0.4rem;
  z-index: 999;
  padding: 0 0.1rem;
  padding-top: 0.15rem;
}

.header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread::before {
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-right: 0.16rem solid rgba(0,0,0,0);
  border-left: 0.16rem solid rgba(0,0,0,0);
  border-bottom: 0.16rem solid #b8b8b8;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-100%) translateZ(0);
}

.header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread::after {
  top: 0.02rem;
  content: "";
  width: 0;
  height: 0;
  border-right: 0.15rem solid rgba(0,0,0,0);
  border-left: 0.15rem solid rgba(0,0,0,0);
  border-bottom: 0.15rem solid #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-100%) translateZ(0);
}

.header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li {
  display: flex;
  align-items: center;
  pointer-events: auto;
  height: 0.42rem;
  font-weight: bold;
  font-size: 0.14rem;
  color: #444;
  cursor: pointer;
}

.header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li:hover a {
  color: #0a974b;
}

.header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li span {
  min-width: 0.7rem;
  height: 0.3rem;
  position: relative;
}

.header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li span>i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
}

.header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li>a {
  text-transform: capitalize;
}

.header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li:hover {
  color: #2b7d53;
}

.header-top .header-nav-tools.logined>div .wallet .split {
  color: #3db24a;
  font-size: 0.36rem;
  transform: scaleX(0.5) skew(-25deg);
  margin-right: 0.17rem;
  margin-left: 0.15rem;
}

.header-top .header-nav-tools.logined>div>span {
  font-family: "Roboto-Bold";
  font-weight: bold;
  color: #39b549;
  margin-left: 0.32rem;
}

.header-top .header-nav-tools.logined>div>span.deposit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  width: 1.17rem;
  height: 0.26rem;
  border-radius: 0.02rem;
  line-height: 0;
  color: #222;
  margin-left: 0.38rem;
}

.header-bottom {
  height: 0.74rem;
  display: flex;
}

.header-bottom .header-logo-box {
  width: 2.3rem;
  height: 0.74rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  transform-origin: 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.header-bottom .header-logo-box .header-triangle-box {
  width: 20%;
  height: 0;
  position: absolute;
  right: -0.1rem;
  top: 0;
  border-bottom: 0.74rem solid #19723c;
  border-left: 0.35rem solid rgba(0,0,0,0);
}

.header-bottom .header-separator-triangle-box {
  background: url("/public/html/images/header-bottom-separator.png");
  background-size: 100% 100%;
  height: 0.74rem;
  width: 0.7rem;
}

.header-bottom .header-menu-box {
  width: 11.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.12rem;
}

.header-bottom .header-menu-box .menu-name {
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.16rem;
  font-weight: bold;
  cursor: pointer;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .header-bottom .header-menu-box .menu-name {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: uppercase;
}

.header-bottom .header-menu-box .menu-name.current,
.header-bottom .header-menu-box .menu-name:hover {
  color: #e9cc79;
  border-bottom: 0.05rem solid #e9cc79;
  padding-top: 0.06rem;
}

.header-bottom .header-menu-box .menu-name .fifa-icon {
  width: 1.5rem;
}

.header-bottom .nav-menu {
  pointer-events: auto;
  position: absolute;
  width: 2rem;
  top: 0;
  overflow: hidden;
  font-style: normal;
  background: #13242c;
  font-size: 0.13rem;
  font-family: OpenSans;
  color: #39b549;
  cursor: default;
  z-index: 1;
}

.header-bottom .nav-menu li {
  padding-left: 0.2rem;
  height: 0.4rem;
  margin: 0.05rem 0;
  font-family: "AvenirNextLTPro";
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header-bottom .nav-menu li .icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.6rem;
}

.footer-top {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.35rem 0.4rem 0 0.4rem;
}

.website-footer-bottom {
  height: 0.45rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 .4rem 0 .4rem;
}

.footer-bottom-line {
  width: 100%;
  height: 0.01rem;
  padding: 0 .4rem 0 .4rem;
}

.brand-row-container {
  padding: 0 0.1rem;
  display: flex;
  justify-content: space-between;
  font-family: OpenSans;
  font-size: 0.14rem;
  color: #39b549;
  text-transform: capitalize;
}

.brand-row-container .license-container {
  width: 65%;
  padding: 0 0.15rem 0 0;
}

.brand-row-container .license-container li {
  margin-right: 0.35rem;
}

.brand-row-container .cert-container {
  width: 65%;
  padding: 0 0.15rem 0 0;
}

.brand-row-container .cert-container li {
  margin-right: 0.35rem;
}

.brand-row-container .security-container {
  width: 35%;
}

.brand-row-container .security-container li {
  margin-right: 0.35rem;
}

.brand-row-container .follow-us-container {
  width: 35%;
}

.brand-row-container .follow-us-container li.follow-item {
  display: flex;
  justify-content: center;
}

.brand-row-container .follow-us-container li.follow-item img {
  width: 100%;
}

.brand-row-container .follow-us-container li.follow-item .pointer {
  width: 0.5rem;
  height: 0.5rem;
}

.brand-row-container .follow-us-container li.follow-item .pointer.followusline,
.brand-row-container .follow-us-container li.follow-item .pointer.followusline-on {
  width: 0.6rem;
  height: 0.6rem;
}

.brand-row-container .follow-us-container .dummy-container {
  height: 1.1rem;
}

.brand-row-container .payment-container {
  width: 65%;
  padding: 0 0.15rem 0 0;
}

.brand-row-container .payment-container li {
  margin-right: 0.2rem;
}

.brand-row-container .bottom-navbar-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 0.16rem;
}

.brand-row-container .bottom-navbar-container li {
  margin-right: 0.3rem;
  color: #39b549;
  font-weight: bold;
  white-space: nowrap;
}

.brand-row-container .bottom-navbar-container li span,
.brand-row-container .bottom-navbar-container li a {
  color: #39b549;
  cursor: pointer;
  font-family: OpenSans;
}

html:lang(vi) .brand-row-container .bottom-navbar-container li span,
html:lang(vi) .brand-row-container .bottom-navbar-container li a {
  font-family: "Alexandria";
  font-weight: 600;
}

.brand-row-container .bottom-navbar-container li span:hover,
.brand-row-container .bottom-navbar-container li a:hover {
  text-decoration: underline;
}

.brand-row-container .footer-row-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.brand-row-container .powered_by {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #757575;
  width: 2.6rem;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.brand-row-container .powered_by#copyright-container-top {
  display: none;
}

.brand-row-container .powered_by#copyright-container-top.show {
  display: flex;
}

.brand-row-container .powered_by p {
  color: #757575;
  text-align: right;
  padding-right: 0.06rem;
}

.brand-row-container .powered_by img {
  width: 100%;
  margin-top: -0.02rem;
}

.brand-row-container .powered_by svg {
  margin-top: -0.04rem;
  margin-left: -0.15rem;
}

.brand-row-container .copyright-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #757575;
}

.brand-row-container .copyright-container#copyright-container-top {
  display: none;
}

.brand-row-container .copyright-container#copyright-container-top.show {
  display: flex;
}

.brand-row-container .copyright-container span {
  margin-left: 0.05rem;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  border: 0.01rem solid #c2c7c1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d70808;
  font-size: 0.12rem;
}

.brand-row-container .brand-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.brand-row-container .brand-item-container li {
  padding: 0.05rem 0;
}

.brand-row-container .align-right {
  justify-content: flex-end;
}

.brand-row-separator-1 {
  width: 100%;
  height: 0.01rem;
  background: rgba(0,0,0,0);
}

.brand-row-separator-35 {
  width: 100%;
  height: 0.35rem;
  background: rgba(0,0,0,0);
}

.brand-row-separator-60 {
  width: 100%;
  height: 0.6rem;
  background: rgba(0,0,0,0);
}

.brand-row-separator-70 {
  width: 100%;
  height: 0.7rem;
  background: rgba(0,0,0,0);
}

.brand-row-separator-250 {
  width: 100%;
  height: 2.5rem;
  background: rgba(0,0,0,0);
}

.brand-row-line-separator {
  border-bottom: 0.01rem solid #4c4c4c;
}

.col-100 {
  width: 100%;
  display: flex;
}

.col-60 {
  width: 60%;
}

.col-40 {
  width: 40%;
}

.col-75 {
  width: 75%;
}

.col-25 {
  width: 25%;
}

.w-35 {
  width: 35%;
}

.position-relative {
  position: relative;
}

.d-inline {
  display: inline;
}

.f-section-title {
  font-family: OpenSans;
  font-size: 0.14rem;
  color: #39b549;
}

.capitalize-text {
  text-transform: capitalize;
}

.sponsor-container .sponsor-img {
  max-height: 2.3rem;
  max-width: 3.2rem;
}

@media (max-width: 768px) {
  #footer-top {
    position: unset;
  }

  .footer-top {
    padding: 0.5rem 0.45rem 0 0.45rem;
  }

  .website-footer-bottom {
    height: 0.6rem;
    display: unset;
  }

  .website-footer-bottom .brand-row-container .powered_by {
    width: 38%;
    grid-template-columns: 40% 60%;
  }

  .website-footer-bottom .brand-row-container .powered_by img {
    width: 100%;
  }

  .website-footer-bottom .brand-row-container .powered_by svg {
    height: 0.9rem;
    margin-top: -0.13rem;
    margin-left: -0.05rem;
  }

  .footer-mobile-isLogin {
    padding-bottom: 2.12rem;
  }

  .brand-row-container {
    padding: 0 0.15rem;
    font-size: 0.26rem;
  }

  .brand-row-container .license-container {
    width: 50%;
  }

  .brand-row-container .license-container li {
    margin-right: 0.46rem;
    margin-bottom: 0.15rem;
    margin-top: 0.15rem;
  }

  .brand-row-container .cert-container {
    width: 60%;
  }

  .brand-row-container .cert-container li {
    margin: 0 0.46rem 0.15rem 0;
  }

  .brand-row-container .security-container {
    width: 100%;
  }

  .brand-row-container .security-container li {
    margin: 0 0.46rem 0.15rem 0;
  }

  .brand-row-container .follow-us-container {
    width: 40%;
  }

  .brand-row-container .follow-us-container li.follow-item {
    -webkit-backface-visibility: unset;
            backface-visibility: unset;
  }

  .brand-row-container .follow-us-container li.follow-item .pointer {
    width: 0.9rem;
    height: 0.9rem;
  }

  .brand-row-container .follow-us-container li.follow-item .pointer.followusline,
  .brand-row-container .follow-us-container li.follow-item .pointer.followusline-on {
    width: 1rem;
    height: 1rem;
  }

  .brand-row-container .payment-container {
    width: 100%;
  }

  .brand-row-container .payment-container .brand-item-container {
    height: auto;
  }

  .brand-row-container .bottom-navbar-container {
    font-size: 0.32rem;
  }

  .brand-row-container .bottom-navbar-container li {
    margin-right: 0.5rem;
    margin-bottom: 0.15rem;
  }

  .brand-row-container .copyright-container {
    font-size: 0.24rem;
    padding: 0 0.2rem 0 0;
  }

  .brand-row-container .copyright-container span {
    margin-left: 0.26rem;
    width: 0.56rem;
    height: 0.56rem;
    border: 0.02rem solid #c2c7c1;
  }

  .brand-row-container .brand-item-container {
    height: auto;
  }

  .brand-row-container.footer-row-copyright {
    justify-content: space-between;
  }

  .brand-row-container .powered_by {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: unset;
  }

  .brand-row-container .powered_by p {
    color: #757575;
    text-align: right;
    white-space: nowrap;
    padding-right: 0;
  }

  .brand-row-container .powered_by img {
    width: 50%;
    margin-top: -0.02rem;
  }

  .brand-row-container .powered_by svg {
    height: 0.82rem;
    margin-top: -0.15rem;
    margin-left: -0.05rem;
    width: auto;
  }

  .footer-bottom-line {
    padding-left: 0.45rem;
    padding-right: 0.45rem;
    padding-top: 0.35rem;
  }

  .sponsor-container .sponsor-img {
    max-height: 4rem;
    max-width: 100%;
  }

  .f-section-title {
    font-size: 0.26rem;
  }
}

.nav-menu li {
  transition: background,color .3s,.3s;
  font-family: "AvenirNextLTPro";
}

.nav-menu li.on {
  background: #19723c;
  color: #fff;
  cursor: default;
}

.footer-bottom {
  height: 1.2rem;
  position: relative;
}

.footer-bottom .nav-menu {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-bottom .nav-menu li {
  width: 2.17rem;
  height: 100%;
  color: #18723c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.28rem;
  font-weight: bold;
  cursor: pointer;
}

.footer-bottom .nav-menu li.current {
  color: #c59b4e;
  border-bottom: 0.05rem solid #c59b4e;
  margin-top: 0.06rem;
}

.footer-bottom .nav-menu li .icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.44rem;
  margin-bottom: 0.2rem;
  transform: scale(2.5);
}

.footer-bottom .nav-menu li:hover {
  border-bottom: 0.05rem solid #c59b4e;
}

.nav-menu li {
  transition: background,color .3s,.3s;
  font-family: "AvenirNextLTPro";
}

.nav-menu li.on {
  background: #19723c;
  color: #fff;
  cursor: default;
}

.bg-content-section {
  position: relative;
  background: linear-gradient(to bottom, #f7f7f7, #fff 20%);
}

.bg-content-section.games {
  background: #182329;
}

.bg-content-section.games .slide-menu {
  top: 0.76rem;
}

.bg-content-section.slots .normal-banner .slide.slide1 .slide-text-box>p:nth-child(3) {
  margin-top: 0;
}

.bg-content-section.slots .normal-banner .slide.slide1 .icon-pngo-banner {
  position: absolute;
  left: 0.7rem;
  bottom: 0.7rem;
}

.bg-content-section .content-promotions .content-center {
  padding: 0 0.43rem;
  position: relative;
}

.bg-content-section .content-promotions .promoBox {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
}

.bg-content-section .content-promotions .promoBox ul {
  width: 100%;
  display: inline-block;
}

.bg-content-section .content-promotions .promoBox li {
  width: 4.1rem;
  height: 2.92rem;
  position: relative !important;
  font-size: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.bg-content-section .content-promotions .img-box {
  height: 100%;
  align-items: flex-end;
  display: flex;
}

.bg-content-section .content-promotions .img-box img {
  width: 100%;
  max-width: 4.1rem;
}

.bg-content-section .content-promotions .promoBox-content {
  font-size: 0.16rem;
  font-family: OpenSans;
  color: #cacaca;
  line-height: 1.3;
}

.bg-content-section .content-promotions .promoBox-content .promoBox-title {
  margin-top: 0.03rem;
}

.bg-content-section .content-promotions .promoBox-btn {
  display: flex;
  margin-top: 0.15rem;
}

.bg-content-section .content-promotions .promoBox-btn>div {
  width: 100%;
}

.bg-content-section .content-promotions .slick-slider {
  height: 100%;
}

.bg-content-section .content-promotions .slick-next-btn,
.bg-content-section .content-promotions .slick-prev-btn {
  position: absolute;
  display: flex !important;
  align-items: center;
  height: 46.4%;
  margin-top: 1.05rem;
  padding-bottom: 0;
  top: 0;
  right: -0.02rem;
  z-index: 1;
}

.bg-content-section .content-promotions .slick-next-btn i,
.bg-content-section .content-promotions .slick-prev-btn i {
  background-color: rgba(66,66,66,.9);
}

.bg-content-section .content-promotions .slick-prev-btn {
  left: -0.03rem;
  right: unset;
  z-index: 2;
}

.bg-content-section .content-promotions .slick-list {
  z-index: 1;
  padding: 0.3rem 0 0.45rem;
  height: 100%;
}

.bg-content-section .content-promotions .slick-list li {
  width: 4.1rem;
  height: 2.88rem;
  position: relative !important;
  font-size: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.bg-content-section .content-promotions .slick-dots {
  top: -0.5rem;
  right: -0.36rem;
  bottom: 0;
  z-index: 0;
  text-align: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.bg-content-section .content-promotions .slick-dots li {
  margin: 0;
  margin-left: 0.07rem;
}

.bg-content-section .content-promotions .slick-dots li button:before {
  content: unset;
}

.bg-content-section .content-promotions .slick-dots li.slick-active {
  background: #39b549;
}

.bg-content-section .content-maxim88 .content-center {
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg-content-section .content-maxim88 .maxim88-box {
  width: 44%;
  padding: 0.47rem 0 0.25rem 0.58rem;
  position: relative;
}

.bg-content-section .content-maxim88 .maxim88-box::after {
  content: "";
  background: linear-gradient(to top, #f5f5f5 0%, rgba(245, 245, 245, 0.5) 100%);
  position: absolute;
  bottom: 0;
  width: 5.15rem;
  height: 0.5rem;
}

.bg-content-section .content-maxim88 .maxim88-box .box-title {
  color: #3cb748;
  font-size: 0.24rem;
  font-weight: bold;
  padding-bottom: 0.21rem;
  margin-right: 0.58rem;
  border-bottom: 0.02rem solid #dcdcdc;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .bg-content-section .content-maxim88 .maxim88-box .box-title {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.bg-content-section .content-maxim88 .maxim88-box .box-title span {
  color: #000;
  font-size: 0.18rem;
  font-weight: 100;
  margin-left: 0.15rem;
  text-transform: capitalize;
  font-family: "AvenirNextLTPro";
}

html:lang(vi) .bg-content-section .content-maxim88 .maxim88-box .box-title span {
  font-family: "Alexandria";
  font-weight: 400;
}

.bg-content-section .content-maxim88 .maxim88-box .box-content {
  margin-top: 0.37rem;
  font-size: 0.18rem;
  height: 3.3rem;
  padding-bottom: 0.23rem;
  overflow-y: scroll;
  padding-right: 0.58rem;
}

.bg-content-section .content-maxim88 .maxim88-box .box-content::-webkit-scrollbar {
  width: 0.05rem;
}

.bg-content-section .content-maxim88 .maxim88-box .box-content div[class*=content-] {
  margin-bottom: 0.27rem;
}

.bg-content-section .content-maxim88 .maxim88-box .box-content .content-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.18rem;
}

.bg-content-section .content-maxim88 .maxim88-box .box-content .content-title span {
  margin-left: 0.12rem;
  width: 100%;
  max-width: 2rem;
}

.bg-content-section .content-maxim88 .maxim88-box .box-content .content-title span img,
.bg-content-section .content-maxim88 .maxim88-box .box-content .content-title span svg {
  margin-right: 0.03rem;
}

.bg-content-section .content-maxim88 .maxim88-box .box-content .content-title span img:last-child,
.bg-content-section .content-maxim88 .maxim88-box .box-content .content-title span svg:last-child {
  margin-right: 0;
}

.bg-content-section .content-maxim88 .maxim88-video {
  height: 100%;
  width: 56%;
  padding: 0 0.3rem;
  position: relative;
}

.bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-list .slick-track .slick-slide .react-player {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-list .slick-track .slick-slide .react-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-dots {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  right: 0;
  top: -0.35rem;
}

.bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-dots li {
  display: inline-block;
  width: 0.32rem;
  height: 0.08rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  background: #e2dcde;
  margin: 0;
  margin-left: 0.07rem;
  cursor: pointer;
}

.bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-dots li.slick-active {
  background: #39b549;
  cursor: default;
}

.bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-dots li button {
  height: 100%;
}

.bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-dots li button::before {
  content: unset;
}

.bg-content-section .content-maxim88 img,
.bg-content-section .content-maxim88 svg {
  max-height: 4.72rem;
  width: 100%;
  max-width: 0.3rem;
}

.bg-content-section .content-maxim88 img.img-signature,
.bg-content-section .content-maxim88 svg.img-signature {
  max-width: 7.5rem;
}

.bg-content-section .slick-slide .nav-game {
  display: flex !important;
  flex-wrap: wrap;
  width: 100% !important;
}

.bg-content-section .nav-game li {
  width: 2.25rem;
  position: relative !important;
  font-size: 0;
  margin: auto auto 0.24rem 0;
  left: 0;
  right: 0;
}

.bg-content-section .nav-game li .icon-tag {
  position: absolute;
  left: 0;
  top: 0;
}

.bg-content-section .nav-game li>img {
  width: 100%;
  height: auto;
  min-height: 1.77rem;
}

.bg-content-section .nav-game li>p {
  position: relative;
  width: 100%;
  margin: auto;
  height: 0.46rem;
  background: #222;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.15rem;
}

.bg-content-section .nav-game li>p .gamename {
  font-size: 0.12rem;
  font-family: OpenSans;
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;
  position: absolute;
  width: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-content-section .nav-game li>p .recommendgamename {
  font-size: 0.12rem;
  top: 50%;
  color: #fff;
  transform: translateY(-50%);
  left: 0.2rem;
  width: 1.2rem;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-content-section .nav-game li>p .icon-provider-logo {
  width: .3rem;
}

.bg-content-section .nav-game li .hover-box {
  width: 3.25rem;
  height: 2.66rem;
  background: #fff;
  box-shadow: 0 0 0.03rem #cacaca;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.bg-content-section .nav-game li .hover-box .game-item-info {
  height: 0.56rem;
  padding: 0 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-content-section .nav-game li .hover-box .game-item-info>p {
  font-size: 0.16rem;
  font-weight: bold;
  color: #222;
}

.bg-content-section .nav-game li .hover-box .game-item-info>div {
  display: flex;
  justify-content: space-between;
  font-size: 0.14rem;
  color: #858585;
}

.bg-content-section .nav-game li .hover-box .img-box {
  margin: 0 auto;
  width: 2.88rem;
  height: 1.88rem;
  position: relative;
}

.bg-content-section .nav-game li .hover-box .img-box::before,
.bg-content-section .nav-game li .hover-box .img-box .item-menu {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  position: absolute;
  left: 0;
  top: 0;
}

.bg-content-section .nav-game li .hover-box .img-box .item-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-content-section .nav-game li .hover-box .img-box .item-menu .btn-normal,
.bg-content-section .nav-game li .hover-box .img-box .item-menu .btn-normal-green,
.bg-content-section .nav-game li .hover-box .img-box .item-menu .btn-long {
  margin-top: 0.3rem;
}

.bg-content-section .nav-game li .hover-box .img-box .item-menu .btn-normal-green {
  margin-top: 0.14rem;
}

.bg-content-section .nav-game li .hover-box .img-box .item-menu div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.16rem;
  color: #fff;
  margin-top: 0.28rem;
}

.bg-content-section .nav-game li .hover-box .img-box .item-menu div>span {
  margin-left: 0.15rem;
}

.bg-content-section .nav-game li .hover-box .img-box .item-menu div .icon-love,
.bg-content-section .nav-game li .hover-box .img-box .item-menu div .icon-love-on {
  width: 0.3rem;
  height: 0.24rem;
}

.bg-content-section .nav-game li .hover-box .img-box img {
  width: 100%;
  height: 100%;
}

.bg-content-section .nav-game .scale-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: static;
}

.bg-content-section .content-recommended .nav-game li {
  position: relative;
  overflow: hidden;
  background: #dad8d8;
  border: 0.02rem solid #dad8d8;
}

.bg-content-section .content-recommended .nav-game li:hover .hover-box {
  transform: translateY(0);
  top: 0;
}

.bg-content-section .content-recommended .nav-game li img {
  min-height: unset;
}

.bg-content-section .content-recommended .nav-game li .hover-box {
  transition: transform .5s;
  transform: translateY(100%);
  position: absolute;
  left: 0;
  top: 0.02rem;
  width: 100%;
  height: 100%;
}

.bg-content-section .content-recommended .nav-game li .hover-box .img-box {
  width: 100%;
  height: 100%;
}

.bg-content-section .content-recommended .nav-game li .hover-box .img-box .item-menu {
  justify-content: center;
}

.bg-content-section .content-recommended .nav-game li .hover-box .img-box .item-menu .btn-normal:nth-child(1),
.bg-content-section .content-recommended .nav-game li .hover-box .img-box .item-menu .btn-normal-green:nth-child(1),
.bg-content-section .content-recommended .nav-game li .hover-box .img-box .item-menu .btn-long:nth-child(1) {
  margin-top: 0;
}

.bg-content-section .raffledraw-content .banner-box .banner .slider .sli .fade1-enter-done .banner-item .content-container .slide-text-box {
  left: .2rem;
  top: 3rem;
}

@media (max-width: 768px) {
  .bg-content-section .raffledraw-content .banner-box .banner .slider .sli .fade1-enter-done .banner-item .content-container .slide-text-box {
    left: .7rem;
    top: 2.5rem;
    width: 3rem;
  }
}

.content-recommended .slider>ul.tab {
  top: -0.78rem;
  right: -0.7rem;
}

.big-banner {
  height: 11.26rem;
  padding: 0.8rem 0;
}

.big-banner .game-box {
  width: 13.12rem;
  height: 9.7rem;
  margin: 0 auto;
  background: #0d0d0d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 0.3rem #19723c;
}

.big-banner .game-box .game-top {
  height: 0.85rem;
  display: flex;
  justify-content: space-between;
  padding-left: 0.4rem;
  padding-right: 0.25rem;
}

.big-banner .game-box .game-top .group-info {
  display: flex;
  align-items: center;
  color: #0a974b;
}

.big-banner .game-box .game-top .group-info span {
  font-size: 0.24rem;
  font-weight: bold;
  margin-right: 0.18rem;
}

.big-banner .game-box .game-top .group-info p {
  font-size: 0.14rem;
  margin-right: 0.2rem;
}

.big-banner .game-box .game-top .group-tools {
  display: flex;
  align-items: center;
}

.big-banner .game-box .game-top .group-tools .icon-close2 {
  margin-left: 0.2rem;
}

.big-banner .game-box .game-content {
  height: 8rem;
  display: flex;
  justify-content: center;
}

.big-banner .game-box .game-bottom {
  height: 0.88rem;
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.slide-menu {
  position: absolute;
  right: 0;
  top: 2.75rem;
  z-index: 10;
}

.slide-menu.fix-top {
  position: fixed;
  top: 1.25rem;
  z-index: 10;
}

.slide-menu.fix-top li {
  background: #005329;
}

.slide-menu li {
  width: 0.98rem;
  height: 0.83rem;
  background: rgba(0,83,41,.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  margin-bottom: 0.06rem;
  font-weight: bold;
  cursor: pointer;
}

.slide-menu li>i,
.slide-menu li>svg {
  margin-bottom: 0.05rem;
}

.slide-menu li>span {
  text-transform: uppercase;
  font-size: 0.14rem;
  text-align: center;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .slide-menu li>span {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: uppercase;
}

.backTop-box {
  position: fixed;
  right: 1.14rem;
  bottom: 1.8rem;
  z-index: 10;
  cursor: pointer;
}

.backTop-box svg {
  width: 0.7rem;
}

.live-chat {
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  right: 0.2rem;
  bottom: 0.85rem;
  z-index: 100;
}

.live-chat .text {
  transition: opacity .3s;
  opacity: 0;
  margin-left: 0.1rem;
  font-size: 0.12rem;
  color: #39b549;
  font-weight: bold;
  background: #fff;
  padding: 0 0.05rem;
}

.live-chat .icon-box {
  width: 0.7rem;
  height: 0.7rem;
  background: #f3f3f3;
  border-radius: 50%;
}

.live-chat .icon-box:hover+.text {
  opacity: 1;
}

.live-chat.in-account {
  transform: translate(0, 0);
  right: 0.52rem;
  bottom: 0.3rem;
  top: auto;
}

.live-chat.in-account .text {
  margin-top: 0;
  color: #4fa94f;
  order: 1;
  margin-right: 0.12rem;
}

.live-chat.in-account .icon-box {
  background: #212121;
  order: 2;
}

.slider {
  width: 100%;
  height: 100%;
  position: relative;
}

.slider>ul.sli {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.slider>ul.sli>li {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.slider>ul.sli .slide-enter {
  left: -100%;
}

.slider>ul.sli .slide-enter-active {
  left: 0;
  transition: left 500ms;
}

.slider>ul.sli .slide-exit {
  left: 0;
}

.slider>ul.sli .slide-exit-active {
  left: 100%;
  transition: left 500ms;
}

.slider>ul.sli .slide-exit-done {
  opacity: 0;
}

.slider>ul.sli .fade-enter {
  opacity: 0;
}

.slider>ul.sli .fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
  z-index: 2;
}

.slider>ul.sli .fade-exit {
  opacity: 1;
}

.slider>ul.sli .fade-exit-active {
  opacity: 1;
  transition: opacity 500ms;
}

.slider>ul.sli .fade-exit-done {
  visibility: hidden;
}

.slider>ul.sli .fade1-enter {
  opacity: 0;
}

.slider>ul.sli .fade1-enter-active {
  opacity: 1;
  transition: opacity 1s;
  z-index: 2;
}

.slider>ul.sli .fade1-enter-done {
  z-index: 2;
}

.slider>ul.sli .fade1-exit-done {
  visibility: hidden;
}

.slider>ul.sli .each-spread-enter>ul>li {
  transform: scaleY(0);
  transform-origin: top;
}

.slider>ul.sli .each-spread-enter-active>ul>li {
  transform: scaleY(1);
  transition: all 500ms;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.slider>ul.sli .each-spread-exit>ul>li {
  transform: scaleY(1);
  transform-origin: bottom;
}

.slider>ul.sli .each-spread-exit-active>ul>li {
  transform: scaleY(0);
  transition: all 500ms;
}

.slider>ul.sli .each-spread-exit-done {
  visibility: hidden;
}

.slider>ul.sli.left .slide-enter {
  left: 100%;
}

.slider>ul.sli.left .slide-enter-active {
  left: 0;
}

.slider>ul.sli.left .slide-exit-active {
  left: -100%;
}

.slider>ul.tab {
  position: absolute;
  top: -0.42rem;
  right: -0.36rem;
  z-index: 3;
}

.notice {
  width: 9rem;
  margin: 0.2rem auto 0.45rem;
  display: flex;
  align-items: center;
  font-family: OpenSans;
  cursor: pointer;
}

.notice .content {
  font-size: 0.16rem;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.notice .msg-enter,
.notice .msg-exit {
  transform: translateX(60vw) translateZ(0);
}

.notice .msg-enter-active,
.notice .msg-exit-active {
  transform: translateX(-100%) translateZ(0);
}

@keyframes noticeFade {
  0% {
    transform: translateX(60vw) translateZ(0);
  }

  100% {
    transform: translateX(-100%) translateZ(0);
  }
}

.noticeText-box {
  list-style: circle;
}

.noticeText-box li {
  list-style: unset;
  padding-bottom: 0.3rem;
  margin-left: 0.25rem;
}

.noticeText-box li:last-child() {
  padding-bottom: unset;
}

.banner-box {
  background: #13242a;
  height: 4.25rem;
  position: relative;
  z-index: 1;
}

.banner-box .banner {
  height: 4.25rem;
  width: 20rem;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.banner-box .banner .banner-item {
  width: 100%;
  height: 100%;
  position: relative;
}

.banner-box .banner ul.sli li {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.banner-box .banner ul.sli .content-container {
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}

.banner-box .banner ul.sli img,
.banner-box .banner ul.sli svg {
  height: 100%;
  width: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.banner-box .banner ul.sli:nth-child(1) .slide-text-box {
  position: absolute;
  left: 0.7rem;
  top: 0.42rem;
  width: 6.2rem;
  padding-top: 0.2rem;
  overflow: hidden;
}

.banner-box .banner ul.sli:nth-child(1) .slide-text-box>p {
  font-style: italic;
  width: 100%;
  max-width: 5.3rem;
}

.banner-box .banner ul.sli:nth-child(1) .slide-text-box>p.text-banner-content {
  margin-top: 0.17rem;
  font-size: 0.22rem;
  line-height: 0.28rem;
  color: #c59b4e;
}

.banner-box .banner ul.sli:nth-child(1) .slide-text-box>p:first-child {
  max-width: unset;
  line-height: 1.1;
}

.banner-box .banner ul.sli:nth-child(1) .slide-text-box .text-banner-content {
  font-size: 0.2rem;
  font-weight: lighter;
  line-height: 0.28rem;
  color: #fff;
  font-family: "AvenirNextLTPro";
}

html:lang(vi) .banner-box .banner ul.sli:nth-child(1) .slide-text-box .text-banner-content {
  font-family: "Alexandria";
  font-weight: 300;
}

.banner-box .banner ul.sli:nth-child(1) .home-slide>p {
  overflow: hidden;
  white-space: nowrap;
}

.banner-box .banner ul.sli:nth-child(1) .home-slide>p.text-banner-content {
  white-space: unset;
}

.banner-box .banner ul.sli:nth-child(1) .to-claim {
  position: absolute;
  left: 4rem;
  bottom: 0.62rem;
  font-family: Roboto;
  font-weight: bold;
  font-style: italic;
  color: #222;
  font-size: 0.24rem;
}

.banner-box .banner ul.sli:nth-child(1) .to-claim span {
  font-weight: bold;
  font-family: "AvenirNextLTPro-Bold";
  white-space: nowrap;
}

.banner-box .banner ul.sli:nth-child(1) .to-play {
  bottom: 0.45rem;
  left: 3.9rem;
}

.banner-box .banner ul.sli:nth-child(1) .to-demo {
  background: #39b549;
}

.banner-box .banner .slick-slider {
  height: 100%;
}

.banner-box .banner .slick-slider .slick-list {
  height: 100%;
}

.banner-box .banner .slick-slider .slick-list .slick-track {
  height: 100%;
}

.banner-box .banner .slick-slider .slick-list .slick-track .slick-slide {
  height: 100%;
}

.banner-box .banner .slick-slider .slick-list .slick-track .slick-slide>div {
  height: 100%;
}

.banner-box .banner .slick-slider .slick-list .slick-track .slick-slide>div img {
  width: 100%;
  height: 100%;
}

.banner-box .banner ul.tab,
.banner-box .banner ul.slick-dots {
  transform: translateX(-6rem);
  left: 44%;
  top: 3.88rem;
  width: unset;
  bottom: unset;
  text-align: unset;
}

.banner-box .banner ul.tab li,
.banner-box .banner ul.slick-dots li {
  display: inline-block;
  width: 0.32rem;
  height: 0.08rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  background: #e2dcde;
  margin: 0;
  margin-left: 0.07rem;
}

.banner-box .banner ul.tab li.slick-active,
.banner-box .banner ul.slick-dots li.slick-active {
  background: #39b549;
  cursor: default;
}

.banner-box .banner ul.tab li button,
.banner-box .banner ul.slick-dots li button {
  width: unset;
  height: unset;
  padding: unset;
}

.banner-box .banner ul.tab li button::before,
.banner-box .banner ul.slick-dots li button::before {
  font-family: unset;
}

.nav-menu .icon-raffle {
  width: 0.39rem;
  height: 0.3rem;
}

.nav-menu .icon-download {
  width: 0.2rem;
  height: 0.3rem;
}

.nav-menu .icon-contact {
  width: 0.31rem;
  height: 0.31rem;
}

.nav-menu .icon-chat,
.nav-menu .icon-chat-white {
  width: 0.3rem;
  height: 0.3rem;
}

.nav-menu .icon-lan,
.nav-menu .icon-lan-white {
  width: 0.29rem;
  height: 0.29rem;
}

.account-box {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  z-index: 20;
}

.account-box .account-content {
  width: 100vw;
  background: #f0f0f0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.account-box .account-content.account-content-liteapp {
  background: url("/public/html/images/login/login_bg.jpg");
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.account-box .account-content .account-box-top {
  height: 0.6rem;
  background: #0a9749;
  position: relative;
}

.account-box .account-content .account-box-top .icon-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.3rem;
}

.account-box .account-content .account-box-top .tabs-box {
  display: flex;
  align-items: center;
  height: 100%;
}

.account-box .account-content .account-box-top .tabs-box .btn-skew {
  font-family: OpenSans;
  width: auto;
  padding: 0 0.4rem;
  height: 100%;
  font-size: 0.16rem;
  font-weight: normal;
  background: rgba(0,0,0,0);
  color: #fff;
  text-transform: uppercase;
}

.account-box .account-content .account-box-top .tabs-box .btn-skew.on {
  background: #fff;
  color: #0a974b;
}

.account-box .account-content .account-main-content {
  height: calc(100% - 0.6rem);
  overflow: auto;
}

.account-box .account-content .account-main-content .account-box-header {
  padding: .1rem .75rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.account-box .account-content .account-main-content .account-box-header img {
  width: 100%;
}

.account-box .account-content .account-main-content .account-box-footer {
  text-align: center;
  font-size: .1rem;
  color: #919191;
  height: 1rem;
  margin: .5rem 0 0;
  padding: 0 .5rem;
}

.account-box .account-content .account-main-content .account-box-footer .select-lang {
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-box .account-content .account-main-content .account-box-footer .select-lang svg {
  width: .15rem;
  margin: 0 .05rem 0 0;
}

.account-box .account-content .account-main-content ul li {
  position: relative;
  font-size: 0.14rem;
  margin-bottom: 0.14rem;
}

.account-box .account-content .account-main-content ul li label {
  display: block;
  color: #3b3b3b;
  height: 0.26rem;
  margin-bottom: -0.03rem;
}

.account-box .account-content .account-main-content ul li .input-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.account-box .account-content .account-main-content ul li .input-box .bio-container {
  position: absolute;
  width: .75rem;
  z-index: 1;
  right: 0;
  margin: .1rem .15rem 0 0;
}

.account-box .account-content .account-main-content ul li .input-box select {
  height: 0.42rem;
  width: 0.88rem;
  border: 1PX solid #cecece;
  border-radius: 0.02rem;
  margin-right: 0.05rem;
  color: #aaa;
}

.account-box .account-content .account-main-content ul li .input-box .btn {
  background: #fff;
  height: 0.42rem;
  width: 0.88rem;
  border: 1PX solid #cecece;
  border-radius: 0.02rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #0a974b;
  font-size: 0.14rem;
  font-weight: bold;
  border-left: none;
}

.account-box .account-content .account-main-content ul li .input-box .btn-show {
  background: #0a974b;
  color: #fff;
}

.account-box .account-content .account-main-content ul li .input-box .icon-box {
  width: 0.58rem;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.account-box .account-content .account-main-content ul li input {
  width: 100%;
  height: 0.42rem;
  border: 1PX solid #cecece;
  border-radius: 0.02rem;
  padding-left: 0.1rem;
  outline: none;
  font-size: 0.14rem;
}

.account-box .account-content .account-main-content ul li input::-webkit-input-placeholder {
  color: #aaa;
}

.account-box .account-content .account-main-content ul li input:-moz-input-placeholder {
  color: #aaa;
}

.account-box .account-content .account-main-content ul li input::-moz-input-placeholder {
  color: #aaa;
}

.account-box .account-content .account-main-content ul li input:-ms-input-placeholder {
  color: #aaa;
}

.account-box .account-content .account-main-content ul li input:focus {
  border: 1PX solid #65a16b;
}

.account-box .account-content .account-main-content ul li p {
  height: 0.24rem;
  line-height: 0.24rem;
}

.account-box .account-content .account-main-content ul li .getOtp-button {
  cursor: pointer;
  float: right;
  color: #65a16b;
}

.account-box .account-content .account-main-content ul li .standard-password-eye {
  position: absolute;
  top: .3rem;
  right: .15rem;
}

.account-box .account-content .account-main-content ul li .register-form-error-message {
  font-size: 0.11rem;
  display: flex;
  gap: 0.06rem;
  align-items: center;
}

.account-box .account-content .account-main-content ul li .register-form-error-message img {
  width: 0.14rem;
  height: 0.14rem;
}

.account-box .account-content .account-main-content>div {
  padding: 0.44rem 0;
  width: 4.7rem;
}

.account-box .account-content .account-main-content .btn-normal,
.account-box .account-content .account-main-content .btn-normal-green,
.account-box .account-content .account-main-content .btn-long {
  margin: 0 auto;
  margin-top: 0.4rem;
}

.account-box .account-content .account-main-content .text {
  font-size: 0.14rem;
  color: #919191;
  padding-top: 0.2rem;
  text-align: center;
}

.account-box .account-content .account-main-content .text span {
  color: #0a974b;
  cursor: pointer;
}

.account-box .account-content .account-main-content .create-acount.create-account-liteapp {
  background: #fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.account-box .account-content .account-main-content .create-acount>ul {
  padding: 0 0.45rem;
}

.account-box .account-content .account-main-content .create-acount .btn-normal,
.account-box .account-content .account-main-content .create-acount .btn-normal-green,
.account-box .account-content .account-main-content .create-acount .btn-long {
  background: #0a9749;
  color: #fff;
  margin: 0.4rem auto 0;
  font-size: 0.14rem;
}

.account-box .account-content .account-main-content .create-acount .member-benefits {
  width: 3.76rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.account-box .account-content .account-main-content .create-acount .member-benefits .img-box {
  width: 100%;
  height: 1.61rem;
  margin-bottom: 0.12rem;
}

.account-box .account-content .account-main-content .create-acount .member-benefits .img-box img {
  display: block;
  width: 100%;
  height: 100%;
}

.account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box {
  width: 3.76rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 0.01rem solid #ddd;
  padding: 0.3rem 0.32rem;
  font-family: Arial;
  background: #fff;
}

.account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box>p {
  font-size: 0.18rem;
  font-weight: 400;
  color: #3b3b3b;
  line-height: 0.14rem;
}

.account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box .benefits-list {
  margin-top: 0.25rem;
  font-weight: 400;
  padding-left: 0.15rem;
}

.account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box .benefits-list li {
  list-style-type: disc;
  color: #27795b;
  font-size: 0.12rem;
}

.account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box .benefits-list li p {
  font-size: 0.13rem;
  line-height: 0.16rem;
  color: #747474;
}

.account-box .account-content .account-main-content .create-acount .telCode-img {
  background-image: url("/public/html/images/icon/imgTel-MYR.jpg");
}

.account-box .account-content .account-main-content .create-acount .err-summary-container .text-red {
  padding: .2rem .7rem 0;
  text-align: center;
}

.account-box .account-content .account-main-content .create-acount .turnstile-widget-register {
  margin: 0.2rem;
}

.account-box .account-content .account-main-content .create-acount .turnstile-widget-register .turnstile-wrapper {
  display: flex;
  justify-content: center;
}

.account-box .account-content .account-main-content .login {
  margin: 0 .25rem;
}

.account-box .account-content .account-main-content .login.login-liteapp {
  background: #fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.account-box .account-content .account-main-content .login>ul {
  padding: 0 0.45rem;
}

.account-box .account-content .account-main-content .login>ul .username {
  margin-bottom: 0;
}

.account-box .account-content .account-main-content .login>ul .enter-username {
  display: flex;
  margin-bottom: 0.14rem;
}

.account-box .account-content .account-main-content .login ul li input {
  padding-left: 0.55rem;
  z-index: 1;
}

.account-box .account-content .account-main-content .login .btn-normal,
.account-box .account-content .account-main-content .login .btn-normal-green,
.account-box .account-content .account-main-content .login .btn-long {
  margin-bottom: 0.2rem;
}

.account-box .account-content .account-main-content .login .link {
  text-decoration: underline;
  text-align: center;
  font-size: 0.14rem;
  height: 0.4rem;
  text-align: center;
  cursor: pointer;
  width: 2.2rem;
  margin: auto;
}

.account-box .account-content .account-main-content .login .link.text-gray {
  color: #919191;
}

.account-box .account-content .account-main-content .login .link.text-green {
  color: #0a974b;
}

.account-box .account-content .account-main-content .login .turnstile-widget-login,
.account-box .account-content .account-main-content .login .turnstile-widget-forgot {
  margin: 0.2rem;
}

.account-box .account-content .account-main-content .login .turnstile-widget-login .turnstile-wrapper,
.account-box .account-content .account-main-content .login .turnstile-widget-forgot .turnstile-wrapper {
  display: flex;
  justify-content: center;
}

.account-box .account-content .account-main-content .completed {
  padding: 0.44rem 0.3rem;
}

.account-box .account-content .account-main-content .completed .completed-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.account-box .account-content .account-main-content .completed .completed-main p.text-big {
  font-size: 0.4rem;
  margin-top: 0.2rem;
}

.account-box .account-content .account-main-content .completed .completed-main p.text-small {
  font-size: 0.3rem;
  text-align: center;
}

.account-box .account-content .account-main-content .completed .btn-normal,
.account-box .account-content .account-main-content .completed .btn-normal-green,
.account-box .account-content .account-main-content .completed .btn-long {
  background: #0a9749;
  color: #fff;
  margin: 0 auto;
  margin-top: 0.4rem;
  font-size: 0.14rem;
}

.menu-side-box {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  z-index: 20;
}

.menu-side-box .menu-side {
  background: #13232e;
  width: 4.7rem;
  position: absolute;
  right: 0;
  top: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.menu-side-box .menu-side .nav-menu {
  padding-top: 0.65rem;
  padding-bottom: calc(100vw - 9.5rem);
}

.menu-side-box .menu-side .nav-menu li {
  color: #39b549;
  display: flex;
  align-items: center;
  height: 0.86rem;
  font-size: 0.28rem;
  padding-left: 1.83rem;
  position: relative;
  cursor: pointer;
}

.menu-side-box .menu-side .nav-menu li .icon-box {
  width: 0.88rem;
  position: absolute;
  left: 0.64rem;
  top: 50%;
  transform: translateY(-50%) scale(1.8);
}

.menu-side-box .menu-side .nav-menu li:hover {
  color: #fff;
}

.new-games-released {
  position: fixed;
  right: 0;
  bottom: 8%;
  transform: translateY(-50%);
  z-index: 10;
  width: 3.95rem;
  height: 2.25rem;
  background: #f2f2f2;
  border: 0.02rem solid #d5d5d5;
  border-top-left-radius: 0.1rem;
}

.new-games-released .top {
  border-top-left-radius: 0.1rem;
  height: 0.55rem;
  background: #1a723d;
  font-size: 0.2rem;
  font-weight: normal;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
}

.new-games-released .top .icon-box {
  width: 0.57rem;
  height: 100%;
}

.new-games-released .top .icon-close {
  position: absolute;
  right: 0.11rem;
  top: 50%;
  transform: translateY(-50%);
}

.new-games-released .content {
  padding: 0.11rem 0.22rem;
  display: flex;
  align-items: center;
}

.new-games-released .content img {
  width: 1.42rem;
  height: 1.42rem;
  margin-right: 0.26rem;
  border: 0.01rem solid #d5d5d5;
}

.new-games-released .content .right>p {
  font-size: 0.22rem;
  font-weight: normal;
  color: #000;
}

.new-games-released .content .right div {
  font-size: 0.15rem;
  font-weight: 400;
  color: #000;
  opacity: .6;
  margin-top: 0.15rem;
}

.new-games-exit {
  right: 0;
}

.new-games-exit-active {
  right: -3.95rem;
  transition: right 300ms;
}

.ad-box {
  position: absolute;
  left: 50%;
  top: 7.88rem;
  transform: translate(-50%, 0);
  width: 20rem;
  height: 5.5rem;
  z-index: 9;
}

.ad-box img {
  display: block;
  width: 100%;
  height: 100%;
}

.ad-box .content-container {
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ad-box .content-container .icon-close {
  position: absolute;
  right: 0.32rem;
  top: 0.32rem;
}

.promotion-section {
  padding-top: 0;
  padding-bottom: 0;
  background: rgba(0,0,0,0);
}

.promotion-section .w-100 {
  width: 100%;
}

.promotion-section>div.d-flex {
  padding: 5% 0;
}

.promotion-section .promotion-box {
  max-height: 3.5rem;
  padding: 0 5%;
}

.promotion-section .promotion-box .promotion-image {
  width: 100%;
  height: calc(12vh + 1rem);
}

.promotion-section .promotion-box .promotion-content-detail {
  padding: 0;
  background: rgba(0,0,0,0);
  width: 100%;
  min-height: unset;
}

.promotion-section .promotion-box .promotion-content-detail .promotion-text-container {
  padding: 0.05rem 0;
  height: 0.98rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.promotion-section .promotion-box .promotion-content-detail .promotion-text-container .promotion-content-title {
  color: #fff;
}

.promotion-section .promotion-box .promotion-content-detail .promotion-btn-container>div {
  width: 50% !important;
}

.promotion-section .promotion-box button {
  border-radius: 0.03rem;
}

.f-modal-alert {
  display: flex;
  justify-content: center;
}

.f-modal-alert .f-modal-icon {
  border-radius: 50%;
  border: 0.01rem solid #dd5600;
  box-sizing: content-box;
  height: 0.5rem;
  margin: 0.1rem 0;
  padding: 0;
  position: relative;
  width: 0.5rem;
}

.f-alert-text {
  text-align: center;
}

.f-alert-text span {
  color: red;
  cursor: pointer;
}

.f-modal-warning {
  border-color: #dd5600;
}

.f-modal-warning:before {
  animation: pulseWarning 2s linear infinite;
  background-color: #fff;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.f-modal-warning:after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.f-modal-warning .f-modal-body {
  background-color: #dd5600;
  border-radius: 0.02rem;
  height: 0.2rem;
  left: 50%;
  margin-left: -0.02rem;
  position: absolute;
  top: 0.1rem;
  width: 0.05rem;
  z-index: 2;
}

.f-modal-warning .f-modal-dot {
  background-color: #dd5600;
  border-radius: 50%;
  bottom: 0.1rem;
  height: 0.07rem;
  left: 50%;
  margin-left: -0.03rem;
  position: absolute;
  width: 0.07rem;
  z-index: 2;
}

.scaleWarning {
  animation: scaleWarning .75s infinite alternate;
}

.pulseWarningIns {
  animation: pulseWarningIns .75s infinite alternate;
}

.sort-box {
  width: 14.14rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 0.88rem;
}

.sort-box li {
  height: 100%;
}

.sort-box li .sort-item {
  width: 2.02rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.16rem;
  color: #858585;
  border-right: 0.01rem solid #cfcfcf;
  position: relative;
}

.sort-box li .sort-item.active>div:nth-child(1) {
  background: #1a713f !important;
  color: #fff;
}

.sort-box li .sort-item.active .sort-select {
  background: #e1dee2;
  color: #858585;
}

.sort-box li .sort-item>div {
  transition: background .3s,color .3s;
  padding-left: 0.25rem;
  padding-right: 0.3rem;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sort-box li .sort-item .sort-select {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222;
}

.sort-box li .sort-item .sort-select .spread {
  position: absolute;
  left: 0;
  top: 0.88rem;
  width: 100%;
  height: 2.5rem;
  background: #e1dee2;
  z-index: 1;
  overflow: auto;
  padding: 0.2rem 0;
}

.sort-box li .sort-item .sort-select .spread li {
  height: 0.27rem;
  padding-left: 0.25rem;
  color: #222;
  transition: color .3s;
  white-space: nowrap;
}

.sort-box li .sort-item .sort-select .spread li:hover {
  color: #216e43;
}

.sort-box li:last-child {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

@keyframes pulseWarning {
  0% {
    background-color: #fff;
    transform: scale(1);
    opacity: .5;
  }

  30% {
    background-color: #fff;
    transform: scale(1);
    opacity: .5;
  }

  100% {
    background-color: #dd5600;
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulseWarningIns {
  0% {
    background-color: #dd5600;
  }

  100% {
    background-color: #dd5600;
  }
}

.status-box {
  display: flex;
  align-items: center;
  padding-left: 1.3rem;
  margin-top: 0.34rem;
}

@media (min-width: 1441px) {
  .menu-side-box {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1025px) {
  html {
    font-size: 100PX;
  }

  #root>div>.screen {
    padding-top: 1.19rem;
  }

  #root>div>.add-topGap {
    padding-top: 2.1rem;
  }

  #js-footer {
    padding-bottom: 1.11rem;
  }

  .footer-bottom .nav-menu li {
    width: 1.4rem;
    font-size: 0.14rem;
  }

  .footer-bottom .nav-menu li .icon-box {
    margin-bottom: 0.1rem;
    transform: scale(1.5);
  }

  .account-box.slide-right-exit-active {
    right: -4.7rem;
  }
}

@media (max-width: 1445px) {
  html {
    font-size: 6.25vw;
  }
}

@media (min-width: 1025px) {
  .account-box .account-content {
    width: 4.7rem;
  }

  .menu-side-box {
    background: rgba(0,0,0,0);
    width: 14.4rem;
    position: absolute;
    top: 0.45rem;
    pointer-events: none;
    left: 58%;
    transform: translateX(-50%);
  }

  .menu-side-box .menu-side {
    position: absolute;
    top: 0;
    right: 1.5rem;
    width: 2.11rem;
    pointer-events: auto;
    background: #13242c;
    padding-top: 0;
    font-size: 0.13rem;
    cursor: default;
    z-index: 1;
  }

  .menu-side-box .menu-side .nav-menu {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  .menu-side-box .menu-side .nav-menu li {
    padding-left: 0.92rem;
    height: 0.43rem;
    cursor: pointer;
    font-size: 0.14rem;
    display: flex;
    align-items: center;
  }

  .menu-side-box .menu-side .nav-menu li.on {
    background: #19723c;
    color: #fff;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.6rem;
    left: 0.24rem;
    transform: translateY(-50%) scale(1);
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .content-center-box .provider-bar .provider {
    padding-top: 0.05rem;
  }

  .content-center-box .provider-bar .provider .provider-bar-FG {
    height: 0.4rem;
  }

  .backTop-box {
    right: 0.5rem;
    bottom: 2.8rem;
  }

  .backTop-box svg {
    width: 1rem;
  }

  .notice .msg-enter,
  .notice .msg-exit {
    transform: translateX(80vw) translateZ(0);
  }

  .notice .msg-enter-active,
  .notice .msg-exit-active {
    transform: translateX(-100%) translateZ(0);
  }

@keyframes noticeFade {
    0% {
      transform: translateX(80vw) translateZ(0);
    }

    100% {
      transform: translateX(-100%) translateZ(0);
    }
}

  .status-box {
    padding-left: 1.95rem;
    margin-top: 0.51rem;
  }

  .main-layout {
    min-height: 8.4rem;
  }

  .bg-footer-bottom.fix-top {
    top: 1.88rem;
  }

  html {
    font-size: 4.8828125vw;
  }

  .content-container,
  #seo-footer {
    width: 100%;
  }

  #seo-footer {
    padding: 0.1rem 1.8rem 0.1rem 1rem;
  }

  #seo-footer>div .content-title {
    font-size: 0.24rem;
    color: #39b549;
  }

  #seo-footer>div .content-text {
    font-size: 0.22rem;
    color: #757575;
  }

  #root>div>.screen {
    padding-top: 2.05rem;
  }

  .live-chat {
    right: 0.4rem;
    bottom: 1.7rem;
    z-index: 100;
  }

  .live-chat .text {
    margin-left: 0.4rem;
    font-size: 0.24rem;
    padding: 0 0.1rem;
  }

  .live-chat .icon-box {
    transform: scale(2);
  }

  .ad-box {
    width: 100%;
  }

  .ad-box img {
    width: 100%;
    height: auto;
  }

  .header-top {
    height: 0.75rem;
    font-size: 0.24rem;
  }

  .header-top .date-time {
    width: 4.3rem;
  }

  .header-top .lan-select-box {
    height: 100%;
  }

  .header-top .lan-select-box .lan-label {
    margin-left: 0.14rem;
    margin-right: 0.24rem;
  }

  .header-top .lan-select-box .current-lan-box .current-lan {
    font-size: 0.21rem;
    margin: 0 0.18rem;
  }

  .header-top .lan-select-box .lan-list-box {
    font-size: 0.21rem;
    top: 0.44rem;
    left: -0.3rem;
  }

  .header-top .lan-select-box .lan-list-box ul li {
    height: 0.88rem;
  }

  .header-top .user-info {
    right: 0.92rem;
    font-size: 0.18rem !important;
  }

  .header-top .user-info>span {
    font-size: 0.21rem;
  }

  .header-top .user-info .message .msg-count-box {
    width: 0.31rem;
    height: 0.31rem;
    font-size: 0.15rem;
    top: -0.15rem;
    left: 0.27rem;
  }

  .header-top .user-info .message:hover .headerMsg-box {
    transform: translateY(0.35rem);
  }

  .header-top .header-nav-tools {
    font-size: 0.24rem;
  }

  .header-top .header-nav-tools .drop-menu-box {
    display: none;
  }

  .header-top .header-nav-tools>* {
    margin-left: 0.32rem;
  }

  .header-top .header-nav-tools>span.register-btn {
    width: 1.8rem;
    height: 0.4rem;
  }

  .header-top .header-nav-tools .icon-game-menu {
    margin-left: 0.32rem;
  }

  .header-top .header-nav-tools.logined .text-yellow,
  .header-top .header-nav-tools.logined .user-info,
  .header-top .header-nav-tools.logined .slide-menu li,
  .slide-menu .header-top .header-nav-tools.logined li {
    font-size: 0.24rem;
  }

  .header-top .header-nav-tools.logined>div .wallet p .number,
  .header-top .header-nav-tools.logined>div .wallet span.fund-box .number {
    font-size: 0.28rem;
    margin-left: 0.28rem;
    margin-right: 0.2rem;
  }

  .header-top .header-nav-tools.logined>div .wallet .split {
    margin-left: 0.22rem;
    margin-right: 0.14rem;
  }

  .header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread {
    left: -0.56rem;
    top: 0.6rem;
    width: 2.31rem;
    height: 2.7rem;
  }

  .header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li {
    height: 0.6rem;
    font-size: 0.2rem;
  }

  .header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box {
    width: 1.01rem;
    height: 100%;
  }

  .header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box .icon-deposit {
    width: 0.45rem;
    height: 0.5rem;
  }

  .header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box .icon-transfer {
    width: 0.53rem;
    height: 0.39rem;
  }

  .header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box .icon-withdraw {
    width: 0.44rem;
    height: 0.39rem;
  }

  .header-top .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box .icon-report {
    width: 0.39rem;
    height: 0.38rem;
  }

  .header-top .header-nav-tools.logined>div .wallet .fund-box .icon-arrow-down {
    width: 0.14rem;
    height: 0.09rem;
    margin-left: 0.12rem;
  }

  .header-top .header-nav-tools.logined>div>span.deposit-btn {
    width: 1.8rem;
    height: 0.4rem;
  }

  .header-bottom {
    height: 1.3rem;
  }

  .header-bottom .header-logo-box {
    width: 5.3rem;
    height: 1.3rem;
  }

  .header-bottom .header-logo-box .header-triangle-box {
    width: 15%;
    border-bottom: 1.3rem solid #19723c;
    border-left: 0.5rem solid rgba(0,0,0,0);
  }

  .header-bottom .header-separator-triangle-box {
    height: 1.3rem;
    width: 1.2rem;
  }

  .header-bottom .header-menu-box {
    width: 13.8rem;
    margin-right: 0.34rem;
  }

  .header-bottom .header-menu-box .menu-name {
    font-size: 0.24rem;
  }

  .banner-box {
    height: 5.63rem;
  }

  .banner-box .banner {
    height: 5.63rem;
    width: 20.48rem;
  }

  .banner-box .banner .slider ul.tab {
    top: 4.88rem;
    left: 33.1%;
  }

  .banner-box .banner .slider ul.tab li:last-child::after {
    right: 0;
  }

  .banner-box .banner .slider ul.sli img {
    height: 100%;
    width: auto;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box {
    left: 1.8rem;
    top: 0.46rem;
    width: 7.5rem;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box>p {
    max-width: 6rem;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box>p.text-banner-white {
    font-size: 0.72rem;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box>p.text-banner-green {
    font-size: 0.88rem;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box>p.text-banner-content {
    font-size: 0.3rem;
    line-height: 0.38rem;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box>p:first-child {
    max-width: unset;
    line-height: unset;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .to-claim {
    left: 6.05rem;
    bottom: 0.82rem;
  }

  .banner-box .banner ul.slick-dots {
    top: 4.88rem;
    right: 2.8rem;
    transform: unset;
  }

  .banner-box .banner ul.slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .big-banner {
    height: 13.26rem;
    padding-top: 0.99rem;
    padding-bottom: 0.66rem;
  }

  .big-banner .game-box {
    width: 15.78rem;
    height: 11.64rem;
  }

  .big-banner .game-box .game-top {
    height: 1rem;
  }

  .big-banner .game-box .game-content {
    height: 9.64rem;
  }

  .big-banner .game-box .game-content img {
    height: 100%;
    width: auto;
  }

  .big-banner .game-box .game-bottom {
    height: 1rem;
  }

  .slide-menu {
    top: 3.2rem;
  }

  .slide-menu.fix-top {
    top: 3.75rem;
  }

  .slide-menu li {
    width: 1.68rem;
    height: 1.42rem;
  }

  .slide-menu li>span {
    font-size: 0.2rem;
  }

  .slide-menu-top {
    top: 3.77rem;
  }

  .menu-side-box .menu-side {
    width: 4.22rem;
    overflow-y: auto;
    height: 100%;
  }

  .notice {
    width: 100%;
    height: 1rem;
    padding-left: 1.5rem;
    margin-left: 0;
    margin-bottom: 0;
  }

  .notice .content {
    font-size: 0.26rem;
    white-space: nowrap;
  }

  .content-section {
    padding: 0 0.7rem;
  }

  .content-top {
    height: 1.2rem;
    padding: 0.34rem 0.24rem;
  }

  .content-title {
    font-size: 0.32rem;
  }

  .footer-bottom {
    height: 1.8rem;
    overflow: auto;
  }

  .footer-bottom .nav-menu {
    justify-content: flex-start;
  }

  .footer-bottom .nav-menu li {
    width: 1.85rem;
    flex: 0 0 auto;
    font-size: 0.26rem;
    margin: auto;
  }

  .bg-content-section .nav-game li {
    width: 3.1rem;
    height: 2.6rem;
    margin-bottom: 0.32rem;
  }

  .bg-content-section .nav-game li>p {
    height: 0.69rem;
  }

  .bg-content-section .nav-game li>p .gamename {
    font-size: 0.2rem;
    left: 0.72rem;
    width: 2.2rem;
  }

  .bg-content-section .nav-game li>p .icon-love,
  .bg-content-section .nav-game li>p .icon-love-on {
    transform-origin: 0 center;
    transform: scale(1.5);
  }

  .bg-content-section .nav-game li>p .icon-box {
    transform-origin: 100% center;
    transform: scale(1.7);
  }

  .bg-content-section .nav-game li .hover-box {
    width: 4.88rem;
    height: 4rem;
  }

  .bg-content-section .nav-game li .hover-box .game-item-info {
    height: 0.84rem;
  }

  .bg-content-section .nav-game li .hover-box .game-item-info p {
    font-size: 0.24rem;
  }

  .bg-content-section .nav-game li .hover-box .game-item-info>div {
    font-size: 0.2rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box {
    width: 4.3rem;
    height: 2.82rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu .btn-long {
    margin-top: 0.4rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu .btn-normal-green {
    margin-top: 0.24rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu div {
    margin-top: 0.48rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu div>i,
  .bg-content-section .nav-game li .hover-box .img-box .item-menu div .icon-love,
  .bg-content-section .nav-game li .hover-box .img-box .item-menu div .icon-love-on {
    transform: scale(1.5);
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu * {
    font-size: 0.24rem !important;
  }

  .bg-content-section .nav-game li>img {
    max-height: 1.8rem;
  }

  .bg-content-section .content-promotions .content-center {
    height: 6.78rem;
    padding: 0 0.84rem;
  }

  .bg-content-section .content-promotions .content-center .slick-list {
    max-height: unset;
    padding: 0.5rem 0 0.8rem;
  }

  .bg-content-section .content-promotions .content-center .slick-list li {
    width: 5rem;
    height: 4rem;
  }

  .bg-content-section .content-promotions .content-center .promoBox li {
    width: 5rem;
    height: 4rem;
  }

  .bg-content-section .content-promotions .content-center .img-box img {
    max-width: 100%;
  }

  .bg-content-section .content-promotions .content-center .promoBox-content {
    line-height: 1;
    font-size: 0.35rem;
    padding-left: 0.1rem;
    padding-top: 0.22rem;
  }

  .bg-content-section .content-promotions .content-center .promoBox-content .promoBox-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 100%;
  }

  .bg-content-section .content-promotions .content-center .slick-dots {
    top: -0.8rem;
  }

  .bg-content-section .content-promotions .slick-prev-btn {
    margin-top: 1.9rem;
    height: 38.4%;
  }

  .bg-content-section .content-promotions .slick-next-btn {
    margin-top: 1.9rem;
    height: 38.4%;
  }

  .bg-content-section .content-maxim88 .maxim88-box {
    width: 44%;
  }

  .bg-content-section .content-maxim88 .maxim88-box::after {
    width: 44%;
    height: 0.5rem;
  }

  .bg-content-section .content-maxim88 .maxim88-box .box-title {
    font-size: 0.32rem;
  }

  .bg-content-section .content-maxim88 .maxim88-box .box-title span {
    font-size: 0.25rem;
  }

  .bg-content-section .content-maxim88 .maxim88-box .box-content {
    font-size: 0.25rem;
    height: 4.48rem;
  }

  .bg-content-section .content-maxim88 .maxim88-box .box-content .content-title {
    font-size: 0.25rem;
  }

  .bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider {
    touch-action: unset;
  }

  .bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-list .slick-track iframe {
    border: 0;
    height: 4.5rem !important;
  }

  .bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-dots {
    top: -0.65rem;
  }

  .bg-content-section .content-maxim88 .maxim88-video .video-slider-container .slick-slider .slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
  }

  .bg-content-section .content-maxim88 img,
  .bg-content-section .content-maxim88 svg {
    max-height: 6rem;
  }

  .bg-content-section .content-maxim88 img.img-signature,
  .bg-content-section .content-maxim88 svg.img-signature {
    max-width: 9.26rem;
  }

  .slider>ul.tab {
    top: -0.62rem;
  }

  .account-box .account-content {
    width: 8rem;
  }

  .account-box .account-content .account-box-top {
    height: 1.32rem;
  }

  .account-box .account-content .account-box-top .icon-close {
    width: 0.6rem;
    height: 0.6rem;
  }

  .account-box .account-content .account-box-top .tabs-box .btn-skew {
    font-size: 0.28rem !important;
    padding: 0 0.8rem !important;
  }

  .account-box .account-content .account-main-content {
    height: calc(100% - 1.32rem);
    margin: 0.75rem;
  }

  .account-box .account-content .account-main-content .account-box-header {
    padding: .1rem 1rem 0;
    text-align: center;
  }

  .account-box .account-content .account-main-content .account-box-footer {
    text-align: center;
    font-size: .2rem;
    color: #919191;
    height: 2rem;
    margin: .75rem 0;
    width: 100%;
  }

  .account-box .account-content .account-main-content .account-box-footer .select-lang {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .account-box .account-content .account-main-content .account-box-footer .select-lang svg {
    width: .2rem;
    margin: 0 .1rem 0 0;
  }

  .account-box .account-content .account-main-content .btn-normal,
  .account-box .account-content .account-main-content .btn-normal-green,
  .account-box .account-content .account-main-content .btn-long {
    font-size: 0.34rem !important;
    width: 3.61rem;
    height: 0.84rem;
  }

  .account-box .account-content .account-main-content>div {
    width: 100%;
  }

  .account-box .account-content .account-main-content>div>div {
    width: 7rem;
    margin: 0 auto;
    transform-origin: center top;
  }

  .account-box .account-content .account-main-content ul li {
    font-size: 0.26rem;
    margin-bottom: 0.3rem;
  }

  .account-box .account-content .account-main-content ul li label {
    height: 0.45rem;
  }

  .account-box .account-content .account-main-content ul li p {
    height: 0.56rem;
    line-height: 0.56rem;
  }

  .account-box .account-content .account-main-content ul li input {
    width: 100%;
    height: 0.8rem;
    font-size: 0.26rem;
  }

  .account-box .account-content .account-main-content ul li input[type=number]::-webkit-inner-spin-button,
  .account-box .account-content .account-main-content ul li input[type=number]::-webkit-outer-spin-button {
    height: 0.75rem;
    padding: 0.05rem;
  }

  .account-box .account-content .account-main-content ul li .input-box select {
    height: 0.8rem;
    width: 2rem;
    font-size: 0.26rem;
  }

  .account-box .account-content .account-main-content ul li .input-box .icon-box {
    width: 1.16rem;
  }

  .account-box .account-content .account-main-content ul li .input-box .btn {
    width: 2rem;
    height: 0.8rem;
    font-size: 0.26rem;
  }

  .account-box .account-content .account-main-content .text {
    font-size: 0.26rem;
    padding-top: 0.4rem;
  }

  .account-box .account-content .account-main-content .create-acount .btn-normal,
  .account-box .account-content .account-main-content .create-acount .btn-normal-green,
  .account-box .account-content .account-main-content .create-acount .btn-long {
    margin: 0.6rem auto 0;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits {
    width: 100%;
    margin-top: 1.2rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .img-box {
    height: 3.25rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0.6rem 0.5rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box>p {
    font-size: 0.35rem;
    line-height: 0.41rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box .benefits-list li {
    font-size: 0.2rem;
    margin-bottom: 0.2rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box .benefits-list li p {
    font-size: 0.26rem;
    line-height: 0.34rem;
    height: auto;
  }

  .account-box .account-content .account-main-content .login {
    width: 100%;
  }

  .account-box .account-content .account-main-content .login .btn-normal,
  .account-box .account-content .account-main-content .login .btn-normal-green,
  .account-box .account-content .account-main-content .login .btn-long {
    margin-top: 0.8rem;
    margin-bottom: 0.4rem;
  }

  .account-box .account-content .account-main-content .login ul li input {
    padding-left: 1.16rem;
  }

  .account-box .account-content .account-main-content .login .link {
    font-size: 0.3rem;
    height: 1rem;
    width: 5rem;
  }

  .new-games-released {
    width: 5.92rem;
    height: 3.37rem;
  }

  .new-games-released.new-games-exit-active {
    right: -5.92rem;
  }

  .new-games-released .top {
    border-top-left-radius: 0.1rem;
    height: 0.82rem;
    font-size: 0.3rem;
  }

  .new-games-released .top .icon-box {
    transform: scale(1.3);
  }

  .new-games-released .top .icon-close {
    right: 0.2rem;
    transform: translateY(-50%) scale(1.5);
  }

  .new-games-released .content {
    padding: 0.16rem 0.33rem;
    display: flex;
    align-items: center;
  }

  .new-games-released .content img {
    width: 2.13rem;
    height: 2.13rem;
    margin-right: 0.39rem;
  }

  .new-games-released .content .right>p {
    font-size: 0.33rem;
  }

  .new-games-released .content .right div {
    font-size: 0.22rem;
    margin-top: 0.22rem;
  }
}

@media (min-width: 769px) {
  .mb-only {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .noticeText-box li {
    margin-left: 0.6rem;
    font-size: 0.45rem;
  }

  .status-box {
    padding-left: 0.4rem;
    justify-content: flex-start;
  }

  .dt-only {
    display: none !important;
  }

  #root>div>.screen {
    padding-top: 1.32rem;
  }

  html {
    font-size: 8.0515297907vw;
  }

  html .Toastify .Toastify__toast {
    border-radius: 0.13rem;
  }

  .main-layout .bg-footer-bottom {
    bottom: auto;
  }

  .main-layout .bg-footer-bottom.fix-top {
    top: 1.3rem;
  }

  .main-layout .bg-footer-bottom.add-fix-top {
    top: 2.56rem;
    position: fixed;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .main-layout .home-footer-bottom {
    top: 8.3rem;
  }

  .main-layout.logined {
    padding-top: 2.61rem;
  }

  .main-layout.logined .bg-footer-bottom {
    top: 8.29rem;
  }

  .main-layout .menuChd {
    height: 1.5rem;
  }

  .main-layout .menuChd-icon {
    display: none !important;
  }

  .main-layout .menuChd-text {
    padding-bottom: 0.3rem;
  }

  .content-recommended .content-center-box,
  .content-promotions .content-center-box {
    overflow-x: scroll;
    overflow-y: unset;
    position: relative;
    z-index: 1;
    padding-top: 0.8rem;
  }

  .ad-box {
    z-index: 10;
  }

  .content-container,
  #seo-footer {
    width: 100%;
  }

  .header-top {
    height: 0;
    font-size: 0.22rem;
  }

  .header-top .date-time {
    padding-left: 0.4rem;
    width: 4.16rem;
  }

  .header-top .date-time {
    display: none !important;
  }

  .header-top .lan-select-box {
    height: 100%;
    display: none;
  }

  .header-top .lan-select-box>i,
  .header-top .lan-select-box>span {
    display: none !important;
  }

  .header-top .lan-select-box .lan-label {
    margin-left: 0.14rem;
    margin-right: 0.24rem;
  }

  .header-top .lan-select-box .current-lan-box {
    margin-left: 0.18rem;
  }

  .header-top .lan-select-box .current-lan-box .current-lan {
    font-size: 0.21rem;
    min-width: 0.9rem;
  }

  .header-top .lan-select-box .lan-list-box {
    font-size: 0.21rem;
    top: 0;
    left: 5.46rem;
  }

  .header-top .lan-select-box .lan-list-box ul {
    padding: 0.15rem 0.28rem;
  }

  .header-top .lan-select-box .lan-list-box ul li {
    height: 0.88rem;
  }

  .header-top .user-info {
    font-weight: 700;
    position: absolute;
    display: flex;
    align-items: center;
    font-family: Arial;
    height: 1.32rem;
    top: 0;
    right: 1.4rem;
    transform: translateY(0);
    z-index: 4;
  }

  .header-top .user-info>span {
    font-size: 0.32rem;
  }

  .header-top .user-info .deposit-btn {
    width: 2rem;
    height: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
    border-radius: 0.05rem;
    color: #222;
    font-weight: bold;
    font-family: "Roboto-Bold";
  }

  .header-top .user-info .message {
    margin-left: 0.5rem;
  }

  .header-top .user-info .message .icon-message {
    width: 0.6rem;
    height: 0.46rem;
    background: url("../assets/message2.png") no-repeat;
    background-size: 100% 100%;
  }

  .header-top .user-info .message>span,
  .header-top .user-info .message .headerMsg-box {
    display: none;
  }

  .header-top .user-info .message .msg-count-box {
    width: 0.45rem;
    height: 0.45rem;
    border-radius: 50%;
    border: 0.04rem solid #fff;
    background: #ec1c23;
    font-size: 0.23rem;
    color: #fff;
    font-family: "AvenirNextLTPro";
    display: unset;
    left: 0.4rem;
    top: -0.2rem;
  }

  .header-top .user-info .message .headerMsg-box>span {
    font-size: 0.32rem;
  }

  .header-top .user-info .message:hover .headerMsg-box {
    transform: translateY(0.55rem);
  }

  .live-chat {
    right: 0.2rem;
    bottom: 0.5rem;
    z-index: 100;
  }

  .live-chat .text {
    margin-right: 0.8rem;
    font-size: 0.24rem;
    padding: 0 0.1rem;
    order: 0;
  }

  .live-chat .icon-box {
    order: 1;
    transform: scale(2);
    transform-origin: right center;
  }

  .header-bottom {
    height: 1.32rem;
  }

  .header-bottom .header-logo-box {
    margin-left: 0;
    width: 3.7rem;
    transform-origin: 0 100%;
    height: 1.32rem;
  }

  .header-bottom .header-logo-box .header-triangle-box {
    width: 15%;
    border-bottom: 1.33rem solid #19723c;
    border-left: 0.5rem solid rgba(0,0,0,0);
  }

  .header-bottom .header-separator-triangle-box {
    height: 1.32rem;
    width: 1.32rem;
  }

  .header-bottom .header-nav-tools {
    font-size: 0.38rem;
    margin-left: auto;
    margin-right: 0.42rem;
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
  }

  .header-bottom .header-nav-tools .drop-menu-box {
    display: none;
  }

  .header-bottom .header-nav-tools>span {
    font-family: "Roboto-Bold";
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
  }

  .header-bottom .header-nav-tools>span.register-btn {
    background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
    color: #222;
    width: 2.8rem;
    border-radius: 0.08rem;
    white-space: nowrap;
    padding: 0.12rem 0;
    margin: 0 0.5rem 0 0;
  }

  .header-bottom .header-nav-tools>span.login-btn {
    background: unset;
    color: #2be53d;
    white-space: nowrap;
    margin: 0 0.5rem 0 0;
  }

  .header-bottom .header-nav-tools.logined {
    justify-content: flex-end;
  }

  .header-bottom .header-nav-tools.logined .text-yellow,
  .header-bottom .header-nav-tools.logined .header-top .user-info,
  .header-top .header-bottom .header-nav-tools.logined .user-info,
  .header-bottom .header-nav-tools.logined .slide-menu li,
  .slide-menu .header-bottom .header-nav-tools.logined li {
    font-size: 0.24rem;
  }

  .header-bottom .header-nav-tools.logined>div .wallet {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 0;
    padding-right: 0.96rem;
    font-weight: 400;
    align-items: center;
  }

  .header-bottom .header-nav-tools.logined>div .wallet p,
  .header-bottom .header-nav-tools.logined>div .wallet span.fund-box {
    display: flex;
    align-items: center;
  }

  .header-bottom .header-nav-tools.logined>div .wallet p .number,
  .header-bottom .header-nav-tools.logined>div .wallet span.fund-box .number {
    font-size: 0.36rem;
    margin: 0 0.05rem;
    margin-left: 0;
    margin-right: 0.35rem;
    font-weight: bold;
    color: #fff;
  }

  .header-bottom .header-nav-tools.logined>div .wallet p .icon-reload,
  .header-bottom .header-nav-tools.logined>div .wallet span.fund-box .icon-reload {
    width: 0.38rem;
    height: 0.42rem;
    transform: scale(1);
  }

  .header-bottom .header-nav-tools.logined>div .wallet .split {
    margin-left: 0.22rem;
    margin-right: 0.14rem;
    font-size: 0.8rem;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .restore-box {
    display: flex;
    align-items: center;
    margin-left: 0.55rem;
    cursor: pointer;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .restore-box .text-yellow,
  .header-bottom .header-nav-tools.logined>div .wallet .restore-box .header-top .user-info,
  .header-top .header-bottom .header-nav-tools.logined>div .wallet .restore-box .user-info,
  .header-bottom .header-nav-tools.logined>div .wallet .restore-box .slide-menu li,
  .slide-menu .header-bottom .header-nav-tools.logined>div .wallet .restore-box li {
    font-size: 0.33rem;
    color: #fff;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .restore-box .icon-restore {
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 0.27rem;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .tool-box {
    display: flex;
    align-items: center;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box {
    position: relative;
    pointer-events: auto;
    cursor: pointer;
    z-index: 3;
    transform: translateX(0.2rem);
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread {
    left: -0.66rem;
    top: 1.1rem;
    width: 4.09rem;
    height: 6.24rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: none;
    position: absolute;
    background: linear-gradient(0deg, #e9e9e9, #fff);
    border: 0.01rem solid #b8b8b8;
    z-index: 999;
    padding: 0.15rem 0.1rem 0;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread::before {
    border-right: 0.31rem solid rgba(0,0,0,0);
    border-left: 0.31rem solid rgba(0,0,0,0);
    border-bottom: 0.31rem solid #b8b8b8;
    top: 0;
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-100%) translateZ(0);
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread::after {
    border-right: 0.3rem solid rgba(0,0,0,0);
    border-left: 0.3rem solid rgba(0,0,0,0);
    border-bottom: 0.3rem solid #fff;
    top: 0.02rem;
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-100%) translateZ(0);
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread li {
    height: 1.5rem;
    font-size: 0.32rem;
    display: flex;
    align-items: center;
    pointer-events: auto;
    font-weight: 700;
    color: #444;
    cursor: pointer;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box {
    width: 2rem;
    height: 100%;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box .icon-deposit {
    width: 0.83rem;
    height: 0.92rem;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box .icon-transfer {
    width: 0.97rem;
    height: 0.92rem;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box .icon-withdraw {
    width: 0.8rem;
    height: 0.72rem;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread li .icon-box .icon-report {
    width: 0.72rem;
    height: 0.69rem;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .fund-spread li span {
    width: 60%;
    height: 0.3rem;
    position: relative;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .icon-fund {
    background: url("../assets/fund_icon2.png") no-repeat;
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 0.27rem;
    background-size: 100% 100%;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .text-yellow,
  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .header-top .user-info,
  .header-top .header-bottom .header-nav-tools.logined>div .wallet .fund-box .user-info,
  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .slide-menu li,
  .slide-menu .header-bottom .header-nav-tools.logined>div .wallet .fund-box li {
    font-size: 0.33rem;
    color: #fff;
  }

  .header-bottom .header-nav-tools.logined>div .wallet .fund-box .icon-arrow-down {
    width: 0.25rem;
    height: 0.18rem;
    background: url("../assets/arrow2.png");
    background-size: 100% 100%;
    margin-left: 0.24rem;
  }

  .header-bottom .header-nav-tools.logined>div>span {
    display: none;
    margin-left: 0.1rem;
  }

  .header-bottom .header-nav-tools.logined .wallet-box {
    height: 1.29rem;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    width: 11.58rem;
    font-family: Arial;
  }

  .header-bottom .header-nav-tools.logined .wallet-box .restore-btn {
    display: inline-block !important;
    font-size: 0.33rem;
  }

  .header-bottom .header-nav-tools.logined .wallet-box .reload-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .header-bottom .header-nav-tools.logined .wallet-box .reload-box .text-yellow,
  .header-bottom .header-nav-tools.logined .wallet-box .reload-box .header-top .user-info,
  .header-top .header-bottom .header-nav-tools.logined .wallet-box .reload-box .user-info,
  .header-bottom .header-nav-tools.logined .wallet-box .reload-box .slide-menu li,
  .slide-menu .header-bottom .header-nav-tools.logined .wallet-box .reload-box li {
    font-size: 0.24rem !important;
    color: #10572c !important;
    font-weight: bold;
  }

  .header-bottom-mobile {
    display: flex !important;
    height: 1.29rem;
    background: #0a984b;
  }

  .banner-box {
    height: 4.68rem;
  }

  .banner-box .banner {
    width: 12.42rem;
    height: 4.68rem;
  }

  .banner-box .banner .slider ul.tab {
    top: 3.88rem;
    left: 50%;
  }

  .banner-box .banner .slider ul.tab li:last-child::after {
    right: 0;
  }

  .banner-box .banner .slider ul.sli img {
    height: 100%;
    width: auto;
    left: 50%;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box {
    left: 0.7rem;
    top: .42rem;
    width: 6.2rem;
    overflow: visible;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box>p {
    max-width: 5.3rem;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box>p.text-banner-content {
    font-size: 0.28rem;
    line-height: 0.4rem;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box>p:first-child {
    max-width: unset;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box:lang(vi) {
    width: 4.2rem;
  }

  .banner-box .banner .slider ul.sli:nth-child(1) .to-claim {
    left: 2.25rem;
    bottom: 0.5rem;
  }

  .banner-box .banner ul.slick-dots {
    top: 3.88rem;
    right: 1.1rem;
    transform: unset;
    display: none !important;
  }

  .banner-box .banner ul.slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .big-banner {
    height: 10.4rem;
    padding-top: 0.73rem;
    padding-bottom: 0.52rem;
  }

  .big-banner .game-box {
    width: 12.42rem;
    height: 9.15rem;
  }

  .big-banner .game-box .game-top {
    height: 1rem;
  }

  .big-banner .game-box .game-content {
    height: 7.15rem;
  }

  .big-banner .game-box .game-content img {
    height: 7.15rem;
    width: 11.57rem;
  }

  .big-banner .game-box .game-bottom {
    height: 1rem;
  }

  .menu-side-box .menu-side {
    width: 4.14rem;
    overflow-y: auto;
    height: 100%;
  }

  .menu-side-box .menu-side .nav-menu li {
    height: 1.56rem;
    font-size: 0.28rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box {
    width: 37%;
    left: 1.09rem;
    transform: translateX(-50%) translateY(-50%) scale(2.7);
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-home {
    width: 0.32rem;
    height: 0.26rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-918kiss,
  .menu-side-box .menu-side .nav-menu li .icon-box .icon-fishing,
  .menu-side-box .menu-side .nav-menu li .icon-box .icon-promo,
  .menu-side-box .menu-side .nav-menu li .icon-box .icon-poker,
  .menu-side-box .menu-side .nav-menu li .icon-box .icon-vip {
    transform: translateX(0.01rem);
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-logout {
    transform: translateX(-0.03rem);
    width: 0.24rem;
    height: 0.28rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-fishing {
    width: 0.35rem;
    height: 0.28rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-918kiss {
    width: 0.46rem;
    height: 0.19rem;
    background-image: url(../assets/slidemenu/mobile/918kiss.png);
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-favourite {
    width: 0.28rem;
    height: 0.24rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-casino,
  .menu-side-box .menu-side .nav-menu li .icon-box .icon-sports {
    width: 0.28rem;
    height: 0.28rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-poker {
    width: 0.29rem;
    height: 0.29rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-chat-white {
    width: 0.26rem;
    height: 0.26rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-contact {
    width: 0.24rem;
    height: 0.24rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-download {
    width: 0.18rem;
    height: 0.26rem;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-raffle {
    width: 0.48rem;
    height: auto;
    max-width: unset;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .menu-sponsorship {
    width: 0.52rem;
    height: auto;
    max-width: unset;
  }

  .menu-side-box .menu-side .nav-menu li .icon-box .icon-lan-white {
    width: 0.27rem;
    height: 0.28rem;
  }

  .menu-side-box .menu-side .nav-menu li>span:nth-child(2) {
    transform: translateX(0);
    font-family: "AvenirNextLTPro";
    width: 2rem;
    white-space: break-spaces;
  }

  .slide-menu,
  .backTop-box {
    display: none;
  }

  .notice {
    width: 100%;
    height: 1rem;
    padding-left: 0.5rem;
    margin: auto;
  }

  .notice .content {
    font-size: 0.35rem;
    white-space: nowrap;
  }

  .notice .msg-enter,
  .notice .msg-exit {
    transform: translateX(100vw) translateZ(0);
  }

  .notice .msg-enter-active,
  .notice .msg-exit-active {
    transform: translateX(-100%) translateZ(0);
  }

@keyframes noticeFade {
    0% {
      transform: translateX(100vw) translateZ(0);
    }

    100% {
      transform: translateX(-100%) translateZ(0);
    }
}

  .content-section {
    padding: 0 0;
    min-height: 12.85rem;
  }

  .content-section>.home-content-section-with-marginTop {
    margin-top: 2.24rem;
  }

  .content-top {
    height: 1.4rem;
    padding: 0.28rem 0.64rem;
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
  }

  .content-title {
    font-size: 0.36rem;
  }

  .footer-bottom {
    height: 2.84rem;
    overflow: auto;
  }

  .footer-bottom .nav-menu {
    justify-content: flex-start;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: 0;
  }

  .footer-bottom .nav-menu .menu-item-container {
    width: 2.17rem;
    flex: 0 0 auto;
    font-size: 0.32rem;
    letter-spacing: -0.02rem;
    height: 100%;
    color: #18723c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: background,color .3s,.3s;
    font-family: "AvenirNextLTPro";
    text-align: center;
  }

  .footer-bottom .nav-menu .menu-item-container .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.8rem;
    margin-bottom: 0.2rem;
  }

  .footer-bottom .nav-menu .menu-item-container>span:nth-child(2) {
    font-family: "AvenirNextLTPro";
    font-weight: bold;
  }

  .footer-bottom .nav-menu .menu-item-container.current {
    border-bottom: 0.1rem solid #c59b4e;
    border-top: 0.1rem solid rgba(0,0,0,0);
    margin-top: 0;
    color: #c59b4e;
  }

  .footer-bottom .nav-menu .menu-item-container .fifa-icon {
    width: 1.2rem;
  }

  .footer-bottom .nav-menu::-webkit-scrollbar {
    display: none;
  }

  .footer-bottom {
    height: 2.2rem;
  }

  .bg-content-section {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .bg-content-section .nav-game {
    display: inline-block !important;
    width: 3.8rem;
  }

  .bg-content-section .nav-game li {
    width: 3.45rem;
    height: 2.97rem;
    margin-bottom: 0.32rem;
  }

  .bg-content-section .nav-game li>p {
    height: 0.69rem;
  }

  .bg-content-section .nav-game li>p .gamename {
    font-size: 0.2rem;
    left: 0.8rem;
    margin-top: -0.02rem;
    width: 1.7rem;
  }

  .bg-content-section .nav-game li>p .icon-love,
  .bg-content-section .nav-game li>p .icon-love-on {
    transform-origin: 0 center;
    transform: scale(2);
  }

  .bg-content-section .nav-game li>p .icon-box {
    transform-origin: 100% center;
    transform: scale(1.7);
  }

  .bg-content-section .nav-game li .hover-box {
    width: 4.88rem;
    height: 4rem;
  }

  .bg-content-section .nav-game li .hover-box .game-item-info {
    height: 0.84rem;
  }

  .bg-content-section .nav-game li .hover-box .game-item-info p {
    font-size: 0.24rem;
  }

  .bg-content-section .nav-game li .hover-box .game-item-info>div {
    font-size: 0.2rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box {
    width: 4.3rem;
    height: 2.82rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu .btn-long {
    margin-top: 0.4rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu .btn-normal-green {
    margin-top: 0.24rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu div {
    margin-top: 0.48rem;
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu div>i {
    transform: scale(1.5);
  }

  .bg-content-section .nav-game li .hover-box .img-box .item-menu * {
    font-size: 0.24rem !important;
  }

  .bg-content-section .nav-game li>img {
    max-height: 2.29rem;
  }

  .bg-content-section .content-recommended .content-top {
    width: 11.6rem;
    padding-left: 0.24rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bg-content-section .content-promotions .content-top {
    width: 11.6rem;
    padding-left: 0.24rem;
    position: relative;
    z-index: 1;
    margin: 0 auto;
  }

  .bg-content-section .content-promotions .content-top .more-info {
    display: inline-block !important;
    font-size: 0.36rem;
    font-style: italic;
    font-family: "OpenSans-Bold";
    color: #39b549;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    text-transform: lowercase;
  }

  .bg-content-section .content-promotions .content-top .more-info:hover {
    text-decoration: underline;
  }

  .bg-content-section .content-promotions .content-center-box {
    padding: 0;
  }

  .bg-content-section .content-promotions .content-center-box .content-center {
    margin: 0.2rem 0 0;
  }

  .bg-content-section .content-promotions .content-center {
    height: 6.78rem;
    padding: 0 1.06rem;
    display: flex;
    width: 100%;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

  .bg-content-section .content-promotions .content-center .slick-list {
    max-height: unset;
    padding: 0.5rem 0;
  }

  .bg-content-section .content-promotions .content-center .slick-list li {
    width: 5rem;
    height: 4rem;
  }

  .bg-content-section .content-promotions .content-center .promoBox ul {
    margin-right: 0.5rem;
  }

  .bg-content-section .content-promotions .content-center .promoBox ul:last-child {
    margin-right: 0;
  }

  .bg-content-section .content-promotions .content-center .promoBox li {
    margin: unset;
    width: 5rem;
    height: 4rem;
  }

  .bg-content-section .content-promotions .content-center .img-box img {
    max-width: 100%;
  }

  .bg-content-section .content-promotions .content-center .promoBox-content {
    height: 0.76rem;
    line-height: 0.4rem;
    font-size: 0.32rem;
    padding-left: 0.2rem;
    padding-top: 0.22rem;
  }

  .bg-content-section .content-promotions .content-center .slick-dots {
    display: none !important;
  }

  .bg-content-section .content-promotions .promoBox-btn {
    display: none;
  }

  .bg-content-section .content-promotions .slick-next-btn,
  .bg-content-section .content-promotions .slick-prev-btn {
    display: none !important;
  }

  .slider>ul.tab {
    top: -0.82rem;
    right: 0.04rem;
  }

  .account-box .account-content {
    width: 100vw;
  }

  .account-box .account-content .account-box-top {
    height: 1.32rem;
  }

  .account-box .account-content .account-box-top .icon-close {
    width: 0.6rem;
    height: 0.6rem;
  }

  .account-box .account-content .account-box-top .tabs-box .btn-skew {
    font-size: 0.4rem;
    padding: 0 0.8rem;
    max-width: 15rem;
  }

  .account-box .account-content .account-main-content {
    height: calc(100% - 1.32rem);
    margin: 0 0 0.75rem 0;
    padding: 0.75rem 0 0 0;
  }

  .account-box .account-content .account-main-content .account-box-header {
    padding: .1rem 1rem 0;
    text-align: center;
  }

  .account-box .account-content .account-main-content .account-box-header .desc2 {
    color: #929292;
  }

  .account-box .account-content .account-main-content .account-box-footer {
    padding: .05rem .75rem 0;
    text-align: center;
    font-size: .2rem;
    color: #919191;
    height: 4.5rem;
    margin: 0 0 .75rem;
  }

  .account-box .account-content .account-main-content .account-box-footer span {
    color: #0a974b;
  }

  .account-box .account-content .account-main-content .account-box-footer .select-lang {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.2rem 0;
  }

  .account-box .account-content .account-main-content .account-box-footer .select-lang svg {
    width: .2rem;
    margin: 0 .1rem 0 0;
  }

  .account-box .account-content .account-main-content .btn-normal,
  .account-box .account-content .account-main-content .btn-normal-green,
  .account-box .account-content .account-main-content .btn-long {
    font-size: 0.34rem !important;
    width: 3.61rem;
    height: 0.84rem;
  }

  .account-box .account-content .account-main-content .page {
    width: 8rem;
    margin: 0 auto;
    transform-origin: center top;
    transform: scale(1.4);
  }

  .account-box .account-content .account-main-content>div {
    width: 100%;
  }

  .account-box .account-content .account-main-content>div>.login,
  .account-box .account-content .account-main-content>div .completed,
  .account-box .account-content .account-main-content>div .create-acount {
    margin-bottom: .5rem;
  }

  .account-box .account-content .account-main-content>div>.login.login-panel-liteapp,
  .account-box .account-content .account-main-content>div .completed.login-panel-liteapp,
  .account-box .account-content .account-main-content>div .create-acount.login-panel-liteapp {
    padding-top: 0.65rem;
    padding-bottom: 0.5rem;
    background: #fff;
  }

  .account-box .account-content .account-main-content>div>.login .turnstile-widget-register,
  .account-box .account-content .account-main-content>div .completed .turnstile-widget-register,
  .account-box .account-content .account-main-content>div .create-acount .turnstile-widget-register {
    transform: scale(0.8);
  }

  .account-box .account-content .account-main-content>div>.login .turnstile-widget-register .turnstile-wrapper,
  .account-box .account-content .account-main-content>div .completed .turnstile-widget-register .turnstile-wrapper,
  .account-box .account-content .account-main-content>div .create-acount .turnstile-widget-register .turnstile-wrapper {
    display: flex;
    justify-content: center;
  }

  .account-box .account-content .account-main-content ul li {
    font-size: 0.28rem;
  }

  .account-box .account-content .account-main-content ul li label {
    height: 0.56rem;
  }

  .account-box .account-content .account-main-content ul li p {
    height: 0.56rem;
    line-height: 0.56rem;
  }

  .account-box .account-content .account-main-content ul li input {
    width: 100%;
    height: 1rem;
    font-size: 0.28rem;
  }

  .account-box .account-content .account-main-content ul li .input-box select {
    height: 1rem;
    width: 2rem;
    font-size: 0.28rem;
  }

  .account-box .account-content .account-main-content ul li .input-box .icon-box {
    width: 1.16rem;
  }

  .account-box .account-content .account-main-content ul li .input-box .btn {
    width: 2rem;
    height: 1rem;
    font-size: 0.3rem;
  }

  .account-box .account-content .account-main-content ul li .standard-password-eye {
    top: .75rem;
    right: .25rem;
    width: .6rem;
    height: .6rem;
  }

  .account-box .account-content .account-main-content ul li .register-form-error-message {
    font-size: .2rem;
  }

  .account-box .account-content .account-main-content ul li .register-form-error-message img {
    width: .14rem;
    height: .14rem;
  }

  .account-box .account-content .account-main-content .text {
    font-size: 0.3rem;
    padding-top: 0.4rem;
  }

  .account-box .account-content .account-main-content .create-acount .btn-normal,
  .account-box .account-content .account-main-content .create-acount .btn-normal-green,
  .account-box .account-content .account-main-content .create-acount .btn-long {
    margin: 0.6rem auto 0;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits {
    width: 100%;
    margin-top: 1.2rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .img-box {
    height: 3.43rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0.6rem 0.5rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box>p {
    font-size: 0.41rem;
    line-height: 0.41rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box .benefits-list li {
    font-size: 0.24rem;
    margin-bottom: 0.2rem;
  }

  .account-box .account-content .account-main-content .create-acount .member-benefits .benefits-content-box .benefits-list li p {
    font-size: 0.3rem;
    line-height: 0.34rem;
    height: auto;
  }

  .account-box .account-content .account-main-content .login .btn-normal,
  .account-box .account-content .account-main-content .login .btn-normal-green,
  .account-box .account-content .account-main-content .login .btn-long {
    margin-top: 0.8rem;
    margin-bottom: 0.4rem;
    text-align: center;
  }

  .account-box .account-content .account-main-content .login .link {
    font-size: 0.3rem;
    height: 0.8rem;
    width: 5.75rem;
  }

  .account-box .account-content .account-main-content .login ul li input {
    padding-left: 1.16rem;
  }

  .account-box .account-content .account-main-content .login .turnstile-widget-login,
  .account-box .account-content .account-main-content .login .turnstile-widget-forgot {
    transform: scale(0.7);
  }

  .account-box .account-content .account-main-content .login .turnstile-widget-login .turnstile-wrapper,
  .account-box .account-content .account-main-content .login .turnstile-widget-forgot .turnstile-wrapper {
    display: flex;
    justify-content: center;
  }

  .new-games-released {
    width: 5.92rem;
    height: 3.37rem;
  }

  .new-games-released.new-games-exit-active {
    right: -5.92rem;
  }

  .new-games-released .top {
    border-top-left-radius: 0.1rem;
    height: 0.82rem;
    font-size: 0.3rem;
  }

  .new-games-released .top .icon-box {
    transform: scale(1.3);
  }

  .new-games-released .top .icon-close {
    right: 0.2rem;
    transform: translateY(-50%) scale(1.5);
  }

  .new-games-released .content {
    padding: 0.16rem 0.33rem;
    display: flex;
    align-items: center;
  }

  .new-games-released .content img {
    width: 2.13rem;
    height: 2.13rem;
    margin-right: 0.39rem;
  }

  .new-games-released .content .right>p {
    font-size: 0.33rem;
  }

  .new-games-released .content .right div {
    font-size: 0.22rem;
    margin-top: 0.22rem;
  }

  .user-name,
  .user-pass {
    font-size: 0.42rem;
    font-weight: bold;
    color: #4d4d4d;
  }

  .user-name:first-child,
  .user-pass:first-child {
    margin-bottom: 0.63rem;
  }

  .user-name .input-box,
  .user-pass .input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 0.32rem;
  }

  .user-name .input-box input,
  .user-pass .input-box input {
    border: 0.01rem solid #65a16b;
    width: 100%;
    height: 0.98rem;
    border-radius: 0.1rem;
    outline: none;
    padding-left: 0.5rem;
    padding-right: 1rem;
    font-size: 0.42rem;
  }

  .user-name .input-box .icon-box,
  .user-pass .input-box .icon-box {
    width: 1.2rem;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    cursor: pointer;
  }

  .user-name .input-box .icon-box .icon-copy,
  .user-pass .input-box .icon-box .icon-copy {
    width: 0.63rem;
    height: 0.7rem;
  }

  .m-content-maxim88 {
    padding-bottom: .4rem;
  }

  .m-content-maxim88 .content-container .content-center {
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 0.58rem;
  }

  .m-content-maxim88 .content-container .maxim88-box {
    width: auto;
    padding: 0.47rem 0 0.25rem 0.58rem;
    position: relative;
    order: 2;
  }

  .m-content-maxim88 .content-container .maxim88-box .box-title {
    color: #3cb748;
    font-size: 0.4rem;
    font-weight: bold;
    padding-bottom: 0.21rem;
    border-bottom: 0.02rem solid #dcdcdc;
    font-family: "AvenirNextLTPro-Bold";
  }

  html:lang(vi) .m-content-maxim88 .content-container .maxim88-box .box-title {
    font-family: "Alexandria";
    font-weight: 700;
    text-transform: capitalize;
  }

  .m-content-maxim88 .content-container .maxim88-box .box-title span {
    color: #000;
    font-size: 0.32rem;
    font-weight: 100;
    margin-left: 0.15rem;
    text-transform: capitalize;
    font-family: "AvenirNextLTPro";
  }

  html:lang(vi) .m-content-maxim88 .content-container .maxim88-box .box-title span {
    font-family: "Alexandria";
    font-weight: 400;
  }

  .m-content-maxim88 .content-container .maxim88-box .box-content {
    margin-top: 0.37rem;
    font-size: 0.32rem;
    max-height: 8rem;
    padding: 0 0.58rem 0.23rem 0;
    overflow-y: scroll;
  }

  .m-content-maxim88 .content-container .maxim88-box .box-content::-webkit-scrollbar {
    width: 0.05rem;
  }

  .m-content-maxim88 .content-container .maxim88-box .box-content div[class*=content-] {
    margin-bottom: 0.27rem;
  }

  .m-content-maxim88 .content-container .maxim88-box .box-content .content-title {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .m-content-maxim88 .content-container .maxim88-box .box-content .content-title span {
    margin-left: 0.12rem;
    width: 100%;
    max-width: 2rem;
  }

  .m-content-maxim88 .content-container .maxim88-box .box-content .content-title span img,
  .m-content-maxim88 .content-container .maxim88-box .box-content .content-title span svg {
    margin-right: 0.03rem;
    width: 0.3rem;
    height: 0.3rem;
  }

  .m-content-maxim88 .content-container .maxim88-box .box-content .content-title span img:last-child,
  .m-content-maxim88 .content-container .maxim88-box .box-content .content-title span svg:last-child {
    margin-right: 0;
  }

  .m-content-maxim88 .content-container .maxim88-video {
    order: 1;
    padding: 0.4rem 0.4rem 0.1rem;
    width: 100%;
  }

  .m-content-maxim88 .content-container .maxim88-video .video-slider-container .slick-slider {
    touch-action: unset;
  }

  .m-content-maxim88 .content-container .maxim88-video .video-slider-container .slick-slider .slick-list .slick-track iframe {
    border: 0;
    height: 6rem;
  }

  .m-content-maxim88 .content-container .maxim88-video .video-slider-container .slick-slider .slick-dots {
    top: -0.65rem;
    text-align: right;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .m-content-maxim88 .content-container .maxim88-video .video-slider-container .slick-slider .slick-dots li {
    display: inline-block;
    width: 0.75rem;
    height: 0.16rem;
    transform: skew(-25deg);
    transform-origin: 0 100%;
    background: #e2dcde;
    margin: 0;
    margin-left: 0.07rem;
    cursor: pointer;
  }

  .m-content-maxim88 .content-container .maxim88-video .video-slider-container .slick-slider .slick-dots li.slick-active {
    background: #39b549;
    cursor: default;
  }

  .m-content-maxim88 .content-container .maxim88-video .video-slider-container .slick-slider .slick-dots li button {
    height: 100%;
  }

  .m-content-maxim88 .content-container .maxim88-video .video-slider-container .slick-slider .slick-dots li button::before {
    content: unset;
  }

  .providerGroup2-top-container .back-box {
    height: 1.56rem;
    display: flex;
    align-items: center;
    font-size: 0.4rem;
    font-weight: bold;
    color: #0a974b;
    padding-left: 0.38rem;
  }

  .providerGroup2-top-container .back-box>i {
    cursor: pointer;
  }

  .providerGroup2-top-container .back-box>span {
    display: flex;
    align-items: center;
  }

  .providerGroup2-top-container .back-box>span .icon-box {
    width: 1.28rem;
    height: 0.86rem;
    margin-left: 0.2rem;
  }

  .providerGroup2-top-container .back-box>span .icon-box .icon-PT,
  .providerGroup2-top-container .back-box>span .icon-box .icon-PT2,
  .providerGroup2-top-container .back-box>span .icon-box .icon-PT3,
  .providerGroup2-top-container .back-box>span .icon-box .icon-YBL,
  .providerGroup2-top-container .back-box>span .icon-box .icon-SG,
  .providerGroup2-top-container .back-box>span .icon-box .icon-TTG,
  .providerGroup2-top-container .back-box>span .icon-box .icon-GP,
  .providerGroup2-top-container .back-box>span .icon-box .icon-BINL2,
  .providerGroup2-top-container .back-box>span .icon-box .icon-BINS2,
  .providerGroup2-top-container .back-box>span .icon-box .icon-SA,
  .providerGroup2-top-container .back-box>span .icon-box .icon-MGP,
  .providerGroup2-top-container .back-box>span .icon-box .icon-AG,
  .providerGroup2-top-container .back-box>span .icon-box .icon-AG2 {
    width: 0.75rem;
    height: 0.75rem;
  }

  .providerGroup2-top-container .back-box>span .icon-box .icon-PP,
  .providerGroup2-top-container .back-box>span .icon-box .icon-MEGA,
  .providerGroup2-top-container .back-box>span .icon-box .icon-NS,
  .providerGroup2-top-container .back-box>span .icon-box .icon-HB,
  .providerGroup2-top-container .back-box>span .icon-box .icon-PP-slot,
  .providerGroup2-top-container .back-box>span .icon-box .icon-NS-slot {
    width: 0.95rem;
    height: 0.95rem;
  }

  .providerGroup2-top-container .back-box>span .icon-box .icon-UL {
    width: 1.03rem;
    height: 0.7rem;
  }

  .providerGroup2-top-container .back-box>span .icon-box .icon-SCR2 {
    width: 1rem;
    height: 0.45rem;
  }

  .providerGroup2-top-container .back-box>span .icon-box .icon-PG {
    width: 0.9rem;
    height: 0.66rem;
  }

  .providerGroup2-top-container .back-box .icon-back {
    cursor: pointer;
  }

  .providerGroup2-top-container .sort-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 2.1rem;
  }

  .providerGroup2-top-container .sort-box li {
    width: 5.76rem;
    position: relative;
    height: 1.9rem;
  }

  .providerGroup2-top-container .sort-box li:nth-child(1) {
    order: 1;
    margin-right: 0.11rem;
  }

  .providerGroup2-top-container .sort-box li:nth-child(2) {
    order: 4;
    flex: none;
  }

  .providerGroup2-top-container .sort-box li .sort-item {
    width: 100%;
    border: none;
    font-size: 0.4rem;
    height: 100%;
  }

  .providerGroup2-top-container .sort-box li .sort-item>div {
    padding-left: 0.5rem;
    padding-right: 0.64rem;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .providerGroup2-top-container .sort-box li .sort-item>div:nth-child(1) {
    background: #e3e3e3;
  }

  .providerGroup2-top-container .sort-box li .sort-item>div:nth-child(2) {
    background: #f2f2f2;
  }

  .providerGroup2-top-container .sort-box li .sort-item .sort-select .icon-arrow-down2 {
    width: 0.25rem;
    height: 0.18rem;
  }

  .providerGroup2-top-container .sort-box li .sort-item .sort-select .spread {
    top: 1.9rem;
    width: 100%;
    height: 5.7rem;
    overflow-x: hidden;
    padding: 0.2rem 0;
  }

  .providerGroup2-top-container .sort-box li .sort-item .sort-select .spread li {
    height: 1rem;
    padding-left: 0.5rem;
  }

  .content-center {
    margin-top: 0.5rem;
  }
}

@media (max-width: 575px) {
  .maxim88-video {
    padding: 1rem 0 0.1rem;
  }

  .maxim88-video .slick-dots {
    top: -0.95rem !important;
  }
}

.content-bottom {
  padding-bottom: 0.55rem;
}

@media (min-width: 769px) {
  .website-footer-bottom .brand-row-container .powered_by {
    display: none;
  }
}

.hidden {
  display: none;
}

.border-none,
.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child .sort-item,
.slots .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child .sort-item,
.sort-box li:last-child .sort-item {
  border: none !important;
}

.icon-back {
  display: inline-block;
  background: url("../assets/back.png") no-repeat;
  width: 0.66rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-restore {
  display: inline-block;
  width: 0.28rem;
  height: 0.29rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-lighting {
  display: inline-block;
  background: url("../assets/lighting.png") no-repeat;
  width: 0.63rem;
  height: 0.53rem;
  background-size: 100% 100%;
}

.icon-logo {
  display: inline-block;
  max-width: 2.28rem;
  max-height: 0.8rem;
  width: 100%;
  height: auto;
}

.icon-logo-min {
  display: inline-block;
  background: url("../assets/logo.png") no-repeat;
  width: 0.36rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-close {
  display: inline-block;
  width: 0.31rem;
  height: 0.31rem;
  background-size: 100% 100%;
  cursor: pointer;
  fill: #fff;
}

.icon-close2 {
  display: inline-block;
  background: url("../assets/close2.png") no-repeat;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-pngo-banner {
  display: inline-block;
  background: url("../assets/pngo_banner.png") no-repeat;
  width: 1.87rem;
  height: 0.51rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-sg-logo {
  display: inline-block;
  background: url("../assets/sg_logo.png") no-repeat;
  width: 1.95rem;
  height: 0.33rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-chat {
  display: inline-block;
  background: url("../assets/chat.png") no-repeat;
  width: 0.37rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-chat-white {
  display: inline-block;
  background: url("../assets/chat_white.png") no-repeat;
  width: 0.37rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-lan {
  display: inline-block;
  background: url("../assets/language.png") no-repeat;
  width: 0.22rem;
  height: 0.23rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-lan-white {
  display: inline-block;
  background: url("../assets/language_white.png") no-repeat;
  width: 0.22rem;
  height: 0.23rem;
  background-size: 100% 100%;
}

.icon-flag {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.lang-choice>div:last-child .divide {
  display: none;
}

.icon-en {
  display: inline-block;
  background: url("../assets/malysia.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-thai {
  display: inline-block;
  background: url("../assets/thai.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-idr {
  display: inline-block;
  background: url("../assets/idr.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-game-menu {
  display: inline-block;
  width: 0.28rem;
  height: 0.16rem;
  background-size: 100% 100%;
  fill: #dabc6f;
}

.icon-raffle {
  display: inline-block;
  width: 0.45rem;
  height: 0.29rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-evo {
  display: inline-block;
  width: 0.8rem;
  height: 0.39rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-download {
  display: inline-block;
  width: 0.25rem;
  height: 0.36rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-contact {
  display: inline-block;
  width: 0.33rem;
  height: 0.33rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-arrow-down {
  display: inline-block;
  width: 0.09rem;
  height: 0.06rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-arrow-big {
  display: inline-block;
  background-image: url("../assets/arrow_big.svg");
  background-repeat: no-repeat;
  width: 0.53rem;
  height: 0.53rem;
  background-size: 100% 100%;
  pointer-events: auto;
  cursor: pointer;
  background-color: rgba(240,240,240,.9);
  border-radius: 50%;
  padding: 0.18rem;
}

.icon-arrow-big.left {
  transform: rotate(180deg);
}

.icon-love {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-love-on {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-love2 {
  display: inline-block;
  background: url("../assets/love.png") no-repeat;
  width: 0.38rem;
  height: 0.32rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-SG {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-PT,
.icon-PT3,
.icon-PT2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-YBL {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ybl.png") no-repeat;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-lucky {
  display: inline-block;
  background: url("../assets/lucky_bomber.png") no-repeat;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-TTG {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-SA {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-GP {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-BINL2,
.icon-BINS2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-PP,
.icon-PP-slot {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-AG,
.icon-AG2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-UG,
.icon-AES,
.icon-AES2 {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ug.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-EVO {
  display: inline-block;
  background: url("/public/html/images/home_img/096-evo.svg") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-EBET {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ebet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-MGP {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-JKR {
  display: inline-block;
  width: 0.4rem;
  height: 0.09rem;
  background-size: 100% 100%;
}

.icon-NS,
.icon-NS-slot {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-PGS,
.icon-FG,
.icon-MT,
.icon-YGR,
.icon-V8,
.icon-KM,
.icon-KM2 {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-PG {
  display: inline-block;
  width: 0.36rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-UL {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ul.png") no-repeat;
  width: 0.42rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-SCR2 {
  display: inline-block;
  background: url("/public/html/images/home_img/096-scr.png") no-repeat;
  width: 0.4rem;
  height: 0.18rem;
  background-size: 100% 100%;
}

.icon-JL {
  display: inline-block;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-PSY8 {
  display: inline-block;
  width: 0.4rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-MEGA {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-HC {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-HB {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-NT {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-RT {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-CQ9 {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-JDB,
.icon-JDB2 {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-YGG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-AP {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-DS,
.icon-DS-slot,
.icon-EP,
.icon-SW,
.icon-R88,
.icon-R88-slot,
.icon-WESLT,
.icon-WESLT-slot {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-SPX {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-DRG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-RG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-PS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-FC {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-SPB {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-T1G,
.icon-VP,
.icon-MTV {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-EXS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-FS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-hot {
  display: inline-block;
  background: url("../assets/hot.png") no-repeat;
  width: 0.67rem;
  height: 0.68rem;
  background-size: 100% 100%;
}

.icon-new {
  display: inline-block;
  background: url("../assets/new.png") no-repeat;
  width: 0.67rem;
  height: 0.68rem;
  background-size: 100% 100%;
}

.icon-new-green {
  display: inline-block;
  background: url("../assets/new_green.png") no-repeat;
  width: 0.79rem;
  height: 0.79rem;
  background-size: 100% 100%;
}

.icon-transfer-918kiss {
  display: inline-block;
  background: url("../assets/transfer/918kiss.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ae_sexy {
  display: inline-block;
  background: url("../assets/transfer/ae_sexy.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ag_gaming {
  display: inline-block;
  background: url("../assets/transfer/ag_gaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-allbet {
  display: inline-block;
  background: url("../assets/transfer/allbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-allbet2 {
  display: inline-block;
  background: url("../assets/transfer/allbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-betrader {
  display: inline-block;
  background: url("../assets/transfer/betrader.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bk8 {
  display: inline-block;
  background: url("../assets/transfer/bk8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-cmd368 {
  display: inline-block;
  background: url("../assets/transfer/cmd368.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-m8 {
  display: inline-block;
  background: url("../assets/transfer/m8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-kingmaker {
  display: inline-block;
  background: url("../assets/transfer/kingmaker.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-dreamgaming {
  display: inline-block;
  background: url("../assets/transfer/dreamgaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ebet {
  display: inline-block;
  background: url("../assets/transfer/ebet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-evo {
  display: inline-block;
  background: url("../assets/transfer/evo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gameplay {
  display: inline-block;
  background: url("../assets/transfer/gameplay.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gd {
  display: inline-block;
  background: url("../assets/transfer/gd.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jkr {
  display: inline-block;
  background: url("../assets/transfer/096-jkr-icon.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-hongchow {
  display: inline-block;
  background: url("../assets/transfer/hongchow.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-funkygames {
  display: inline-block;
  background: url("../assets/transfer/funkygames.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-minited {
  display: inline-block;
  background: url("../assets/transfer/minited.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pgsoft {
  display: inline-block;
  background: url("../assets/transfer/pgsoft.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-yesgetrich {
  display: inline-block;
  background: url("../assets/transfer/yesgetrich.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jili {
  display: inline-block;
  background: url("../assets/transfer/jili.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gg_gaming {
  display: inline-block;
  background: url("../assets/transfer/gg_gaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-idn_poker {
  display: inline-block;
  background: url("../assets/transfer/idn_poker.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-im {
  display: inline-block;
  background: url("../assets/transfer/im.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bti {
  display: inline-block;
  background: url("../assets/transfer/bti.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-maxbet {
  display: inline-block;
  background: url("../assets/transfer/maxbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-drg {
  display: inline-block;
  background: url("../assets/transfer/drg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-w {
  display: inline-block;
  background: url("../assets/transfer/w.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-we {
  display: inline-block;
  background: url("../assets/transfer/we.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-we.icon-custom-transfer-Maxim88Casino {
  background: url("../assets/transfer/we_rebrand.png") no-repeat;
  background-size: 100% 100%;
}

.icon-transfer-we.icon-custom-transfer-Maxim88 {
  background: url("../assets/transfer/we_rebrand.png") no-repeat;
  background-size: 100% 100%;
}

.icon-transfer-mega888 {
  display: inline-block;
  background: url("../assets/transfer/mega888.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-mg {
  display: inline-block;
  background: url("../assets/transfer/mg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ybl {
  display: inline-block;
  background: url("../assets/transfer/ybl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-PP {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-PPL {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-psy8 {
  display: inline-block;
  background: url("../assets/transfer/psy8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ns {
  display: inline-block;
  background: url("../assets/transfer/nextspin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-nextspin {
  display: inline-block;
  background: url("../assets/transfer/nextspin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pngo {
  display: inline-block;
  background: url("../assets/transfer/pngo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pp {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pt_logo {
  display: inline-block;
  background: url("../assets/transfer/pt_logo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-qq {
  display: inline-block;
  background: url("../assets/transfer/qq.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-winwinlottery {
  display: inline-block;
  background: url("../assets/transfer/wwl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-shicai {
  display: inline-block;
  background: url("../assets/transfer/gw.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tfg {
  display: inline-block;
  background: url("../assets/transfer/tf.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-v8poker {
  display: inline-block;
  background: url("../assets/transfer/v8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sa {
  display: inline-block;
  background: url("../assets/transfer/sa.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sbo {
  display: inline-block;
  background: url("../assets/transfer/sbo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-hb {
  display: inline-block;
  background: url("../assets/transfer/hb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spade {
  display: inline-block;
  background: url("../assets/transfer/spade.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ttg {
  display: inline-block;
  background: url("../assets/transfer/ttg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ultimate {
  display: inline-block;
  background: url("../assets/transfer/ultimate.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-wm {
  display: inline-block;
  background: url("../assets/transfer/wm.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-rt {
  display: inline-block;
  background: url("../assets/transfer/rt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-nt {
  display: inline-block;
  background: url("../assets/transfer/nt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-cq9 {
  display: inline-block;
  background: url("../assets/transfer/cq9.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-fachai {
  display: inline-block;
  background: url("../assets/transfer/fc.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-granddragon {
  display: inline-block;
  background: url("../assets/transfer/gdl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-digmaan {
  display: inline-block;
  background: url("../assets/transfer/dig.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tcg {
  display: inline-block;
  background: url("../assets/transfer/tcg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tcga {
  display: inline-block;
  background: url("../assets/transfer/tcg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ws {
  display: inline-block;
  background: url("../assets/transfer/ws.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-binl2 {
  display: inline-block;
  background: url("../assets/transfer/bbin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bins2 {
  display: inline-block;
  background: url("../assets/transfer/bbin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spb {
  display: inline-block;
  background: url("../assets/transfer/spb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-t1g {
  display: inline-block;
  background: url("../assets/transfer/t1g.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-mtv {
  display: inline-block;
  background: url("../assets/transfer/mtv.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bg {
  display: inline-block;
  background: url("../assets/transfer/bg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jdb {
  display: inline-block;
  background: url("../assets/transfer/jdb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jdb2 {
  display: inline-block;
  background: url("../assets/transfer/jdb2.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ds {
  display: inline-block;
  background: url("../assets/transfer/ds.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ep {
  display: inline-block;
  background: url("../assets/transfer/ep.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-rg {
  display: inline-block;
  background: url("../assets/transfer/rg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ps {
  display: inline-block;
  background: url("../assets/transfer/ps.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ygg {
  display: inline-block;
  background: url("../assets/transfer/ygg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ap {
  display: inline-block;
  background: url("../assets/transfer/ap.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spx {
  display: inline-block;
  background: url("../assets/transfer/spx.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sw {
  display: inline-block;
  background: url("../assets/transfer/sw.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-r88 {
  display: inline-block;
  background: url("../assets/transfer/r88.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-weslt {
  display: inline-block;
  background: url("../assets/transfer/weslt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-exs {
  display: inline-block;
  background: url("../assets/transfer/exs.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-vp {
  display: inline-block;
  background: url("../assets/transfer/vp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-vc {
  display: inline-block;
  background: url("../assets/transfer/vc.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-fs {
  display: inline-block;
  background: url("../assets/transfer/fs.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-box.bg-white {
  width: 0.3rem;
  height: 0.3rem;
  background: #fff;
  border-radius: 50%;
}

.icon-box>i {
  overflow: visible;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-logout {
  display: inline-block;
  background: url("../assets/menu/login.png") no-repeat;
  width: 0.28rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-home {
  display: inline-block;
  width: 0.33rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-918kiss {
  display: inline-block;
  background: url("../assets/menu/918kiss.png") no-repeat;
  width: 0.45rem;
  height: 0.24rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.icon-slots {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-casino {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-favourite {
  display: inline-block;
  width: 0.3rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.icon-fishing {
  display: inline-block;
  width: 0.36rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-poker {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-e-sport {
  display: inline-block;
  width: 0.36rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-promotion {
  display: inline-block;
  width: 0.36rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-sports {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-vip {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-meda {
  display: inline-block;
  background: url("../assets/meda.png") no-repeat;
  width: 0.32rem;
  height: 0.47rem;
  background-size: 100% 100%;
}

.icon-curaccao {
  display: inline-block;
  background: url("../assets/brand/curaccao-gray.svg") no-repeat;
  width: 1rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-curaccao:hover {
  background: url("../assets/brand/curaccao.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-pagcor {
  display: inline-block;
  background: url("../assets/brand/pagcor-gray.png") no-repeat;
  width: 1.25rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-pagcor:hover {
  background: url("../assets/brand/pagcor.png") no-repeat;
  background-size: 100% 100%;
}

.icon-iovation {
  display: inline-block;
  background: url("../assets/brand/iovation-gray.png") no-repeat;
  width: 1.19rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-iovation:hover {
  background: url("../assets/brand/iovation.png") no-repeat;
  background-size: 100% 100%;
}

.icon-tm {
  display: inline-block;
  background: url("../assets/brand/threatmetrix-gray.svg") no-repeat;
  width: 1.19rem;
  height: 0.32rem;
  background-size: 100% 100%;
}

.icon-tm:hover {
  background: url("../assets/brand/threatmetrix.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-bmm {
  display: inline-block;
  background: url("../assets/brand/bmm-gray.png") no-repeat;
  width: 0.55rem;
  height: 0.17rem;
  background-size: 100% 100%;
}

.icon-bmm:hover {
  background: url("../assets/brand/bmm.png") no-repeat;
  background-size: 100% 100%;
}

.icon-itechlab {
  display: inline-block;
  background: url("../assets/brand/itechlab-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.39rem;
  background-size: 100% 100%;
}

.icon-itechlab:hover {
  background: url("../assets/brand/itechlab.png") no-repeat;
  background-size: 100% 100%;
}

.icon-global {
  display: inline-block;
  background: url("../assets/brand/global-gray.png") no-repeat;
  width: 0.48rem;
  height: 0.48rem;
  background-size: 100% 100%;
}

.icon-global:hover {
  background: url("../assets/brand/global.png") no-repeat;
  background-size: 100% 100%;
}

.icon-godaddy {
  display: inline-block;
  background: url("../assets/brand/godaddy-gray.png") no-repeat;
  width: 1.35rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-godaddy:hover {
  background: url("../assets/brand/godaddy.png") no-repeat;
  background-size: 100% 100%;
}

.icon-payouts {
  display: inline-block;
  background: url("../assets/brand/payouts-gray.png") no-repeat;
  width: 0.83rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-payouts:hover {
  background: url("../assets/brand/payouts.png") no-repeat;
  background-size: 100% 100%;
}

.icon-youtube {
  display: inline-block;
  background: url("../assets/brand/youtube-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-youtube:hover {
  background: url("../assets/brand/youtube.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-eeziepay {
  display: inline-block;
  background: url("../assets/brand/eeziepay-gray.png") no-repeat;
  width: 0.77rem;
  height: 0.32rem;
  background-size: 100% 100%;
  margin: 0 0.05rem;
}

.icon-eeziepay:hover {
  background: url("../assets/brand/eeziepay.png") no-repeat;
  background-size: 100% 100%;
}

.icon-paytrust {
  display: inline-block;
  background: url("../assets/brand/paytrust-gray.png") no-repeat;
  width: 0.9rem;
  height: 0.19rem;
  background-size: 100% 100%;
}

.icon-paytrust:hover {
  background: url("../assets/brand/paytrust.png") no-repeat;
  background-size: 100% 100%;
}

.icon-help2pay {
  display: inline-block;
  background: url("../assets/brand/help2pay-gray.png") no-repeat;
  width: 0.4rem;
  height: 0.3rem;
  background-size: 100% 100%;
  margin: 0 0.05rem;
}

.icon-help2pay:hover {
  background: url("../assets/brand/help2pay.png") no-repeat;
  background-size: 100% 100%;
}

.icon-bitcoin {
  display: inline-block;
  background: url("../assets/brand/bitcoin-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-bitcoin:hover {
  background: url("../assets/brand/bitcoin.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-usdt {
  display: inline-block;
  background: url("../assets/brand/usdt-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-usdt:hover {
  background: url("../assets/brand/usdt.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-paynow {
  display: inline-block;
  background: url("../assets/brand/paynow-gray.svg") no-repeat;
  width: 0.86rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-paynow:hover {
  background: url("../assets/brand/paynow.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-paynow-v2 {
  display: inline-block;
  background: url("../assets/brand/paynow-gray-v2.svg") no-repeat;
  width: 0.6rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-paynow-v2:hover {
  background: url("../assets/brand/paynow-v2.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-eth {
  display: inline-block;
  background: url("../assets/brand/eth-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-eth:hover {
  background: url("../assets/brand/eth-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-duitnow {
  display: inline-block;
  background: url("../assets/brand/duitnow-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-duitnow:hover {
  background: url("../assets/brand/duitnow-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-instantpay {
  display: inline-block;
  background: url("../assets/brand/instantpay-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-instantpay:hover {
  background: url("../assets/brand/instantpay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-tng {
  display: inline-block;
  background: url("../assets/brand/tng-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-tng:hover {
  background: url("../assets/brand/tng-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-grab {
  display: inline-block;
  background: url("../assets/brand/grabpay-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-grab:hover {
  background: url("../assets/brand/grabpay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-boost {
  display: inline-block;
  background: url("../assets/brand/boost-logo-of.svg") no-repeat;
  width: 0.85rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-boost:hover {
  background: url("../assets/brand/boost-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-shopee {
  display: inline-block;
  background: url("../assets/brand/shopee-logo-of.svg") no-repeat;
  width: 0.75rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-shopee:hover {
  background: url("../assets/brand/shopee-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-truepay {
  display: inline-block;
  background: url("../assets/brand/truepay-logo-of.svg") no-repeat;
  width: 1rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-truepay:hover {
  background: url("../assets/brand/truepay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-surepay {
  display: inline-block;
  background: url("../assets/brand/surepay-logo-of.svg") no-repeat;
  width: 1rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-surepay:hover {
  background: url("../assets/brand/surepay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-notice {
  display: inline-block;
  width: 2.32rem;
  height: 0.2rem;
  background-size: 100% 100%;
  max-width: 0.55rem;
}

.icon-allbet {
  display: inline-block;
  background: url("../assets/casino/allbet.png") no-repeat;
  width: 1.01rem;
  height: 0.59rem;
  background-size: 100% 100%;
}

.icon-asiagaming {
  display: inline-block;
  background: url("../assets/casino/asiagaming.png") no-repeat;
  width: 1.22rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-dreamgaming {
  display: inline-block;
  background: url("../assets/casino/dreamgaming.png") no-repeat;
  width: 0.96rem;
  height: 0.58rem;
  background-size: 100% 100%;
}

.icon-evolution {
  display: inline-block;
  background: url("../assets/casino/evolution.png") no-repeat;
  width: 1.12rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-gameplay {
  display: inline-block;
  background: url("../assets/casino/gameplay.png") no-repeat;
  width: 1.46rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-gd {
  display: inline-block;
  background: url("../assets/casino/gd.png") no-repeat;
  width: 1.08rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-microgaming {
  display: inline-block;
  background: url("../assets/casino/microgaming.png") no-repeat;
  width: 1.53rem;
  height: 0.38rem;
  background-size: 100% 100%;
}

.icon-playtech {
  display: inline-block;
  background: url("../assets/casino/playtech.png") no-repeat;
  width: 1.43rem;
  height: 0.31rem;
  background-size: 100% 100%;
}

.icon-sa-gaming {
  display: inline-block;
  background: url("../assets/casino/sa_gaming.png") no-repeat;
  width: 1.22rem;
  height: 0.46rem;
  background-size: 100% 100%;
}

.icon-sexy {
  display: inline-block;
  background: url("../assets/casino/sexy.png") no-repeat;
  width: 1.01rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.icon-username,
.icon-myprofile {
  display: inline-block;
  width: 0.2rem;
  height: 0.28rem;
  background-size: 100% 100%;
  z-index: 2;
}

.icon-lock {
  display: inline-block;
  width: 0.22rem;
  height: 0.27rem;
  background-size: 100% 100%;
  z-index: 2;
}

.icon-changepassword {
  display: inline-block;
  width: 0.26rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-changegamepassword {
  display: inline-block;
  width: 0.26rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-bankdetail {
  display: inline-block;
  width: 0.35rem;
  height: 0.32rem;
  background-size: 100% 100%;
}

.icon-referral {
  display: inline-block;
  width: 0.35rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-search {
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  background-size: 100% 100%;
}

.icon-arrow-down2 {
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  background-size: 100% 100%;
  fill: #000;
}

.icon-messaging {
  display: inline-block;
  width: 0.28rem;
  height: 0.21rem;
  background-size: 100% 100%;
}

.icon-reload {
  display: inline-block;
  width: 0.25rem;
  height: 0.27rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-reload.start {
  animation: rotation 1s linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.icon-reload2 {
  display: inline-block;
  background: url("../assets/reload2.png") no-repeat;
  width: 0.25rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-reload2.start {
  animation: rotation 1s linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.icon-fund {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-fund2 {
  display: inline-block;
  background: url("../assets/fund_icon2.png") no-repeat;
  background-size: 100% 100%;
}

.icon-deposit {
  display: inline-block;
  width: 0.3rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.icon-report {
  display: inline-block;
  width: 0.26rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.icon-transfer {
  display: inline-block;
  width: 0.35rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-withdraw {
  display: inline-block;
  width: 0.29rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-start {
  display: inline-block;
  background: url("../assets/start.png") no-repeat;
  width: 0.3rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-start2 {
  display: inline-block;
  background: url("../assets/start2.png") no-repeat;
  width: 0.17rem;
  height: 0.15rem;
  background-size: 100% 100%;
}

.icon-on {
  display: inline-block;
  background: url("../assets/on.png") no-repeat;
  width: 0.6rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-off {
  display: inline-block;
  background: url("../assets/off.png") no-repeat;
  width: 0.6rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-sapphire {
  display: inline-block;
  width: 0.63rem;
  height: 0.43rem;
  object-fit: contain;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-ruby {
  display: inline-block;
  background: url("../assets/ruby.png") no-repeat;
  width: 0.71rem;
  height: 0.56rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-diamond {
  display: inline-block;
  background: url("../assets/diamond.png") no-repeat;
  width: 0.71rem;
  height: 0.56rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-transfer-btn {
  display: inline-block;
  background: url("../assets/transfer_btn.png") no-repeat;
  width: 0.2rem;
  height: 0.17rem;
  background-size: 100% 100%;
}

.icon-transfer-to {
  display: inline-block;
  background: url("../assets/transfer_to.png") no-repeat;
  width: 0.17rem;
  height: 0.11rem;
  background-size: 100% 100%;
}

.icon-checked {
  display: inline-block;
  background: url("../assets/checked.png") no-repeat;
  width: 0.24rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-tip {
  display: inline-block;
  background: url("../assets/tip.png") no-repeat;
  width: 0.18rem;
  height: 0.18rem;
  background-size: 100% 100%;
}

.icon-notice-important {
  display: inline-block;
  background: url("../assets/important_notice.svg") no-repeat;
  width: 0.29rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-affin {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-affin-gray.png") no-repeat;
  width: 0.5rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-affin:hover,
.icon-affin.on {
  background: url("/public/html/images/bank_img/096-affin.png") no-repeat;
  background-size: 100% 100%;
}

.icon-alliance {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-alliance-gray.png") no-repeat;
  width: 0.43rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-alliance:hover,
.icon-alliance.on {
  background: url("/public/html/images/bank_img/096-alliance.png") no-repeat;
  background-size: 100% 100%;
}

.icon-bsn {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-bsn-gray.png") no-repeat;
  width: 0.36rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-bsn:hover,
.icon-bsn.on {
  background: url("/public/html/images/bank_img/096-bsn.png") no-repeat;
  background-size: 100% 100%;
}

.icon-cimb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-cimb-gray.png") no-repeat;
  width: 0.34rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-cimb:hover,
.icon-cimb.on {
  background: url("/public/html/images/bank_img/096-cimb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-citi {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-citi-gray.png") no-repeat;
  width: 0.36rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-citi:hover,
.icon-citi.on {
  background: url("/public/html/images/bank_img/096-citi.png") no-repeat;
  background-size: 100% 100%;
}

.icon-hlb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-hlb-gray.png") no-repeat;
  width: 0.28rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-hlb:hover,
.icon-hlb.on {
  background: url("/public/html/images/bank_img/096-hlb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-islam {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-islam-gray.png") no-repeat;
  width: 0.45rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-islam:hover,
.icon-islam.on {
  background: url("/public/html/images/bank_img/096-islam.png") no-repeat;
  background-size: 100% 100%;
}

.icon-mbb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-mbb-gray.png") no-repeat;
  width: 0.33rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-mbb:hover,
.icon-mbb.on {
  background: url("/public/html/images/bank_img/096-mbb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-pbe {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-pbe-gray.png") no-repeat;
  width: 0.26rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.icon-pbe:hover,
.icon-pbe.on {
  background: url("/public/html/images/bank_img/096-pbe.png") no-repeat;
  background-size: 100% 100%;
}

.icon-rhb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-rhb-gray.png") no-repeat;
  width: 0.57rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-rhb:hover,
.icon-rhb.on {
  background: url("/public/html/images/bank_img/096-rhb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-amb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-amb-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-amb:hover,
.icon-amb.on {
  background: url("/public/html/images/bank_img/096-amb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-uob {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-uob-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-uob:hover,
.icon-uob.on {
  background: url("/public/html/images/bank_img/096-uob.png") no-repeat;
  background-size: 100% 100%;
}

.icon-rakyat {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-br-gray.png") no-repeat;
  width: 0.39rem;
  height: 0.42rem;
  background-size: 100% 100%;
}

.icon-rakyat:hover,
.icon-rakyat.on {
  background: url("/public/html/images/bank_img/096-br.png") no-repeat;
  background-size: 100% 100%;
}

.icon-ocbc {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-ocbc-gray.png") no-repeat;
  width: 0.37rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-ocbc:hover,
.icon-ocbc.on {
  background: url("/public/html/images/bank_img/096-ocbc.png") no-repeat;
  background-size: 100% 100%;
}

.icon-hsbc {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-hsbc-gray.png") no-repeat;
  width: 0.47rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-hsbc:hover,
.icon-hsbc.on {
  background: url("/public/html/images/bank_img/096-hsbc.png") no-repeat;
  background-size: 100% 100%;
}

.icon-scb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-scb-gray.png") no-repeat;
  width: 0.47rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-scb:hover,
.icon-scb.on {
  background: url("/public/html/images/bank_img/096-scb.png") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-home {
  background: url("/public/html/images/home_img/home.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-casino {
  background: url("/public/html/images/home_img/casino.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-918kiss {
  background: url("/public/html/images/home_img/918kiss.svg") no-repeat;
  background-size: 100% 100%;
  -webkit-backface-visibility: unset;
          backface-visibility: unset;
}

.footer-bottom .icon-slots {
  background: url("/public/html/images/home_img/slots.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-sports {
  background: url("/public/html/images/home_img/sports.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-e-sport {
  background: url("/public/html/images/home_img/e-sport.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-fishing {
  background: url("/public/html/images/home_img/fishing.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-poker {
  background: url("/public/html/images/home_img/poker.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-favourite {
  background: url("/public/html/images/home_img/favourite.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-promotion {
  background: url("/public/html/images/home_img/promo.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-vip {
  background: url("/public/html/images/home_img/vip.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-raffle {
  background: url("/public/html/images/home_img/raffle.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-download {
  background: url("/public/html/images/home_img/download.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-contact {
  background: url("/public/html/images/home_img/contact.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-lan-white {
  background: url("/public/html/images/home_img/language.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-logout {
  background: url("/public/html/images/home_img/logout.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-home {
  background: url("/public/html/images/home_img/home-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-casino {
  background: url("/public/html/images/home_img/casino-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-918kiss {
  background: url("/public/html/images/home_img/918kiss-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-slots {
  background: url("/public/html/images/home_img/slots-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-sports {
  background: url("/public/html/images/home_img/sports-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-e-sport {
  background: url("/public/html/images/home_img/e-sport-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-fishing {
  background: url("/public/html/images/home_img/fishing-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-poker {
  background: url("/public/html/images/home_img/poker-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-favourite {
  background: url("/public/html/images/home_img/favourite-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-promotion {
  background: url("/public/html/images/home_img/promo-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-vip {
  background: url("/public/html/images/home_img/vip-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-raffle {
  background: url("/public/html/images/home_img/raffle-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-download {
  background: url("/public/html/images/home_img/download-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-contact {
  background: url("/public/html/images/home_img/contact-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-lan-white {
  background: url("/public/html/images/home_img/language-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-logout {
  background: url("/public/html/images/home_img/logout-on.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-wap-menu {
  cursor: pointer;
  display: inline-block;
  width: 0.69rem;
  height: 0.46rem;
  background-size: 100% 100%;
  fill: #dabc6f;
}

.icon-wap-back {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/back.png") no-repeat;
  width: 0.6rem;
  height: 0.42rem;
  background-size: 100% 100%;
}

.icon-wap-eye {
  cursor: pointer;
  display: inline-block;
  width: 0.85rem;
  height: 0.58rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-wap-eye.off {
  background-size: 100% 100%;
  fill: #02532b;
}

.icon-wap-auto {
  border: none;
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/on.png") no-repeat;
  width: 1.54rem;
  height: 0.52rem;
  background-size: 100% 100%;
}

.icon-wap-auto.off {
  background: url("../assets/wap/off.png") no-repeat;
  background-size: 100% 100%;
}

.icon-wap-chat {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/chat.png") no-repeat;
  width: 0.72rem;
  height: 0.72rem;
  background-size: 100% 100%;
}

.icon-wap-message {
  cursor: pointer;
  display: inline-block;
  background-size: 100% 100%;
}

.icon-wap-message svg {
  width: 0.75rem;
  height: 0.58rem;
  fill: #fff;
}

.icon-wap-messaging {
  cursor: pointer;
  display: inline-block;
  width: 0.75rem;
  height: 0.58rem;
  background-size: 100% 100%;
}

.icon-wap-messaging.on {
  background-size: 100% 100%;
}

.icon-wap-profile {
  cursor: pointer;
  display: inline-block;
  width: 0.65rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-profile.on {
  background-size: 100% 100%;
}

.icon-wap-changepassword {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-changepassword.on {
  background-size: 100% 100%;
}

.icon-wap-changegamepassword {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-changegamepassword.on {
  background-size: 100% 100%;
}

.icon-wap-referral {
  cursor: pointer;
  display: inline-block;
  width: 1.1rem;
  height: 0.7rem;
  background-size: 100% 100%;
}

.icon-wap-referral.on {
  background-size: 100% 100%;
}

.icon-wap-bankdetail {
  cursor: pointer;
  display: inline-block;
  width: 0.95rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-bankdetail.on {
  background-size: 100% 100%;
}

.icon-wap-deposit {
  cursor: pointer;
  display: inline-block;
  width: 0.97rem;
  height: 1.08rem;
  background-size: 100% 100%;
}

.icon-wap-transfer {
  cursor: pointer;
  display: inline-block;
  width: 1.14rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-withdraw {
  cursor: pointer;
  display: inline-block;
  width: 0.95rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-report {
  cursor: pointer;
  display: inline-block;
  width: 0.84rem;
  height: 0.82rem;
  background-size: 100% 100%;
}

.icon-wap-home {
  cursor: pointer;
  display: inline-block;
  width: 1.01rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-funds {
  cursor: pointer;
  display: inline-block;
  width: 0.84rem;
  height: 0.76rem;
  background-size: 100% 100%;
}

.icon-wap-promos {
  cursor: pointer;
  display: inline-block;
  width: 1.21rem;
  height: 0.83rem;
  background-size: 100% 100%;
}

.icon-wap-live_chat {
  cursor: pointer;
  display: inline-block;
  width: 0.77rem;
  height: 0.77rem;
  background-size: 100% 100%;
}

.icon-wap-my_account {
  cursor: pointer;
  display: inline-block;
  width: 0.55rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-transfer_btn {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/transfer_btn.png") no-repeat;
  width: 0.53rem;
  height: 0.57rem;
  background-size: 100% 100%;
  border: none;
}

.icon-wap-transfer_btn:disabled,
.icon-wap-transfer_btn[disabled] {
  opacity: .3;
  cursor: not-allowed;
}

.icon-wap-reload {
  cursor: pointer;
  display: inline-block;
  width: 0.59rem;
  height: 0.65rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-wap-arrow {
  cursor: pointer;
  display: inline-block;
  width: 0.37rem;
  height: 0.23rem;
  background-size: 100% 100%;
}

.font-gray {
  color: #19723c;
}

.bg-white {
  background: #fff;
}

.bg-header-t {
  background: green;
}

.bg-header {
  background: green;
}

.border-white {
  border-color: #fff;
}

.border-primary {
  border-color: solid 0.01rem green;
}

.btn-normal,
.btn-normal-green,
.btn-long {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 0.35rem;
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #222;
  font-size: 0.16rem;
  border-radius: 0.02rem;
  cursor: pointer;
  font-family: "Roboto-Bold";
}

html:lang(vi) .btn-normal,
html:lang(vi) .btn-normal-green,
html:lang(vi) .btn-long {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: uppercase;
}

.btn-normal:disabled,
.btn-normal-green:disabled,
.btn-long:disabled {
  filter: grayscale(0.6);
  cursor: not-allowed;
}

.login-button,
.reset-password-button {
  border: none;
}

.btn .btn-ok {
  text-transform: uppercase;
}

.btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
  filter: grayscale(0.7);
  color: rgba(0,0,0,.431372549);
}

.btn-normal-green {
  background: #15743c;
  color: #fff;
  white-space: nowrap;
}

.btn-skew {
  display: inline-block;
  width: 2.28rem;
  height: 0.48rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  font-size: 0.2rem;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #c59b4e 0%, #d2af62 57%, #dec276 100%);
}

.btn-skew>* {
  transform: skew(25deg);
}

.btn-long {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.5rem;
  white-space: nowrap;
}

.text-red {
  color: #e1415d;
  font-size: 0.11rem;
}

.text-yellow,
.header-top .user-info,
.slide-menu li {
  color: #dec276;
  font-size: 0.12rem;
}

.btn-swiper-point,
.content-section .content-recommended .slick-dots li,
.bg-content-section .content-promotions .slick-dots li,
.slider>ul.tab li {
  display: inline-block;
  width: 0.32rem;
  height: 0.08rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  background: #e2dcde;
  margin-left: 0.07rem;
  cursor: pointer;
}

.btn-swiper-point.on,
.content-section .content-recommended .slick-dots li.on,
.bg-content-section .content-promotions .slick-dots li.on,
.slider>ul.tab li.on {
  background: #39b549;
  cursor: default;
}

.bg-gray {
  background: #f5f5f5;
}

.bg-black {
  background: #222;
}

.bg-header-top {
  background: #13242c;
}

.bg-header-top .content-container {
  width: 100%;
  max-width: 20rem;
  padding: 0 0.25rem;
}

.bg-header-bottom {
  background: linear-gradient(to right, #fff 0%, #fff 17%, #19723c 17%, #19723c 100%);
  z-index: 3;
  position: relative;
  height: 0.74rem;
}

.bg-header-bottom .content-container {
  width: 100%;
  max-width: 20rem;
  padding: 0 0.25rem;
}

.bg-footer-bottom {
  background: #ebebeb;
}

.swiper-arrow {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.26rem;
}

.text-banner-green {
  font-size: 0.6rem;
  color: #39b549;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .text-banner-green {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.text-banner-white {
  font-size: 0.42rem;
  color: #fff;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .text-banner-white {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.text-banner-white-small {
  font-family: OpenSans;
  font-style: italic;
  font-size: 0.24rem;
  color: #fff;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.page-enter {
  position: absolute;
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms,transform 300ms;
}

.page-exit {
  position: absolute;
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms,transform 300ms;
}

.page-slide-enter {
  position: absolute;
  right: -100%;
}

.page-slide-enter-active {
  right: 0;
  transition: right 300ms;
}

.page-slide-exit {
  position: absolute;
  right: 0;
}

.page-slide-exit-active {
  right: 100%;
  transition: right 300ms;
}

.slide-right-enter {
  right: -100%;
}

.slide-right-enter-active {
  right: 0;
  transition: right 300ms;
}

.slide-right-exit {
  right: 0;
}

.slide-right-exit-active {
  right: -100%;
  transition: right 300ms;
}

.spread-enter {
  transform-origin: top;
  transform: translateZ(0) scaleY(0);
  opacity: 0;
}

.spread-enter-active {
  opacity: 1;
  transform: translateZ(0) scaleY(1);
  transition: all 300ms;
}

.spread-exit {
  opacity: 1;
  transform: translateZ(0) scaleY(1);
  transform-origin: top;
}

.spread-exit-active {
  opacity: 0;
  transform: translateZ(0) scaleY(0);
  transition: all 300ms;
}

.rotate180-enter {
  transform: rotate(0deg);
}

.rotate180-enter-active {
  transform: rotate(180deg);
  transition: all 300ms;
}

.rotate180-enter-done {
  transform: rotate(180deg);
}

.rotate180-exit {
  transform: rotate(180deg);
}

.rotate180-exit-active {
  transform: rotate(360deg);
  transition: all 300ms;
}

.drop-right-enter {
  transform: translateY(-100%);
}

.drop-right-enter-active {
  transform: translateY(0);
  transition: all .3s;
}

.drop-right-exit {
  transform: translateY(0);
}

.drop-right-exit-active {
  transform: translateY(-100%);
  transition: all .3s;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms,transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms,transform 300ms;
}

.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
}

.icon-message {
  display: inline-block;
  width: 0.28rem;
  height: 0.21rem;
  background-size: 100% 100%;
  fill: #fff;
}

.provider-logo {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.icon-copy {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.new-icon,
.hot-icon,
.promo-icon {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 0.79rem;
  border: none !important;
  outline: none !important;
  outline-offset: unset !important;
  z-index: 2;
}

.tnc-apply {
  color: #0a974b;
  font-size: 0.16rem;
  font-family: "AvenirNextLTPro-Bold";
  border-bottom: 0.01rem solid;
}

html:lang(vi) .tnc-apply {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

@media (min-width: 1700px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 16%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2100px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 17%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2200px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 18%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2300px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 19%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2400px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 20.5%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2500px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 22%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2650px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 23%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2750px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 25%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3000px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 26%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3200px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 27%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3300px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 29%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3600px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 31%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 4000px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 33%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 1441px) {
  .menu-animation-enter {
    transform-origin: top;
    transform: translateZ(0) translateX(-50%) scaleY(0);
    opacity: 0;
  }

  .menu-animation-enter-active {
    opacity: 1;
    transform: translateZ(0) translateX(-50%) scaleY(1);
    transition: all 300ms;
  }

  .menu-animation-exit {
    opacity: 1;
    transform: translateZ(0) translateX(-50%) scaleY(1);
    transform-origin: top;
  }

  .menu-animation-exit-active {
    opacity: 0;
    transform: translateZ(0) translateX(-50%) scaleY(0);
    transition: all 300ms;
  }
}

@media (min-width: 1025px)and (max-width: 1440px) {
  .menu-animation-enter {
    transform-origin: top;
    transform: translateZ(0) scaleY(0);
    opacity: 0;
  }

  .menu-animation-enter-active {
    opacity: 1;
    transform: translateZ(0) scaleY(1);
    transition: all 300ms;
  }

  .menu-animation-exit {
    opacity: 1;
    transform: translateZ(0) scaleY(1);
    transform-origin: top;
  }

  .menu-animation-exit-active {
    opacity: 0;
    transform: translateZ(0) scaleY(0);
    transition: all 300ms;
  }
}

@media (max-width: 1025px) {
  .bg-header-top .content-container {
    max-width: unset;
  }

  .bg-header-bottom {
    height: 100%;
  }

  .bg-header-bottom .content-container {
    max-width: unset;
  }

  .text-red {
    font-size: 0.2rem;
  }

  .new-icon,
  .hot-icon,
  .promo-icon {
    max-width: 1.2rem;
  }

  .tnc-apply {
    font-size: 0.3rem;
  }

  .provider-logo {
    left: 1.8rem;
    bottom: 0.8rem;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .menu-animation-enter {
    right: -4.22rem;
  }

  .menu-animation-enter-active {
    right: 0;
    transition: right 300ms;
  }

  .menu-animation-exit {
    right: 0;
  }

  .menu-animation-exit-active {
    right: -4.22rem;
    transition: right 300ms;
  }

  .icon-lan,
  .icon-lan-white {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-en,
  .icon-thai,
  .icon-idr {
    width: 0.48rem;
    height: 0.26rem;
  }

  .icon-flag {
    width: 0.48rem;
    height: 0.48rem;
  }

  .icon-logo {
    max-width: 4.66rem;
    max-height: 1.5rem;
  }

  .icon-reload {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-fund {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-game-menu {
    width: 0.48rem;
    height: 0.3rem;
  }

  .icon-raffle {
    width: 0.72rem;
    height: 0.5rem;
  }

  .icon-evo {
    width: 0.72rem;
    height: 0.8rem;
  }

  .icon-download {
    width: 0.4rem;
    height: 0.58rem;
  }

  .icon-contact {
    width: 0.53rem;
    height: 0.53rem;
  }

  .icon-notice {
    width: 1.21rem;
    height: 0.44rem;
    max-width: unset;
  }

  .icon-pagcor {
    width: 1.87rem;
    height: 0.42rem;
  }

  .icon-iovation {
    width: 1.785rem;
    height: 0.36rem;
  }

  .icon-bmm {
    width: 0.825rem;
    height: 0.255rem;
  }

  .icon-itechlab {
    width: 0.615rem;
    height: 0.585rem;
  }

  .icon-global {
    width: 0.72rem;
    height: 0.72rem;
  }

  .icon-godaddy {
    width: 2.025rem;
    height: 0.39rem;
  }

  .icon-payouts {
    width: 1.245rem;
    height: 0.435rem;
  }

  .icon-youtube {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-eeziepay {
    width: 1.155rem;
    height: 0.48rem;
  }

  .icon-paytrust {
    width: 1.35rem;
    height: 0.285rem;
  }

  .icon-help2pay {
    width: 0.555rem;
    height: 0.39rem;
  }

  .icon-hot {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-new {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-arrow-big {
    width: 0.6rem;
    height: 0.6rem;
  }

  .icon-meda {
    width: 0.44rem;
    height: 0.66rem;
  }

  .icon-message {
    width: 0.42rem;
    height: 0.32rem;
  }

  .icon-search {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-on,
  .icon-off {
    width: 0.9rem;
    height: 0.3rem;
  }

  .icon-sapphire,
  .icon-ruby,
  .icon-diamond {
    width: 1.06rem;
    height: 0.84rem;
  }

  .icon-notice-important {
    width: 0.44rem;
    height: 0.39rem;
  }

  .icon-tip {
    width: 0.27rem;
    height: 0.27rem;
  }

  .icon-transfer-btn {
    width: 0.3rem;
    height: 0.26rem;
  }

  .icon-checked {
    width: 0.36rem;
    height: 0.36rem;
  }

  .icon-transfer-to {
    width: 0.27rem;
    height: 0.17rem;
  }

  .icon-love2 {
    width: 0.45rem;
    height: 0.38rem;
  }

  .icon-username {
    width: 0.3rem;
    height: 0.46rem;
  }

  .icon-lock {
    width: 0.44rem;
    height: 0.54rem;
  }

  .btn-swiper-point,
  .content-section .content-recommended .slick-dots li,
  .bg-content-section .content-promotions .slick-dots li,
  .slider>ul.tab li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .text-banner-green {
    font-size: 1.22rem;
  }

  .text-banner-white {
    font-size: 0.56rem;
    line-height: 0.8rem;
  }

  .text-banner-white-small {
    font-size: 0.3rem;
  }

  .btn-skew {
    width: 2.18rem;
    height: 0.58rem;
    font-size: 0.3rem;
  }

  .btn-normal,
  .btn-normal-green,
  .btn-long {
    width: 2.26rem;
    height: 0.54rem;
    font-size: 0.23rem;
  }

  .btn-long {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 0.3rem;
  }
}

@media (max-width: 768px) {
  .bg-header-bottom .content-container {
    padding: unset;
  }

  .icon-copy {
    width: 0.63rem;
    height: 0.7rem;
  }

  .icon-arrow-down {
    fill: #10572c;
  }

  .icon-home {
    height: 0.3rem;
  }

  .icon-casino,
  .icon-poker,
  .icon-vip,
  .icon-slots,
  .icon-sports,
  .icon-favourite {
    width: 0.32rem;
    height: 0.32rem;
  }

  .icon-e-sport,
  .icon-promotion {
    width: 0.39rem;
    height: 0.27rem;
  }

  .icon-fishing {
    width: 0.39rem;
    height: 0.42rem;
  }

  .icon-918kiss {
    width: 0.42rem;
    height: 0.7rem;
  }

  .daily-limit {
    margin-top: 0.2rem;
    color: #41b850;
    font-size: 0.35rem;
  }

  .daily-limit>span>span:first-child {
    margin-left: 0.1rem;
  }

  .provider-logo {
    left: 0.3rem;
    bottom: 0.5rem;
  }

  .swiper-arrow {
    display: none;
  }

  .menu-animation-enter {
    right: -4.14rem;
  }

  .menu-animation-enter-active {
    right: 0;
    transition: right 300ms;
  }

  .menu-animation-exit {
    right: 0;
  }

  .menu-animation-exit-active {
    right: -4.14rem;
    transition: right 300ms;
  }

  .icon-lan,
  .icon-lan-white {
    width: 0.33rem;
    height: 0.35rem;
  }

  .icon-reload {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-fund {
    width: 0.35rem;
    height: 0.35rem;
    fill: #10572c;
  }

  .icon-restore {
    fill: #10572c;
  }

  .icon-en,
  .icon-thai,
  .icon-idr {
    width: 0.41rem;
    height: 0.26rem;
  }

  .icon-flag {
    width: 0.41rem;
    height: 0.41rem;
  }

  .icon-logo {
    max-width: 3.5rem;
    max-height: 1rem;
    margin-right: 0;
    margin-left: 0.3rem;
  }

  .completed-main .icon-logo {
    max-width: 5rem;
    max-height: 3.85rem;
    margin-right: 0;
  }

  .icon-game-menu {
    width: 0.67rem;
    height: 0.43rem;
  }

  .icon-raffle {
    width: 0.72rem;
    height: 0.5rem;
  }

  .icon-download {
    width: 0.4rem;
    height: 0.58rem;
  }

  .icon-contact {
    width: 0.53rem;
    height: 0.53rem;
  }

  .icon-notice {
    width: 1.5rem;
    height: 0.49rem;
    max-width: unset;
  }

  .icon-curaccao {
    width: 2rem;
    height: 0.9rem;
  }

  .icon-pagcor {
    width: 1.87rem;
    height: 0.42rem;
  }

  .icon-iovation {
    width: 1.785rem;
    height: 0.36rem;
  }

  .icon-tm {
    width: 2.43rem;
    height: 0.8rem;
  }

  .icon-bmm {
    width: 0.825rem;
    height: 0.255rem;
  }

  .icon-itechlab {
    width: 0.615rem;
    height: 0.585rem;
  }

  .icon-global {
    width: 0.72rem;
    height: 0.72rem;
  }

  .icon-godaddy {
    width: 2.025rem;
    height: 0.39rem;
  }

  .icon-payouts {
    width: 1.245rem;
    height: 0.435rem;
  }

  .icon-youtube {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-eeziepay {
    width: 1.4rem;
    height: 0.6rem;
    margin: 0 0.1rem;
  }

  .icon-paytrust {
    width: 1.35rem;
    height: 0.285rem;
  }

  .icon-help2pay {
    width: 0.9rem;
    height: 0.6rem;
    margin: 0 0.1rem;
  }

  .icon-bitcoin {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-usdt {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-paynow {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-paynow-v2 {
    width: 1rem;
    height: 0.9rem;
  }

  .icon-eth {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-duitnow {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-instantpay {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-tng {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-grab {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-boost {
    width: 1.65rem;
    height: 0.9rem;
  }

  .icon-shopee {
    width: 1.5rem;
    height: 0.9rem;
  }

  .icon-truepay {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-surepay {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-hot {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-new {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-arrow-big {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-meda {
    width: 0.48rem;
    height: 0.71rem;
  }

  .icon-message {
    width: 0.42rem;
    height: 0.32rem;
  }

  .icon-search {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-on,
  .icon-off {
    width: 0.9rem;
    height: 0.3rem;
  }

  .icon-sapphire,
  .icon-ruby,
  .icon-diamond {
    width: 1.82rem;
    height: 1.19rem;
  }

  .icon-notice-important {
    width: 0.44rem;
    height: 0.39rem;
  }

  .icon-tip {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-transfer-btn {
    width: 0.3rem;
    height: 0.26rem;
  }

  .icon-checked {
    width: 0.36rem;
    height: 0.36rem;
  }

  .icon-transfer-to {
    width: 0.27rem;
    height: 0.17rem;
  }

  .icon-love2 {
    width: 0.37rem;
    height: 0.31rem;
  }

  .icon-username {
    width: 0.4rem;
    height: 0.56rem;
  }

  .icon-lock {
    width: 0.54rem;
    height: 0.64rem;
  }

  .btn-swiper-point,
  .content-section .content-recommended .slick-dots li,
  .bg-content-section .content-promotions .slick-dots li,
  .slider>ul.tab li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .text-banner-green {
    font-size: 0.7rem;
  }

  .text-banner-white {
    font-size: 0.4rem;
    line-height: 0.6rem;
  }

  .text-banner-white-small {
    font-size: 0.24rem;
  }

  .btn-skew {
    width: 2.2rem;
    height: 0.46rem;
    font-size: 0.24rem;
  }

  .btn-normal,
  .btn-normal-green,
  .btn-long {
    width: 3.05rem;
    height: 0.54rem;
    font-size: 0.38rem;
    border: none;
  }

  .btn-long {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 0.76rem;
  }

  .new-icon,
  .hot-icon,
  .promo-icon {
    max-width: 1.5rem;
  }
}

.content-section .home-content-section,
.content-section .home-content-section-with-marginTop {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-section .home-content-section>li,
.content-section .home-content-section-with-marginTop>li {
  width: 100%;
}

.content-section .home-content-section>li:not(:last-child),
.content-section .home-content-section-with-marginTop>li:not(:last-child) {
  margin-bottom: 0.55rem;
}

.content-section .home-content-section>li .content-center,
.content-section .home-content-section-with-marginTop>li .content-center {
  position: relative;
}

.content-section .home-content-section>li.home-content-app img,
.content-section .home-content-section-with-marginTop>li.home-content-app img {
  width: 100%;
}

.content-section .home-content-section>li.home-content-app .app-detail-container,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container {
  position: absolute;
  left: 8.5rem;
  bottom: 0.5rem;
  width: 50%;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .desc1,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .desc1 {
  color: #ddc175;
  font-size: 0.2rem;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .content-section .home-content-section>li.home-content-app .app-detail-container .desc1,
html:lang(vi) .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .desc1 {
  font-family: "Alexandria";
  font-weight: 500;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .desc2,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .desc2 {
  font-size: .4rem;
  font-family: "AvenirNextLTPro-Bold";
  color: #fff;
}

html:lang(vi) .content-section .home-content-section>li.home-content-app .app-detail-container .desc2,
html:lang(vi) .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .desc2 {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download {
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container:first-child,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container:first-child {
  margin-right: 0.5rem;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .download-qr-img-container,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .download-qr-img-container {
  margin: 0.15rem 0;
  width: 1.5rem;
  height: 1.5rem;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .download-qr-img-container .download-qr-img,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .download-qr-img-container .download-qr-img {
  background: #fff;
  padding: 0.1rem;
  width: 100% !important;
  height: 100% !important;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner {
  display: flex;
  color: #fff;
  align-items: center;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner button,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner button {
  width: 1.75rem;
}

.content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner svg,
.content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner svg {
  fill: #fff;
  width: 0.25rem;
  margin: 0 0.1rem 0 0;
}

.content-section .home-content-section>li.home-content-raffle,
.content-section .home-content-section>li.home-content-progressive,
.content-section .home-content-section-with-marginTop>li.home-content-raffle,
.content-section .home-content-section-with-marginTop>li.home-content-progressive {
  width: 7.12rem;
}

.content-section .home-content-section>li.home-content-raffle .content-center,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center {
  height: 5.16rem;
  padding-top: 0.89rem;
}

.content-section .home-content-section>li.home-content-raffle .content-center .countdown,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown {
  display: block;
  width: 6.11rem;
  height: 0.97rem;
  background-size: 100% 100%;
  margin: 0 auto;
  position: relative;
}

.content-section .home-content-section>li.home-content-raffle .content-center .countdown::before,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown::before {
  content: "";
  position: absolute;
  background: url("../assets/home/countdown_bg.webp") no-repeat;
  width: 6.55rem;
  height: 1.37rem;
  background-size: 100% 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.content-section .home-content-section>li.home-content-raffle .content-center .countdown ul,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  top: -0.52rem;
  transform: translateX(-50%);
  width: 3.88rem;
}

.content-section .home-content-section>li.home-content-raffle .content-center .countdown ul li,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
}

.content-section .home-content-section>li.home-content-raffle .content-center .countdown ul li>span:nth-child(1),
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul li>span:nth-child(1) {
  font-size: 0.8rem;
  color: #fff;
  background: url("../assets/home/time_bg.png") no-repeat;
  width: 1.14rem;
  height: 1.04rem;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.05rem;
}

.content-section .home-content-section>li.home-content-raffle .content-center .countdown ul li>span:nth-child(2),
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul li>span:nth-child(2) {
  font-size: 0.24rem;
  color: #dadada;
  font-weight: bold;
  text-transform: uppercase;
}

.content-section .home-content-section>li.home-content-raffle .content-center .ranks,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.44rem;
}

.content-section .home-content-section>li.home-content-raffle .content-center .ranks li,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li {
  width: 6.1rem;
  height: 0.54rem;
  margin-bottom: 0.08rem;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  padding-left: 0.94rem;
  padding-right: 0.3rem;
  position: relative;
}

.content-section .home-content-section>li.home-content-raffle .content-center .ranks li .icon-meda,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li .icon-meda {
  position: absolute;
  top: 0;
  left: 0.36rem;
  font-style: normal;
  color: #fff153;
  display: flex;
  justify-content: center;
  line-height: 0.24rem;
  align-items: flex-end;
  padding-bottom: 0.01rem;
  font-size: 0.24rem;
  font-family: Roboto;
  font-weight: bold;
}

.content-section .home-content-section>li.home-content-raffle .content-center .ranks li .icon-lighting,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li .icon-lighting {
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.content-section .home-content-section>li.home-content-raffle .content-center .ranks li>span:nth-child(1),
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li>span:nth-child(1) {
  font-size: 0.24rem;
  font-weight: bold;
  font-family: OpenSans;
}

.content-section .home-content-section>li.home-content-raffle .content-center .ranks li>span:nth-child(3),
.content-section .home-content-section>li.home-content-raffle .content-center .ranks li>span:nth-child(4),
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li>span:nth-child(3),
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li>span:nth-child(4) {
  font-family: Roboto;
  font-size: 0.36rem;
  font-weight: bold;
  white-space: nowrap;
}

.content-section .home-content-section>li.home-content-raffle .content-center .ranks li:nth-child(1),
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li:nth-child(1) {
  background: #d19f22;
}

.content-section .home-content-section>li.home-content-raffle .content-center .ranks li:nth-child(2),
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li:nth-child(2) {
  background: #1a733d;
}

.content-section .home-content-section>li.home-content-raffle .content-center .ranks li:nth-child(3),
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li:nth-child(3) {
  background: #41b852;
}

.content-section .home-content-section>li.home-content-raffle .content-center .tools,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .tools {
  padding: 0 0.5rem;
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-section .home-content-section>li.home-content-raffle .content-center .tools>p,
.content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .tools>p {
  font-size: 0.14rem;
  color: #222;
  font-family: OpenSans;
  white-space: nowrap;
}

.content-section .home-content-section>li.home-content-progressive .content-center,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center {
  padding: 0 0.32rem 0 0.15rem;
  height: 5.15rem;
}

.content-section .home-content-section>li.home-content-progressive .content-center .slider ul.tab,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .slider ul.tab {
  top: -0.4rem;
  right: 0.2rem;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5.16rem;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li {
  width: 6.66rem;
  height: 1.58rem;
  margin: 0.05rem 0;
  padding: 0.14rem;
  display: flex;
  align-items: center;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li:nth-child(even),
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li:nth-child(even) {
  background: #373737;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li:nth-child(even) .item-content .item-content-bottom::after,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li:nth-child(even) .item-content .item-content-bottom::after {
  background: #373737;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content {
  margin-left: 0.02rem;
  width: 4.24rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top {
  padding-left: 0.26rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.6rem;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .content-title,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .content-title {
  font-size: 0.24rem;
  color: #39b549;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .icon-918kiss,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .icon-918kiss {
  width: 1rem;
  height: 0.42rem;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .icon-SG,
.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .icon-PT,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .icon-SG,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .icon-PT {
  width: 0.5rem;
  height: 0.5rem;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom {
  font-family: Roboto;
  padding-left: 0.26rem;
  font-size: 0.36rem;
  font-weight: bold;
  color: #fff;
  height: 0.5rem;
  background: linear-gradient(270deg, rgb(25, 115, 61), rgb(57, 181, 73));
  display: flex;
  align-items: center;
  position: relative;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom>p,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom>p {
  height: 100%;
  display: flex;
  align-items: center;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom::after,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom::after {
  content: "";
  width: 0.51rem;
  height: 0.51rem;
  transform: skew(-45deg);
  background: #222;
  position: absolute;
  top: 0;
  right: -0.26rem;
}

.content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li img,
.content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li img {
  height: 100%;
}

.content-section .home-content-section>li.home-content-recommendCasino .favourite-flow .nav-game,
.content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .favourite-flow .nav-game {
  max-width: 3.06rem;
}

.content-section .home-content-section>li.home-content-recommendCasino .nav-game li,
.content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li {
  width: 100%;
  height: 100%;
  background: #dad8d8;
  border: 0.02rem solid #d8d8d8;
}

.content-section .home-content-section>li.home-content-recommendCasino .nav-game li>img,
.content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li>img {
  max-height: 2.46rem;
}

.content-section .home-content-section>li.home-content-recommendCasino .nav-game li>p,
.content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li>p {
  height: 0.56rem;
}

.content-section .home-content-section>li.home-content-recommendCasino .nav-game li>p .gamename,
.content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li>p .gamename {
  left: 0.7rem;
  font-size: 0.15rem;
  color: #dec276;
  font-weight: bold;
  font-family: "AvenirNextLTPro-Bold";
  position: unset;
  width: 1.9rem;
  transform: unset;
}

.content-section .home-content-section>li.home-content-recommendCasino .nav-game li .icon-type,
.content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li .icon-type {
  width: 100%;
  max-width: 0.5rem;
  margin-bottom: 0.12rem;
  fill: #e5e5e5;
}

.content-section .home-content-section>li.home-content-recommendCasino .nav-game li .icon-brand,
.content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li .icon-brand {
  width: 0.42rem;
  height: 0.42rem;
}

.content-section .home-content-section>li.home-content-recommend,
.content-section .home-content-section-with-marginTop>li.home-content-recommend {
  width: 7.12rem;
}

.content-section .home-content-section>li.home-content-recommend .content-center,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .content-center {
  padding: 0 0.32rem 0 0.15rem;
  height: 5.15rem;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .content-center,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .content-center {
  padding: 0;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .recommed-slick-dots,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .recommed-slick-dots {
  display: flex;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .recommed-slick-dots .slick-btn,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .recommed-slick-dots .slick-btn {
  display: inline-block;
  width: 0.32rem;
  height: 0.08rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  background: #e2dcde;
  margin: 0;
  margin-left: 0.07rem;
  cursor: pointer;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .recommed-slick-dots .slick-btn.active,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .recommed-slick-dots .slick-btn.active {
  background: #39b549;
  cursor: default;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .category-box,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .category-box {
  display: flex;
  padding: 0.37rem 0.32rem 0.25rem;
  white-space: nowrap;
  overflow-x: scroll;
  text-align: center;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .category-box .category-wrapper,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .category-box .category-wrapper {
  padding: 0 0.3rem;
  font-family: "AvenirNextLTPro";
  font-size: 0.24rem;
  color: #858585;
  border-right: 0.01rem solid #dadada;
  font-weight: bold;
  cursor: pointer;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .content-section .home-content-section>li.home-content-recommend .recommedGame-box .category-box .category-wrapper,
html:lang(vi) .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .category-box .category-wrapper {
  font-family: "Alexandria";
  font-weight: 500;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .category-box .category-wrapper:first-child,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .category-box .category-wrapper:first-child {
  padding-left: 0;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .category-box .category-wrapper:last-child,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .category-box .category-wrapper:last-child {
  padding-right: 0;
  border-right: none;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .category-box .active,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .category-box .active {
  color: #0a974b;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-height: 3.95rem;
  padding: 0 0.3rem;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: -1.53rem;
  bottom: unset;
  right: 0;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots li,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots li {
  display: inline-block;
  width: 0.32rem;
  height: 0.08rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  background: #e2dcde;
  margin: 0;
  margin-left: 0.07rem;
  cursor: pointer;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots li.slick-active,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots li.slick-active {
  background: #39b549;
  cursor: default;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots li button,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots li button {
  height: 100%;
  width: 100%;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots li button::before,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots li button::before {
  content: unset;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .subGame-box,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .subGame-box {
  width: 100% !important;
  height: 1.97rem;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.25rem;
  animation: fadeIn 1s ease;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .subGame-box:nth-child(odd),
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .subGame-box:nth-child(odd) {
  background: #222;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .subGame-box:nth-child(even),
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .subGame-box:nth-child(even) {
  background: #373737;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-img,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-img {
  width: 2.05rem;
  height: 1.35rem;
  border: 0.01rem solid #cfb36b;
  background: #cfb36b;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail {
  width: 2.11rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0.1rem;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-title,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-title {
  font-family: "Alexandria","OpenSans";
  font-size: 0.2rem;
  color: #c59b4e;
  font-weight: bold;
  margin-bottom: 0.14rem;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating {
  margin-bottom: 0.33rem;
  display: flex;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating img,
.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating svg,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating img,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating svg {
  margin-right: 0.06rem;
  width: 0.2rem;
  object-fit: contain;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating img:last-child,
.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating svg:last-child,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating img:last-child,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating svg:last-child {
  margin-right: 0;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  color: #f5f5f5;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider i,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider i {
  margin-right: 0.1rem;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider span,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider span {
  margin-left: 0.1rem;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn>span,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn>span {
  margin-bottom: 0.15rem;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.16rem;
  color: #858585;
  font-family: "AvenirNextLTPro";
  white-space: nowrap;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite i,
.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite svg,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite i,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite svg {
  margin-right: 0.1rem;
}

.content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-slider,
.content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-slider {
  width: 100%;
}

.content-section .content-recommended .content-center {
  height: 100%;
}

.content-section .content-recommended .content-center.favourite-content {
  padding: 0.48rem 1.07rem 0.24rem;
}

.content-section .content-recommended .content-center .slider ul.tab {
  top: -0.8rem;
  right: -0.9rem;
}

.content-section .content-recommended .slick-next-btn,
.content-section .content-recommended .slick-prev-btn {
  position: absolute;
  display: flex !important;
  align-items: center;
  height: 100%;
  padding-bottom: 0;
  top: 0;
  right: -0.25rem;
  z-index: 1;
}

.content-section .content-recommended .slick-prev-btn {
  left: -0.25rem;
  right: unset;
  z-index: 2;
}

.content-section .content-recommended .slick-list {
  z-index: 1;
  max-height: 4.16rem;
}

.content-section .content-recommended .slick-dots {
  top: -0.6rem;
  right: 0.25rem;
  z-index: 0;
  text-align: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.content-section .content-recommended .slick-dots li {
  margin: 0;
  margin-left: 0.07rem;
}

.content-section .content-recommended .slick-dots li button:before {
  content: unset;
}

.content-section .content-recommended .slick-dots li.slick-active {
  background: #39b549;
}

.recommendCasino-box .icon-brand {
  border-radius: 50%;
}

.recommendCasino-box .recommendCasino-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommendCasino-box .recommendCasino-title .content-title {
  white-space: nowrap;
}

.recommendCasino-box .recommendCasino-title img {
  width: 100%;
  height: 100%;
  max-width: 1.5rem;
  margin-left: 0.1rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.recommendCasino-box .content-center-box .content-center .nav-game li .evo-game {
  opacity: 0;
  transition: all .2s ease-in;
  transform: unset;
}

.recommendCasino-box .content-center-box .content-center .nav-game li .evo-game video {
  max-height: 2.46rem;
  width: 100%;
  position: relative;
  z-index: 1;
}

.recommendCasino-box .content-center-box .content-center .nav-game li .evo-game .content-text {
  position: absolute;
  bottom: 0.55rem;
  z-index: 3;
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  font-weight: bold;
  color: #dec276;
  width: 100%;
  height: 0.3rem;
  padding: 0 0.25rem;
  background: linear-gradient(to top, #000 5%, transparent);
}

.recommendCasino-box .content-center-box .content-center .nav-game li .evo-game .game-content {
  width: 100%;
  height: 0.8rem;
  padding: 0 0.15rem;
  background: #000;
}

.recommendCasino-box .content-center-box .content-center .nav-game li .evo-game .game-content .btn-normal,
.recommendCasino-box .content-center-box .content-center .nav-game li .evo-game .game-content .btn-normal-green,
.recommendCasino-box .content-center-box .content-center .nav-game li .evo-game .game-content .btn-long {
  width: 100%;
}

.recommendCasino-box .content-center-box .content-center .nav-game li:hover .evo-game {
  opacity: 1;
}

@media (min-width: 768px)and (max-width: 1025px) {
  .content-section .home-content-section>li.home-content-app img,
  .content-section .home-content-section-with-marginTop>li.home-content-app img {
    width: 100%;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container {
    position: absolute;
    left: 11.5rem;
    bottom: 0.5rem;
    width: 50%;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .desc1,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .desc1 {
    color: #ddc175;
    font-size: .4rem;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .desc2,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .desc2 {
    font-size: .6rem;
    color: #fff;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download {
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container:first-child,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container:first-child {
    margin-right: 1rem;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .download-qr-img-container,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .download-qr-img-container {
    margin: 0.25rem 0;
    width: 2.25rem;
    height: 2.25rem;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner {
    display: flex;
    color: #fff;
    align-items: center;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner svg,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner svg {
    fill: #fff;
    width: 0.35rem;
    margin: 0 0.1rem 0 0;
  }

  .content-section .home-content-section>li.home-content-raffle,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle {
    width: 9.64rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center {
    height: 6.9rem;
    padding-top: 1.2rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown {
    width: 8.26rem;
    height: 1.31rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown::before,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown::before {
    width: 8.5rem;
    height: 1.55rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown ul,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul {
    width: 5.22rem;
    top: -0.72rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown ul li>span:nth-child(1),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul li>span:nth-child(1) {
    width: 1.55rem;
    height: 1.42rem;
    font-size: 0.8rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown ul li>span:nth-child(2),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul li>span:nth-child(2) {
    font-size: 0.26rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks {
    margin-top: 0.6rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li {
    width: 8.24rem;
    height: 0.72rem;
    margin-bottom: 0.08rem;
    padding-left: 1.3rem;
    padding-right: 0.42rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li .icon-meda,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li .icon-meda {
    font-size: 0.25rem;
    line-height: 0.25rem;
    padding-bottom: 0.08rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li .icon-lighting,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li .icon-lighting {
    width: 0.8rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li>span:nth-child(3),
  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li>span:nth-child(4),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li>span:nth-child(3),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li>span:nth-child(4) {
    font-size: 0.36rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li>span:nth-child(1),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li>span:nth-child(1) {
    font-size: 0.26rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .tools,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .tools {
    margin-top: 0.3rem;
    padding: 0 0.7rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .tools>p,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .tools>p {
    font-size: 0.2rem;
  }

  .content-section .home-content-section>li.home-content-progressive,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive {
    width: 8.98rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center {
    height: 6.9rem;
    padding: 0.11rem 0;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .slider>ul.tab,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .slider>ul.tab {
    top: -0.78rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list {
    height: 6.6rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li {
    width: 100%;
    height: 2.1rem;
    margin: 0.07rem 0;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li img,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li img {
    width: 2.76rem;
    height: 1.81rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content {
    width: 5.76rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top {
    height: 0.87rem;
    padding-left: 0.34rem;
    font-size: 0.3rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .content-title,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .content-title {
    font-size: 0.33rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom {
    padding-left: 0.34rem;
    height: 0.66rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom::after,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom::after {
    width: 0.67rem;
    height: 0.67rem;
    right: -0.32rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .favourite-flow .nav-game,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .favourite-flow .nav-game {
    max-width: 4rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .content-center,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .content-center {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li {
    width: 3.56rem;
    height: 3rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li>p,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li>p {
    height: 0.63rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li>p .gamename,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li>p .gamename {
    font-size: 0.18rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li>img,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li>img {
    max-height: 3rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li .icon-brand,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li .icon-brand {
    width: 0.35rem;
    height: 0.35rem;
  }

  .content-section .home-content-section>li.home-content-recommend,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend {
    width: 8.98rem;
  }

  .content-section .home-content-section>li.home-content-recommend .content-center,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .content-center {
    height: 6.9rem;
    padding: 0.11rem 0;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .recommed-slick-dots .slick-btn,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .recommed-slick-dots .slick-btn {
    width: 0.56rem;
    height: 0.12rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .category-box .category-wrapper,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .category-box .category-wrapper {
    font-size: 0.32rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box {
    max-height: unset;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots {
    top: -1.98rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots li,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .subGame-box,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .subGame-box {
    width: 100%;
    height: 2.8rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-img,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-img {
    width: 2.6rem;
    height: 1.7rem;
    border: 0.02rem solid #cfb36b;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail {
    width: 2.6rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-title,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-title {
    font-size: 0.32rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider {
    font-size: 0.24rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating img,
  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating svg,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating img,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating svg {
    width: 0.25rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite {
    font-size: 0.24rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite i,
  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite svg,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite i,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite svg {
    transform: scale(1.5);
    margin-right: 0.3rem;
  }

  .content-section .content-recommended .slick-list {
    max-height: 6.85rem;
  }

  .content-section .content-recommended .slick-dots {
    top: -0.93rem;
  }

  .content-section .content-recommended .content-center {
    height: 6.6rem;
    width: 19.05rem;
  }

  .content-section .content-recommended .content-center .slider ul.tab {
    top: -1rem;
    right: -0.9rem;
  }

  .recommendCasino-box .recommendCasino-title img {
    max-width: 2rem;
  }

  .recommendCasino-box .content-center-box .content-center .nav-game li .evo-game video {
    max-height: 2.5rem;
  }

  .recommendCasino-box .content-center-box .content-center .nav-game li .evo-game .content-text {
    bottom: 0.65rem;
    font-size: 0.18rem;
    height: 0.5rem;
  }

  .recommendCasino-box .content-center-box .content-center .nav-game li .evo-game .game-content {
    height: 0.69rem;
  }
}

@media (max-width: 768px) {
  .content-section .home-content-section>li.home-content-app .content-center,
  .content-section .home-content-section-with-marginTop>li.home-content-app .content-center {
    margin: 0;
  }

  .content-section .home-content-section>li.home-content-app img,
  .content-section .home-content-section-with-marginTop>li.home-content-app img {
    width: 100%;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container {
    position: absolute;
    left: 6.75rem;
    bottom: 1rem;
    width: 45%;
    font-size: .25rem;
    padding: 0 0.3rem;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .desc1,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .desc1 {
    color: #ddc175;
    font-size: .3rem;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .desc2,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .desc2 {
    font-size: .5rem;
    color: #fff;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container:first-child,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container:first-child {
    margin: 0 0 0.4rem 0;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner {
    display: flex;
    color: #000;
    padding: 0.1rem;
    width: 100%;
    height: 0.75rem;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner .device,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner .device {
    font-size: .3rem;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner svg,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .detail-tab-inner svg {
    fill: #000;
    width: 0.45rem;
    margin: 0 0.1rem 0 0;
    overflow: visible;
  }

  .content-section .home-content-section>li.home-content-app .app-detail-container .app-detail-download .detail-container .btn-instruction,
  .content-section .home-content-section-with-marginTop>li.home-content-app .app-detail-container .app-detail-download .detail-container .btn-instruction {
    color: #fff;
    text-decoration: underline;
  }

  .content-section .home-content-section>li.home-content-raffle,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle {
    width: 11.6rem;
    margin: 0 auto;
  }

  .content-section .home-content-section>li.home-content-raffle .content-top,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-top {
    padding-left: 0.24rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center {
    height: 7.68rem;
    padding-top: 1.32rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown {
    width: 9.16rem;
    height: 1.46rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown::before,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown::before {
    width: 9.7rem;
    height: 1.94rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown ul,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul {
    width: 5.84rem;
    top: -0.72rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown ul li>span:nth-child(1),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul li>span:nth-child(1) {
    width: 1.71rem;
    height: 1.56rem;
    font-size: 1.17rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .countdown ul li>span:nth-child(2),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .countdown ul li>span:nth-child(2) {
    font-size: 0.36rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks {
    margin-top: 0.64rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li {
    width: 9.16rem;
    height: 0.78rem;
    margin-bottom: 0.08rem;
    padding-left: 1.45rem;
    padding-right: 0.45rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li .icon-meda,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li .icon-meda {
    font-size: 0.36rem;
    line-height: 0.36rem;
    padding-bottom: 0.03rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li .icon-lighting,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li .icon-lighting {
    width: 0.8rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li>span:nth-child(3),
  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li>span:nth-child(4),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li>span:nth-child(3),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li>span:nth-child(4) {
    font-size: 0.4rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .ranks li>span:nth-child(1),
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .ranks li>span:nth-child(1) {
    font-size: 0.32rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .tools,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .tools {
    margin-top: 0.47rem;
    padding: 0 1.24rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .tools .btn-long,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .tools .btn-long {
    font-size: 0.32rem;
    margin-right: 0.5rem;
  }

  .content-section .home-content-section>li.home-content-raffle .content-center .tools p,
  .content-section .home-content-section-with-marginTop>li.home-content-raffle .content-center .tools p {
    font-size: 0.23rem;
  }

  .content-section .home-content-section>li.home-content-progressive,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive {
    width: 11.6rem;
    margin: 0 auto;
  }

  .content-section .home-content-section>li.home-content-progressive .content-top,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-top {
    padding-left: 0.24rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center {
    height: 7.4rem;
    padding: 0 0.82rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list {
    height: 100%;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li {
    width: 9.96rem;
    height: 2.33rem;
    margin: 0.08rem 0;
    padding: 0.17rem 0.23rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li img,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li img {
    width: 3.01rem;
    height: 1.93rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content {
    width: 6.38rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top {
    height: 0.87rem;
    padding-left: 0.41rem;
    font-size: 0.36rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .content-title,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-top .content-title {
    font-size: 0.36rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom {
    padding-left: 0.41rem;
    height: 0.73rem;
    font-size: 0.54rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom::after,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .jackpot-list li .item-content .item-content-bottom::after {
    width: 0.74rem;
    height: 0.74rem;
    right: -0.38rem;
  }

  .content-section .home-content-section>li.home-content-progressive .content-center .slider ul.tab,
  .content-section .home-content-section-with-marginTop>li.home-content-progressive .content-center .slider ul.tab {
    top: -0.79rem;
    right: -0.7rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li {
    width: 3.45rem;
    height: 2.97rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li>P,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li>P {
    height: 0.65rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li>P .gamename,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li>P .gamename {
    font-size: 0.22rem;
    width: 2.45rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li>img,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li>img {
    max-height: 2.46rem;
  }

  .content-section .home-content-section>li.home-content-recommendCasino .nav-game li .icon-brand,
  .content-section .home-content-section-with-marginTop>li.home-content-recommendCasino .nav-game li .icon-brand {
    width: 0.3rem;
    height: 0.3rem;
  }

  .content-section .home-content-section>li.home-content-recommend,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend {
    width: 11.6rem;
    margin: 0 auto;
  }

  .content-section .home-content-section>li.home-content-recommend .content-top,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .content-top {
    padding-left: 0.24rem;
  }

  .content-section .home-content-section>li.home-content-recommend .content-center,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .content-center {
    height: 7.7rem;
    padding: 0 0.82rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .recommed-slick-dots .slick-btn,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .recommed-slick-dots .slick-btn {
    width: 0.75rem;
    height: 0.16rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .category-box .category-wrapper,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .category-box .category-wrapper {
    padding: 0 0.6rem;
    font-size: 0.36rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box {
    max-height: unset;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots {
    top: -1.98rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots li,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots li {
    width: 0.75rem;
    height: 0.16rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .subGame-box,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .subGame-box {
    height: 3.03rem;
    padding: 0 0.3rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-img,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-img {
    width: 3.5rem;
    height: 2.29rem;
    border: 0.03rem solid #cfb36b;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail {
    width: 3.5rem;
    margin-left: 0.3rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-title,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-title {
    font-size: 0.36rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider {
    font-size: 0.28rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider>svg,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-provider>svg {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.2rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating {
    margin-bottom: 0.2rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating img,
  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating svg,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating img,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-detail .detail-rating svg {
    width: 0.35rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn>span,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn>span {
    margin-bottom: 0.3rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite {
    font-size: 0.28rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite i,
  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite svg,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite i,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .game-btn .add-favourite svg {
    transform: scale(2);
    margin-right: 0.3rem;
  }

  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .content-title,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .content-title {
    width: 7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content-section .content-recommended {
    margin-bottom: 0 !important;
  }

  .content-section .content-recommended .content-top {
    transform: translateY(0.03rem);
  }

  .content-section .content-recommended .slick-next-btn,
  .content-section .content-recommended .slick-prev-btn {
    display: none !important;
  }

  .content-section .content-recommended .slick-dots {
    top: -1.4rem;
    right: 0;
    display: none !important;
  }

  .content-section .content-recommended .content-center {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 100%;
    display: flex;
    padding-left: 0.3rem;
  }

  .content-section .content-recommended .content-center .slider ul.tab {
    top: -1.4rem;
    right: 0;
  }

  .recommendCasino-box .recommendCasino-title img {
    max-width: 3rem;
  }

  .recommendCasino-box .content-center-box .content-center .nav-game li .evo-game video {
    max-height: 2.46rem;
  }

  .recommendCasino-box .content-center-box .content-center .nav-game li .evo-game .content-text {
    bottom: 0.65rem;
    font-size: 0.22rem;
    height: 0.4rem;
  }

  .recommendCasino-box .content-center-box .content-center .nav-game li .evo-game .game-content {
    height: 0.69rem;
  }
}

@media (max-width: 575px) {
  .content-section .home-content-section>li.home-content-recommend .recommedGame-box .game-box .slick-dots,
  .content-section .home-content-section-with-marginTop>li.home-content-recommend .recommedGame-box .game-box .slick-dots {
    top: -2.65rem;
  }
}

.casino .banner-box .banner ul.sli:nth-child(1) .slide-text-box {
  top: 0.63rem !important;
}

.casino .banner-box .banner ul.sli:nth-child(1) .slide-text-box>p:nth-child(1) {
  font-size: 0.7rem !important;
}

.casino .banner-box .banner ul.sli:nth-child(1) .slide-text-box>p:nth-child(2) {
  font-size: 0.5rem !important;
  line-height: 0.5rem !important;
}

.casino .content-section .casino-content-section>li:not(:last-child) {
  margin-bottom: 0.2rem !important;
}

.casino .content-casino .content-center {
  padding: 0.6rem 0.45rem !important;
  padding-bottom: 0.3rem !important;
}

.casino .content-casino .nav-game {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

.casino .content-casino .nav-game li {
  width: 3.14rem !important;
  height: 2.82rem !important;
  cursor: pointer !important;
  overflow: hidden !important;
  position: relative !important;
  margin-bottom: 0.3rem !important;
}

.casino .content-casino .nav-game li .icon-tag {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}

.casino .content-casino .nav-game li:not(:nth-child(5n)) {
  margin-right: 0 !important;
}

.casino .content-casino .nav-game li:not(:nth-child(4n)) {
  margin-right: 0.3rem !important;
}

.casino .content-casino .nav-game li .hover-box {
  transition: transform .5s !important;
  transform: translateY(100%) !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  cursor: default !important;
  background: #fff !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0.37rem 0 !important;
}

.casino .content-casino .nav-game li .hover-box>span {
  font-size: 0.24rem !important;
  font-weight: bold !important;
  color: #222 !important;
}

.casino .content-casino .nav-game li .hover-box .content-box {
  flex: 1 !important;
}

.casino .content-casino .nav-game li .hover-box .content-box>p {
  font-size: 0.14rem !important;
  color: #222 !important;
  margin: 0.1rem 0 !important;
}

.casino .content-casino .nav-game li .hover-box .content-box .icon-box {
  margin-top: 0.1rem !important;
  height: 0.5rem !important;
}

.casino .content-casino .nav-game li .hover-box .btn-normal,
.casino .content-casino .nav-game li .hover-box .btn-normal-green,
.casino .content-casino .nav-game li .hover-box .btn-long {
  font-size: 0.16rem !important;
}

.casino .content-casino .nav-game li:hover .hover-box {
  transform: translateY(0) !important;
}

.bg-content-section .nav-game li .evo-game {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.bg-content-section .nav-game li .evo-game .game-title {
  font-size: 0.25rem;
  color: #dec276;
  font-weight: bold;
  margin-bottom: 0.16rem;
}

.bg-content-section .nav-game li .evo-game .game-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-content-section .nav-game li .evo-game .game-content .content-text {
  font-size: 0.18rem;
  color: #fff;
  margin-bottom: 0.2rem;
}

.bg-content-section .nav-game li .evo-game .game-content img {
  width: 1.5rem;
  margin-bottom: 0.3rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.bg-content-section .nav-game li .evo-game .btn-normal,
.bg-content-section .nav-game li .evo-game .btn-normal-green,
.bg-content-section .nav-game li .evo-game .btn-long {
  text-transform: uppercase;
}

@media (min-width: 768px)and (max-width: 1025px) {
  .casino .banner-box .banner ul.sli:nth-child(1) .slide-text-box {
    top: 0.75rem !important;
  }

  .casino .banner-box .banner ul.sli:nth-child(1) .slide-text-box>p:nth-child(1) {
    font-size: 0.91rem !important;
  }

  .casino .banner-box .banner ul.sli:nth-child(1) .slide-text-box>p:nth-child(2) {
    font-size: 0.65rem !important;
    line-height: 0.65rem !important;
  }

  .casino .content-casino .content-center {
    padding: 1.66rem 1.66rem !important;
    padding-bottom: 0.83rem !important;
  }

  .casino .content-casino .nav-game {
    width: 15.77rem !important;
  }

  .casino .content-casino .nav-game li {
    width: 4.7rem !important;
    height: 4.23rem !important;
    margin-bottom: 0.83rem !important;
  }

  .casino .content-casino .nav-game li:not(:nth-child(5n)),
  .casino .content-casino .nav-game li:not(:nth-child(4n)) {
    margin-right: 0 !important;
  }

  .casino .content-casino .nav-game li:not(:nth-child(3n)) {
    margin-right: 0.83rem !important;
  }

  .casino .content-casino .nav-game li .hover-box {
    padding: 0.67rem 0 !important;
  }

  .casino .content-casino .nav-game li .hover-box>span {
    font-size: 0.36rem !important;
    font-weight: bold !important;
    color: #000 !important;
  }

  .casino .content-casino .nav-game li .hover-box .content-box>p {
    font-size: 0.21rem !important;
    color: #000 !important;
    margin: 0.21rem 0 !important;
  }

  .casino .content-casino .nav-game li .hover-box .content-box .icon-box {
    margin-top: 0.1rem !important;
    height: 0.5rem !important;
  }

  .casino .content-casino .nav-game li .hover-box .btn-normal,
  .casino .content-casino .nav-game li .hover-box .btn-normal-green,
  .casino .content-casino .nav-game li .hover-box .btn-long {
    font-size: 0.24rem !important;
    width: 2.25rem !important;
    height: 0.53rem !important;
  }

  .bg-content-section .nav-game li .evo-game .game-title {
    font-size: 0.38rem;
  }

  .bg-content-section .nav-game li .evo-game .game-content .content-text {
    font-size: 0.25rem;
  }

  .bg-content-section .nav-game li .evo-game .game-content img {
    width: 2rem;
  }
}

@media (max-width: 768px) {
  #root .casino .bg-footer-bottom {
    top: 5.98rem !important;
  }

  #root .casino.logined .bg-footer-bottom {
    top: 7.27rem !important;
  }

  .casino .banner-box .banner ul.sli:nth-child(1) .slide-text-box {
    top: 0.83rem !important;
  }

  .casino .content-casino {
    padding-top: 0.2rem !important;
  }

  .casino .content-casino .content-center-box {
    padding-top: 1.2rem !important;
  }

  .casino .content-casino .content-center {
    padding: 0 0.35rem !important;
    padding-bottom: 0.75rem !important;
    background: #fff !important;
  }

  .casino .content-casino .nav-game {
    width: 11.71rem !important;
  }

  .casino .content-casino .nav-game li {
    width: 5.65rem !important;
    height: 5.09rem !important;
    margin-bottom: 0.605rem !important;
    border: none !important;
  }

  .casino .content-casino .nav-game li:not(:nth-child(10n)),
  .casino .content-casino .nav-game li:not(:nth-child(5n)),
  .casino .content-casino .nav-game li:not(:nth-child(4n)) {
    margin-right: 0 !important;
  }

  .casino .content-casino .nav-game li:not(:nth-child(2n)) {
    margin-right: 0.4rem !important;
  }

  .casino .content-casino .nav-game li .hover-box {
    height: 101% !important;
    padding: 0.67rem 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .casino .content-casino .nav-game li .hover-box>span {
    font-size: 0.43rem !important;
    font-weight: bold !important;
    color: #000 !important;
  }

  .casino .content-casino .nav-game li .hover-box .content-box>p {
    font-size: 0.25rem !important;
    color: #000 !important;
    margin: 0.21rem 0 !important;
    text-align: center !important;
  }

  .casino .content-casino .nav-game li .hover-box .content-box .icon-box {
    transform: scale(1.5) !important;
    margin-top: 0.4rem !important;
    height: 0.5rem !important;
  }

  .casino .content-casino .nav-game li .hover-box .btn-normal,
  .casino .content-casino .nav-game li .hover-box .btn-normal-green,
  .casino .content-casino .nav-game li .hover-box .btn-long {
    font-size: 0.3rem !important;
    width: 2.7rem !important;
    height: 0.66rem !important;
  }

  .bg-content-section .nav-game li .evo-game .game-title {
    font-size: 0.38rem;
  }

  .bg-content-section .nav-game li .evo-game .game-content .content-text {
    font-size: 0.25rem;
  }

  .bg-content-section .nav-game li .evo-game .game-content img {
    width: 2.2rem;
  }
}

.games {
  background: #182329;
}

.games .content-section .games-content-section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.games .content-section .games-content-section>li {
  width: 100%;
  margin-bottom: 0.2rem;
}

.games .content-section .games-content-section>li .content-center {
  position: relative;
}

@media (max-width: 768px) {
  .games .bg-footer-bottom {
    display: none;
  }

  .games .content-section .games-content-section {
    margin-top: 0;
  }
}

.login-failed {
  margin-bottom: 0.2rem;
  font-size: 0.15rem;
}

.member {
  overflow-y: hidden;
}

.member .popup-footer .btn-normal,
.member .popup-footer .btn-normal-green,
.member .popup-footer .btn-long {
  text-transform: uppercase;
}

.otp-errMsg {
  white-space: pre-line;
}

.member-main {
  font-family: Arial;
  margin: 0 auto;
  width: 12.62rem;
  padding-bottom: 1.53rem;
  padding-left: 0;
  padding-right: 0;
}

.member-main .member-header {
  height: 0.7rem;
  padding-top: 0.5rem;
}

.member-main .auto-transfer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.14rem;
  color: #222;
  padding-right: 0.36rem;
}

.member-main .auto-transfer>span {
  margin-right: 0.14rem;
}

.member-main .auto-transfer>input {
  border: none;
  cursor: pointer;
}

.member-main .main-wallet-content {
  padding-top: 0.07rem;
  display: flex;
  justify-content: space-between;
}

.member-main .main-wallet-content .main-wallet-nav {
  width: 2.25rem;
}

.member-main .main-wallet-content .main-wallet-nav .info-box {
  margin-bottom: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-nav .info-box .member-name-level {
  margin-bottom: 0.05rem;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1) {
  display: flex;
  align-items: flex-end;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1)>div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 0.11rem;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1)>div>span:nth-child(1) {
  font-size: 0.18rem;
  font-family: Arial;
  font-weight: 400;
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1)>div>span:nth-child(2) {
  font-size: 0.18rem;
  font-family: Arial;
  font-weight: bold;
  color: #0a974b;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .line-out {
  width: 2rem;
  height: 0.15rem;
  border: 0.01rem solid #858585;
  border-radius: 1rem;
  margin-top: 0.11rem;
  margin-bottom: 0.06rem;
  font-size: 0.14rem;
  color: #000;
  display: flex;
  position: relative;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .line-out .line-inner {
  height: 100%;
  background: #006eca;
  border-radius: 1rem;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .line-out span {
  position: absolute;
  width: 100%;
  margin: auto;
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .rank-condition {
  font-size: 0.14rem;
  font-weight: 600;
  color: #858585;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .rank-condition span {
  padding: 0 0.1rem;
  color: #fff;
  white-space: nowrap;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .memberReview-date {
  font-size: 0.14rem;
  margin-top: 0.2rem;
  color: gray;
  font-weight: bold;
}

.member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .number {
  font-size: 0.14rem;
  font-family: Arial;
  font-weight: 400;
  color: #0a974b;
}

.member-main .main-wallet-content .main-wallet-nav>div p {
  font-weight: bold;
  font-size: 0.24rem;
  line-height: 0.65rem;
  color: #222;
  font-family: "AvenirNextLTPro-Bold";
}

.member-main .main-wallet-content .main-wallet-nav>div ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0.03rem;
}

.member-main .main-wallet-content .main-wallet-nav>div ul li:hover a,
.member-main .main-wallet-content .main-wallet-nav>div ul li.on a {
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #000;
}

.member-main .main-wallet-content .main-wallet-nav>div ul li .icon-box {
  width: 0.5rem;
  height: 0.4rem;
}

.member-main .main-wallet-content .main-wallet-nav>div ul li a {
  transition: color .3s,background .3s;
  display: flex;
  width: 1.85rem;
  height: 0.4rem;
  align-items: center;
  padding-left: 0.16rem;
  background: #f7f4f8;
  font-size: 0.14rem;
  color: #858585;
  cursor: pointer;
  text-transform: capitalize;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .container-label {
  font-size: 0.14rem;
  font-weight: normal;
  font-family: unset;
  color: #858585;
  line-height: normal;
  margin: 0.4rem 0 0.1rem;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verification-icons-container {
  display: flex;
  justify-content: space-between;
  background: rgba(245,245,245,.92);
  padding: 0.05rem 0.2rem;
  border-radius: 0.2rem;
  width: 80%;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verification-icons-container .icon-wrapper {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verification-icons-container .icon-wrapper .verify-icon {
  width: 0.25rem;
  height: 0.25rem;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container {
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
  margin-top: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field {
  margin: 0;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field .standard-select {
  padding: 0 0.06rem;
  height: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field .standard-select span {
  font-size: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field .standard-select .standard-select-options p {
  font-size: 0.1rem;
  color: unset;
  line-height: normal;
  font-family: unset;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field .standard-select .standard-select-dropdown {
  top: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-button {
  text-transform: uppercase;
  padding: unset;
  font-size: 0.12rem;
}

.member-main .main-wallet-content .main-wallet-main {
  width: 10.18rem;
  padding-top: 0.05rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content {
  position: relative;
  transition: min-height .3s;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content>div {
  padding: 0 0.33rem;
  background: #f7f5f6;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content h1 {
  font-family: "OpenSans-Bold";
  font-size: 0.24rem;
  color: #000;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0.59rem;
  top: -0.4rem;
  font-weight: bold;
  text-transform: capitalize;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert-box {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert {
  width: 4.86rem;
  height: 4.24rem;
  background: #fffeff;
  border-radius: 0.05rem;
  overflow: hidden;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .title {
  background: #09984a;
  height: 0.6rem;
  font-size: 0.24rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .title .icon-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.16rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content {
  width: 3.8rem;
  margin: 0 auto;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content>div {
  font-size: 0.18rem;
  text-align: center;
  color: #0a974b;
  margin-top: 0.47rem;
  margin-bottom: 0.37rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content>p {
  font-size: 0.14rem;
  text-align: center;
  line-height: 0.24rem;
  margin-bottom: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content>p>span {
  color: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content>p .customer {
  text-decoration: underline;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content .btn-close {
  width: 0.98rem;
  height: 0.35rem;
  background: #005329;
  color: #fff;
  border-radius: 0.02rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.16rem;
  margin: 0 auto;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer {
  padding: 0 0.5rem;
  padding-bottom: 0.55rem;
  padding-top: 0.08rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li {
  padding-bottom: 0.16rem;
  font-family: OpenSans;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 {
  padding: 0.45rem 0.09rem;
  padding-bottom: 0.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 select,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 input {
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  width: 2.69rem;
  padding: 0 0.12rem;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 select[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 select:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 input[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 input:disabled {
  background: #e4e4e4;
  border: none;
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 select {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  background: url("../assets/select-arrow.png") no-repeat scroll 2.5rem center #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 select::-ms-expand {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 input {
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 input::-webkit-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 input:-ms-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 input::-moz-placeholder {
  color: #999;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .react-dropdown-select {
  border: 0;
  padding: 0;
  width: 2.69rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .react-dropdown-select-content {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .react-dropdown-select-content span {
  position: absolute;
  bottom: 0.1rem;
  left: 0.15rem;
  color: #999;
  font-size: 0.14rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 0.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .react-dropdown-select-input {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  margin-left: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-transfer {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item {
  margin-bottom: 0.16rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item .top {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item .top .top-title {
  width: 1.3rem;
  font-size: 0.14rem;
  font-weight: 600;
  color: #41b850;
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item .bottom {
  padding-left: 1.3rem;
  padding-top: 0.06rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to {
  margin-left: 0.22rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to .top-title {
  background: none;
  border: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to .top-title:disabled .icon-transfer-btn,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to .top-title[disabled] .icon-transfer-btn {
  opacity: .3;
  cursor: not-allowed;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to .icon-transfer-btn {
  margin-right: 0.22rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top>button {
  font-size: 0.14rem;
  font-weight: 400;
  text-decoration: underline;
  color: #0090ff;
  cursor: pointer;
  margin-left: 0.24rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top>button {
  background: unset;
  border: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top>button[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top>button:disabled {
  color: rgba(179,179,179,.5);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.ta {
  margin-top: 0.43rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.ta input {
  border: 0.01rem solid #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.ta .btn-allin {
  width: 1.66rem;
  height: 0.35rem;
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  border-radius: 0.02rem;
  font-weight: bold;
  color: #147036;
  margin-left: 0.09rem;
  text-transform: uppercase;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box {
  display: flex;
  align-items: center;
  padding-left: 1.3rem;
  margin-top: 0.34rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box .btn-reset {
  width: 1.66rem;
  height: 0.35rem;
  background: #cdcdcd;
  border-radius: 0.02rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  margin-right: 0.1rem;
  text-transform: uppercase;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box .btn-transfer {
  width: 1.66rem;
  height: 0.35rem;
  background: #0a974b;
  border-radius: 0.02rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  border: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box .btn-transfer:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box .btn-transfer[disabled] {
  filter: grayscale(0.6);
  cursor: not-allowed;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box {
  position: absolute;
  right: 0;
  top: 0.05rem;
  width: 3.49rem;
  height: 0.36rem;
  background: #fff;
  border: 0.01rem solid #dadada;
  border-radius: 0.04rem;
  color: #575757;
  display: flex;
  align-items: center;
  padding: 0 0.16rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>span {
  font-size: 0.14rem;
  white-space: nowrap;
  font-family: "OpenSans";
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>div {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>div>span {
  font-size: 0.16rem;
  font-weight: bold;
  margin: 0 0.15rem;
  margin-left: 0.25rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>div .icon-reload2 {
  width: 0.21rem;
  height: 0.23rem;
  cursor: pointer;
  margin-left: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-nav {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-nav li {
  width: 2.55rem;
  height: 0.5rem;
  border-top-right-radius: 0.04rem;
  border-top-left-radius: 0.04rem;
  background: #0a974b;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.18rem;
  font-weight: bold;
  color: hsla(0,0%,100%,.4);
  margin-right: 0.07rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-nav li.on {
  background: #59b766;
  color: #fff;
  pointer-events: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .turnover-item {
  width: 3.49rem;
  height: 0.36rem;
  background: #fff;
  border: 0.01rem solid #dadada;
  border-radius: 0.04rem;
  color: #575757;
  display: flex;
  align-items: center;
  padding: 0 0.16rem;
  margin: auto 0 0.07rem auto;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .turnover-item>span {
  font-size: 0.14rem;
  white-space: nowrap;
  font-family: "OpenSans";
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .turnover-item>span.item-amount {
  font-size: 0.16rem;
  font-weight: bold;
  margin: 0 0.15rem;
  margin-left: 0.25rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content {
  width: 9.18rem;
  height: 100%;
  background: #252525;
  border-radius: 0.06rem;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div {
  width: 100%;
  left: 0;
  top: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.fade-enter-done {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover {
  padding: 0.35rem 0.6rem 0.3rem 0.6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li {
  display: flex;
  flex-direction: column;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>span {
  font-size: 0.18rem;
  color: #fff;
  margin-top: 0.33rem;
  margin-bottom: 0.2rem;
  font-family: "OpenSans";
  font-weight: bold;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.05rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container {
  width: 23%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .item,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .item {
  display: flex;
  align-items: center;
  background: #2d2d2d;
  border-radius: 0.05rem;
  padding: 0.05rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .item .icon-box,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .item .icon-box {
  width: 0.36rem;
  height: 0.35rem;
  margin-right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .item>div,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .item>div {
  font-size: 0.16rem;
  font-family: OpenSans;
  font-weight: 400;
  color: #fff;
  line-height: 0.15rem;
  display: flex;
  flex-direction: column;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .item>div>span:nth-child(1),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .item>div>span:nth-child(1) {
  margin-bottom: 0.03rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .turnover-box,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .turnover-box {
  display: flex;
  align-items: center;
  padding: 0.05rem 0;
  margin-bottom: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .turnover-box span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .turnover-box span {
  margin-left: 0.05rem;
  width: 0.5rem;
  color: #166a31;
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 0.13rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .turnover-box span.turnover-amount,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .turnover-box span.turnover-amount {
  margin-left: 0.14rem;
  color: #0a974b;
  line-height: 0;
  font-weight: unset;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .btn-all-in,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .btn-all-in {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 0.64rem;
  height: 0.27rem;
  padding: 0.06rem;
  border: 0.01rem solid #ba9b43;
  border-radius: 0.05rem;
  color: #ba9b43;
  font-size: 0.16rem;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 0.3rem;
  white-space: nowrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-column-separator,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-column-separator {
  width: 2.65%;
  height: 0.01rem;
  background: rgba(0,0,0,0);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list .provider-category-separator,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list .provider-category-separator {
  background-color: hsla(0,0%,100%,.3);
  height: 0.01rem;
  border: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw {
  padding-top: 0.1rem;
  padding-bottom: 0.2rem;
  font-family: Helvetica;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .btn-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .btn-close svg {
  background: #19723c;
  border-radius: 50%;
  fill: #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li {
  width: 6rem;
  padding-bottom: 0.2rem;
  display: flex;
  flex-direction: column;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:first-child {
  border-bottom: 0.01rem solid #e9e9e9;
  padding: 0 0 0.8rem 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdrawal-ewallet {
  width: 68%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li {
  padding-top: 0.42rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item {
  padding-left: 0.29rem;
  margin-top: 0.2rem;
  display: flex;
  align-items: flex-start;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field .receipt-image-box,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field .receipt-image-box {
  margin-top: .1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field .receipt-image-box img,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field .receipt-image-box img {
  width: 100%;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field .receipt-image-box .pdf-clear,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field .receipt-image-box .pdf-clear {
  margin-left: 0.1rem;
  width: 0.12rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field button,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field button {
  min-width: 1.5rem;
}

@media (max-width: 1025px) {
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field button,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field button {
    min-width: 2.2rem;
  }
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .errormsg,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .errormsg {
  width: 65%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-title,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-title {
  transform: translateY(0.1rem) !important;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item>p,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item>p {
  font-size: 0.14rem;
  color: #41b850;
  min-width: 1.43rem;
  max-width: 1.43rem;
  font-weight: 600;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item>p span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item>p span {
  color: red;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box input {
  font-size: 0.14rem;
  width: 3.5rem;
  height: 0.4rem;
  border: 0.01rem solid #e2e2e2;
  position: relative;
  border-radius: 0.04rem;
  padding: 0 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input:after,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box input:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 1PX solid #e2e2e2;
  border-radius: 0.04rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box input:after {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),only screen and (min-device-pixel-ratio: 3) {
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box input:after {
    width: 300%;
    height: 300%;
    transform: scale(0.333333);
    transform-origin: left top;
  }
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box .withdraw-amount-list,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box .withdraw-amount-list {
  width: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box .withdraw-amount-list .amount-select-btn,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box .withdraw-amount-list .amount-select-btn {
  margin-top: 0.12rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 0.08rem;
  grid-row-gap: 0.08rem;
  width: 88%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box .withdraw-amount-list .amount-select-btn .btn-amount,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box .withdraw-amount-list .amount-select-btn .btn-amount {
  width: 0.8rem;
  height: 0.4rem;
  border-radius: 0.05rem;
  border: 0.01rem solid #707070;
  background: linear-gradient(to top, #e6e6e6, #f4f3f3);
  color: #858585;
  cursor: pointer;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box>p,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box>p {
  display: flex;
  align-items: center;
  color: #41b850;
  font-size: 0.14rem;
  margin: 0.15rem 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box>p .icon-tip,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box>p .icon-tip {
  margin-right: 0.08rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .submit-btn,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.16rem;
  font-family: Arial;
  font-weight: bold;
  color: #fff;
  width: 1.66rem;
  height: 0.35rem;
  background: #0b964e;
  border-radius: 0.02rem;
  border: none;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .submit-btn:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .submit-btn[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .submit-btn:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .submit-btn[disabled] {
  filter: grayscale(0.6);
  cursor: not-allowed;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab {
  display: flex;
  flex-wrap: wrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.12rem;
  max-width: .8rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border: 0.01rem solid #dedcdf;
  border-radius: 0.05rem;
  width: 0.8rem;
  height: 0.5rem;
  margin: auto;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img {
  max-width: 0.35rem;
  max-height: 0.32rem;
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img.crypto,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img.crypto {
  max-width: 0.7rem;
  max-height: unset;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img.active,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img.active {
  filter: grayscale(0);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer div,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer div {
  font-size: 0.14rem;
  font-family: "OpenSans-Bold","sans-serif","SimHei";
  line-height: 0.13rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-options-text,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-options-text {
  font-size: 0.14rem;
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .active .withdraw-banktransfer,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .active .withdraw-banktransfer {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 101% 105%;
  background-size: 0.2rem 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .active .withdraw-options-text,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .active .withdraw-options-text {
  color: #19723c;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img {
  min-width: 0.6rem;
  max-width: 0.6rem;
  min-height: 0.6rem;
  max-height: 0.6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .bank-list .small-bank .banklist-group .bank-name,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .bank-list .small-bank .banklist-group .bank-name {
  font-size: 0.18rem;
  width: 1.8rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field .reminder-note-container,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field .reminder-note-container {
  display: flex;
  align-items: center;
  color: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field .reminder-note-container .icon-alert,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field .reminder-note-container .icon-alert {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.05rem;
  fill: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field .receipt-image-box,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field .receipt-image-box {
  margin-top: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field .receipt-image-box img,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field .receipt-image-box img {
  width: 100%;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item.bank-statement-field .receipt-image-box .pdf-clear,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item.bank-statement-field .receipt-image-box .pdf-clear {
  margin-left: 0.1rem;
  width: 0.12rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul {
  display: flex;
  flex-wrap: wrap;
  width: 4.6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li {
  margin-right: 0.12rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box {
  width: 0.8rem;
  height: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box:after,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 1PX solid #dedcdf;
  border-radius: 0.04rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box:after {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),only screen and (min-device-pixel-ratio: 3) {
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box:after {
    width: 300%;
    height: 300%;
    transform: scale(0.333333);
    transform-origin: left top;
  }
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box img,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box img {
  width: 0.5rem;
  height: 0.4rem;
  overflow: hidden;
  object-fit: contain;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box svg,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box svg {
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box.active,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box.active {
  filter: grayscale(0);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li>span {
  width: 0.8rem !important;
  color: #868387;
  font-size: 0.14rem;
  margin-top: 0.06rem;
  display: block;
  width: 100%;
  text-align: center;
  word-break: break-word;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box {
  position: relative;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box:after,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box:after,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box:after,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2PX solid #368b5f;
  border-radius: 0.04rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box:after {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),only screen and (min-device-pixel-ratio: 3) {
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box:after,
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box:after {
    width: 300%;
    height: 300%;
    transform: scale(0.333333);
    transform-origin: left top;
  }
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box::before,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box::before,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box::before,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background: url("../assets/member/bank_on.png") no-repeat;
  width: 0.22rem;
  height: 0.27rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover>span {
  color: #368b5f;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.other-bank,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.other-bank {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: default;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.other-bank>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.other-bank>span {
  width: auto;
  display: inline-block;
  cursor: pointer;
  font-size: 0.14rem;
  color: #858585;
  text-decoration: underline;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box {
  width: 3rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fffeff;
  border: 0.01rem solid #e2e2e2;
  padding: 0.29rem 0.23rem;
  position: absolute;
  right: 0;
  top: 0.68rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box .title,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box .title {
  margin: 0 auto;
  margin-bottom: 0.24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.14rem;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box .title>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box .title>span {
  margin-left: 0.12rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box>ul,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box>ul {
  font-size: 0.14rem;
  font-family: Arial;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box>ul li,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box>ul li {
  display: flex;
  margin-bottom: 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box>ul li>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box>ul li>span {
  padding-top: 0.01rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box>ul li>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box>ul li>p span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box>ul li>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box>ul li>p span {
  color: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box>ul li>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box>ul li>span {
  margin-right: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(1),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(1) {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(1) .item,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(1) .item {
  width: 6rem;
  margin-right: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(1) .item>p,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(1) .item>p {
  transform: translateY(0.2rem);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(2) .item {
  margin-bottom: 0.27rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item:nth-child(3),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(2) .item:nth-child(3) {
  align-items: flex-start;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item:nth-child(3)>p,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(2) .item:nth-child(3)>p {
  margin-top: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item:last-child,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(2) .item:last-child {
  padding-left: 1.74rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content {
  min-height: 7rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .react-dropdown-select {
  border: 0;
  padding: 0;
  width: 3.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .react-dropdown-select-content {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .react-dropdown-select-content span {
  position: absolute;
  bottom: 0.1rem;
  left: 0.15rem;
  color: #999;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .react-dropdown-select-input {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  margin-left: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content input {
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  width: 3.5rem;
  padding: 0 0.12rem;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content input::-webkit-outer-spin-button,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content input[type=number] {
  -moz-appearance: textfield;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .icon-reload {
  fill: #39b549;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank {
  display: flex;
  width: 50%;
  min-height: 0.4rem;
  padding-bottom: 0.14rem;
  padding-right: 0.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank:nth-child(2) {
  padding-right: 0.25rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .group-box {
  display: flex;
  align-self: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .group-box .bank-img {
  min-width: 0.4rem;
  max-width: 0.4rem;
  min-height: 0.4rem;
  max-height: 0.4rem;
  border: 0.01rem solid #707070;
  background-color: #fff;
  border-radius: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .group-box .bank-img.bank-on {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 107% 107%;
  background-size: 0.14rem 0.14rem;
  filter: grayscale(0%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .group-box .bank-img.bank-on svg {
  filter: grayscale(0%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .group-box .bank-img.bank-off {
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .group-box .bank-img svg {
  width: 0.3rem;
  max-width: 0.3rem;
  height: 0.3rem;
  max-height: 0.3rem;
  opacity: 1;
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .group-box .bank-img img {
  width: auto;
  max-width: 0.3rem;
  height: auto;
  max-height: 0.3rem;
  opacity: 1;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .group-box .bank-name {
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  margin-left: 0.1rem;
  color: #858585;
  width: 1.6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .group-box .bank-selected {
  color: #19763c;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul.remark-content {
  flex-direction: column;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .amount-select-btn {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin: 0.12rem 0 0 29%;
  gap: 0.08rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .amount-select-btn .btn-amount {
  width: 0.8rem;
  height: 0.4rem;
  border-radius: 0.05rem;
  border: 0.01rem solid #707070;
  background: linear-gradient(to top, #e6e6e6, #f4f3f3);
  color: #858585;
  cursor: pointer;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item {
  width: 6rem;
  display: flex;
  align-items: center;
  padding-left: 0.29rem;
  margin-top: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item>p {
  font-size: 0.14rem;
  color: #41b850;
  min-width: 1.43rem;
  max-width: 1.43rem;
  font-weight: 600;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount.currency-amount,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount.withdrawCrypto-submitBox {
  flex-direction: column;
  align-items: flex-start;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount>div {
  width: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount .btn-addnewbank {
  width: 0.3rem;
  height: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.15rem;
  background-color: #005329;
  color: #fff;
  fill: #fff;
  border: 0;
  font-size: 1.14rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount .react-dropdown-select-content span {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount .deposit-amount-input {
  position: relative;
  width: auto;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount .deposit-amount-input input {
  padding: 0 .4rem 0 .12rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.1rem;
  width: .2rem;
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button img {
  max-width: 100%;
  width: auto;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount .invalid-feedback {
  align-self: start;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .btn-withdraw {
  font-family: Arial;
  font-weight: 700;
  color: #fff;
  background: #0b964e;
  border: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info {
  font-size: 0.16rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info .bank-list {
  flex-wrap: unset;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info .bank-list .small-bank {
  flex-direction: column;
  white-space: nowrap;
  padding-bottom: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info .chain-currency-rate-info-container {
  display: unset;
  margin-bottom: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info .chain-currency-rate-info-container .chain-currency-rate-info {
  display: flex;
  flex-flow: column;
  padding-right: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info .chain-currency-rate-info-container .chain-guaranteed-time {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info .chain-currency-rate-info-container .chain-guaranteed-time p {
  padding-right: 0.05rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info .chain-currency-rate-info-container .icon-reload {
  fill: #39b549;
  cursor: pointer;
  width: 0.2rem;
  height: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .small-banklist {
  padding: 0;
  border-bottom: none;
  min-height: unset;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .qrCode li .item {
  align-items: flex-start;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .qrCode li .item .qr-box {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .qrCode li .item .qr-box .box-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .qrCode li .item .qr-box .box-image canvas {
  margin-bottom: 0.2rem;
  max-width: 1.66rem;
  max-height: 1.66rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .qrCode li .item .qr-box .box-image span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.66rem;
  height: 0.35rem;
  background: #cdcdcd;
  border-radius: 0.02rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .qrCode li .item .qr-box .box-info {
  font-size: 0.16rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .qrCode li .item .qr-box .box-info p:nth-child(4) {
  margin-top: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-qrCode p {
  max-width: 1.43rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-qrCode .share-box {
  display: flex;
  justify-content: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-qrCode .share-box .share-column {
  display: flex;
  flex-wrap: wrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-qrCode .share-box .share-column .checkStatus {
  margin-top: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-qrCode .share-box span {
  display: flex;
  align-items: center;
  padding: 0.1rem;
  width: 100%;
  height: 0.35rem;
  background: #0a974b;
  border-radius: 0.02rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  margin-right: 0.05rem;
  max-width: 3.87rem;
  overflow: hidden;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-qrCode .share-box .share-btn {
  background: #cdcdcd;
  border-radius: 0.02rem;
  height: 0.35rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .remark-box {
  background: #fffeff;
  border: 0.01rem solid #e2e2e2;
  padding: 0.29rem 0.23rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .remark-box .remark-title {
  font-size: 0.14rem;
  font-weight: bold;
  margin-bottom: 0.24rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .remark-box .remark-content {
  font-size: 0.14rem;
  font-family: Arial;
  margin-left: 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .remark-box .remark-content li {
  margin-bottom: 0.15rem;
  list-style-type: decimal;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-popup .popup .popup-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-popup .popup .popup-body>:not(:last-child) {
  margin-right: 0.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-popup .popup .popup-body>:nth-child(n) {
  width: 0.64rem;
  height: 0.64rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-popup .popup .popup-body .icon-copy,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .share-popup .popup .popup-body svg {
  width: 100%;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .memberVerify-popup .popup .popup-body>div .popup-field.phone-field {
  display: block;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .memberVerify-popup .popup .popup-body>div .popup-field.phone-field .phone-box {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .memberVerify-popup .popup .popup-body>div .popup-field input {
  width: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .memberVerify-popup .popup .popup-body>div .text-red {
  font-size: 0.11rem;
  margin-bottom: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .memberVerify-popup .popup .popup-body>div .text-red strong {
  font-size: 0.11rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .refill-box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.11rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .refill-box.crypto-address-alert {
  justify-content: flex-start;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .address-popup .popup .popup-body .name-popup .popup-field.otp-field {
  flex-direction: row;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .address-popup .popup .popup-body .name-popup .popup-field.otp-field button {
  width: 1.3rem;
  margin-left: 0.2rem;
  font-size: 0.14rem;
  background-color: #005329;
  color: #fff;
  cursor: pointer;
  border: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .address-popup .popup .popup-body .name-popup .popup-field.otp-field button[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .address-popup .popup .popup-body .name-popup .popup-field.otp-field button:disabled {
  background-color: rgba(0,147,73,.4549019608);
  cursor: not-allowed;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .address-popup .popup .popup-body .name-popup .popup-field .react-dropdown-select {
  width: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .address-popup .popup .popup-body .name-popup .popup-field .react-dropdown-select input {
  width: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile {
  padding: 0 0.27rem;
  padding-bottom: 0.55rem;
  padding-top: 0.35rem;
  font-family: Helvetica;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile select,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile input {
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  width: 2.8rem;
  padding: 0 0.12rem;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile select[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile select:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile input[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile input:disabled {
  background: #e4e4e4;
  border: none;
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile select {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  background: url("../assets/select-arrow.png") no-repeat scroll 2.5rem center #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile select::-ms-expand {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile input {
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile input::-webkit-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile input:-ms-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile input::-moz-placeholder {
  color: #999;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .react-dropdown-select {
  border: 0;
  padding: 0;
  width: 2.8rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .react-dropdown-select-content {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .react-dropdown-select-content span {
  position: absolute;
  bottom: 0.1rem;
  left: 0.15rem;
  color: #999;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .react-dropdown-select-input {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  margin-left: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item {
  background-color: #fff;
  min-width: 33.3%;
  min-height: 2.7rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item:not(:last-child) {
  border-right: 0.05rem solid #f7f5f6;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .user-detail1 .top {
  margin-top: 0.27rem;
  padding-left: 0.4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .user-detail1 .top .top-title {
  width: 1.3rem;
  font-size: 0.14rem;
  font-weight: 600;
  color: #41b850;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0.03rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .user-detail1 .top .bottom {
  width: 2.3rem;
  font-size: 0.14rem;
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .user-detail1 .top .bottom .icon-wrapper {
  width: 0.3rem;
  height: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .user-detail1 .top .bottom .icon-wrapper svg {
  width: 0.3rem;
  height: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .top {
  margin-top: 0.27rem;
  padding-left: 0.4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .top .top-title {
  width: 1.3rem;
  font-size: 0.14rem;
  font-weight: 600;
  color: #41b850;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0.03rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .bank-list {
  margin-top: 0.1rem;
  padding-left: 0.4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .bank-list ul li {
  display: flex;
  margin-bottom: 0.35rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .bank-list ul li .bank-logo {
  width: 0.35rem;
  max-height: 0.35rem;
  height: auto;
  margin-top: -0.05rem;
  object-fit: contain;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .bank-list ul li .bank-acc {
  width: 1.3rem;
  font-size: 0.14rem;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 0.03rem;
  margin-left: 0.15rem;
  line-height: 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .bank-list ul li .bank-acc .bank-accno {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .bank-list ul li .bank-acc .bank-accno .bank-verified {
  width: 0.12rem;
  height: 0.12rem;
  margin-left: 0.05rem;
  font-size: 0.14rem;
  background-color: #74c863;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .bank-list ul li .bank-acc .bank-accno .bank-verified svg {
  stroke-width: 0.01rem;
  width: 0.1rem;
  height: 0.1rem;
  margin-right: 0.01rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile>ul.profile-top>li.profile-item .bank-detail .bank-list ul li .bank-acc .bank-accno span {
  white-space: normal;
  word-break: break-word;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li .form-profile {
  margin-top: 0.4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li select,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li input {
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  width: 2.8rem;
  padding: 0 0.12rem;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li select[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li select:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li input[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li input:disabled {
  background: #e4e4e4;
  border: none;
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li select {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  background: url("../assets/select-arrow.png") no-repeat scroll 2.5rem center #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li select::-ms-expand {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li input {
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li input::-webkit-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li input:-ms-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li input::-moz-placeholder {
  color: #999;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li .form-profile .form-item .top {
  display: flex;
  padding-left: 0.4rem;
  margin-bottom: 0.28rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li .form-profile .form-item .top .top-title {
  width: 1.3rem;
  margin-right: 0.3rem;
  font-size: 0.14rem;
  font-weight: 600;
  color: #41b850;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li .form-profile .form-item .top .top-title .asterisk {
  color: red;
  margin-left: 0.05rem;
  font-size: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile ul.profile-updateform li .form-profile .form-item .top .btn-updateprofile {
  width: 1.66rem;
  height: 0.35rem;
  background: #0a974b;
  border-radius: 0.02rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  border: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail {
  padding: 0.43rem 0.33rem;
  min-height: 7rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .toggle-crypto-popup-button-container {
  margin-top: .6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .inbox {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 0.14rem;
  padding-bottom: 0.03rem;
  border-bottom: 0.01rem solid #dbdbdb;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .inbox .bankDetail-tab {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .inbox span {
  width: 1.5rem;
  height: 0.35rem;
  background-color: #18723c;
  border-top-right-radius: 0.04rem;
  border-top-left-radius: 0.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.04rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .inbox span.tab-active {
  background-color: #39b549;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .more-bankacc {
  padding: 0.09rem 0;
  padding-left: 0.28rem;
  font-size: 0.14rem;
  color: #41b851;
  font-weight: 600;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header {
  display: grid;
  grid-template-columns: 20% 18% 18% 18% 18% 6%;
  padding: 0.139rem 0;
  padding-left: 0.28rem;
  background-color: #cfe8d3;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .bank {
  text-align: left;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .account-no {
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .address {
  min-width: 4rem;
  max-width: 4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .address div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .branch,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .account-name,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .account-no,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .network,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .address,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.bank-header .bank-status {
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist {
  display: grid;
  grid-template-columns: 20% 18% 18% 18% 18% 6%;
  padding: 0.07rem 0;
  padding-left: 0.28rem;
  border-bottom: 0.01rem solid #dcdcdc;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .bank {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .bank img,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .bank svg {
  margin-left: -0.05rem;
  min-width: 0.2rem;
  width: 0.35rem;
  height: auto;
  max-height: 0.4rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .bank div {
  margin-left: 0.2rem;
  width: 1.2rem;
  overflow-wrap: break-word;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .address {
  min-width: 4rem;
  max-width: 4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .address div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .branch,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .account-name,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .account-no,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .network,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .address,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .bank-status {
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .account-no {
  color: #0a974b;
  overflow-wrap: break-word;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .branch {
  overflow-wrap: break-word;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .bank-status.verified {
  color: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .bank-status.pending {
  color: #757575;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .bank-status.rejected {
  color: red;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .delete .dont-show {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .delete .show {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .delete .delete-icon {
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .delete .delete-icon img,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .delete .delete-icon svg {
  width: 0.22rem;
  height: 0.25rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .delete .delete-bank .btn-cancel {
  font-size: 0.14rem;
  width: 0.73rem;
  height: 0.37rem;
  background-color: #aeaeae;
  color: #fff;
  margin-right: 0.05rem;
  border: 0;
  border-radius: 0.05rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .delete .delete-bank .btn-delete {
  font-size: 0.14rem;
  width: 0.73rem;
  height: 0.37rem;
  background-color: red;
  color: #fff;
  border: 0;
  border-radius: 0.05rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.crypto-member-banklist {
  grid-template-columns: 20% 18% 50% 10%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li {
  color: #005329;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li.bank {
  text-align: left;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li.delete {
  display: flex;
  justify-content: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank {
  margin-top: 0.5rem;
  padding-left: 0.4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank select,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank input {
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  width: 3rem;
  padding: 0 0.12rem;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank select[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank select:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank input[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank input:disabled {
  background: #e4e4e4;
  border: none;
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank select {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  background: url("../assets/select-arrow.png") no-repeat scroll 2.5rem center #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank select::-ms-expand {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank input {
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank input::-webkit-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank input:-ms-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank input::-moz-placeholder {
  color: #999;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .react-dropdown-select {
  border: 0;
  padding: 0;
  width: 3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .react-dropdown-select-content {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .react-dropdown-select-content span {
  position: absolute;
  bottom: 0.1rem;
  left: 0.15rem;
  color: #999;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .react-dropdown-select-input {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  margin-left: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item {
  margin-top: 0.25rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.item-otp input {
  width: 1.6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.item-otp input::-webkit-outer-spin-button,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.item-otp input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.item-otp input[type=number] {
  -moz-appearance: textfield;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.item-otp button {
  font-size: 0.14rem;
  width: 1.2rem;
  margin-left: 0.2rem;
  background-color: #005329;
  color: #fff;
  cursor: pointer;
  border: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.item-otp button[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.item-otp button:disabled {
  background-color: rgba(0,147,73,.4549019608);
  cursor: not-allowed;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement .upload-receipt-container button {
  min-width: 1.5rem;
}

@media (max-width: 1025px) {
  .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement .upload-receipt-container button {
    min-width: 2.2rem;
  }
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement-field {
  margin-top: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement-field .reminder-note-container {
  display: flex;
  align-items: center;
  margin-top: 0.05rem;
  padding-bottom: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement-field .reminder-note-container .icon-tip {
  margin-right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement-field .reminder-note-container .reminder-note {
  color: #41b850;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement-field .receipt-image-box {
  margin-top: .1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement-field .receipt-image-box img {
  width: 100%;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement-field .receipt-image-box .pdf-clear {
  margin-left: 0.1rem;
  width: 0.12rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top .top-title {
  display: flex;
  align-items: center;
  min-width: 1.5rem;
  font-size: 0.14rem;
  font-weight: 600;
  color: #41b850;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top .top-title .asterisk {
  color: red;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top .btn-addbank {
  width: 1.66rem;
  height: 0.35rem;
  background: #0a974b;
  border-radius: 0.02rem;
  margin-top: 0.15rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  border: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top .upload-receipt-container .reminder-note-container {
  display: flex;
  align-items: center;
  color: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top .upload-receipt-container .reminder-note-container .icon-alert {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.05rem;
  fill: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top .upload-receipt-container .receipt-image-box {
  margin-top: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top .upload-receipt-container .receipt-image-box img {
  width: 100%;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top .upload-receipt-container .receipt-image-box .pdf-clear {
  margin-left: 0.1rem;
  width: 0.12rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword {
  min-height: 7rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass {
  padding-left: 0.3rem;
  padding-top: 0.42rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item {
  margin-bottom: 0.275rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item select,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item input {
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  width: 3rem;
  padding: 0 0.12rem;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item select[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item select:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item input[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item input:disabled {
  background: #e4e4e4;
  border: none;
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item select {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  background: url("../assets/select-arrow.png") no-repeat scroll 2.5rem center #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item select::-ms-expand {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item input {
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item input::-webkit-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item input:-ms-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item input::-moz-placeholder {
  color: #999;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select {
  border: 0;
  padding: 0;
  width: 3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select:focus {
  box-shadow: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select-dropdown {
  width: 3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select-content {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select-content span {
  position: absolute;
  bottom: 0.1rem;
  left: 0.15rem;
  color: #999;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select-item:hover {
  background-color: #f2f2f2;
  color: #999;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select-item-selected {
  background-color: #fff;
  color: #41b850;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select-input {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  margin-left: 0;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .react-dropdown-select-input:focus {
  border: 0.02rem solid #41b850;
  box-shadow: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .top-title {
  width: 1.5rem;
  margin-right: 0.3rem;
  font-size: 0.14rem;
  font-weight: 600;
  color: #41b850;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .top-title .asterisk {
  color: red;
  margin-left: 0.04rem;
  font-size: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .top-text {
  font-size: 0.14rem;
  width: 5rem;
  margin-left: 0.05rem;
  text-align: justify;
  color: #41b850;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .btn-changepass {
  width: 1.66rem;
  height: 0.35rem;
  background: #0a974b;
  border-radius: 0.02rem;
  margin-top: 0.22rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  border: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .btn-changepass:disabled {
  background: #959595;
  filter: unset;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .top-input {
  width: 70%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top div:not(.react-dropdown-select-dropdown,.react-dropdown-select-dropdown-handle) {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top>div {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top>div .standard-password-eye {
  width: 0.3rem;
  position: absolute;
  top: 0.05rem;
  left: 2.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top>div .change-password-form-error-message-status-img {
  width: 0.14rem;
  height: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .invalid-feedback {
  grid-column: 2;
  margin: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .input-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .input-box .icon-box {
  width: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .input-box .icon-box .icon-copy {
  width: 0.3rem;
  height: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging {
  padding-top: 0.37rem;
  min-height: 7rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 0.14rem;
  padding-bottom: 0.03rem;
  border-bottom: 0.01rem solid #dbdbdb;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox .msg-tab {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox span {
  width: 1.3rem;
  height: 0.35rem;
  background-color: #18723c;
  border-top-right-radius: 0.04rem;
  border-top-left-radius: 0.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.04rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox span:last-child {
  margin-right: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox span.tab-active {
  background-color: #39b549;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox .readAll-msg {
  display: flex;
  align-items: center;
  color: #18723c;
  opacity: .5;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox .readAll-msg svg {
  width: 0.3rem;
  fill: #18723c;
  margin-right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox .readAll-msg:hover {
  opacity: 1;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.14rem;
  color: #005329;
  font-weight: 600;
  padding: 0.145rem 0.12rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction .msgChkbox {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction .msgChkbox input {
  min-width: 0.2rem;
  height: 0.2rem;
  padding: 0;
  background-color: #fff;
  border: 0.01rem solid #a1a1a1;
  margin-right: 0.1rem;
  border-radius: 0.05rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction .msgChkbox input[type=checkbox]:checked:after {
  background-image: url("/public/html/images/common_img/tick-icon.svg");
  color: #29c04a;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction .msgChkbox input[type=checkbox]:after {
  content: "";
  color: #fff;
  display: flex;
  align-items: center;
  margin: auto;
  width: 0.12rem;
  height: 0.16rem;
  background-repeat: no-repeat;
  background-position: center 0.03rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction .msgDelete_Read {
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction .msgDelete_Read>p {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction .msgDelete_Read .readAll-msg {
  margin-right: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction .msgDelete_Read .readAll-msg svg {
  fill: gray;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction .msgDelete_Read svg {
  width: 0.25rem;
  height: 0.25rem;
  margin-right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .no-msg-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.01rem;
  padding: 0.145rem 0.12rem;
  font-size: 0.14rem;
  background-color: #cfe8d3;
  color: #005329;
  font-weight: 600;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item .custom-checkbox,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item .custom-checkbox {
  opacity: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item.msgChkbox,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgChkbox {
  width: 5%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item.msgChkbox .top-title,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgChkbox .top-title {
  display: flex;
  align-items: center;
  width: 1rem;
  line-height: 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item.msgChkbox input,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgChkbox input {
  width: 0.2rem;
  height: 0.2rem;
  padding: 0;
  background-color: #fff;
  border: 0.01rem solid #a1a1a1;
  margin-right: 0.1rem;
  border-radius: 0.05rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item.msgChkbox input[type=checkbox]:checked:after,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgChkbox input[type=checkbox]:checked:after {
  background-image: url("/public/html/images/common_img/tick-icon.svg");
  color: #29c04a;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item.msgChkbox input[type=checkbox]:after,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgChkbox input[type=checkbox]:after {
  content: "";
  color: #fff;
  display: flex;
  align-items: center;
  margin: auto;
  width: 0.12rem;
  height: 0.16rem;
  background-repeat: no-repeat;
  background-position: center 0.03rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item.msgDate,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDate {
  width: 15%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item.msgSubject,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgSubject {
  width: 60%;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item.msgRemark,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark {
  width: 20%;
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row {
  background-color: rgba(0,0,0,0);
  border-bottom: 0.01rem solid #dedede;
  padding: 0 0.12rem;
  color: #000;
  min-height: 0.6rem;
  display: flex;
  align-items: center;
  margin-top: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item {
  font-size: 0.14rem;
  overflow: hidden;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgSubject {
  color: #0a974b;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgSubject .top .top-title {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgSubject .top .top-title.msg-read {
  opacity: .5;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .flag-delete {
  display: block;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .flag-delete .msg-flagged,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .flag-delete .msg-unflag {
  opacity: 1;
  cursor: pointer;
  width: 0.2rem;
  height: 0.25rem;
  fill: gray;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .flag-delete .msg-unflag {
  opacity: .3;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .flag-delete .msg-delete {
  cursor: pointer;
  width: 0.22rem;
  height: 0.25rem;
  margin-left: 0.27rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .msg-cancel-delete .btn-cancel {
  width: 0.7rem;
  height: 0.4rem;
  background-color: #aeaeae;
  color: #fff;
  margin-right: 0.05rem;
  border: 0;
  border-radius: 0.05rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .msg-cancel-delete .btn-delete {
  width: 0.7rem;
  height: 0.4rem;
  background-color: red;
  color: #fff;
  border: 0;
  border-radius: 0.05rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .show {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .hide {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title .flag-delete {
  display: block;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title .flag-delete .msg-flagged,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title .flag-delete .msg-unflag {
  opacity: 1;
  cursor: pointer;
  width: 0.2rem;
  height: 0.25rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title .flag-delete .msg-unflag {
  opacity: .3;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title .flag-delete .msg-delete {
  cursor: pointer;
  width: 0.22rem;
  height: 0.25rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title .msg-cancel-delete .btn-cancel {
  width: 0.7rem;
  height: 0.4rem;
  background-color: #aeaeae;
  color: #fff;
  margin-right: 0.05rem;
  border: 0;
  border-radius: 0.05rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title .msg-cancel-delete .btn-delete {
  width: 0.7rem;
  height: 0.4rem;
  background-color: red;
  color: #fff;
  border: 0;
  border-radius: 0.05rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title .show {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgDelete .top .top-title .hide {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history {
  min-height: 7rem;
  padding-top: 0.7rem;
  padding-left: 0.4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history select,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history input {
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  width: 1.3rem;
  padding: 0 0.12rem;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history select[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history select:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history input[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history input:disabled {
  background: #e4e4e4;
  border: none;
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history select {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  background: url("../assets/select-arrow.png") no-repeat scroll 2.5rem center #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history select::-ms-expand {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history input {
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history input::-webkit-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history input:-ms-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history input::-moz-placeholder {
  color: #999;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select {
  border: 0;
  padding: 0;
  width: 1.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select:focus {
  box-shadow: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select-dropdown {
  width: 1.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select-content {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select-content span {
  position: absolute;
  bottom: 0.1rem;
  left: 0.15rem;
  color: #999;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select-item:hover {
  background-color: #f2f2f2;
  color: #999;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select-item-selected {
  background-color: #fff;
  color: #41b850;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select-input {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  margin-left: 0;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .react-dropdown-select-input:focus {
  border: 0.02rem solid #41b850;
  box-shadow: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formrow {
  padding: 0 0.22rem;
  min-height: 0.52rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.01rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formrow .history-label {
  color: #59c066;
  min-width: 1.5rem;
  font-size: 0.14rem;
  white-space: nowrap;
  font-weight: 600;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formrow .history-label .asterisk {
  color: red;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-type,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-time,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history history-formpage {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-type ul.history-tab,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-time ul.history-tab,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history history-formpage ul.history-tab {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-type ul.history-tab .history-tab-click a,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-time ul.history-tab .history-tab-click a,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history history-formpage ul.history-tab .history-tab-click a {
  min-width: 0.6rem;
  margin-right: 0.05rem;
  padding: 0.05rem 0.185rem;
  border-radius: 0.05rem;
  font-size: 0.14rem;
  cursor: pointer;
  white-space: nowrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-type ul.history-tab .history-tab-click .active,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-time ul.history-tab .history-tab-click .active,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history history-formpage ul.history-tab .history-tab-click .active {
  background-color: #005329;
  color: #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-type ul.history-tab .history-tab-click:nth-child(2),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-time ul.history-tab .history-tab-click:nth-child(2),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history history-formpage ul.history-tab .history-tab-click:nth-child(2) {
  margin-left: 0.04rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-type ul.history-tab .history-tab-click:nth-child(3),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-time ul.history-tab .history-tab-click:nth-child(3),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history history-formpage ul.history-tab .history-tab-click:nth-child(3) {
  margin-left: -0.02rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-type ul.history-tab .history-time-click:nth-child(2),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-time ul.history-tab .history-time-click:nth-child(2),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history history-formpage ul.history-tab .history-time-click:nth-child(2) {
  margin-left: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-type ul.history-tab .history-time-click:nth-child(4),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .trans-time ul.history-tab .history-time-click:nth-child(4),
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history history-formpage ul.history-tab .history-time-click:nth-child(4) {
  margin-left: -0.06rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formpage ul li .form-history {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formpage ul li .form-history .form-item.mobile-history-transtype {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formpage ul li .form-history .form-item .btn-search {
  border: 0;
  border-radius: 0.03rem;
  background: #0a974b;
  color: #fff;
  text-transform: capitalize;
  margin-left: 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formpage ul li .form-history .form-item .top {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formpage ul li .form-history .form-item .top .top-title {
  min-width: 1.35rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formpage ul li .form-history .form-item .top .top-title .history-label {
  color: #59c066;
  font-size: 0.14rem;
  min-width: 1.35rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formpage ul li .form-history .form-item .top .top-title .asterisk {
  color: red;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formpage ul li .form-history .form-item .top .to-label {
  max-width: 0.7rem;
  text-transform: lowercase;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formpage ul li .form-history .form-item .top .to-label .history-label {
  min-width: 0.45rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container {
  margin: 0.4rem 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details {
  display: flex;
  align-items: center;
  background-color: #cfe8d3;
  padding: 0.145rem 0.18rem 0.145rem 0.18rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.14rem;
  color: #005329;
  font-weight: 600;
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-gameProvider,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-gameProvider {
  width: 15%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-betCount,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-betCount {
  width: 15%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-betAmount,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-betAmount {
  width: 15%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-validBetAmount,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-validBetAmount {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-bonusTurnover,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-bonusTurnover {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-turnoverWithoutBonus,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-turnoverWithoutBonus {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-totalBonus,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-totalBonus {
  width: 10%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-totalRebate,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-totalRebate {
  width: 10%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-date,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-date {
  width: 23%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-amount,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-amount {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-from,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-from {
  width: 12%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-to,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-to {
  width: 15%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-type,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-type {
  width: 15%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-status,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-status {
  width: 10%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div li.history-remark,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-remark {
  width: 13%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-withdepo .history-date,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-withdepo .history-date {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-withdepo .history-amount,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-withdepo .history-amount {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-withdepo .history-method,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-withdepo .history-method {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-withdepo .history-type,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-withdepo .history-type {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-withdepo .history-status,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-withdepo .history-status {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-promotion .history-date,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-promotion .history-date {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-promotion .history-promo,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-promotion .history-promo {
  width: 25%;
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-promotion .history-target,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-promotion .history-target {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-promotion .history-status,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-promotion .history-status {
  width: 15%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-rewards .history-date,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-rewards .history-date {
  width: 25%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-rewards .history-reward,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-rewards .history-reward {
  width: 25%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-rewards .history-amount,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-rewards .history-amount {
  width: 25%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-rebate .history-turnover,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-rebate .history-turnover {
  width: 20%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-rebate .history-provider,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-rebate .history-provider {
  width: 45%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-freeSpinHistory li,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-freeSpinHistory li {
  width: 12.25%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-title-div.history-freeSpinHistory li.history-provider,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details.history-freeSpinHistory li.history-provider {
  white-space: nowrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details {
  background-color: rgba(0,0,0,0);
  border-bottom: 0.01rem solid #dcdcdc;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li {
  color: #000;
  font-weight: normal;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-amount {
  color: #409567;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header ul.history-details li.history-popup {
  color: #409567;
  cursor: pointer;
  text-decoration: underline;
  width: 45%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .rebate-details ul {
  display: flex;
  padding: 0.5rem 0.3rem;
  font-size: 0.15rem;
  justify-content: space-between;
  border-bottom: 0.01rem solid #e9e9e9;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .rebate-details ul .claim-processing {
  display: grid;
  text-align: end;
  color: red;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .rebate-details li {
  display: grid;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .rebate-details .rebate-details-title {
  font-weight: 600;
  color: #59c066;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .provider-table {
  padding: 0 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .provider-table ul {
  display: flex;
  font-size: 0.15rem;
  justify-content: space-between;
  margin: 0 0 0 -0.2rem;
  width: 105%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .provider-table li {
  width: 1.7rem;
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .provider-table .provider-table-header {
  font-weight: bold;
  color: #18723c;
  font-size: 0.19rem;
  padding: 0.4rem 0 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .provider-table .provider-table-list {
  margin: 0.1rem 0 0 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .provider-table .provider-table-list ul {
  background-color: #cfe8d3;
  padding: 0.12rem 0;
  width: 105%;
  color: #18723c;
  font-weight: bold;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .provider-table .provider-table-data ul {
  color: #000;
  border-bottom: 0.01rem solid #e9e9e9;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .provider-table .provider-table-data li {
  margin: 0.2rem 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .search-btn {
  border: 0;
  border-radius: 0.03rem;
  background: #0a974b;
  color: #fff;
  text-transform: capitalize;
  margin-left: 0.15rem;
  width: 1.5rem;
  height: 0.35rem;
  font-size: 0.16rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .rebate .search-btn:disabled {
  cursor: not-allowed;
  background: #959595;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit {
  min-height: 7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  font-family: Helvetica;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit select,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit input {
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  width: 3.5rem;
  padding: 0 0.12rem;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit select[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit select:disabled,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit input[disabled],
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit input:disabled {
  background: #e4e4e4;
  border: none;
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit select {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  background: url("../assets/select-arrow.png") no-repeat scroll 2.5rem center #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit select::-ms-expand {
  display: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit input {
  color: #1c2c34;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit input::-webkit-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit input:-ms-input-placeholder,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit input::-moz-placeholder {
  color: #999;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .react-dropdown-select {
  border: 0;
  padding: 0;
  width: 3.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .react-dropdown-select-content {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .react-dropdown-select-content span {
  position: absolute;
  bottom: 0.1rem;
  left: 0.15rem;
  color: #999;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .react-dropdown-select-input {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  margin-left: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul.small-banklist {
  min-height: 1.7rem;
  border-bottom: 0.01rem solid rgba(0,0,0,.1);
  padding: 0.2rem 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item {
  width: 6rem;
  display: flex;
  padding-left: 0.29rem;
  margin-top: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.note {
  margin: -0.2rem 0 0 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.bank-qrcode-container {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  border-top: solid 0.01rem #dbdbdb;
  padding-top: 0.35rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.bank-qrcode-container .frame {
  width: 1.8rem;
  height: 1.8rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.bank-qrcode-container .frame img {
  width: 1.6rem;
  height: 1.6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.bank-qrcode-container .guide {
  max-width: 3.55rem;
  font-size: 0.14rem;
  display: flex;
  flex-direction: column;
  gap: 0.12rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.bank-qrcode-container .guide h5 {
  font-size: 0.16rem;
  font-weight: 700;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.bank-qrcode-container .guide ul h6 {
  font-size: 0.14rem;
  font-weight: 700;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.bank-qrcode-container .guide ul li {
  list-style-type: decimal;
  margin-left: 0.16rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.onlinetransfer-bankacc {
  align-items: flex-start;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.amount-select {
  margin-top: 0.26rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.deposit-datetime {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.select-bank {
  padding-top: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.select-bank .important-notice-container {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.select-bank .important-notice-container svg {
  width: 0.3rem;
  height: 0.3rem;
  margin: 0.05rem 0 0 0.1rem;
  fill: #c5d2d8;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.select-bank .important-notice-box {
  position: absolute;
  width: auto;
  left: 59%;
  padding: 0.1rem;
  background: #fff;
  color: #000;
  border-radius: 0.1rem;
  z-index: 1;
  box-shadow: 0.02rem 0.03rem 0.05rem #9b9b9b;
  display: flex;
  word-break: break-word;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.button-row {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.button-row .online-transfer-button {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.button-row .online-transfer-button .btn-receipt {
  text-transform: capitalize;
  font-weight: bold;
  color: #147036;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item.button-row .online-transfer-button .btn-deposit {
  text-transform: capitalize;
  font-weight: bold;
  background: #0a974b;
  color: #fff;
  margin-left: 0.1rem;
  border: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item>p {
  font-size: 0.14rem;
  color: #41b850;
  min-width: 1.43rem;
  max-width: 1.43rem;
  font-weight: 600;
  text-transform: capitalize;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .select-member-bank {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .select-member-bank .btn-addnewbank {
  width: 0.3rem;
  height: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.15rem;
  background-color: #005329;
  color: #fff;
  fill: #fff;
  border: 0;
  font-size: 1.14rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul {
  display: flex;
  flex-wrap: wrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.qr-deposit-channel-tab,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.p2p-deposit-channel-tab,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bqr-deposit-channel-tab {
  padding: 0 0 0.4rem 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank {
  display: flex;
  width: 50%;
  min-height: 0.4rem;
  padding-bottom: 0.14rem;
  padding-right: 0.38rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank.bank-acc-list {
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank.bank-acc-list .member-bankacc label {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank.bank-acc-list .member-bankacc .no-Data {
  white-space: nowrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .group-box {
  display: flex;
  align-self: center;
  justify-content: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img {
  min-width: 0.4rem;
  max-width: 0.4rem;
  min-height: 0.4rem;
  max-height: 0.4rem;
  border: 0.01rem solid #707070;
  background-color: #fff;
  border-radius: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img .bank-on {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 107% 107%;
  background-size: 0.14rem 0.14rem;
  filter: grayscale(0%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img .bank-on svg {
  filter: grayscale(0%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img .bank-off {
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img svg {
  width: 0.3rem;
  max-width: 0.3rem;
  height: 0.3rem;
  max-height: 0.3rem;
  opacity: 1;
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img img {
  width: auto;
  max-width: 0.3rem;
  height: auto;
  max-height: 0.3rem;
  opacity: 1;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img .bank-active {
  position: absolute;
  content: "";
  background-color: #10ff00;
  border-radius: 50%;
  width: 0.07rem;
  height: 0.07rem;
  right: 0;
  z-index: 89;
  top: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img .bank-maintenance {
  position: absolute;
  content: "";
  background-color: red;
  border-radius: 50%;
  width: 0.07rem;
  height: 0.07rem;
  left: 0.31rem;
  z-index: 89;
  top: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img>div {
  line-height: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img.bank-on {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 107% 107%;
  background-size: 0.14rem 0.14rem;
  filter: grayscale(0%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img.bank-on svg {
  filter: grayscale(0%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img svg {
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-img.bank-off {
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-name {
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  margin-left: 0.1rem;
  color: #858585;
  width: 1.6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-time {
  color: #858585;
  display: grid;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .banklist-group .bank-selected {
  color: #19763c;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .radio-form {
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .radio-form .account-holder {
  background-color: #19723c;
  color: #fff;
  padding: 0 0.05rem;
  margin-bottom: 0.02rem;
  min-width: 3rem;
  min-height: 0.3rem;
  font-size: 0.14rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.04rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .radio-form .account-holder:nth-child(2) {
  margin-bottom: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .radio-form .account-holder .icon-copy {
  height: 0.15rem;
  width: 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
  max-width: 0.8rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border: 0.01rem solid #dedcdf;
  border-radius: 0.05rem;
  width: 0.8rem;
  height: 0.6rem;
  margin: auto;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer .truepay svg,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer .truepay2 svg {
  max-height: unset;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer svg {
  width: 0.6rem;
  max-width: 0.6rem;
  max-height: 0.45rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer svg.ezpay {
  max-width: 0.6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer svg.h2pay {
  max-width: 0.44rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer svg.fpy {
  max-height: 0.44rem;
  max-width: 0.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer svg.crypto {
  max-width: 0.7rem;
  max-height: unset;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer img {
  max-width: 0.6rem;
  max-height: 0.4rem;
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer img.ezpay {
  max-width: 0.6rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer img.h2pay {
  max-width: 0.44rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer img.fpy {
  max-height: 0.44rem;
  max-width: 0.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer img.crypto {
  max-width: 0.7rem;
  max-height: unset;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer img.active {
  filter: grayscale(0%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer div {
  font-size: 0.14rem;
  font-family: "OpenSans-Bold","sans-serif","SimHei";
  line-height: 0.13rem;
  filter: grayscale(100%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .depositLink .deposit-banktransfer div.active {
  filter: grayscale(0%);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .active .deposit-banktransfer {
  background-image: url(/public/html/images/bank_img/096-bank-on.png);
  background-repeat: no-repeat;
  border: 0.02rem solid #0a974b;
  background-position: 101% 105%;
  background-size: 0.2rem 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul li.deposit-tab-click .active .deposit-options-text {
  color: #19723c;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul .deposit-options-text {
  font-size: 0.14rem;
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .amount-select-btn {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .amount-select-btn .btn-amount {
  width: 0.8rem;
  height: 0.4rem;
  border-radius: 0.05rem;
  border: 0.01rem solid #707070;
  background: linear-gradient(to top, #e6e6e6, #f4f3f3);
  color: #858585;
  margin-right: 0.12rem;
  cursor: pointer;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-qrpay-button,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-p2p-button,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-crypto-button,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-quickpay-button {
  padding-top: 0.41rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-qrpay-button .btn-deposit,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-p2p-button .btn-deposit,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-crypto-button .btn-deposit,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-quickpay-button .btn-deposit {
  background: #0a974b;
  color: #fff;
  border: 0;
  border-radius: 0.03rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-amount {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-amount .deposit-amount-input {
  position: relative;
  width: auto;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-amount .deposit-amount-input input {
  padding: 0 .4rem 0 .12rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: .1rem;
  width: .25rem;
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-amount .deposit-amount-input .deposit-amount-clear-button img {
  max-width: 100%;
  width: auto;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-amount .invalid-feedback {
  align-self: start;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .app-onlinetransfer {
  min-height: 8rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .small-banklist {
  padding: 0;
  border-bottom: none;
  min-height: unset;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item {
  align-items: flex-start;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-image canvas {
  margin-bottom: 0.2rem;
  max-width: 1.66rem;
  max-height: 1.66rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-image span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.66rem;
  height: 0.35rem;
  background: #cdcdcd;
  border-radius: 0.02rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-info {
  font-size: 0.16rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-info p:nth-child(4) {
  margin-top: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-qrCode p {
  max-width: 1.43rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-qrCode .share-box {
  display: flex;
  justify-content: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-qrCode .share-box .share-column {
  display: flex;
  flex-wrap: wrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-qrCode .share-box .share-column .checkStatus {
  margin-top: 0.3rem;
  border: none;
  padding: 0.2rem 0.8rem;
  text-transform: capitalize;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-qrCode .share-box span {
  display: flex;
  align-items: center;
  padding: 0.1rem;
  width: 100%;
  height: 0.35rem;
  background: #0a974b;
  border-radius: 0.02rem;
  font-size: 0.16rem;
  font-weight: bold;
  color: #fff;
  margin-right: 0.05rem;
  max-width: 3.87rem;
  overflow: hidden;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-qrCode .share-box .share-btn {
  background: #cdcdcd;
  border-radius: 0.02rem;
  height: 0.35rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .remark-box {
  background: #fffeff;
  border: 0.01rem solid #e2e2e2;
  padding: 0.29rem 0.23rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .remark-box .remark-title {
  font-size: 0.14rem;
  font-weight: bold;
  margin-bottom: 0.24rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .remark-box .remark-content {
  font-size: 0.14rem;
  font-family: Arial;
  margin-left: 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .remark-box .remark-content li {
  margin-bottom: 0.15rem;
  list-style-type: decimal;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-popup .popup .popup-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-popup .popup .popup-body>:not(:last-child) {
  margin-right: 0.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-popup .popup .popup-body>:nth-child(n) {
  width: 0.64rem;
  height: 0.64rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-popup .popup .popup-body .icon-copy,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-popup .popup .popup-body svg {
  width: 100%;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .memberVerify-popup .popup .popup-body>div .popup-field.phone-field {
  display: block;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .memberVerify-popup .popup .popup-body>div .popup-field.phone-field .phone-box {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .memberVerify-popup .popup .popup-body>div .popup-field input {
  width: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .memberVerify-popup .popup .popup-body>div .text-red {
  font-size: 0.11rem;
  margin-bottom: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .memberVerify-popup .popup .popup-body>div .text-red strong {
  font-size: 0.11rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .refill-box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .important-notice {
  color: #41b850;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option {
  display: flex;
  border-bottom: 0.01rem solid #e2e2e2;
  justify-content: space-between;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .payment-option-container {
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .payment-option-container .payment-option {
  height: 0.35rem;
  background: #18723c;
  border-top-right-radius: 0.04rem;
  border-top-left-radius: 0.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.04rem 0.02rem;
  cursor: pointer;
  color: #fff;
  font-size: 0.14rem;
  padding: 0.05rem;
  width: 2.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .payment-option-container .payment-option.active {
  background: #39b549;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .payment-option-container .payment-option svg {
  width: 15%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .bank-offline-container {
  margin: 0 0.3rem 0 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .bank-offline-container .bankoff-btn {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .bank-offline-container .bankoff-btn .img-container {
  margin: 0 0.1rem 0 0;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .bank-offline-container .bankoff-btn .img-container img {
  height: 0.35rem;
  width: 0.4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .bank-offline-container .bankoff-btn .img-container .red-dot {
  background: red;
  border-radius: 50%;
  width: 0.18rem;
  height: 0.18rem;
  bottom: 50%;
  left: 70%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .bank-offline-container .bankoff-btn span {
  color: #18723c;
  font-size: 0.14rem;
  font-weight: bold;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .floating-bank-offline-container {
  background: #fff;
  border-radius: 0.05rem;
  box-shadow: 0 0.02rem 0.08rem rgba(0,0,0,.3);
  padding: 0.15rem 0.3rem;
  position: absolute;
  right: 0;
  top: 120%;
  z-index: 1;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .floating-bank-offline-container .bank-container {
  align-items: center;
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .floating-bank-offline-container .bank-container:not(:last-child) {
  padding: 0 0 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .floating-bank-offline-container .bank-container .img-container {
  margin: 0 0.1rem 0 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .floating-bank-offline-container .bank-container .img-container img {
  height: 0.4rem;
  width: 0.4rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .floating-bank-offline-container .bank-container .bank-detail {
  font-size: 0.14rem;
  line-height: 1.3;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .floating-bank-offline-container .bank-container .bank-detail .title {
  color: #18723c;
  margin: 0;
  font-weight: bold;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .floating-bank-offline-container .bank-container .bank-detail .content {
  color: #858585;
  display: flex;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .deposit-payment-option .floating-bank-offline-container .bank-container .bank-detail .content p:first-child {
  margin: 0 0.15rem 0 0;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.react-datepicker {
  border-radius: .04rem !important;
  font-size: .13rem !important;
  border: .01rem solid #dadada !important;
}

.react-datepicker-popper[data-placement^=bottom] {
  margin-top: .1rem;
  margin-left: 0.77rem;
}

.react-datepicker__header {
  border-top-left-radius: .04rem !important;
  border-top-right-radius: .04rem !important;
  border-bottom: .01rem solid #dadada !important;
}

.react-datepicker__navigation {
  line-height: .08rem !important;
  border: .1rem solid rgba(0,0,0,0) !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
}

.react-datepicker__month-container {
  float: left;
  max-width: 3rem !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: .13rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 0.35rem !important;
  line-height: 0.35rem !important;
  margin: 0 !important;
}

.react-datepicker__month {
  margin: .13rem !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: .04rem !important;
  background-color: #005329 !important;
  color: #fff;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: .04rem !important;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #005329 !important;
  border-radius: .04rem !important;
  line-height: 0 !important;
}

.react-dropdown-select-dropdown {
  border-radius: .04rem !important;
  font-size: .14rem;
  width: 100% !important;
}

.css-g2497v-DropDown {
  position: absolute;
  top: 0.45rem !important;
  left: unset !important;
  border: 0.01rem solid hsla(0,0%,80%,0) !important;
}

.css-148o527-ItemComponent {
  font-size: .14rem !important;
  padding: .12rem !important;
  cursor: pointer;
  border-bottom: 0.01rem solid #fff;
  color: #b3b3b3;
}

.css-1bsdrez-DropDown {
  border: 0.01rem solid hsla(0,0%,80%,0) !important;
}

.css-148o527-ItemComponent:hover,
.css-148o527-ItemComponent:focus {
  background: #f2f2f2 !important;
  outline: none;
  color: #999 !important;
}

.css-12zlm52-ReactDropdownSelect:focus,
.css-12zlm52-ReactDropdownSelect:focus-within {
  outline: 0;
  box-shadow: 0 0 0 0.01rem #41b850 !important;
}

.css-12zlm52-ReactDropdownSelect {
  border: 0 solid hsla(0,0%,80%,0) !important;
  border-radius: .04rem !important;
  min-height: .4rem !important;
  pointer-events: all;
}

.css-148o527-ItemComponent.react-dropdown-select-item-selected {
  background: rgba(0,0,0,0) !important;
  color: #41b850 !important;
  border-bottom: 0.01rem solid #fff;
}

.select-noData {
  color: #b3b3b3;
  font-size: .14rem;
  padding: .12rem;
}

input[type=radio] {
  display: none;
}

.text-danger {
  color: #e1415d;
}

.invalid-feedback {
  display: flex;
  align-items: center;
  gap: .06rem;
  color: red;
  font-size: 0.12rem;
  align-self: center;
  margin: 0 0.05rem;
}

.invalid-feedback img {
  width: .14rem;
  height: .14rem;
}

.text-success {
  color: #39b549;
}

.valid-feedback {
  color: #41b850;
  font-size: 0.12rem;
}

ul.pagination {
  display: flex;
  justify-content: center;
  padding: 0.15rem 0;
  font-size: 0.14rem;
}

ul.pagination svg {
  max-width: 0.14rem;
}

ul.pagination li {
  background: #fff;
}

ul.pagination li a {
  padding: 0.05rem 0.1rem;
  display: flex;
  align-items: center;
  height: 100%;
}

ul.pagination li.active,
ul.pagination li:hover {
  background: #39b549;
}

ul.pagination li.active a,
ul.pagination li.active svg,
ul.pagination li:hover a,
ul.pagination li:hover svg {
  color: #fff;
  fill: #fff;
}

ul.pagination li.disabled svg {
  fill: #acacac;
}

ul.pagination li.disabled.active,
ul.pagination li.disabled:hover {
  background: #fff;
}

ul.pagination li.disabled.active a,
ul.pagination li.disabled.active svg,
ul.pagination li.disabled:hover a,
ul.pagination li.disabled:hover svg {
  color: #acacac;
  fill: #acacac;
  cursor: not-allowed;
}

.pagination-btn {
  width: 0.3rem;
  height: 0.3rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-btn {
  background-color: #fff;
  cursor: pointer;
}

.page-item.active .pagination-btn {
  background-color: #39b549;
  color: #fff;
  border: 0;
}

ul.page-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.3rem 0;
}

ul.page-numbers .pagination-number.msg-active-num {
  color: #fff;
  background-color: #39b549;
}

ul.page-numbers li {
  width: 0.3rem;
  height: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
}

.account-box .account-content .account-main-content .resetPass ul li.resetPass-title {
  text-align: center;
}

.account-box .account-content .account-main-content .resetPass ul li.resetPass-title label:first-child {
  font-size: 0.2rem;
  color: #0a974b;
  margin-bottom: 0.1rem;
}

.account-box .account-content .account-main-content .resetPass ul li.resetPass-title label:last-child {
  font-size: 0.14rem;
  margin-top: 0.1rem;
}

.account-box .account-content .account-main-content .resetPass ul li input {
  padding-left: 0.1rem;
}

.account-box .account-content .account-main-content .resetPass .text-green {
  color: #0a974b;
}

.account-box .account-content .account-main-content .form-header {
  text-align: center;
  margin-bottom: 0.25rem;
  font-size: 0.175rem;
  padding: 0 0.45rem;
}

.account-box .account-content .account-main-content .account-box-banner {
  padding: 0;
  margin-bottom: 0.25rem;
}

.account-box .account-content .account-main-content .account-box-banner .banner-image {
  line-height: 0;
}

.account-box .account-content .account-main-content .account-box-banner .banner-image img {
  width: 100%;
  object-fit: cover;
}

.account-box .account-content .account-main-content .page {
  padding-top: 0;
}

@media (max-width: 1025px) {
  .css-148o527-ItemComponent {
    font-size: 0.18rem !important;
  }

  .select-noData {
    font-size: 0.18rem;
  }

  .otp-errMsg {
    font-size: .3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content {
    min-height: 7rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-transfer,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-deposit,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-history,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-message,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-profile,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-changepwd,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-bank {
    min-height: 13.67rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-withdraw {
    min-height: 7rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit {
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
    min-height: 6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-options {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .select-member-bank .btn-addnewbank {
    width: 0.45rem;
    height: 0.45rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector ul.bank-list .small-bank .radio-form .account-holder,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .amount-select-btn .btn-amount {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .react-dropdown-select-content span {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .app-onlinetransfer {
    min-height: 11.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-image {
    margin-right: 0.4rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-image canvas {
    max-width: 2.49rem;
    max-height: 2.49rem;
    margin-bottom: 0.3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-image span {
    width: 2.49rem;
    height: 0.53rem;
    border-radius: 0.03rem;
    font-size: 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-info {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .qrCode li .item .qr-box .box-info p:nth-child(4) {
    margin-top: 0.3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-qrCode p {
    max-width: 2.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-qrCode .share-box span {
    padding: 0.2rem;
    height: 0.53rem;
    border-radius: 0.03rem;
    font-size: 0.21rem;
    margin-right: 0.15rem;
    max-width: 5.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-qrCode .share-box .share-btn {
    background: #cdcdcd;
    border-radius: 0.03rem;
    height: 0.53rem;
    cursor: pointer;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .remark-box {
    padding: 0.39rem 0.33rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .remark-box .remark-title {
    font-size: 0.18rem;
    margin-bottom: 0.34rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .remark-box .remark-content {
    font-size: 0.18rem;
    margin-left: 0.25rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-popup .popup .popup-body>:not(:last-child) {
    margin-right: 0.6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .share-popup .popup .popup-body>:nth-child(n) {
    width: 1rem;
    height: 1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .memberVerify-popup .popup .popup-body>div .text-red {
    font-size: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .crypto-deposit .memberVerify-popup .popup .popup-body>div .text-red strong {
    font-size: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box img {
    width: 0.8rem;
    height: 0.55rem;
    overflow: hidden;
    object-fit: contain;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li .all-in-box>ul li:not(:nth-child(4n)) {
    margin-right: 0.14rem;
    margin-bottom: 0.14rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .react-dropdown-select-content span {
    font-size: 0.22rem;
    bottom: 0.15rem;
    padding-right: 0.7rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .react-dropdown-select-dropdown .react-dropdown-select-item {
    font-size: 0.22rem !important;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history {
    padding-top: 1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank input {
    font-size: 0.19rem;
    height: 0.54rem;
    width: 4.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item .top .top-title {
    font-size: 0.19rem;
    margin-right: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.item-otp input {
    width: 2.6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.item-otp button {
    font-size: 0.19rem;
    width: 1.7rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .form-item.bank-statement-field {
    margin-top: 0.05rem;
    padding-bottom: 0.3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li .form-addbank .react-dropdown-select {
    width: 100%;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .msgAction {
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .msg-cancel-delete .btn-cancel,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item.msgRemark .top .top-title .msg-cancel-delete .btn-delete {
    width: 1rem;
  }

  .account-box .account-content .account-main-content .form-header {
    font-size: 0.3rem;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  ul.history-tab {
    gap: 0.04rem;
  }

  .login-failed {
    font-size: 0.3rem;
  }

  .invalid-feedback {
    font-size: 0.18rem;
  }

  ul.pagination {
    display: flex;
    justify-content: center;
    padding: 0.3rem 0;
    font-size: 0.2rem;
  }

  ul.pagination svg {
    max-width: 0.2rem;
  }

  .pagination-btn {
    width: 0.4rem;
    height: 0.4rem;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-btn {
    background-color: #fff;
    cursor: pointer;
  }

  .page-item.active .pagination-btn {
    background-color: #39b549;
    color: #fff;
    border: 0;
  }

  .member .member-main {
    width: 17.12rem;
  }

  .member .member-main .member-header {
    height: 1.05rem;
    padding-top: 0.6rem;
  }

  .member .member-main .auto-transfer {
    font-size: 0.21rem;
    padding-right: 0.54rem;
  }

  .member .member-main .auto-transfer>span {
    margin-right: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav {
    width: 3.54rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box {
    margin-bottom: 0.3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1)>div {
    margin-left: 0.17rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1)>div>span:nth-child(1) {
    font-size: 0.27rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1)>div>span:nth-child(2) {
    font-size: 0.27rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .line-out {
    width: 2.52rem;
    height: 0.25rem;
    border: 0.02rem solid #858585;
    margin-top: 0.16rem;
    margin-bottom: 0.09rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .rank-condition {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .memberReview-date {
    font-size: 0.21rem;
    margin-top: 0.3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .number {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div p {
    font-size: 0.36rem;
    line-height: 0.98rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li {
    margin-bottom: 0.05rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li .icon-box {
    width: 0.8rem;
    height: 0.6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li .icon-box>i {
    transform: scale(1.5);
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li .icon-box>i.icon-message {
    width: 0.26rem;
    height: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li a {
    width: 2.77rem;
    height: 0.6rem;
    padding-left: 0.24rem;
    font-size: 0.22rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .container-label {
    font-size: 0.22rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verification-icons-container .icon-wrapper {
    display: flex;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verification-icons-container .icon-wrapper .verify-icon {
    width: 0.3rem;
    height: 0.3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container {
    margin-top: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field .standard-select {
    padding: 0 0.12rem;
    height: 0.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field .standard-select span {
    font-size: 0.16rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field .standard-select .standard-select-options p {
    font-size: 0.16rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field .standard-select .standard-select-arrow {
    right: 0.2rem;
    width: 0.12rem;
    height: 0.12rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-field .standard-select .standard-select-dropdown {
    max-height: 1.4rem;
    top: 0.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .st-user-verification-container .verify-action-container .verify-button {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main {
    width: 13.44rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-transfer,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-deposit,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-history,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-message,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-profile,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-changepwd,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-bank {
    min-height: 24.07rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit input {
    height: 0.54rem;
    font-size: 0.18rem;
    width: 4rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit .react-dropdown-select {
    min-width: 3.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item {
    width: 9rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item p {
    font-size: 0.21rem;
    min-width: 2.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .deposit-options {
    font-size: 0.21rem;
    min-width: 2.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .display-inherit .deposit-tab-click {
    max-width: 1.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .display-inherit .deposit-tab-click a .deposit-banktransfer {
    width: 1.2rem;
    height: 0.8rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .display-inherit .deposit-tab-click a .deposit-banktransfer svg {
    height: auto;
    max-width: 0.8rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .display-inherit .deposit-tab-click a .deposit-banktransfer svg.ezpay {
    height: auto;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .display-inherit .deposit-tab-click a .deposit-banktransfer svg.h2pay {
    height: auto;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .display-inherit .deposit-tab-click a .deposit-banktransfer img {
    height: auto;
    max-width: 0.8rem;
    filter: grayscale(100%);
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .display-inherit .deposit-tab-click a .deposit-banktransfer img.ezpay {
    height: auto;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .display-inherit .deposit-tab-click a .deposit-banktransfer img.h2pay {
    height: auto;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .display-inherit .deposit-tab-click a .deposit-banktransfer img.active {
    filter: grayscale(0%);
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click {
    max-width: 1.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click .depositLink .deposit-banktransfer {
    width: 1.2rem;
    height: 0.8rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click .depositLink .deposit-banktransfer svg {
    max-width: 0.4rem;
    height: auto;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click .depositLink .deposit-banktransfer svg.crypto {
    max-width: 1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click .depositLink .deposit-banktransfer svg.bankapp {
    max-width: 0.8rem;
    max-height: unset;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click .depositLink .deposit-banktransfer img {
    max-width: 0.4rem;
    height: auto;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click .depositLink .deposit-banktransfer img.crypto {
    max-width: 1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click .depositLink .deposit-banktransfer img.bankapp {
    max-width: 0.8rem;
    max-height: unset;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click .depositLink .deposit-banktransfer div {
    font-size: 0.16rem;
    margin-top: 0.05rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .deposit-tab .deposit-tab-click .depositLink .deposit-options-text {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .bank-list .small-bank .banklist-group .bank-img {
    min-width: 0.6rem;
    max-width: 0.6rem;
    min-height: 0.6rem;
    max-height: 0.6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .deposit ul li .item .bank-selector .bank-list .small-bank .banklist-group .bank-name {
    font-size: 0.18rem;
    width: 1.8rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history {
    padding-top: 0.7rem;
    min-height: 10rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history input {
    font-size: 0.21rem;
    width: 1.7rem;
    height: 0.54rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formrow {
    margin-bottom: 0.15rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formrow .history-label {
    font-size: 0.21rem;
    width: 3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formrow .history-tab .history-tab-click a {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formrow ul li .form-history .form-item .top .top-title {
    width: 2.4rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formrow ul li .form-history .form-item .top .top-title .history-label {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-formrow ul li .form-history .form-item .top .to-label .history-label {
    max-width: 0.6rem;
    min-width: 0.6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header .history-title-div li {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .history .history-container .history-header div ul.history-details li {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content>div {
    padding: 0 0.4rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content h1 {
    height: 0.98rem;
    font-size: 0.36rem;
    top: -0.98rem;
    left: 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer {
    padding: 0 0.75rem;
    padding-bottom: 0.83rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer .react-dropdown-select {
    min-width: 4.05rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer .react-dropdown-select-dropdown {
    width: 4.05rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li {
    padding-bottom: 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 {
    padding: 0.68rem 0;
    padding-bottom: 1.26rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 select,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 input {
    font-size: 0.21rem;
    height: 0.6rem;
    border-radius: 0.06rem;
    border: 0.02rem solid #dadada;
    width: 4.04rem;
    padding: 0 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 select {
    background-position-x: 3.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item {
    margin-bottom: 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item .top .top-title {
    width: 1.95rem;
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item .bottom {
    padding-left: 1.95rem;
    padding-top: 0.09rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to {
    margin-left: 0.33rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to .icon-transfer-btn {
    margin-right: 0.33rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top>span,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top>button {
    font-size: 0.21rem;
    margin-left: 0.36rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.ta {
    margin-top: 0.65rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.ta input {
    border: 0.02rem solid #0a974b;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.ta .btn-allin {
    width: 2.49rem;
    height: 0.53rem;
    border-radius: 0.03rem;
    margin-left: 0.14rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box {
    padding-left: 1.95rem;
    margin-top: 0.51rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box .btn-reset {
    width: 2.49rem;
    height: 0.53rem;
    border-radius: 0.03rem;
    margin-right: 0.15rem;
    font-size: 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box .btn-transfer {
    font-size: 0.24rem;
    width: 2.49rem;
    height: 0.53rem;
    border-radius: 0.03rem;
    margin-right: 0.15rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box {
    right: 0;
    top: 0.08rem;
    width: 5.24rem;
    height: 0.54rem;
    border: 0.02rem solid #dadada;
    border-radius: 0.06rem;
    padding: 0 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>span {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>div>span {
    font-size: 0.24rem;
    margin: 0 0.23rem;
    margin-left: 0.48rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>div .icon-reload2 {
    width: 0.32rem;
    height: 0.33rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-nav li {
    width: 3.1rem;
    height: 0.75rem;
    border-top-right-radius: 0.06rem;
    border-top-left-radius: 0.06rem;
    font-size: 0.27rem;
    margin-right: 0.11rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .turnover-item {
    width: 5.24rem;
    height: 0.54rem;
    border: 0.02rem solid #dadada;
    border-radius: 0.06rem;
    padding: 0 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .turnover-item>span {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .turnover-item>span.item-amount {
    font-size: 0.24rem;
    margin: 0 0.23rem;
    margin-left: 0.48rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content {
    width: 12rem;
    border-radius: 0.09rem;
    border-top-left-radius: 0;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>span,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>span {
    font-size: 0.27rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .item,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .item {
    padding: 0.1rem;
    border-radius: 0.1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .item .icon-box,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .item .icon-box {
    width: 0.54rem;
    height: 0.53rem;
    margin-right: 0.15rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .item .icon-box>i,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .item .icon-box>i {
    width: 0.54rem;
    height: 0.54rem;
    background-size: 100% 100%;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .item>div,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .item>div {
    font-size: 0.24rem;
    line-height: 0.23rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .item>div>span:nth-child(1),
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .item>div>span:nth-child(1) {
    margin-bottom: 0.045rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .turnover-box,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .turnover-box {
    padding: 0.1rem;
    margin-bottom: 0.1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .turnover-box span,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .turnover-box span {
    width: 0.8rem;
    font-size: 0.24rem;
    line-height: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .turnover-box span.turnover-amount,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .turnover-box span.turnover-amount {
    margin-left: 0.15rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .provider-wallet-container .btn-all-in,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .provider-wallet-container .btn-all-in {
    width: 1rem;
    height: 0.43rem;
    padding: 0.06rem;
    border: 0.01rem solid #ba9b43;
    border-radius: 0.1rem;
    font-size: 0.24rem;
    margin-bottom: 0.35rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content {
    min-height: 11.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .react-dropdown-select,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content input {
    min-width: 4.5rem;
    font-size: 0.18rem;
    height: 0.54rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .react-dropdown-select-content span,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .css-148o527-ItemComponent {
    font-size: 0.18rem !important;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit .address-popup .popup .popup-body .name-popup .popup-field.otp-field button {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item {
    width: 9rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item>p {
    font-size: 0.21rem;
    min-width: 2.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .box-info .bank-list .small-bank:nth-child(2) {
    padding-right: 0;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .remark-box {
    padding: 0.39rem 0.33rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .remark-box .remark-title {
    font-size: 0.18rem;
    margin-bottom: 0.34rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .remark-box .remark-content {
    font-size: 0.18rem;
    margin-left: 0.25rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .crypto-deposit ul li .item .deposit-amount .btn-addnewbank {
    width: 0.45rem;
    height: 0.45rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .bank-img {
    min-width: 0.6rem;
    max-width: 0.6rem;
    min-height: 0.6rem;
    max-height: 0.6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-tab-content .bank-selector .bank-list .small-bank .banklist-group .bank-name {
    font-size: 0.18rem;
    width: 1.8rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item {
    padding-left: 0.39rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .submit-btn,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .submit-btn {
    width: 2.49rem;
    height: 0.53rem;
    font-size: 0.24rem;
    border-radius: 0.03rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item>p,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item>p {
    font-size: 0.21rem;
    min-width: 2.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box input {
    width: 4.5rem;
    height: 0.6rem;
    padding: 0 0.22rem;
    font-size: 0.21rem;
    border: 1PX solid #e2e2e2;
    position: relative;
    border-radius: 0.06rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box input:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 1PX solid #e2e2e2;
    border-radius: 0.06rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 768px)and (max-width: 1025px)and (-webkit-min-device-pixel-ratio: 2),only screen and (min-width: 768px)and (max-width: 1025px)and (min-device-pixel-ratio: 2) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box input:after {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

@media only screen and (min-width: 768px)and (max-width: 1025px)and (-webkit-min-device-pixel-ratio: 3),only screen and (min-width: 768px)and (max-width: 1025px)and (min-device-pixel-ratio: 3) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box input:after {
    width: 300%;
    height: 300%;
    transform: scale(0.333333);
    transform-origin: left top;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box>p,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .input-box>p {
    font-size: 0.21rem;
    margin: 0.22rem 0;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer {
    width: 1.2rem;
    height: 0.8rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img {
    max-width: 0.4rem;
    height: auto;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img.crypto,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer img.crypto {
    max-width: 1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer div,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-banktransfer div {
    font-size: 0.16rem;
    margin-top: 0.05rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-options-text,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .item .bank-selector .withdraw-tab .withdraw-tab-click .withdrawLink .withdraw-options-text {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(1) .item,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(1) .item {
    width: 8.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(2) .item {
    margin-bottom: 0.31rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item:nth-child(3),
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(2) .item:nth-child(3) {
    align-items: flex-start;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item:nth-child(3)>p,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(2) .item:nth-child(3)>p {
    margin-top: 0.1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item:last-child,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li:nth-child(2) .item:last-child {
    padding-left: 2.85rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul {
    width: 5.6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li {
    margin-right: 0.18rem;
    margin-bottom: 0.32rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box {
    width: 1.2rem;
    height: 0.75rem;
    position: relative;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 1PX solid #dedcdf;
    border-radius: 0.06rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 768px)and (max-width: 1025px)and (-webkit-min-device-pixel-ratio: 2),only screen and (min-width: 768px)and (max-width: 1025px)and (min-device-pixel-ratio: 2) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box:after {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

@media only screen and (min-width: 768px)and (max-width: 1025px)and (-webkit-min-device-pixel-ratio: 3),only screen and (min-width: 768px)and (max-width: 1025px)and (min-device-pixel-ratio: 3) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box:after {
    width: 300%;
    height: 300%;
    transform: scale(0.333333);
    transform-origin: left top;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box>i,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li .icon-box>i {
    transform: scale(1.5);
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li>span,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li>span {
    width: 1.2rem !important;
    margin-top: 0.09rem;
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.other-bank>span,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.other-bank>span {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box {
    position: relative;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2PX solid #368b5f;
    border-radius: 0.06rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 768px)and (max-width: 1025px)and (-webkit-min-device-pixel-ratio: 2),only screen and (min-width: 768px)and (max-width: 1025px)and (min-device-pixel-ratio: 2) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box:after {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

@media only screen and (min-width: 768px)and (max-width: 1025px)and (-webkit-min-device-pixel-ratio: 3),only screen and (min-width: 768px)and (max-width: 1025px)and (min-device-pixel-ratio: 3) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box:after {
    width: 300%;
    height: 300%;
    transform: scale(0.333333);
    transform-origin: left top;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box::before,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box::before,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li:hover .icon-box::before,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .bank-selector ul li.on .icon-box::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background: url("../assets/member/bank_on.png") no-repeat;
    width: 0.33rem;
    height: 0.4rem;
    background-size: 100% 100%;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box {
    width: 3rem;
    padding: 0.43rem 0.28rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box .title,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box .title {
    font-size: 0.21rem;
    margin-bottom: 0.36rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box>ul,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw .withdraw-bankTransfer>li .notice-box>ul {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging {
    padding-top: 0.5rem;
    min-height: 10rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox {
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging .inbox span {
    width: 1.75rem;
    height: 0.43rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .no-msg-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.36rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header {
    height: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-header .msg-item.msgChkbox .top-title {
    width: 1.3rem;
    line-height: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul li .msg-row .msg-item {
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul.page-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem 0;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul.page-numbers .pagination-number.msg-active-num {
    color: #fff;
    background-color: #39b549;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .messaging ul.page-numbers li {
    width: 0.3rem;
    height: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    cursor: pointer;
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile {
    padding: 0.5rem 0.4rem;
    min-height: 10rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-top .profile-item {
    min-height: 3.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-top .profile-item .form-item .top .top-title {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-top .profile-item .form-item .top .bottom {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-top .profile-item .form-item .bank-list ul li .bank-logo {
    max-height: 0.55rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-top .profile-item .form-item .bank-list ul li .bank-acc {
    line-height: 0.2rem;
    margin-bottom: 0;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-top .profile-item .form-item .bank-list ul li .bank-acc span {
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-top .profile-item .form-item .bank-list ul li .bank-acc .bank-verified {
    min-width: 0.16rem;
    min-height: 0.16rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-top .profile-item .form-item .bank-list ul li .bank-acc .bank-verified svg {
    min-width: 0.16rem;
    min-height: 0.16rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-top .profile-item .form-item .bank-list ul li .bank-acc .bank-holder {
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-updateform li .form-profile input {
    height: 0.54rem;
    width: 4.5rem;
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-updateform li .form-profile .react-dropdown-select {
    width: 4.52rem;
    left: -0.01rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-updateform li .form-profile .react-dropdown-select-content {
    width: 4.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-updateform li .form-profile .react-dropdown-select-content span {
    font-size: 0.21rem;
    top: 0.1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-updateform li .form-profile .react-dropdown-select-dropdown {
    width: 4.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-updateform li .form-profile .react-dropdown-select-item {
    font-size: 0.21rem !important;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-updateform li .form-profile .form-item .top .top-title {
    font-size: 0.21rem;
    margin-right: 0.8rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .profile .profile-updateform li .form-profile .form-item .top .btn-updateprofile {
    width: 2.26rem;
    height: 0.54rem;
    font-size: 0.26rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword {
    min-height: 10rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass input {
    height: 0.54rem;
    width: 4.5rem;
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .standard-password-eye {
    width: 0.4rem !important;
    left: 3.8rem !important;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .react-dropdown-select {
    width: 4.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .react-dropdown-select-dropdown {
    width: 4.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .react-dropdown-select-dropdown span {
    font-size: 0.21rem !important;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .react-dropdown-select-content span {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .top-title {
    width: 2.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .top-title span {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item .top .top-text {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .changepassword ul li .form-changepass .form-item div .btn-changepass {
    height: 0.54rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail {
    padding-top: 0.5rem;
    min-height: 10rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .inbox {
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .inbox span {
    width: 2.1rem;
    height: 0.43rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .bank-header {
    height: 0.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .bank-header li {
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail .bank-header li.address {
    min-width: 7rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul li {
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .bankdetail ul.member-banklist .address {
    min-width: 7rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert {
    width: 7.29rem;
    height: 6.36rem;
    border-radius: 0.05rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .title {
    height: 0.9rem;
    font-size: 0.36rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .title .icon-close {
    right: 0.24rem;
    transform: translateY(-50%) scale(1.5);
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content {
    width: 5.7rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content>div {
    font-size: 0.27rem;
    margin-top: 0.71rem;
    margin-bottom: 0.56rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content>p {
    font-size: 0.21rem;
    line-height: 0.36rem;
    margin-bottom: 0.45rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content .btn-close {
    width: 1.47rem;
    height: 0.53rem;
    border-radius: 0.04rem;
    font-size: 0.24rem;
  }

  .account-box .account-content .account-main-content .resetPass ul li.resetPass-title label:first-child {
    font-size: 0.35rem;
  }

  .account-box .account-content .account-main-content .resetPass ul li.resetPass-title label:last-child {
    font-size: 0.23rem;
  }
}

@media (max-width: 768px) {
  .login-failed {
    font-size: 0.3rem;
  }

  .wap .popup-footer .btn-normal,
  .wap .popup-footer .btn-normal-green,
  .wap .popup-footer .btn-long {
    text-transform: uppercase;
  }

  .member .bg-footer-bottom {
    display: none;
  }

  .css-pvvpmh-InputComponent {
    margin-left: 0 !important;
  }

  .invalid-feedback {
    font-size: 0.36rem;
  }

  .member .member-main {
    width: 11.6rem;
    padding: 0;
  }

  .member .member-main .member-header {
    height: 1.05rem;
    padding-top: 0.6rem;
  }

  .member .member-main .auto-transfer {
    font-size: 0.21rem;
    padding-right: 0.54rem;
  }

  .member .member-main .auto-transfer>span {
    margin-right: 0.21rem;
  }

  .member .member-main .main-wallet-content {
    padding-top: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav {
    width: 3.44rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box {
    margin-bottom: 0.3rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1)>div {
    margin-left: 0.17rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1)>div>span:nth-child(1) {
    font-size: 0.27rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(1)>div>span:nth-child(2) {
    font-size: 0.27rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .line-out {
    width: 2.52rem;
    height: 0.11rem;
    border: 0.02rem solid #858585;
    border-radius: 0.06rem;
    margin-top: 0.16rem;
    margin-bottom: 0.09rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav .info-box>div:nth-child(2) .number {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div p {
    font-size: 0.36rem;
    line-height: 0.92rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li {
    margin-bottom: 0.04rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li .icon-box {
    width: 0.68rem;
    height: 0.6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li .icon-box>i {
    transform: scale(1.5);
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li .icon-box>i.icon-message {
    width: 0.26rem;
    height: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-nav>div ul li a {
    width: 2.77rem;
    height: 0.6rem;
    padding-left: 0.23rem;
    font-size: 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main {
    width: 7.97rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content {
    min-height: 29.88rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-transfer,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-deposit,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-history,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-message,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-profile,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-changepwd,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-bank {
    min-height: 29.88rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content.page-withdraw {
    min-height: 16.88rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content>div {
    padding: 0 0.17rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content h1 {
    height: 0.92rem;
    font-size: 0.36rem;
    top: -0.92rem;
    left: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 {
    padding: 0.68rem 0;
    padding-bottom: 1.26rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 select,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 input {
    font-size: 0.21rem;
    height: 0.6rem;
    border-radius: 0.06rem;
    border: 0.02rem solid #dadada;
    width: 4.04rem;
    padding: 0 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 select {
    background-position-x: 3.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-transfer {
    display: flex;
    flex-direction: column;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item {
    margin-bottom: 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item .top .top-title {
    width: 1.95rem;
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item .bottom {
    padding-left: 1.95rem;
    padding-top: 0.09rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to {
    margin-left: 0;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to .top-title>i {
    order: 1;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to .top-title>span {
    order: 0;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.to .icon-transfer-btn {
    margin-left: 0.33rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top {
    position: relative;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top>span,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.pc .top>button {
    font-size: 0.21rem;
    margin-left: 0.1rem;
    position: absolute;
    left: 5.95rem;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.ta {
    margin-top: 0.65rem;
    position: relative;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.ta input {
    border: 0.02rem solid #0a974b;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .form-item.ta .btn-allin {
    width: 2.49rem;
    height: 0.53rem;
    border-radius: 0.03rem;
    margin-left: 0.14rem;
    position: absolute;
    left: 1.8rem;
    transform: translateY(150%);
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box {
    padding-left: 1.95rem;
    margin-top: 1.51rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box .btn-reset {
    width: 2.49rem;
    height: 0.53rem;
    border-radius: 0.03rem;
    margin-right: 0.15rem;
    font-size: 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item1 .btn-box .btn-transfer {
    font-size: 0.24rem;
    width: 2.49rem;
    height: 0.53rem;
    border-radius: 0.03rem;
    margin-right: 0.15rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box {
    right: 0.1rem;
    top: -0.8rem;
    width: 5.24rem;
    height: 0.54rem;
    border: 0.02rem solid #dadada;
    border-radius: 0.06rem;
    padding: 0 0.24rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>span {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>div>span {
    font-size: 0.24rem;
    margin: 0 0.23rem;
    margin-left: 0.48rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .item2-restore-box>div .icon-reload2 {
    width: 0.32rem;
    height: 0.33rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-nav li {
    width: 3.1rem;
    height: 0.75rem;
    border-top-right-radius: 0.06rem;
    border-top-left-radius: 0.06rem;
    font-size: 0.27rem;
    margin-right: 0.11rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content {
    width: 7rem;
    border-radius: 0.09rem;
    border-top-left-radius: 0;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li {
    padding-left: 0.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li:not(:last-child),
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li:not(:last-child) {
    border-bottom: 0.03rem solid hsla(0,0%,100%,.3);
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>span,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>span {
    font-size: 0.27rem;
    margin-top: 0.5rem;
    margin-bottom: 0.38rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div {
    padding-left: 0.14rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .item,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .item {
    width: 3.1rem;
    margin-bottom: 0.51rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .item .icon-box,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .item .icon-box {
    width: 0.54rem;
    height: 0.53rem;
    margin-right: 0.15rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .item .icon-box>i,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .item .icon-box>i {
    width: 0.54rem;
    height: 0.54rem;
    background-size: 100% 100%;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .item>div,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .item>div {
    font-size: 0.24rem;
    line-height: 0.23rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.provider-balance .provider-list>li>div .item>div>span:nth-child(1),
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .transfer>ul>li.transfer-item2 .transfer-tabs .tabs-content>div.weekly-turnover .provider-list>li>div .item>div>span:nth-child(1) {
    margin-bottom: 0.1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li {
    padding-bottom: 0.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item {
    padding-left: 0.39rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .submit-btn {
    width: 2.49rem;
    height: 0.53rem;
    font-size: 0.24rem;
    border-radius: 0.03rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item>p {
    font-size: 0.21rem;
    width: 1.7rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input {
    width: 4.5rem;
    height: 0.6rem;
    padding: 0 0.22rem;
    font-size: 0.21rem;
    border: 1PX solid #e2e2e2;
    position: relative;
    border-radius: 0.06rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 1PX solid #e2e2e2;
    border-radius: 0.06rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 768px)and (-webkit-min-device-pixel-ratio: 2),only screen and (max-width: 768px)and (min-device-pixel-ratio: 2) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input:after {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

@media only screen and (max-width: 768px)and (-webkit-min-device-pixel-ratio: 3),only screen and (max-width: 768px)and (min-device-pixel-ratio: 3) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box input:after {
    width: 300%;
    height: 300%;
    transform: scale(0.333333);
    transform-origin: left top;
  }
}

@media (max-width: 768px) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box>p {
    font-size: 0.21rem;
    margin: 0.22rem 0;
    display: flex;
    align-items: flex-start;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .item .input-box>p>span {
    display: flex;
    flex-direction: column;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(1) .item {
    width: 8.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) {
    padding-top: 0.69rem;
    padding-bottom: 5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item {
    margin-bottom: 0.31rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item:nth-child(3) {
    align-items: flex-start;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li:nth-child(2) .item:nth-child(3)>p {
    margin-top: 0.1rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul {
    width: 5.6rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li {
    margin-right: 0.18rem;
    margin-bottom: 0.32rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box {
    width: 1.2rem;
    height: 0.75rem;
    position: relative;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 1PX solid #dedcdf;
    border-radius: 0.06rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 768px)and (-webkit-min-device-pixel-ratio: 2),only screen and (max-width: 768px)and (min-device-pixel-ratio: 2) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box:after {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

@media only screen and (max-width: 768px)and (-webkit-min-device-pixel-ratio: 3),only screen and (max-width: 768px)and (min-device-pixel-ratio: 3) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box:after {
    width: 300%;
    height: 300%;
    transform: scale(0.333333);
    transform-origin: left top;
  }
}

@media (max-width: 768px) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li .icon-box>i {
    transform: scale(1.5);
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li>span {
    margin-top: 0.09rem;
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.other-bank>span {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box {
    position: relative;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2PX solid #368b5f;
    border-radius: 0.06rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 768px)and (-webkit-min-device-pixel-ratio: 2),only screen and (max-width: 768px)and (min-device-pixel-ratio: 2) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box:after {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

@media only screen and (max-width: 768px)and (-webkit-min-device-pixel-ratio: 3),only screen and (max-width: 768px)and (min-device-pixel-ratio: 3) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box:after,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box:after {
    width: 300%;
    height: 300%;
    transform: scale(0.333333);
    transform-origin: left top;
  }
}

@media (max-width: 768px) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li:hover .icon-box::before,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .bank-selector ul li.on .icon-box::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background: url("../assets/member/bank_on.png") no-repeat;
    width: 0.33rem;
    height: 0.4rem;
    background-size: 100% 100%;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box {
    position: absolute;
    left: 2.08rem;
    bottom: 0.9rem;
    width: 4.2rem;
    padding: 0.43rem 0.28rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box .title {
    font-size: 0.21rem;
    margin-bottom: 0.36rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .withdraw>ul>li .notice-box>ul {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert {
    width: 7.29rem;
    height: 6.36rem;
    border-radius: 0.05rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .title {
    height: 0.9rem;
    font-size: 0.36rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .title .icon-close {
    right: 0.24rem;
    transform: translateY(-50%) scale(1.5);
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content {
    width: 5.7rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content>div {
    font-size: 0.27rem;
    margin-top: 0.71rem;
    margin-bottom: 0.56rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content>p {
    font-size: 0.21rem;
    line-height: 0.36rem;
    margin-bottom: 0.45rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .submit-alert .content .btn-close {
    width: 1.47rem;
    height: 0.53rem;
    border-radius: 0.04rem;
    font-size: 0.24rem;
  }

  .account-box .account-content .account-main-content .resetPass ul li {
    margin-bottom: 0.3rem;
  }

  .account-box .account-content .account-main-content .resetPass ul li.resetPass-title label:first-child {
    font-size: 0.4rem;
  }

  .account-box .account-content .account-main-content .resetPass ul li.resetPass-title label:last-child {
    font-size: 0.28rem;
  }

  .app-onlinetransfer .select-bank .label-and-infoIcon-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  .app-onlinetransfer .select-bank .label-and-infoIcon-container p {
    padding-right: 0.2rem;
  }

  .app-onlinetransfer .select-bank .label-and-infoIcon-container .important-notice-container {
    display: flex;
    align-items: center;
    margin: 0 0 0.3rem;
    width: 58%;
  }

  .app-onlinetransfer .select-bank .label-and-infoIcon-container .important-notice-container svg {
    height: 0.5rem;
    width: 0.5rem;
  }

  .app-onlinetransfer .select-bank .label-and-infoIcon-container .important-notice-box {
    position: absolute;
    width: 100%;
    bottom: 100%;
    left: 0%;
    padding: 0.1rem;
    background: #fff;
    color: #000;
    border-radius: 0.1rem;
    z-index: 1;
    box-shadow: 0.02rem 0.03rem 0.05rem #9b9b9b;
    display: flex;
    word-break: break-word;
    font-size: 0.42rem;
  }
}

@media (min-width: 1025px) {
  ul.history-tab {
    gap: 0.12rem;
  }
}

.display-inherit {
  display: inherit;
}

.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.pointer {
  cursor: pointer;
}

.specialCodeValid {
  margin-left: 0.2rem;
  color: #6ac259;
}

.specialCodeInvalid {
  margin-left: 0.2rem;
  color: #e05161;
}

.disable-input {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.slot-logo1 {
  background-image: url(/public/html/images/slot_img/096-participate-pg.png);
  width: 1.9rem;
  height: 0.51rem;
  bottom: 0.61rem;
  left: 0.65rem;
}

.slots .slots-container {
  margin-top: 0.55rem;
}

.slots .slots-container .content-recommended .content-top {
  padding-bottom: 0.35rem;
}

.slots .slots-container .content-recommended .slick-list {
  max-height: 2.03rem;
}

.slots .slots-container .content-recommended .slot-special-cap {
  display: block;
  padding: 0 1.09rem;
}

.slots .slots-container .content-recommended .slick-prev-btn {
  left: -0.6rem;
}

.slots .slots-container .content-recommended .slick-next-btn {
  right: -0.5rem;
}

.slots .slots-container .content-recommended .slick-dots {
  right: -0.9rem;
}

.slots .content-section .slots-content-section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.slots .content-section .slots-content-section>li {
  width: 100%;
}

.slots .content-section .slots-content-section>li:not(:last-child) {
  margin-bottom: 0.2rem;
}

.slots .content-section .slots-content-section>li .content-center {
  position: relative;
}

.slots .content-section .slots-content-section>li.content-sort-search {
  margin-top: 0.35rem;
  margin-bottom: 0.7rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box {
  width: 14.14rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 0.88rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li {
  height: 100%;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item {
  width: 2.02rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.16rem;
  color: #858585;
  border-right: 0.01rem solid #cfcfcf;
  position: relative;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item.active>div:nth-child(1) {
  background: #1a713f;
  color: #fff;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item.active .sort-select {
  background: #e1dee2;
  color: #858585;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div {
  transition: background .3s,color .3s;
  padding-left: 0.25rem;
  padding-right: 0.3rem;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread {
  position: absolute;
  left: 0;
  top: 0.88rem;
  width: 100%;
  height: 2.5rem;
  background: #e1dee2;
  z-index: 1;
  overflow: auto;
  padding: 0.2rem 0;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread li {
  height: 0.27rem;
  padding-left: 0.25rem;
  color: #222;
  transition: color .3s;
  white-space: nowrap;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread li:hover {
  color: #216e43;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content {
  width: 14.14rem;
  margin: 0 auto;
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-between;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list {
  margin-right: 0.2rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li {
  width: 1.84rem;
  height: 0.5rem;
  display: flex;
  align-items: center;
  color: #858585;
  cursor: pointer !important;
  transition: background .5s,color .5s;
  position: relative;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li:not(:last-child) {
  margin-bottom: 0.08rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li.on {
  cursor: default !important;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li.on,
.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li:hover {
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #000;
  cursor: default;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-name {
  font-size: 0.16rem;
  padding-left: 0.14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-box {
  min-width: 0.5rem;
  height: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f2322;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .maintenance-hover-box {
  background: url(/public/html/images/icon/maintenance-en.svg);
  width: 1.84rem;
  height: 0.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(245,245,245,.8);
  position: absolute;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 998;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.204919));
  animation: modalPopUp .3s;
  cursor: default;
}

@keyframes modalPopUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 !important;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  max-width: 8rem;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box p {
  font-size: 0.16rem;
  margin-bottom: 0.3rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-header {
  padding: 0.28rem 0.27rem 0.05rem;
  width: 100%;
  display: flex;
  align-items: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-header .box-header-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #39b549;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-header .box-header-right svg {
  width: 0.35rem;
  height: 0.35rem;
  cursor: pointer;
  border: 0.02rem solid gray;
  border-radius: 50%;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body {
  width: 100%;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-icon {
  margin: auto;
  width: 10%;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-title {
  display: flex;
  justify-content: center;
  border-bottom: 0.01rem solid rgba(0,0,0,.1294117647);
  padding: 0.3rem;
  margin: 0 0.6rem;
  text-align: center;
  color: #0a974b;
  line-height: 0.29rem;
  font-weight: 700;
  font-size: 0.24rem;
  font-style: normal;
  font-family: "AvenirNextLTPro";
  white-space: nowrap;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details {
  margin: 0.3rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container {
  margin: 0 0.8rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .dateColon {
  text-align: center;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 {
  text-align: left;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 .key {
  width: 100%;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.26rem;
  font-family: "AvenirNextLTPro";
  color: #424242;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 {
  text-align: right;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime {
  width: 100%;
  margin-left: 0.2rem;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.26rem;
  font-family: "AvenirNextLTPro";
  color: #0a974b;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime:lang(vi) {
  font-variant: tabular-nums;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime.dateTimeNotice {
  position: absolute;
  margin-top: 0.5rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .slashColor {
  color: #0a974b;
  margin-left: 0.2rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container {
  display: flex;
  flex-flow: column;
  margin: 0.6rem 2rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  justify-items: center;
  grid-gap: 0.3rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row1 {
  width: 100%;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row2 {
  padding: 0.2rem 0;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer {
  background: #f0f0f0;
  border-radius: 0.05rem;
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #424242;
  font-weight: 700;
  font-style: normal;
  font-size: 0.24rem;
  line-height: 0.26rem;
  text-align: center;
  font-family: "AvenirNextLTPro";
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer-status {
  color: #424242;
  font-weight: 700;
  font-style: normal;
  font-size: 0.16rem;
  line-height: 0.26rem;
  text-align: center;
  font-family: "AvenirNextLTPro";
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 0;
  overflow: hidden;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game {
  width: 12.2rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li {
  margin-bottom: 0.17rem;
  background: #dad8d8;
  border: 0.02rem solid #dad8d8;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>img {
  max-width: 2.26rem;
  max-height: 1.48rem;
  min-height: unset;
  margin: 0 auto;
  display: block;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p {
  height: 0.47rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(5n)) {
  margin-right: 0.16rem;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box {
  transition: transform .5s;
  transform: translate(-50%, -50%) scale(0);
  position: absolute;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:hover .hover-box {
  transform: translate(-50%, -50%) scale(1);
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game .item-enter {
  opacity: 0;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game .item-enter-active {
  opacity: 1;
  transition: all 300ms;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game .item-exit {
  opacity: 1;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game .item-exit-active {
  opacity: 0;
  transition: all 300ms;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .loading-container {
  width: 12.2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .nav-game .items {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.01rem;
}

.slots .content-promotions {
  margin-top: 0.3rem;
}

.slots .brand-list .badge::before {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.04rem;
  font-size: 0.1rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.slots .brand-list .badge-hot::before {
  content: "hot";
  background-color: #ff3d00;
}

.slots .brand-list .badge-new::before {
  content: "new";
  background-color: #19733d;
}

@media (max-width: 1445px) {
  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .loading-container {
    width: 75.5vw;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .slots .slots-container .content-recommended .slick-list {
    max-height: 3.24rem;
  }

  .slots .banner-box .banner ul.sli:nth-child(1) .slide-text-box {
    top: 1rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search {
    margin-top: 0.7rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box {
    width: 19rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 1.36rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li {
    height: 100%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item {
    width: 3rem;
    font-size: 0.24rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div {
    padding-left: 0.3rem;
    padding-right: 0.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread {
    top: 1.36rem;
    width: 100%;
    height: 3.7rem;
    padding: 0.2rem 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread li {
    height: 0.5rem;
    padding-left: 0.36rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content {
    width: 19rem;
    margin-top: 0.46rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li {
    width: 3rem;
    height: 0.72rem;
    font-size: 0.24rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-name {
    padding-left: 0.34rem;
    font-size: 0.24rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-box {
    min-width: 0.72rem;
    height: 0.72rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-box .icon-box>i {
    transform: scale(1.5);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box {
    width: 15.8rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .loading-container {
    width: 77.2vw;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game {
    width: 16rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li {
    width: 3.7rem;
    height: 3.18rem;
    margin: 0 0 0.24rem;
    border: 0.04rem solid #dad8d8;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(5n)) {
    margin-right: 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(4n)) {
    margin-right: 0.2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>img {
    max-width: unset;
    max-height: 2.48rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p {
    height: 0.73rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .icon-love-on {
    transform: scale(1.5);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .gamename {
    left: 0.6rem;
    width: 2.9rem;
    font-size: 0.2rem;
  }
}

@media (max-width: 768px) {
  #root .slots.logined .bg-footer-bottom {
    top: 7.27rem;
  }

  .slots .slots-container {
    margin-top: 0;
  }

  .slots .slots-container .content-recommended .recommend-content-title {
    margin: 1rem 0 0 .38rem;
    padding: 0rem;
    height: auto;
  }

  .slots .slots-container .content-recommended .recommend-content-top {
    padding: 0rem;
    margin: 0rem;
    height: 3.4rem;
    width: auto;
    overflow-x: hidden;
  }

  .slots .slots-container .content-recommended .slot-special-cap {
    padding-left: .38rem;
  }

  .slots .slots-container .content-recommended .slick-slider {
    height: 100%;
    width: 100vw;
  }

  .slots .slots-container .content-recommended .slick-list {
    z-index: 1;
    max-height: 4.5rem;
    height: 100%;
  }

  .slots .slots-container .content-recommended .slick-list li {
    width: 4.1rem;
    height: 3rem;
    position: relative !important;
    font-size: 0;
    margin: auto;
    left: 0;
    right: 0;
    margin-right: .19rem;
  }

  .slots .slots-container .content-recommended .slick-list li>p {
    height: 0.79rem;
  }

  .slots .slots-container .content-recommended .slick-dots {
    top: -0.5rem;
    right: -0.36rem;
    bottom: 0;
    z-index: 0;
    text-align: right;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .slots .slots-container .content-recommended .slick-dots li.slick-active {
    background: #39b549;
  }

  .slots .content-section>ul {
    margin-top: 0;
  }

  .slots .content-section .slots-content-section>li.content-recommended {
    display: flex;
  }

  .slots .content-section .slots-content-section>li.content-recommended .recommendgamename {
    font-size: 0.23rem;
    width: 2.5rem;
  }

  .slots .content-section .slots-content-section>li.content-recommended .icon-provider-logo {
    width: .4rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search {
    margin-top: 0;
    overflow: hidden;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .web {
    display: none !important;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile {
    display: block !important;
    position: relative;
    transition: min-height .5s;
    margin-top: 1rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile.mobileRecommend {
    margin-top: 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile.page-games {
    min-height: 22.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile>div {
    width: 100%;
    left: 0;
    top: 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile>div.fade-enter-done {
    position: relative;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PT,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PT2,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PT3,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-SG,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-TTG,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-GP,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-BINL2,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-BINS2,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-SA,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-MGP,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-AG,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-AG2 {
    width: 1.4rem;
    height: 1.4rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-HB {
    width: 1.6rem;
    height: 1.6rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-MEGA,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-JL,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PGS,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-FG,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-MT,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-YGR,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-V8,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-KM,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-KM2 {
    width: 2rem;
    height: 2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PSY8 {
    width: 2rem;
    height: 1.05rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PP,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PP-slot {
    width: 1.6rem;
    height: 1.6rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-NS,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-NS-slot {
    width: 1.5rem;
    height: 1.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-UL {
    width: 1.93rem;
    height: 1.29rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-SCR2 {
    width: 1.88rem;
    height: 0.83rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-JKR {
    width: 1.88rem;
    height: 0.43rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PG {
    width: 1.67rem;
    height: 1.2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-NT {
    width: 1.6rem;
    height: 1.6rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-NT .st1 {
    fill: #000 !important;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-RT {
    width: 1.6rem;
    height: 1.6rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-CQ9 {
    width: 2rem;
    height: 2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-FC {
    width: 1.5rem;
    height: 1.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-SPB {
    width: 1.3rem;
    height: 1.3rem;
    fill: #000;
    margin: 0 0.1rem 0 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-RG {
    width: 1.8rem;
    height: 2rem;
    fill: #000;
    margin: 0 0.1rem 0 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PS {
    width: 1.8rem;
    height: 2rem;
    fill: #000;
    margin: 0 0.1rem 0 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-PS .st15 {
    fill: #000 !important;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-JDB,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-JDB2 {
    width: 1.29rem;
    height: 1rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-RG {
    width: 1.7rem;
    height: 2rem;
    fill: #000;
    margin: 0 0.1rem 0 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-YGG {
    width: 1.7rem;
    height: 2rem;
    fill: #000;
    margin: 0 0.1rem 0 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-AP {
    width: 1.7rem;
    height: 2rem;
    fill: #000;
    margin: 0 0.1rem 0 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-SPX {
    width: 1.7rem;
    height: 2rem;
    margin: 0 0.1rem 0 0;
    fill: #fff;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-SPX .black {
    fill: #000 !important;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-DRG {
    width: 1.7rem;
    height: 2rem;
    margin: 0 0.1rem 0 0;
    fill: #ceaa53;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-DS,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-DS-slot,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-EP,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-SW {
    width: 1.7rem;
    height: 2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-DS .cls-1,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-DS-slot .cls-1,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-EP .cls-1,
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-SW .cls-1 {
    fill: #020200;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-R88 {
    width: 1.7rem;
    height: 2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-WESLT {
    width: 2rem;
    height: 2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-EXS {
    width: 2rem;
    height: 2rem;
    fill: #000;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-VP {
    width: 1.4rem;
    height: 1.8rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .icon-FS {
    width: 2rem;
    height: 2rem;
    fill: #ef4136;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .content-title {
    display: block;
    width: 11.6rem;
    margin: 0 auto;
    padding-left: 0.24rem;
    height: 1rem;
    line-height: 1rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0.7rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li {
    cursor: pointer;
    width: 3.16rem;
    height: 2.6rem;
    transform: skew(-25deg);
    overflow: hidden;
    margin: 0 0.27rem;
    margin-bottom: 0.7rem;
    position: relative;
    background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 20%, rgb(253, 253, 253) 24%, rgb(249, 249, 249) 75%, rgb(249, 249, 249) 100%);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li .logo-box {
    transform: skew(25deg) translate(-0.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.12rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li .logo-box .icon-box .icon-HC {
    width: 3rem;
    height: 3rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li .logo-name {
    position: absolute;
    width: 120%;
    display: block;
    transform: skew(25deg) translateX(-50%);
    height: 0.48rem;
    background: #dec276;
    font-size: 0.29rem;
    text-align: center;
    line-height: 0.48rem;
    left: 50%;
    bottom: 0;
    font-weight: bold;
    color: #222;
    font-family: "AvenirNextLTPro";
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li .maintenance-hover-box {
    background: url(/public/html/images/icon/m-maintenance-en.svg);
    width: 155%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(245,245,245,.8509803922);
    position: absolute;
    margin-top: -2rem;
    margin: -2.1rem 0 0 -1.1rem;
    transform: skew(25deg);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box {
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 998;
    width: 100%;
    height: 100%;
    overflow: auto;
    outline: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.204919));
    animation: modalPopUp .3s;
    cursor: default;
  }

@keyframes modalPopUp {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
}

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding: 0 !important;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    max-width: 8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box p {
    font-size: 0.16rem;
    margin-bottom: 0.3rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-header {
    padding: 0.28rem 0.27rem 0.05rem;
    width: 100%;
    display: flex;
    align-items: center;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-header .box-header-right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #39b549;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-header .box-header-right svg {
    width: 0.35rem;
    height: 0.35rem;
    cursor: pointer;
    border: 0.02rem solid gray;
    border-radius: 50%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body {
    width: 100%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-icon {
    margin: auto;
    width: 15%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-title {
    display: flex;
    justify-content: center;
    border-bottom: 0.01rem solid #eaeaea;
    padding: 0.3rem;
    margin: 0 0.6rem;
    color: #0a974b;
    line-height: 0.18rem;
    font-weight: 700;
    font-size: 0.5rem;
    font-style: normal;
    font-family: "AvenirNextLTPro";
    white-space: nowrap;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details {
    margin: 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container {
    margin: 0.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container {
    display: grid;
    grid-template-columns: 48% 4% 48%;
    align-items: center;
    width: 100%;
    white-space: nowrap;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .dateColon {
    text-align: center;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 {
    text-align: left;
    width: 30%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 .key {
    width: 100%;
    font-size: 0.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.26rem;
    font-family: "AvenirNextLTPro";
    color: #424242;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 {
    text-align: right;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime {
    width: 100%;
    margin-left: 0.2rem;
    font-size: 0.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.26rem;
    font-family: "AvenirNextLTPro";
    color: #0a974b;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime:lang(vi) {
    font-variant: tabular-nums;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime.dateTimeNotice {
    position: absolute;
    margin-top: 0.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .slashColor {
    color: #0a974b;
    margin-left: 0.2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container {
    display: flex;
    flex-flow: column;
    margin: 0.6rem 2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    grid-gap: 0.3rem;
    padding: 0.05rem 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row1 {
    width: 100%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row2 {
    padding: 0.2rem 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer {
    background: #f0f0f0;
    border-radius: 0.05rem;
    width: 100%;
    height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #424242;
    font-weight: 700;
    font-style: normal;
    font-size: 0.3rem;
    line-height: 0.26rem;
    text-align: center;
    font-family: "AvenirNextLTPro";
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer-status {
    color: #424242;
    font-weight: 700;
    font-style: normal;
    font-size: 0.3rem;
    line-height: 0.26rem;
    text-align: center;
    font-family: "AvenirNextLTPro";
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box-overlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 0;
    overflow: hidden;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box {
    margin-top: 0.4rem;
    height: 1.56rem;
    display: flex;
    align-items: center;
    font-size: 0.4rem;
    font-weight: bold;
    color: #0a974b;
    padding-left: 0.38rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>i {
    cursor: pointer;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span {
    display: flex;
    align-items: center;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box {
    width: 1.28rem;
    height: 0.86rem;
    margin-left: 0.2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PT,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PT2,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PT3,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-SG,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-TTG,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-GP,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-BINL2,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-BINS2,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-SA,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-MGP,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-AG,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-AG2 {
    width: 0.75rem;
    height: 0.75rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PP,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-MEGA,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-NS,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-HB,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PP-slot,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-NS-slot,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-DS,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-DS-slot {
    width: 0.95rem;
    height: 0.95rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-UL {
    width: 1.03rem;
    height: 0.7rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-SCR2 {
    width: 1rem;
    height: 0.45rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PG {
    width: 0.9rem;
    height: 0.66rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-JDB,
  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-JDB2 {
    width: 0.65rem;
    height: 0.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-HC {
    width: 3rem;
    height: 3rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .back-box .icon-back {
    cursor: pointer;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 2.1rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li {
    width: 5.76rem;
    position: relative;
    height: 1.9rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li:nth-child(1) {
    order: 1;
    margin-right: 0.11rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li:nth-child(2) {
    order: 4;
    flex: none;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item {
    width: 100%;
    border: none;
    font-size: 0.4rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div {
    padding-left: 0.5rem;
    padding-right: 0.64rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div:nth-child(1) {
    background: #e3e3e3;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div:nth-child(2) {
    background: #f2f2f2;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .icon-arrow-down2 {
    width: 0.25rem;
    height: 0.18rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread {
    top: 1.9rem;
    width: 100%;
    height: 5.7rem;
    overflow-x: hidden;
    padding: 0.2rem 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread li {
    height: 1rem;
    padding-left: 0.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content {
    width: 11.7rem;
    margin-top: 0.6rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li {
    width: 2.64rem;
    height: 0.72rem;
    font-size: 0.24rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-name {
    padding-left: 0.24rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-box {
    width: 0.72rem;
    height: 0.72rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box {
    width: 100%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .loading-container {
    width: 100%;
    height: 100vh;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #000;
    transition: opacity .5s;
    z-index: 10;
    transform: scale(1);
    opacity: .5;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box {
    position: fixed;
    z-index: 11;
    width: 10.65rem;
    height: 8.65rem;
    background: #fff;
    transition: transform .5s;
    transform: translate(-50%, -50%) scale(1.1);
    left: 50%;
    top: 50%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .game-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 1.8rem;
    padding: 0 .65rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .game-item-info>p {
    font-size: .52rem;
    font-weight: 700;
    color: #222;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box {
    width: 9.4rem;
    height: 6.16rem;
    margin: 0 auto;
    position: relative;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box img {
    width: 100%;
    height: 100%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    position: absolute;
    top: 0;
    left: 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu .btn-normal,
  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu .btn-long,
  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu .btn-normal-green {
    margin-top: .96rem;
    width: 4.9rem;
    height: 1.16rem;
    font-size: .52rem !important;
    border-radius: .08rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu .btn-normal-green {
    margin-top: .44rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu div {
    margin-top: .88rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu div .icon-love-on {
    width: .98rem;
    height: .8rem;
    transform: scale(1) !important;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu div>span {
    margin-left: .48rem;
    font-size: .52rem !important;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .icon-close {
    width: .74rem;
    height: .74rem;
    position: absolute;
    right: .22rem;
    top: -0.22rem;
    transform: translateY(-100%);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game {
    width: 100%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li {
    width: 3.77rem;
    height: 3.26rem;
    margin-bottom: 0.28rem;
    border: 0.04rem solid #dad8d8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>img {
    height: 2.47rem;
    max-width: unset;
    max-height: unset;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(5n)) {
    margin-right: 0.19rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(3n)) {
    margin-right: 0.19rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:nth-child(3n) {
    margin-right: 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:hover .hover-box {
    position: fixed;
    z-index: 11;
    transform: translate(-50%, -50%) scale(0);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:hover .hover-box-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    transform: scale(0);
    background: #000;
    opacity: 0;
    transition: opacity .5s;
    z-index: 10;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li.on .hover-box {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li.on .hover-box-mask {
    transform: scale(1);
    opacity: .5;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p {
    height: 0.79rem;
    line-height: 0.79rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p .icon-love,
  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p .icon-love-on {
    transform: scale(2);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p .gamename {
    font-size: 0.23rem;
    left: 0.8rem;
    line-height: 0.35rem;
    width: 2.7rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box {
    position: fixed;
    z-index: 11;
    width: 10.65rem;
    height: 8.65rem;
    background: #fff;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .game-item-info {
    height: 1.8rem;
    padding: 0 0.65rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .game-item-info>p {
    font-size: 0.52rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .game-item-info>div {
    font-size: 0.46rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box {
    width: 9.4rem;
    height: 6.16rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu .btn-normal,
  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu .btn-normal-green,
  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu .btn-long {
    margin-top: 0.96rem;
    width: 4.9rem;
    height: 1.16rem;
    font-size: 0.52rem !important;
    border-radius: 0.08rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu .btn-normal-green {
    margin-top: 0.44rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu div {
    margin-top: 0.88rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu div>span {
    margin-left: 0.48rem;
    font-size: 0.52rem !important;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu div .icon-love,
  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu div .icon-love-on {
    width: 0.98rem;
    height: 0.8rem;
    transform: scale(1) !important;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box img {
    width: 100%;
    height: 100%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .icon-close {
    width: 0.74rem;
    height: 0.74rem;
    position: absolute;
    right: 0.22rem;
    top: -0.22rem;
    transform: translateY(-100%);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    transform: scale(0);
    background: #000;
    opacity: 0;
    transition: opacity .5s;
    z-index: 10;
  }

  .slots .content-section .slots-content-section>li.content-promotions {
    display: none;
  }

  .slots .content-section .slots-content-section .loading svg#logo {
    width: 7rem;
  }

  .slots .brand-list .badge::before {
    content: "";
  }

  .slots .brand-list .badge-hot .logo-box::before,
  .slots .brand-list .badge-hot .logo-box::after {
    content: "hot";
  }

  .slots .brand-list .badge-hot .logo-box::before {
    background-color: #ff3d00;
  }

  .slots .brand-list .badge-new .logo-box::before,
  .slots .brand-list .badge-new .logo-box::after {
    content: "new";
  }

  .slots .brand-list .badge-new .logo-box::before {
    background-color: #19733d;
  }

  .slots .brand-list .badge .logo-box::before,
  .slots .brand-list .badge .logo-box::after {
    position: absolute;
    bottom: 0.02rem;
    right: 0;
    padding: 0 0.08rem;
    font-size: 0.2rem;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }

  .slots .brand-list .badge .logo-box::before {
    color: rgba(0,0,0,0);
    transform: skew(-25deg);
  }
}

@media (max-width: 575px) {
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box {
    max-width: 10.62rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-header .box-header-right svg {
    width: 0.58rem;
    height: 0.58rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-icon {
    margin: auto;
    width: 15%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-title {
    font-size: 0.5rem;
    padding: 0.7rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details {
    margin: 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container {
    margin: 0.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 .key {
    font-size: 0.3rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 {
    font-size: 0.3rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer {
    font-size: 0.3rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer-status {
    font-size: 0.3rem;
  }
}

.small-loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-banner .banner {
  height: 5.5rem;
  width: 20rem;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.download-banner .banner .banner-item {
  width: 100%;
  height: 100%;
  position: relative;
}

.download-banner .banner .content-container {
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}

.download-banner .banner img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: auto;
}

.download-banner .banner:nth-child(1) .slide-text-box {
  position: absolute;
  left: 0.7rem;
  top: 1.06rem;
}

.download-banner .banner:nth-child(1) .slide-text-box>p {
  font-style: italic;
}

.download-banner .banner:nth-child(1) .slide-text-box>p:nth-child(3) {
  margin-top: 0.25rem;
}

.download-banner .banner:nth-child(1) .to-claim {
  position: absolute;
  left: 3.43rem;
  bottom: 0.7rem;
  font-family: Roboto;
  font-weight: bold;
  font-style: italic;
  color: #222;
  font-size: 0.24rem;
}

.content-download .content-center-box {
  padding-top: 0.55rem;
  padding-bottom: 1rem;
}

.content-download .download-nav-tab {
  display: flex;
  align-items: center;
  height: 0.4rem;
  overflow: hidden;
  margin-bottom: 0.1rem;
  background-color: #ebebeb;
}

.content-download .download-nav-tab .download-tab-item {
  width: 2.5rem;
  height: 100%;
  cursor: pointer;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.4rem;
  margin-left: -0.13rem;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .download-header-text {
  margin-right: 0.17rem;
  z-index: 1;
  color: #b0b0b0;
  font-size: 0.16rem;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-ios {
  width: 0.16rem;
  height: 0.2rem;
  z-index: 1;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-ios.on {
  fill: #fff;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-ios.off {
  fill: #424242;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-android {
  width: 0.2rem;
  height: 0.25rem;
  z-index: 1;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-android.on {
  fill: #fff;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-android.off {
  fill: #85c808;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-app {
  width: 0.25rem;
  height: 0.25rem;
  z-index: 1;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-app.on {
  fill: #fff;
}

.content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-app.off {
  fill: #85c808;
}

.content-download .download-nav-tab .download-tab-item .active .download-tab-inner {
  position: relative;
  transform: skew(-35deg);
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
}

.content-download .download-nav-tab .download-tab-item .active .download-tab-inner .download-header-text,
.content-download .download-nav-tab .download-tab-item .active .download-tab-inner .icon-ios,
.content-download .download-nav-tab .download-tab-item .active .download-tab-inner .icon-android,
.content-download .download-nav-tab .download-tab-item .active .download-tab-inner .icon-app {
  color: #000;
  transform: skew(35deg);
}

.content-download .download-nav-tab .download-tab-item .active .download-tab-inner .ios-text {
  color: #000;
}

.download-tab-content {
  background: #f5f5f5;
}

.download-tab-content .download-tabpane ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.3rem;
  padding-bottom: 0;
}

.download-tab-content .download-tabpane ul .provider-img {
  height: 100%;
  width: 100%;
}

.download-tab-content .download-tabpane .app-top-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.download-tab-content .download-tabpane .app-top-container .left-container {
  width: 50%;
  margin-right: 0.5rem;
}

.download-tab-content .download-tabpane .app-top-container .left-container img {
  width: 100%;
}

.download-tab-content .download-tabpane .app-top-container .right-container {
  width: 50%;
}

.download-tab-content .download-tabpane .app-top-container .right-container .title {
  font-family: "AvenirNextLTPro-Bold";
  font-size: .4rem;
  margin: 0 0 0.1rem 0;
}

html:lang(vi) .download-tab-content .download-tabpane .app-top-container .right-container .title {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.download-tab-content .download-tabpane .app-top-container .right-container .desc {
  font-size: .2rem;
  font-family: "AvenirNextLTPro";
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container {
  margin-top: 0.35rem;
  display: flex;
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container:first-child {
  margin-right: 0.5rem;
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-device-qr-container {
  margin: 0 0 0.2rem 0;
  width: 2rem;
  height: 2rem;
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-device-qr-container .download-device-qr {
  padding: 0.15rem;
  background: #fff;
  width: 100%;
  height: 100%;
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.05rem;
  padding: 0.1rem;
  color: #fff;
  cursor: pointer;
  width: 2rem;
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-btn-container svg {
  width: .25rem;
  margin-right: 0.075rem;
  fill: #fff;
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .android {
  background: #a4c639;
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .ios {
  background: #2a2a2a;
}

.download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .instruction-btn {
  margin: 0.05rem 0 0 0;
  cursor: pointer;
  text-decoration: underline;
}

.download-tab-content .download-tabpane .flip-card {
  background-color: rgba(0,0,0,0);
  height: 2.77rem;
  width: 2.62rem;
  margin-right: 0.16rem;
  margin-bottom: 0.3rem;
}

.download-tab-content .download-tabpane .flip-card:nth-child(5n) {
  margin-right: 0;
}

.download-tab-content .download-tabpane .flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  width: 100%;
}

.download-tab-content .flip-card {
  background-color: rgba(0,0,0,0);
  height: 2.77rem;
  width: 2.62rem;
  margin-right: 0.16rem;
  margin-bottom: 0.34rem;
  overflow: hidden;
}

.download-tab-content .flip-card:nth-child(5n) {
  margin-right: 0;
}

.download-tab-content .flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform .5s;
}

.download-tab-content .flip-card:hover .flip-card-inner .flip-card-back {
  transition: transform .5s;
  transform: translateY(0);
  z-index: 1;
}

.download-tab-content .maintenance-box {
  position: absolute;
  height: 100%;
  width: 100%;
  background: hsla(0,0%,100%,.8);
}

.download-tab-content .flip-card:hover .flip-card-inner .maintenance-flip-card-back {
  display: none;
}

.download-tab-content .flip-card-front,
.download-tab-content .flip-card-back {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.download-tab-content .flip-card-front {
  background-color: #bbb;
  color: #000;
}

.download-tab-content .flip-card-front .game-android,
.download-tab-content .flip-card-front .game-ios {
  position: absolute;
  top: 0.16rem;
  right: 0.2rem;
  z-index: 1;
  fill: #85c807;
  width: 0.27rem;
  height: 0.34rem;
}

.download-tab-content .flip-card-front .game-ios {
  fill: #858689;
}

.download-tab-content .flip-card-back {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0.01rem 0.08rem 0 rgba(0,0,0,.3);
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0;
  transition: transform .5s;
}

.download-tab-content .flip-card-back .download-provider-scr {
  width: 0.93rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-provider-ab {
  width: 0.92rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-provider-idn {
  width: 1.37rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-provider-gp {
  width: 1.31rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-provider-dg {
  width: 1rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-provider-ag {
  width: 1.08rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-provider-pt,
.download-tab-content .flip-card-back .download-provider-pt2,
.download-tab-content .flip-card-back .download-provider-mega,
.download-tab-content .flip-card-back .download-provider-wm,
.download-tab-content .flip-card-back .download-provider-joker {
  width: 1.44rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-provider-ebet {
  width: 1rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-provider-psy8 {
  width: 1.3rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-provider-hc {
  width: 1.03rem;
  height: auto;
}

.download-tab-content .flip-card-back .download-qr {
  max-width: 0.99rem;
  max-height: 0.99rem;
  min-height: 0.99rem;
  min-width: 0.99rem;
  padding: 0.02rem;
  border: 0.01rem solid #000;
}

.download-tab-content .flip-card-back .download-qr .download-qr-img {
  width: 100% !important;
  height: 100% !important;
}

.download-tab-content .flip-card-back .btn-download {
  border: 0;
  width: 1.18rem;
  height: 0.35rem;
  border-radius: 0.03rem;
  font-size: 0.16rem;
  background: #fbba13;
}

@media (max-width: 1445px) {
  .content-download .content-center-box {
    padding-top: 0.33rem;
  }

  .content-download .download-nav-tab {
    height: 0.4rem;
    overflow: hidden;
    margin-bottom: 0.11rem;
    background-color: #ebebeb;
  }

  .content-download .download-nav-tab .download-tab-item {
    height: 100%;
    cursor: pointer;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner {
    height: 0.4rem;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .download-header-text {
    font-size: 0.16rem;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-ios {
    width: 0.16rem;
    height: 0.2rem;
    z-index: 1;
    fill: #fff;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-android {
    width: 0.2rem;
    height: 0.25rem;
    z-index: 1;
    fill: #659b00;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-app {
    width: 0.25rem;
    height: 0.35rem;
  }

  .download-tab-content .download-tabpane ul {
    padding: 0.31rem;
  }

  .download-tab-content .download-tabpane ul .provider-img {
    height: 100%;
    width: 100%;
  }

  .download-tab-content .download-tabpane .app-top-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .download-tab-content .download-tabpane .app-top-container .left-container {
    width: 50%;
    margin-right: 0.5rem;
  }

  .download-tab-content .download-tabpane .app-top-container .left-container img {
    width: 100%;
  }

  .download-tab-content .download-tabpane .app-top-container .right-container {
    width: 50%;
  }

  .download-tab-content .download-tabpane .app-top-container .right-container .title {
    font-size: .4rem;
  }

  .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container {
    margin-top: 0.3rem;
    display: flex;
  }

  .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container:first-child {
    margin-right: 0.5rem;
  }

  .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-device-qr-container {
    margin: 0 0 0.2rem 0;
    width: 2.5rem;
    height: 2.5rem;
  }

  .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.05rem;
    padding: 0.1rem;
    color: #fff;
    cursor: pointer;
    width: 2.5rem;
  }

  .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-btn-container svg {
    width: .25rem;
    margin-right: .1rem;
    fill: #fff;
  }

  .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .instruction-btn {
    cursor: pointer;
    text-decoration: underline;
  }

  .download-tab-content .download-tabpane .flip-card {
    background-color: rgba(0,0,0,0);
    height: 2.77rem;
    width: 2.62rem;
    margin-right: 0.16rem;
    margin-bottom: 0.31rem;
  }

  .download-tab-content .download-tabpane .flip-card:nth-child(5n) {
    margin-right: 0;
  }

  .download-tab-content .flip-card {
    background-color: rgba(0,0,0,0);
    height: 2.77rem;
    width: 2.62rem;
    margin-right: 0.16rem;
    margin-bottom: 0.34rem;
    overflow: hidden;
  }

  .download-tab-content .flip-card:nth-child(5n) {
    margin-right: 0;
  }

  .download-tab-content .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform .5s;
  }

  .download-tab-content .flip-card:hover .flip-card-inner .flip-card-back {
    transition: transform .5s;
    transform: translateY(0);
    z-index: 1;
  }

  .download-tab-content .flip-card-front,
  .download-tab-content .flip-card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .download-tab-content .flip-card-front .game-android,
  .download-tab-content .flip-card-front .game-ios {
    position: absolute;
    top: 0.16rem;
    right: 0.2rem;
    z-index: 1;
    width: 0.27rem;
    height: 0.34rem;
  }

  .download-tab-content .flip-card-back {
    padding: 0.3rem 0;
    transition: transform .5s;
  }

  .download-tab-content .flip-card-back .download-provider-scr {
    width: 0.93rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-provider-ab {
    width: 0.92rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-provider-idn {
    width: 1.37rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-provider-gp {
    width: 1.31rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-provider-dg {
    width: 1rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-provider-ag {
    width: 1.08rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-provider-pt,
  .download-tab-content .flip-card-back .download-provider-pt2,
  .download-tab-content .flip-card-back .download-provider-mega {
    width: 1.44rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-provider-ebet {
    width: 1rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-provider-psy8 {
    width: 1.3rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-provider-hc {
    width: 1.03rem;
    height: auto;
  }

  .download-tab-content .flip-card-back .download-qr {
    max-width: 0.99rem;
    max-height: 0.99rem;
    min-height: 0.99rem;
    min-width: 0.99rem;
    padding: 0.02rem;
    border: 0.01rem solid #000;
  }

  .download-tab-content .flip-card-back .download-qr .download-qr-img {
    width: 100% !important;
    height: 100% !important;
  }

  .download-tab-content .flip-card-back .btn-download {
    border: 0;
    width: 1.18rem;
    height: 0.35rem;
    border-radius: 0.03rem;
    font-size: 0.16rem;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .content-download .download-nav-tab {
    height: 0.65rem;
    margin-bottom: 0.11rem;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner {
    height: 0.65rem;
    margin-left: -0.2rem;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .download-header-text {
    font-size: 0.24rem;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-ios {
    width: 0.22rem;
    height: 0.28rem;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-android {
    width: 0.24rem;
    height: 0.29rem;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-app {
    width: 0.35rem;
    height: 0.45rem;
  }

  .content-download .download-tab-content .download-tabpane ul {
    min-height: 5.87rem;
    padding: 0.31rem;
  }

  .content-download .download-tab-content .download-tabpane ul .provider-img {
    height: 100%;
    width: 100%;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .left-container {
    width: 50%;
    margin-right: 0.5rem;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .left-container img {
    width: 100%;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container {
    width: 50%;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .title {
    font-size: .45rem;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .desc {
    font-size: .3rem;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container {
    margin-top: 0.5rem;
    display: flex;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container:first-child {
    margin-right: 0.5rem;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.05rem;
    padding: 0.1rem;
    color: #fff;
    cursor: pointer;
    width: 3rem;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-btn-container svg {
    width: .4rem;
    margin-right: .2rem;
    fill: #fff;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .instruction-btn {
    cursor: pointer;
    text-decoration: underline;
  }

  .content-download .download-tab-content .download-tabpane .flip-card {
    background-color: rgba(0,0,0,0);
    height: 3.9rem;
    width: 3.5rem;
    margin-right: 0.16rem;
    margin-bottom: 0.34rem;
  }

  .content-download .download-tab-content .download-tabpane .flip-card:nth-child(5n) {
    margin-right: 0.16rem;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform .8s;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-front,
  .content-download .download-tab-content .download-tabpane .flip-card-back {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-front .game-android,
  .content-download .download-tab-content .download-tabpane .flip-card-front .game-ios {
    position: absolute;
    top: 0.2rem;
    right: 0.25rem;
    width: 0.4rem;
    height: 0.54rem;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-front .game-ios {
    fill: #858689;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back {
    box-shadow: 0 0.01rem 0.08rem 0 rgba(0,0,0,.3);
    padding: 0.3rem 0;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-scr {
    width: 1.23rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-ab {
    width: 1.42rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-idn {
    width: 1.67rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-gp {
    width: 1.61rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-dg {
    width: 1.5rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-ag {
    width: 1.38rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-pt,
  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-pt2,
  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-mega {
    width: 1.74rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-ebet {
    width: 1.3rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-psy8 {
    width: 1.6rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-hc {
    width: 1.339rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-qr {
    max-width: 1.4rem;
    max-height: 1.4rem;
    min-height: 1.4rem;
    min-width: 1.4rem;
    padding: 0.02rem;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-qr .download-qr-img {
    width: 100% !important;
    height: 100% !important;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .btn-download {
    border: 0;
    width: 1.6rem;
    height: 0.45rem;
    border-radius: 0.03rem;
    font-size: 0.21rem;
  }
}

@media (max-width: 768px) {
  .download-banner .banner {
    height: 4.68rem;
  }

  .content-download .content-center-box {
    padding: 0.72rem 0.77rem;
  }

  .content-download .download-nav-tab {
    display: flex;
    align-items: center;
    height: 0.8rem;
    overflow: hidden;
    margin-bottom: 0.55rem;
    background-color: #ebebeb;
  }

  .content-download .download-nav-tab .download-tab-item {
    width: 50%;
    cursor: pointer;
  }

  .content-download .download-nav-tab .download-tab-item.app-tab-existed {
    width: 35%;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.8rem;
    margin-left: -0.26rem;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .download-header-text {
    margin-right: 0.15rem;
    z-index: 1;
    color: #b0b0b0;
    font-size: 0.325rem;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-ios {
    width: 0.38rem;
    height: 0.47rem;
    z-index: 1;
    fill: #fff;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-android {
    width: 0.43rem;
    height: 0.54rem;
    z-index: 1;
    fill: #659b00;
  }

  .content-download .download-nav-tab .download-tab-item .nav-link .download-tab-inner .icon-app {
    width: 0.6rem;
    height: 0.6rem;
  }

  .content-download .download-nav-tab .download-tab-item .active .download-tab-inner {
    position: relative;
    transform: skew(-35deg);
    background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
    height: 0.82rem;
    margin-right: -0.25rem;
    padding: 0 0 0 0.1rem;
  }

  .content-download .download-nav-tab .download-tab-item .active .download-tab-inner .download-header-text,
  .content-download .download-nav-tab .download-tab-item .active .download-tab-inner .icon-ios,
  .content-download .download-nav-tab .download-tab-item .active .download-tab-inner .icon-app,
  .content-download .download-nav-tab .download-tab-item .active .download-tab-inner .icon-android {
    color: #000;
    transform: skew(35deg);
  }

  .content-download .download-nav-tab .download-tab-item .active .download-tab-inner .ios-text {
    color: #000;
  }

  .content-download .download-tab-content {
    background: #fff;
  }

  .content-download .download-tab-content .download-tabpane ul {
    display: flex;
    flex-wrap: wrap;
    min-height: 5.87rem;
    padding: 0;
  }

  .content-download .download-tab-content .download-tabpane ul .provider-img {
    height: 100%;
    width: 100%;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .left-container {
    width: 75%;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .left-container img {
    width: 100%;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .title {
    font-size: .5rem;
    margin: 0 0 0.2rem 0;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .desc {
    font-size: .4rem;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container {
    margin-top: 0.5rem;
    display: flex;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .35rem;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container:first-child {
    margin-right: 0.75rem;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.05rem;
    padding: 0.1rem;
    color: #fff;
    cursor: pointer;
    width: 3rem;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .download-btn-container svg {
    width: .35rem;
    margin-right: .1rem;
    fill: #fff;
  }

  .content-download .download-tab-content .download-tabpane .app-top-container .right-container .app-download-container .download-device-container .instruction-btn {
    margin: 0.1rem 0 0 0;
    font-size: .3rem;
    cursor: pointer;
    text-decoration: underline;
  }

  .content-download .download-tab-content .download-tabpane .flip-card {
    background-color: rgba(0,0,0,0);
    height: 5.56rem;
    width: 5.26rem;
    margin-bottom: 0.66rem;
  }

  .content-download .download-tab-content .download-tabpane .flip-card:nth-child(odd) {
    margin-right: 0.32rem;
  }

  .content-download .download-tab-content .download-tabpane .flip-card:nth-child(even) {
    margin-right: 0;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform .8s;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-front,
  .content-download .download-tab-content .download-tabpane .flip-card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-front {
    background-color: #bbb;
    color: #000;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-front .game-android,
  .content-download .download-tab-content .download-tabpane .flip-card-front .game-ios {
    position: absolute;
    top: 0.34rem;
    right: 0.41rem;
    z-index: 1;
    fill: #85c807;
    width: 0.55rem;
    height: 0.69rem;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-front .game-ios {
    fill: #858689;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back {
    background-color: #fff;
    color: #000;
    box-shadow: 0 0.01rem 0.08rem 0 rgba(0,0,0,.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-scr {
    width: 2.43rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-ab {
    width: 2.22rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-idn {
    width: 2.67rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-gp {
    width: 2.61rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-dg {
    width: 2.2rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-ag {
    width: 2.58rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-pt,
  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-pt2,
  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-mega {
    width: 2.64rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-ebet {
    width: 1.85rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-psy8 {
    width: 2.15rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-provider-hc {
    width: 1.9055rem;
    height: auto;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-qr {
    max-width: 2.6rem;
    max-height: 2.6rem;
    min-height: 2.6rem;
    min-width: 2.6rem;
    padding: 0.02rem;
    border: 0.01rem solid #000;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .download-qr .download-qr-img {
    width: 100% !important;
    height: 100% !important;
  }

  .content-download .download-tab-content .download-tabpane .flip-card-back .btn-download {
    border: 0;
    width: 2.8rem;
    height: 0.6rem;
    border-radius: 0.03rem;
    font-size: 0.3rem;
  }
}

.apply-hide {
  display: none !important;
}

.apply-show {
  display: block;
}

.btn-promo-details span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 0.35rem;
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  border-radius: 0.02rem;
  cursor: pointer;
  color: #222;
  font-size: 0.16rem;
  font-family: "Roboto-Bold";
}

html:lang(vi) .btn-promo-details span {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: uppercase;
}

.btn-promo-apply {
  width: 100%;
}

.btn-promo-apply span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 0.35rem;
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  border-radius: 0.02rem;
  cursor: pointer;
  color: #222;
  font-size: 0.16rem;
  text-transform: uppercase;
  font-family: "Roboto-Bold";
}

html:lang(vi) .btn-promo-apply span {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: uppercase;
}

.modal-window {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  display: none;
  transition: all .3s;
}

.modal-window.open {
  display: block;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 0.15rem;
  color: #424242;
}

.search-box {
  padding-top: 0.28rem;
  margin-bottom: 0.15rem !important;
}

.search-item-outer {
  overflow: hidden;
  text-align: right;
}

.search-item {
  margin-top: 0.12rem;
  display: inline-block;
  width: 3.07rem;
  height: 0.42rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  font-size: 0.12rem;
  color: #858585;
  background: #e3e3e3;
}

.search-item>div {
  height: 100%;
  transform: skew(25deg);
  display: flex;
  align-items: center;
  padding-left: 0.23rem;
}

.search-item>div .icon-search {
  margin-right: 0.1rem;
}

.search-item>div input {
  border: none;
  background: rgba(0,0,0,0);
  width: 2.5rem;
}

.search-item>div input::-webkit-input-placeholder {
  color: #858585;
}

.favourite-container .content-top {
  padding: 0 0.14rem 0.25rem;
}

.favourite-container .content-promotions .content-top {
  padding: 0.33rem 0.14rem 0.21rem;
}

.favourite-container .content-section .content-recommended {
  margin-bottom: 0.77rem !important;
}

.favourite-container .content-section .content-recommended .slick-prev-btn {
  left: -0.55rem;
}

.favourite-container .content-section .content-recommended .slick-next-btn {
  right: -0.35rem;
}

.favourite-container .content-section .content-recommended .slick-dots {
  top: -1rem;
  right: -0.9rem;
}

.favourite-container .content-section .content-recommended .content-center {
  width: 100%;
  padding: .48rem 1.07rem;
  background: #f5f5f5;
}

.favourite-container .content-section .content-recommended .favourite-flow {
  padding: 0.48rem 1.07rem 0.24rem;
}

.content-section .content-recommended .favourite-content {
  height: auto;
}

.content-section .content-recommended .favourite-flow {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  height: auto;
}

.content-section .content-recommended .favourite-flow .nav-game {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 2.45rem;
}

.content-section .content-recommended .slot-favourite-flow {
  flex-flow: unset;
}

.provider-content-section {
  margin-top: 0.2rem;
}

.provider-content-section .content-provider .content-center {
  padding: 0.4rem 0.45rem 0.1rem;
}

.provider-content-section .content-provider .content-center .provider-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.provider-content-section .content-provider .content-center .provider-box .maintenance-hover-box {
  background: url(/public/html/images/icon/maintenance-en.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: hsla(0,0%,100%,.737254902);
  position: absolute;
  margin-top: -2.9rem;
  background-size: 2.5rem;
}

.provider-content-section .content-provider .content-center .provider-box.casino-provider-box {
  display: grid;
  grid-template-columns: 3.45rem 3.45rem 3.45rem 3.45rem;
}

.provider-content-section .content-provider .content-center .provider-box.casino-provider-box .maintenance-hover-box {
  background: url(/public/html/images/icon/maintenance-en.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: hsla(0,0%,100%,.737254902);
  position: absolute;
  margin-top: -2.9rem;
  background-size: 2.5rem;
}

.provider-content-section .content-provider .content-center .provider-box.casino-provider-box li:first-child {
  width: 6.6rem;
  height: 6.02rem;
  grid-column: 1/span 2;
  grid-row: 1/span 2;
  margin-top: -0.15rem;
}

.provider-content-section .content-provider .content-center .provider-box.casino-provider-box li:first-child img {
  height: 99.5%;
}

.provider-content-section .content-provider .content-center .provider-box.casino-provider-box li:first-child .maintenance-hover-box {
  background: url(/public/html/images/icon/maintenance-en.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: hsla(0,0%,100%,.737254902);
  position: absolute;
  margin-top: -6.1rem;
  background-size: 5rem;
}

.provider-content-section .content-provider .content-center .provider-box.casino-provider-box li:first-child .hover-box .content-box>p {
  width: 62%;
  margin: .1rem auto;
}

@media (max-width: 768px) {
  .provider-content-section .content-provider .content-center .provider-box.casino-provider-box li:first-child .hover-box .content-box>p {
    width: 68%;
    margin: .3rem auto;
  }
}

.provider-content-section .content-provider .content-center .provider-box.casino-provider-box li:first-child .hover-box .content-box .icon-box {
  margin: 0.1rem 0 0.3rem;
}

@media (max-width: 768px) {
  .provider-content-section .content-provider .content-center .provider-box.casino-provider-box li:first-child .hover-box .content-box .icon-box {
    margin: .4rem 0 0;
  }
}

.provider-content-section .content-provider .content-center .provider-box li {
  width: 3.14rem;
  height: 2.88rem;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-bottom: 0.4rem;
  margin-right: 0.3rem;
  margin-top: -0.14rem;
  transition: .2s;
}

.provider-content-section .content-provider .content-center .provider-box li:hover {
  transform: scale(1.05);
}

.provider-content-section .content-provider .content-center .provider-box li img {
  width: 100%;
  height: auto;
  border: 0.05rem solid #e6e6e6;
  outline: 0.06rem solid #e6e6e6;
  outline-offset: -0.06rem;
  margin-top: 0.02rem;
}

.provider-content-section .content-provider .content-center .provider-box li .hover-box {
  transition: transform .5s;
  transform: translateY(100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  background: hsla(0,0%,100%,.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0;
  z-index: 1;
}

@media (max-width: 768px) {
  .provider-content-section .content-provider .content-center .provider-box li .hover-box {
    justify-content: space-between;
  }
}

.provider-content-section .content-provider .content-center .provider-box li .hover-box span {
  font-size: 0.24rem;
  font-weight: bold;
  color: #222;
  text-align: center;
}

.provider-content-section .content-provider .content-center .provider-box li .hover-box .content-box {
  text-align: center;
}

@media (max-width: 768px) {
  .provider-content-section .content-provider .content-center .provider-box li .hover-box .content-box {
    flex: 1;
  }
}

.provider-content-section .content-provider .content-center .provider-box li .hover-box .content-box>p {
  font-size: 0.14rem;
  color: #222;
  margin: 0.1rem 0;
  padding: 0 0.1rem;
}

.provider-content-section .content-provider .content-center .provider-box li .hover-box .content-box .icon-box {
  margin: 0.1rem 0 0.2rem;
}

.provider-content-section .content-provider .content-center .provider-box li .hover-box .btn-normal,
.provider-content-section .content-provider .content-center .provider-box li .hover-box .btn-normal-green,
.provider-content-section .content-provider .content-center .provider-box li .hover-box .btn-long {
  font-size: 0.16rem;
}

.provider-content-section .content-provider .content-center .provider-box li:hover .hover-box {
  transform: translateY(0);
}

.provider-content-section .content-provider .content-center .provider-box li:nth-child(4n) {
  margin-right: 0;
}

.provider-content-section .content-provider .content-center .provider-box li.provider-disable {
  opacity: .4;
}

.provider-content-section .content-provider .content-center .provider-box li .vpn-icon {
  position: absolute;
  bottom: .2rem;
  right: .2rem;
  border: 0;
  outline: 0;
  margin-top: 0;
  height: .33rem;
  width: .69rem;
  z-index: 0;
}

.provider-content-section .content-provider .content-center .provider-box .icon-GG {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-gg-icon.png") no-repeat;
  width: 1.08rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-GD {
  display: inline-block;
  width: 1.08rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-PPL {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ppl-icon.png") no-repeat;
  width: 1.29rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-JKR {
  display: inline-block;
  width: 1.08rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-DG {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-dg-icon.png") no-repeat;
  width: 0.8rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-AB,
.provider-content-section .content-provider .content-center .provider-box .icon-AB2 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ab-icon.png") no-repeat;
  width: 0.92rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-EVO {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-evo-icon.png") no-repeat;
  width: 2rem;
  height: 0.48rem;
  background-size: 100% 100%;
}

@media (max-width: 768px) {
  .provider-content-section .content-provider .content-center .provider-box .icon-EVO {
    width: 2rem;
    height: 0.5rem;
    margin: .5rem 0 .2rem;
  }
}

.provider-content-section .content-provider .content-center .provider-box .icon-BG {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-bg-icon.png") no-repeat;
  width: 1.9rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-GP {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-gp-icon.png") no-repeat;
  width: 1.46rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-BINL2,
.provider-content-section .content-provider .content-center .provider-box .icon-BINS2 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-bbin-icon.png") no-repeat;
  background: none;
  width: 1.46rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-AG,
.provider-content-section .content-provider .content-center .provider-box .icon-AG2 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ag-icon.png") no-repeat;
  width: 1.38rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-EBET {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ebet-icon.png") no-repeat;
  width: 1.2rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-PTL,
.provider-content-section .content-provider .content-center .provider-box .icon-PT3,
.provider-content-section .content-provider .content-center .provider-box .icon-PT,
.provider-content-section .content-provider .content-center .provider-box .icon-PT2 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-pt-icon.png") no-repeat;
  width: 1.32rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-YBL {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ybl-icon.svg") center no-repeat;
  width: 1.32rem;
  height: 0.52rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-W {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-w-icon.svg") center no-repeat;
  width: 0.58rem;
  height: 0.51rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-WE {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-we-icon.svg") center no-repeat;
  width: 1.2rem;
  height: 0.5rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-WE.icon-custom-WE_Rebrand {
  background: url("/public/html/images/provider_logo/096-we-rebrand-icon.svg") center no-repeat;
}

.provider-content-section .content-provider .content-center .provider-box .icon-UG,
.provider-content-section .content-provider .content-center .provider-box .icon-AES,
.provider-content-section .content-provider .content-center .provider-box .icon-AES2,
.provider-content-section .content-provider .content-center .provider-box .icon-JL {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ug-icon.png") no-repeat;
  width: 1.3rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-MGP {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-mg-icon.png") no-repeat;
  width: 1.47rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-WM {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-wm-icon.png") no-repeat;
  width: 1.3rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-SA {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-sa-icon.png") no-repeat;
  width: 1.61rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-GG {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-gg-icon.png") no-repeat;
  width: 1.43rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-SG {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-sg-icon.svg") no-repeat;
  width: 2rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-MT {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-mt-icon.svg") no-repeat;
  width: 1rem;
  height: 1rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-YGR {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ygr-icon.svg") center no-repeat;
  width: 1.2rem;
  height: 0.5rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-QQK {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-qqk-icon.svg") no-repeat;
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-GP {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-gp-icon.png") no-repeat;
  width: 1.45rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-BINL2,
.provider-content-section .content-provider .content-center .provider-box .icon-BINS2 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-bbin-icon.png") no-repeat;
  width: 0.7rem;
  height: 0.7rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-WWL {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-wwl-icon.svg") no-repeat;
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-lottery-GW {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-gw-icon.svg") no-repeat;
  width: 1rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-lottery-QQK {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-qqk-icon.svg") center no-repeat;
  width: 2rem;
  height: 0.5rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-lottery-GP {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-gp-icon.png") no-repeat;
  width: 2rem;
  height: 1rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-lottery-WWL {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-wwl-icon.svg") no-repeat;
  width: 2rem;
  height: 1rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-lottery-TCGA {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-tcg-icon.svg") center no-repeat;
  width: 2rem;
  height: 0.5rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-lottery-TCG {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-tcg-icon.svg") no-repeat;
  width: 2rem;
  height: 1rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-JL {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-jl-icon.svg") no-repeat;
  width: 1.2rem;
  height: 0.48rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-NS {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ns-icon.png") no-repeat;
  width: 2rem;
  height: 0.48rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-CQ9 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-cq9-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.8rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-FC {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-fc-icon.svg") center no-repeat;
  width: 2.5rem;
  height: 0.55rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-PS {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ps-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-CMD {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-cmd-icon.png") no-repeat;
  width: 1.3rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-IME {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ime-icon.png") no-repeat;
  width: 1.3rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-TF {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-tf-icon.svg") center no-repeat;
  width: 1.9rem;
  height: 0.5rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-IBC {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ibc-icon.svg") center no-repeat;
  width: 1.65rem;
  height: 0.65rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-SBO {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-sbo-icon.png") no-repeat;
  width: 1.43rem;
  height: 0.31rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-BR {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-br-icon.png") no-repeat;
  width: 1.59rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-BTI {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-bti-icon.svg") no-repeat;
  width: 1.12rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-M8 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-m8-icon.png") no-repeat;
  width: 1.13rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-GDL,
.provider-content-section .content-provider .content-center .provider-box .icon-lottery-GDL {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-gdl-icon.svg") center no-repeat;
  width: 2rem;
  height: 0.55rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-lottery-FG {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-funkygame-icon.svg") no-repeat;
  width: 2rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-DIG {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-dig-icon.svg") no-repeat;
  width: 2rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-WS {
  display: inline-block;
  width: 2rem;
  height: 0.6rem;
  background: url("/public/html/images/provider_logo/096-ws-icon.svg") no-repeat;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-CQ9 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-cq9-icon.svg") no-repeat;
  width: 2rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-JDB {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-jdb-icon.svg") center no-repeat;
  width: 0.9rem;
  height: 0.5rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-JDB2 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-jdb2-icon.svg") center no-repeat;
  width: 0.9rem;
  height: 0.5rem;
  background-size: cover;
}

.provider-content-section .content-provider .content-center .provider-box .icon-SPX {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-spx-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-DS {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-ds-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-DRG {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-drg-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-EXS {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-exs-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-VP {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-vp-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-VC {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-vc-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-FS {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-fs-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-SW {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-sw-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-R88 {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-r88-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .icon-SW {
  display: inline-block;
  background: url("/public/html/images/provider_logo/096-weslt-icon.svg") no-repeat;
  width: 2.5rem;
  height: 0.6rem;
  background-size: 100% 100%;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 998;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.204919));
  animation: modalPopUp .3s;
  cursor: default;
}

@keyframes modalPopUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 !important;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  max-width: 8rem;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-header {
  padding: 0.28rem 0.27rem 0.05rem;
  width: 100%;
  display: flex;
  align-items: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-header .box-header-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #39b549;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-header .box-header-right svg {
  width: 0.35rem;
  height: 0.35rem;
  cursor: pointer;
  border: 0.02rem solid gray;
  border-radius: 50%;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body {
  padding: 0.2rem;
  min-height: 5.48rem;
  max-height: 6.64rem;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: contents;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body>i[class*=icon],
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body svg[class*=icon] {
  margin-bottom: 0.24rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-AG,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-AG2 {
  width: 2.17rem;
  height: 0.71rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-EBET {
  width: 1.68rem;
  height: 0.54rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-PT3,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-PT,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-PT2 {
  width: 2.36rem;
  height: 0.49rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-GD {
  width: 1.53rem;
  height: 0.8rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-PPL {
  width: 2.2rem;
  height: 1.01rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-GP {
  width: 2.57rem;
  height: 0.64rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-BINL2,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-BINS2 {
  width: 2.57rem;
  height: 0.64rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-UG,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-AES,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-AES2,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-JL {
  width: 2.67rem;
  height: 1.1rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-MGP {
  width: 2.56rem;
  height: 0.64rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-AB,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-AB2 {
  width: 1.46rem;
  height: 0.82rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-DG {
  width: 1.53rem;
  height: 0.93rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-EVO {
  width: 2.2rem;
  height: 0.53rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-SA {
  width: 2.09rem;
  height: 0.75rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .icon-BG {
  width: 2.56rem;
  height: 0.64rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-name {
  font-size: 0.24rem;
  color: #222;
  font-weight: bold;
  font-family: "AvenirNextLTPro-Bold";
  margin-bottom: 0.14rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-text {
  margin-bottom: 0.49rem;
  font-size: 0.14rem;
  color: #333;
  font-weight: 300;
  font-family: "AvenirNextLTPro";
  text-align: center;
  max-width: 3rem;
  line-height: 0.2rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game {
  margin-bottom: 0.65rem;
  max-width: 5.63rem;
  display: flex;
  flex-flow: wrap;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box {
  width: 1rem;
  text-align: center;
  align-self: baseline;
  margin-right: 0.15rem;
  fill: gray;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-last-child(1),
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-child(5n) {
  margin-right: 0;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-child(n+6) {
  margin-top: 0.24rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box img {
  width: 100%;
  margin-bottom: 0.05rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-thr {
  max-width: 0.83rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-fig,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-lk7 {
  max-width: 0.73rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-rou,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-dra,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-sic,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-hil,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-gam,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-spi {
  max-width: 0.63rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-bac {
  max-width: 0.58rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-bla {
  max-width: 0.57rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-pok {
  max-width: 0.56rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box p {
  font-size: 0.18rem;
  color: #333;
  font-weight: 300;
  font-family: "AvenirNextLTPro";
  white-space: nowrap;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body.DG .provider-game,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body.AB2 .provider-game {
  max-width: 5rem;
  justify-content: center;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body.DG .provider-game .game-box:nth-child(5n),
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body.AB2 .provider-game .game-box:nth-child(5n) {
  margin-right: 0.15rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .btn-normal,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .btn-normal-green,
.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .btn-long {
  margin-bottom: 0.82rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.28rem;
  font-size: 0.18rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .provider-maintenance {
  margin-bottom: 0.1rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .time-estimate {
  border-top: 0.01rem solid #b3b3b3;
  margin-bottom: 0.1rem;
  padding: 0.1rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .time-estimate span {
  font-weight: bold;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .time-countdown {
  font-size: 0.15rem;
}

.provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 0;
  overflow: hidden;
}

.poker-logo {
  background-image: url(/public/html/images/poker/096-poker-icon.png);
  width: 2rem;
  height: 0.32rem;
  bottom: 0.66rem;
  left: 0.7rem;
}

.poker-content .slider>ul.sli {
  overflow: visible;
}

.poker-content .banner-box {
  height: 4.3rem;
  z-index: 2;
}

.poker-content .banner-box .banner ul.sli:nth-child(1) .to-claim {
  bottom: 0.58rem;
  left: 1.9rem;
  opacity: 0;
}

.poker-content .banner-box .banner ul.sli li {
  overflow: visible;
}

.banner-btm.poker-btm {
  background: #091116;
  width: 100%;
  height: 0.95rem;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}

.banner-btm.poker-btm ul {
  width: 14.4rem;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.banner-btm.poker-btm ul img {
  position: unset;
  transform: unset;
  left: unset;
}

.banner-btm.poker-btm ul li {
  display: flex;
  height: 100%;
  align-items: center;
  margin: auto;
  width: unset;
  position: unset;
}

.banner-btm.poker-btm ul li p {
  color: #fff;
  font-size: 0.14rem;
  line-height: 0.18rem;
  font-family: "AvenirNextLTPro";
  margin-left: 0.08rem;
}

.banner-btm.poker-btm ul li p .text-notice {
  color: #813e98;
}

.banner-btm.poker-btm ul li p .text-highlight {
  color: #349ad5;
}

.banner-btm.poker-btm ul li.cup img {
  max-height: 0.39rem;
}

.banner-btm.poker-btm ul li.cup p {
  width: 1.15rem;
}

.banner-btm.poker-btm ul li.trade img {
  max-height: 0.37rem;
}

.banner-btm.poker-btm ul li.trade p {
  width: 2.2rem;
}

.banner-btm.poker-btm ul li.pokers img {
  max-height: 0.37rem;
}

.banner-btm.poker-btm ul li.pokers p {
  width: 0.85rem;
}

.banner-btm.poker-btm ul li.live img {
  max-height: 0.32rem;
}

.banner-btm.poker-btm ul li.live p {
  width: 1.8rem;
}

.banner-btm.poker-btm ul li.currency img {
  max-height: 0.41rem;
}

.banner-btm.poker-btm ul li.currency p {
  width: 0.95rem;
}

.esport-logo {
  background-image: url(/public/html/images/sports/096-esport-logo.png);
  width: 2.27rem;
  height: 0.38rem;
  bottom: 0.35rem;
  left: 0.73rem;
}

.esport-logo-sgd {
  background-image: url(/public/html/images/sports/096-esport-logo-sgd.svg);
  width: 2.27rem;
  height: 2.27rem;
  bottom: -1.25rem;
  left: 0.73rem;
}

.esport-item .text-banner-green {
  font-size: 1.1rem;
  line-height: 1rem !important;
}

.content-poker {
  margin-top: 1rem;
  margin-bottom: 1rem !important;
  height: 8.58rem;
  position: relative;
  background: url(/public/html/images/poker/096-poker-content-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.content-poker .poker-box {
  display: flex;
  justify-content: space-between;
}

.content-poker .poker-box .content-left,
.content-poker .poker-box .content-right {
  width: 4.05rem;
  margin-top: 0.27rem;
}

.content-poker .poker-box .content-left li,
.content-poker .poker-box .content-right li {
  font-family: "AvenirNextLTPro";
  text-align: right;
  display: flex;
  justify-content: flex-end;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 2.5rem;
}

.content-poker .poker-box .content-left li .content-text,
.content-poker .poker-box .content-right li .content-text {
  margin-right: 0.23rem;
}

.content-poker .poker-box .content-left li .content-text p,
.content-poker .poker-box .content-right li .content-text p {
  font-size: 0.24rem;
  color: #28bc2d;
  font-weight: bold;
  margin-bottom: 0.06rem;
}

.content-poker .poker-box .content-left li .content-text span,
.content-poker .poker-box .content-right li .content-text span {
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #202628;
}

.content-poker .poker-box .content-left li img,
.content-poker .poker-box .content-right li img {
  max-height: 0.5rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.content-poker .poker-box .content-left li:nth-child(2),
.content-poker .poker-box .content-right li:nth-child(2) {
  height: 2.2rem;
}

.content-poker .poker-box .content-right li {
  text-align: left;
  justify-content: flex-start;
}

.content-poker .poker-box .content-right li .content-text {
  margin-right: 0;
  margin-left: 0.23rem;
}

.scr-logo {
  background-image: url(/public/html/images/slot_img/096-918kiss-icon.png);
  width: 1.55rem;
  height: 0.6rem;
  bottom: 0.37rem;
  left: 0.7rem;
}

.scr-container .banner-box .banner ul.sli:nth-child(1) .slide-text-box {
  top: 0.4rem;
}

.scr-container .banner-box .banner ul.sli:nth-child(1) .slide-text-box>p {
  text-transform: uppercase;
}

.scr-container .banner-box .banner ul.sli:nth-child(1) .slide-text-box>p.text-banner-green {
  font-size: 0.9rem;
}

.scr-container .banner-box .banner ul.sli:nth-child(1) .slide-text-box>p:nth-child(3) {
  margin-top: 0;
}

.scr-container>.content-section {
  margin-top: 0.55rem;
  padding-bottom: 1.05rem;
}

.scr-container .scr-content {
  padding: 0.55rem 0.65rem;
}

.scr-container .scr-content .tab-pane {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.scr-container .scr-content ul.guide-slide {
  width: 8rem;
  height: 6.15rem;
}

.scr-container .scr-content ul.guide-slide .slick-slider {
  height: 100%;
}

.scr-container .scr-content ul.guide-slide .slick-slider .slick-prev-btn,
.scr-container .scr-content ul.guide-slide .slick-slider .slick-next-btn {
  position: absolute;
  display: flex !important;
  align-items: center;
  height: 100%;
  padding-bottom: 0;
  top: 0;
  left: 1.5rem;
  z-index: 2;
}

.scr-container .scr-content ul.guide-slide .slick-slider .slick-next-btn {
  left: unset;
  right: 1.5rem;
}

.scr-container .scr-content ul.guide-slide .slick-slider .slick-list {
  margin: auto;
  width: 81%;
  height: 100%;
  background: #fff;
  z-index: 1;
}

.scr-container .scr-content ul.guide-slide .slick-slider .slick-list .slick-track {
  height: 100%;
}

.scr-container .scr-content ul.guide-slide .slick-slider .slick-list .slick-slide>div {
  height: 100%;
}

.scr-container .scr-content ul.guide-slide .slick-slider .slick-list .slick-slide>div li {
  padding: 0.38rem 0;
  height: 100%;
}

.scr-container .scr-content ul.guide-slide .slick-slider .slick-list .slick-slide>div li img {
  margin: auto;
  height: 100%;
}

.scr-container .scr-content ul li .content-title {
  font-size: 0.24rem;
  line-height: 0.3rem;
  font-family: "Arial";
  color: #3b3b3b;
  margin-bottom: 0.32rem;
}

.scr-container .scr-content ul li .content-guide {
  font-size: 0.16rem;
  line-height: 0.2rem;
  font-family: "Arial";
  color: #3b3b3b;
  margin-bottom: 0.34rem;
}

.scr-container .scr-content ul li .btn-normal,
.scr-container .scr-content ul li .btn-normal-green,
.scr-container .scr-content ul li .btn-long {
  background: #18723c;
  color: #fff;
}

.scr-container .scr-content ul li label {
  height: 0.22rem;
  display: block;
  font-size: 0.14rem;
  font-weight: bold;
  color: #3b3b3b;
  font-family: "Arial";
}

.scr-container .scr-content ul li .input-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 3.4rem;
}

.scr-container .scr-content ul li .input-box input {
  border: 0.01rem solid #65a16b;
  width: 3.4rem;
  height: 0.42rem;
  border-radius: 0.02rem;
  outline: none;
  padding-left: 0.1rem;
  background: #fff;
  padding-right: 0.55rem;
}

.scr-container .scr-content ul li .input-box .icon-box {
  width: 0.58rem;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
}

.scr-container .scr-content ul .detail {
  margin-bottom: 0.65rem;
}

.scr-container .scr-content ul .user-name {
  margin-bottom: 0.2rem;
}

.scr-container .scr-content ul .user-pass {
  margin-bottom: 0.3rem;
}

.mega-logo {
  background-image: url(/public/html/images/slot_img/096-mega-icon.png);
  width: 2.3rem;
  height: 0.38rem;
  bottom: 0.35rem;
  left: 0.66rem;
}

.mega-content {
  display: flex;
  justify-content: space-between;
}

.mega-content .mega-frame {
  width: 6.2rem;
  height: 3.95rem;
}

.mega-content .mega-frame iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.psy8-logo {
  background-image: url(/public/html/images/slot_img/096-psy8-icon.png);
  width: 1.2rem;
  height: 0.68rem;
  bottom: 0.35rem;
  left: 0.66rem;
}

.download-container .banner-box .banner .slider ul.sli li .banner-item .content-container .slide-text-box p:nth-child(3) {
  margin-top: 0;
  margin-bottom: 0.3rem;
}

.ultimate-logo {
  background-image: url(/public/html/images/slot_img/096-participate-ul.png);
  width: 1.3rem;
  height: 0.78rem;
  left: 0.75rem;
  bottom: 0.35rem;
}

.ultimate-container .banner-box .banner ul.sli .slide-text-box {
  top: 0.8rem;
}

.content-ultimate {
  height: 6.1rem;
  background: url(/public/html/images/slot_img/096-ultimate-btm-bn.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 0.85rem;
  padding-left: 0.8rem;
}

.content-ultimate p:first-child {
  font-size: 0.24rem;
  color: #039e40;
  font-family: "AvenirNextLTPro";
  font-weight: bold;
  margin-bottom: 0.22rem;
}

.content-ultimate p:nth-child(2) {
  font-size: 0.18rem;
  color: #333;
  font-family: "AvenirNextLTPro";
  margin-bottom: 0.15rem;
}

.content-ultimate p:last-child {
  width: 4.2rem;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #333;
  font-family: "AvenirNextLTPro";
}

.lottery-logo1 {
  background-image: url(/public/html/images/lottery/096-qqkeno-icon.svg);
  width: 2.25rem;
  height: 2.25rem;
  top: 3.4rem;
  left: 0.5rem;
}

.lottery-logo2 {
  background-image: url(/public/html/images/lottery/096-funkygame-icon.svg);
  width: 3.5rem;
  height: 1.4rem;
  top: 3rem;
  left: 0.5rem;
}

.fourD-logo {
  background-image: url(/public/html/images/lottery/096-gdl-icon.svg);
  width: 3.5rem;
  height: 1.4rem;
  top: 3rem;
  left: 0.5rem;
}

.fish-logo1 {
  background-image: url(/public/html/images/fishing/096-fish-icon1.png);
  width: 2.15rem;
  height: 0.38rem;
  bottom: 0.35rem;
  left: 0.68rem;
}

.fishing-container,
.fourD-container {
  margin-top: 0.55rem;
}

.fishing-container .banner-box .banner ul.sli .slide-text-box,
.fourD-container .banner-box .banner ul.sli .slide-text-box {
  top: 0.8rem;
}

.fishing-container .banner-box .banner ul.sli .slide-text-box>p:nth-child(3),
.fourD-container .banner-box .banner ul.sli .slide-text-box>p:nth-child(3) {
  margin-top: 0;
}

.fishing-container .banner-box .banner ul.sli .slide-text-box .text-banner-content,
.fourD-container .banner-box .banner ul.sli .slide-text-box .text-banner-content {
  margin-top: 0.35rem;
  font-size: 0.22rem;
  line-height: 0.3rem;
}

.fishing-container .content-provider .content-center-box .provider-bar .provider svg,
.fourD-container .content-provider .content-center-box .provider-bar .provider svg {
  margin-left: .15rem;
  width: 1.4rem;
}

.casino-container .text-banner-white {
  line-height: 0.62rem;
}

.casino-container .recommendCasino-box {
  margin-top: 0.35rem;
}

.sport-container {
  margin-top: 0.55rem;
}

.sport-container .banner-box .banner ul.sli .slide-text-box {
  top: 0.6rem;
}

.sport-container .banner-box .banner ul.sli .slide-text-box>p:nth-child(3) {
  margin-top: 0.35rem;
}

.cmd-container {
  margin-top: 0.97rem;
}

.m8-container {
  margin: 0;
}

.esport-container {
  margin-top: 0.55rem;
}

.loading-container {
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.3);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.sort-box-content .loading-container {
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,.3);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loading {
  padding-bottom: 10vh;
}

.loading img {
  width: 100%;
  height: 100%;
  max-width: 3rem;
}

.header-logout {
  text-transform: uppercase;
}

.no-Data,
.no-Matches {
  padding: 0.2rem 0;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #cacaca;
  width: 100%;
}

.no-Matches {
  color: #2d2d2d;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.header-title {
  text-transform: uppercase;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: 0.35rem;
  padding: 0.02rem;
}

.slide-text-box {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.menu-name {
  text-transform: uppercase;
}

.maintain-container {
  padding-top: 1.2rem;
}

.vip-page {
  margin-top: 1.13rem;
}

.vip-page img {
  height: auto;
  width: 100%;
}

.mega888-content .text-banner-green,
.scr-content .text-banner-green {
  font-size: 1.1rem;
}

@media (max-width: 1025px) {
  .fish-logo1 {
    width: 3.15rem;
    height: 0.55rem;
  }

  .btn-promo-details span {
    width: 2.26rem;
    height: 0.54rem;
    font-size: 0.26rem;
  }

  .btn-promo-apply span {
    width: 2.26rem;
    height: 0.54rem;
    font-size: 0.26rem;
  }

  .search-item {
    margin-top: 0.12rem;
    display: inline-block;
    width: 4.61rem;
    height: 0.6rem;
    font-size: 0.24rem;
  }

  .search-item>div {
    padding-left: 0.23rem;
  }

  .search-item>div .icon-search {
    margin-right: 0.1rem;
  }

  .search-item>div input {
    font-size: 0.24rem;
  }

  .provider-content-section .content-provider .content-center {
    padding: 0.8rem 0.9rem 0;
  }

  .provider-content-section .content-provider .content-center .provider-box {
    width: 15.77rem;
    margin: auto;
  }

  .provider-content-section .content-provider .content-center .provider-box .maintenance-hover-box {
    background: url(/public/html/images/icon/maintenance-en.svg);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: hsla(0,0%,100%,.737254902);
    position: absolute;
    margin-top: -4.4rem;
    background-size: 2.5rem;
  }

  .provider-content-section .content-provider .content-center .provider-box.casino-provider-box {
    grid-template-columns: 5.45rem 5.45rem 5.45rem;
  }

  .provider-content-section .content-provider .content-center .provider-box.casino-provider-box li {
    margin-bottom: 0.7rem;
  }

  .provider-content-section .content-provider .content-center .provider-box.casino-provider-box li .maintenance-hover-box {
    background: url(/public/html/images/icon/maintenance-en.svg);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: hsla(0,0%,100%,.737254902);
    position: absolute;
    margin-top: -4.4rem;
    background-size: 3.8rem;
  }

  .provider-content-section .content-provider .content-center .provider-box.casino-provider-box li:first-child {
    width: 10.2rem;
    height: 9.2rem;
    grid-column: 1/span 2;
    grid-row: 1/span 2;
    margin-top: -0.2rem;
  }

  .provider-content-section .content-provider .content-center .provider-box.casino-provider-box li:first-child .maintenance-hover-box {
    background: url(/public/html/images/icon/maintenance-en.svg);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: hsla(0,0%,100%,.737254902);
    position: absolute;
    margin-top: -9.3rem;
    background-size: 8rem;
  }

  .provider-content-section .content-provider .content-center .provider-box li {
    width: 4.7rem;
    height: 4.35rem;
    margin-bottom: 0.83rem;
    margin-right: 0.83rem;
  }

  .provider-content-section .content-provider .content-center .provider-box li:nth-child(4n) {
    margin-right: 0.83rem;
  }

  .provider-content-section .content-provider .content-center .provider-box li:nth-child(3n) {
    margin-right: 0;
  }

  .provider-content-section .content-provider .content-center .provider-box li img {
    border: 0.06rem solid #e6e6e6;
    outline: 0.07rem solid #e6e6e6;
    outline-offset: -0.08rem;
  }

  .provider-content-section .content-provider .content-center .provider-box li .hover-box {
    padding: 0.67rem 0;
  }

  .provider-content-section .content-provider .content-center .provider-box li .hover-box>span {
    font-size: 0.36rem;
  }

  .provider-content-section .content-provider .content-center .provider-box li .hover-box .content-box>p {
    font-size: 0.21rem;
    margin: 0.21rem 0;
  }

  .provider-content-section .content-provider .content-center .provider-box li .hover-box .content-box .icon-box {
    margin-top: 0.1rem;
    height: 0.5rem;
  }

  .provider-content-section .content-provider .content-center .provider-box li .hover-box .btn-normal,
  .provider-content-section .content-provider .content-center .provider-box li .hover-box .btn-normal-green,
  .provider-content-section .content-provider .content-center .provider-box li .hover-box .btn-long {
    font-size: 0.24rem;
    width: 2.25rem;
    height: 0.53rem;
  }

  .provider-content-section .content-provider .content-center .provider-box li .vpn-icon {
    height: .4rem;
    width: .8rem;
    bottom: .4rem;
    right: .4rem;
  }

  .poker-content .banner-box {
    height: 5.63rem;
  }

  .poker-content .banner-box .banner ul.sli:nth-child(1) .slide-text-box>p:nth-child(3) {
    font-size: 0.2rem;
  }

  .poker-content .banner-box .banner ul.sli:nth-child(1) .to-claim {
    left: 3.5rem;
    bottom: 0.78rem;
  }

  .banner-btm.poker-btm {
    width: 20.5rem;
  }

  .banner-btm.poker-btm ul {
    width: 18rem;
  }

  .banner-btm.poker-btm ul li p {
    font-size: 0.2rem;
    line-height: 0.28rem;
    margin-left: 0.1rem;
  }

  .banner-btm.poker-btm ul li.cup p {
    width: 1.7rem;
  }

  .banner-btm.poker-btm ul li.trade p {
    width: 3.2rem;
  }

  .banner-btm.poker-btm ul li.pokers p {
    width: 1.3rem;
  }

  .banner-btm.poker-btm ul li.live p {
    width: 2.7rem;
  }

  .banner-btm.poker-btm ul li.currency p {
    width: 1.5rem;
  }

  .content-poker {
    height: 9rem;
    margin-bottom: 2rem !important;
  }

  .content-poker .poker-box .content-left,
  .content-poker .poker-box .content-right {
    width: 6.2rem;
  }

  .content-poker .poker-box .content-left li,
  .content-poker .poker-box .content-right li {
    height: 3.5rem;
  }

  .content-poker .poker-box .content-left li .content-text p,
  .content-poker .poker-box .content-right li .content-text p {
    font-size: 0.4rem;
  }

  .content-poker .poker-box .content-left li .content-text span,
  .content-poker .poker-box .content-right li .content-text span {
    font-size: 0.24rem;
  }

  .content-poker .poker-box .content-left li:nth-child(2),
  .content-poker .poker-box .content-right li:nth-child(2) {
    height: 4rem;
  }

  .scr-container .scr-content ul .user-name {
    margin-bottom: 0.3rem;
  }

  .scr-container .scr-content ul .user-pass {
    margin-bottom: 0.4rem;
  }

  .scr-container .scr-content ul li .content-title {
    font-size: 0.35rem;
  }

  .scr-container .scr-content ul li .content-guide {
    font-size: 0.28rem;
    line-height: 0.4rem;
  }

  .scr-container .scr-content ul li label {
    font-size: 0.23rem;
    height: 0.33rem;
  }

  .scr-container .scr-content ul li .input-box {
    width: 3.3rem;
  }

  .scr-container .scr-content ul li .input-box input {
    height: 0.5rem;
  }

  .scr-container .scr-content ul.guide-slide {
    width: 10rem;
    height: 7.5rem;
  }

  .mega-logo {
    bottom: 0.67rem;
    left: 0.66rem;
  }

  .mega-content .mega-frame {
    width: 9rem;
    height: 5rem;
  }

  .psy8-logo {
    bottom: 0.67rem;
    left: 0.66rem;
  }

  .ultimate-container .text-banner-content {
    margin-top: 0.35rem !important;
    font-size: 0.22rem !important;
    line-height: 0.3rem !important;
  }

  .ultimate-container .banner-box .banner ul.sli:nth-child(1) .to-play {
    left: 6.15rem;
    bottom: 1.15rem;
  }

  .ultimate-container .banner-box .banner ul.sli:nth-child(1) .to-demo {
    left: 5.8rem;
    bottom: 0.4rem;
  }

  .content-ultimate {
    height: 8.5rem;
    padding-left: 0.8rem;
  }

  .content-ultimate p:first-child {
    font-size: 0.4rem;
    margin-bottom: 0.35rem;
  }

  .content-ultimate p:nth-child(2) {
    font-size: 0.26rem;
    margin-bottom: 0.2rem;
  }

  .content-ultimate p:last-child {
    width: 7rem;
    font-size: 0.22rem;
    line-height: 0.35rem;
  }

  .sport-container .banner-box .banner ul.sli .slide-text-box {
    top: 0.6rem;
  }

  .sport-container .banner-box .banner ul.sli .slide-text-box .text-banner-content {
    margin-top: 0.35rem;
    font-size: 0.22rem !important;
    line-height: 0.3rem;
  }

  .slots .banner-box .banner ul.sli:nth-child(1) .games-container .slide-text-box {
    top: 0.6rem;
  }

  .cmd-container {
    margin-top: 1.8rem;
  }

  .m8-container {
    margin: 0;
  }

  .favourite-container .content-section .content-recommended .slick-prev-btn {
    left: -0.85rem;
  }

  .favourite-container .content-section .content-recommended .slick-next-btn {
    right: -0.65rem;
  }

  .favourite-container .content-section .content-recommended .slick-dots {
    top: -1.2rem;
  }

  .content-section .content-recommended .favourite-content .nav-game li {
    margin-bottom: 0;
  }

  .content-section .content-recommended .favourite-flow .nav-game {
    max-width: 3.23rem;
  }

  .vip-page {
    margin-top: 2.25rem;
  }

  .provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box {
    margin-bottom: 0.32rem;
    font-size: 0.23rem;
  }

  .provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .provider-maintenance {
    margin-bottom: 0.15rem;
  }

  .provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .time-estimate {
    margin-bottom: 0.15rem;
    padding: 0.15rem;
  }

  .provider-content-section .content-provider .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .time-countdown {
    font-size: 0.18rem;
  }
}

@media (max-width: 768px) {
  .no-Data,
  .no-Matches {
    font-size: 0.28rem;
  }

  .mTopPadding {
    padding-top: 1.29rem;
  }

  .mAccTopPadding {
    padding-top: 0.8rem;
  }

  .search-item-outer {
    right: 0;
    top: 0;
    position: absolute;
    transform: translateY(-1.22rem);
  }

  .search-item {
    margin-top: 0;
    display: inline-block;
    width: 5.66rem;
    height: 0.98rem;
    font-size: 0.4rem;
  }

  .search-item>div {
    padding-left: 0.43rem;
  }

  .search-item>div .icon-search {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.2rem;
  }

  .search-item>div input {
    font-size: 0.36rem;
    width: 3.8rem;
  }

  .favourite-container {
    margin-top: 2.2rem;
  }

  .favourite-container .content-section .home-content-section>li.search-box,
  .favourite-container .content-section .home-content-section-with-marginTop>li.search-box {
    width: 11.16rem;
    margin: 0.59rem auto 0;
  }

  .favourite-container .content-section .home-content-section>li.search-box .search-item-outer,
  .favourite-container .content-section .home-content-section-with-marginTop>li.search-box .search-item-outer {
    right: 0;
    top: 0;
    position: absolute;
    transform: translateY(0.5rem);
  }

  .favourite-container .content-section .content-recommended .content-center {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.66rem 0.63rem;
  }

  .favourite-container .content-section .content-recommended .nav-game li {
    margin-bottom: unset;
  }

  .provider-content-section .content-provider {
    padding-top: 0.2rem;
  }

  .provider-content-section .content-provider .content-center-box {
    padding-top: 1.2rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center {
    padding: 0 0.35rem;
    background: unset;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box {
    width: 11.71rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .maintenance-hover-box {
    background: url(/public/html/images/icon/m-maintenance-en.svg);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: hsla(0,0%,100%,.6901960784);
    position: absolute;
    margin-top: -5.3rem;
    background-size: 4rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box.casino-provider-box {
    grid-template-columns: 6rem 6rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box.casino-provider-box li .maintenance-hover-box {
    background: url(/public/html/images/icon/m-maintenance-en.svg);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: hsla(0,0%,100%,.6901960784);
    position: absolute;
    margin-top: -5.3rem;
    background-size: 3.8rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box.casino-provider-box li:first-child {
    width: 11.67rem;
    height: 5.25rem;
    grid-column: 1/span 2;
    grid-row: 1/span 2;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box.casino-provider-box li:first-child .maintenance-hover-box {
    background: url(/public/html/images/icon/m-maintenance-en.svg);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: hsla(0,0%,100%,.7411764706);
    position: absolute;
    background-size: 3.8rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box li {
    width: 5.65rem;
    height: 5.2rem;
    margin-bottom: 0.605rem;
    margin-right: 0.4rem;
    border: none;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box li:nth-child(2n) {
    margin-right: 0;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box li .hover-box {
    height: 101%;
    padding: 0.67rem 0;
    right: 0;
    bottom: 0;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box li .hover-box>span {
    font-size: 0.43rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box li .hover-box .content-box>p {
    font-size: 0.25rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box li .hover-box .content-box .icon-box {
    transform: scale(1.5);
    margin-top: 0.4rem;
    height: 0.5rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box li .hover-box .btn-normal,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box li .hover-box .btn-normal-green,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box li .hover-box .btn-long {
    font-size: 0.3rem;
    width: 3rem;
    height: 0.66rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box li .vpn-icon {
    height: .7rem;
    width: 1.3rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box {
    transform: unset;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-header .box-header-right svg {
    width: 0.45rem;
    height: 0.45rem;
    border: 0.05rem solid #cecece;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body>i[class*=icon] {
    transform: scale(1.5);
    margin-bottom: 0.5rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-name {
    font-size: 0.35rem;
    margin-bottom: 0.24rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-text {
    margin-bottom: 1rem;
    font-size: 0.24rem;
    max-width: 5rem;
    line-height: 0.3rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game {
    margin-bottom: 1.01rem;
    max-width: 7.23rem;
    transform: unset;
    justify-content: center;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box {
    width: 1.5rem;
    margin-right: 0.4rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-last-child(1),
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-child(4n) {
    margin-right: 0;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-child(5n) {
    margin-right: 0.4rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-child(n+5) {
    margin-top: 0.63rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box img {
    margin-bottom: 0.2rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-thr {
    max-width: 1.35rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-fig,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-lk7 {
    max-width: 1.25rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-rou,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-spi {
    max-width: 0.97rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-hil,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-gam {
    max-width: 0.96rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-dra,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-sic {
    max-width: 0.95rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-bac {
    max-width: 0.87rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-bla {
    max-width: 0.83rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-pok {
    max-width: 0.82rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box p {
    font-size: 0.26rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .user-name,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .user-pass {
    height: unset;
    margin-bottom: 0.59rem;
    margin-right: unset;
    width: 100%;
    font-size: 0.3rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .user-name .input-box,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .user-pass .input-box {
    margin-top: 0.29rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .btn-normal,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .btn-normal-green,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .btn-long {
    margin-top: 0.2rem;
    margin-bottom: 0.8rem;
    width: 3rem;
    height: 0.8rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box {
    margin-bottom: 0.56rem;
    font-size: 0.36rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .provider-maintenance {
    margin-bottom: 0.3rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .time-estimate {
    margin-bottom: 0.3rem;
    padding: 0.25rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .maintenance-box .time-countdown {
    font-size: 0.3rem;
  }

  .poker-content .banner-box {
    height: 4.7rem;
  }

  .poker-content .banner-box .banner .slider ul.sli:nth-child(1) .slide-text-box>p:nth-child(3) {
    font-size: 0.36rem;
    width: 4rem;
    line-height: 0.4rem;
  }

  .poker-content .banner-box .banner ul.sli:nth-child(1) .to-claim {
    bottom: 0.65rem;
    left: 0.65rem;
  }

  .poker-content .appStore-btn {
    background-image: url(/public/html/images/poker/096-appStore-btn.png);
    width: 2.23rem;
    height: 0.65rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 1.2rem;
    left: 0.25rem;
  }

  .banner-btm.poker-btm {
    display: none;
  }

  .poker-container .poker-box {
    padding: 0.6rem 0.35rem 1.83rem;
  }

  .poker-container .poker-box .m-poker-btm-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .poker-container .poker-box .m-poker-btm-box .m-poker-btm-img {
    width: 100%;
  }

  .poker-container .poker-box .m-poker-btm-box .btn-play {
    position: absolute;
    bottom: 0.7rem;
    width: 6rem;
    height: 1.5rem;
    font-size: 0.5rem;
    border-radius: 0.25rem;
  }

  .poker-container .poker-box ul {
    padding: 1.12rem 0.44rem 1.14rem;
    border-radius: 0.1rem;
  }

  .poker-container .poker-box ul .download-btm {
    font-size: 0.42rem;
    font-weight: bold;
    height: 0.98rem;
    background: #11232b;
    border-radius: 0.15rem;
    position: relative;
    margin-top: 1.03rem;
    background-image: url(/public/html/images/poker/096-poker-icon.png);
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: 93% 45%;
  }

  .poker-container .poker-box ul .download-btm .download-box {
    width: 5.24rem;
    background: #0a974b;
    height: 0.98rem;
    border-radius: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }

  .scr-container .banner-box .banner ul.sli:nth-child(1) .slide-text-box {
    top: 0.2rem;
  }

  .scr-container .banner-box .banner ul.sli:nth-child(1) .to-claim {
    left: 2.4rem;
    bottom: 0.3rem;
  }

  .scr-container .content-center {
    padding: 0 0.35rem;
  }

  .scr-container .scr-content .tab-pane {
    display: block;
  }

  .scr-container .scr-content .tab-pane ul .user-name,
  .scr-container .scr-content .tab-pane ul .user-pass {
    margin-bottom: 0.63rem;
  }

  .scr-container .scr-content .tab-pane ul .detail {
    text-align: center;
  }

  .scr-container .scr-content .tab-pane ul .btn-normal,
  .scr-container .scr-content .tab-pane ul .btn-normal-green,
  .scr-container .scr-content .tab-pane ul .btn-long {
    margin: auto;
    width: 3rem;
    height: 0.8rem;
    font-size: 0.35rem;
  }

  .scr-container .scr-content .tab-pane ul>div {
    margin-top: 0.18rem;
    margin-bottom: 0.6rem;
  }

  .scr-container .scr-content .tab-pane ul li .content-title {
    font-size: 0.5rem;
    font-weight: bolder;
    line-height: unset;
  }

  .scr-container .scr-content .tab-pane ul li .content-guide {
    font-size: 0.4rem;
    line-height: 0.5rem;
  }

  .scr-container .scr-content .tab-pane ul li label {
    font-size: .42rem;
    color: #4d4d4d;
    font-family: unset;
    height: unset;
  }

  .scr-container .scr-content .tab-pane ul li .input-box {
    width: unset;
  }

  .scr-container .scr-content .tab-pane ul li .input-box input {
    border: 0.01rem solid #65a16b;
    width: 100%;
    height: 0.98rem;
    border-radius: 0.1rem;
    outline: none;
    padding-left: 0.5rem;
    padding-right: 1rem;
    font-size: 0.42rem;
  }

  .scr-container .scr-content .tab-pane ul li .input-box .icon-box {
    width: 1.2rem;
  }

  .scr-container .scr-content ul.guide-slide {
    width: 100%;
    height: 8rem;
  }

  .scr-container .mega-content {
    display: block;
  }

  .scr-container .mega-content ul .user-name,
  .scr-container .mega-content ul .user-pass {
    margin-bottom: 0.63rem;
  }

  .scr-container .mega-content ul .detail {
    text-align: center;
  }

  .scr-container .mega-content ul .btn-normal,
  .scr-container .mega-content ul .btn-normal-green,
  .scr-container .mega-content ul .btn-long {
    margin: auto;
    width: 3rem;
    height: 0.8rem;
    font-size: 0.35rem;
  }

  .scr-container .mega-content ul>div {
    margin-top: 0.18rem;
    margin-bottom: 0.6rem;
  }

  .scr-container .mega-content ul li .content-title {
    font-size: 0.5rem;
    font-weight: bolder;
    line-height: unset;
  }

  .scr-container .mega-content ul li .content-guide {
    font-size: 0.4rem;
    line-height: 0.5rem;
  }

  .scr-container .mega-content ul li label {
    font-size: .42rem;
    color: #4d4d4d;
    font-family: unset;
    height: unset;
  }

  .scr-container .mega-content ul li .input-box {
    width: unset;
  }

  .scr-container .mega-content ul li .input-box input {
    border: 0.01rem solid #65a16b;
    width: 100%;
    height: 0.98rem;
    border-radius: 0.1rem;
    outline: none;
    padding-left: 0.5rem;
    padding-right: 1rem;
    font-size: 0.42rem;
  }

  .scr-container .mega-content ul li .input-box .icon-box {
    width: 1.2rem;
  }

  .psy8-logo {
    bottom: 0.15rem;
  }

  .mega-content .mega-frame {
    width: 10.3rem;
    height: 12.7rem;
  }

  .download-container .banner-box .banner .slider ul.sli li .banner-item .content-container .slide-text-box {
    top: 1.2rem;
    left: 0.9rem;
  }

  .download-container .banner-box .banner .slider ul.sli li .banner-item .content-container .slide-text-box p:nth-child(1) {
    font-size: 0.7rem !important;
  }

  .download-container .banner-box .banner .slider ul.sli li .banner-item .content-container .slide-text-box p:nth-child(2) {
    font-size: 0.5rem;
  }

  .download-container .banner-box .banner .slider ul.sli li .banner-item .content-container .slide-text-box p.text-banner-content {
    display: none;
  }

  .download-container .banner-box .banner .slider ul.sli li .banner-item .content-container .btn-skew {
    display: none;
  }

  .ultimate-container .text-banner-content {
    margin-top: 0.2rem !important;
  }

  .ultimate-container .banner-box .banner ul.sli:nth-child(1) .to-play {
    left: 2.65rem;
    bottom: 0.8rem;
  }

  .ultimate-container .banner-box .banner ul.sli:nth-child(1) .to-demo {
    left: 2.38rem;
    bottom: 0.2rem;
  }

  .content-ultimate {
    padding-left: 0.35rem;
    padding-top: 0.68rem;
  }

  .fishing-container {
    margin-top: 0;
  }

  .fishing-container .text-banner-green {
    font-size: 0.8rem;
  }

  .fourD-container {
    margin-top: 0;
  }

  .fourD-container .text-banner-green {
    font-size: 0.8rem;
  }

  .sport-container .banner-box .banner ul.sli .slide-text-box .text-banner-green {
    font-size: 0.8rem;
  }

  .sport-container .banner-box .banner ul.sli .slide-text-box .text-banner-content {
    margin-top: 0.35rem !important;
    font-size: 0.22rem !important;
    line-height: 0.3rem;
  }

  .casino-container .text-banner-green {
    font-size: 0.8rem;
  }

  .category-footer-bottom {
    top: 6rem !important;
  }

  .category-footer-bottom-login {
    top: 7.29rem !important;
  }

  .content-section .content-recommended .favourite-flow {
    flex-flow: unset;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 100%;
    height: 4.3rem;
  }

  .content-section .content-recommended .favourite-flow .nav-game {
    max-width: 3.7rem;
  }

  .content-section .content-recommended .special-cap {
    flex-flow: unset;
    justify-content: flex-start;
  }

  .content-section .content-recommended .special-cap .nav-game {
    max-width: 3.7rem;
  }
}

@media (max-width: 575px) {
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box {
    transform: none;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box {
    max-width: 10.62rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-header {
    padding: 0.4rem 0.42rem 0.2rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-header .box-header-right svg {
    width: 0.58rem;
    height: 0.58rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body>i[class*=icon] {
    margin-bottom: 0.65rem;
    transform: unset;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-PT3,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-PT,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-PT2 {
    width: 4.72rem;
    height: 0.99rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-AG,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-AG2 {
    width: 3.75rem;
    height: 1.23rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-EBET {
    width: 3.36rem;
    height: 1.06rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-GD {
    width: 3.53rem;
    height: 1.84rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-PPL {
    width: 4.2rem;
    height: 1.92rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-GP {
    width: 4.5rem;
    height: 1.22rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-BINL2,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-BINS2 {
    width: 4.5rem;
    height: 1.22rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-UG,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-AES,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-AES2,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-JL {
    width: 2.53rem;
    height: 1.28rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-MGP {
    width: 4.47rem;
    height: 1.11rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-WM {
    width: 4.53rem;
    height: 1.27rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-AB,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-AB2 {
    width: 2.87rem;
    height: 1.67rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-DG {
    width: 3.08rem;
    height: 1.87rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-EVO {
    width: 3.82rem;
    height: 0.92rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .icon-SA {
    width: 4.19rem;
    height: 1.49rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-name {
    font-size: 0.6rem;
    margin-bottom: 0.43rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-text {
    margin-bottom: 1.15rem;
    font-size: 0.36rem;
    max-width: 7rem;
    line-height: 0.5rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game {
    margin-bottom: 1.01rem;
    max-width: 9.23rem;
    transform: unset;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box {
    width: 2rem;
    margin-right: 0.4rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-child(4n) {
    margin-right: 0;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-child(5n) {
    margin-right: 0.4rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box:nth-child(n+5) {
    margin-top: 0.63rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-thr {
    max-width: 1.65rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-fig,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-lk7 {
    max-width: 1.55rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-rou,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-spi {
    max-width: 1.27rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-hil,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-gam {
    max-width: 1.26rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-dra,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-sic {
    max-width: 1.25rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-bac {
    max-width: 1.17rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-bla {
    max-width: 1.13rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box .icon-pok {
    max-width: 1.12rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .provider-game .game-box p {
    font-size: 0.36rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .user-name,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .user-pass {
    height: unset;
    margin-bottom: 0.59rem;
    margin-right: unset;
    width: 8.67rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .user-name .input-box,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .user-pass .input-box {
    margin-top: 0.29rem;
  }

  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .btn-normal,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .btn-normal-green,
  .provider-content-section .content-provider .content-center-box .content-center .provider-box .providerPopup-Box .box .box-body .btn-long {
    margin-top: 0.68rem;
    margin-bottom: 1.65rem;
    width: 4.91rem;
    height: 1.15rem;
  }
}

.btn-normal:disabled,
.btn-normal-green:disabled,
.btn-long:disabled,
.submit-btn[disabled] {
  background: #959595 !important;
}

.dropdown-please-select .react-dropdown-select-content {
  color: #999 !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.raffleDraw-container {
  min-height: 3rem;
}

.raffleDraw-container .home-content-section {
  padding-top: 0.23rem;
}

.raffleDraw-container .ranks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.33rem;
}

.raffleDraw-container .ranks li {
  width: 5.55rem;
  height: 0.54rem;
  margin-bottom: 0.08rem;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  padding-left: 0.94rem;
  padding-right: 0.3rem;
  position: relative;
}

.raffleDraw-container .ranks li:nth-child(odd) {
  background: #98a0a5;
}

.raffleDraw-container .ranks li:nth-child(even) {
  background: #a2abb0;
}

.raffleDraw-container .ranks li:nth-child(1) {
  background: #d19f22;
}

.raffleDraw-container .ranks li:nth-child(2) {
  background: #1a733d;
}

.raffleDraw-container .ranks li:nth-child(3) {
  background: #41b852;
}

.raffleDraw-container .ranks li>span:nth-child(1) {
  font-size: 0.24rem;
  font-weight: bold;
  font-family: OpenSans;
}

.raffleDraw-container .ranks li>span:nth-child(4) {
  font-family: Roboto;
  font-size: 0.28rem;
  font-weight: bold;
}

.raffleDraw-container .ranks li .icon-lighting {
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.raffleDraw-container .ranks li .icon-meda {
  position: absolute;
  top: 0;
  left: 0.36rem;
  font-style: normal;
  color: #fff153;
  display: flex;
  justify-content: center;
  line-height: 0.24rem;
  align-items: flex-end;
  padding-bottom: 0.01rem;
  font-size: 0.24rem;
  font-family: Roboto;
  font-weight: bold;
}

.raffleDraw-container .ranks li:nth-child(n+4) {
  margin-bottom: 0.01rem;
  height: 0.37rem;
}

.raffleDraw-container .ranks li:nth-child(n+4)>span:nth-child(1) {
  font-size: 0.15rem;
}

.raffleDraw-container .ranks li:nth-child(n+4)>span:nth-child(4) {
  font-size: 0.22rem;
}

.raffleDraw-container .ranks li:nth-child(n+4) .icon-lighting {
  background: none;
}

.raffleDraw-container .ranks li:nth-child(n+4) .icon-meda {
  background: none;
  color: #fff;
  font-size: 0.17rem;
  height: 0.37rem;
  width: 0.37rem;
  line-height: 0.33rem;
  z-index: 1;
  padding-bottom: 0;
}

.raffleDraw-container .ranks li:nth-child(n+4) .icon-meda::after {
  content: "";
  background: #8a9296;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 0.25rem;
  height: 0.25rem;
  z-index: -1;
}

.raffleDraw-container .content-promotions {
  margin-top: 0.36rem;
}

.raffleDraw-container .content-promotions .content-center-box {
  padding-top: 0;
}

.raffleDraw-container .icon-calender {
  display: inline-block;
  background: url("/public/html/images/slot_img/096-calendar.png") no-repeat;
  width: 0.35rem;
  height: 0.32rem;
  background-size: 100% 100%;
}

.raffle-countdown {
  position: relative;
  text-align: center;
  transform: translate(0, -1.65rem);
}

.raffle-countdown>img {
  width: 100%;
  max-width: 6rem;
}

.raffle-countdown .countdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-size: 100% 100%;
}

.raffle-countdown .countdown::before {
  content: "";
  position: absolute;
  background: url("../assets/home/countdown_bg.png") no-repeat;
  width: 4rem;
  height: 1rem;
  background-size: 100% 100%;
  transform: translate(-50%, 40%);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.raffle-countdown .countdown ul {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  top: 0.1rem;
  transform: translateX(-50%);
  width: 3.5rem;
}

.raffle-countdown .countdown ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
}

.raffle-countdown .countdown ul li>span:nth-child(1) {
  font-size: 0.7rem;
  color: #fff;
  background: url("../assets/home/time_bg.png") no-repeat;
  width: 1rem;
  height: 0.9rem;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.05rem;
}

.raffle-countdown .countdown ul li>span:nth-child(2) {
  font-size: 0.15rem;
  color: #dadada;
  font-weight: bold;
  text-transform: uppercase;
}

.content-section .home-content-section .raffle-result {
  width: 6.58rem;
}

.content-section .home-content-section .raffle-result .content-center-box {
  padding-top: 0;
}

.content-section .home-content-section .raffle-result .content-center {
  padding-top: 0.395rem;
  padding-bottom: 0.25rem;
}

.content-section .home-content-section .raffle-result .content-top {
  padding: 0.21rem 0 0.21rem 0.14rem;
  margin: 0;
}

.content-section .home-content-section .raffle-result .ranks-title {
  width: 5.55rem;
  margin: auto;
}

.content-section .home-content-section .raffle-result .ranks-title span {
  font-size: 0.24rem;
  font-weight: bolder;
  font-family: "AvenirNextLTPro-Bold";
}

.content-section .home-content-section .raffle-result .tools {
  padding: 0 0.5rem;
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-section .home-content-section .raffle-result .tools>p {
  font-size: 0.14rem;
  color: #222;
  font-family: OpenSans;
  white-space: nowrap;
}

.content-section .home-content-section .raffle-result .search-item input {
  cursor: pointer;
  margin-right: 0.14rem;
  font-size: 0.16rem;
}

.content-section .home-content-section .raffle-guide {
  width: 7.03rem;
}

.content-section .home-content-section .raffle-guide .videoContent iframe {
  border-style: none;
  height: 3.8rem;
  width: 6.8rem;
}

@media (max-width: 768px) {
  .content-section .home-content-section .raffle-guide .videoContent iframe {
    height: 7rem;
    width: 11.8rem;
  }
}

.content-section .home-content-section .raffle-guide .content-top {
  padding: 0.21rem 0.14rem 0.21rem 0;
  margin: 0;
}

.content-section .home-content-section .raffle-guide .content-center-box {
  padding-left: 0.3rem;
  padding-top: 0;
}

.content-section .home-content-section .raffle-guide .participate-outer {
  overflow: hidden;
  text-align: right;
}

.content-section .home-content-section .raffle-guide .participate-outer .participate-item {
  display: inline-block;
  padding: 0 1rem 0 0;
  height: 0.52rem;
  transform: skew(-45deg);
  transform-origin: 100% 0;
  font-size: 0.12rem;
  background: linear-gradient(to right, #19733d, #39b549);
}

.content-section .home-content-section .raffle-guide .participate-outer .participate-item>div {
  height: 100%;
  transform: skew(45deg);
  display: flex;
  align-items: center;
  padding-left: 0.55rem;
}

.content-section .home-content-section .raffle-guide .participate-outer .participate-item .participate-title {
  font-size: 0.26rem;
  color: #fff;
  font-family: "AvenirNextLTPro";
  font-family: "AvenirNextLTPro";
}

html:lang(vi) .content-section .home-content-section .raffle-guide .participate-outer .participate-item .participate-title {
  font-family: "Alexandria";
  font-weight: 400;
}

.content-section .home-content-section .raffle-guide .participate-content {
  display: inline-block;
  width: 6.1rem;
}

.content-section .home-content-section .raffle-guide .participate-content li {
  position: relative;
}

.content-section .home-content-section .raffle-guide .participate-content li::before {
  content: "";
  display: list-item;
  list-style: square;
  color: #39b54a;
  font-size: 0.25rem;
  position: absolute;
  top: -0.08rem;
}

.content-section .home-content-section .raffle-guide .participate-content li p {
  color: #000;
  font-size: 0.18rem;
  margin-bottom: 0.18rem;
  font-family: "AvenirNextLTPro";
  line-height: 0.25rem;
}

.content-section .home-content-section .raffle-guide .participate-content li p.text-notice {
  font-weight: bolder;
  font-family: "AvenirNextLTPro-Bold";
}

.content-section .home-content-section .raffle-guide .participate-content li p .text-highlight {
  color: #41b850;
  font-weight: bolder;
  font-family: "AvenirNextLTPro-Bold";
  font-size: 0.18rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-provider {
  display: flex;
  margin-left: -0.15rem;
  padding-top: 0.16rem;
  padding-bottom: 0.32rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-provider img {
  margin-right: 0.23rem;
  width: 100%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-provider .sg {
  max-width: 0.75rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-provider .mg {
  max-width: 0.7rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-provider .ul {
  max-width: 0.6rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-provider .sa {
  max-width: 0.51rem;
  margin-right: 0.18rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-provider .pg {
  max-width: 1rem;
}

.content-section .home-content-section .raffle-guide .participate-content .border-bottom {
  border-bottom: 0.01rem solid #e5e5e5;
  margin-left: -0.25rem;
  margin-bottom: 0.3rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box {
  position: relative;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box li {
  max-width: 3.7rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .participate-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-width: 2.18rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) {
  height: 2.13rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) li {
  max-width: 4rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) li p:nth-child(2) {
  max-width: 3.5rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) li .text-highlight {
  line-height: 0.35rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(9) {
  height: 3.5rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(9) li {
  max-width: 100%;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .text-prize {
  font-weight: bolder;
  font-family: "AvenirNextLTPro-Bold";
  font-size: 0.18rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks {
  flex-direction: unset;
  align-items: unset;
  justify-content: unset;
  margin-top: 0.1rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li {
  width: 1.48rem;
  height: 0.5rem;
  margin-bottom: 0;
  margin-right: 0.05rem;
  padding-left: 0.52rem;
  padding-right: 0;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li::before {
  content: unset;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li .icon-lighting {
  width: 0.45rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li .icon-meda {
  height: 0.34rem;
  width: 0.24rem;
  left: 0.16rem;
  font-size: 0.16rem;
  line-height: 0.19rem;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li span {
  font-size: 0.16rem;
  font-family: "AvenirNextLTPro";
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li:nth-child(4) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  text-align: center;
}

.content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li:nth-child(4) span {
  font-size: 0.13rem;
}

.content-section .home-content-section .raffle-guide .participate-content .tnc-apply {
  font-size: 0.18rem;
  margin: unset;
  cursor: pointer;
}

.raffleBoard-container {
  width: 13.46rem;
  min-height: 6rem;
  padding-bottom: 1.55rem;
}

.raffleBoard-container .content-section .home-content-section li:first-child .content-top {
  padding: 0.05rem 0 0.21rem 0.58rem;
}

.raffleBoard-container .content-section .home-content-section .board-filter {
  display: flex;
  z-index: 2;
}

.raffleBoard-container .content-section .home-content-section .board-filter .year-box,
.raffleBoard-container .content-section .home-content-section .board-filter .month-box {
  position: relative;
}

.raffleBoard-container .content-section .home-content-section .board-filter .date-box {
  position: relative;
}

.raffleBoard-container .content-section .home-content-section .board-filter .year-outer,
.raffleBoard-container .content-section .home-content-section .board-filter .month-outer {
  overflow-x: hidden;
  text-align: right;
}

.raffleBoard-container .content-section .home-content-section .board-filter .date-outer {
  overflow-x: hidden;
  text-align: right;
}

.raffleBoard-container .content-section .home-content-section .board-filter .year-item,
.raffleBoard-container .content-section .home-content-section .board-filter .month-item {
  margin-top: 0.12rem;
  display: inline-block;
  width: 2.62rem;
  height: 0.42rem;
  font-size: 0.12rem;
  color: #858585;
  background: #e3e3e3;
}

.raffleBoard-container .content-section .home-content-section .board-filter .date-item {
  margin-top: 0.12rem;
  display: inline-block;
  width: 2.62rem;
  height: 0.42rem;
  font-size: 0.12rem;
  color: #858585;
  background: #e3e3e3;
  width: 2.45rem;
}

.raffleBoard-container .content-section .home-content-section .board-filter .year-item {
  transform: skew(-25deg);
  transform-origin: 0 100%;
}

.raffleBoard-container .content-section .home-content-section .board-filter .month-item {
  width: 2.45rem;
}

.raffleBoard-container .content-section .home-content-section .board-filter .sort-select {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 0.03rem;
  justify-content: space-around;
}

.raffleBoard-container .content-section .home-content-section .board-filter .sort-select>div {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.2rem;
}

.raffleBoard-container .content-section .home-content-section .board-filter .sort-select>div .icon-calender {
  padding-right: 0.35rem;
}

.raffleBoard-container .content-section .home-content-section .board-filter .sort-select>div .react-datepicker__input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.raffleBoard-container .content-section .home-content-section .board-filter .sort-select>div .react-datepicker-wrapper {
  width: 78%;
  cursor: pointer;
}

.raffleBoard-container .content-section .home-content-section .board-filter .sort-select>div .react-datepicker-wrapper input {
  font-size: 0.2rem;
  background: rgba(0,0,0,0);
  border: none;
  width: -webkit-max-content;
  width: max-content;
  text-align: center;
  cursor: pointer;
}

.raffleBoard-container .content-section .home-content-section .board-filter .sort-select span {
  font-size: 0.16rem;
  font-family: "AvenirNextLTPro";
  font-weight: bold;
  color: #222;
}

.raffleBoard-container .content-section .home-content-section .board-filter .month-box {
  margin-left: 0.1rem;
}

.raffleBoard-container .content-section .home-content-section .board-filter .month-box .sort-select {
  padding-right: 0.08rem;
}

.raffleBoard-container .content-section .home-content-section .board-filter .date-box {
  margin-left: 0.1rem;
}

.raffleBoard-container .content-section .home-content-section .board-filter .spread {
  position: absolute;
  left: 0;
  top: 0.51rem;
  width: 100%;
  height: 1.3rem;
  background: #e3e3e3;
  z-index: 1;
  overflow: auto;
  padding: 0.2rem 0;
  text-align: center;
}

.raffleBoard-container .content-section .home-content-section .board-filter .spread li {
  height: 0.27rem;
  color: #222;
  transition: color .3s;
  white-space: nowrap;
}

.raffleBoard-container .content-section .home-content-section .board-filter .spread li:hover {
  color: #216e43;
}

.raffleBoard-container .winner-board {
  width: 7.98rem;
  margin-right: 0.15rem;
}

.raffleBoard-container .winner-board .content-center {
  padding: 0 0.5rem;
}

.raffleBoard-container .winner-board .raffle-board {
  z-index: 1;
}

.raffleBoard-container .winner-board .raffle-board .board-date {
  margin: auto;
}

.raffleBoard-container .winner-board .raffle-board .board-date span {
  font-size: 0.25rem;
  font-weight: bolder;
  font-family: "AvenirNextLTPro";
  padding-left: 0.15rem;
}

.raffleBoard-container .winner-board .raffle-board .board-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #98a0a5;
  padding: 0.1rem 0;
  margin-top: 0.3rem;
  margin-bottom: 0.05rem;
}

.raffleBoard-container .winner-board .raffle-board .board-title span {
  font-size: 0.25rem;
  font-weight: bolder;
  font-family: "AvenirNextLTPro";
  color: #fff;
  text-transform: uppercase;
}

.raffleBoard-container .winner-board .raffle-board .ranks {
  margin-top: 0;
  margin-bottom: 0.3rem;
}

.raffleBoard-container .winner-board .raffle-board .ranks li {
  width: 100%;
}

.raffleBoard-container .winner-board .raffle-board .special-ranks {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.01rem;
  background: #e6e6e6;
}

.raffleBoard-container .winner-board .raffle-board .special-ranks li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.12rem 0;
  border: 0.01rem solid #fff;
  border-top: none;
  border-left: none;
}

.raffleBoard-container .winner-board .raffle-board .special-ranks li:nth-child(5n) {
  border-right: none;
}

.raffleBoard-container .winner-board .raffle-board .special-ranks li:last-child {
  border-bottom: none;
}

.raffleBoard-container .winner-board .raffle-board .special-ranks li span {
  font-size: 0.15rem;
}

.raffleBoard-container .winner-board .raffle-board .content-center-box {
  padding-top: 0.75rem;
}

.raffleBoard-container .winner-board .raffle-board .content-center {
  padding-top: 0.36rem;
  padding-bottom: 0.48rem;
}

.raffleBoard-container .winner-board .raffle-board:not(:nth-child(-n+3)) .content-center-box {
  padding-top: 0;
}

.raffleBoard-container .winner-board .raffle-board:nth-last-child(2) {
  margin-bottom: 1.06rem;
}

.raffleBoard-container .winner-list {
  width: 5.29rem;
}

.raffleBoard-container .winner-list .content-center {
  padding: 0 .25rem;
}

.raffleBoard-container .winner-list .raffle-board {
  z-index: 1;
}

.raffleBoard-container .winner-list .raffle-board:not(:first-child) .content-center-box {
  padding-top: 0.07rem;
}

.raffleBoard-container .winner-list .raffle-board .board-date {
  margin: auto;
}

.raffleBoard-container .winner-list .raffle-board .board-date span {
  font-size: 0.25rem;
  font-weight: bolder;
  font-family: "AvenirNextLTPro";
  padding-left: 0.15rem;
}

.raffleBoard-container .winner-list .raffle-board .board-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #98a0a5;
  padding: 0.1rem 0;
  margin-top: 0.3rem;
  margin-bottom: 0.05rem;
}

.raffleBoard-container .winner-list .raffle-board .board-title span {
  font-size: 0.25rem;
  font-weight: bolder;
  font-family: "AvenirNextLTPro";
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
}

.raffleBoard-container .winner-list .raffle-board .ranks {
  margin-top: 0;
  margin-bottom: 0.2rem;
}

.raffleBoard-container .winner-list .raffle-board .ranks li {
  width: 100%;
  padding-left: 0.7rem;
  padding-right: 0.2rem;
}

.raffleBoard-container .winner-list .raffle-board .ranks li .icon-meda {
  left: 0.22rem;
}

.raffleBoard-container .winner-list .raffle-board .ranks li span:nth-child(1) {
  font-size: 0.2rem;
}

.raffleBoard-container .winner-list .raffle-board .ranks li span:nth-child(4) {
  font-size: 0.3rem;
  white-space: nowrap;
}

.raffleBoard-container .winner-list .raffle-board .special-ranks {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: .01rem;
  background: #e6e6e6;
}

.raffleBoard-container .winner-list .raffle-board .special-ranks li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.16rem;
  height: 0.36rem;
  border: 0.01rem solid #fff;
  border-top: none;
  border-left: none;
}

.raffleBoard-container .winner-list .raffle-board .special-ranks li:nth-child(6n) {
  border-right: none;
}

.raffleBoard-container .winner-list .raffle-board .special-ranks li:last-child {
  border-bottom: none;
}

.raffleBoard-container .winner-list .raffle-board .special-ranks li span {
  font-size: .8vw;
}

.raffleBoard-container .winner-list .raffle-board .content-center-box {
  padding-top: 0.75rem;
}

.raffleBoard-container .winner-list .raffle-board .content-center {
  padding-top: 0.36rem;
  padding-bottom: 0.48rem;
}

.raffleBoard-container .winner-list .raffle-board .tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.raffleBoard-container .winner-list .raffle-board .tools>p {
  font-size: 0.14rem;
  color: #222;
  font-family: OpenSans;
  white-space: nowrap;
}

.raffle-comingSoon {
  width: 100%;
  height: 100%;
}

@media (max-width: 1025px) {
  .raffle-countdown {
    transform: translate(0, 4.12rem);
  }

  .content-section .home-content-section .raffle-result {
    width: 9rem;
  }

  .content-section .home-content-section .raffle-result .search-item input {
    font-size: 0.24rem;
    margin-right: 0.35rem;
  }

  .content-section .home-content-section .raffle-result .ranks-title {
    width: 8.24rem;
  }

  .content-section .home-content-section .raffle-result .ranks-title span {
    font-size: 0.35rem;
  }

  .content-section .home-content-section .raffle-result .tools>p {
    font-size: 0.2rem;
  }

  .content-section .home-content-section .raffle-guide {
    width: 9.5rem;
  }

  .content-section .home-content-section .raffle-guide .content-top {
    height: 1.2rem;
  }

  .content-section .home-content-section .raffle-guide .content-center-box {
    padding-left: 0.55rem;
  }

  .content-section .home-content-section .raffle-guide .participate-outer .participate-item {
    width: 5.5rem;
    height: 0.7rem;
  }

  .content-section .home-content-section .raffle-guide .participate-outer .participate-item .participate-title {
    font-size: 0.35rem;
  }

  .content-section .home-content-section .raffle-guide .participate-outer .participate-item>div {
    padding-left: 0.7rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content {
    width: 8.5rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content li::before {
    font-size: 0.45rem;
    top: -0.16rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content li p {
    font-size: 0.25rem;
    line-height: 0.35rem;
    margin-bottom: 0.3rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content li p .text-highlight {
    font-size: 0.35rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider {
    width: 9.7rem;
    flex-wrap: wrap;
    padding-bottom: 0.12rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider img {
    padding-bottom: 0.4rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .sg {
    max-width: 1.48rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .mg {
    max-width: 1.38rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .ttg {
    max-width: 1.76rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .ul {
    max-width: 1.18rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .sa {
    max-width: 1.02rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .pg {
    max-width: 1.52rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .gg {
    max-width: 1.44rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box li {
    max-width: 5rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) {
    height: 3.2rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) li {
    max-width: 5.5rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) li p:nth-child(2) {
    max-width: unset;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) li .text-highlight {
    line-height: 0.5rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(9) {
    height: 3.5rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .text-prize {
    font-size: 0.3rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li {
    width: 1.8rem;
    height: 0.5rem;
    padding-left: 0.65rem;
    margin-right: 0.1rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li:nth-child(4) span {
    font-size: 0.19rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li .icon-lighting {
    width: 0.6rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li .icon-meda {
    height: 0.45rem;
    width: 0.3rem;
    left: 0.2rem;
    font-size: 0.2rem;
    line-height: 0.1rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li span {
    font-size: 0.26rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .participate-img {
    max-width: 3rem;
  }

  .raffleDraw-container .icon-calender {
    width: 0.5rem;
    height: 0.45rem;
  }

  .raffleDraw-container .ranks li {
    width: 8.24rem;
    height: 0.72rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4) {
    height: 0.52rem;
    margin-bottom: 0.05rem;
  }

  .raffleDraw-container .ranks li .icon-meda {
    font-size: 0.25rem;
    line-height: 0.25rem;
    padding-bottom: 0.08rem;
  }

  .raffleDraw-container .ranks li>span:nth-child(1) {
    font-size: 0.26rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4) .icon-meda {
    font-size: 0.2rem;
    line-height: 0.46rem;
    height: 0.5rem;
    width: 0.5rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4) .icon-meda::after {
    width: 0.35rem;
    height: 0.35rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4)>span:nth-child(1) {
    font-size: 0.2rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4)>span:nth-child(4) {
    font-size: 0.25rem;
  }

  .raffleBoard-container {
    width: 18rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .year-item,
  .raffleBoard-container .content-section .home-content-section .board-filter .month-item {
    width: 3.61rem;
    height: 0.6rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .date-item {
    width: 3.61rem;
    height: 0.6rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .sort-select {
    margin-top: 0;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .sort-select span {
    font-size: 0.24rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .sort-select>div .react-datepicker-wrapper input {
    font-size: 0.25rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .spread {
    top: 0.69rem;
    height: 2rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .spread li {
    height: 0.5rem;
    font-size: 0.24rem;
  }

  .raffleBoard-container .ranks li {
    width: 6.5rem;
  }

  .raffleBoard-container .winner-board {
    width: 10.43rem;
  }

  .raffleBoard-container .winner-board .raffle-board .board-date span,
  .raffleBoard-container .winner-board .raffle-board .board-title span {
    font-size: 0.3rem;
  }

  .raffleBoard-container .winner-board .raffle-board .special-ranks li span {
    font-size: .2rem;
  }

  .raffleBoard-container .winner-list {
    width: 6rem;
  }

  .raffleBoard-container .winner-list .raffle-board .board-date span,
  .raffleBoard-container .winner-list .raffle-board .board-title span {
    font-size: 0.3rem;
  }

  .raffleBoard-container .winner-list .raffle-board .ranks li {
    padding-left: 0.9rem;
  }

  .raffleBoard-container .winner-list .raffle-board .ranks li span:nth-child(1) {
    font-size: 0.22rem;
  }

  .raffleBoard-container .winner-list .raffle-board .tools>p {
    font-size: 0.18rem;
  }

  .raffleBoard-container .react-datepicker {
    border-radius: .04rem !important;
    font-size: .13rem !important;
    border: .01rem solid #dadada !important;
    width: 100% !important;
  }

  .raffleBoard-container .react-datepicker-popper[data-placement^=bottom] {
    margin-top: 0.25rem;
    margin-left: -0.5rem;
  }

  .raffleBoard-container .react-datepicker__header {
    border-top-left-radius: .04rem !important;
    border-top-right-radius: .04rem !important;
    border-bottom: .01rem solid #dadada !important;
  }

  .raffleBoard-container .react-datepicker__navigation {
    line-height: .08rem !important;
    border: .1rem solid rgba(0,0,0,0) !important;
  }

  .raffleBoard-container .react-datepicker__navigation--previous {
    border-right-color: #ccc !important;
  }

  .raffleBoard-container .react-datepicker__navigation--next {
    border-left-color: #ccc !important;
  }

  .raffleBoard-container .react-datepicker__month-container {
    float: left;
    max-width: 6rem !important;
    min-width: 4rem !important;
    width: 100% !important;
  }

  .raffleBoard-container .react-datepicker__time-container {
    width: 1rem;
  }

  .raffleBoard-container .react-datepicker__current-month,
  .raffleBoard-container .react-datepicker-time__header,
  .raffleBoard-container .react-datepicker-year-header {
    font-size: 0.25rem !important;
  }

  .raffleBoard-container .react-datepicker__day-name,
  .raffleBoard-container .react-datepicker__day,
  .raffleBoard-container .react-datepicker__time-name {
    width: 12% !important;
    line-height: 0.5rem !important;
    margin: 0.05rem !important;
    font-size: 0.25rem;
  }

  .raffleBoard-container .react-datepicker__time-box {
    width: 1.85rem;
  }

  .raffleBoard-container .react-datepicker__time-list-item {
    font-size: 0.25rem;
    height: 0.5rem;
    padding: 0.1rem;
  }

  .raffleBoard-container .react-datepicker__month {
    margin: .13rem !important;
  }

  .raffleBoard-container .react-datepicker__day--selected,
  .raffleBoard-container .react-datepicker__day--in-selecting-range,
  .raffleBoard-container .react-datepicker__day--in-range,
  .raffleBoard-container .react-datepicker__month-text--selected,
  .raffleBoard-container .react-datepicker__month-text--in-selecting-range,
  .raffleBoard-container .react-datepicker__month-text--in-range,
  .raffleBoard-container .react-datepicker__quarter-text--selected,
  .raffleBoard-container .react-datepicker__quarter-text--in-selecting-range,
  .raffleBoard-container .react-datepicker__quarter-text--in-range {
    border-radius: .04rem !important;
    background-color: #005329 !important;
    color: #fff;
  }

  .raffleBoard-container .react-datepicker__day:hover,
  .raffleBoard-container .react-datepicker__month-text:hover,
  .raffleBoard-container .react-datepicker__quarter-text:hover {
    border-radius: .04rem !important;
  }

  .raffleBoard-container .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #005329 !important;
    border-radius: .04rem !important;
  }
}

@media (max-width: 768px) {
  .raffle-countdown {
    transform: translate(0, 2.95rem);
  }

  .raffle-countdown>img {
    max-width: 7rem;
  }

  .raffle-countdown .countdown::before {
    width: 4.7rem;
    height: 1.1rem;
    transform: translate(-50%, 50%);
  }

  .raffle-countdown .countdown ul li>span:nth-child(1) {
    width: 1.05rem;
    height: 1.05rem;
    font-size: 0.8rem;
  }

  .raffle-countdown .countdown ul li>span:nth-child(2) {
    font-size: 0.25rem;
  }

  .content-section .home-content-section .raffle-result {
    margin: 0 auto;
    width: 11.6rem;
  }

  .content-section .home-content-section .raffle-result .search-item-outer {
    position: unset;
    transform: translateY(0.3rem);
  }

  .content-section .home-content-section .raffle-result .search-item>div input {
    width: 2.8rem;
  }

  .content-section .home-content-section .raffle-result .content-center-box {
    padding-top: 0;
  }

  .content-section .home-content-section .raffle-result .ranks-title {
    width: 9.16rem;
  }

  .content-section .home-content-section .raffle-result .ranks-title span {
    font-size: 0.45rem;
  }

  .content-section .home-content-section .raffle-result .tools {
    margin-top: 0.47rem;
    padding: 0 1.24rem;
  }

  .content-section .home-content-section .raffle-result .tools>p {
    font-size: 0.23rem;
  }

  .content-section .home-content-section .raffle-guide {
    margin: 0 auto;
    width: 11.6rem;
  }

  .content-section .home-content-section .raffle-guide .content-top {
    height: 1.5rem;
  }

  .content-section .home-content-section .raffle-guide .videoContent {
    margin: .28rem 0;
  }

  .content-section .home-content-section .raffle-guide .content-center-box {
    padding-top: 0;
    padding-left: 0.65rem;
  }

  .content-section .home-content-section .raffle-guide .participate-outer .participate-item {
    width: 10.5rem;
    height: 1.7rem;
    padding: 0 1.5rem;
  }

  .content-section .home-content-section .raffle-guide .participate-outer .participate-item>div {
    padding-left: 0;
  }

  .content-section .home-content-section .raffle-guide .participate-outer .participate-item .participate-title {
    font-size: 0.61rem;
    text-align: left;
  }

  .content-section .home-content-section .raffle-guide .participate-content {
    width: unset;
  }

  .content-section .home-content-section .raffle-guide .participate-content li::before {
    font-size: 0.55rem;
    top: -0.03rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content li p {
    font-size: 0.38rem;
    line-height: 0.6rem;
    margin-bottom: 0.3rem;
    padding-top: 0.2rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider {
    width: unset;
    flex-wrap: unset;
    padding-bottom: 0.3rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider img {
    margin-right: 0.45rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .sg {
    max-width: 1.2rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .mg {
    max-width: 1.12rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .ttg {
    max-width: 1.43rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .ul {
    max-width: 0.96rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .sa {
    max-width: 0.83rem;
    margin-right: 0.35rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .pg {
    max-width: 1.24rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-provider .gg {
    max-width: 1.17rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .border-bottom {
    border-bottom: 0.05rem solid #e5e5e5;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box li {
    max-width: 7.2rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .participate-img {
    max-width: 4rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) {
    height: 5rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(7) li {
    max-width: 7.5rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box:nth-child(9) {
    height: 6rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .text-prize {
    font-size: 0.4rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li {
    width: 2.6rem;
    height: 1rem;
    padding-left: 0.85rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li:nth-child(4) span {
    font-size: 0.23rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li .icon-lighting {
    width: 0.8rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li .icon-meda {
    height: 0.65rem;
    width: 0.45rem;
    font-size: 0.33rem;
    line-height: 0.33rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .participate-box .ranks li span {
    font-size: 0.35rem;
  }

  .content-section .home-content-section .raffle-guide .participate-content .tnc-apply {
    margin: 2rem 0 0 0;
    font-size: 0.38rem;
  }

  .raffleDraw-container .icon-calender {
    width: 0.8rem;
    height: 0.7rem;
  }

  .raffleDraw-container .ranks li {
    width: 9.16rem;
    height: 0.78rem;
    margin-bottom: 0.08rem;
    padding-left: 1.45rem;
    padding-right: 0.45rem;
  }

  .raffleDraw-container .ranks li .icon-lighting {
    width: 0.8rem;
  }

  .raffleDraw-container .ranks li .icon-meda {
    font-size: 0.3rem;
    line-height: 0.36rem;
    padding-bottom: 0.03rem;
  }

  .raffleDraw-container .ranks li>span:nth-child(1) {
    font-size: 0.36rem;
  }

  .raffleDraw-container .ranks li>span:nth-child(1):lang(vi) {
    font-size: 0.32rem;
  }

  .raffleDraw-container .ranks li>span:nth-child(4) {
    font-size: 0.45rem;
  }

  .raffleDraw-container .ranks li>span:nth-child(4):lang(vi) {
    font-size: 0.38rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4) {
    height: 0.65rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4) .icon-meda {
    height: 0.65rem;
    font-size: 0.25rem;
    line-height: 0.6rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4) .icon-meda::after {
    width: 0.5rem;
    height: 0.5rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4)>span:nth-child(1) {
    font-size: 0.28rem;
  }

  .raffleDraw-container .ranks li:nth-child(n+4)>span:nth-child(4) {
    font-size: 0.38rem;
  }

  .raffleBoard-container {
    width: 100%;
    min-height: 16.15rem;
    margin-top: 2.2rem;
  }

  .raffleBoard-container .content-section .home-content-section li:first-child .content-top {
    align-items: flex-start;
    padding: 0.05rem 0.4rem 0.21rem;
    height: 1.5rem;
  }

  .raffleBoard-container .content-section .home-content-section li:first-child .content-top .content-title {
    margin: auto 0;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter {
    flex-direction: column;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .year-item,
  .raffleBoard-container .content-section .home-content-section .board-filter .month-item {
    width: 5.66rem;
    height: 0.98rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .date-item {
    width: 5.66rem;
    height: 0.98rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .icon-arrow-down2 {
    width: 0.25rem;
    height: 0.18rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .month-box {
    margin-left: 0;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .month-box .sort-select {
    padding-left: 0.3rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .date-box {
    margin-left: 0;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .sort-select {
    margin-top: 0;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .sort-select>div {
    padding: 0 0.5rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .sort-select>div .react-datepicker-wrapper input {
    font-size: 0.4rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .sort-select span {
    font-size: 0.3rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .spread {
    top: 1.1rem;
    height: 2.3rem;
  }

  .raffleBoard-container .content-section .home-content-section .board-filter .spread li {
    font-size: 0.3rem;
    height: 0.6rem;
  }

  .raffleBoard-container .winner-board {
    width: 11.6rem;
    margin: 0 auto;
  }

  .raffleBoard-container .winner-board .raffle-board .board-date span,
  .raffleBoard-container .winner-board .raffle-board .board-title span {
    font-size: 0.45rem;
    padding-left: 0.25rem;
  }

  .raffleBoard-container .winner-board .raffle-board .content-center-box {
    padding-top: 1rem;
  }

  .raffleBoard-container .winner-board .raffle-board .special-ranks li span {
    font-size: .2rem;
  }

  .raffleBoard-container .winner-list {
    width: 11.6rem;
    margin: 0 auto;
  }

  .raffleBoard-container .winner-list .raffle-board:not(:first-child) .content-center-box {
    padding-top: 0.5rem;
  }

  .raffleBoard-container .winner-list .raffle-board .board-date span,
  .raffleBoard-container .winner-list .raffle-board .board-title span {
    font-size: 0.45rem;
    padding-left: 0.25rem;
  }

  .raffleBoard-container .winner-list .raffle-board .content-center-box {
    padding-top: 0.5rem;
  }

  .raffleBoard-container .winner-list .raffle-board .ranks li span:nth-child(1) {
    font-size: 0.32rem;
  }

  .raffleBoard-container .winner-list .raffle-board .ranks li span:nth-child(4) {
    font-size: 0.48rem;
  }

  .raffleBoard-container .winner-list .raffle-board .tools>p {
    font-size: 0.27rem;
  }

  .raffleBoard-container .react-datepicker {
    border-radius: .04rem !important;
    font-size: .13rem !important;
    border: .01rem solid #dadada !important;
  }

  .raffleBoard-container .react-datepicker-popper[data-placement^=bottom] {
    margin-top: 0.25rem;
    margin-left: 0.15rem;
  }

  .raffleBoard-container .react-datepicker__header {
    border-top-left-radius: .04rem !important;
    border-top-right-radius: .04rem !important;
    border-bottom: .01rem solid #dadada !important;
  }

  .raffleBoard-container .react-datepicker__navigation {
    line-height: .08rem !important;
    border: .1rem solid rgba(0,0,0,0) !important;
  }

  .raffleBoard-container .react-datepicker__navigation--previous {
    border-right-color: #ccc !important;
  }

  .raffleBoard-container .react-datepicker__navigation--next {
    border-left-color: #ccc !important;
  }

  .raffleBoard-container .react-datepicker__month-container {
    float: left;
    max-width: 8rem !important;
    min-width: 5rem !important;
  }

  .raffleBoard-container .react-datepicker__time-container {
    width: 2rem;
  }

  .raffleBoard-container .react-datepicker__current-month,
  .raffleBoard-container .react-datepicker-time__header,
  .raffleBoard-container .react-datepicker-year-header {
    font-size: 0.36rem !important;
  }

  .raffleBoard-container .react-datepicker__day-name,
  .raffleBoard-container .react-datepicker__day,
  .raffleBoard-container .react-datepicker__time-name {
    width: 0.5rem !important;
    line-height: 0.5rem !important;
    margin: 0.15rem !important;
    font-size: 0.36rem;
  }

  .raffleBoard-container .react-datepicker__time-box {
    width: 1.85rem;
  }

  .raffleBoard-container .react-datepicker__time-list-item {
    font-size: 0.36rem;
    height: 0.5rem;
    padding: 0.1rem;
  }

  .raffleBoard-container .react-datepicker__month {
    margin: .13rem !important;
  }

  .raffleBoard-container .react-datepicker__day--selected,
  .raffleBoard-container .react-datepicker__day--in-selecting-range,
  .raffleBoard-container .react-datepicker__day--in-range,
  .raffleBoard-container .react-datepicker__month-text--selected,
  .raffleBoard-container .react-datepicker__month-text--in-selecting-range,
  .raffleBoard-container .react-datepicker__month-text--in-range,
  .raffleBoard-container .react-datepicker__quarter-text--selected,
  .raffleBoard-container .react-datepicker__quarter-text--in-selecting-range,
  .raffleBoard-container .react-datepicker__quarter-text--in-range {
    border-radius: .04rem !important;
    background-color: #005329 !important;
    color: #fff;
  }

  .raffleBoard-container .react-datepicker__day:hover,
  .raffleBoard-container .react-datepicker__month-text:hover,
  .raffleBoard-container .react-datepicker__quarter-text:hover {
    border-radius: .04rem !important;
  }

  .raffleBoard-container .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #005329 !important;
    border-radius: .04rem !important;
  }
}

@media (min-width: 768px) {
  .home-content-section:lang(vi)>li.home-content-raffle .content-center .ranks li>span:nth-child(4) {
    font-size: 0.28rem;
  }

  .home-content-section:lang(vi)>li.home-content-raffle .content-center .ranks li>span:first-child {
    font-size: 0.2rem;
  }

  .raffle-result:lang(vi) .ranks li>span:nth-child(1) {
    font-size: 0.2rem;
  }

  .raffle-result:lang(vi) .ranks li>span:nth-child(4) {
    font-size: 0.24rem;
  }

  .raffle-result:lang(vi) .ranks li:nth-child(n+4)>span:nth-child(1) {
    font-size: 0.16rem;
  }

  .raffle-result:lang(vi) .ranks li:nth-child(n+4)>span:nth-child(4) {
    font-size: 0.24rem;
  }

  .winner-board:lang(vi) .raffle-board .board-title span {
    font-size: 0.18rem;
  }

  .winner-board:lang(vi) .ranks li>span:nth-child(4) {
    font-size: 0.28rem;
  }

  .winner-list:lang(vi) .raffle-board .board-title span {
    font-size: 0.18rem;
  }

  .winner-list:lang(vi) .raffle-board .ranks li span:nth-child(1) {
    font-size: 0.18rem;
  }

  .winner-list:lang(vi) .raffle-board .ranks li span:nth-child(4) {
    font-size: 0.24rem;
  }

  .content-section:lang(vi) .home-content-section .raffle-guide .participate-content .participate-box .ranks li span {
    font-size: 0.15rem;
  }
}

.content-promopage .content-center-box {
  padding-top: 0.5rem;
  margin-bottom: 1.25rem;
}

.content-promopage .promotion-nav-tab {
  display: flex;
  align-items: center;
  height: 0.4rem;
  overflow: hidden;
  margin-bottom: 0.4rem;
  background-color: #ebebeb;
}

.content-promopage .promotion-nav-tab .promo-tab-item {
  width: 1.9rem;
  height: 100%;
  cursor: pointer;
}

.content-promopage .promotion-nav-tab .promo-tab-item .promo-nav-link .promo-tab-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: -0.12rem;
}

.content-promopage .promotion-nav-tab .promo-tab-item .promo-nav-link .promo-tab-inner .promo-header-text {
  margin-right: 0.17rem;
  z-index: 1;
  color: #858585;
  font-size: 0.16rem;
  margin-left: 0.12rem;
}

.content-promopage .promotion-nav-tab .promo-tab-item .active .promo-tab-inner {
  position: relative;
  transform: skew(-35deg);
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
}

.content-promopage .promotion-nav-tab .promo-tab-item .active .promo-tab-inner .promo-header-text {
  color: #000;
  transform: skew(35deg);
  margin-left: 0.12rem;
  font-weight: bold;
}

.content-promopage .promo-list {
  display: block;
}

.content-promopage .promo-list .promotion-container {
  display: flex;
  height: 1.66rem;
  margin-bottom: 0.2rem;
}

.content-promopage .promo-list .promotion-container .promotion-image {
  width: 5.11rem;
  height: 1.66rem;
}

.content-promopage .promo-list .promotion-container .promotion-image img {
  width: 100%;
  height: 100%;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail {
  background-color: #f5f5f5;
  width: 9.28rem;
  height: 1.66rem;
  padding-left: 0.52rem;
  padding-right: 0.3rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-title {
  font-size: 0.24rem;
  font-weight: bold;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-detail-desc {
  font-size: 0.16rem;
  color: #1e1e1e;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group {
  display: flex;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group .btn-promo-details {
  background: #18723c;
  border: 0;
  color: #fff;
  margin-right: 0.1rem;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group .btn-promoapply {
  border: 0;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container {
  display: flex;
  font-size: 0.14rem;
  color: #858585;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container {
  display: flex;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .day-box {
  display: flex;
  padding: 0 0.05rem;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .time-box {
  display: flex;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .day-number,
.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .time-hour {
  color: #39b549;
  margin: 0 0.05rem 0 0;
}

.content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promo-type {
  font-size: 0.12rem;
  color: #39b549;
}

.promo-transferprovider-modal .popup-body {
  display: flex;
}

.promo-transferprovider-modal .popup-body .promo-wallet {
  width: 40%;
  height: 100%;
  padding: 0.2rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-right: 0.01rem solid #dfdfdf;
}

.promo-transferprovider-modal .popup-body .promo-wallet .wallet {
  display: flex;
  flex-flow: column;
  justify-items: center;
  margin-bottom: 0.2rem;
}

.promo-transferprovider-modal .popup-body .promo-wallet .wallet .main-wallet {
  display: flex;
  align-items: center;
}

.promo-transferprovider-modal .popup-body .promo-wallet .wallet .main-wallet .promo-icon-wallet {
  width: 0.6rem;
  padding: 0.15rem;
  fill: #19723c;
  border: 0.02rem solid #19723c;
  border-radius: 50%;
  overflow: visible;
}

.promo-transferprovider-modal .popup-body .promo-wallet .wallet .main-wallet .promo-icon-restore {
  width: 0.45rem;
  height: 0.45rem;
  fill: #19723c;
  cursor: pointer;
}

.promo-transferprovider-modal .popup-body .promo-wallet .wallet .main-wallet .wallet-info {
  width: 0.8rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 0.15rem;
  margin: 0 0.2rem;
}

.promo-transferprovider-modal .popup-body .promo-wallet .wallet .promo-icon-arrow {
  width: 0.6rem;
  height: 0.25rem;
  margin: 0.05rem 0;
  fill: #19723c;
}

.promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet {
  display: flex;
  align-items: center;
}

.promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet .provider-selected {
  width: 0.6rem;
  height: 0.6rem;
  fill: #19723c;
  border: 0.02rem solid #19723c;
  border-radius: 50%;
  overflow: visible;
}

.promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet .wallet-info {
  width: 0.8rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 0.15rem;
  margin: 0 0.2rem;
}

.promo-transferprovider-modal .popup-body .promo-wallet .amount {
  margin-bottom: 0.2rem;
}

.promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-title {
  font-size: 0.15rem;
}

.promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-input {
  border: 0.01rem solid #cecece;
  border-radius: 0.02rem;
  padding-left: 0.1rem;
  outline: none;
  font-size: 0.15rem;
  margin-top: 0.1rem;
}

.promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range .range-box {
  padding: 0;
  height: 0.2rem;
  margin-top: 0.1rem;
  border: none;
}

.promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range .range-box::-webkit-slider-thumb {
  background: #19723c;
}

.promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range .range-box::-webkit-slider-runnable-track {
  background: #cfe8d3;
}

.promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range .amount-info {
  font-size: 0.12rem;
}

.promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus {
  width: 100%;
  padding: 0.15rem;
  background-color: #cfe8d3;
  color: #005329;
  font-size: 0.15rem;
  white-space: nowrap;
}

.promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus .turnover,
.promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus .bonus {
  display: flex;
  align-items: center;
}

.promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus .turnover>label,
.promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus .bonus>label {
  width: 55%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus .turnover>span,
.promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus .bonus>span {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus .turnover {
  margin-bottom: 0.1rem;
}

.promo-transferprovider-modal .popup-body .promo-provider {
  width: 60%;
  height: 100%;
  padding: 0.2rem;
  display: flex;
  flex-flow: column;
}

.promo-transferprovider-modal .popup-body .promo-provider .provider-title {
  font-size: 0.15rem;
  margin-bottom: 0.1rem;
}

.promo-transferprovider-modal .popup-body .promo-provider .provider-list {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.promo-transferprovider-modal .popup-body .promo-provider .provider-list .provider-icon {
  width: 0.6rem;
  height: 0.6rem;
  fill: #19723c;
  border: 0.02rem solid #19723c;
  border-radius: 50%;
  overflow: visible;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
}

.promo-transferprovider-modal .popup-body .promo-provider .provider-list .provider-icon:nth-child(5n) {
  margin-right: 0;
}

@media (min-width: 768px)and (max-width: 1025px) {
  .content-promopage .promotion-nav-tab {
    display: flex;
    align-items: center;
    height: 0.7rem;
    overflow: hidden;
    margin-bottom: 0.25rem;
    background-color: #ebebeb;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item {
    width: 2.5rem;
    height: 100%;
    cursor: pointer;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .promo-nav-link .promo-tab-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: -0.2rem;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .promo-nav-link .promo-tab-inner .promo-header-text {
    margin-right: 0.17rem;
    z-index: 1;
    color: #858585;
    font-size: 0.23rem;
    margin-left: 0.2rem;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .active .promo-tab-inner {
    position: relative;
    transform: skew(-35deg);
    background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .active .promo-tab-inner .promo-header-text {
    color: #000;
    transform: skew(35deg);
    margin-left: 0.12rem;
    font-weight: bold;
  }

  .content-promopage .promo-list {
    display: block;
  }

  .content-promopage .promo-list .promotion-container {
    display: flex;
    height: 2.4rem;
    margin-bottom: 0.15rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-image {
    width: 36.74%;
    height: 2.4rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-image img {
    width: 100%;
    height: 100%;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail {
    width: 63.26%;
    background-color: #f5f5f5;
    padding-left: 0.52rem;
    padding-right: 0.3rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 2.4rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-title {
    font-size: 0.28rem;
    font-weight: bold;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-detail-desc {
    font-size: 0.22rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group {
    display: flex;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group .popup-promoinfo .btn-promo-details {
    background: #18723c;
    border: 0;
    color: #fff;
    margin-right: 0.1rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group .popup-promoinfo .btn-promoapply {
    width: 2.26rem;
    height: 0.54rem;
    border: 0;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promo-type {
    font-size: 0.16rem;
    color: #39b549;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .main-wallet .promo-icon-wallet {
    width: 1rem;
    padding: 0.25rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .main-wallet .promo-icon-restore {
    width: 0.8rem;
    height: 0.8rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .main-wallet .wallet-info {
    width: 2rem;
    font-size: 0.22rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .promo-icon-arrow {
    width: 1rem;
    height: 0.4rem;
    margin: 0.1rem 0;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet .provider-selected {
    width: 1rem;
    height: 1rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet .wallet-info {
    width: 2rem;
    font-size: 0.22rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-title {
    font-size: 0.22rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-input {
    font-size: 0.22rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range .range-box {
    margin-top: 0.2rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range .amount-info {
    font-size: 0.2rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus {
    padding: 0.22rem;
    font-size: 0.22rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus .turnover {
    margin-bottom: 0.15rem;
  }

  .promo-transferprovider-modal .popup-body .promo-provider .provider-title {
    font-size: 0.22rem;
    margin-bottom: 0.2rem;
  }

  .promo-transferprovider-modal .popup-body .promo-provider .provider-list .provider-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
  }
}

@media (max-width: 768px) {
  .content-promopage .content-center-box {
    padding: 0.72rem 0.77rem;
  }

  .content-promopage .promotion-nav-tab {
    display: flex;
    align-items: center;
    height: 0.82rem;
    overflow: hidden;
    margin-bottom: 0.25rem;
    background-color: #ebebeb;
    overflow-x: scroll;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item {
    width: auto;
    cursor: pointer;
    height: 100%;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .promo-nav-link {
    padding-right: 0.5rem;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .promo-nav-link .promo-tab-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
    margin-left: -0.27rem;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .promo-nav-link .promo-tab-inner .promo-header-text {
    margin-right: -0.1rem;
    margin-left: -0.1rem;
    z-index: 1;
    color: #858585;
    font-size: 0.3rem;
    white-space: nowrap;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .active .promo-tab-inner {
    position: relative;
    transform: skew(-35deg);
    background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
    padding: 0 1rem;
    margin-left: -0.27rem;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .active .promo-tab-inner .promo-header-text {
    color: #000;
    transform: skew(35deg);
    font-weight: bold;
    margin-right: -0.1rem;
    margin-left: -0.1rem;
  }

  .content-promopage .promotion-nav-tab .promo-tab-item .active .promo-tab-inner:last-child {
    margin-right: 0.27rem;
  }

  .content-promopage .promo-list {
    display: block;
  }

  .content-promopage .promo-list .promotion-container {
    display: flex;
    flex-direction: column;
    min-height: 6.5rem;
    margin-bottom: 0.3rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-image {
    width: 100%;
    height: auto;
    min-height: 3.6rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-image img {
    width: 100%;
    height: 100%;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail {
    width: 100%;
    background-color: #f5f5f5;
    min-height: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-title {
    font-size: 0.36rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-detail-desc {
    font-size: 0.28rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group {
    display: flex;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group .btn-promo-details {
    background: #18723c;
    border: 0;
    color: #fff;
    margin-right: 0.1rem;
    width: 2.5rem;
    height: 0.6rem;
    font-size: 0.28rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group .btn-promoapply {
    width: 2.5rem;
    height: 0.6rem;
    font-size: 0.28rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .promo-type {
    font-size: 0.24rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0.3rem;
  }

  .content-promopage .promo-list .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container {
    font-size: 0.24rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet {
    width: 100%;
    border: none;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet {
    flex-flow: row;
    margin-bottom: 0.35rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .main-wallet .promo-icon-restore {
    width: 1rem;
    height: 1rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .main-wallet .wallet-info {
    width: 2.3rem;
    font-size: 0.4rem;
    margin: 0;
    margin-right: 0.3rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .promo-icon-arrow {
    transform: rotate(270deg);
    width: 0.8rem;
    height: 1.5rem;
    margin: 0 0.5rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet {
    position: relative;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet .provider-selected {
    width: 1.5rem;
    height: 1.5rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet .provider-list {
    position: absolute;
    background: #f5f5f5;
    border: 0.01rem solid #13232e;
    top: 0;
    left: 0;
    width: 2rem;
    overflow-y: scroll;
    max-height: 6.7rem;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet .provider-list .provider-icon {
    width: 1.5rem;
    min-height: 1.5rem;
    border-bottom: 0.01rem solid #13232e;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .wallet .provider-wallet .provider-list .provider-icon:last-child {
    border-bottom: 0;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount {
    margin-bottom: 0.35rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-title {
    font-size: 0.4rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-input {
    font-size: 0.4rem;
    height: 1rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range {
    margin-top: 0.2rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range .range-box {
    height: 0.5rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .amount .amount-range .amount-info {
    font-size: 0.35rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus {
    font-size: 0.4rem;
    padding: 0.3rem;
  }

  .promo-transferprovider-modal .popup-body .promo-wallet .turnover-bonus .turnover {
    margin-bottom: 0.3rem;
  }
}

.about-container {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.content-aboutus {
  min-height: 4rem;
}

.content-aboutus .about-content {
  padding: 0.66rem 0.89rem 0.9rem 0.89rem;
}

.content-aboutus .about-content .about-banner {
  display: flex;
  justify-content: center;
}

.content-aboutus .about-content .about-banner img {
  width: 8.17rem;
  height: 4.3rem;
  margin-bottom: 0.68rem;
}

.content-aboutus .about-content p {
  font-size: 0.16rem;
  margin-bottom: 0.35rem;
}

.content-aboutus .about-content .about-provider {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.content-aboutus .about-content .about-provider img {
  margin-right: 0.4rem;
}

.content-aboutus .about-content .about-provider img:nth-child(1) {
  width: 10%;
}

.content-aboutus .about-content .about-provider img:nth-child(2) {
  width: 7.5%;
}

.content-aboutus .about-content .about-provider img:nth-child(3) {
  width: 12%;
}

.content-aboutus .about-content .about-provider img:nth-child(4) {
  width: 8%;
}

.content-aboutus .about-content .about-provider img:nth-child(5) {
  width: 15%;
}

@media (min-width: 768px)and (max-width: 1025px) {
  .content-aboutus {
    min-height: 4rem;
  }

  .content-aboutus .about-content {
    padding: 0.66rem 0.89rem 0.9rem 0.89rem;
  }

  .content-aboutus .about-content .about-banner {
    display: flex;
    justify-content: center;
  }

  .content-aboutus .about-content .about-banner img {
    width: 8.17rem;
    height: 4.3rem;
    margin-bottom: 0.63rem;
  }

  .content-aboutus .about-content p {
    font-size: 0.24rem;
    margin-bottom: 0.4rem;
  }

  .content-aboutus .about-content .about-provider {
    display: flex;
    margin-bottom: 0.5rem;
  }

  .content-aboutus .about-content .about-provider img {
    margin-right: 0.5rem;
  }

  .content-aboutus .about-content .about-provider img:nth-child(1) {
    width: 1.8rem;
    height: auto;
  }

  .content-aboutus .about-content .about-provider img:nth-child(2) {
    width: 1.4rem;
    height: auto;
  }

  .content-aboutus .about-content .about-provider img:nth-child(3) {
    width: 1.8rem;
    height: auto;
  }

  .content-aboutus .about-content .about-provider img:nth-child(4) {
    width: 1.5rem;
    height: auto;
  }

  .content-aboutus .about-content .about-provider img:nth-child(5) {
    width: 2rem;
    height: auto;
  }
}

@media (max-width: 768px) {
  .about-container {
    margin-top: 2.2rem;
  }

  .content-aboutus {
    min-height: 4rem;
  }

  .content-aboutus .about-content {
    padding: 0.66rem 0.89rem 0.9rem 0.89rem;
  }

  .content-aboutus .about-content .about-banner {
    display: flex;
    justify-content: center;
  }

  .content-aboutus .about-content .about-banner img {
    width: 8.17rem;
    height: 4.3rem;
    margin-bottom: 0.63rem;
  }

  .content-aboutus .about-content p {
    font-size: 0.28rem;
    margin-bottom: 0.4rem;
  }

  .content-aboutus .about-content .about-provider {
    display: flex;
    margin-bottom: 0.5rem;
  }

  .content-aboutus .about-content .about-provider img {
    margin-right: 0.5rem;
  }

  .content-aboutus .about-content .about-provider img:nth-child(1) {
    width: 1.8rem;
    height: auto;
  }

  .content-aboutus .about-content .about-provider img:nth-child(2) {
    width: 1.3rem;
    height: auto;
  }

  .content-aboutus .about-content .about-provider img:nth-child(3) {
    width: 1.7rem;
    height: auto;
  }

  .content-aboutus .about-content .about-provider img:nth-child(4) {
    width: 1.5rem;
    height: auto;
  }

  .content-aboutus .about-content .about-provider img:nth-child(5) {
    width: 1.85rem;
    height: auto;
  }
}

.terms-container {
  margin-top: -1.1rem;
  padding-bottom: 1rem;
  background: #fff;
}

.terms-container .terms-header .terms-header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.2rem;
  cursor: pointer;
}

.terms-container .terms-header .terms-header-logo img {
  max-height: 1rem;
  max-width: 3.16rem;
  height: auto;
  width: auto;
}

.terms-container .terms-header .terms-header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.6rem;
  background-color: #19723c;
  color: #fff;
  font-weight: bold;
  margin-bottom: 0.3rem;
  font-size: 0.18rem;
  text-transform: capitalize;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .terms-container .terms-header .terms-header-title {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.content-terms {
  min-height: 4rem;
}

.content-terms .content-center-box {
  padding-top: 0;
}

.content-terms .content-center-box .content-center {
  padding: 0.3rem 0;
}

.content-terms .content-center-box .content-center .terms-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.26rem;
  font-weight: bold;
  padding: 0.3rem 0;
  border-bottom: 0.01rem solid #d3d3d3;
  color: #19723c;
  margin: 0 0.3rem;
}

.content-terms .content-center-box .content-center .terms-logo svg {
  width: 1rem;
  height: 1rem;
  fill: #19723c;
}

.content-terms .content-center-box .content-center .terms-intro {
  padding: 0 0.7rem;
  color: #7e8c8d;
  margin: 0.3rem 0;
  font-size: 0.18rem;
}

.content-terms .content-center-box .content-center .terms-intro p {
  margin-bottom: 0.05rem;
}

.content-terms .content-center-box .content-center .terms-row {
  display: flex;
  flex-direction: column;
  margin: 0 0.3rem;
  background-color: #fff;
  border-bottom: 0.01rem solid #d3d3d3;
  position: relative;
}

.content-terms .content-center-box .content-center .terms-row .term-title {
  padding: 0.2rem 0.3rem;
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  align-items: center;
  font-weight: bold;
  color: #8d8d8d;
  cursor: pointer;
  transition: .3s;
  font-size: 0.18rem;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .content-terms .content-center-box .content-center .terms-row .term-title {
  font-family: "Alexandria";
  font-weight: 500;
}

.content-terms .content-center-box .content-center .terms-row .term-title:hover {
  background-color: #19723c;
  color: #fff;
}

.content-terms .content-center-box .content-center .terms-row .term-title.active-term {
  background-color: #19723c;
  color: #fff;
}

.content-terms .content-center-box .content-center .terms-row .term-title .rotate-arrowup {
  transform: rotate(0deg);
  transition: transform .3s;
}

.content-terms .content-center-box .content-center .terms-row .term-title .rotate-arrowdown {
  transform: rotate(180deg);
  transition: transform .3s;
}

.content-terms .content-center-box .content-center .terms-row .ReactCollapse--content {
  background-color: #f5f5f5;
  padding: 0.2rem 0.4rem;
}

.content-terms .content-center-box .content-center .terms-row .ReactCollapse--content div div {
  margin: 0.2rem 0;
  color: #8d8d8d;
  font-size: 0.16rem;
}

.content-terms .content-center-box .content-center .terms-row .terms-input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

@media (max-width: 1445px) {
  .terms-container .terms-header .terms-header-logo {
    height: 1.4rem;
  }

  .terms-container .terms-header .terms-header-title {
    height: 0.8rem;
    font-size: 0.24rem;
  }

  .content-terms .content-center-box .content-center .terms-logo {
    font-size: 0.32rem;
  }

  .content-terms .content-center-box .content-center .terms-logo svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: #19723c;
  }

  .content-terms .content-center-box .content-center .terms-intro {
    font-size: 0.24rem;
  }

  .content-terms .content-center-box .content-center .terms-row .term-title {
    font-size: 0.24rem;
  }

  .content-terms .content-center-box .content-center .terms-row .ReactCollapse--content div div {
    font-size: 0.2rem;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .terms-container {
    margin-top: -1.9rem;
  }

  .terms-container .terms-header .terms-header-logo {
    height: 1.4rem;
  }

  .terms-container .terms-header .terms-header-title {
    height: 0.8rem;
    font-size: 0.3rem;
  }

  .content-terms .content-center-box .content-center .terms-logo {
    font-size: 0.38rem;
  }

  .content-terms .content-center-box .content-center .terms-logo svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: #19723c;
  }

  .content-terms .content-center-box .content-center .terms-intro {
    font-size: 0.3rem;
  }

  .content-terms .content-center-box .content-center .terms-row .term-title {
    font-size: 0.3rem;
  }

  .content-terms .content-center-box .content-center .terms-row .ReactCollapse--content div div {
    font-size: 0.26rem;
  }
}

@media (max-width: 768px) {
  .terms-container {
    margin-top: -1.31rem;
  }

  .terms-container .terms-header .terms-header-logo {
    height: 1.4rem;
  }

  .terms-container .terms-header .terms-header-title {
    height: 0.8rem;
    font-size: 0.32rem;
  }

  .content-terms .content-center-box .content-center .terms-logo {
    font-size: 0.38rem;
  }

  .content-terms .content-center-box .content-center .terms-logo svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: #19723c;
  }

  .content-terms .content-center-box .content-center .terms-intro {
    font-size: 0.32rem;
  }

  .content-terms .content-center-box .content-center .terms-row .term-title {
    font-size: 0.38rem;
  }

  .content-terms .content-center-box .content-center .terms-row .ReactCollapse--content div div {
    font-size: 0.32rem;
  }
}

@media (max-width: 768px) {
  .language-container {
    margin-top: 2.2rem;
  }

  .content-language {
    min-height: 5rem;
    margin: 0 0.4rem;
  }

  .content-language .content-center-box .content-center ul li {
    display: flex;
    align-items: center;
    padding: 0.3rem 0.65rem;
  }

  .content-language .content-center-box .content-center ul li .language-country {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 0.3rem;
  }

  .content-language .content-center-box .content-center ul li .language-country img {
    width: 1rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
  }

  .content-language .content-center-box .content-center ul li .language-selection {
    font-size: 0.4rem;
    padding: 0 0.35rem;
    cursor: pointer;
  }

  .content-language .content-center-box .content-center ul li .language-selection .language-active {
    color: #0a974b;
    font-weight: bold;
  }
}

.content-maintain {
  min-height: 8rem;
}

.content-maintain .content-center-box .content-center {
  padding: 0.66rem 0.89rem 0.9rem 0.89rem;
}

.content-maintain .content-center-box .content-center p {
  font-size: 0.16rem;
  margin-bottom: 0.3rem;
}

.content-maintain .maintenance-content-center-box {
  padding-top: 0;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center {
  padding: 0.3rem 0.89rem 0.3rem 0.89rem;
  width: 55%;
  margin: auto;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center p {
  font-size: 0.16rem;
  margin-bottom: 0.3rem;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .popup-header-right {
  width: 5%;
  display: flex;
  color: #3f51b5;
  margin-left: 100%;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .popup-header-right svg {
  fill: #14984f;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-icon {
  margin: auto;
  width: 20%;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-title {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0.1rem;
  border-bottom: 0.01rem solid rgba(0,0,0,.1294117647);
  padding: 0.3rem 0 0.35rem 0;
  text-align: center;
  color: #14984f;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details {
  margin-top: 0.3rem;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container {
  display: flex;
  text-align: left;
  margin-left: 1rem;
  margin-top: 0.13rem;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column1 {
  width: 30%;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column1 .key {
  width: 100%;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column2 .dateTime {
  width: 100%;
  color: #14984f;
  margin-left: 0.2rem;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column2 .dateTime.dateTimeNotice {
  position: absolute;
  margin-top: 0.5rem;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column2 .slashColor {
  color: #000;
  margin-left: 0.2rem;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container {
  font-weight: bold;
  justify-items: center;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container .timer-container {
  display: grid;
  grid-template-columns: 15% 15% 15% 15%;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container .timer-container .timer {
  background: #a4a7b0;
  border-radius: 0.05rem;
  color: #fff;
  font-size: 0.15rem;
  padding: 0.1rem;
  margin-top: 0.6rem;
  text-align: center;
}

.content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container .timer-container .timer-status {
  text-align: center;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 998;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.204919));
  animation: modalPopUp .3s;
  cursor: default;
}

@keyframes modalPopUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 !important;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  max-width: 8rem;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box p {
  font-size: 0.16rem;
  margin-bottom: 0.3rem;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-header {
  padding: 0.28rem 0.27rem 0.05rem;
  width: 100%;
  display: flex;
  align-items: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-header .box-header-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #39b549;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-header .box-header-right svg {
  width: 0.35rem;
  height: 0.35rem;
  cursor: pointer;
  border: 0.02rem solid gray;
  border-radius: 50%;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body {
  width: 100%;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-icon {
  margin: auto;
  width: 10%;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-title {
  display: flex;
  justify-content: center;
  border-bottom: 0.01rem solid #eaeaea;
  padding: 0.3rem;
  margin: 0 0.6rem;
  color: #0a974b;
  line-height: 0.29rem;
  font-weight: 700;
  font-size: 0.24rem;
  font-style: normal;
  font-family: "AvenirNextLTPro";
  white-space: nowrap;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details {
  margin: 0.3rem;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container {
  margin: 0 0.8rem;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .dateColon {
  text-align: center;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 {
  text-align: left;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 .key {
  width: 100%;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.26rem;
  font-family: "AvenirNextLTPro";
  color: #424242;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 {
  text-align: right;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime {
  width: 100%;
  margin-left: 0.2rem;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.26rem;
  font-family: "AvenirNextLTPro";
  color: #0a974b;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime:lang(vi) {
  font-variant: tabular-nums;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime.dateTimeNotice {
  position: absolute;
  margin-top: 0.5rem;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .slashColor {
  color: #0a974b;
  margin-left: 0.2rem;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container {
  display: flex;
  flex-flow: column;
  margin: 0.6rem 2rem;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  justify-items: center;
  grid-gap: 0.3rem;
  padding: 0.05rem 0;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row1 {
  width: 100%;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row2 {
  padding: 0.2rem 0;
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer {
  background: #f0f0f0;
  border-radius: 0.05rem;
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #424242;
  font-weight: 700;
  font-style: normal;
  font-size: 0.24rem;
  line-height: 0.26rem;
  text-align: center;
  font-family: "AvenirNextLTPro";
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer-status {
  color: #424242;
  font-weight: 700;
  font-style: normal;
  font-size: 0.16rem;
  line-height: 0.26rem;
  text-align: center;
  font-family: "AvenirNextLTPro";
}

.content-center-box .content-center .provider-box .maintenancePopup-Box .box-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 0;
  overflow: hidden;
}

@media (min-width: 768px)and (max-width: 1025px) {
  .content-maintain {
    min-height: 12rem;
  }

  .content-maintain .content-center-box .content-center {
    padding: 0.66rem 0.89rem 0.9rem 0.89rem;
  }

  .content-maintain .content-center-box .content-center p {
    font-size: 0.28rem;
    margin-bottom: 0.35rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center {
    padding: 0.66rem 0.89rem 0.9rem 0.89rem;
    width: 50%;
    margin: auto;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center p {
    font-size: 0.16rem;
    margin-bottom: 0.3rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-icon {
    margin: auto;
    width: 20%;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-title {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0.1rem;
    border-bottom: 0.01rem solid rgba(0,0,0,.1294117647);
    padding: 0.45rem 0 0.6rem 0;
    text-align: center;
    color: #14984f;
    white-space: nowrap;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details {
    margin-top: 0.6rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container {
    display: flex;
    text-align: left;
    margin-left: 0.6rem;
    margin-top: 0.13rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column1 {
    width: 30%;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column1 .key {
    width: 100%;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column2 .dateTime {
    width: 100%;
    color: #14984f;
    margin-left: 0.2rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column2 .dateTime.dateTimeNotice {
    position: absolute;
    margin-top: 0.5rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column2 .slashColor {
    color: #000;
    margin-left: 0.2rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container {
    font-weight: bold;
    justify-items: center;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container .timer-container {
    display: grid;
    grid-template-columns: 16% 16% 16% 16%;
    justify-content: space-between;
    width: 70%;
    margin: auto;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container .timer-container .timer {
    background: #a4a7b0;
    border-radius: 0.05rem;
    color: #fff;
    font-size: 0.35rem;
    padding: 0.2rem;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .content-maintain {
    min-height: 12rem;
  }

  .content-maintain .content-center-box .content-center {
    padding: 0.66rem 0.89rem 0.9rem 0.89rem;
  }

  .content-maintain .content-center-box .content-center p {
    font-size: 0.28rem;
    margin-bottom: 0.35rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center {
    padding: 0.66rem 0.89rem 0.9rem 0.89rem;
    width: 90%;
    margin: auto;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center p {
    font-size: 0.16rem;
    margin-bottom: 0.3rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-icon {
    margin: auto;
    width: 15%;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-title {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0.1rem;
    border-bottom: 0.01rem solid rgba(0,0,0,.1294117647);
    padding: 0.45rem 0 0.6rem 0;
    text-align: center;
    color: #14984f;
    white-space: nowrap;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details {
    margin-top: 0.6rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container {
    display: flex;
    text-align: left;
    margin-left: 0;
    margin-top: 0.13rem;
    font-size: 0.5rem;
    white-space: nowrap;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column1 {
    width: 30%;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column1 .key {
    width: 100%;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column2 .dateTime {
    width: 100%;
    color: #14984f;
    margin-left: 0.2rem;
    white-space: nowrap;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column2 .dateTime.dateTimeNotice {
    position: absolute;
    margin-top: 0.5rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-container .maintenance-container-column2 .slashColor {
    color: #000;
    margin-left: 0.2rem;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container {
    font-weight: bold;
    justify-items: center;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container .timer-container {
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    justify-content: space-between;
    width: 70%;
    margin: auto;
  }

  .content-maintain .maintenance-content-center-box .maintenance-content-center .maintenance-details .maintenance-timer-container .timer-container .timer {
    background: #a4a7b0;
    border-radius: 0.1rem;
    color: #fff;
    font-size: 0.55rem;
    padding: 0.2rem;
    text-align: center;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box {
    max-width: 10.62rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-header .box-header-right svg {
    width: 0.58rem;
    height: 0.58rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-title {
    font-size: 0.5rem;
    padding: 0.5rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details {
    margin: 0;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container {
    margin: 0.5rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 .key {
    font-size: 0.3rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime {
    font-size: 0.3rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer {
    font-size: 0.15rem;
    height: 1rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer-status {
    font-size: 0.4rem;
  }
}

@media (max-width: 575px) {
  .content-center-box .content-center .provider-box .maintenancePopup-Box .box {
    max-width: 10.62rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-header .box-header-right svg {
    width: 0.58rem;
    height: 0.58rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-icon {
    margin: auto;
    width: 15%;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-title {
    font-size: 0.5rem;
    padding: 0.5rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details {
    margin: 0;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container {
    margin: 0.5rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 .key {
    font-size: 0.3rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 {
    font-size: 0.3rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer {
    font-size: 0.3rem;
    height: 1rem;
  }

  .content-center-box .content-center .provider-box .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer-status {
    font-size: 0.3rem;
  }
}

.sport-box {
  height: 10rem;
  margin-bottom: 0.28rem;
}

.sport-box .sport-content {
  background-image: url(/public/html/images/sports/096-sports-bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 10rem;
  padding: 0.45rem;
}

.sport-box .sport-content .sport-iframe-container {
  height: 100%;
  width: 12.8rem;
  margin: 0 auto;
}

.sport-box .sport-content .sport-iframe-container li {
  width: 100%;
  height: 100%;
}

.sport-box .sport-content .sport-iframe-container li .sport-iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 1025px) {
  .sport-box {
    height: 13rem;
  }

  .sport-box .sport-content {
    height: 13rem;
  }

  .sport-box .sport-content .sport-iframe-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .sport-box .sport-content {
    padding: 0.45rem 0;
  }

  .sport-box .sport-content .sport-iframe-container {
    width: 100%;
  }
}

.messaging-popup {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 998;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.204919));
  animation: modalPopUp .3s;
}

@keyframes modalPopUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.messaging-popup select,
.messaging-popup input {
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.05rem solid #dadada;
  width: 3rem;
  padding: 0 0.12rem;
  outline: none;
}

.messaging-popup select[disabled],
.messaging-popup select:disabled,
.messaging-popup input[disabled],
.messaging-popup input:disabled {
  background: #e4e4e4;
  border: none;
  color: #1c2c34;
}

.messaging-popup select {
  color: #999;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  background: #fff;
  background: url("../assets/select-arrow.png") no-repeat scroll 2.5rem center #fff;
}

.messaging-popup select::-ms-expand {
  display: none;
}

.messaging-popup input {
  color: #1c2c34;
  width: 100%;
}

.messaging-popup input::-webkit-input-placeholder,
.messaging-popup input:-ms-input-placeholder,
.messaging-popup input::-moz-placeholder {
  color: #999;
}

.messaging-popup textarea {
  border: 0.01rem solid #dadada;
  padding: 0.1rem 0.12rem;
  font-size: 0.14rem;
}

.messaging-popup textarea::-webkit-input-placeholder,
.messaging-popup textarea:-ms-input-placeholder,
.messaging-popup textarea::-moz-placeholder {
  color: #999;
}

.messaging-popup .react-dropdown-select-dropdown {
  width: 3rem;
  max-height: 2.5rem;
}

.messaging-popup .popup-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 0;
  overflow: hidden;
}

.messaging-popup .popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 !important;
  border-radius: 0.06rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  max-width: 8rem;
  max-height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 100%;
  transform: translate(-50%, -50%);
}

.messaging-popup .popup .popup-header {
  background-color: #0a974b;
  padding: 0.1rem 0.47rem;
  width: 100%;
  display: flex;
  align-items: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.messaging-popup .popup .popup-header .popup-header-left {
  width: 80%;
}

.messaging-popup .popup .popup-header .popup-header-right {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  color: #fff;
}

.messaging-popup .popup .popup-header .popup-header-right svg {
  width: 0.3rem;
  height: 0.3rem;
  cursor: pointer;
  fill: #fff;
}

.messaging-popup .popup .popup-header .popup-title,
.messaging-popup .popup .popup-header .popup-date {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fff;
  font-size: 0.25rem;
}

.messaging-popup .popup .popup-header .popup-title>p:first-child {
  font-size: 0.14rem;
}

.messaging-popup .popup .popup-header .popup-title>p:last-child {
  font-size: 0.2rem;
  font-weight: bolder;
}

.messaging-popup .popup .popup-header .popup-date {
  font-size: 0.12rem;
}

.messaging-popup .popup .body-flow::-webkit-scrollbar {
  width: 0.08rem;
}

.messaging-popup .popup .popup-body {
  min-height: 0.75rem;
  padding: 0.5rem;
  background-color: #fff;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  word-break: break-word;
}

.messaging-popup .popup .popup-body.body-flow div {
  display: flex;
  flex-direction: column;
}

.messaging-popup .popup .popup-body div p {
  font-size: 0.15rem;
  margin-bottom: 0.1rem;
}

.messaging-popup .popup .popup-body div p b,
.messaging-popup .popup .popup-body div p strong {
  font-size: 0.18rem;
  margin-bottom: 0.2rem;
}

.messaging-popup .popup .popup-body div ol li,
.messaging-popup .popup .popup-body div ul li {
  font-size: 0.15rem;
  list-style: decimal;
  margin-left: 0.2rem;
  margin-bottom: 0.1rem;
}

.messaging-popup .popup .popup-body div table {
  margin: 0.15rem 0;
}

.messaging-popup .popup .popup-body div table tr:first-child {
  background-color: #0a974b;
}

.messaging-popup .popup .popup-body div table tr:first-child td {
  color: #fff;
  font-size: 0.15rem;
  padding: 0.1rem 0.05rem;
  font-weight: bolder;
}

.messaging-popup .popup .popup-body div table tr td {
  font-size: 0.15rem;
  padding: 0.1rem;
}

.messaging-popup .popup .popup-body div table p {
  margin-bottom: 0;
}

.messaging-popup .popup .popup-body .btn-normal,
.messaging-popup .popup .popup-body .btn-normal-green,
.messaging-popup .popup .popup-body .btn-long {
  margin: auto;
}

.messaging-popup .popup .popup-body .name-popup,
.messaging-popup .popup .popup-body .num-popup,
.messaging-popup .popup .popup-body .email-popup,
.messaging-popup .popup .popup-body .address-popup,
.messaging-popup .popup .popup-body .remark-popup,
.messaging-popup .popup .popup-body .popup-selectbank,
.messaging-popup .popup .popup-body .popup-bankbranch,
.messaging-popup .popup .popup-body .popup-bankname,
.messaging-popup .popup .popup-body .popup-bankaccname,
.messaging-popup .popup .popup-body .popup-bankaccno,
.messaging-popup .popup .popup-body .shippingAddress-popup,
.messaging-popup .popup .popup-body .betId-popup,
.messaging-popup .popup .popup-body .score-popup,
.messaging-popup .popup .popup-body .eWallet-popup,
.messaging-popup .popup .popup-body .providerWallet-popup,
.messaging-popup .popup .popup-body .popup-common-field {
  display: flex;
  width: 100%;
  margin-bottom: 0.3rem;
  justify-content: center;
}

.messaging-popup .popup .popup-body .name-popup>p,
.messaging-popup .popup .popup-body .num-popup>p,
.messaging-popup .popup .popup-body .email-popup>p,
.messaging-popup .popup .popup-body .address-popup>p,
.messaging-popup .popup .popup-body .remark-popup>p,
.messaging-popup .popup .popup-body .popup-selectbank>p,
.messaging-popup .popup .popup-body .popup-bankbranch>p,
.messaging-popup .popup .popup-body .popup-bankname>p,
.messaging-popup .popup .popup-body .popup-bankaccname>p,
.messaging-popup .popup .popup-body .popup-bankaccno>p,
.messaging-popup .popup .popup-body .shippingAddress-popup>p,
.messaging-popup .popup .popup-body .betId-popup>p,
.messaging-popup .popup .popup-body .score-popup>p,
.messaging-popup .popup .popup-body .eWallet-popup>p,
.messaging-popup .popup .popup-body .providerWallet-popup>p,
.messaging-popup .popup .popup-body .popup-common-field>p {
  width: 100%;
  max-width: 1.8rem;
  font-size: 0.16rem;
}

.messaging-popup .popup .popup-body .name-popup .popup-field,
.messaging-popup .popup .popup-body .num-popup .popup-field,
.messaging-popup .popup .popup-body .email-popup .popup-field,
.messaging-popup .popup .popup-body .address-popup .popup-field,
.messaging-popup .popup .popup-body .remark-popup .popup-field,
.messaging-popup .popup .popup-body .popup-selectbank .popup-field,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-field,
.messaging-popup .popup .popup-body .popup-bankname .popup-field,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-field,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-field,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-field,
.messaging-popup .popup .popup-body .betId-popup .popup-field,
.messaging-popup .popup .popup-body .score-popup .popup-field,
.messaging-popup .popup .popup-body .eWallet-popup .popup-field,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-field,
.messaging-popup .popup .popup-body .popup-common-field .popup-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 3rem;
}

.messaging-popup .popup .popup-body .name-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .num-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .email-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .address-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .remark-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .popup-selectbank .popup-field.address-field input,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-field.address-field input,
.messaging-popup .popup .popup-body .popup-bankname .popup-field.address-field input,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-field.address-field input,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-field.address-field input,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .betId-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .score-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .eWallet-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-field.address-field input,
.messaging-popup .popup .popup-body .popup-common-field .popup-field.address-field input {
  width: 100%;
}

.messaging-popup .popup .popup-body .name-popup .popup-field select,
.messaging-popup .popup .popup-body .num-popup .popup-field select,
.messaging-popup .popup .popup-body .email-popup .popup-field select,
.messaging-popup .popup .popup-body .address-popup .popup-field select,
.messaging-popup .popup .popup-body .remark-popup .popup-field select,
.messaging-popup .popup .popup-body .popup-selectbank .popup-field select,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-field select,
.messaging-popup .popup .popup-body .popup-bankname .popup-field select,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-field select,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-field select,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-field select,
.messaging-popup .popup .popup-body .betId-popup .popup-field select,
.messaging-popup .popup .popup-body .score-popup .popup-field select,
.messaging-popup .popup .popup-body .eWallet-popup .popup-field select,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-field select,
.messaging-popup .popup .popup-body .popup-common-field .popup-field select {
  width: 100%;
  background: url("../assets/select-arrow.png") no-repeat scroll 3rem center #fff;
  color: #1c2c34;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode,
.messaging-popup .popup .popup-body .num-popup .popup-telcode,
.messaging-popup .popup .popup-body .email-popup .popup-telcode,
.messaging-popup .popup .popup-body .address-popup .popup-telcode,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode,
.messaging-popup .popup .popup-body .score-popup .popup-telcode,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode {
  font-size: 0.14rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  padding: 0 0.12rem;
  width: 1.1rem;
  outline: none;
  margin-right: 0.1rem;
  max-height: 0.4rem;
  max-width: 1.06rem;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .num-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .email-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .address-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode.disabled,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode.disabled,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode.disabled,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode.disabled,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode.disabled,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .score-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode.disabled,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode.disabled {
  background: #e4e4e4;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select {
  height: 100%;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .selected--flag--option,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .selected--flag--option {
  height: 100%;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .flag-options,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .flag-options {
  height: 100%;
  overflow-y: scroll;
  background: #e0e0e0;
  border: 0.01rem solid #c3c3c3;
  min-height: 0.5rem;
  position: relative;
  z-index: 2;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .flag-options .flag-option.has-label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.3rem;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .flag-options .flag-option.has-label:hover,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .flag-options .flag-option.has-label:hover {
  background: rgba(10,151,75,.5);
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .country-flag,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .country-flag {
  cursor: pointer;
  display: flex;
  height: 100%;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .country-flag img,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .country-flag img {
  max-width: 0.25rem;
  margin-right: 0.05rem;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .country-flag .country-label,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .country-flag .country-label {
  display: flex;
  align-items: center;
}

.messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .country-flag .arrow-down,
.messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .country-flag .arrow-down {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.messaging-popup .popup .popup-body .name-popup .popup-phone,
.messaging-popup .popup .popup-body .num-popup .popup-phone,
.messaging-popup .popup .popup-body .email-popup .popup-phone,
.messaging-popup .popup .popup-body .address-popup .popup-phone,
.messaging-popup .popup .popup-body .remark-popup .popup-phone,
.messaging-popup .popup .popup-body .popup-selectbank .popup-phone,
.messaging-popup .popup .popup-body .popup-bankbranch .popup-phone,
.messaging-popup .popup .popup-body .popup-bankname .popup-phone,
.messaging-popup .popup .popup-body .popup-bankaccname .popup-phone,
.messaging-popup .popup .popup-body .popup-bankaccno .popup-phone,
.messaging-popup .popup .popup-body .shippingAddress-popup .popup-phone,
.messaging-popup .popup .popup-body .betId-popup .popup-phone,
.messaging-popup .popup .popup-body .score-popup .popup-phone,
.messaging-popup .popup .popup-body .eWallet-popup .popup-phone,
.messaging-popup .popup .popup-body .providerWallet-popup .popup-phone,
.messaging-popup .popup .popup-body .popup-common-field .popup-phone {
  width: 100%;
}

.messaging-popup .popup .popup-body .name-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .num-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .email-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .address-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .remark-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .shippingAddress-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .betId-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .score-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .eWallet-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .providerWallet-popup.popup-bank-statement .popup-field .reminder-note-container,
.messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .reminder-note-container {
  display: flex;
  margin-top: 0.1rem;
}

.messaging-popup .popup .popup-body .name-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .num-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .email-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .address-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .remark-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .shippingAddress-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .betId-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .score-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .eWallet-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .providerWallet-popup.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
.messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .reminder-note-container .icon-tip {
  margin-right: 0.05rem;
}

.messaging-popup .popup .popup-body .name-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .num-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .email-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .address-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .remark-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .shippingAddress-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .betId-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .score-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .eWallet-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .providerWallet-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
.messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .reminder-note-container .reminder-note {
  width: 95%;
  color: #41b850;
  font-size: 0.14rem;
  line-height: 1;
}

.messaging-popup .popup .popup-body .name-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .num-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .email-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .address-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .remark-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .shippingAddress-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .betId-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .score-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .eWallet-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .providerWallet-popup.popup-bank-statement .popup-field .receipt-image-box,
.messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .receipt-image-box {
  margin-top: .1rem;
}

.messaging-popup .popup .popup-body .name-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .num-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .email-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .address-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .remark-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .shippingAddress-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .betId-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .score-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .eWallet-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .providerWallet-popup.popup-bank-statement .popup-field .receipt-image-box img,
.messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .receipt-image-box img {
  width: 100%;
  height: 100%;
}

.messaging-popup .popup .popup-body .name-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .num-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .email-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .address-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .remark-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .shippingAddress-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .betId-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .score-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .eWallet-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .providerWallet-popup.popup-bank-statement .popup-field .receipt-image-box .pdf-clear,
.messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .receipt-image-box .pdf-clear {
  margin-left: 0.1rem;
  width: 0.12rem;
}

.messaging-popup .popup .popup-body .num-popup .popup-field {
  flex-direction: unset;
  margin-bottom: 0.05rem;
}

.messaging-popup .popup .popup-body .annoucement-content {
  padding: 0.1rem 0;
  border-bottom: 0.01rem solid #dbdbdb;
  font-size: 0.16rem;
}

.messaging-popup .popup .popup-body .annoucement-content:nth-last-child(1) {
  border-bottom: 0;
}

.messaging-popup .popup .popup-body .btn-body {
  width: 1.66rem;
  height: 0.45rem;
  font-size: 0.16rem;
}

.messaging-popup .popup .popup-body .btn-ok {
  font-size: 0.25rem;
}

.messaging-popup .popup .popup-body .top-content {
  border-bottom: 0.01rem solid rgba(133,133,133,.3098039216);
}

.messaging-popup .popup .popup-body .top-content .promotion-container {
  margin: 0.2rem 0;
  padding: 0 0.47rem;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-text-container {
  padding: 0.2rem 0;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-title {
  font-size: .24rem;
  font-weight: 700;
  margin: 0.1rem 0;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-detail-desc {
  font-size: .16rem;
  color: #1e1e1e;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container {
  flex-direction: block;
  padding: 0.1rem 0;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group {
  margin-bottom: 0.1rem;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .promotion-btn-group .apply-show {
  border: 0;
  margin: 0 0.3rem 0 0;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container {
  flex-direction: unset;
  align-items: center;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container.unlimited-text {
  padding: 0;
  font-size: .15rem;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .remaining-time {
  margin: 0;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container {
  flex-direction: unset;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .day-box {
  flex-direction: unset;
  padding: 0 0.05rem;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .time-box {
  flex-direction: unset;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .day-number,
.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .time-hour {
  color: #39b549;
  margin: 0 0.05rem 0 0;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .time-text,
.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-btn-container .countdown-container .time-container .day-text {
  margin: 0;
}

.messaging-popup .popup .popup-body .top-content .promotion-container .promotion-image img {
  width: 100%;
}

.messaging-popup .popup .popup-body .bottom-content {
  height: 7rem;
  margin: 0.2rem 0;
  padding: 0 0.47rem;
  font-size: .15rem;
}

.messaging-popup .popup .popup-body .bottom-content h1,
.messaging-popup .popup .popup-body .bottom-content h2,
.messaging-popup .popup .popup-body .bottom-content h3,
.messaging-popup .popup .popup-body .bottom-content h4,
.messaging-popup .popup .popup-body .bottom-content h5,
.messaging-popup .popup .popup-body .bottom-content h6,
.messaging-popup .popup .popup-body .bottom-content strong {
  font-size: .18rem;
}

.messaging-popup .popup .popup-body .bottom-content p,
.messaging-popup .popup .popup-body .bottom-content td,
.messaging-popup .popup .popup-body .bottom-content ol,
.messaging-popup .popup .popup-body .bottom-content ul,
.messaging-popup .popup .popup-body .bottom-content li {
  font-size: .15rem;
}

.messaging-popup .popup .popup-body .bottom-content p {
  margin-bottom: 0;
}

.messaging-popup .popup .popup-body .bottom-content li {
  margin-bottom: 0.1rem;
}

.messaging-popup .popup .popup-body .bottom-content table {
  margin: 0.1rem 0;
}

.messaging-popup .popup .popup-body .bottom-content table tr:first-child td {
  font-size: .18rem;
}

.messaging-popup .popup .popup-body .bottom-content table tr td {
  font-size: .15rem;
  padding: 0.2rem;
}

.messaging-popup .popup .popup-body .bottom-content table td {
  font-size: .15rem;
}

.messaging-popup .popup .popup-history-body {
  padding: 0 0.47rem 0.47rem 0.47rem;
  overflow-y: scroll;
  min-height: 0.75rem;
  background-color: #fff;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.messaging-popup .popup .popup-history-body .popup-provider-table {
  padding: 0 0.3rem;
}

.messaging-popup .popup .popup-history-body .popup-provider-table ul {
  display: flex;
  font-size: 0.15rem;
  justify-content: space-between;
  margin: 0 0 0 -0.2rem;
  width: 105%;
}

.messaging-popup .popup .popup-history-body .popup-provider-table li {
  width: 1.7rem;
  text-align: center;
  list-style: none;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .popup-provider-table-header {
  font-weight: bold;
  color: #18723c;
  font-size: 0.19rem;
  padding: 0.3rem 0 0;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .popup-provider-table-list {
  margin: 0.1rem 0 0 0;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .popup-provider-table-list ul {
  background-color: #cfe8d3;
  padding: 0.12rem 0;
  width: 105%;
  color: #18723c;
  font-weight: bold;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .popup-provider-table-data ul {
  color: #000;
  border-bottom: 0.01rem solid #e9e9e9;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .popup-provider-table-data li {
  margin: 0.2rem 0;
  list-style: none;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .rebate-provider-title {
  font-weight: 700;
  font-family: opensans-bold;
  color: #41b850;
  height: 0.6rem;
  margin: 0.6rem 0 0.3rem;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .rebate-provider-table {
  margin: 0 0 0.2rem 0;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .rebate-provider-table ul {
  display: grid;
  justify-content: unset;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .rebate-provider-table ul li {
  list-style-type: none;
  width: 100%;
  display: flex;
  background: #f3f2f2;
  font-size: 0.42rem;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .rebate-provider-table ul li span {
  flex: 0 0 50%;
  border: 0.01rem solid #fff;
  padding: .7em;
  display: flex;
  align-items: flex-end;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .rebate-provider-table ul li .title {
  color: #41b850;
}

.messaging-popup .popup .popup-history-body .popup-provider-table .rebate-show-button {
  display: flex;
  justify-content: center;
  padding: 0.2rem;
  color: #858585;
  text-decoration: underline;
}

.messaging-popup .popup .popup-footer {
  padding: 0.2rem;
  min-height: 0.8rem;
  border-top: 0.03rem solid #f5f5f5;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.messaging-popup .popup .popup-footer .annoucement-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.messaging-popup .popup .popup-footer .annoucement-footer .annoucement-footer-left {
  display: flex;
  align-items: center;
}

.messaging-popup .popup .popup-footer .annoucement-footer .annoucement-footer-left input[type=checkbox] {
  width: 0.25rem;
  height: 0.32rem;
}

.messaging-popup .popup .popup-footer .annoucement-footer .annoucement-footer-left input[type=checkbox]:after {
  content: "✓";
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.16rem;
}

.messaging-popup .popup .popup-footer .annoucement-footer .annoucement-footer-left input[type=checkbox]:checked:after {
  color: #29c04a;
}

.messaging-popup .popup .popup-footer .annoucement-footer .annoucement-footer-left label {
  margin-left: 0.1rem;
  font-size: 0.14rem;
  color: #8a8a8a;
}

.messaging-popup .popup .popup-footer .btn-submit-popup {
  border: 0;
  color: #fff;
  font-weight: bold;
  background-color: #0a974b;
  width: 1.66rem;
  height: 0.45rem;
}

.messaging-popup .instruction-popup {
  width: 8rem;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  background: url(/public/html/images/instruction_pop_up/desktop-bg.png);
  background-position: 75%;
  border-radius: 0.1rem;
}

.messaging-popup .instruction-popup .title-container {
  position: absolute;
  top: 1.2rem;
  left: 0.45rem;
  z-index: 1;
}

.messaging-popup .instruction-popup .title-container .title {
  font-family: "AvenirNextLTPro-Bold";
  font-size: .35rem;
  font-weight: bold;
  color: #0a974b;
}

.messaging-popup .instruction-popup .left-arrow {
  cursor: pointer;
  font-size: .35rem;
  color: #0a974b;
  background: #eee;
  max-width: 0.4rem;
  width: 0.8rem;
  height: 0.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2.75rem;
  left: 4.15rem;
  z-index: 1;
}

.messaging-popup .instruction-popup .right-arrow {
  cursor: pointer;
  font-size: .35rem;
  color: #0a974b;
  background: #eee;
  max-width: 0.4rem;
  width: 0.8rem;
  height: 0.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2.75rem;
  left: 7.1rem;
  z-index: 1;
}

.messaging-popup .instruction-popup svg {
  cursor: pointer;
  width: 0.35rem;
  height: 0.35rem;
  fill: #fff;
  position: absolute;
  top: .2rem;
  right: .2rem;
  z-index: 1;
}

.messaging-popup .instruction-popup .instruction-popup-body {
  padding: 0.45rem 0.45rem 0.25rem 0.45rem;
  width: 100%;
  min-height: 6rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.messaging-popup .instruction-popup .instruction-popup-body .container-wrapper {
  display: flex;
  justify-content: space-between;
}

.messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .left-container {
  width: 50%;
  margin: 1.9rem 0.25rem 0 0;
}

.messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .left-container.extra-margin {
  margin: 2.3rem 0.25rem 0 0;
}

.messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .left-container .step-title {
  font-family: "AvenirNextLTPro-Bold";
  font-size: .25rem;
  font-weight: bold;
  margin: 0.3rem 0 0 0;
}

.messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .left-container .desc {
  font-family: "AvenirNextLTPro";
  font-size: .2rem;
  margin: 0 0 .2rem 0;
}

.messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .right-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .right-container img {
  height: 4.94rem;
  padding: 0 0.4rem;
}

.form-popup .invalid-feedback {
  display: flex;
  justify-content: center;
}

.img-popup {
  position: relative;
}

.img-popup svg {
  width: 0.3rem;
  height: 0.3rem;
  cursor: pointer;
  position: absolute;
  top: -0.1rem;
  right: -0.1rem;
  background: #19723c;
  border-radius: 50%;
  fill: #fff;
  z-index: 1;
}

.img-popup .popup-img-container {
  max-height: 90vh;
  overflow: auto;
}

.img-popup .popup-img-container .popup-img {
  width: 100%;
  height: 100%;
}

.img-popup .popup-img-container .btn-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img-popup .popup-img-container .btn-item .dont-show-again {
  display: flex;
  align-items: center;
  color: #fff;
  position: relative;
  z-index: 1;
  left: .2rem;
}

.img-popup .popup-img-container .btn-item .dont-show-again input {
  width: 0.28rem;
  height: 0.28rem;
  border: 0.01rem solid #cecece;
}

.img-popup .popup-img-container .btn-item .dont-show-again input[type=checkbox]:after {
  content: "✓";
  color: rgba(0,0,0,0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.12rem;
  height: 100%;
}

.img-popup .popup-img-container .btn-item .dont-show-again input[type=checkbox]:checked:after {
  color: #fff;
}

.img-popup .popup-img-container .btn-item .dont-show-again span {
  font-size: 0.16rem;
  margin-left: 0.11rem;
  color: #cecece;
}

.img-popup .popup-img-container .btn-item>span {
  font-size: 0.16rem;
  color: #ff4e00;
  line-height: 0.18rem;
  cursor: pointer;
}

.mobile-notice .notice-box {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fffeff;
  border: 0.01rem solid #e2e2e2;
}

.mobile-notice .notice-box .popup-body .title {
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

.mobile-notice .notice-box .popup-body .title .icon-notice-important {
  width: 1rem;
  height: 0.5rem;
  margin-right: 0.2rem;
}

.mobile-notice .notice-box .popup-body>ul {
  font-size: 0.35rem;
  font-family: Arial;
}

.mobile-notice .notice-box .popup-body>ul li {
  display: flex;
  margin-bottom: 0.25rem;
}

.mobile-notice .notice-box .popup-body>ul li>span {
  margin-right: 0.3rem;
  color: #0a974b;
}

.mobile-notice .notice-box .popup-body>ul li>span.empty-span {
  margin-right: 0.5rem;
}

.mobile-notice .notice-box .popup-body>ul li a {
  color: #2196f3;
}

.tnc-apply {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  display: flex;
}

.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  background: rgba(0,0,0,.7);
  animation: modalPopUp .3s;
}

@keyframes modalPopUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.success-popup .success-popup-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 !important;
  border-radius: 0.06rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  width: 60%;
  height: 20%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.success-popup .success-popup-body svg {
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  top: -0.05rem;
  right: -0.05rem;
  background: #19723c;
  border-radius: 50%;
  fill: #fff;
  transform: scale(1.5);
  cursor: pointer;
}

.direct-popup {
  font-size: 0.25rem;
}

.direct-popup .invalid-feedback {
  color: red;
  font-size: 0.25rem;
}

.direct-popup span {
  padding: 0.2rem;
}

.rotate-alert {
  background: rgba(0,0,0,.9);
}

.rotate-alert .popup-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #000 !important;
}

.rotate-alert .popup-body img {
  width: 30%;
  object-fit: contain;
}

.qrcode-popup-modal-container {
  width: 3.6rem;
  min-width: 3.6rem;
  max-width: 3.6rem;
  overflow: auto;
}

.qrcode-popup-modal-container .qr-code-img {
  max-width: 100%;
  display: block;
  max-height: 100%;
}

@media screen and (max-width: 2048px) {
  .uploadWrapper {
    position: relative;
    width: 100%;
    color: gray;
    display: flex;
    justify-content: space-between;
  }

  .uploadInput {
    background: none;
    color: gray;
    font-size: .25rem;
    width: 65%;
    line-height: 0.28rem;
  }

  .uploadButton {
    background: #959595;
    color: #fff;
    border-radius: .05rem;
    border: none;
    padding: .01rem .12rem;
  }
}

@media (max-width: 1025px) {
  .messaging-popup input {
    font-size: 0.25rem;
    height: 0.6rem;
  }

  .messaging-popup select {
    font-size: 0.25rem;
  }

  .messaging-popup .popup {
    max-width: 15rem;
  }

  .messaging-popup .popup .popup-header {
    padding: 0.2rem 0.3rem;
  }

  .messaging-popup .popup .popup-header .popup-date {
    font-size: 0.35rem;
  }

  .messaging-popup .popup .popup-header .popup-title {
    font-size: 0.5rem;
  }

  .messaging-popup .popup .popup-header .popup-title>p:first-child {
    font-size: 0.35rem;
  }

  .messaging-popup .popup .popup-header .popup-title>p:last-child {
    font-size: 0.5rem;
  }

  .messaging-popup .popup .popup-header .popup-header-right svg {
    width: 0.5rem;
    height: 0.5rem;
  }

  .messaging-popup .popup .popup-body {
    padding: 0.5rem;
    max-height: 15rem;
  }

  .messaging-popup .popup .popup-body div p {
    font-size: 0.3rem;
  }

  .messaging-popup .popup .popup-body div p strong {
    font-size: 0.35rem;
  }

  .messaging-popup .popup .popup-body div ol li,
  .messaging-popup .popup .popup-body div ul li {
    font-size: 0.28rem;
    margin-bottom: 0.2rem;
  }

  .messaging-popup .popup .popup-body div table tr:first-child td {
    font-size: 0.28rem;
  }

  .messaging-popup .popup .popup-body div table tr td {
    font-size: 0.28rem;
    padding: 0.2rem;
  }

  .messaging-popup .popup .popup-body .annoucement-content {
    font-size: 0.3rem;
  }

  .messaging-popup .popup .popup-body .btn-body {
    width: 3.76rem;
    height: 0.8rem;
    font-size: 0.36rem;
  }

  .messaging-popup .popup .popup-body .btn-ok {
    font-size: 0.5rem;
  }

  .messaging-popup .popup .popup-body .name-popup>p,
  .messaging-popup .popup .popup-body .num-popup>p,
  .messaging-popup .popup .popup-body .email-popup>p,
  .messaging-popup .popup .popup-body .address-popup>p,
  .messaging-popup .popup .popup-body .remark-popup>p,
  .messaging-popup .popup .popup-body .popup-selectbank>p,
  .messaging-popup .popup .popup-body .popup-bankbranch>p,
  .messaging-popup .popup .popup-body .popup-bankname>p,
  .messaging-popup .popup .popup-body .popup-bankaccname>p,
  .messaging-popup .popup .popup-body .popup-bankaccno>p,
  .messaging-popup .popup .popup-body .shippingAddress-popup>p,
  .messaging-popup .popup .popup-body .betId-popup>p,
  .messaging-popup .popup .popup-body .score-popup>p,
  .messaging-popup .popup .popup-body .eWallet-popup>p,
  .messaging-popup .popup .popup-body .providerWallet-popup>p,
  .messaging-popup .popup .popup-body .popup-common-field>p {
    font-size: 0.3rem;
    max-width: 3.5rem;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field,
  .messaging-popup .popup .popup-body .num-popup .popup-field,
  .messaging-popup .popup .popup-body .email-popup .popup-field,
  .messaging-popup .popup .popup-body .address-popup .popup-field,
  .messaging-popup .popup .popup-body .remark-popup .popup-field,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field,
  .messaging-popup .popup .popup-body .betId-popup .popup-field,
  .messaging-popup .popup .popup-body .score-popup .popup-field,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field {
    max-width: 3.5rem;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-telcode,
  .messaging-popup .popup .popup-body .num-popup .popup-telcode,
  .messaging-popup .popup .popup-body .email-popup .popup-telcode,
  .messaging-popup .popup .popup-body .address-popup .popup-telcode,
  .messaging-popup .popup .popup-body .remark-popup .popup-telcode,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankname .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode,
  .messaging-popup .popup .popup-body .betId-popup .popup-telcode,
  .messaging-popup .popup .popup-body .score-popup .popup-telcode,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-telcode,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode,
  .messaging-popup .popup .popup-body .popup-common-field .popup-telcode {
    font-size: 0.25rem;
    width: 2.5rem;
    max-width: 100%;
    max-height: 0.6rem;
  }

  .messaging-popup .popup .popup-body .name-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .num-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .email-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .address-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .remark-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .shippingAddress-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .betId-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .score-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .eWallet-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .providerWallet-popup.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .reminder-note-container .reminder-note {
    font-size: 0.19rem;
  }

  .messaging-popup .popup .popup-footer {
    min-height: 1.2rem;
    padding: 0 0.5rem;
  }

  .messaging-popup .popup .popup-footer .annoucement-footer .annoucement-footer-left input[type=checkbox] {
    width: 0.4rem;
    height: 0.45rem;
  }

  .messaging-popup .popup .popup-footer .annoucement-footer .annoucement-footer-left input[type=checkbox]:after {
    content: "✓";
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.25rem;
  }

  .messaging-popup .popup .popup-footer .annoucement-footer .annoucement-footer-left input[type=checkbox]:checked:after {
    color: #29c04a;
  }

  .messaging-popup .popup .popup-footer .annoucement-footer .annoucement-footer-left label {
    margin-left: 0.3rem;
    font-size: 0.3rem;
    color: #8a8a8a;
  }

  .messaging-popup .popup .popup-footer .btn-submit-popup {
    border: 0;
    color: #fff;
    font-weight: bold;
    background-color: #0a974b;
    width: 3.66rem;
    height: 0.8rem;
    font-size: 0.3rem;
  }

  .messaging-popup textarea {
    font-size: 0.25rem;
  }

  .img-popup svg {
    width: 0.5rem;
    height: 0.5rem;
  }

  .success-popup {
    font-size: 0.2rem;
  }

  .success-popup .success-popup-body {
    width: 90%;
    height: 10%;
  }

  .success-popup .success-popup-body svg {
    width: 0.4rem;
    height: 0.4rem;
  }

  .uploadWrapper {
    position: relative;
    width: 100%;
    color: gray;
    display: flex;
    justify-content: space-between;
  }

  .uploadInput {
    background: none;
    color: gray;
    font-size: .25rem;
    height: .6rem;
    width: 65%;
    line-height: 0.28rem;
  }

  .uploadButton {
    background: #959595;
    color: #fff;
    border-radius: .05rem;
    border: none;
    padding: .05rem .12rem;
    font-size: .25rem;
  }
}

@media (max-width: 768px) {
  .img-popup .popup-img-container .btn-item .dont-show-again input {
    width: 0.4rem;
    height: 0.41rem;
  }

  .img-popup .popup-img-container .btn-item .dont-show-again input[type=checkbox]:after {
    font-size: 0.36rem;
  }

  .img-popup .popup-img-container .btn-item .dont-show-again span {
    font-size: 0.4rem;
    margin-left: 0.2rem;
    color: #cecece;
  }

  .img-popup .popup-img-container .btn-item>span {
    font-size: 0.4rem;
  }

  .img-popup .popup-img-container .btn-item>span.btn-close {
    width: 1.8rem;
    height: 0.98rem;
    border-bottom-left-radius: 0.15rem;
    border-bottom-right-radius: 0.15rem;
  }

  .messaging-popup input,
  .messaging-popup textarea {
    font-size: 0.45rem;
  }

  .messaging-popup select {
    font-size: 0.45rem;
    color: #999;
    box-shadow: none;
    -webkit-appearance: none;
            appearance: none;
    background: #fff;
    background: url("../assets/select-arrow.png") no-repeat scroll 2.5rem center #fff;
  }

  .messaging-popup select::-ms-expand {
    display: none;
  }

  .messaging-popup input {
    color: #1c2c34;
  }

  .messaging-popup input::-webkit-input-placeholder,
  .messaging-popup input:-ms-input-placeholder,
  .messaging-popup input::-moz-placeholder {
    color: #999;
  }

  .messaging-popup .react-dropdown-select-dropdown {
    width: 3rem;
    max-height: 4.5rem;
    top: 1.2rem;
  }

  .messaging-popup .popup {
    width: 11rem;
  }

  .messaging-popup .popup .popup-header {
    max-height: unset;
  }

  .messaging-popup .popup .popup-header .popup-header-right {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    color: #fff;
  }

  .messaging-popup .popup .popup-header .popup-header-right svg {
    width: 0.45rem;
    height: 0.45rem;
  }

  .messaging-popup .popup .popup-header .popup-title {
    font-size: 0.45rem;
  }

  .messaging-popup .popup .popup-header .popup-date {
    font-size: 0.3rem;
  }

  .messaging-popup .popup .popup-body {
    max-height: 12rem;
  }

  .messaging-popup .popup .popup-body div p {
    font-size: 0.3rem;
  }

  .messaging-popup .popup .popup-body div p strong {
    font-size: 0.4rem;
  }

  .messaging-popup .popup .popup-body div ol li,
  .messaging-popup .popup .popup-body div ul li {
    font-size: 0.32rem;
  }

  .messaging-popup .popup .popup-body div table {
    display: block;
    overflow: auto;
    margin: 0.15rem 0;
  }

  .messaging-popup .popup .popup-body div table tr:first-child td {
    padding: 0.1rem 0.2rem;
    font-size: 0.22rem;
  }

  .messaging-popup .popup .popup-body div table p {
    margin-bottom: 0;
  }

  .messaging-popup .popup .popup-body .name-popup,
  .messaging-popup .popup .popup-body .num-popup,
  .messaging-popup .popup .popup-body .email-popup,
  .messaging-popup .popup .popup-body .address-popup,
  .messaging-popup .popup .popup-body .remark-popup,
  .messaging-popup .popup .popup-body .popup-selectbank,
  .messaging-popup .popup .popup-body .popup-bankbranch,
  .messaging-popup .popup .popup-body .popup-bankname,
  .messaging-popup .popup .popup-body .popup-bankaccname,
  .messaging-popup .popup .popup-body .popup-bankaccno,
  .messaging-popup .popup .popup-body .shippingAddress-popup,
  .messaging-popup .popup .popup-body .betId-popup,
  .messaging-popup .popup .popup-body .score-popup,
  .messaging-popup .popup .popup-body .eWallet-popup,
  .messaging-popup .popup .popup-body .providerWallet-popup,
  .messaging-popup .popup .popup-body .popup-common-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.3rem;
  }

  .messaging-popup .popup .popup-body .name-popup>p,
  .messaging-popup .popup .popup-body .num-popup>p,
  .messaging-popup .popup .popup-body .email-popup>p,
  .messaging-popup .popup .popup-body .address-popup>p,
  .messaging-popup .popup .popup-body .remark-popup>p,
  .messaging-popup .popup .popup-body .popup-selectbank>p,
  .messaging-popup .popup .popup-body .popup-bankbranch>p,
  .messaging-popup .popup .popup-body .popup-bankname>p,
  .messaging-popup .popup .popup-body .popup-bankaccname>p,
  .messaging-popup .popup .popup-body .popup-bankaccno>p,
  .messaging-popup .popup .popup-body .shippingAddress-popup>p,
  .messaging-popup .popup .popup-body .betId-popup>p,
  .messaging-popup .popup .popup-body .score-popup>p,
  .messaging-popup .popup .popup-body .eWallet-popup>p,
  .messaging-popup .popup .popup-body .providerWallet-popup>p,
  .messaging-popup .popup .popup-body .popup-common-field>p {
    font-size: 0.45rem;
    width: 100%;
    max-width: 9.5rem;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field,
  .messaging-popup .popup .popup-body .num-popup .popup-field,
  .messaging-popup .popup .popup-body .email-popup .popup-field,
  .messaging-popup .popup .popup-body .address-popup .popup-field,
  .messaging-popup .popup .popup-body .remark-popup .popup-field,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field,
  .messaging-popup .popup .popup-body .betId-popup .popup-field,
  .messaging-popup .popup .popup-body .score-popup .popup-field,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field {
    width: 100%;
    max-width: unset;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field input,
  .messaging-popup .popup .popup-body .num-popup .popup-field input,
  .messaging-popup .popup .popup-body .email-popup .popup-field input,
  .messaging-popup .popup .popup-body .address-popup .popup-field input,
  .messaging-popup .popup .popup-body .remark-popup .popup-field input,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field input,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field input,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field input,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field input,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field input,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field input,
  .messaging-popup .popup .popup-body .betId-popup .popup-field input,
  .messaging-popup .popup .popup-body .score-popup .popup-field input,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field input,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field input,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field input {
    height: 1rem;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field select,
  .messaging-popup .popup .popup-body .num-popup .popup-field select,
  .messaging-popup .popup .popup-body .email-popup .popup-field select,
  .messaging-popup .popup .popup-body .address-popup .popup-field select,
  .messaging-popup .popup .popup-body .remark-popup .popup-field select,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field select,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field select,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field select,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field select,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field select,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field select,
  .messaging-popup .popup .popup-body .betId-popup .popup-field select,
  .messaging-popup .popup .popup-body .score-popup .popup-field select,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field select,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field select,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field select {
    height: 1rem;
    background: url("../assets/select-arrow.png") no-repeat scroll 9rem center #fff;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .num-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .email-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .address-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .remark-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field.otp-field,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field.otp-field,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field.otp-field,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field.otp-field,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field.otp-field,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .betId-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .score-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field.otp-field,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field.otp-field {
    flex-direction: row;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .num-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .email-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .address-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .remark-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .betId-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .score-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field.otp-field input,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field.otp-field input {
    width: 6rem;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .name-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .num-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .num-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .email-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .email-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .address-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .address-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .remark-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .remark-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .betId-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .betId-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .score-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .score-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field.otp-field input::-webkit-inner-spin-button,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field.otp-field input::-webkit-outer-spin-button,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field.otp-field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .num-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .email-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .address-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .remark-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .popup-bankname .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .betId-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .score-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field.otp-field input[type=number],
  .messaging-popup .popup .popup-body .popup-common-field .popup-field.otp-field input[type=number] {
    -moz-appearance: textfield;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .num-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .email-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .address-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .remark-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .betId-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .score-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field.otp-field button,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field.otp-field button {
    width: 4rem;
    margin-left: 0.5rem;
    background-color: #005329;
    color: #fff;
    cursor: pointer;
    border: 0;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .name-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .num-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .num-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .email-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .email-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .address-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .address-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .remark-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .remark-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .popup-selectbank .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .popup-bankname .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .betId-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .betId-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .score-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .score-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .eWallet-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-field.otp-field button:disabled,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field.otp-field button[disabled],
  .messaging-popup .popup .popup-body .popup-common-field .popup-field.otp-field button:disabled {
    background-color: rgba(0,147,73,.4549019608);
    cursor: not-allowed;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-telcode,
  .messaging-popup .popup .popup-body .name-popup .popup-phone,
  .messaging-popup .popup .popup-body .num-popup .popup-telcode,
  .messaging-popup .popup .popup-body .num-popup .popup-phone,
  .messaging-popup .popup .popup-body .email-popup .popup-telcode,
  .messaging-popup .popup .popup-body .email-popup .popup-phone,
  .messaging-popup .popup .popup-body .address-popup .popup-telcode,
  .messaging-popup .popup .popup-body .address-popup .popup-phone,
  .messaging-popup .popup .popup-body .remark-popup .popup-telcode,
  .messaging-popup .popup .popup-body .remark-popup .popup-phone,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-telcode,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-phone,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-phone,
  .messaging-popup .popup .popup-body .popup-bankname .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankname .popup-phone,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-phone,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-phone,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-phone,
  .messaging-popup .popup .popup-body .betId-popup .popup-telcode,
  .messaging-popup .popup .popup-body .betId-popup .popup-phone,
  .messaging-popup .popup .popup-body .score-popup .popup-telcode,
  .messaging-popup .popup .popup-body .score-popup .popup-phone,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-telcode,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-phone,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-phone,
  .messaging-popup .popup .popup-body .popup-common-field .popup-telcode,
  .messaging-popup .popup .popup-body .popup-common-field .popup-phone {
    height: 1rem;
    max-height: unset;
    font-size: 0.45rem;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-telcode,
  .messaging-popup .popup .popup-body .num-popup .popup-telcode,
  .messaging-popup .popup .popup-body .email-popup .popup-telcode,
  .messaging-popup .popup .popup-body .address-popup .popup-telcode,
  .messaging-popup .popup .popup-body .remark-popup .popup-telcode,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankname .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode,
  .messaging-popup .popup .popup-body .betId-popup .popup-telcode,
  .messaging-popup .popup .popup-body .score-popup .popup-telcode,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-telcode,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode,
  .messaging-popup .popup .popup-body .popup-common-field .popup-telcode {
    width: 4rem;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .flag-options,
  .messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .flag-options {
    height: 2rem;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .flag-options .flag-option.has-label,
  .messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .flag-options .flag-option.has-label {
    height: 0.8rem;
  }

  .messaging-popup .popup .popup-body .name-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .num-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .email-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .address-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .remark-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .popup-selectbank .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .popup-bankname .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .shippingAddress-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .betId-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .score-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .eWallet-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .providerWallet-popup .popup-telcode .flag-select .country-flag img,
  .messaging-popup .popup .popup-body .popup-common-field .popup-telcode .flag-select .country-flag img {
    max-width: 0.8rem;
    margin-right: 0.1rem;
  }

  .messaging-popup .popup .popup-body .popup-selectbank,
  .messaging-popup .popup .popup-body .popup-bankname,
  .messaging-popup .popup .popup-body .popup-bankbranch,
  .messaging-popup .popup .popup-body .popup-bankaccname,
  .messaging-popup .popup .popup-body .popup-bankaccno,
  .messaging-popup .popup .popup-body .popup-common-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
  }

  .messaging-popup .popup .popup-body .popup-selectbank .popup-field,
  .messaging-popup .popup .popup-body .popup-bankname .popup-field,
  .messaging-popup .popup .popup-body .popup-bankbranch .popup-field,
  .messaging-popup .popup .popup-body .popup-bankaccname .popup-field,
  .messaging-popup .popup .popup-body .popup-bankaccno .popup-field,
  .messaging-popup .popup .popup-body .popup-common-field .popup-field {
    width: 100%;
    max-width: 9.5rem;
  }

  .messaging-popup .popup .popup-body .popup-selectbank>p,
  .messaging-popup .popup .popup-body .popup-bankname>p,
  .messaging-popup .popup .popup-body .popup-bankbranch>p,
  .messaging-popup .popup .popup-body .popup-bankaccname>p,
  .messaging-popup .popup .popup-body .popup-bankaccno>p,
  .messaging-popup .popup .popup-body .popup-common-field>p {
    font-size: 0.36rem;
    width: 100%;
    max-width: 9.5rem;
  }

  .messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .reminder-note-container,
  .messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .reminder-note-container,
  .messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .reminder-note-container,
  .messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .reminder-note-container,
  .messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .reminder-note-container,
  .messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .reminder-note-container {
    display: flex;
    align-items: flex-start;
  }

  .messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
  .messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
  .messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
  .messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
  .messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .reminder-note-container .icon-tip,
  .messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .reminder-note-container .icon-tip {
    margin-right: 0.1rem;
  }

  .messaging-popup .popup .popup-body .popup-selectbank.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-bankname.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-bankbranch.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-bankaccname.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-bankaccno.popup-bank-statement .popup-field .reminder-note-container .reminder-note,
  .messaging-popup .popup .popup-body .popup-common-field.popup-bank-statement .popup-field .reminder-note-container .reminder-note {
    font-size: .33rem;
  }

  .messaging-popup .popup .popup-body .btn-body {
    height: 0.7rem;
    width: 3.66rem;
    font-size: 0.41rem;
  }

  .messaging-popup .popup .popup-body .btn-ok {
    font-size: 0.45rem;
  }

  .messaging-popup .popup .popup-body.body-flow::-webkit-scrollbar {
    width: 0.16rem;
  }

  .messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-title {
    font-size: .4rem;
  }

  .messaging-popup .popup .popup-body .top-content .promotion-container .promotion-content-detail .promotion-text-container .promotion-content-detail-desc {
    font-size: .27rem;
    color: #1e1e1e;
  }

  .messaging-popup .popup .popup-body .top-content .promotion-container .promotion-image img {
    width: 100%;
  }

  .messaging-popup .popup .popup-body .bottom-content {
    height: auto;
    font-size: 12PX;
  }

  .messaging-popup .popup .popup-body .bottom-content h1,
  .messaging-popup .popup .popup-body .bottom-content h2,
  .messaging-popup .popup .popup-body .bottom-content h3,
  .messaging-popup .popup .popup-body .bottom-content h4,
  .messaging-popup .popup .popup-body .bottom-content h5,
  .messaging-popup .popup .popup-body .bottom-content h6,
  .messaging-popup .popup .popup-body .bottom-content strong {
    font-size: 14PX;
  }

  .messaging-popup .popup .popup-body .bottom-content p,
  .messaging-popup .popup .popup-body .bottom-content td,
  .messaging-popup .popup .popup-body .bottom-content ol,
  .messaging-popup .popup .popup-body .bottom-content ul,
  .messaging-popup .popup .popup-body .bottom-content li {
    font-size: 12PX;
  }

  .messaging-popup .popup .popup-body .bottom-content table {
    width: auto;
    overflow: auto;
    display: block;
    table-layout: fixed;
    white-space: nowrap;
    border: none;
  }

  .messaging-popup .popup .popup-body .bottom-content table tr:first-child td {
    font-size: 14PX;
  }

  .messaging-popup .popup .popup-body .bottom-content table tr td {
    font-size: 12PX;
  }

  .messaging-popup .popup .popup-body .bottom-content table td {
    font-size: 12PX;
  }

  .messaging-popup .popup .popup-footer {
    padding: 0.2rem;
    min-height: 0.8rem;
    border-top: 0.05rem solid #f5f5f5;
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .messaging-popup .popup .popup-footer .btn-submit-popup {
    border: 0;
    color: #fff;
    font-weight: bold;
    background-color: #0a974b;
    width: 4rem;
    height: 1rem;
    font-size: 0.41rem;
  }

  .messaging-popup .instruction-popup {
    width: 11.35rem;
    height: 21.0935rem;
    position: absolute;
    left: 50%;
    top: 75%;
    z-index: 1;
    transform: translate(-50%, -50%);
    background: url(/public/html/images/instruction_pop_up/mobile-bg.png);
    background-position-y: 80%;
    background-size: cover;
    border-radius: 0.25rem;
  }

  .messaging-popup .instruction-popup .top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1.25rem 0 0 0;
    position: absolute;
    z-index: 1;
  }

  .messaging-popup .instruction-popup .top-container .title {
    font-family: "AvenirNextLTPro-Bold";
    font-size: .7rem;
    font-weight: bold;
    color: #0a974b;
  }

  .messaging-popup .instruction-popup .left-arrow {
    font-size: 1rem;
    color: #0a974b;
    max-width: 0.4rem;
    width: 0.8rem;
    height: 0.4rem;
    background: rgba(0,0,0,0);
    position: absolute;
    z-index: 1;
    top: 10.5rem;
    left: 1.45rem;
  }

  .messaging-popup .instruction-popup .right-arrow {
    font-size: 1rem;
    color: #0a974b;
    max-width: 0.4rem;
    width: 0.8rem;
    height: 0.4rem;
    background: rgba(0,0,0,0);
    position: absolute;
    z-index: 1;
    top: 10.5rem;
    left: 9.55rem;
  }

  .messaging-popup .instruction-popup svg {
    width: 0.7rem;
    height: 0.7rem;
    fill: #000;
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    z-index: 1;
  }

  .messaging-popup .instruction-popup .instruction-popup-body {
    padding: 4.5rem 0.45rem 0.25rem 0.45rem;
    width: 100%;
  }

  .messaging-popup .instruction-popup .instruction-popup-body .container-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .mid-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .mid-container img {
    width: 60%;
    margin: 0 .7rem;
  }

  .messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .bot-container .step-title {
    font-family: "AvenirNextLTPro-Bold";
    font-size: .4rem;
    font-weight: bold;
    margin: 0.3rem 0;
    text-align: center;
  }

  .messaging-popup .instruction-popup .instruction-popup-body .container-wrapper .bot-container .desc {
    font-family: "AvenirNextLTPro";
    font-size: .3rem;
    margin: 0 2rem .5rem;
    text-align: center;
  }

  .success-popup {
    font-size: 0.3rem;
  }

  .success-popup .success-popup-body {
    width: 90%;
    height: 10%;
  }

  .uploadButton {
    background: #959595;
    color: #fff;
    border-radius: .05rem;
    border: none;
    padding: .17rem .6rem;
    font-size: .45rem;
  }

  .qrcode-popup-modal-container {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
    overflow: auto;
  }
}

@media (max-width: 575px) {
  .messaging-popup .popup .popup-body {
    max-height: 22rem;
    min-height: 1.2rem;
  }

  .success-popup .success-popup-body {
    width: 90%;
  }

  .countdown-container {
    display: flex;
    flex-direction: unset;
    align-items: center;
  }
}

.contact-container {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.content-contactus .content-center-box .content-center {
  padding: 0.62rem 1.01rem .9rem 0.94rem;
}

.content-contactus .content-center-box .content-center p {
  font-size: 0.14rem;
  color: #333;
}

.content-contactus .content-center-box .content-center p:nth-child(2) {
  margin-top: 0.57rem;
  margin-bottom: 0.38rem;
}

.content-contactus .content-center-box .content-center ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.content-contactus .content-center-box .content-center ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 2.21rem;
  min-height: 0.64rem;
  margin: 0 0.22rem 0.7rem 0;
}

.content-contactus .content-center-box .content-center ul li .contact-right {
  justify-content: center;
  flex-direction: column;
  display: none;
  position: absolute;
  margin-top: 1.4rem;
  width: 100%;
  font-size: 0.18rem;
  width: 2.21rem;
  background-image: url(/public/html/sp-images/images/common_img/contact-border.png);
  background-size: 2.21rem 0.53rem;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.content-contactus .content-center-box .content-center ul li .contact-right .contact-desc {
  font-size: 0.14rem;
  display: flex;
  align-items: center;
  height: 0.53rem;
  margin-top: 0.05rem;
  white-space: nowrap;
  text-align: center;
  margin: auto;
  width: 100%;
}

.content-contactus .content-center-box .content-center ul li .contact-right .contact-desc .contact-detail {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
}

.content-contactus .content-center-box .content-center ul li .contact-right .international-charge {
  font-size: 0.12rem;
  font-style: italic;
  text-align: center;
}

.content-contactus .content-center-box .content-center ul li .contact-left {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: relative;
  height: 100%;
  width: 100%;
  border: 0.01rem solid #dedcdf;
  color: #3eb25d;
}

.content-contactus .content-center-box .content-center ul li .contact-left .contact-name {
  font-weight: bold;
  font-size: 0.18rem;
  text-transform: capitalize;
  margin-left: 0.12rem;
}

.content-contactus .content-center-box .content-center ul li .contact-left .contact-svg {
  fill: #19723c;
  width: 0.3rem;
  height: 0.3rem;
}

.content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon {
  width: 0.45rem;
  height: 0.49rem;
}

.content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-livechat {
  width: 0.44rem;
  height: 0.44rem;
}

.content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-phone {
  width: 0.33rem;
  height: 0.46rem;
}

.content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-whatsapp {
  width: 0.44rem;
  height: 0.44rem;
}

.content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-twitter {
  width: 0.44rem;
  height: 0.44rem;
}

.content-contactus .content-center-box .content-center ul li .contact-left:hover {
  border: 0.01rem solid #88ce88;
}

.content-contactus .content-center-box .content-center ul li .contact-left:hover .contact-right {
  display: flex;
  color: #000;
  z-index: 1;
}

@media (min-width: 1025px)and (max-width: 1445px) {
  .content-contactus .content-center-box .content-center p {
    font-size: 0.18rem;
  }

  .content-contactus .content-center-box .content-center ul li {
    width: 2.79rem;
    min-height: 0.74rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-right {
    background-size: 2.79rem 0.53rem;
    width: 2.79rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-right .contact-desc {
    font-size: 0.18rem;
    height: 0.53rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-right .international-charge {
    font-size: 0.14rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .contact-name {
    font-size: 0.18rem;
    margin-left: 0.12rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .contact-svg {
    width: 0.3rem;
    height: 0.3rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left:hover .contact-right {
    font-size: 0.18rem;
  }
}

@media (min-width: 768px)and (max-width: 1024px) {
  .content-contactus .content-center-box .content-center p {
    font-size: 0.24rem;
  }

  .content-contactus .content-center-box .content-center ul li {
    width: 3.71rem;
    min-height: 0.94rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-right {
    width: 3.71rem;
    background-size: 3.71rem 0.94rem;
    margin-top: 1.8rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-right .contact-desc {
    font-size: 0.24rem;
    margin-top: 0.15rem;
    height: 0.94rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-right .international-charge {
    font-size: 0.14rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .contact-name {
    font-size: 0.24rem;
    margin-left: 0.1rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .contact-svg {
    width: 0.3rem;
    height: 0.3rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon .contact-icon-livechat {
    width: 0.44rem;
    height: 0.44rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon .contact-icon-phone {
    width: 0.33rem;
    height: 0.46rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon .contact-icon-whatsapp {
    width: 0.44rem;
    height: 0.44rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon .contact-icon-wechat {
    width: 0.98rem;
    height: 0.88rem;
    margin-left: 0.53rem;
    margin-right: 0.44rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-twitter {
    width: 0.44rem;
    height: 0.44rem;
  }
}

@media (max-width: 768px) {
  .contact-container {
    margin-top: 2.2rem;
  }

  .content-contactus .content-center-box .content-center p {
    font-size: 0.3rem;
    margin-bottom: 0.88rem;
  }

  .content-contactus .content-center-box .content-center p:nth-child(2) {
    display: none;
  }

  .content-contactus .content-center-box .content-center ul li {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .content-contactus .content-center-box .content-center ul li .contact-right {
    display: flex;
    right: -5rem;
    margin-top: 0;
    width: 4.34rem;
    background-image: url(/public/html/sp-images/images/common_img/contact-border-m.png);
    background-size: 4.34rem 0.84rem;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .content-contactus .content-center-box .content-center ul li .contact-right .contact-desc {
    font-size: 0.23rem;
    height: 0.84rem;
    color: #333;
    margin-top: 0;
  }

  .content-contactus .content-center-box .content-center ul li .contact-right .international-charge {
    font-size: 0.14rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left {
    display: flex;
    justify-content: flex-start;
    width: 4.22rem;
    min-height: 1.28rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .contact-name {
    font-size: 0.36rem;
    margin-left: 0.1rem;
    letter-spacing: -0.02rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .contact-svg {
    width: 0.3rem;
    height: 0.3rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon {
    width: 0.88rem;
    height: 0.88rem;
    margin-left: 0.53rem;
    margin-right: 0.44rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-livechat {
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 0.53rem;
    margin-right: 0.44rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-phone {
    width: 0.66rem;
    height: 0.92rem;
    margin-left: 0.69rem;
    margin-right: 0.49rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-whatsapp {
    width: 0.88rem;
    height: 0.88rem;
    margin-left: 0.58rem;
    margin-right: 0.37rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-wechat {
    width: 1.16rem;
    height: 0.98rem;
    margin-left: 0.45rem;
    margin-right: 0.22rem;
  }

  .content-contactus .content-center-box .content-center ul li .contact-left .group-contact-icon.contact-icon-twitter {
    width: 0.7rem;
    height: 0.7rem;
    margin-left: 0.6rem;
    margin-right: 0.22rem;
  }
}

#open-modal {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  background: rgba(0,0,0,.7);
  animation: modalPopUp .3s;
}

#open-modal .col-12 {
  width: 100%;
}

@keyframes modalPopUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#open-modal .modal-lg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 !important;
  border-radius: 0.06rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  max-width: 6rem;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 100%;
  transform: translate(-50%, -50%);
}

#open-modal .quickmodal-header {
  background-color: #0a974b;
  padding: 0.1rem 0.15rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
}

#open-modal .quickmodal-header>div:nth-child(2) {
  width: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#open-modal .quickmodal-header>div:nth-child(3) {
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#open-modal .quickmodal-header>div:nth-child(4) {
  width: 2rem;
}

#open-modal .quick-modal-close {
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50% !important;
  top: -0.15rem;
  right: -0.15rem;
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0;
  font: 400 0.133333rem Arial;
  padding: 0;
  border: unset;
  border-image: initial;
  z-index: 2;
}

#open-modal .quick-modal-close span {
  font-size: 0.2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 0.04rem;
}

#open-modal .main-wallet {
  color: #ffd000;
  font-size: 0.15rem;
}

#open-modal .quicklabel-amount {
  color: #0a974b;
  font-size: 0.15rem;
}

#open-modal .wallet-balance {
  font-size: 0.4rem;
  color: #fff;
}

#open-modal .wallet-currency {
  color: #fff;
  font-size: 0.15rem;
}

#open-modal .icon-quicktransfer {
  fill: #ffd000;
  width: 0.3rem;
  overflow: hidden;
  height: 100%;
  vertical-align: middle;
}

#open-modal .quick-modal-provider {
  height: 100%;
}

#open-modal .quick-modal-provider img {
  vertical-align: middle;
  border-style: none;
  width: 100%;
  height: auto;
}

#open-modal .quickymodal-body {
  padding: 0.2rem;
  overflow-y: scroll;
  min-height: 0.75rem;
  max-height: 6rem;
  background-color: #fff;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

#open-modal .quickymodal-body>div {
  align-items: center;
  position: relative;
  margin-right: 0;
  margin-left: 0;
  flex-wrap: wrap;
  display: flex;
}

#open-modal .quickymodal-body>div .form-group {
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
}

#open-modal .quickymodal-body>div .form-group .col-2 {
  width: 100%;
  max-width: 0.5rem;
  font-size: 0.16rem;
}

#open-modal .quickymodal-body>div .form-group .col-8 {
  width: 100%;
  max-width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#open-modal .quickymodal-body>div .form-group>div:last-child {
  height: 0.7rem;
}

#open-modal .quickymodal-body>div .form-group input {
  border-radius: 0.03rem;
  border: 0.01rem solid #959595;
  background-color: #ebebeb;
  height: 0.3rem;
  font-size: 0.15rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.1rem;
  color: #8b8b8b;
  -webkit-writing-mode: horizontal-tb;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-align: start;
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0;
  font: inherit;
}

#open-modal .quickymodal-body>div .form-group .transfer-form-control-inner {
  width: 100%;
  border-radius: 0.03rem;
  border: 0.01rem solid #959595;
  background-color: #ebebeb !important;
  height: 0.3rem;
  font-size: 0.15rem;
  margin-top: 0.1rem;
}

#open-modal .quickymodal-body .quicklabel-slide {
  color: #bcbcbc;
  display: flex;
}

#open-modal .quickymodal-body .quicklabel-slide>div {
  border-radius: 0.05rem;
  height: 0.2rem;
}

#open-modal .quickymodal-body .quicklabel-slide>div:last-child div {
  border-radius: 0.05rem;
  height: 0.2rem;
}

#open-modal .quickymodal-body .slider-handles button {
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0;
  font: 400 0.133333rem Arial;
  padding: 0;
  border: unset;
  border-image: initial;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 0.05rem;
}

#open-modal .quickmodal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-top: 0.01rem dotted rgba(0,0,0,0);
  height: 0.8rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: #232426;
  width: 100%;
  padding: 0;
  margin-top: -0.2rem;
}

#open-modal .quickmodal-footer>div {
  display: flex;
  width: 100%;
}

#open-modal .quickmodal-footer button {
  border: none;
  width: 100%;
  height: 0.5rem;
  font-family: "Roboto-Bold";
  font-size: 0.16rem;
  cursor: pointer;
}

#open-modal .quickmodal-footer .qkTransfer-submit {
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #222;
}

#open-modal .quickmodal-footer .qkTransfer-close {
  background: #15743c;
  color: #fff;
}

@media (max-width: 1025px) {
  #open-modal {
    transform: scale(1.5);
  }

  #open-modal .quickymodal-body>div .form-group {
    transform: scale(1);
  }

  #open-modal .quickymodal-body>div .form-group .transfer-form-control-inner {
    height: 0.4rem;
  }
}

.subBanner-item {
  position: relative;
  margin-bottom: 0.72rem;
  max-width: 20rem;
  margin: 0 auto;
}

.subBanner-item img {
  width: 100%;
}

.subBanner-item .content-container {
  position: absolute;
  width: 6rem;
  top: 0.8rem;
  right: 2.7rem;
}

.subBanner-item .content-container .text-box1,
.subBanner-item .content-container .text-box2 {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.subBanner-item .content-container .text-box1 .text-banner-green,
.subBanner-item .content-container .text-box2 .text-banner-green {
  font-size: 0.23rem;
  margin-bottom: 0.05rem;
  font-style: unset;
}

.subBanner-item .content-container .text-box1 .text-banner-content,
.subBanner-item .content-container .text-box2 .text-banner-content {
  font-size: 0.16rem;
  text-align: justify;
}

.subBanner-item .content-container .text-box1 {
  margin-bottom: 0.64rem;
}

.vip-container .content-section .home-content-section {
  position: relative;
}

.vip-container .content-section .home-content-section .vip-table {
  width: 11.98rem;
  margin-left: 0.4rem;
  margin-bottom: 0.99rem;
}

.vip-container .content-section .home-content-section .vip-table .table-title {
  margin-left: 0.22rem;
  margin-bottom: 0.54rem;
  font-size: 0.24rem;
  color: #19723c;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .vip-container .content-section .home-content-section .vip-table .table-title {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.vip-container .content-section .home-content-section .vip-table table {
  vertical-align: middle;
  border-color: inherit;
  width: 100%;
  border-spacing: 0;
  margin-bottom: 0.38rem;
}

.vip-container .content-section .home-content-section .vip-table table tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  text-align: center;
}

.vip-container .content-section .home-content-section .vip-table table tr td {
  align-items: flex-start;
  display: table-cell;
  vertical-align: inherit;
  font-size: 0.16rem;
  color: #13242c;
  padding: 0.16rem 0;
}

.vip-container .content-section .home-content-section .vip-table table tr td.type-title {
  font-size: 0.24rem;
  color: #d4b366;
  font-weight: bold;
  font-family: "AvenirNextLTPro-Bold";
}

.vip-container .content-section .home-content-section .vip-table table tr td:not(:first-child) {
  border-left: 0.02rem solid #d4b366;
}

.vip-container .content-section .home-content-section .vip-table table tr td:first-child {
  text-align: left;
  padding-left: 0.22rem;
}

.vip-container .content-section .home-content-section .vip-table table tr td.content-title {
  text-align: center;
  color: #fff;
  background-color: #d4b366;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .vip-container .content-section .home-content-section .vip-table table tr td.content-title {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.vip-container .content-section .home-content-section .vip-table table tr td img {
  width: 1.36rem;
  height: 0.87rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.vip-container .content-section .home-content-section .vip-table table tr:nth-child(odd) {
  background: #faf4e5;
}

.vip-container .content-section .home-content-section .vip-table table tr:nth-child(even) {
  background: #fefcf8;
}

.vip-container .content-section .home-content-section .vip-table table tr:first-child {
  background: unset;
}

.vip-container .content-section .home-content-section .vip-table table tr:first-child td {
  padding: 0;
  border-left: none;
}

.vip-container .content-section .home-content-section .vip-table table tr:nth-child(2) {
  background: unset;
}

.vip-container .content-section .home-content-section .vip-table table tr:nth-child(2) td {
  border-left: none;
}

.vip-container .content-section .home-content-section .vip-table .tnc-apply {
  margin-left: 0.22rem;
  cursor: pointer;
}

.vip-container .content-section .home-content-section .vip-benefit {
  width: 11.98rem;
  margin-left: 0.4rem;
  margin-bottom: 0.88rem;
  display: flex;
  align-items: baseline;
}

.vip-container .content-section .home-content-section .vip-benefit>div {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-right: 0.45rem;
  font-size: 0.18rem;
  color: #a58640;
}

.vip-container .content-section .home-content-section .vip-benefit>div:last-child {
  margin-right: 0;
}

.vip-container .content-section .home-content-section .vip-benefit .top-service img {
  width: 1.89rem;
  height: 1.79rem;
}

.vip-container .content-section .home-content-section .vip-benefit .exclu-promo img {
  width: 2.1rem;
  height: 1.68rem;
}

.vip-container .content-section .home-content-section .vip-benefit .birth-gift img {
  width: 1.89rem;
  height: 1.82rem;
}

.vip-container .content-section .home-content-section .vip-benefit .life-member img {
  width: 2.01rem;
  height: 1.83rem;
}

.vip-container .content-section .home-content-section .vip-girl {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 0.34rem;
  width: 2.61rem;
  height: 5.56rem;
}

.vip-container .messaging-popup .popup .popup-body {
  max-height: unset;
}

.vip-container .messaging-popup .popup .popup-body ol li {
  list-style: decimal;
  margin: 0.1rem 0.3rem;
  padding-left: 0.2rem;
  text-align: justify;
}

@media (max-width: 1999px) {
  .subBanner-item .content-container {
    top: 10%;
    right: unset;
    left: 56.5%;
  }
}

@media (max-width: 1445px) {
  .subBanner-item .content-container {
    top: 0.3rem;
    right: 0.8rem;
  }
}

@media (max-width: 1025px) {
  .subBanner-item {
    max-width: 20.48rem;
  }

  .subBanner-item .content-container {
    width: 8rem;
    top: 0.7rem;
    right: 1rem;
  }

  .subBanner-item .content-container .text-box1,
  .subBanner-item .content-container .text-box2 {
    margin-bottom: 0.5rem;
  }

  .subBanner-item .content-container .text-box1 .text-banner-green,
  .subBanner-item .content-container .text-box2 .text-banner-green {
    font-size: 0.3rem;
    margin-bottom: 0.2rem;
  }

  .subBanner-item .content-container .text-box1 .text-banner-content,
  .subBanner-item .content-container .text-box2 .text-banner-content {
    font-size: 0.2rem;
  }

  .vip-container .content-section .home-content-section .vip-table {
    width: 16.7rem;
  }

  .vip-container .content-section .home-content-section .vip-table .table-title {
    font-size: 0.32rem;
  }

  .vip-container .content-section .home-content-section .vip-table table tr td {
    font-size: 0.22rem;
  }

  .vip-container .content-section .home-content-section .vip-table table tr td.type-title {
    font-size: 0.32rem;
  }

  .vip-container .content-section .home-content-section .vip-table table tr td img {
    width: 2rem;
    height: 1.3rem;
  }

  .vip-container .content-section .home-content-section .vip-benefit>div {
    font-size: 0.25rem;
  }

  .vip-container .content-section .home-content-section .vip-benefit .top-service img {
    width: 2.5rem;
    height: 2.4rem;
  }

  .vip-container .content-section .home-content-section .vip-benefit .exclu-promo img {
    width: 3rem;
    height: 2.4rem;
  }

  .vip-container .content-section .home-content-section .vip-benefit .birth-gift img {
    width: 2.5rem;
    height: 2.45rem;
  }

  .vip-container .content-section .home-content-section .vip-benefit .life-member img {
    width: 2.8rem;
    height: 2.55rem;
  }

  .vip-container .content-section .home-content-section .vip-girl {
    margin-right: 0;
    width: 3.5rem;
    height: 7.45rem;
  }
}

@media (max-width: 768px) {
  .subBanner-item img {
    display: none;
  }

  .subBanner-item .content-container {
    position: relative;
    width: 100%;
    top: unset;
    right: unset;
    left: unset;
    padding: 0.5rem 0.35rem 0;
  }

  .subBanner-item .content-container .text-box1 .text-banner-green,
  .subBanner-item .content-container .text-box2 .text-banner-green {
    font-size: 0.4rem !important;
  }

  .subBanner-item .content-container .text-box1 .text-banner-content,
  .subBanner-item .content-container .text-box2 .text-banner-content {
    font-size: 0.35rem;
  }

  .vip-container .content-section .home-content-section .vip-table {
    margin-left: 0;
    width: 100%;
    padding: 0 0.35rem;
  }

  .vip-container .content-section .home-content-section .vip-table .table-title {
    font-size: 0.4rem;
    margin-left: 0;
  }

  .vip-container .content-section .home-content-section .vip-table>div {
    overflow-x: scroll;
    margin-bottom: 0.38rem;
  }

  .vip-container .content-section .home-content-section .vip-table table {
    overflow-x: scroll;
    margin-bottom: 0;
  }

  .vip-container .content-section .home-content-section .vip-table table tr td {
    white-space: nowrap;
    padding: 0.35rem 0.22rem;
    font-size: 0.35rem;
  }

  .vip-container .content-section .home-content-section .vip-table table tr td.type-title {
    font-size: 0.4rem;
  }

  .vip-container .content-section .home-content-section .vip-table table tr td img {
    width: 3.5rem;
    height: 1.9rem;
    padding: 0 0.2rem;
  }

  .vip-container .content-section .home-content-section .vip-table .tnc-apply {
    font-size: 0.35rem;
  }

  .vip-container .content-section .home-content-section .vip-benefit {
    flex-flow: wrap;
    justify-content: center;
  }

  .vip-container .content-section .home-content-section .vip-benefit>div {
    margin-right: 1rem;
    margin-bottom: 0.65rem;
    font-size: 0.3rem;
  }

  .vip-container .content-section .home-content-section .vip-benefit>div:nth-child(2) {
    margin-right: 0;
  }

  .vip-container .content-section .home-content-section .vip-benefit .top-service img {
    width: 4.5rem;
    height: 100%;
  }

  .vip-container .content-section .home-content-section .vip-benefit .exclu-promo img {
    width: 5rem;
    height: 100%;
  }

  .vip-container .content-section .home-content-section .vip-benefit .birth-gift img {
    width: 4.5rem;
    height: 100%;
  }

  .vip-container .content-section .home-content-section .vip-benefit .life-member img {
    width: 4.8rem;
    height: 100%;
  }

  .vip-container .content-section .home-content-section .vip-girl {
    display: none;
  }

  .vip-container .messaging-popup .popup .popup-body {
    padding: 0.5rem 1rem;
  }

  .vip-container .messaging-popup .popup .popup-body ol li {
    margin: 0.1rem 0 0.1rem 0.3rem;
    font-size: 0.45rem;
  }
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral {
  padding: 0.3rem;
  min-height: 7rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .row-separator-4 {
  width: 100%;
  height: 0.04rem;
  background: rgba(0,0,0,0);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .row-separator-10 {
  width: 100%;
  height: 0.1rem;
  background: rgba(0,0,0,0);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .row-separator-15 {
  width: 100%;
  height: 0.15rem;
  background: rgba(0,0,0,0);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .row-separator-25 {
  width: 100%;
  height: 0.25rem;
  background: rgba(0,0,0,0);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .row-separator-30 {
  width: 100%;
  height: 0.3rem;
  background: rgba(0,0,0,0);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .row-separator-40 {
  width: 100%;
  height: 0.4rem;
  background: rgba(0,0,0,0);
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .row-separator-line {
  border-bottom: 0.01rem solid #e2e2e2;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .inbox {
  display: flex;
  color: #fff;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .inbox span {
  width: 1.37rem;
  height: 0.35rem;
  background-color: #18723c;
  border-top-right-radius: 0.04rem;
  border-top-left-radius: 0.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.04rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .inbox span:last-child {
  margin-right: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .inbox span.tab-active {
  background-color: #39b549;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container {
  display: flex;
  justify-content: space-between;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .left-container {
  width: 69%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .right-container {
  width: 30%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info {
  display: flex;
  flex-flow: column;
  font-size: 0.14rem;
  background-color: #cfe8d3;
  font-weight: 600;
  padding: 0.12rem 0 0.12rem 0.3rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info .referral-link-table {
  width: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info .referral-link-table .title-column {
  width: 16%;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info .referral-link-table .title-column {
  font-family: "Alexandria";
  font-weight: 500;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info .referral-link-table .colon-column {
  width: 5%;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info .referral-link-table .colon-column {
  font-family: "Alexandria";
  font-weight: 500;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info .referral-link-table .content-column {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info .referral-link-table .content-column {
  font-family: "Alexandria";
  font-weight: 500;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info .referral-link-table .content-column>p {
  margin-right: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info>div {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info>div:first-child {
  margin-bottom: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info>div p {
  margin-right: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info>div p span {
  margin-right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .user-info>div .icon-box {
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container {
  padding-left: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-container-title {
  font-size: 0.18rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container {
  padding-left: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-option {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-option input {
  -webkit-appearance: radio;
          appearance: radio;
  display: block;
  opacity: 0;
  height: 0;
  width: 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-option input:checked~.checkmark {
  background: #0a974b;
  position: relative;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-option input:checked~.checkmark::before {
  content: "";
  border: 0.05rem solid #fff;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-option input:checked~.checkmark::after {
  content: "";
  border: 0.02rem solid #0a974b;
  width: initial;
  height: initial;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-option .checkmark {
  height: 0.2rem;
  width: 0.2rem;
  background-color: #eee;
  border-radius: 50%;
  margin-right: 0.1rem;
  cursor: pointer;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-option .checkmark:hover {
  background: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-option .option-amount {
  margin-left: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #39b64b;
  color: #000;
  border-radius: 0.04rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.18rem;
  font-weight: bold;
  padding: 0.16rem 0.5rem;
  margin-bottom: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-box .share-amount {
  font-family: "AvenirNextLTPro-Bold";
  font-size: 0.3rem;
  font-weight: bolder;
  color: #ffdf89;
  margin-bottom: 0.05rem;
}

html:lang(vi) .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-box .share-amount {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-box .share-detail .freebetTable tbody tr td {
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .share-box .share-detail .freebetTable tbody tr td {
  font-family: "Alexandria";
  font-weight: 500;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .invite-btn {
  background: #0a974b;
  border-radius: 0.02rem;
  font-weight: bold;
  color: #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .messaging-popup .popup .popup-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .messaging-popup .popup .popup-body>:not(:last-child) {
  margin-right: 0.5rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .messaging-popup .popup .popup-body>:nth-child(n) {
  width: 0.64rem;
  height: 0.64rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .messaging-popup .popup .popup-body .icon-copy,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-option-container .referral-radio-button-container .messaging-popup .popup .popup-body svg {
  width: 100%;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-content {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.01rem solid #dbdbdb;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container {
  padding-bottom: 0.4rem;
  display: flex;
  flex-flow: column;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container>p {
  padding-left: 0.1rem;
  font-size: 0.2rem;
  color: #000;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box {
  background: #d0ead4;
  color: #000;
  border-radius: 0.04rem;
  font-weight: bold;
  padding: 0.12rem 0 0.12rem 0.3rem;
  margin-top: 0;
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .status-detail-table {
  width: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .status-detail-table tr {
  height: 0.25rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .status-detail-table .title-column {
  width: 22.5%;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .status-detail-table .title-column {
  font-family: "Alexandria";
  font-weight: 500;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .status-detail-table .colon-column {
  width: 5%;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .status-detail-table .colon-column {
  font-family: "Alexandria";
  font-weight: 500;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .status-detail-table .content-column {
  width: 70.5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 0.25rem;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .status-detail-table .content-column {
  font-family: "Alexandria";
  font-weight: 500;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .status-detail-table .content-column>p {
  margin-right: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .invitation-box {
  margin-bottom: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .invitation-box span {
  margin-left: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .referral-status-container .status-box .mission-box span {
  margin-left: 0.2rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fffeff;
  border: 0.01rem solid #e2e2e2;
  padding: 0.29rem 0.23rem;
  margin-right: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box .title {
  margin: 0 auto;
  margin-bottom: 0.24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.14rem;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box .title>span {
  margin-left: 0.12rem;
  text-transform: uppercase;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box>ul {
  font-size: 0.14rem;
  font-family: Arial;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box>ul li {
  display: flex;
  margin-bottom: 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box>ul li>span {
  padding-top: 0.01rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box>ul li>span,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box>ul li>p span {
  color: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box>ul li>span {
  margin-right: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box>ul li>span.empty-span {
  margin-right: 0.22rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-content-container .notice-box>ul li a {
  color: #2196f3;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container {
  border-top: 0.01rem solid #dbdbdb;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage {
  padding: 0 0.22rem;
  min-height: 0.52rem;
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top {
  display: flex;
  align-items: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top .top-title {
  min-width: 1.35rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top .top-title .bonus-label {
  color: #59c066;
  font-size: 0.14rem;
  min-width: 1.35rem;
  font-weight: 600;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top input {
  color: #1c2c34;
  font-size: 0.14rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #dadada;
  width: 1.3rem;
  padding: 0 0.12rem;
  outline: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top .to-label {
  max-width: 0.7rem;
  text-transform: lowercase;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top .to-label .bonus-label {
  min-width: 0.45rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #59c066;
  font-size: 0.14rem;
  font-weight: 600;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .btn-search {
  border: 0;
  border-radius: 0.03rem;
  background: #0a974b;
  color: #fff;
  text-transform: capitalize;
  margin-left: 0.15rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container {
  margin: 0.4rem 0;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table {
  vertical-align: middle;
  border-color: inherit;
  width: 100%;
  border-spacing: 0;
  margin-bottom: 0.38rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  text-align: center;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr th,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr td {
  align-items: flex-start;
  display: table-cell;
  vertical-align: inherit;
  font-size: 0.14rem;
  padding: 0.16rem 0;
  color: #000;
  font-weight: normal;
  background-color: rgba(0,0,0,0);
  border-bottom: 0.01rem solid #dcdcdc;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr:first-child {
  background-color: #cfe8d3;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr:first-child th {
  font-size: 0.15rem;
  color: #005329;
  font-weight: 600;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr .deposit-amount {
  color: #0a974b;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr .deposit-amount img {
  width: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .btn-disable {
  cursor: not-allowed;
  pointer-events: none;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination {
  font-size: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination svg {
  max-width: 0.14rem;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li {
  background: #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li a {
  padding: 0.05rem 0.1rem;
  display: flex;
  align-items: center;
  height: 100%;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.active,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li:hover {
  background: #39b549;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.active a,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.active svg,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li:hover a,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li:hover svg {
  color: #fff;
  fill: #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.disabled svg {
  fill: #acacac;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.disabled.active,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.disabled:hover {
  background: #fff;
}

.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.disabled.active a,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.disabled.active svg,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.disabled:hover a,
.member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination li.disabled:hover svg {
  color: #acacac;
  fill: #acacac;
  cursor: not-allowed;
}

@media (max-width: 1025px)and (min-width: 768px) {
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral {
    padding-top: 0.5rem;
    min-height: 10rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .inbox {
    font-size: 0.19rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .inbox span {
    width: 1.27rem;
    height: 0.43rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .user-info {
    font-size: 0.19rem;
    padding: 0.2rem 0;
    padding-left: 0.5rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .referral-content .notice-box {
    width: 4.2rem;
    padding: 0.43rem 0.28rem;
    margin-top: -1.52rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .referral-content .notice-box .title {
    font-size: 0.21rem;
    margin-bottom: 0.36rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .referral-content .notice-box>ul {
    font-size: 0.18rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top .top-title {
    width: 2.4rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top .top-title .bonus-label {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top input {
    font-size: 0.21rem;
    width: 1.7rem;
    height: 0.54rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-formpage .form-bonus .form-item .top .to-label .bonus-label {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr th,
  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr td {
    font-size: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .bonus-container table tr:first-child th {
    font-size: 0.21rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination {
    font-size: 0.2rem;
  }

  .member .member-main .main-wallet-content .main-wallet-main .main-wallet-main-content .referral .referral-container .pagination svg {
    max-width: 0.2rem;
  }
}

@media (max-width: 768px) {
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box {
    padding: 0 1rem;
    margin-bottom: 0.5rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .title-item {
    font-size: 0.42rem;
    color: #4d4d4d;
    height: 0.6rem;
    margin-bottom: 0.38rem;
    padding-right: 0.53rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .title-item>span {
    font-family: "OpenSans-Bold";
    font-weight: bold;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .title-item>span .asterisk {
    color: red;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .invite-box {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 0.5rem;
    font-size: 0.3rem;
    background-color: #cfe8d3;
    color: #005329;
    font-weight: 600;
    text-align: justify;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .invite-box .invite-text {
    margin-bottom: 0.5rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .invite-box .invite-text span {
    font-size: 0.35rem;
    color: #002010;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .invite-box .invite-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .invite-box .invite-info span {
    margin-right: 0.2rem;
    background: #fff;
    border: 0.02rem dashed #13242c;
    border-radius: 0.05rem;
    padding: 0.2rem 0.3rem;
    width: 70%;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .invite-box .invite-info .invite-btn {
    width: 30%;
    height: 0.9rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .messaging-popup .popup .popup-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    padding: 0.5rem 0.5rem 0;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .messaging-popup .popup .popup-body>:nth-child(n) {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.5rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .messaging-popup .popup .popup-body>:not(:last-child) {
    margin-right: 0.1rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .messaging-popup .popup .popup-body .icon-copy,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .messaging-popup .popup .popup-body svg {
    width: 100%;
    height: 100%;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content {
    padding-top: 0.85rem;
    padding-bottom: 0.97rem;
    display: flex;
    flex-direction: column;
    border-bottom: 0.01rem solid #e2e2e2;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content p {
    font-size: 0.45rem;
    color: #000;
    margin-bottom: 0.3rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content {
    width: 100%;
    padding: 0.4rem 0.4rem 0;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-option {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0.2rem;
    font-size: 0.4rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-option input {
    -webkit-appearance: radio;
            appearance: radio;
    display: block;
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-option input:checked~.checkmark {
    background: #0a974b;
    position: relative;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-option input:checked~.checkmark::before {
    content: "";
    border: 0.1rem solid #fff;
    width: initial;
    height: initial;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-option input:checked~.checkmark::after {
    content: "";
    border: 0.05rem solid #0a974b;
    width: initial;
    height: initial;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-option .checkmark {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #eee;
    border-radius: 50%;
    margin-right: 0.2rem;
    cursor: pointer;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-option .option-amount {
    margin-left: 0.15rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-box {
    background: #59b766;
    color: #fff;
    border-radius: 0.05rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.35rem;
    font-weight: bold;
    padding: 0.41rem 1.24rem;
    margin-bottom: 0.75rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-box .share-amount {
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.6rem;
    font-weight: bolder;
    color: #ffdf89;
    margin-bottom: 0.05rem;
  }

  html:lang(vi) #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-box .share-amount {
    font-family: "Alexandria";
    font-weight: 700;
    text-transform: capitalize;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-box .share-detail .freebetTable tbody tr td {
    font-family: "AvenirNextLTPro-Bold";
  }

  html:lang(vi) #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .share-box .share-detail .freebetTable tbody tr td {
    font-family: "Alexandria";
    font-weight: 500;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-content .share-content .invite-btn {
    background: #0a974b;
    border-radius: 0.05rem;
    font-weight: bold;
    color: #fff;
    height: 0.88rem;
    width: 4.15rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-status {
    margin-top: 0.85rem;
    display: flex;
    flex-flow: column;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-status>p {
    font-size: 0.45rem;
    color: #000;
    margin-bottom: 0.42rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-status .status-box {
    background: #d0ead4;
    color: #000;
    border-radius: 0.04rem;
    font-size: 0.35rem;
    font-weight: bold;
    padding: 0.3rem 0.5rem;
    margin-bottom: 0.5rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .referral-status .tnc-apply {
    margin-left: 0.4rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-formpage .form-bonus .form-item {
    margin-bottom: 0.37rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-formpage .form-bonus .form-item .top .top-title,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-formpage .form-bonus .form-item .top .to-label {
    font-size: 0.42rem;
    color: #4d4d4d;
    height: 0.6rem;
    margin-bottom: 0.38rem;
    padding-right: 0.53rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-formpage .form-bonus .form-item .top .top-title .bonus-label,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-formpage .form-bonus .form-item .top .to-label .bonus-label {
    font-weight: bold;
    font-family: "OpenSans-Bold";
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-formpage .form-bonus .form-item:nth-child(3) {
    display: flex;
    justify-content: flex-start;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-formpage .form-bonus .form-item:nth-child(3) .btn-search {
    cursor: pointer;
    width: 5.23rem;
    height: 0.98rem;
    border-radius: 0.13rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.42rem;
    font-family: "OpenSans-Bold";
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    background: #0a974b;
    border: 0;
    margin-top: 0.4rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container {
    overflow-x: scroll;
    margin: 1rem 0 0;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container table {
    vertical-align: middle;
    border-color: inherit;
    width: 100%;
    border-spacing: 0;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container table tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    text-align: center;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container table tr td {
    align-items: flex-start;
    display: table-cell;
    vertical-align: inherit;
    font-size: 0.35rem;
    padding: 0.16rem 0;
    color: #000;
    font-weight: normal;
    background-color: #fff;
    border-bottom: 0.01rem solid #e4e4e4;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container table tr th,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container table tr td {
    white-space: nowrap;
    padding: 0.2rem 0.5rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container table tr:first-child {
    background: #d7f1db;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container table tr:first-child th {
    font-size: 0.4rem;
    color: #075129;
    font-weight: 600;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container table tr .deposit-amount {
    color: #0a974b;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .bonus-container table tr .deposit-amount img {
    width: 100%;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .btn-disable {
    cursor: not-allowed;
    pointer-events: none;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination {
    font-size: 0.35rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination svg {
    max-width: 0.35rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li {
    background: #fff;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li a {
    padding: 0.1rem 0.2rem;
    display: flex;
    align-items: center;
    height: 100%;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.active,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li:hover {
    background: #39b549;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.active a,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.active svg,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li:hover a,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li:hover svg {
    color: #fff;
    fill: #fff;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.disabled svg {
    fill: #acacac;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.disabled.active,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.disabled:hover {
    background: #fff;
  }

  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.disabled.active a,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.disabled.active svg,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.disabled:hover a,
  #root .wap .wap .wap-pages-out .wap-pages>div.wap-referral-page .referral-item-box .referral-container .pagination li.disabled:hover svg {
    color: #acacac;
    fill: #acacac;
    cursor: not-allowed;
  }
}

.deposit-tab-content .qrpay>ul:first-child,
.deposit-tab-content .app-onlinetransfer>ul:first-child,
.deposit-tab-content .p2p>ul:first-child,
.deposit-tab-content .bqr>ul:first-child,
.deposit-tab-content .quickpay>ul:first-child {
  display: flex;
}

.deposit-tab-content .qrpay>ul:first-child .notice-box,
.deposit-tab-content .app-onlinetransfer>ul:first-child .notice-box,
.deposit-tab-content .p2p>ul:first-child .notice-box,
.deposit-tab-content .bqr>ul:first-child .notice-box,
.deposit-tab-content .quickpay>ul:first-child .notice-box {
  width: 3rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fffeff;
  border: 0.01rem solid #e2e2e2;
  padding: 0.29rem 0.23rem;
  position: absolute;
  top: 1.1rem;
  right: 0.5rem;
}

.deposit-tab-content .qrpay>ul:first-child .notice-box .title,
.deposit-tab-content .app-onlinetransfer>ul:first-child .notice-box .title,
.deposit-tab-content .p2p>ul:first-child .notice-box .title,
.deposit-tab-content .bqr>ul:first-child .notice-box .title,
.deposit-tab-content .quickpay>ul:first-child .notice-box .title {
  margin: 0 auto;
  margin-bottom: 0.24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.14rem;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

.deposit-tab-content .qrpay>ul:first-child .notice-box .title span,
.deposit-tab-content .app-onlinetransfer>ul:first-child .notice-box .title span,
.deposit-tab-content .p2p>ul:first-child .notice-box .title span,
.deposit-tab-content .bqr>ul:first-child .notice-box .title span,
.deposit-tab-content .quickpay>ul:first-child .notice-box .title span {
  margin-left: 0.12rem;
}

.deposit-tab-content .qrpay>ul:first-child .notice-box>ul,
.deposit-tab-content .app-onlinetransfer>ul:first-child .notice-box>ul,
.deposit-tab-content .p2p>ul:first-child .notice-box>ul,
.deposit-tab-content .bqr>ul:first-child .notice-box>ul,
.deposit-tab-content .quickpay>ul:first-child .notice-box>ul {
  font-size: 0.14rem;
  font-family: Arial;
}

.deposit-tab-content .qrpay>ul:first-child .notice-box>ul li,
.deposit-tab-content .app-onlinetransfer>ul:first-child .notice-box>ul li,
.deposit-tab-content .p2p>ul:first-child .notice-box>ul li,
.deposit-tab-content .bqr>ul:first-child .notice-box>ul li,
.deposit-tab-content .quickpay>ul:first-child .notice-box>ul li {
  display: flex;
  margin-bottom: 0.15rem;
}

.deposit-tab-content .qrpay>ul:first-child .notice-box>ul li>span,
.deposit-tab-content .app-onlinetransfer>ul:first-child .notice-box>ul li>span,
.deposit-tab-content .p2p>ul:first-child .notice-box>ul li>span,
.deposit-tab-content .bqr>ul:first-child .notice-box>ul li>span,
.deposit-tab-content .quickpay>ul:first-child .notice-box>ul li>span {
  margin-right: 0.14rem;
}

.deposit-tab-content .qrpay>ul:first-child .deposit-amount,
.deposit-tab-content .app-onlinetransfer>ul:first-child .deposit-amount,
.deposit-tab-content .p2p>ul:first-child .deposit-amount,
.deposit-tab-content .bqr>ul:first-child .deposit-amount,
.deposit-tab-content .quickpay>ul:first-child .deposit-amount {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.deposit-tab-content .qrpay>ul:first-child .deposit-amount>div,
.deposit-tab-content .app-onlinetransfer>ul:first-child .deposit-amount>div,
.deposit-tab-content .p2p>ul:first-child .deposit-amount>div,
.deposit-tab-content .bqr>ul:first-child .deposit-amount>div,
.deposit-tab-content .quickpay>ul:first-child .deposit-amount>div {
  width: 100%;
}

.deposit-tab-content .qrpay>ul:first-child .deposit-amount img,
.deposit-tab-content .app-onlinetransfer>ul:first-child .deposit-amount img,
.deposit-tab-content .p2p>ul:first-child .deposit-amount img,
.deposit-tab-content .bqr>ul:first-child .deposit-amount img,
.deposit-tab-content .quickpay>ul:first-child .deposit-amount img {
  width: 100%;
}

.deposit-tab-content .qrpay>ul:first-child .deposit-amount .deposit-amount-input,
.deposit-tab-content .app-onlinetransfer>ul:first-child .deposit-amount .deposit-amount-input,
.deposit-tab-content .p2p>ul:first-child .deposit-amount .deposit-amount-input,
.deposit-tab-content .bqr>ul:first-child .deposit-amount .deposit-amount-input,
.deposit-tab-content .quickpay>ul:first-child .deposit-amount .deposit-amount-input {
  position: relative;
  width: auto;
}

.deposit-tab-content .qrpay>ul:first-child .deposit-amount .deposit-amount-input input,
.deposit-tab-content .app-onlinetransfer>ul:first-child .deposit-amount .deposit-amount-input input,
.deposit-tab-content .p2p>ul:first-child .deposit-amount .deposit-amount-input input,
.deposit-tab-content .bqr>ul:first-child .deposit-amount .deposit-amount-input input,
.deposit-tab-content .quickpay>ul:first-child .deposit-amount .deposit-amount-input input {
  padding: 0 .4rem 0 .12rem;
}

.deposit-tab-content .qrpay>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button,
.deposit-tab-content .app-onlinetransfer>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button,
.deposit-tab-content .p2p>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button,
.deposit-tab-content .bqr>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button,
.deposit-tab-content .quickpay>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: .1rem;
  width: .25rem;
  display: flex;
}

.deposit-tab-content .qrpay>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button img,
.deposit-tab-content .app-onlinetransfer>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button img,
.deposit-tab-content .p2p>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button img,
.deposit-tab-content .bqr>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button img,
.deposit-tab-content .quickpay>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button img {
  max-width: 100%;
  width: auto;
}

.deposit-tab-content .qrpay>ul:first-child .deposit-amount .invalid-feedback,
.deposit-tab-content .app-onlinetransfer>ul:first-child .deposit-amount .invalid-feedback,
.deposit-tab-content .p2p>ul:first-child .deposit-amount .invalid-feedback,
.deposit-tab-content .bqr>ul:first-child .deposit-amount .invalid-feedback,
.deposit-tab-content .quickpay>ul:first-child .deposit-amount .invalid-feedback {
  align-self: start;
}

.promoCodeDetail-popup .popup .popup-body {
  border-radius: 0.1rem;
}

.promoCodeDetail-popup .popup .popup-body .body-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.3rem;
}

.promoCodeDetail-popup .popup .popup-body .body-header>span {
  font-size: 0.26rem;
  font-weight: bold;
  color: #4c4c4c;
  width: 95%;
}

.promoCodeDetail-popup .popup .popup-body .body-header>svg {
  fill: #39b549;
  width: 0.3rem;
  height: 0.3rem;
  cursor: pointer;
}

.promoCodeDetail-popup .popup .popup-body table {
  font-size: 0.2rem;
  width: 100%;
  border: 0.01rem solid #39b549;
  border-radius: 0.1rem;
  margin-bottom: 0.5rem;
}

.promoCodeDetail-popup .popup .popup-body table tr:nth-child(odd) {
  background: #f2f2f2;
}

.promoCodeDetail-popup .popup .popup-body table tr td {
  border-bottom: 0.01rem solid #39b549;
  padding: 0.1rem;
}

.promoCodeDetail-popup .popup .popup-body table tr td:last-child {
  font-weight: bold;
}

.promoCodeDetail-popup .popup .popup-body table tr:last-child td {
  border-bottom: none;
}

.promoCodeDetail-popup .popup .popup-body .tnc-apply {
  margin: unset;
  border-bottom: unset;
}

@media (max-width: 1025px) {
  .deposit-tab-content .app-onlinetransfer>ul:first-child .notice-box .title,
  .deposit-tab-content .app-onlinetransfer>ul:first-child .notice-box>ul,
  .deposit-tab-content .quickpay>ul:first-child .notice-box .title,
  .deposit-tab-content .quickpay>ul:first-child .notice-box>ul {
    font-size: 0.18rem;
  }

  .promoCodeDetail-popup .popup .popup-body {
    border-radius: 0.15rem;
  }

  .promoCodeDetail-popup .popup .popup-body .body-header {
    margin-bottom: 0.38rem;
  }

  .promoCodeDetail-popup .popup .popup-body .body-header>span {
    font-size: 0.36rem;
  }

  .promoCodeDetail-popup .popup .popup-body .body-header>svg {
    width: 0.5rem;
    height: 0.5rem;
  }

  .promoCodeDetail-popup .popup .popup-body table {
    font-size: 0.3rem;
    border-radius: 0.15rem;
    margin-bottom: 0.58rem;
  }

  .promoCodeDetail-popup .popup .popup-body table tr td {
    padding: 0.15rem;
  }
}

@media (max-width: 768px) {
  .providerTransferTo-popUp .popup .popup-body {
    padding: 0.7rem 0.43rem;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.42rem;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box {
    width: 8.8rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    white-space: nowrap;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box>span {
    height: 1.15rem;
    display: flex;
    align-items: center;
    margin-right: 0.69rem;
    font-weight: bold;
    color: #4c4c4c;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .promo-icon-arrow {
    display: flex;
    align-items: center;
    transform: rotate(270deg);
    width: 0.68rem;
    height: 1.15rem;
    fill: #38b845;
    margin-right: 0.86rem;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .provider-to {
    width: 5.1rem;
    margin-bottom: 0.56rem;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .provider-to .provider-balance {
    height: 1.15rem;
    display: flex;
    align-items: center;
    padding: 0.11rem;
    background: #f3f2f2;
    border-radius: 0.2rem;
    margin-bottom: 0.27rem;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .provider-to .provider-balance>i {
    width: 0.95rem;
    height: 0.92rem;
    margin-right: 0.31rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .provider-to .provider-balance>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .provider-to .provider-balance>div span {
    font-weight: bold;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .provider-to .provider-balance>div span:first-child {
    color: #139343;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .provider-to .provider-turover {
    display: flex;
    flex-direction: column;
    margin-left: 1.33rem;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .provider-to .provider-turover span:first-child {
    font-weight: bold;
    color: #177438;
  }

  .providerTransferTo-popUp .popup .popup-body .providerInfo-box .provider-to .provider-turover span:last-child {
    color: #43c14f;
  }

  .providerTransferTo-popUp .popup .popup-body .providerTransfer-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.28rem;
  }

  .providerTransferTo-popUp .popup .popup-body .providerTransfer-box>span {
    width: 8.8rem;
    font-weight: bold;
    color: #4c4c4c;
    margin-bottom: 0.3rem;
  }

  .providerTransferTo-popUp .popup .popup-body .providerTransfer-box .amount-box {
    display: flex;
    align-items: center;
    width: 9.63rem;
    height: 1rem;
    padding: 0.03rem;
    border: 0.01rem solid #05955a;
    border-radius: 0.2rem;
  }

  .providerTransferTo-popUp .popup .popup-body .providerTransfer-box .amount-box input {
    height: 100% !important;
    background: unset !important;
    width: 5.74rem !important;
    padding: 0 0.5rem !important;
  }

  .providerTransferTo-popUp .popup .popup-body .providerTransfer-box .amount-box .btn-all-in {
    width: 3.84rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #c29b53;
    background: #2d2d2d;
    border: 0.05rem solid #c29b53;
    border-radius: 0.2rem;
  }

  .providerTransferTo-popUp .popup .popup-body .range-box {
    width: 9.63rem !important;
    height: 0.36rem !important;
    padding: 0.18rem 0.02rem !important;
    background: unset !important;
    border: 0.01rem solid #05955a !important;
    border-radius: 0.2rem !important;
    margin-bottom: 0.78rem;
  }

  .providerTransferTo-popUp .popup .popup-body .range-box::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  .providerTransferTo-popUp .popup .popup-body .range-box::-webkit-slider-thumb {
    width: 1.28rem;
    height: 0.26rem;
    background: #05955a;
    border-radius: 0.2rem;
  }

  .providerTransferTo-popUp .popup .popup-body .specialPromo-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.28rem;
  }

  .providerTransferTo-popUp .popup .popup-body .specialPromo-box>span {
    width: 8.8rem;
    color: #05955a;
    margin-bottom: 0.08rem;
  }

  .providerTransferTo-popUp .popup .popup-body .specialPromo-box>span.specialCodeInvalid {
    color: #e05161;
  }

  .providerTransferTo-popUp .popup .popup-body .specialPromo-box .specialCode-box {
    display: flex;
    align-items: center;
    width: 9.63rem;
    height: 1rem;
    padding: 0.03rem;
    border: 0.01rem solid #05955a;
    border-radius: 0.2rem;
  }

  .providerTransferTo-popUp .popup .popup-body .specialPromo-box .specialCode-box input {
    height: 100% !important;
    background: unset !important;
    padding: 0 0.5rem !important;
  }

  .providerTransferTo-popUp .popup .popup-body .promoCode-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.61rem;
  }

  .providerTransferTo-popUp .popup .popup-body .promoCode-box>span {
    width: 8.8rem;
    color: #05955a;
    margin-bottom: 0.08rem;
  }

  .providerTransferTo-popUp .popup .popup-body .promoCode-box .showPromo-btn {
    display: flex;
    align-items: center;
    width: 9.63rem;
    height: 1rem;
    padding: 0.03rem;
    border: 0.01rem solid #05955a;
    border-radius: 0.2rem;
  }

  .providerTransferTo-popUp .popup .popup-body .promoCode-box .showPromo-btn>div {
    width: 100%;
    height: 100%;
  }

  .providerTransferTo-popUp .popup .popup-body .promoCode-box .showPromo-btn>div .form-control-inner,
  .providerTransferTo-popUp .popup .popup-body .promoCode-box .showPromo-btn>div input {
    height: 100%;
    background: unset !important;
    padding: 0 0.5rem !important;
    min-height: unset;
  }

  .providerTransferTo-popUp .popup .popup-body .promoCode-box .showPromo-btn>div .form-control-inner .react-dropdown-select-content,
  .providerTransferTo-popUp .popup .popup-body .promoCode-box .showPromo-btn>div input .react-dropdown-select-content {
    display: flex;
    align-items: center;
    height: 100% !important;
  }

  .providerTransferTo-popUp .popup .popup-body .promoCode-box .showPromo-btn>div .form-control-inner .react-dropdown-select-content span,
  .providerTransferTo-popUp .popup .popup-body .promoCode-box .showPromo-btn>div input .react-dropdown-select-content span {
    width: 6.8rem;
    position: unset !important;
    padding-right: unset !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .providerTransferTo-popUp .popup .popup-body .btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.77rem;
  }

  .providerTransferTo-popUp .popup .popup-body .btn-box span,
  .providerTransferTo-popUp .popup .popup-body .btn-box button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.6rem;
    height: 0.98rem;
    font-weight: bold;
    color: #fff;
    border-radius: 0.2rem;
    text-transform: uppercase;
  }

  .providerTransferTo-popUp .popup .popup-body .btn-box span {
    background: #cdcdcd;
    margin-right: 0.42rem;
  }

  .providerTransferTo-popUp .popup .popup-body .btn-box button {
    font-size: 0.42rem;
    background: #08984b;
    border: none;
  }

  .providerTransferTo-popUp .popup .popup-body .deposit-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.38rem;
    color: #787878;
  }

  .providerTransferTo-popUp .popup .popup-body .deposit-text span {
    color: #08984b;
    margin-left: 0.13rem;
  }

  .promoCodeDetail-popup .popup .popup-body {
    border-radius: 0.2rem;
    padding: 0.7rem 0.65rem;
  }

  .promoCodeDetail-popup .popup .popup-body .body-header {
    margin-bottom: 0.56rem;
  }

  .promoCodeDetail-popup .popup .popup-body .body-header>span {
    font-size: 0.48rem;
  }

  .promoCodeDetail-popup .popup .popup-body .body-header>svg {
    width: 0.5rem;
    height: 0.5rem;
  }

  .promoCodeDetail-popup .popup .popup-body table {
    font-size: 0.42rem;
    border-radius: 0.2rem;
    margin-bottom: 0.77rem;
  }

  .promoCodeDetail-popup .popup .popup-body table tr td {
    padding: 0.2rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages .app-onlinetransfer>ul:first-child .deposit-amount .deposit-amount-input input {
    padding: 0 1rem 0 .52rem;
    width: 10.8rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages .app-onlinetransfer>ul:first-child .deposit-amount .deposit-amount-input .deposit-amount-clear-button {
    right: .2rem;
    width: .65rem;
  }

  #root .wap .wap .wap-pages-out .wap-pages .app-onlinetransfer>ul:first-child .deposit-amount .invalid-feedback {
    align-self: start;
  }
}

.reward-box {
  width: 3rem;
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 11;
}

.reward-box .reward-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.reward-box .reward-close {
  position: absolute;
  top: 0;
  right: 0.3rem;
  width: 0.3rem;
  height: 0.3rem;
  padding: 0.05rem;
  background: #000;
  border-radius: 50%;
  fill: #fff;
  cursor: pointer;
  z-index: 5;
}

.reward-popup {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.204919));
  animation: modalPopUp .3s;
}

@keyframes modalPopUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.reward-popup .popup-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 0;
  overflow: hidden;
}

.reward-popup .popup {
  margin: auto;
  padding: 0 !important;
  border-radius: 0.06rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 100%;
  max-width: 6rem;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.reward-popup .popup .reward-popupImg {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
}

.reward-popup .popup .reward-close {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  width: 0.3rem;
  height: 0.3rem;
  padding: 0.05rem;
  background: #000;
  border-radius: 50%;
  fill: #fff;
  cursor: pointer;
  z-index: 5;
}

.reward-popup .popup .popup-detail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: auto;
  width: 3.5rem;
  height: 3rem;
  transform: translate(0, 0.8rem);
  color: #fff;
  z-index: 3;
  text-align: center;
}

.reward-popup .popup .popup-detail .detail-title {
  width: 100%;
  font-size: 0.3rem;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.reward-popup .popup .popup-detail .detail-amount {
  width: 100%;
  font-size: 0.5rem;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.reward-popup .popup .popup-detail .detail-desc {
  width: 100%;
  font-size: 0.2rem;
  overflow-y: scroll;
  max-height: 1.5rem;
}

@media (max-width: 1025px) {
  .reward-box {
    width: 5rem;
  }

  .reward-box .reward-close {
    width: 0.5rem;
    height: 0.5rem;
  }

  .reward-popup .popup {
    max-width: 10rem;
  }

  .reward-popup .popup .reward-close {
    width: 0.5rem;
    height: 0.5rem;
  }

  .reward-popup .popup .popup-detail {
    width: 5.5rem;
    height: 4.8rem;
    transform: translate(0, 1.4rem);
  }

  .reward-popup .popup .popup-detail .detail-title {
    font-size: 0.6rem;
  }

  .reward-popup .popup .popup-detail .detail-amount {
    font-size: 0.8rem;
  }

  .reward-popup .popup .popup-detail .detail-desc {
    font-size: 0.5rem;
  }
}

@media (max-width: 768px) {
  .reward-box {
    bottom: 3rem;
  }

  .reward-box .reward-close {
    width: 0.5rem;
    height: 0.5rem;
  }

  .reward-popup .popup .reward-close {
    width: 0.8rem;
    height: 0.8rem;
  }
}

.telcode-select__control {
  height: 0.42rem;
  width: 1.05rem;
  min-height: unset !important;
  border: 0.01rem solid #cecece;
  border-radius: 0.02rem !important;
  margin-right: 0.05rem;
  color: #aaa;
  border-color: unset !important;
  box-shadow: unset !important;
  cursor: pointer !important;
}

.telcode-select__control .telcode-select__value-container {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.telcode-select__control .telcode-select__value-container .telcode-select__single-value {
  display: flex;
  align-items: center;
}

.telcode-select__control .telcode-select__value-container .telcode-select__single-value img {
  width: 0.3rem;
  margin-right: 0.05rem;
}

.telcode-select__control .telcode-select__indicators {
  width: 0.25rem;
}

.telcode-select__control .telcode-select__indicators .telcode-select__indicator-separator {
  display: none;
}

.telcode-select__control .telcode-select__indicators .telcode-select__dropdown-indicator {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.telcode-select__control .telcode-select__indicators .telcode-select__dropdown-indicator svg {
  width: 0.15rem;
  fill: #000;
}

.telcode-select__menu .telcode-select__menu-list .telcode-select__option {
  display: flex;
  align-items: center;
}

.telcode-select__menu .telcode-select__menu-list .telcode-select__option img {
  width: 0.3rem;
  margin-right: 0.05rem;
}

@media (max-width: 1025px) {
  .telcode-select__control {
    height: 0.8rem;
    width: 1.8rem;
  }

  .telcode-select__control .telcode-select__value-container .telcode-select__single-value img {
    width: 0.5rem;
    margin-right: 0.1rem;
  }

  .telcode-select__control .telcode-select__indicators {
    width: 0.35rem;
  }

  .telcode-select__control .telcode-select__indicators .telcode-select__dropdown-indicator svg {
    width: 0.3rem;
  }

  .telcode-select__menu .telcode-select__menu-list .telcode-select__option img {
    width: 0.5rem;
    margin-right: 0.1rem;
  }
}

@media (max-width: 768px) {
  .telcode-select__control {
    height: 1rem;
    width: 2rem;
  }

  .telcode-select__control .telcode-select__value-container .telcode-select__single-value img {
    width: 0.6rem;
    margin-right: 0.15rem;
  }

  .telcode-select__control .telcode-select__indicators {
    width: 0.4rem;
  }

  .telcode-select__control .telcode-select__indicators .telcode-select__dropdown-indicator svg {
    width: 0.35rem;
  }

  .telcode-select__menu .telcode-select__menu-list .telcode-select__option img {
    width: 0.6rem;
    margin-right: 0.15rem;
  }
}

.icon-menu {
  width: 5rem;
  height: auto;
  fill: #fff;
}

.icon-menu.on {
  fill: #c59b4e;
}

.icon-menu.off {
  fill: #39b54a;
}

.menu-home {
  max-width: 0.33rem;
}

.menu-918kiss {
  max-width: 0.46rem;
}

.menu-slots {
  max-width: 0.32rem;
}

.menu-casino,
.menu-sports,
.menu-lan-white {
  max-width: 0.28rem;
}

.menu-mega {
  max-width: 0.48rem;
}

.menu-vip {
  max-width: 0.3rem;
}

.menu-poker {
  max-width: 0.3rem;
}

.menu-favourite,
.menu-contact {
  max-width: 0.31rem;
}

.menu-promotion,
.menu-promotions {
  max-width: 0.36rem;
}

.menu-fishing,
.menu-e-sport {
  max-width: 0.37rem;
}

.menu-fourD {
  max-width: 0.38rem;
}

.menu-lottery,
.menu-games {
  max-width: 0.4rem;
}

.menu-raffle {
  max-width: 0.36rem;
}

.menu-download {
  max-width: 0.19rem;
}

.menu-logout {
  max-width: 0.21rem;
}

.menu-fastgames,
.menu-cockfight {
  max-width: 0.4rem;
}

.menu-settings {
  max-width: 0.21rem;
}

@media (max-width: 768px) {
  .footer-bottom .menu-home {
    max-width: 0.93rem;
  }

  .footer-bottom .menu-918kiss {
    max-width: 1.35rem;
  }

  .footer-bottom .menu-casino,
  .footer-bottom .menu-sports,
  .footer-bottom .menu-poker,
  .footer-bottom .menu-vip {
    max-width: 0.84rem;
  }

  .footer-bottom .menu-slots {
    max-width: 1.01rem;
  }

  .footer-bottom .menu-fishing,
  .footer-bottom .menu-e-sport,
  .footer-bottom .menu-lottery,
  .footer-bottom .menu-games {
    max-width: 1.04rem;
  }

  .footer-bottom .menu-favourite,
  .footer-bottom .menu-contact {
    max-width: 0.87rem;
  }

  .footer-bottom .menu-promotion,
  .footer-bottom .menu-promotions {
    max-width: 1.07rem;
  }

  .footer-bottom .menu-mega {
    max-width: 1.51rem;
  }

  .menu-918kiss {
    max-width: 0.48rem;
  }

  .menu-slots {
    max-width: 0.36rem;
  }

  .menu-casino,
  .menu-sports,
  .menu-lan-white {
    max-width: 0.3rem;
  }

  .menu-settings {
    max-width: 0.35rem;
  }

  .menu-olympic {
    max-width: 1rem;
  }

  .menu-promotion,
  .menu-promotions {
    max-width: 0.38rem;
  }

  .menu-favourite,
  .menu-contact {
    max-width: 0.31rem;
  }

  .menu-item-container .menu-fourD {
    max-width: 0.9rem;
  }

  .menu-item-container .menu-cockfight {
    max-width: 1.2rem;
  }

  .menu-item-container .menu-fastgames {
    max-width: 0.7rem;
  }
}

.banner-box p.text-banner-white,
.casino-content p.text-banner-white,
.scr-content p.text-banner-white,
.slots p.text-banner-white,
.fishing-content p.text-banner-white,
.poker-content p.text-banner-white,
.sports-content p.text-banner-white,
.mega888-content p.text-banner-white,
.esports-content p.text-banner-white,
.vip-content p.text-banner-white,
.raffledraw-content p.text-banner-white {
  margin-top: 0.15rem;
}

.banner-box p .green-content,
.casino-content p .green-content,
.scr-content p .green-content,
.slots p .green-content,
.fishing-content p .green-content,
.poker-content p .green-content,
.sports-content p .green-content,
.mega888-content p .green-content,
.esports-content p .green-content,
.vip-content p .green-content,
.raffledraw-content p .green-content {
  display: block;
  font-style: italic;
  width: 100%;
  max-width: unset;
  line-height: 0.63rem;
  font-size: 0.6rem;
  color: #39b549;
  font-weight: bold;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .banner-box p .green-content,
html:lang(vi) .casino-content p .green-content,
html:lang(vi) .scr-content p .green-content,
html:lang(vi) .slots p .green-content,
html:lang(vi) .fishing-content p .green-content,
html:lang(vi) .poker-content p .green-content,
html:lang(vi) .sports-content p .green-content,
html:lang(vi) .mega888-content p .green-content,
html:lang(vi) .esports-content p .green-content,
html:lang(vi) .vip-content p .green-content,
html:lang(vi) .raffledraw-content p .green-content {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.banner-box p .white-content,
.casino-content p .white-content,
.scr-content p .white-content,
.slots p .white-content,
.fishing-content p .white-content,
.poker-content p .white-content,
.sports-content p .white-content,
.mega888-content p .white-content,
.esports-content p .white-content,
.vip-content p .white-content,
.raffledraw-content p .white-content {
  font-family: "AvenirNextLTPro-Bold";
  font-size: 0.42rem;
  color: #fff;
  line-height: 0.5rem;
  display: block;
  text-transform: capitalize;
}

html:lang(vi) .banner-box p .white-content,
html:lang(vi) .casino-content p .white-content,
html:lang(vi) .scr-content p .white-content,
html:lang(vi) .slots p .white-content,
html:lang(vi) .fishing-content p .white-content,
html:lang(vi) .poker-content p .white-content,
html:lang(vi) .sports-content p .white-content,
html:lang(vi) .mega888-content p .white-content,
html:lang(vi) .esports-content p .white-content,
html:lang(vi) .vip-content p .white-content,
html:lang(vi) .raffledraw-content p .white-content {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.casino-content .provider-logo {
  position: relative !important;
  top: 3.57rem;
  left: 1.22rem;
  width: 100%;
  height: auto;
  max-width: 2.03rem;
}

.casino-content .provider-logo img {
  width: 100% !important;
  height: auto !important;
  position: unset !important;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.casino-content .habanero-logo {
  top: 3.2rem;
  left: 1.85rem;
  max-width: 2.46rem;
}

.casino-content .nextspin-logo {
  top: 3.46rem;
}

.slots .provider-logo,
.sports-content .provider-logo {
  position: relative !important;
  top: 3.57rem;
  left: 1.7rem;
  width: 100%;
  height: auto;
  max-width: 2.03rem;
}

.slots .provider-logo img,
.slots .provider-logo svg,
.sports-content .provider-logo img,
.sports-content .provider-logo svg {
  width: 100% !important;
  height: auto !important;
  position: unset !important;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.slots .habanero-logo,
.sports-content .habanero-logo {
  top: 3.2rem;
  left: 1.85rem;
  max-width: 2.46rem;
}

.slots .nextspin-logo,
.sports-content .nextspin-logo {
  top: 3.46rem;
}

@media (max-width: 1025px) {
  .banner-box p .green-content,
  .casino-content p .green-content,
  .scr-content p .green-content,
  .slots p .green-content,
  .fishing-content p .green-content,
  .poker-content p .green-content,
  .sports-content p .green-content,
  .mega888-content p .green-content,
  .esports-content p .green-content,
  .vip-content p .green-content,
  .raffledraw-content p .green-content {
    line-height: 0.68rem;
    font-size: 0.7rem;
  }

  .banner-box p .white-content,
  .casino-content p .white-content,
  .scr-content p .white-content,
  .slots p .white-content,
  .fishing-content p .white-content,
  .poker-content p .white-content,
  .sports-content p .white-content,
  .mega888-content p .white-content,
  .esports-content p .white-content,
  .vip-content p .white-content,
  .raffledraw-content p .white-content {
    font-size: 0.52rem;
    line-height: 0.5rem;
  }

  .banner-box p .white-content:last-child,
  .casino-content p .white-content:last-child,
  .scr-content p .white-content:last-child,
  .slots p .white-content:last-child,
  .fishing-content p .white-content:last-child,
  .poker-content p .white-content:last-child,
  .sports-content p .white-content:last-child,
  .mega888-content p .white-content:last-child,
  .esports-content p .white-content:last-child,
  .vip-content p .white-content:last-child,
  .raffledraw-content p .white-content:last-child {
    line-height: 0.6rem;
  }

  .casino-content .provider-logo {
    top: 4.3rem;
    left: 2.8rem;
    max-width: 3.03rem;
  }

  .slots .provider-logo,
  .sports-content .provider-logo {
    top: 4.3rem;
    left: 3.2rem;
    max-width: 3.03rem;
  }
}

@media (max-width: 768px) {
  .banner-box p .green-content,
  .casino-content p .green-content,
  .scr-content p .green-content,
  .slots p .green-content,
  .fishing-content p .green-content,
  .poker-content p .green-content,
  .sports-content p .green-content,
  .mega888-content p .green-content,
  .esports-content p .green-content,
  .vip-content p .green-content,
  .raffledraw-content p .green-content {
    line-height: 0.58rem;
    font-size: 0.6rem;
  }

  .banner-box p .white-content,
  .casino-content p .white-content,
  .scr-content p .white-content,
  .slots p .white-content,
  .fishing-content p .white-content,
  .poker-content p .white-content,
  .sports-content p .white-content,
  .mega888-content p .white-content,
  .esports-content p .white-content,
  .vip-content p .white-content,
  .raffledraw-content p .white-content {
    font-size: .3rem;
    line-height: .5rem;
    max-width: 3.5rem;
  }

  .banner-box p .white-content:last-child,
  .casino-content p .white-content:last-child,
  .scr-content p .white-content:last-child,
  .slots p .white-content:last-child,
  .fishing-content p .white-content:last-child,
  .poker-content p .white-content:last-child,
  .sports-content p .white-content:last-child,
  .mega888-content p .white-content:last-child,
  .esports-content p .white-content:last-child,
  .vip-content p .white-content:last-child,
  .raffledraw-content p .white-content:last-child {
    line-height: 0.6rem;
  }

  .casino-content .provider-logo {
    top: 3.8rem;
    left: 2.15rem;
    max-width: 2.5rem;
  }

  .slots .provider-logo,
  .sports-content .provider-logo {
    top: 3.5rem;
    left: 1.6rem;
    max-width: 2.5rem;
  }

  .raffledraw-content .text-banner-green .green-content:lang(ms) {
    font-size: .5rem;
    max-width: 6rem;
  }

  .raffledraw-content .text-banner-white .white-content {
    max-width: 4rem;
  }

  .raffledraw-content .text-banner-white .white-content:lang(ms) {
    font-size: .3rem;
    line-height: .5rem;
    max-width: 4.5rem;
  }

  .raffledraw-content .text-banner-content span:lang(en),
  .raffledraw-content .text-banner-content span:lang(ms) {
    display: block;
  }
}

.standard-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.7215686275);
  z-index: 9999;
  overflow-y: auto;
}

.standard-popup-overlay .standard-popup-modal-container {
  border-radius: 0.1rem;
  overflow: auto;
  color: #000;
  background: #fff;
  transform: translate(0, 0);
  margin: 1rem auto;
}

.standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #0a974b;
  color: #fff;
  padding: 0 0.32rem;
  border-top-left-radius: .1rem;
  border-top-right-radius: .1rem;
}

.standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header .standard-modal-title {
  font-size: 0.28rem;
  font-weight: 600;
  width: 90%;
  padding: 0.05rem 0;
}

.standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header .standard-modal-close {
  display: flex;
}

.standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header .standard-modal-close svg {
  border: 0.03rem solid #fff;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  cursor: pointer;
}

.standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-body {
  font-size: 0.18rem;
  background: #fff;
  padding: .25rem .35rem;
}

.standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-body .modal-desc {
  margin-bottom: 0.2rem;
}

.standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-body .standard-modal-title {
  font-size: 0.28rem;
  margin-bottom: 0.15rem;
}

.standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-footer {
  padding: 0.3rem 0.5rem;
  border: 0.01rem solid #f5f5f5;
  border-style: solid none none none;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

@media (max-width: 1025px) {
  .standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header {
    padding: 0.05rem 0.32rem;
  }

  .standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header .standard-modal-title {
    font-size: 0.38rem;
  }

  .standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header .standard-modal-close svg {
    width: 0.4rem;
    height: 0.4rem;
  }

  .standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-body {
    font-size: 0.3rem;
  }

  .standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-body .modal-desc {
    margin-bottom: 0.4rem;
  }

  .standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-body .standard-modal-title {
    font-size: 0.38rem;
    margin-bottom: 0.2rem;
  }

  .standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-footer {
    padding: 0.4rem 0.5rem;
  }
}

.m-standard-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.7215686275);
  z-index: 9999;
  overflow-y: auto;
}

.m-standard-popup-overlay .standard-popup-modal-container {
  border-radius: 0.2rem;
  font-size: .4rem;
  overflow: auto;
  color: #000;
  background: #fff;
  transform: translate(0, 0);
  margin: 1rem auto;
  width: 95%;
}

.m-standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #0a974b;
  color: #fff;
  padding: 0.35rem;
  border-top-left-radius: .1rem;
  border-top-right-radius: .1rem;
}

.m-standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header .standard-modal-title {
  font-size: .5rem;
  font-weight: 600;
  width: 90%;
  padding: .05rem 0;
  margin: auto;
}

.m-standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header .standard-modal-close {
  display: flex;
}

.m-standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-header .standard-modal-close svg {
  border: 0.03rem solid #fff;
  border-radius: 50%;
  width: 0.6rem;
  height: 0.6rem;
  cursor: pointer;
}

.m-standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-body {
  background: #fff;
  padding: .5rem .55rem;
}

.m-standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-body .modal-desc {
  margin-bottom: 0.6rem;
}

.m-standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-body .standard-modal-title {
  font-size: 0.5rem;
  margin-bottom: 0.15rem;
}

.m-standard-popup-overlay .standard-popup-modal-container .standard-popup-modal-footer {
  padding: 0.4rem 0.5rem;
  border: 0.05rem solid #f5f5f5;
  border-style: solid none none none;
}

.Toastify .Toastify__toast-container--top-center {
  top: 2em;
}

.Toastify .Toastify__toast {
  border-radius: 0.1rem;
  min-height: unset;
  margin-bottom: 0.1rem;
}

.Toastify .Toastify__toast-body {
  margin: 0;
}

.Toastify .Toastify__close-button {
  display: none;
}

.Toastify .Toastify__toast-container {
  z-index: 10000;
  min-width: 4rem;
}

.standard-notification-container {
  display: flex;
  color: #666;
}

.standard-notification-container .standard-notification-icon {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.standard-notification-container .standard-notification-icon svg {
  width: 75%;
}

.standard-notification-container .standard-notification-icon .success {
  fill: #32cd32;
}

.standard-notification-container .standard-notification-icon .error {
  fill: red;
}

.standard-notification-container .standard-notification-icon .warning {
  fill: coral;
}

.standard-notification-container .standard-notification-icon .info {
  fill: #4169e1;
}

.standard-notification-container .standard-notification-content {
  font-size: 0.14rem;
  display: flex;
  align-items: center;
}

.standard-notification-container .error {
  color: red !important;
}

@media (max-width: 768px) {
  .Toastify__toast {
    padding: 0.4rem !important;
    margin-bottom: 0.3rem !important;
  }

  .Toastify__toast:last-child {
    margin-bottom: calc(2rem + 5%) !important;
    margin-bottom: -webkit-calc(2rem + 5%) !important;
  }

  .Toastify__toast-container {
    z-index: 10000;
    width: 90vw !important;
    left: 5% !important;
  }

  .standard-notification-container .standard-notification-icon {
    width: 10%;
    margin-right: 0.15rem;
  }

  .standard-notification-container .standard-notification-icon svg {
    width: 85%;
  }

  .standard-notification-container .standard-notification-content {
    font-size: 0.4rem;
  }
}

.standard-button {
  border: 0;
  border-radius: 0.03rem;
  font-size: 0.16rem;
  padding: 0.1rem 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.standard-button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.standard-submit-button {
  background: #0a974b;
  color: #fff;
  font-weight: 700;
}

.standard-modal-submit-button,
.standard-modal-cancel-button {
  font-weight: 700;
  width: 2.6rem;
}

.standard-modal-submit-button {
  background: #0a974b;
  color: #fff;
}

.standard-modal-cancel-button {
  background: #cdcdcd;
  color: #fff;
}

.standard-st-upload-button {
  background: #959595;
  color: #fff;
}

.standard-st-submit-button {
  background: #0a974b;
  color: #fff;
  min-width: 1.5rem;
  height: 0.35rem;
  padding: 0;
}

.standard-st-submit-button:disabled {
  background: #959595;
  opacity: unset;
}

.standard-button-container {
  display: flex;
  justify-content: flex-end;
}

.standard-button-container .standard-button {
  margin-right: 0.2rem;
}

.standard-button-container .standard-button:last-child {
  margin-right: 0;
}

@media (max-width: 1025px) {
  .standard-button {
    font-size: 0.23rem;
    padding: 0.15rem 0.2rem;
  }

  .standard-modal-submit-button,
  .standard-modal-cancel-button {
    font-weight: 700;
    width: 4rem;
  }

  .standard-st-submit-button {
    width: 2.26rem;
    height: 0.54rem;
  }

  .standard-button-container .standard-button {
    margin-right: 0.3rem;
  }

  .standard-button-container .standard-button:last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .standard-button {
    font-size: 0.38rem;
    padding: 0.25rem;
    border-radius: 0.1rem;
  }

  .standard-modal-submit-button,
  .standard-modal-cancel-button {
    font-weight: 700;
    width: 5rem;
  }

  .standard-modal-cancel-button {
    background: #959595;
  }
}

.standard-form-field {
  position: relative;
  width: 100%;
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.standard-form-field .standard-input,
.standard-form-field .standard-select {
  font-size: 0.14rem;
  height: 0.4rem;
  border: 0.01rem solid #aaa;
  border-radius: 0.02rem;
  width: 100%;
  padding: 0 0.12rem;
  outline: none;
  color: #1c2c34;
  display: flex;
  align-items: center;
}

.standard-form-field .standard-input:disabled,
.standard-form-field .standard-input::placeholder,
.standard-form-field .standard-input:read-only,
.standard-form-field .standard-select:disabled,
.standard-form-field .standard-select::placeholder,
.standard-form-field .standard-select:read-only {
  color: #888;
}

.standard-form-field .standard-input.copy-input,
.standard-form-field .standard-select.copy-input {
  background: #19723c;
  color: #fff;
}

.standard-form-field .standard-select {
  position: relative;
  cursor: pointer;
}

.standard-form-field .standard-select.disabled {
  background: #eee;
  color: gray;
  opacity: 1;
  -webkit-text-fill-color: unset;
  cursor: not-allowed;
}

.standard-form-field .standard-select .standard-select-arrow {
  position: absolute;
  right: 0.1rem;
  border-width: 0 0.03rem 0.03rem 0;
  border-color: #1c2c34;
  border-style: solid;
  display: inline-block;
  width: 0.08rem;
  height: 0.08rem;
  transform: rotate(45deg);
  transition: all .4s;
}

.standard-form-field .standard-select .standard-select-arrow.active {
  transform: rotate(223deg);
}

.standard-form-field .standard-select .standard-select-dropdown {
  position: absolute;
  right: 0;
  top: 0.4rem;
  border: 0.01rem solid #ccc;
  background: #fff;
  box-shadow: 0 0 0.1rem 0 rgba(0,0,0,.2);
  z-index: 2;
  width: 100%;
  overflow-y: scroll;
  max-height: 1.5rem;
}

.standard-form-field .standard-select .standard-select-dropdown .standard-select-options {
  display: flex;
  align-items: center;
  padding: 0 0.12rem;
  color: #b3b3b3;
  height: 0.4rem;
}

.standard-form-field .standard-select .standard-select-dropdown .standard-select-options span {
  width: 100%;
}

.standard-form-field .standard-select .standard-select-dropdown .standard-select-options:hover,
.standard-form-field .standard-select .standard-select-dropdown .standard-select-options.active {
  color: #41b850;
  cursor: pointer;
}

.standard-form-field-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.standard-error-validation {
  color: red !important;
  font-size: 0.14rem;
  margin-top: 0.05rem;
}

@media (max-width: 1025px) {
  .standard-form-field {
    margin-bottom: 0.35rem;
  }

  .standard-form-field .standard-input,
  .standard-form-field .standard-select {
    height: 0.6rem;
    font-size: 0.24rem;
  }

  .standard-form-field .standard-select .standard-select-arrow {
    width: 0.15rem;
    height: 0.15rem;
    right: 0.3rem;
  }

  .standard-form-field .standard-select .standard-select-dropdown {
    top: 0.6rem;
    max-height: 3rem;
  }

  .standard-form-field .standard-select .standard-select-dropdown .standard-select-options {
    height: 0.6rem;
  }

  .standard-error-validation {
    font-size: 0.22rem;
    margin-top: 0.1rem;
  }
}

@media (max-width: 768px) {
  .standard-form-field {
    margin-bottom: 0.6rem;
  }

  .standard-form-field .standard-input,
  .standard-form-field .standard-select {
    color: #1c2c34;
    font-size: 0.45rem;
    height: 1rem;
    background: #f4f2f3;
    border: unset;
    padding: 0 0.3rem;
  }

  .standard-form-field .standard-select .standard-select-arrow {
    width: 0.2rem;
    height: 0.2rem;
    right: 0.3rem;
  }

  .standard-form-field .standard-select .standard-select-dropdown {
    top: 1rem;
    max-height: 3rem;
    padding: 0.05rem 0.3rem;
  }

  .standard-form-field .standard-select .standard-select-dropdown .standard-select-options {
    height: 1rem;
    padding: unset;
  }

  .standard-error-validation {
    font-size: 0.3rem;
  }
}

.standard-mandatory::after {
  color: #e0082d;
  content: "*";
  margin-left: .04rem;
  padding: 0 .04rem 0 0;
}

.standard-error {
  font-family: "Alexandria";
  font-weight: 400;
  font-display: swap;
  font-size: .14rem;
  color: red;
}

@media (max-width: 768px) {
  .standard-error {
    font-size: .4043rem;
  }
}

.sms-verify-popup-overlay .sms-verify-popup-container {
  max-width: 8rem;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body {
  padding: 0.8rem 0.5rem 0.5rem;
  color: #777575;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .standard-form-field {
  width: 80%;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .standard-button {
  text-transform: uppercase;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper {
  padding-bottom: 0.4rem;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field {
  justify-content: space-between;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .standard-input::-webkit-outer-spin-button,
.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .standard-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .standard-input[type=number] {
  -moz-appearance: textfield;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .telcode-input {
  width: 15%;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .phone-input {
  width: 83%;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .verify-phone-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .failed-container {
  text-align: center;
  margin-top: 0.4rem;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .failed-container .icon-wrapper .icon-notice-important {
  width: 0.8rem;
  height: 0.8rem;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .failed-container .contact-button-container {
  display: flex;
  justify-content: center;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .failed-container .contact-button-container .contact-button {
  text-transform: unset;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .validate-resend-otp-button-container {
  width: 70%;
  display: flex;
  margin-bottom: 0.4rem;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .validate-resend-otp-button-container .standard-button {
  margin-right: 0.2rem;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .validate-resend-otp-button-container .standard-button:last-child {
  margin-right: 0;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .otp-note {
  padding-bottom: 0.2rem;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.success-content-wrapper {
  padding-bottom: 0.4rem;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.success-content-wrapper .success-title {
  color: #0a974b;
  font-weight: 700;
}

.sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.success-content-wrapper strong {
  color: #0a974b;
}

@media (max-width: 1025px) {
  .sms-verify-popup-overlay .sms-verify-popup-container {
    max-width: 15rem;
  }

  .sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body {
    padding: 1rem 1.2rem 0.5rem;
  }

  .sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .verify-phone-button-container {
    padding-top: 1.6rem;
  }

  .sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .validate-resend-otp-button-container .standard-button {
    margin-right: 0.4rem;
  }

  .sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .validate-resend-otp-button-container .standard-button:last-child {
    margin-right: 0;
  }

  .sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .otp-note {
    padding-bottom: 0.8rem;
  }
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body {
  color: #777575;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .standard-button {
  text-transform: uppercase;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper {
  padding-bottom: 0.8rem;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field {
  justify-content: space-between;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .standard-input::-webkit-outer-spin-button,
.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .standard-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .standard-input[type=number] {
  -moz-appearance: textfield;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .telcode-input {
  width: 15%;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .telcode-phone-field .phone-input {
  width: 83%;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .verify-phone-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.6rem;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .failed-container {
  text-align: center;
  padding-top: 0.8rem;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .failed-container .icon-wrapper .icon-notice-important {
  width: 1rem;
  height: 1rem;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.input-phone-content-wrapper .failed-container .contact-button-container {
  display: flex;
  justify-content: center;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .validate-resend-otp-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .otp-note {
  padding-bottom: 0.8rem;
}

.m-sms-verify-popup-overlay .sms-verify-popup-container .sms-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .invalid-feedback {
  font-size: 0.3rem;
}

.hidden {
  display: none;
}

.border-none,
.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child .sort-item,
.sort-box li:last-child .sort-item,
.slots .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child .sort-item {
  border: none !important;
}

.icon-back {
  display: inline-block;
  background: url("../assets/back.png") no-repeat;
  width: 0.66rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-restore {
  display: inline-block;
  width: 0.28rem;
  height: 0.29rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-lighting {
  display: inline-block;
  background: url("../assets/lighting.png") no-repeat;
  width: 0.63rem;
  height: 0.53rem;
  background-size: 100% 100%;
}

.icon-logo {
  display: inline-block;
  max-width: 2.28rem;
  max-height: 0.8rem;
  width: 100%;
  height: auto;
}

.icon-logo-min {
  display: inline-block;
  background: url("../assets/logo.png") no-repeat;
  width: 0.36rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-close {
  display: inline-block;
  width: 0.31rem;
  height: 0.31rem;
  background-size: 100% 100%;
  cursor: pointer;
  fill: #fff;
}

.icon-close2 {
  display: inline-block;
  background: url("../assets/close2.png") no-repeat;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-pngo-banner {
  display: inline-block;
  background: url("../assets/pngo_banner.png") no-repeat;
  width: 1.87rem;
  height: 0.51rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-sg-logo {
  display: inline-block;
  background: url("../assets/sg_logo.png") no-repeat;
  width: 1.95rem;
  height: 0.33rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-chat {
  display: inline-block;
  background: url("../assets/chat.png") no-repeat;
  width: 0.37rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-chat-white {
  display: inline-block;
  background: url("../assets/chat_white.png") no-repeat;
  width: 0.37rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-lan {
  display: inline-block;
  background: url("../assets/language.png") no-repeat;
  width: 0.22rem;
  height: 0.23rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-lan-white {
  display: inline-block;
  background: url("../assets/language_white.png") no-repeat;
  width: 0.22rem;
  height: 0.23rem;
  background-size: 100% 100%;
}

.icon-flag {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.lang-choice>div:last-child .divide {
  display: none;
}

.icon-en {
  display: inline-block;
  background: url("../assets/malysia.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-thai {
  display: inline-block;
  background: url("../assets/thai.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-idr {
  display: inline-block;
  background: url("../assets/idr.png") no-repeat;
  width: 0.27rem;
  height: 0.16rem;
  background-size: 100% 100%;
}

.icon-game-menu {
  display: inline-block;
  width: 0.28rem;
  height: 0.16rem;
  background-size: 100% 100%;
  fill: #dabc6f;
}

.icon-raffle {
  display: inline-block;
  width: 0.45rem;
  height: 0.29rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-evo {
  display: inline-block;
  width: 0.8rem;
  height: 0.39rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-download {
  display: inline-block;
  width: 0.25rem;
  height: 0.36rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-contact {
  display: inline-block;
  width: 0.33rem;
  height: 0.33rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-arrow-down {
  display: inline-block;
  width: 0.09rem;
  height: 0.06rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-arrow-big {
  display: inline-block;
  background-image: url("../assets/arrow_big.svg");
  background-repeat: no-repeat;
  width: 0.53rem;
  height: 0.53rem;
  background-size: 100% 100%;
  pointer-events: auto;
  cursor: pointer;
  background-color: rgba(240,240,240,.9);
  border-radius: 50%;
  padding: 0.18rem;
}

.icon-arrow-big.left {
  transform: rotate(180deg);
}

.icon-love {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-love-on {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-love2 {
  display: inline-block;
  background: url("../assets/love.png") no-repeat;
  width: 0.38rem;
  height: 0.32rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-SG {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-PT,
.icon-PT3,
.icon-PT2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-YBL {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ybl.png") no-repeat;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-lucky {
  display: inline-block;
  background: url("../assets/lucky_bomber.png") no-repeat;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-TTG {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-SA {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-GP {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-BINL2,
.icon-BINS2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-PP,
.icon-PP-slot {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-AG,
.icon-AG2 {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-UG,
.icon-AES,
.icon-AES2 {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ug.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-EVO {
  display: inline-block;
  background: url("/public/html/images/home_img/096-evo.svg") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-EBET {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ebet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-MGP {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-JKR {
  display: inline-block;
  width: 0.4rem;
  height: 0.09rem;
  background-size: 100% 100%;
}

.icon-NS,
.icon-NS-slot {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-PGS,
.icon-FG,
.icon-MT,
.icon-YGR,
.icon-V8,
.icon-KM,
.icon-KM2 {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-PG {
  display: inline-block;
  width: 0.36rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-UL {
  display: inline-block;
  background: url("/public/html/images/home_img/096-ul.png") no-repeat;
  width: 0.42rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-SCR2 {
  display: inline-block;
  background: url("/public/html/images/home_img/096-scr.png") no-repeat;
  width: 0.4rem;
  height: 0.18rem;
  background-size: 100% 100%;
}

.icon-JL {
  display: inline-block;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-PSY8 {
  display: inline-block;
  width: 0.4rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-MEGA {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-HC {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-HB {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-NT {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-RT {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-CQ9 {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-JDB,
.icon-JDB2 {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-YGG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-AP {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-DS,
.icon-DS-slot,
.icon-EP,
.icon-SW,
.icon-R88,
.icon-R88-slot,
.icon-WESLT,
.icon-WESLT-slot {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-SPX {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-DRG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-RG {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-PS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-FC {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
}

.icon-SPB {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-T1G,
.icon-VP,
.icon-MTV {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-EXS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-FS {
  display: inline-block;
  width: 0.45rem;
  height: 0.45rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-hot {
  display: inline-block;
  background: url("../assets/hot.png") no-repeat;
  width: 0.67rem;
  height: 0.68rem;
  background-size: 100% 100%;
}

.icon-new {
  display: inline-block;
  background: url("../assets/new.png") no-repeat;
  width: 0.67rem;
  height: 0.68rem;
  background-size: 100% 100%;
}

.icon-new-green {
  display: inline-block;
  background: url("../assets/new_green.png") no-repeat;
  width: 0.79rem;
  height: 0.79rem;
  background-size: 100% 100%;
}

.icon-transfer-918kiss {
  display: inline-block;
  background: url("../assets/transfer/918kiss.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ae_sexy {
  display: inline-block;
  background: url("../assets/transfer/ae_sexy.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ag_gaming {
  display: inline-block;
  background: url("../assets/transfer/ag_gaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-allbet {
  display: inline-block;
  background: url("../assets/transfer/allbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-allbet2 {
  display: inline-block;
  background: url("../assets/transfer/allbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-betrader {
  display: inline-block;
  background: url("../assets/transfer/betrader.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bk8 {
  display: inline-block;
  background: url("../assets/transfer/bk8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-cmd368 {
  display: inline-block;
  background: url("../assets/transfer/cmd368.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-m8 {
  display: inline-block;
  background: url("../assets/transfer/m8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-kingmaker {
  display: inline-block;
  background: url("../assets/transfer/kingmaker.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-dreamgaming {
  display: inline-block;
  background: url("../assets/transfer/dreamgaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ebet {
  display: inline-block;
  background: url("../assets/transfer/ebet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-evo {
  display: inline-block;
  background: url("../assets/transfer/evo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gameplay {
  display: inline-block;
  background: url("../assets/transfer/gameplay.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gd {
  display: inline-block;
  background: url("../assets/transfer/gd.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jkr {
  display: inline-block;
  background: url("../assets/transfer/096-jkr-icon.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-hongchow {
  display: inline-block;
  background: url("../assets/transfer/hongchow.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-funkygames {
  display: inline-block;
  background: url("../assets/transfer/funkygames.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-minited {
  display: inline-block;
  background: url("../assets/transfer/minited.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pgsoft {
  display: inline-block;
  background: url("../assets/transfer/pgsoft.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-yesgetrich {
  display: inline-block;
  background: url("../assets/transfer/yesgetrich.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jili {
  display: inline-block;
  background: url("../assets/transfer/jili.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-gg_gaming {
  display: inline-block;
  background: url("../assets/transfer/gg_gaming.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-idn_poker {
  display: inline-block;
  background: url("../assets/transfer/idn_poker.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-im {
  display: inline-block;
  background: url("../assets/transfer/im.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bti {
  display: inline-block;
  background: url("../assets/transfer/bti.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-maxbet {
  display: inline-block;
  background: url("../assets/transfer/maxbet.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-drg {
  display: inline-block;
  background: url("../assets/transfer/drg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-w {
  display: inline-block;
  background: url("../assets/transfer/w.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-we {
  display: inline-block;
  background: url("../assets/transfer/we.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-we.icon-custom-transfer-Maxim88Casino {
  background: url("../assets/transfer/we_rebrand.png") no-repeat;
  background-size: 100% 100%;
}

.icon-transfer-we.icon-custom-transfer-Maxim88 {
  background: url("../assets/transfer/we_rebrand.png") no-repeat;
  background-size: 100% 100%;
}

.icon-transfer-mega888 {
  display: inline-block;
  background: url("../assets/transfer/mega888.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-mg {
  display: inline-block;
  background: url("../assets/transfer/mg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ybl {
  display: inline-block;
  background: url("../assets/transfer/ybl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-PP {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-PPL {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-psy8 {
  display: inline-block;
  background: url("../assets/transfer/psy8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ns {
  display: inline-block;
  background: url("../assets/transfer/nextspin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-nextspin {
  display: inline-block;
  background: url("../assets/transfer/nextspin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pngo {
  display: inline-block;
  background: url("../assets/transfer/pngo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pp {
  display: inline-block;
  background: url("../assets/transfer/pp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-pt_logo {
  display: inline-block;
  background: url("../assets/transfer/pt_logo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-qq {
  display: inline-block;
  background: url("../assets/transfer/qq.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-winwinlottery {
  display: inline-block;
  background: url("../assets/transfer/wwl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-shicai {
  display: inline-block;
  background: url("../assets/transfer/gw.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tfg {
  display: inline-block;
  background: url("../assets/transfer/tf.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-v8poker {
  display: inline-block;
  background: url("../assets/transfer/v8.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sa {
  display: inline-block;
  background: url("../assets/transfer/sa.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sbo {
  display: inline-block;
  background: url("../assets/transfer/sbo.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-hb {
  display: inline-block;
  background: url("../assets/transfer/hb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spade {
  display: inline-block;
  background: url("../assets/transfer/spade.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ttg {
  display: inline-block;
  background: url("../assets/transfer/ttg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ultimate {
  display: inline-block;
  background: url("../assets/transfer/ultimate.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-wm {
  display: inline-block;
  background: url("../assets/transfer/wm.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-rt {
  display: inline-block;
  background: url("../assets/transfer/rt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-nt {
  display: inline-block;
  background: url("../assets/transfer/nt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-cq9 {
  display: inline-block;
  background: url("../assets/transfer/cq9.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-fachai {
  display: inline-block;
  background: url("../assets/transfer/fc.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-granddragon {
  display: inline-block;
  background: url("../assets/transfer/gdl.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-digmaan {
  display: inline-block;
  background: url("../assets/transfer/dig.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tcg {
  display: inline-block;
  background: url("../assets/transfer/tcg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-tcga {
  display: inline-block;
  background: url("../assets/transfer/tcg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ws {
  display: inline-block;
  background: url("../assets/transfer/ws.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-binl2 {
  display: inline-block;
  background: url("../assets/transfer/bbin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bins2 {
  display: inline-block;
  background: url("../assets/transfer/bbin.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spb {
  display: inline-block;
  background: url("../assets/transfer/spb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-t1g {
  display: inline-block;
  background: url("../assets/transfer/t1g.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-mtv {
  display: inline-block;
  background: url("../assets/transfer/mtv.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-bg {
  display: inline-block;
  background: url("../assets/transfer/bg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jdb {
  display: inline-block;
  background: url("../assets/transfer/jdb.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-jdb2 {
  display: inline-block;
  background: url("../assets/transfer/jdb2.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ds {
  display: inline-block;
  background: url("../assets/transfer/ds.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ep {
  display: inline-block;
  background: url("../assets/transfer/ep.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-rg {
  display: inline-block;
  background: url("../assets/transfer/rg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ps {
  display: inline-block;
  background: url("../assets/transfer/ps.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ygg {
  display: inline-block;
  background: url("../assets/transfer/ygg.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-ap {
  display: inline-block;
  background: url("../assets/transfer/ap.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-spx {
  display: inline-block;
  background: url("../assets/transfer/spx.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-sw {
  display: inline-block;
  background: url("../assets/transfer/sw.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-r88 {
  display: inline-block;
  background: url("../assets/transfer/r88.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-weslt {
  display: inline-block;
  background: url("../assets/transfer/weslt.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-exs {
  display: inline-block;
  background: url("../assets/transfer/exs.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-vp {
  display: inline-block;
  background: url("../assets/transfer/vp.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-vc {
  display: inline-block;
  background: url("../assets/transfer/vc.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-transfer-fs {
  display: inline-block;
  background: url("../assets/transfer/fs.png") no-repeat;
  width: 0.36rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-box.bg-white {
  width: 0.3rem;
  height: 0.3rem;
  background: #fff;
  border-radius: 50%;
}

.icon-box>i {
  overflow: visible;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-logout {
  display: inline-block;
  background: url("../assets/menu/login.png") no-repeat;
  width: 0.28rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-home {
  display: inline-block;
  width: 0.33rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-918kiss {
  display: inline-block;
  background: url("../assets/menu/918kiss.png") no-repeat;
  width: 0.45rem;
  height: 0.24rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.icon-slots {
  display: inline-block;
  width: 0.3rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-casino {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-favourite {
  display: inline-block;
  width: 0.3rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.icon-fishing {
  display: inline-block;
  width: 0.36rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-poker {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-e-sport {
  display: inline-block;
  width: 0.36rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-promotion {
  display: inline-block;
  width: 0.36rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-sports {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-vip {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-meda {
  display: inline-block;
  background: url("../assets/meda.png") no-repeat;
  width: 0.32rem;
  height: 0.47rem;
  background-size: 100% 100%;
}

.icon-curaccao {
  display: inline-block;
  background: url("../assets/brand/curaccao-gray.svg") no-repeat;
  width: 1rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-curaccao:hover {
  background: url("../assets/brand/curaccao.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon-pagcor {
  display: inline-block;
  background: url("../assets/brand/pagcor-gray.png") no-repeat;
  width: 1.25rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-pagcor:hover {
  background: url("../assets/brand/pagcor.png") no-repeat;
  background-size: 100% 100%;
}

.icon-iovation {
  display: inline-block;
  background: url("../assets/brand/iovation-gray.png") no-repeat;
  width: 1.19rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-iovation:hover {
  background: url("../assets/brand/iovation.png") no-repeat;
  background-size: 100% 100%;
}

.icon-tm {
  display: inline-block;
  background: url("../assets/brand/threatmetrix-gray.svg") no-repeat;
  width: 1.19rem;
  height: 0.32rem;
  background-size: 100% 100%;
}

.icon-tm:hover {
  background: url("../assets/brand/threatmetrix.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-bmm {
  display: inline-block;
  background: url("../assets/brand/bmm-gray.png") no-repeat;
  width: 0.55rem;
  height: 0.17rem;
  background-size: 100% 100%;
}

.icon-bmm:hover {
  background: url("../assets/brand/bmm.png") no-repeat;
  background-size: 100% 100%;
}

.icon-itechlab {
  display: inline-block;
  background: url("../assets/brand/itechlab-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.39rem;
  background-size: 100% 100%;
}

.icon-itechlab:hover {
  background: url("../assets/brand/itechlab.png") no-repeat;
  background-size: 100% 100%;
}

.icon-global {
  display: inline-block;
  background: url("../assets/brand/global-gray.png") no-repeat;
  width: 0.48rem;
  height: 0.48rem;
  background-size: 100% 100%;
}

.icon-global:hover {
  background: url("../assets/brand/global.png") no-repeat;
  background-size: 100% 100%;
}

.icon-godaddy {
  display: inline-block;
  background: url("../assets/brand/godaddy-gray.png") no-repeat;
  width: 1.35rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-godaddy:hover {
  background: url("../assets/brand/godaddy.png") no-repeat;
  background-size: 100% 100%;
}

.icon-payouts {
  display: inline-block;
  background: url("../assets/brand/payouts-gray.png") no-repeat;
  width: 0.83rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-payouts:hover {
  background: url("../assets/brand/payouts.png") no-repeat;
  background-size: 100% 100%;
}

.icon-youtube {
  display: inline-block;
  background: url("../assets/brand/youtube-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-youtube:hover {
  background: url("../assets/brand/youtube.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-eeziepay {
  display: inline-block;
  background: url("../assets/brand/eeziepay-gray.png") no-repeat;
  width: 0.77rem;
  height: 0.32rem;
  background-size: 100% 100%;
  margin: 0 0.05rem;
}

.icon-eeziepay:hover {
  background: url("../assets/brand/eeziepay.png") no-repeat;
  background-size: 100% 100%;
}

.icon-paytrust {
  display: inline-block;
  background: url("../assets/brand/paytrust-gray.png") no-repeat;
  width: 0.9rem;
  height: 0.19rem;
  background-size: 100% 100%;
}

.icon-paytrust:hover {
  background: url("../assets/brand/paytrust.png") no-repeat;
  background-size: 100% 100%;
}

.icon-help2pay {
  display: inline-block;
  background: url("../assets/brand/help2pay-gray.png") no-repeat;
  width: 0.4rem;
  height: 0.3rem;
  background-size: 100% 100%;
  margin: 0 0.05rem;
}

.icon-help2pay:hover {
  background: url("../assets/brand/help2pay.png") no-repeat;
  background-size: 100% 100%;
}

.icon-bitcoin {
  display: inline-block;
  background: url("../assets/brand/bitcoin-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-bitcoin:hover {
  background: url("../assets/brand/bitcoin.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-usdt {
  display: inline-block;
  background: url("../assets/brand/usdt-gray.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-usdt:hover {
  background: url("../assets/brand/usdt.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-paynow {
  display: inline-block;
  background: url("../assets/brand/paynow-gray.svg") no-repeat;
  width: 0.86rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-paynow:hover {
  background: url("../assets/brand/paynow.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-paynow-v2 {
  display: inline-block;
  background: url("../assets/brand/paynow-gray-v2.svg") no-repeat;
  width: 0.6rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-paynow-v2:hover {
  background: url("../assets/brand/paynow-v2.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-eth {
  display: inline-block;
  background: url("../assets/brand/eth-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-eth:hover {
  background: url("../assets/brand/eth-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-duitnow {
  display: inline-block;
  background: url("../assets/brand/duitnow-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-duitnow:hover {
  background: url("../assets/brand/duitnow-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-instantpay {
  display: inline-block;
  background: url("../assets/brand/instantpay-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-instantpay:hover {
  background: url("../assets/brand/instantpay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-tng {
  display: inline-block;
  background: url("../assets/brand/tng-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-tng:hover {
  background: url("../assets/brand/tng-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-grab {
  display: inline-block;
  background: url("../assets/brand/grabpay-logo-of.svg") no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-grab:hover {
  background: url("../assets/brand/grabpay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-boost {
  display: inline-block;
  background: url("../assets/brand/boost-logo-of.svg") no-repeat;
  width: 0.85rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-boost:hover {
  background: url("../assets/brand/boost-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-shopee {
  display: inline-block;
  background: url("../assets/brand/shopee-logo-of.svg") no-repeat;
  width: 0.75rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-shopee:hover {
  background: url("../assets/brand/shopee-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-truepay {
  display: inline-block;
  background: url("../assets/brand/truepay-logo-of.svg") no-repeat;
  width: 1rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-truepay:hover {
  background: url("../assets/brand/truepay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-surepay {
  display: inline-block;
  background: url("../assets/brand/surepay-logo-of.svg") no-repeat;
  width: 1rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-surepay:hover {
  background: url("../assets/brand/surepay-logo-ov.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-notice {
  display: inline-block;
  width: 2.32rem;
  height: 0.2rem;
  background-size: 100% 100%;
  max-width: 0.55rem;
}

.icon-allbet {
  display: inline-block;
  background: url("../assets/casino/allbet.png") no-repeat;
  width: 1.01rem;
  height: 0.59rem;
  background-size: 100% 100%;
}

.icon-asiagaming {
  display: inline-block;
  background: url("../assets/casino/asiagaming.png") no-repeat;
  width: 1.22rem;
  height: 0.35rem;
  background-size: 100% 100%;
}

.icon-dreamgaming {
  display: inline-block;
  background: url("../assets/casino/dreamgaming.png") no-repeat;
  width: 0.96rem;
  height: 0.58rem;
  background-size: 100% 100%;
}

.icon-evolution {
  display: inline-block;
  background: url("../assets/casino/evolution.png") no-repeat;
  width: 1.12rem;
  height: 0.37rem;
  background-size: 100% 100%;
}

.icon-gameplay {
  display: inline-block;
  background: url("../assets/casino/gameplay.png") no-repeat;
  width: 1.46rem;
  height: 0.3rem;
  background-size: 100% 100%;
}

.icon-gd {
  display: inline-block;
  background: url("../assets/casino/gd.png") no-repeat;
  width: 1.08rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-microgaming {
  display: inline-block;
  background: url("../assets/casino/microgaming.png") no-repeat;
  width: 1.53rem;
  height: 0.38rem;
  background-size: 100% 100%;
}

.icon-playtech {
  display: inline-block;
  background: url("../assets/casino/playtech.png") no-repeat;
  width: 1.43rem;
  height: 0.31rem;
  background-size: 100% 100%;
}

.icon-sa-gaming {
  display: inline-block;
  background: url("../assets/casino/sa_gaming.png") no-repeat;
  width: 1.22rem;
  height: 0.46rem;
  background-size: 100% 100%;
}

.icon-sexy {
  display: inline-block;
  background: url("../assets/casino/sexy.png") no-repeat;
  width: 1.01rem;
  height: 0.51rem;
  background-size: 100% 100%;
}

.icon-username,
.icon-myprofile {
  display: inline-block;
  width: 0.2rem;
  height: 0.28rem;
  background-size: 100% 100%;
  z-index: 2;
}

.icon-lock {
  display: inline-block;
  width: 0.22rem;
  height: 0.27rem;
  background-size: 100% 100%;
  z-index: 2;
}

.icon-changepassword {
  display: inline-block;
  width: 0.26rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-changegamepassword {
  display: inline-block;
  width: 0.26rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-bankdetail {
  display: inline-block;
  width: 0.35rem;
  height: 0.32rem;
  background-size: 100% 100%;
}

.icon-referral {
  display: inline-block;
  width: 0.35rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-search {
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  background-size: 100% 100%;
}

.icon-arrow-down2 {
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  background-size: 100% 100%;
  fill: #000;
}

.icon-messaging {
  display: inline-block;
  width: 0.28rem;
  height: 0.21rem;
  background-size: 100% 100%;
}

.icon-reload {
  display: inline-block;
  width: 0.25rem;
  height: 0.27rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-reload.start {
  animation: rotation 1s linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.icon-reload2 {
  display: inline-block;
  background: url("../assets/reload2.png") no-repeat;
  width: 0.25rem;
  height: 0.27rem;
  background-size: 100% 100%;
}

.icon-reload2.start {
  animation: rotation 1s linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.icon-fund {
  display: inline-block;
  width: 0.27rem;
  height: 0.27rem;
  background-size: 100% 100%;
  fill: #3db54a;
}

.icon-fund2 {
  display: inline-block;
  background: url("../assets/fund_icon2.png") no-repeat;
  background-size: 100% 100%;
}

.icon-deposit {
  display: inline-block;
  width: 0.3rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.icon-report {
  display: inline-block;
  width: 0.26rem;
  height: 0.25rem;
  background-size: 100% 100%;
}

.icon-transfer {
  display: inline-block;
  width: 0.35rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-withdraw {
  display: inline-block;
  width: 0.29rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-start {
  display: inline-block;
  background: url("../assets/start.png") no-repeat;
  width: 0.3rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-start2 {
  display: inline-block;
  background: url("../assets/start2.png") no-repeat;
  width: 0.17rem;
  height: 0.15rem;
  background-size: 100% 100%;
}

.icon-on {
  display: inline-block;
  background: url("../assets/on.png") no-repeat;
  width: 0.6rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-off {
  display: inline-block;
  background: url("../assets/off.png") no-repeat;
  width: 0.6rem;
  height: 0.22rem;
  background-size: 100% 100%;
}

.icon-sapphire {
  display: inline-block;
  width: 0.63rem;
  height: 0.43rem;
  object-fit: contain;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-ruby {
  display: inline-block;
  background: url("../assets/ruby.png") no-repeat;
  width: 0.71rem;
  height: 0.56rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-diamond {
  display: inline-block;
  background: url("../assets/diamond.png") no-repeat;
  width: 0.71rem;
  height: 0.56rem;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.icon-transfer-btn {
  display: inline-block;
  background: url("../assets/transfer_btn.png") no-repeat;
  width: 0.2rem;
  height: 0.17rem;
  background-size: 100% 100%;
}

.icon-transfer-to {
  display: inline-block;
  background: url("../assets/transfer_to.png") no-repeat;
  width: 0.17rem;
  height: 0.11rem;
  background-size: 100% 100%;
}

.icon-checked {
  display: inline-block;
  background: url("../assets/checked.png") no-repeat;
  width: 0.24rem;
  height: 0.24rem;
  background-size: 100% 100%;
}

.icon-tip {
  display: inline-block;
  background: url("../assets/tip.png") no-repeat;
  width: 0.18rem;
  height: 0.18rem;
  background-size: 100% 100%;
}

.icon-notice-important {
  display: inline-block;
  background: url("../assets/important_notice.svg") no-repeat;
  width: 0.29rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-affin {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-affin-gray.png") no-repeat;
  width: 0.5rem;
  height: 0.28rem;
  background-size: 100% 100%;
}

.icon-affin:hover,
.icon-affin.on {
  background: url("/public/html/images/bank_img/096-affin.png") no-repeat;
  background-size: 100% 100%;
}

.icon-alliance {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-alliance-gray.png") no-repeat;
  width: 0.43rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-alliance:hover,
.icon-alliance.on {
  background: url("/public/html/images/bank_img/096-alliance.png") no-repeat;
  background-size: 100% 100%;
}

.icon-bsn {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-bsn-gray.png") no-repeat;
  width: 0.36rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-bsn:hover,
.icon-bsn.on {
  background: url("/public/html/images/bank_img/096-bsn.png") no-repeat;
  background-size: 100% 100%;
}

.icon-cimb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-cimb-gray.png") no-repeat;
  width: 0.34rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-cimb:hover,
.icon-cimb.on {
  background: url("/public/html/images/bank_img/096-cimb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-citi {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-citi-gray.png") no-repeat;
  width: 0.36rem;
  height: 0.36rem;
  background-size: 100% 100%;
}

.icon-citi:hover,
.icon-citi.on {
  background: url("/public/html/images/bank_img/096-citi.png") no-repeat;
  background-size: 100% 100%;
}

.icon-hlb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-hlb-gray.png") no-repeat;
  width: 0.28rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-hlb:hover,
.icon-hlb.on {
  background: url("/public/html/images/bank_img/096-hlb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-islam {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-islam-gray.png") no-repeat;
  width: 0.45rem;
  height: 0.26rem;
  background-size: 100% 100%;
}

.icon-islam:hover,
.icon-islam.on {
  background: url("/public/html/images/bank_img/096-islam.png") no-repeat;
  background-size: 100% 100%;
}

.icon-mbb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-mbb-gray.png") no-repeat;
  width: 0.33rem;
  height: 0.34rem;
  background-size: 100% 100%;
}

.icon-mbb:hover,
.icon-mbb.on {
  background: url("/public/html/images/bank_img/096-mbb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-pbe {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-pbe-gray.png") no-repeat;
  width: 0.26rem;
  height: 0.33rem;
  background-size: 100% 100%;
}

.icon-pbe:hover,
.icon-pbe.on {
  background: url("/public/html/images/bank_img/096-pbe.png") no-repeat;
  background-size: 100% 100%;
}

.icon-rhb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-rhb-gray.png") no-repeat;
  width: 0.57rem;
  height: 0.29rem;
  background-size: 100% 100%;
}

.icon-rhb:hover,
.icon-rhb.on {
  background: url("/public/html/images/bank_img/096-rhb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-amb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-amb-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-amb:hover,
.icon-amb.on {
  background: url("/public/html/images/bank_img/096-amb.png") no-repeat;
  background-size: 100% 100%;
}

.icon-uob {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-uob-gray.png") no-repeat;
  width: 0.41rem;
  height: 0.44rem;
  background-size: 100% 100%;
}

.icon-uob:hover,
.icon-uob.on {
  background: url("/public/html/images/bank_img/096-uob.png") no-repeat;
  background-size: 100% 100%;
}

.icon-rakyat {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-br-gray.png") no-repeat;
  width: 0.39rem;
  height: 0.42rem;
  background-size: 100% 100%;
}

.icon-rakyat:hover,
.icon-rakyat.on {
  background: url("/public/html/images/bank_img/096-br.png") no-repeat;
  background-size: 100% 100%;
}

.icon-ocbc {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-ocbc-gray.png") no-repeat;
  width: 0.37rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.icon-ocbc:hover,
.icon-ocbc.on {
  background: url("/public/html/images/bank_img/096-ocbc.png") no-repeat;
  background-size: 100% 100%;
}

.icon-hsbc {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-hsbc-gray.png") no-repeat;
  width: 0.47rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-hsbc:hover,
.icon-hsbc.on {
  background: url("/public/html/images/bank_img/096-hsbc.png") no-repeat;
  background-size: 100% 100%;
}

.icon-scb {
  display: inline-block;
  background: url("/public/html/images/bank_img/096-scb-gray.png") no-repeat;
  width: 0.47rem;
  height: 0.5rem;
  background-size: 100% 100%;
}

.icon-scb:hover,
.icon-scb.on {
  background: url("/public/html/images/bank_img/096-scb.png") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-home {
  background: url("/public/html/images/home_img/home.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-casino {
  background: url("/public/html/images/home_img/casino.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-918kiss {
  background: url("/public/html/images/home_img/918kiss.svg") no-repeat;
  background-size: 100% 100%;
  -webkit-backface-visibility: unset;
          backface-visibility: unset;
}

.footer-bottom .icon-slots {
  background: url("/public/html/images/home_img/slots.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-sports {
  background: url("/public/html/images/home_img/sports.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-e-sport {
  background: url("/public/html/images/home_img/e-sport.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-fishing {
  background: url("/public/html/images/home_img/fishing.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-poker {
  background: url("/public/html/images/home_img/poker.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-favourite {
  background: url("/public/html/images/home_img/favourite.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-promotion {
  background: url("/public/html/images/home_img/promo.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-vip {
  background: url("/public/html/images/home_img/vip.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-raffle {
  background: url("/public/html/images/home_img/raffle.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-download {
  background: url("/public/html/images/home_img/download.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-contact {
  background: url("/public/html/images/home_img/contact.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-lan-white {
  background: url("/public/html/images/home_img/language.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .icon-logout {
  background: url("/public/html/images/home_img/logout.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-home {
  background: url("/public/html/images/home_img/home-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-casino {
  background: url("/public/html/images/home_img/casino-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-918kiss {
  background: url("/public/html/images/home_img/918kiss-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-slots {
  background: url("/public/html/images/home_img/slots-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-sports {
  background: url("/public/html/images/home_img/sports-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-e-sport {
  background: url("/public/html/images/home_img/e-sport-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-fishing {
  background: url("/public/html/images/home_img/fishing-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-poker {
  background: url("/public/html/images/home_img/poker-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-favourite {
  background: url("/public/html/images/home_img/favourite-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-promotion {
  background: url("/public/html/images/home_img/promo-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-vip {
  background: url("/public/html/images/home_img/vip-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-raffle {
  background: url("/public/html/images/home_img/raffle-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-download {
  background: url("/public/html/images/home_img/download-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-contact {
  background: url("/public/html/images/home_img/contact-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-lan-white {
  background: url("/public/html/images/home_img/language-on.svg") no-repeat;
  background-size: 100% 100%;
}

.footer-bottom .current .icon-logout {
  background: url("/public/html/images/home_img/logout-on.svg") no-repeat;
  background-size: 100% 100%;
}

.icon-wap-menu {
  cursor: pointer;
  display: inline-block;
  width: 0.69rem;
  height: 0.46rem;
  background-size: 100% 100%;
  fill: #dabc6f;
}

.icon-wap-back {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/back.png") no-repeat;
  width: 0.6rem;
  height: 0.42rem;
  background-size: 100% 100%;
}

.icon-wap-eye {
  cursor: pointer;
  display: inline-block;
  width: 0.85rem;
  height: 0.58rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-wap-eye.off {
  background-size: 100% 100%;
  fill: #02532b;
}

.icon-wap-auto {
  border: none;
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/on.png") no-repeat;
  width: 1.54rem;
  height: 0.52rem;
  background-size: 100% 100%;
}

.icon-wap-auto.off {
  background: url("../assets/wap/off.png") no-repeat;
  background-size: 100% 100%;
}

.icon-wap-chat {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/chat.png") no-repeat;
  width: 0.72rem;
  height: 0.72rem;
  background-size: 100% 100%;
}

.icon-wap-message {
  cursor: pointer;
  display: inline-block;
  background-size: 100% 100%;
}

.icon-wap-message svg {
  width: 0.75rem;
  height: 0.58rem;
  fill: #fff;
}

.icon-wap-messaging {
  cursor: pointer;
  display: inline-block;
  width: 0.75rem;
  height: 0.58rem;
  background-size: 100% 100%;
}

.icon-wap-messaging.on {
  background-size: 100% 100%;
}

.icon-wap-profile {
  cursor: pointer;
  display: inline-block;
  width: 0.65rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-profile.on {
  background-size: 100% 100%;
}

.icon-wap-changepassword {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-changepassword.on {
  background-size: 100% 100%;
}

.icon-wap-changegamepassword {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-changegamepassword.on {
  background-size: 100% 100%;
}

.icon-wap-referral {
  cursor: pointer;
  display: inline-block;
  width: 1.1rem;
  height: 0.7rem;
  background-size: 100% 100%;
}

.icon-wap-referral.on {
  background-size: 100% 100%;
}

.icon-wap-bankdetail {
  cursor: pointer;
  display: inline-block;
  width: 0.95rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-bankdetail.on {
  background-size: 100% 100%;
}

.icon-wap-deposit {
  cursor: pointer;
  display: inline-block;
  width: 0.97rem;
  height: 1.08rem;
  background-size: 100% 100%;
}

.icon-wap-transfer {
  cursor: pointer;
  display: inline-block;
  width: 1.14rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-withdraw {
  cursor: pointer;
  display: inline-block;
  width: 0.95rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-report {
  cursor: pointer;
  display: inline-block;
  width: 0.84rem;
  height: 0.82rem;
  background-size: 100% 100%;
}

.icon-wap-home {
  cursor: pointer;
  display: inline-block;
  width: 1.01rem;
  height: 0.84rem;
  background-size: 100% 100%;
}

.icon-wap-funds {
  cursor: pointer;
  display: inline-block;
  width: 0.84rem;
  height: 0.76rem;
  background-size: 100% 100%;
}

.icon-wap-promos {
  cursor: pointer;
  display: inline-block;
  width: 1.21rem;
  height: 0.83rem;
  background-size: 100% 100%;
}

.icon-wap-live_chat {
  cursor: pointer;
  display: inline-block;
  width: 0.77rem;
  height: 0.77rem;
  background-size: 100% 100%;
}

.icon-wap-my_account {
  cursor: pointer;
  display: inline-block;
  width: 0.55rem;
  height: auto;
  background-size: 100% 100%;
}

.icon-wap-transfer_btn {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/wap/transfer_btn.png") no-repeat;
  width: 0.53rem;
  height: 0.57rem;
  background-size: 100% 100%;
  border: none;
}

.icon-wap-transfer_btn:disabled,
.icon-wap-transfer_btn[disabled] {
  opacity: .3;
  cursor: not-allowed;
}

.icon-wap-reload {
  cursor: pointer;
  display: inline-block;
  width: 0.59rem;
  height: 0.65rem;
  background-size: 100% 100%;
  fill: #fff;
}

.icon-wap-arrow {
  cursor: pointer;
  display: inline-block;
  width: 0.37rem;
  height: 0.23rem;
  background-size: 100% 100%;
}

.font-gray {
  color: #19723c;
}

.bg-white {
  background: #fff;
}

.bg-header-t {
  background: green;
}

.bg-header {
  background: green;
}

.border-white {
  border-color: #fff;
}

.border-primary {
  border-color: solid 0.01rem green;
}

.btn-normal,
.btn-normal-green,
.btn-long {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 0.35rem;
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #222;
  font-size: 0.16rem;
  border-radius: 0.02rem;
  cursor: pointer;
  font-family: "Roboto-Bold";
}

html:lang(vi) .btn-normal,
html:lang(vi) .btn-normal-green,
html:lang(vi) .btn-long {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: uppercase;
}

.btn-normal:disabled,
.btn-normal-green:disabled,
.btn-long:disabled {
  filter: grayscale(0.6);
  cursor: not-allowed;
}

.login-button,
.reset-password-button {
  border: none;
}

.btn .btn-ok {
  text-transform: uppercase;
}

.btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
  filter: grayscale(0.7);
  color: rgba(0,0,0,.431372549);
}

.btn-normal-green {
  background: #15743c;
  color: #fff;
  white-space: nowrap;
}

.btn-skew {
  display: inline-block;
  width: 2.28rem;
  height: 0.48rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  font-size: 0.2rem;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #c59b4e 0%, #d2af62 57%, #dec276 100%);
}

.btn-skew>* {
  transform: skew(25deg);
}

.btn-long {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.5rem;
  white-space: nowrap;
}

.text-red {
  color: #e1415d;
  font-size: 0.11rem;
}

.text-yellow,
.header-top .user-info,
.slide-menu li {
  color: #dec276;
  font-size: 0.12rem;
}

.btn-swiper-point,
.bg-content-section .content-promotions .slick-dots li,
.slider>ul.tab li,
.content-section .content-recommended .slick-dots li {
  display: inline-block;
  width: 0.32rem;
  height: 0.08rem;
  transform: skew(-25deg);
  transform-origin: 0 100%;
  background: #e2dcde;
  margin-left: 0.07rem;
  cursor: pointer;
}

.btn-swiper-point.on,
.bg-content-section .content-promotions .slick-dots li.on,
.slider>ul.tab li.on,
.content-section .content-recommended .slick-dots li.on {
  background: #39b549;
  cursor: default;
}

.bg-gray {
  background: #f5f5f5;
}

.bg-black {
  background: #222;
}

.bg-header-top {
  background: #13242c;
}

.bg-header-top .content-container {
  width: 100%;
  max-width: 20rem;
  padding: 0 0.25rem;
}

.bg-header-bottom {
  background: linear-gradient(to right, #fff 0%, #fff 17%, #19723c 17%, #19723c 100%);
  z-index: 3;
  position: relative;
  height: 0.74rem;
}

.bg-header-bottom .content-container {
  width: 100%;
  max-width: 20rem;
  padding: 0 0.25rem;
}

.bg-footer-bottom {
  background: #ebebeb;
}

.swiper-arrow {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.26rem;
}

.text-banner-green {
  font-size: 0.6rem;
  color: #39b549;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .text-banner-green {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.text-banner-white {
  font-size: 0.42rem;
  color: #fff;
  font-family: "AvenirNextLTPro-Bold";
}

html:lang(vi) .text-banner-white {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

.text-banner-white-small {
  font-family: OpenSans;
  font-style: italic;
  font-size: 0.24rem;
  color: #fff;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.page-enter {
  position: absolute;
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms,transform 300ms;
}

.page-exit {
  position: absolute;
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms,transform 300ms;
}

.page-slide-enter {
  position: absolute;
  right: -100%;
}

.page-slide-enter-active {
  right: 0;
  transition: right 300ms;
}

.page-slide-exit {
  position: absolute;
  right: 0;
}

.page-slide-exit-active {
  right: 100%;
  transition: right 300ms;
}

.slide-right-enter {
  right: -100%;
}

.slide-right-enter-active {
  right: 0;
  transition: right 300ms;
}

.slide-right-exit {
  right: 0;
}

.slide-right-exit-active {
  right: -100%;
  transition: right 300ms;
}

.spread-enter {
  transform-origin: top;
  transform: translateZ(0) scaleY(0);
  opacity: 0;
}

.spread-enter-active {
  opacity: 1;
  transform: translateZ(0) scaleY(1);
  transition: all 300ms;
}

.spread-exit {
  opacity: 1;
  transform: translateZ(0) scaleY(1);
  transform-origin: top;
}

.spread-exit-active {
  opacity: 0;
  transform: translateZ(0) scaleY(0);
  transition: all 300ms;
}

.rotate180-enter {
  transform: rotate(0deg);
}

.rotate180-enter-active {
  transform: rotate(180deg);
  transition: all 300ms;
}

.rotate180-enter-done {
  transform: rotate(180deg);
}

.rotate180-exit {
  transform: rotate(180deg);
}

.rotate180-exit-active {
  transform: rotate(360deg);
  transition: all 300ms;
}

.drop-right-enter {
  transform: translateY(-100%);
}

.drop-right-enter-active {
  transform: translateY(0);
  transition: all .3s;
}

.drop-right-exit {
  transform: translateY(0);
}

.drop-right-exit-active {
  transform: translateY(-100%);
  transition: all .3s;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms,transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms,transform 300ms;
}

.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
}

.icon-message {
  display: inline-block;
  width: 0.28rem;
  height: 0.21rem;
  background-size: 100% 100%;
  fill: #fff;
}

.provider-logo {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.icon-copy {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.new-icon,
.hot-icon,
.promo-icon {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 0.79rem;
  border: none !important;
  outline: none !important;
  outline-offset: unset !important;
  z-index: 2;
}

.tnc-apply {
  color: #0a974b;
  font-size: 0.16rem;
  font-family: "AvenirNextLTPro-Bold";
  border-bottom: 0.01rem solid;
}

html:lang(vi) .tnc-apply {
  font-family: "Alexandria";
  font-weight: 700;
  text-transform: capitalize;
}

@media (min-width: 1700px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 16%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2100px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 17%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2200px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 18%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2300px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 19%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2400px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 20.5%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2500px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 22%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2650px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 23%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 2750px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 25%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3000px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 26%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3200px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 27%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3300px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 29%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 3600px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 31%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 4000px) {
  .bg-header-bottom {
    background: linear-gradient(to right, #fff 0%, #fff 33%, #19723c 5%, #19723c 100%);
  }
}

@media (min-width: 1441px) {
  .menu-animation-enter {
    transform-origin: top;
    transform: translateZ(0) translateX(-50%) scaleY(0);
    opacity: 0;
  }

  .menu-animation-enter-active {
    opacity: 1;
    transform: translateZ(0) translateX(-50%) scaleY(1);
    transition: all 300ms;
  }

  .menu-animation-exit {
    opacity: 1;
    transform: translateZ(0) translateX(-50%) scaleY(1);
    transform-origin: top;
  }

  .menu-animation-exit-active {
    opacity: 0;
    transform: translateZ(0) translateX(-50%) scaleY(0);
    transition: all 300ms;
  }
}

@media (min-width: 1025px)and (max-width: 1440px) {
  .menu-animation-enter {
    transform-origin: top;
    transform: translateZ(0) scaleY(0);
    opacity: 0;
  }

  .menu-animation-enter-active {
    opacity: 1;
    transform: translateZ(0) scaleY(1);
    transition: all 300ms;
  }

  .menu-animation-exit {
    opacity: 1;
    transform: translateZ(0) scaleY(1);
    transform-origin: top;
  }

  .menu-animation-exit-active {
    opacity: 0;
    transform: translateZ(0) scaleY(0);
    transition: all 300ms;
  }
}

@media (max-width: 1025px) {
  .bg-header-top .content-container {
    max-width: unset;
  }

  .bg-header-bottom {
    height: 100%;
  }

  .bg-header-bottom .content-container {
    max-width: unset;
  }

  .text-red {
    font-size: 0.2rem;
  }

  .new-icon,
  .hot-icon,
  .promo-icon {
    max-width: 1.2rem;
  }

  .tnc-apply {
    font-size: 0.3rem;
  }

  .provider-logo {
    left: 1.8rem;
    bottom: 0.8rem;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .menu-animation-enter {
    right: -4.22rem;
  }

  .menu-animation-enter-active {
    right: 0;
    transition: right 300ms;
  }

  .menu-animation-exit {
    right: 0;
  }

  .menu-animation-exit-active {
    right: -4.22rem;
    transition: right 300ms;
  }

  .icon-lan,
  .icon-lan-white {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-en,
  .icon-thai,
  .icon-idr {
    width: 0.48rem;
    height: 0.26rem;
  }

  .icon-flag {
    width: 0.48rem;
    height: 0.48rem;
  }

  .icon-logo {
    max-width: 4.66rem;
    max-height: 1.5rem;
  }

  .icon-reload {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-fund {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-game-menu {
    width: 0.48rem;
    height: 0.3rem;
  }

  .icon-raffle {
    width: 0.72rem;
    height: 0.5rem;
  }

  .icon-evo {
    width: 0.72rem;
    height: 0.8rem;
  }

  .icon-download {
    width: 0.4rem;
    height: 0.58rem;
  }

  .icon-contact {
    width: 0.53rem;
    height: 0.53rem;
  }

  .icon-notice {
    width: 1.21rem;
    height: 0.44rem;
    max-width: unset;
  }

  .icon-pagcor {
    width: 1.87rem;
    height: 0.42rem;
  }

  .icon-iovation {
    width: 1.785rem;
    height: 0.36rem;
  }

  .icon-bmm {
    width: 0.825rem;
    height: 0.255rem;
  }

  .icon-itechlab {
    width: 0.615rem;
    height: 0.585rem;
  }

  .icon-global {
    width: 0.72rem;
    height: 0.72rem;
  }

  .icon-godaddy {
    width: 2.025rem;
    height: 0.39rem;
  }

  .icon-payouts {
    width: 1.245rem;
    height: 0.435rem;
  }

  .icon-youtube {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-eeziepay {
    width: 1.155rem;
    height: 0.48rem;
  }

  .icon-paytrust {
    width: 1.35rem;
    height: 0.285rem;
  }

  .icon-help2pay {
    width: 0.555rem;
    height: 0.39rem;
  }

  .icon-hot {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-new {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-arrow-big {
    width: 0.6rem;
    height: 0.6rem;
  }

  .icon-meda {
    width: 0.44rem;
    height: 0.66rem;
  }

  .icon-message {
    width: 0.42rem;
    height: 0.32rem;
  }

  .icon-search {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-on,
  .icon-off {
    width: 0.9rem;
    height: 0.3rem;
  }

  .icon-sapphire,
  .icon-ruby,
  .icon-diamond {
    width: 1.06rem;
    height: 0.84rem;
  }

  .icon-notice-important {
    width: 0.44rem;
    height: 0.39rem;
  }

  .icon-tip {
    width: 0.27rem;
    height: 0.27rem;
  }

  .icon-transfer-btn {
    width: 0.3rem;
    height: 0.26rem;
  }

  .icon-checked {
    width: 0.36rem;
    height: 0.36rem;
  }

  .icon-transfer-to {
    width: 0.27rem;
    height: 0.17rem;
  }

  .icon-love2 {
    width: 0.45rem;
    height: 0.38rem;
  }

  .icon-username {
    width: 0.3rem;
    height: 0.46rem;
  }

  .icon-lock {
    width: 0.44rem;
    height: 0.54rem;
  }

  .btn-swiper-point,
  .bg-content-section .content-promotions .slick-dots li,
  .slider>ul.tab li,
  .content-section .content-recommended .slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .text-banner-green {
    font-size: 1.22rem;
  }

  .text-banner-white {
    font-size: 0.56rem;
    line-height: 0.8rem;
  }

  .text-banner-white-small {
    font-size: 0.3rem;
  }

  .btn-skew {
    width: 2.18rem;
    height: 0.58rem;
    font-size: 0.3rem;
  }

  .btn-normal,
  .btn-normal-green,
  .btn-long {
    width: 2.26rem;
    height: 0.54rem;
    font-size: 0.23rem;
  }

  .btn-long {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 0.3rem;
  }
}

@media (max-width: 768px) {
  .bg-header-bottom .content-container {
    padding: unset;
  }

  .icon-copy {
    width: 0.63rem;
    height: 0.7rem;
  }

  .icon-arrow-down {
    fill: #10572c;
  }

  .icon-home {
    height: 0.3rem;
  }

  .icon-casino,
  .icon-poker,
  .icon-vip,
  .icon-slots,
  .icon-sports,
  .icon-favourite {
    width: 0.32rem;
    height: 0.32rem;
  }

  .icon-e-sport,
  .icon-promotion {
    width: 0.39rem;
    height: 0.27rem;
  }

  .icon-fishing {
    width: 0.39rem;
    height: 0.42rem;
  }

  .icon-918kiss {
    width: 0.42rem;
    height: 0.7rem;
  }

  .daily-limit {
    margin-top: 0.2rem;
    color: #41b850;
    font-size: 0.35rem;
  }

  .daily-limit>span>span:first-child {
    margin-left: 0.1rem;
  }

  .provider-logo {
    left: 0.3rem;
    bottom: 0.5rem;
  }

  .swiper-arrow {
    display: none;
  }

  .menu-animation-enter {
    right: -4.14rem;
  }

  .menu-animation-enter-active {
    right: 0;
    transition: right 300ms;
  }

  .menu-animation-exit {
    right: 0;
  }

  .menu-animation-exit-active {
    right: -4.14rem;
    transition: right 300ms;
  }

  .icon-lan,
  .icon-lan-white {
    width: 0.33rem;
    height: 0.35rem;
  }

  .icon-reload {
    width: 0.38rem;
    height: 0.38rem;
  }

  .icon-fund {
    width: 0.35rem;
    height: 0.35rem;
    fill: #10572c;
  }

  .icon-restore {
    fill: #10572c;
  }

  .icon-en,
  .icon-thai,
  .icon-idr {
    width: 0.41rem;
    height: 0.26rem;
  }

  .icon-flag {
    width: 0.41rem;
    height: 0.41rem;
  }

  .icon-logo {
    max-width: 3.5rem;
    max-height: 1rem;
    margin-right: 0;
    margin-left: 0.3rem;
  }

  .completed-main .icon-logo {
    max-width: 5rem;
    max-height: 3.85rem;
    margin-right: 0;
  }

  .icon-game-menu {
    width: 0.67rem;
    height: 0.43rem;
  }

  .icon-raffle {
    width: 0.72rem;
    height: 0.5rem;
  }

  .icon-download {
    width: 0.4rem;
    height: 0.58rem;
  }

  .icon-contact {
    width: 0.53rem;
    height: 0.53rem;
  }

  .icon-notice {
    width: 1.5rem;
    height: 0.49rem;
    max-width: unset;
  }

  .icon-curaccao {
    width: 2rem;
    height: 0.9rem;
  }

  .icon-pagcor {
    width: 1.87rem;
    height: 0.42rem;
  }

  .icon-iovation {
    width: 1.785rem;
    height: 0.36rem;
  }

  .icon-tm {
    width: 2.43rem;
    height: 0.8rem;
  }

  .icon-bmm {
    width: 0.825rem;
    height: 0.255rem;
  }

  .icon-itechlab {
    width: 0.615rem;
    height: 0.585rem;
  }

  .icon-global {
    width: 0.72rem;
    height: 0.72rem;
  }

  .icon-godaddy {
    width: 2.025rem;
    height: 0.39rem;
  }

  .icon-payouts {
    width: 1.245rem;
    height: 0.435rem;
  }

  .icon-youtube {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-eeziepay {
    width: 1.4rem;
    height: 0.6rem;
    margin: 0 0.1rem;
  }

  .icon-paytrust {
    width: 1.35rem;
    height: 0.285rem;
  }

  .icon-help2pay {
    width: 0.9rem;
    height: 0.6rem;
    margin: 0 0.1rem;
  }

  .icon-bitcoin {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-usdt {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-paynow {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-paynow-v2 {
    width: 1rem;
    height: 0.9rem;
  }

  .icon-eth {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-duitnow {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-instantpay {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-tng {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-grab {
    width: 0.9rem;
    height: 0.9rem;
  }

  .icon-boost {
    width: 1.65rem;
    height: 0.9rem;
  }

  .icon-shopee {
    width: 1.5rem;
    height: 0.9rem;
  }

  .icon-truepay {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-surepay {
    width: 1.8rem;
    height: 0.9rem;
  }

  .icon-hot {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-new {
    width: 1.18rem;
    height: 1.18rem;
  }

  .icon-arrow-big {
    width: 0.8rem;
    height: 0.8rem;
  }

  .icon-meda {
    width: 0.48rem;
    height: 0.71rem;
  }

  .icon-message {
    width: 0.42rem;
    height: 0.32rem;
  }

  .icon-search {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-on,
  .icon-off {
    width: 0.9rem;
    height: 0.3rem;
  }

  .icon-sapphire,
  .icon-ruby,
  .icon-diamond {
    width: 1.82rem;
    height: 1.19rem;
  }

  .icon-notice-important {
    width: 0.44rem;
    height: 0.39rem;
  }

  .icon-tip {
    width: 0.3rem;
    height: 0.3rem;
  }

  .icon-transfer-btn {
    width: 0.3rem;
    height: 0.26rem;
  }

  .icon-checked {
    width: 0.36rem;
    height: 0.36rem;
  }

  .icon-transfer-to {
    width: 0.27rem;
    height: 0.17rem;
  }

  .icon-love2 {
    width: 0.37rem;
    height: 0.31rem;
  }

  .icon-username {
    width: 0.4rem;
    height: 0.56rem;
  }

  .icon-lock {
    width: 0.54rem;
    height: 0.64rem;
  }

  .btn-swiper-point,
  .bg-content-section .content-promotions .slick-dots li,
  .slider>ul.tab li,
  .content-section .content-recommended .slick-dots li {
    width: 0.56rem;
    height: 0.12rem;
    margin-left: 0.1rem;
  }

  .text-banner-green {
    font-size: 0.7rem;
  }

  .text-banner-white {
    font-size: 0.4rem;
    line-height: 0.6rem;
  }

  .text-banner-white-small {
    font-size: 0.24rem;
  }

  .btn-skew {
    width: 2.2rem;
    height: 0.46rem;
    font-size: 0.24rem;
  }

  .btn-normal,
  .btn-normal-green,
  .btn-long {
    width: 3.05rem;
    height: 0.54rem;
    font-size: 0.38rem;
    border: none;
  }

  .btn-long {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 0.76rem;
  }

  .new-icon,
  .hot-icon,
  .promo-icon {
    max-width: 1.5rem;
  }
}

#root .notification-slider-list-container {
  position: fixed;
  width: 3.2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 99999;
}

#root .notification-slider-list-container .floating-banner-container {
  position: unset;
  padding: 0.2rem;
}

#root .notification-slider-list-container .floating-banner-container .floating-banner-img img {
  width: 100%;
}

#root .floating-banner-container {
  width: 3rem;
  position: fixed;
  animation-duration: 2s;
  z-index: 99;
}

#root .floating-banner-container .floating-banner-img {
  width: 100%;
  position: relative;
}

#root .floating-banner-container .floating-banner-img .floating-banner-close-btn {
  position: absolute;
  right: -0.15rem;
  top: -0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  font-size: 0.16rem;
  border: unset;
  cursor: pointer;
}

#root .floating-banner-container .floating-banner-img .floating-banner-close-btn svg {
  width: 100%;
}

#root .floating-banner-container .floating-banner-img .floating-banner-close-btn svg .st0 {
  fill: #19723c;
}

#root .floating-banner-container .floating-banner-img .floating-banner-close-btn svg .st1 {
  fill: #fff;
}

#root .floating-banner-container .floating-banner-img img {
  width: 100%;
  display: block;
}

#root .floating-banner-container .floating-banner-content .floating-banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  font-family: "AvenirNextLTPro";
  font-size: 0.14rem;
  color: #000;
  text-align: left;
  padding: 0.2rem;
}

#root .floating-banner-container .floating-banner-content div {
  width: 100%;
}

#root .floating-banner-container .floating-banner-content div p {
  margin: 0.1rem 0;
}

#root .floating-banner-container .floating-banner-content.withoutImg {
  margin: 0 auto;
  padding: 0 0.15rem 0.1rem;
}

#root .floating-banner-container .floating-banner-content .floating-banner-button {
  background: #fff;
  padding: 0.2rem;
}

#root .floating-banner-container .floating-banner-content .floating-banner-button button {
  width: 100%;
  font-size: 0.16rem;
  font-weight: bold;
  font-family: "AvenirNextLTPro-Bold";
  background: linear-gradient(to bottom, #dec276, #c59b4e);
  color: #000;
  padding: 0.03rem 0;
  border: unset;
  border-radius: 0.03rem;
  cursor: pointer;
  height: 0.34rem;
}

@media (max-width: 768px) {
  #root .floating-banner-container .floating-banner-content .floating-banner-button button {
    width: 100%;
    font-size: .42rem;
    padding: .03rem 0;
    border-radius: .1rem;
    height: .8rem;
  }
}

#root .floating-banner-container-left {
  left: 0;
}

#root .floating-banner-container-right {
  right: 0;
}

#root .floating-banner-container-bottom {
  bottom: 10%;
}

@media (max-width: 768px) {
  #root .notification-slider-list-container {
    width: 7.4rem;
  }

  #root .notification-slider-list-container .floating-banner-container {
    padding: 0.5rem;
  }

  #root .floating-banner-container {
    width: 6.9rem;
  }

  #root .floating-banner-container .floating-banner-img .floating-banner-close-btn {
    width: 0.9rem;
    height: 0.9rem;
    font-size: 0.3rem;
    top: -0.3rem;
    right: -0.3rem;
  }

  #root .floating-banner-container .floating-banner-img .floating-banner-close-btn svg {
    width: 100%;
    height: 100%;
  }

  #root .floating-banner-container .floating-banner-content {
    height: 4.72rem;
    padding: 0.5rem;
    background: #fff;
  }

  #root .floating-banner-container .floating-banner-content p {
    margin: 0.2rem 0;
    font-size: 0.26rem;
  }

  #root .floating-banner-container .floating-banner-content button {
    width: 100%;
    font-size: 0.42rem;
    padding: 0.03rem 0;
    border-radius: 0.1rem;
    height: 0.8rem;
  }

  #root .floating-banner-container-left {
    left: 0;
  }

  #root .floating-banner-container-right {
    right: 0;
  }
}

.cockfight-main-content .cockfight-content .provider-logo {
  left: .7rem;
  top: 3.4rem;
  width: 100%;
  max-width: 1.5rem;
}

.cockfight-main-content .cockfight-content .provider-logo img {
  position: relative !important;
  width: 100% !important;
}

@media (max-width: 768px) {
  .cockfight-main-content .cockfight-content .provider-logo {
    left: .3rem;
    bottom: .5rem;
  }

  .back-box div {
    width: 6rem;
  }
}

.slot-logo1 {
  background-image: url(/public/html/images/slot_img/096-participate-pg.png);
  width: 1.9rem;
  height: 0.51rem;
  bottom: 0.61rem;
  left: 0.65rem;
}

.fastgames .slots-container {
  margin-top: 0.55rem;
}

.fastgames .slots-container .content-recommended .content-top {
  padding-bottom: 0.35rem;
}

.fastgames .slots-container .content-recommended .slick-list {
  max-height: 2.03rem;
}

.fastgames .slots-container .content-recommended .slot-special-cap {
  display: block;
  padding: 0 1.09rem;
}

.fastgames .slots-container .content-recommended .slick-prev-btn {
  left: -0.6rem;
}

.fastgames .slots-container .content-recommended .slick-next-btn {
  right: -0.5rem;
}

.fastgames .slots-container .content-recommended .slick-dots {
  right: -0.9rem;
}

.fastgames .content-section .slots-content-section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fastgames .content-section .slots-content-section>li {
  width: 100%;
}

.fastgames .content-section .slots-content-section>li:not(:last-child) {
  margin-bottom: 0.2rem;
}

.fastgames .content-section .slots-content-section>li .content-center {
  position: relative;
}

.fastgames .content-section .slots-content-section>li.content-sort-search {
  margin-top: 0.35rem;
  margin-bottom: 0.7rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box {
  width: 14.14rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 0.88rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li {
  height: 100%;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item {
  width: 2.02rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.16rem;
  color: #858585;
  border-right: 0.01rem solid #cfcfcf;
  position: relative;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item.active>div:nth-child(1) {
  background: #1a713f;
  color: #fff;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item.active .sort-select {
  background: #e1dee2;
  color: #858585;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div {
  transition: background .3s,color .3s;
  padding-left: 0.25rem;
  padding-right: 0.3rem;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread {
  position: absolute;
  left: 0;
  top: 0.88rem;
  width: 100%;
  height: 2.5rem;
  background: #e1dee2;
  z-index: 1;
  overflow: auto;
  padding: 0.2rem 0;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread li {
  height: 0.27rem;
  padding-left: 0.25rem;
  color: #222;
  transition: color .3s;
  white-space: nowrap;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread li:hover {
  color: #216e43;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li:last-child {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content {
  width: 14.14rem;
  margin: 0 auto;
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-between;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list {
  margin-right: 0.2rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li {
  width: 1.84rem;
  height: 0.5rem;
  display: flex;
  align-items: center;
  color: #858585;
  cursor: pointer !important;
  transition: background .5s,color .5s;
  position: relative;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li:not(:last-child) {
  margin-bottom: 0.08rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li.on {
  cursor: default !important;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li.on,
.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li:hover {
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #000;
  cursor: default;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-name {
  font-size: 0.16rem;
  padding-left: 0.14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-box {
  min-width: 0.5rem;
  height: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f2322;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .maintenance-hover-box {
  background: url(/public/html/images/icon/maintenance-en.svg);
  width: 1.84rem;
  height: 0.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(245,245,245,.8);
  position: absolute;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 998;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.204919));
  animation: modalPopUp .3s;
  cursor: default;
}

@keyframes modalPopUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 !important;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  max-width: 8rem;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box p {
  font-size: 0.16rem;
  margin-bottom: 0.3rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-header {
  padding: 0.28rem 0.27rem 0.05rem;
  width: 100%;
  display: flex;
  align-items: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-header .box-header-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #39b549;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-header .box-header-right svg {
  width: 0.35rem;
  height: 0.35rem;
  cursor: pointer;
  border: 0.02rem solid gray;
  border-radius: 50%;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body {
  width: 100%;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-icon {
  margin: auto;
  width: 10%;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-title {
  display: flex;
  justify-content: center;
  border-bottom: 0.01rem solid rgba(0,0,0,.1294117647);
  padding: 0.3rem;
  margin: 0 0.6rem;
  text-align: center;
  color: #0a974b;
  line-height: 0.29rem;
  font-weight: 700;
  font-size: 0.24rem;
  font-style: normal;
  font-family: "AvenirNextLTPro";
  white-space: nowrap;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details {
  margin: 0.3rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container {
  margin: 0 0.8rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .dateColon {
  text-align: center;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 {
  text-align: left;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 .key {
  width: 100%;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.26rem;
  font-family: "AvenirNextLTPro";
  color: #424242;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 {
  text-align: right;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime {
  width: 100%;
  margin-left: 0.2rem;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.26rem;
  font-family: "AvenirNextLTPro";
  color: #0a974b;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime:lang(vi) {
  font-variant: tabular-nums;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime.dateTimeNotice {
  position: absolute;
  margin-top: 0.5rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .slashColor {
  color: #0a974b;
  margin-left: 0.2rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container {
  display: flex;
  flex-flow: column;
  margin: 0.6rem 2rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  justify-items: center;
  grid-gap: 0.3rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row1 {
  width: 100%;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row2 {
  padding: 0.2rem 0;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer {
  background: #f0f0f0;
  border-radius: 0.05rem;
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #424242;
  font-weight: 700;
  font-style: normal;
  font-size: 0.24rem;
  line-height: 0.26rem;
  text-align: center;
  font-family: "AvenirNextLTPro";
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer-status {
  color: #424242;
  font-weight: 700;
  font-style: normal;
  font-size: 0.16rem;
  line-height: 0.26rem;
  text-align: center;
  font-family: "AvenirNextLTPro";
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list .maintenancePopup-Box .box-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 0;
  overflow: hidden;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game {
  width: 12.2rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li {
  margin-bottom: 0.17rem;
  background: #dad8d8;
  border: 0.02rem solid #dad8d8;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>img {
  max-width: 2.26rem;
  max-height: 1.48rem;
  min-height: unset;
  margin: 0 auto;
  display: block;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p {
  height: 0.47rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(5n)) {
  margin-right: 0.16rem;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box {
  transition: transform .5s;
  transform: translate(-50%, -50%) scale(0);
  position: absolute;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:hover .hover-box {
  transform: translate(-50%, -50%) scale(1);
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game .item-enter {
  opacity: 0;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game .item-enter-active {
  opacity: 1;
  transition: all 300ms;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game .item-exit {
  opacity: 1;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game .item-exit-active {
  opacity: 0;
  transition: all 300ms;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .loading-container {
  width: 12.2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .nav-game .items {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.01rem;
}

.fastgames .content-promotions {
  margin-top: 0.3rem;
}

.fastgames .brand-list .badge::before {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.04rem;
  font-size: 0.1rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.fastgames .brand-list .badge-hot::before {
  content: "hot";
  background-color: #ff3d00;
}

.fastgames .brand-list .badge-new::before {
  content: "new";
  background-color: #19733d;
}

@media (max-width: 1445px) {
  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .loading-container {
    width: 75.5vw;
  }
}

@media (min-width: 768px)and (max-width: 1025px) {
  .slots .slots-container .content-recommended .slick-list {
    max-height: 3.24rem;
  }

  .slots .banner-box .banner ul.sli:nth-child(1) .slide-text-box {
    top: 1rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search {
    margin-top: 0.7rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box {
    width: 19rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 1.36rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li {
    height: 100%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item {
    width: 3rem;
    font-size: 0.24rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div {
    padding-left: 0.3rem;
    padding-right: 0.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread {
    top: 1.36rem;
    width: 100%;
    height: 3.7rem;
    padding: 0.2rem 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread li {
    height: 0.5rem;
    padding-left: 0.36rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content {
    width: 19rem;
    margin-top: 0.46rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li {
    width: 3rem;
    height: 0.72rem;
    font-size: 0.24rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-name {
    padding-left: 0.34rem;
    font-size: 0.24rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-box {
    min-width: 0.72rem;
    height: 0.72rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-box .icon-box>i {
    transform: scale(1.5);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box {
    width: 15.8rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .loading-container {
    width: 77.2vw;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game {
    width: 16rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li {
    width: 3.7rem;
    height: 3.18rem;
    margin: 0 0 0.24rem;
    border: 0.04rem solid #dad8d8;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(5n)) {
    margin-right: 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(4n)) {
    margin-right: 0.2rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>img {
    max-width: unset;
    max-height: 2.48rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p {
    height: 0.73rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .icon-love-on {
    transform: scale(1.5);
  }

  .slots .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .gamename {
    left: 0.6rem;
    width: 2.9rem;
    font-size: 0.2rem;
  }
}

@media (max-width: 768px) {
  #root .slots.logined .bg-footer-bottom {
    top: 7.27rem;
  }

  .fastgames .slots-container {
    margin-top: 0;
  }

  .fastgames .content-section>ul {
    margin-top: 0;
  }

  .fastgames .content-section .slots-content-section>li.content-recommended {
    display: none;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search {
    margin-top: 0;
    overflow: hidden;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .web {
    display: none !important;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile {
    display: block !important;
    position: relative;
    transition: min-height .5s;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile.page-games {
    min-height: 22.5rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile>div {
    width: 100%;
    left: 0;
    top: 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile>div.fade-enter-done {
    position: relative;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-PT,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-PT2,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-SG,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-TTG,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-GP,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-BINL2,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-BINS2,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-SA,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-MGP,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-AG,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-AG2 {
    width: 1.4rem;
    height: 1.4rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-HB {
    width: 1.6rem;
    height: 1.6rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-MEGA,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-JL,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-PGS,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-FG,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-MT,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-YGR,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-V8,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-KM {
    width: 2rem;
    height: 2rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-PSY8 {
    width: 2rem;
    height: 1.05rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-PP,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-PP-slot {
    width: 1.6rem;
    height: 1.6rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-NS,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-NS-slot {
    width: 1.5rem;
    height: 1.5rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-UL {
    width: 1.93rem;
    height: 1.29rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-SCR2 {
    width: 1.88rem;
    height: 0.83rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-JKR {
    width: 1.88rem;
    height: 0.43rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-PG {
    width: 1.67rem;
    height: 1.2rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-NT {
    width: 1.6rem;
    height: 1.6rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-NT .st1 {
    fill: #000 !important;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-RT {
    width: 1.6rem;
    height: 1.6rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-CQ9 {
    width: 2rem;
    height: 2rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-FC {
    width: 1.5rem;
    height: 1.5rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-SPB,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-T1G,
  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-MTV {
    width: 1.3rem;
    height: 1.3rem;
    fill: #000;
    margin: 0 -0.1rem 0 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-RG {
    width: 1.8rem;
    height: 2rem;
    fill: #000;
    margin: 0 0.1rem 0 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-PS {
    width: 1.8rem;
    height: 2rem;
    fill: #000;
    margin: 0 0.1rem 0 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-PS .st15 {
    fill: #000 !important;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-JDB {
    width: 1.29rem;
    height: 1rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .icon-RG {
    width: 1.7rem;
    height: 2rem;
    fill: #000;
    margin: 0 0.1rem 0 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .content-title {
    display: block;
    width: 11.6rem;
    margin: 0 auto;
    padding-left: 0.24rem;
    height: 1rem;
    line-height: 1rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0.7rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li {
    cursor: pointer;
    width: 3.16rem;
    height: 2.6rem;
    transform: skew(-25deg);
    overflow: hidden;
    margin: 0 0.27rem;
    margin-bottom: 0.7rem;
    position: relative;
    background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 20%, rgb(253, 253, 253) 24%, rgb(249, 249, 249) 75%, rgb(249, 249, 249) 100%);
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li .logo-box {
    transform: skew(25deg) translate(-0.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.12rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li .logo-box .icon-box .icon-HC {
    width: 3rem;
    height: 3rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li .logo-name {
    position: absolute;
    width: 120%;
    display: block;
    transform: skew(25deg) translateX(-50%);
    height: 0.48rem;
    background: #dec276;
    font-size: 0.29rem;
    text-align: center;
    line-height: 0.48rem;
    left: 50%;
    bottom: 0;
    font-weight: bold;
    color: #222;
    font-family: "AvenirNextLTPro";
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul li .maintenance-hover-box {
    background: url(/public/html/images/icon/m-maintenance-en.svg);
    width: 155%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(245,245,245,.8509803922);
    position: absolute;
    margin-top: -2rem;
    margin: -2.1rem 0 0 -1.1rem;
    transform: skew(25deg);
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box {
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 998;
    width: 100%;
    height: 100%;
    overflow: auto;
    outline: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.204919));
    animation: modalPopUp .3s;
    cursor: default;
  }

@keyframes modalPopUp {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
}

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding: 0 !important;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    max-width: 8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box p {
    font-size: 0.16rem;
    margin-bottom: 0.3rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-header {
    padding: 0.28rem 0.27rem 0.05rem;
    width: 100%;
    display: flex;
    align-items: center;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-header .box-header-right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #39b549;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-header .box-header-right svg {
    width: 0.35rem;
    height: 0.35rem;
    cursor: pointer;
    border: 0.02rem solid gray;
    border-radius: 50%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body {
    width: 100%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-icon {
    margin: auto;
    width: 15%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-title {
    display: flex;
    justify-content: center;
    border-bottom: 0.01rem solid #eaeaea;
    padding: 0.3rem;
    margin: 0 0.6rem;
    color: #0a974b;
    line-height: 0.18rem;
    font-weight: 700;
    font-size: 0.5rem;
    font-style: normal;
    font-family: "AvenirNextLTPro";
    white-space: nowrap;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details {
    margin: 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container {
    margin: 0.5rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container {
    display: grid;
    grid-template-columns: 48% 4% 48%;
    align-items: center;
    width: 100%;
    white-space: nowrap;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .dateColon {
    text-align: center;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 {
    text-align: left;
    width: 30%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 .key {
    width: 100%;
    font-size: 0.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.26rem;
    font-family: "AvenirNextLTPro";
    color: #424242;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 {
    text-align: right;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime {
    width: 100%;
    margin-left: 0.2rem;
    font-size: 0.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.26rem;
    font-family: "AvenirNextLTPro";
    color: #0a974b;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime:lang(vi) {
    font-variant: tabular-nums;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .dateTime.dateTimeNotice {
    position: absolute;
    margin-top: 0.5rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 .slashColor {
    color: #0a974b;
    margin-left: 0.2rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container {
    display: flex;
    flex-flow: column;
    margin: 0.6rem 2rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    grid-gap: 0.3rem;
    padding: 0.05rem 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row1 {
    width: 100%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .row2 {
    padding: 0.2rem 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer {
    background: #f0f0f0;
    border-radius: 0.05rem;
    width: 100%;
    height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #424242;
    font-weight: 700;
    font-style: normal;
    font-size: 0.3rem;
    line-height: 0.26rem;
    text-align: center;
    font-family: "AvenirNextLTPro";
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer-status {
    color: #424242;
    font-weight: 700;
    font-style: normal;
    font-size: 0.3rem;
    line-height: 0.26rem;
    text-align: center;
    font-family: "AvenirNextLTPro";
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box-overlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 0;
    overflow: hidden;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box {
    margin-top: 0.4rem;
    height: 1.56rem;
    display: flex;
    align-items: center;
    font-size: 0.4rem;
    font-weight: bold;
    color: #0a974b;
    padding-left: 0.38rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>i {
    cursor: pointer;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span {
    display: flex;
    align-items: center;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box {
    width: 1.28rem;
    height: 0.86rem;
    margin: 0.2rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PT,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PT2,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-SG,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-TTG,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-GP,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-BINL2,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-BINS2,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-SA,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-MGP,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-AG,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-AG2 {
    width: 0.75rem;
    height: 0.75rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PP,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-MEGA,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-NS,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-HB,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PP-slot,
  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-NS-slot {
    width: 0.95rem;
    height: 0.95rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-UL {
    width: 1.03rem;
    height: 0.7rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-SCR2 {
    width: 1rem;
    height: 0.45rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-PG {
    width: 0.9rem;
    height: 0.66rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-JDB {
    width: 0.65rem;
    height: 0.5rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box>span .icon-box .icon-HC {
    width: 3rem;
    height: 3rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .back-box .icon-back {
    cursor: pointer;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 2.1rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li {
    width: 5.76rem;
    position: relative;
    height: 1.9rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li:nth-child(1) {
    order: 1;
    margin-right: 0.11rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li:nth-child(2) {
    order: 4;
    flex: none;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item {
    width: 100%;
    border: none;
    font-size: 0.4rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div {
    padding-left: 0.5rem;
    padding-right: 0.64rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div:nth-child(1) {
    background: #e3e3e3;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item>div:nth-child(2) {
    background: #f2f2f2;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .icon-arrow-down2 {
    width: 0.25rem;
    height: 0.18rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread {
    top: 1.9rem;
    width: 100%;
    height: 5.7rem;
    overflow-x: hidden;
    padding: 0.2rem 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box li .sort-item .sort-select .spread li {
    height: 1rem;
    padding-left: 0.5rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content {
    width: 11.7rem;
    margin-top: 0.6rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li {
    width: 2.64rem;
    height: 0.72rem;
    font-size: 0.24rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-name {
    padding-left: 0.24rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .brand-list ul li .logo-box {
    width: 0.72rem;
    height: 0.72rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box {
    width: 100%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .loading-container {
    width: 100%;
    height: 100vh;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #000;
    transition: opacity .5s;
    z-index: 10;
    transform: scale(1);
    opacity: .5;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box {
    position: fixed;
    z-index: 11;
    width: 10.65rem;
    height: 8.65rem;
    background: #fff;
    transition: transform .5s;
    transform: translate(-50%, -50%) scale(1.1);
    left: 50%;
    top: 50%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .game-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 1.8rem;
    padding: 0 .65rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .game-item-info>p {
    font-size: .52rem;
    font-weight: 700;
    color: #222;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box {
    width: 9.4rem;
    height: 6.16rem;
    margin: 0 auto;
    position: relative;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box img {
    width: 100%;
    height: 100%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    position: absolute;
    top: 0;
    left: 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu .btn-normal,
  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu .btn-long,
  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu .btn-normal-green {
    margin-top: .96rem;
    width: 4.9rem;
    height: 1.16rem;
    font-size: .52rem !important;
    border-radius: .08rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu .btn-normal-green {
    margin-top: .44rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu div {
    margin-top: .88rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu div .icon-love-on {
    width: .98rem;
    height: .8rem;
    transform: scale(1) !important;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .img-box .item-menu div>span {
    margin-left: .48rem;
    font-size: .52rem !important;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game-box .hover-box .icon-close {
    width: .74rem;
    height: .74rem;
    position: absolute;
    right: .22rem;
    top: -0.22rem;
    transform: translateY(-100%);
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game {
    width: 100%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li {
    width: 3.77rem;
    height: 3.26rem;
    margin-bottom: 0.28rem;
    border: 0.04rem solid #dad8d8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>img {
    height: 2.47rem;
    max-width: unset;
    max-height: unset;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(5n)) {
    margin-right: 0.19rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:not(:nth-child(3n)) {
    margin-right: 0.19rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:nth-child(3n) {
    margin-right: 0;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:hover .hover-box {
    position: fixed;
    z-index: 11;
    transform: translate(-50%, -50%) scale(0);
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li:hover .hover-box-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    transform: scale(0);
    background: #000;
    opacity: 0;
    transition: opacity .5s;
    z-index: 10;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li.on .hover-box {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li.on .hover-box-mask {
    transform: scale(1);
    opacity: .5;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p {
    height: 0.79rem;
    line-height: 0.79rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p .icon-love,
  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p .icon-love-on {
    transform: scale(2);
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li>p .gamename {
    font-size: 0.23rem;
    left: 0.8rem;
    line-height: 0.35rem;
    width: 2.7rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box {
    position: fixed;
    z-index: 11;
    width: 10.65rem;
    height: 8.65rem;
    background: #fff;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .game-item-info {
    height: 1.8rem;
    padding: 0 0.65rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .game-item-info>p {
    font-size: 0.52rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .game-item-info>div {
    font-size: 0.46rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box {
    width: 9.4rem;
    height: 6.16rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu .btn-normal,
  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu .btn-normal-green,
  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu .btn-long {
    margin-top: 0.96rem;
    width: 4.9rem;
    height: 1.16rem;
    font-size: 0.52rem !important;
    border-radius: 0.08rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu .btn-normal-green {
    margin-top: 0.44rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu div {
    margin-top: 0.88rem;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu div>span {
    margin-left: 0.48rem;
    font-size: 0.52rem !important;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu div .icon-love,
  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box .item-menu div .icon-love-on {
    width: 0.98rem;
    height: 0.8rem;
    transform: scale(1) !important;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .img-box img {
    width: 100%;
    height: 100%;
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box .icon-close {
    width: 0.74rem;
    height: 0.74rem;
    position: absolute;
    right: 0.22rem;
    top: -0.22rem;
    transform: translateY(-100%);
  }

  .fastgames .content-section .slots-content-section>li.content-sort-search .sort-box-content .nav-game li .hover-box-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    transform: scale(0);
    background: #000;
    opacity: 0;
    transition: opacity .5s;
    z-index: 10;
  }

  .fastgames .content-section .slots-content-section>li.content-promotions {
    display: none;
  }

  .fastgames .content-section .slots-content-section .loading svg#logo {
    width: 7rem;
  }

  .fastgames .brand-list .badge::before {
    content: "";
  }

  .fastgames .brand-list .badge-hot .logo-box::before,
  .fastgames .brand-list .badge-hot .logo-box::after {
    content: "hot";
  }

  .fastgames .brand-list .badge-hot .logo-box::before {
    background-color: #ff3d00;
  }

  .fastgames .brand-list .badge-new .logo-box::before,
  .fastgames .brand-list .badge-new .logo-box::after {
    content: "new";
  }

  .fastgames .brand-list .badge-new .logo-box::before {
    background-color: #19733d;
  }

  .fastgames .brand-list .badge .logo-box::before,
  .fastgames .brand-list .badge .logo-box::after {
    position: absolute;
    bottom: 0.02rem;
    right: 0;
    padding: 0 0.08rem;
    font-size: 0.2rem;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }

  .fastgames .brand-list .badge .logo-box::before {
    color: rgba(0,0,0,0);
    transform: skew(-25deg);
  }
}

@media (max-width: 575px) {
  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box {
    max-width: 10.62rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-header .box-header-right svg {
    width: 0.58rem;
    height: 0.58rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-icon {
    margin: auto;
    width: 15%;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-title {
    font-size: 0.5rem;
    padding: 0.7rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details {
    margin: 0;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container {
    margin: 0.5rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column1 .key {
    font-size: 0.3rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-date-container .maintenance-container .maintenance-container-column2 {
    font-size: 0.3rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer {
    font-size: 0.3rem;
  }

  .slots .content-section .slots-content-section>li.content-sort-search .mobile .brand-list ul .maintenancePopup-Box .box .box-body .maintenance-details .maintenance-timer-container .timer-container .timer-status {
    font-size: 0.3rem;
  }
}

.fourD-draw-container {
  width: 100%;
  margin: 0 0 .3rem auto;
}

@media (max-width: 768px) {
  .fourD-draw-container {
    width: 100%;
    padding: .34rem .3rem .35rem;
  }
}

.fourD-draw-container .fourD-draw-box {
  display: grid;
  grid-template-columns: 83% auto;
  background: #f5f5f5;
  margin: 0 0 .5rem 0;
}

@media (max-width: 768px) {
  .fourD-draw-container .fourD-draw-box {
    grid-template-columns: none;
    padding: 0 0 .34rem;
    overflow: hidden;
    height: .98rem;
  }
}

.fourD-draw-container .fourD-draw-box .draw-tab {
  border-radius: .1rem;
  display: grid;
  grid-template-columns: repeat(2, 0.14fr);
}

@media (max-width: 768px) {
  .fourD-draw-container .fourD-draw-box .draw-tab {
    width: 105%;
    grid-template-columns: repeat(2, 1fr);
    height: .98rem;
    margin: 0 0 .28rem 0;
    position: relative;
    right: .45rem;
  }
}

.fourD-draw-container .fourD-draw-box .draw-tab .tab-header {
  padding: .05rem 0;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #858585;
  font-size: .14rem;
  height: .4rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .fourD-draw-box .draw-tab .tab-header {
    font-size: .4rem;
    height: auto;
  }
}

.fourD-draw-container .fourD-draw-box .draw-tab .tab-header.active {
  background: linear-gradient(0deg, #c59b4d 31%, #ddc175 100%);
  color: #000;
  transform: skew(-25deg);
  transform-origin: 0 100%;
}

.fourD-draw-container .fourD-draw-box .draw-tab .tab-header.active .tab-inner {
  transform: skew(25deg);
  transform-origin: 0 100%;
}

.fourD-draw-container .fourD-draw-box .draw-date {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .1rem;
  white-space: nowrap;
}

.fourD-draw-container .fourD-draw-box .draw-date .react-datepicker {
  right: 2.3rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .fourD-draw-box .draw-date .react-datepicker .react-datepicker {
    right: 0;
  }
}

.fourD-draw-container .fourD-draw-box .draw-date .react-datepicker__triangle {
  margin-left: 2rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .fourD-draw-box .draw-date .react-datepicker__triangle .react-datepicker__triangle {
    margin-left: auto;
  }
}

.fourD-draw-container .fourD-draw-box .draw-date span {
  font-weight: 600;
}

@media (max-width: 768px) {
  .fourD-draw-container .fourD-draw-box .draw-date {
    display: grid;
    width: 100%;
    justify-content: normal;
  }
}

.fourD-draw-container .fourD-draw-box .draw-date .standard-form-field {
  margin: 0;
  justify-content: center;
}

.fourD-draw-container .fourD-draw-box .draw-date .standard-input {
  font-size: .14rem;
  display: grid;
  grid-template-columns: 85% auto;
  border-radius: .04rem;
  border: .01rem solid #dbe0e4;
  padding: 0 0 0 .2rem;
  color: #000;
  background: #fff;
  width: 1.2rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .fourD-draw-box .draw-date .standard-input {
    font-size: .24rem;
    grid-template-columns: 90% auto;
  }
}

.fourD-draw-container .fourD-draw-box .draw-date .standard-input svg {
  width: 0.17rem;
  height: 0.14rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .fourD-draw-box .draw-date .standard-input svg {
    width: .39rem;
    height: .33rem;
  }
}

.fourD-draw-container .payout-container {
  font-size: .16rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .payout-container {
    font-size: .4rem;
  }
}

.fourD-draw-container .payout-container .payout-title-container {
  gap: .42rem;
  color: #8697a2;
  overflow: auto;
  width: 100%;
  display: flex;
  margin: 0 .34rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .payout-container .payout-title-container {
    width: auto;
    scrollbar-width: none;
    padding: .3rem 0;
  }
}

.fourD-draw-container .payout-container .payout-title-container .payout-title {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  cursor: pointer;
}

.fourD-draw-container .payout-container .payout-title-container .payout-title.active {
  color: #d4b366;
}

.fourD-draw-container .payout-container .payout-table-container {
  border-radius: 0.08rem;
  background: #fff;
  margin: .34rem 0 0;
}

@media (max-width: 768px) {
  .fourD-draw-container .payout-container .payout-table-container {
    padding: 0;
    background: rgba(0,0,0,0);
  }
}

.fourD-draw-container .payout-container .payout-table-container .payout-table-result {
  margin: 0 0 .37rem;
  border-bottom: .01rem solid #dbe0e4;
}

.fourD-draw-container .payout-container .payout-table-container .payout-table-result:last-child {
  margin: 0;
}

@media (max-width: 768px) {
  .fourD-draw-container .payout-container .payout-table-container .payout-table-result {
    background: #fff;
  }
}

.fourD-draw-container .payout-container .payout-table-container .payout-table {
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
}

.fourD-draw-container .payout-container .payout-table-container .payout-table::-webkit-scrollbar {
  width: 0.05rem;
}

.fourD-draw-container .payout-container .payout-table-container .payout-table::-webkit-scrollbar:horizontal {
  height: 0.08rem;
}

.fourD-draw-container .payout-container .payout-table-container .payout-table::-webkit-scrollbar-track {
  background: #fff;
}

.fourD-draw-container .payout-container .payout-table-container .payout-table::-webkit-scrollbar-thumb {
  border-radius: 0.1rem;
  background: hsla(0,0%,80%,.5);
  box-shadow: inset 0 0 0.06rem rgba(0,0,0,.2);
}

.fourD-draw-container .payout-container .payout-table-container .payout-table table {
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

@media (max-width: 768px) {
  .fourD-draw-container .payout-container .payout-table-container .payout-table table {
    overflow: auto;
    display: block;
    table-layout: fixed;
    white-space: nowrap;
  }
}

.fourD-draw-container .payout-container .payout-table-container .payout-table table thead {
  height: .42rem;
  border-radius: 0.08rem 0.08rem 0 0;
  background: #d4b366;
  text-align: center;
  color: #fff;
}

@media (max-width: 768px) {
  .fourD-draw-container .payout-container .payout-table-container .payout-table table thead {
    height: .42rem;
  }
}

@media (max-width: 768px)and (max-width: 768px) {
  .fourD-draw-container .payout-container .payout-table-container .payout-table table thead .payout-table-title {
    text-transform: uppercase;
  }

  .fourD-draw-container .payout-container .payout-table-container .payout-table table thead .payout-table-subtitle {
    text-transform: uppercase;
    padding: .2rem;
  }
}

.fourD-draw-container .payout-container .payout-table-container .payout-table table tbody tr {
  height: .42rem;
}

.fourD-draw-container .payout-container .payout-table-container .payout-table table tbody tr td {
  text-align: center;
  padding: 0 .4rem;
  width: auto;
}

@media (max-width: 768px) {
  .fourD-draw-container .payout-container .payout-table-container .payout-table table tbody tr td {
    font-weight: 400;
    padding: 0 .4rem;
  }
}

.fourD-draw-container .payout-container .payout-table-container .payout-table table tbody tr th {
  padding: .2rem .3rem;
  width: 2.07rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
}

@media (max-width: 768px) {
  .fourD-draw-container .payout-container .payout-table-container .payout-table table tbody tr th {
    width: 5rem;
    display: block;
  }
}

.fourD-draw-container .payout-container .payout-table-container .payout-table table tbody tr:nth-child(odd) {
  background: rgba(212,179,102,.1019607843);
}

.fourD-draw-container .payout-container .payout-table-container .payout-table table tbody tr:last-child th {
  border-radius: 0 0 0 .08rem;
}

.fourD-draw-container .payout-container .payout-table-container .payout-table table tbody tr:last-child td:last-child {
  border-radius: 0 0 .08rem 0;
}

.fourD-draw-container .draw-type-container {
  display: grid;
  grid-template-columns: repeat(5, 0.4fr);
  grid-column-gap: .04rem;
  grid-row-gap: .05rem;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-type-container {
    width: auto;
    grid-template-columns: auto;
  }

  .fourD-draw-container .draw-type-container .draw-result-name-select {
    height: 1.2rem;
    border-radius: 0.06rem;
    border: 0.01rem solid #dadada;
    padding: 0 .2rem;
    -webkit-appearance: none;
            appearance: none;
  }

  .fourD-draw-container .draw-type-container svg {
    position: absolute;
    width: .35rem;
    height: .28rem;
    right: 0;
    left: 11.4rem;
    margin: .4rem 0;
  }
}

.fourD-draw-container .draw-type-container .provider-icon {
  align-items: center;
  background: #fff;
  border: .02rem solid rgba(0,0,0,0);
  border-radius: .2rem;
  display: flex;
  justify-content: center;
  width: 1.33rem;
  height: .97rem;
}

.fourD-draw-container .draw-type-container .provider-icon.active {
  border: .01rem solid #d4b366;
}

.fourD-draw-container .draw-type-container .provider-icon.active img {
  filter: grayscale(0);
  opacity: 1;
}

.fourD-draw-container .draw-type-container .provider-icon img {
  width: .88rem;
  height: .75rem;
  filter: grayscale(100%);
  opacity: .5;
}

.fourD-draw-container .draw-result-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  justify-content: center;
  gap: .2rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    justify-content: normal;
    margin: .52rem 0;
  }
}

.fourD-draw-container .draw-result-container .draw-result-type {
  width: 100%;
  background: #f5f5f5;
  border-radius: .1rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container .draw-result-type {
    width: 100%;
    padding: .21rem;
  }
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D {
  padding: .15rem .2rem 0;
  display: grid;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container .draw-result-type .result-4D {
    padding: .25rem .2rem 0;
  }
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container {
  display: grid;
  text-align: center;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container span.rank-title {
  color: #fff;
  padding: .05rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container span.rank-title {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container span.number-container {
  margin: 0 0 .17rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container span.number-container {
    margin: 0 0 .7rem;
  }
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container span.number-container .number-value {
  padding: .05rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container span.number-container .number-13:last-child {
  grid-column: 4/span 1;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container span.number-container .number-13:nth-last-child(2) {
  grid-column: 3/span 1;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container span.number-container .number-13:nth-last-child(3) {
  grid-column: 2/span 1;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(1) .number-container,
.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(2) .number-container,
.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(3) .number-container {
  font-weight: 600;
  font-size: .18rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(1) .number-container,
  .fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(2) .number-container,
  .fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(3) .number-container {
    font-size: .5rem;
  }
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(2) .rank-title {
  margin: 0 .05rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(4),
.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(5) {
  grid-column: 1/span 3;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(4) .number-container,
.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(5) .number-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  border-style: solid;
  border-width: .01rem 0 0 .01rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(4) .number-value,
.fourD-draw-container .draw-result-container .draw-result-type .result-4D .draw-rank-container:nth-child(5) .number-value {
  border-style: solid;
  border-width: 0 .01rem .01rem 0;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6plus1D {
  margin: 0 .2rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6plus1D .result-title {
  text-align: center;
  padding: .05rem;
  color: #fff;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6plus1D .result-title .number-container {
  color: #fff;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6plus1D .draw-rank-container {
  display: grid;
  grid-template-columns: 15% 85%;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6plus1D .draw-rank-container .rank-title {
  text-align: center;
  border-style: solid;
  border-width: 0 .01rem .01rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6plus1D .draw-rank-container .single-column,
.fourD-draw-container .draw-result-container .draw-result-type .result-6plus1D .draw-rank-container .number-container {
  text-align: center;
  border-style: solid;
  border-width: 0 .01rem .01rem 0;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6D {
  margin: .17rem .2rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6D .result-title {
  text-align: center;
  padding: .05rem;
  color: #fff;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6D .result-title .number-container {
  text-align: center;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6D .draw-rank-container {
  display: grid;
  grid-template-columns: 15% 85%;
  border-style: solid;
  border-width: 0 .01rem .01rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6D .draw-rank-container .rank-title {
  text-align: center;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6D .draw-rank-container .single-column {
  text-align: center;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6D .draw-rank-container .single-column .number-value {
  border-style: solid;
  border-width: 0 0 0 .01rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6D .draw-rank-container .number-container {
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: center;
  border-style: solid;
  border-width: 0 0 0 .01rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-6D .First-rank .number-container {
  grid-template-columns: none;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-5D {
  margin: 0 .2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-style: solid;
  border-width: 0 0 0 .01rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-5D .result-title {
  grid-column: 1/span 2;
  text-align: center;
  color: #fff;
  padding: .05rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-5D .draw-rank-container {
  border-style: solid;
  border-width: 0 .01rem .01rem 0;
  place-items: center;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-5D .draw-rank-container:not(:first-child) {
  display: grid;
  grid-template-columns: 30% 70%;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-5D .draw-rank-container:not(:first-child) .rank-title {
  text-align: center;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-5D .draw-rank-container:not(:first-child) .number-container {
  text-align: center;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-5D .draw-rank-container:nth-child(2) {
  grid-row: 2;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-5D .draw-rank-container:nth-child(3) {
  grid-row: 3;
  grid-column: 1;
}

.fourD-draw-container .draw-result-container .draw-result-type .result-5D .draw-rank-container:nth-child(4) {
  grid-row: 4;
  grid-column: 1;
}

.fourD-draw-container .draw-result-container .draw-result-type .draw-result-title {
  width: 100%;
  height: 0.74rem;
  display: flex;
  color: #262626;
  border-radius: .1rem .1rem 0 0;
  justify-content: center;
  gap: .15rem;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container .draw-result-type .draw-result-title {
    height: 1.45rem;
    border-radius: .2rem .2rem 0 0;
    grid-template-columns: 1.4rem auto;
    grid-row-gap: .1rem;
  }
}

.fourD-draw-container .draw-result-container .draw-result-type .draw-result-title .provider-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container .draw-result-type .draw-result-title .provider-icon img {
    width: .85rem;
  }
}

.fourD-draw-container .draw-result-container .draw-result-type .draw-result-title svg {
  width: 0.45rem;
  height: 0.45rem;
}

.fourD-draw-container .draw-result-container .draw-result-type .draw-result-title span {
  grid-column: 2/span 1;
}

.fourD-draw-container .draw-result-container .draw-result-type .draw-result-title .provider-name {
  justify-content: flex-start;
  display: flex;
  align-items: end;
  font-weight: 600;
  align-self: center;
}

.fourD-draw-container .draw-result-container .draw-date .standard-form-field {
  height: 1.2rem;
  border-radius: .06rem;
  border: .01rem solid #dadada;
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container .draw-date .standard-form-field .react-datepicker-wrapper {
    width: 100%;
  }
}

.fourD-draw-container .draw-result-container .draw-date .standard-form-field .standard-input {
  background: rgba(0,0,0,0);
}

@media (max-width: 768px) {
  .fourD-draw-container .draw-result-container .draw-date .standard-form-field .standard-input {
    justify-content: space-between;
  }

  .fourD-draw-container .draw-result-container .draw-date .standard-form-field .standard-input svg {
    width: .35rem;
    height: .28rem;
  }
}

.fourD-draw-container .draw-result-container .draw-result-Magnum .result-4D span.rank-title {
  background: #262626;
}

.fourD-draw-container .draw-result-container .draw-result-Magnum .result-4D,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6plus1D,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6D,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-5D {
  border-color: #262626;
}

.fourD-draw-container .draw-result-container .draw-result-Magnum .result-4D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6plus1D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-5D span.rank-title {
  border-color: #262626;
}

.fourD-draw-container .draw-result-container .draw-result-Magnum .result-4D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6plus1D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-5D .result-title {
  background: #262626;
}

.fourD-draw-container .draw-result-container .draw-result-Magnum .result-4D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6plus1D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-5D .draw-rank-container {
  border-color: #262626;
}

.fourD-draw-container .draw-result-container .draw-result-Magnum .result-4D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6plus1D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-5D .draw-rank-container .single-column .number-value {
  border-color: #262626;
}

.fourD-draw-container .draw-result-container .draw-result-Magnum .result-4D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6plus1D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-5D .number-container {
  border-color: #262626;
}

.fourD-draw-container .draw-result-container .draw-result-Magnum .result-4D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6plus1D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-6D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Magnum .result-5D .number-container .number-value {
  border-color: #262626;
}

.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-4D span.rank-title {
  background: #0f1468;
}

.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-4D,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6plus1D,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6D,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-5D {
  border-color: #0f1468;
}

.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-4D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6plus1D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-5D span.rank-title {
  border-color: #0f1468;
}

.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-4D .result-title,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6plus1D .result-title,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6D .result-title,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-5D .result-title {
  background: #0f1468;
}

.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-4D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6plus1D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-5D .draw-rank-container {
  border-color: #0f1468;
}

.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-4D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6plus1D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-5D .draw-rank-container .single-column .number-value {
  border-color: #0f1468;
}

.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-4D .number-container,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6plus1D .number-container,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6D .number-container,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-5D .number-container {
  border-color: #0f1468;
}

.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-4D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6plus1D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-6D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-DaMaCai .result-5D .number-container .number-value {
  border-color: #0f1468;
}

.fourD-draw-container .draw-result-container .draw-result-Sabah .result-4D span.rank-title {
  background: #ad0900;
}

.fourD-draw-container .draw-result-container .draw-result-Sabah .result-4D,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6plus1D,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6D,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-5D {
  border-color: #ad0900;
}

.fourD-draw-container .draw-result-container .draw-result-Sabah .result-4D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6plus1D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-5D span.rank-title {
  border-color: #ad0900;
}

.fourD-draw-container .draw-result-container .draw-result-Sabah .result-4D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6plus1D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-5D .result-title {
  background: #ad0900;
}

.fourD-draw-container .draw-result-container .draw-result-Sabah .result-4D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6plus1D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-5D .draw-rank-container {
  border-color: #ad0900;
}

.fourD-draw-container .draw-result-container .draw-result-Sabah .result-4D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6plus1D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-5D .draw-rank-container .single-column .number-value {
  border-color: #ad0900;
}

.fourD-draw-container .draw-result-container .draw-result-Sabah .result-4D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6plus1D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-5D .number-container {
  border-color: #ad0900;
}

.fourD-draw-container .draw-result-container .draw-result-Sabah .result-4D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6plus1D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-6D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sabah .result-5D .number-container .number-value {
  border-color: #ad0900;
}

.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-4D span.rank-title {
  background: #083e04;
}

.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-4D,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6plus1D,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6D,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-5D {
  border-color: #083e04;
}

.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-4D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6plus1D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-5D span.rank-title {
  border-color: #083e04;
}

.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-4D .result-title,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6plus1D .result-title,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6D .result-title,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-5D .result-title {
  background: #083e04;
}

.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-4D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6plus1D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-5D .draw-rank-container {
  border-color: #083e04;
}

.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-4D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6plus1D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-5D .draw-rank-container .single-column .number-value {
  border-color: #083e04;
}

.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-4D .number-container,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6plus1D .number-container,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6D .number-container,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-5D .number-container {
  border-color: #083e04;
}

.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-4D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6plus1D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-6D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-CashSweep .result-5D .number-container .number-value {
  border-color: #083e04;
}

.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-4D span.rank-title {
  background: #2f9505;
}

.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-4D,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6plus1D,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6D,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-5D {
  border-color: #2f9505;
}

.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-4D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6plus1D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-5D span.rank-title {
  border-color: #2f9505;
}

.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-4D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6plus1D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-5D .result-title {
  background: #2f9505;
}

.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-4D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6plus1D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-5D .draw-rank-container {
  border-color: #2f9505;
}

.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-4D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6plus1D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-5D .draw-rank-container .single-column .number-value {
  border-color: #2f9505;
}

.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-4D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6plus1D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-5D .number-container {
  border-color: #2f9505;
}

.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-4D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6plus1D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-6D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Sandakan .result-5D .number-container .number-value {
  border-color: #2f9505;
}

.fourD-draw-container .draw-result-container .draw-result-Singapore .result-4D span.rank-title {
  background: #12689b;
}

.fourD-draw-container .draw-result-container .draw-result-Singapore .result-4D,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6plus1D,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6D,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-5D {
  border-color: #12689b;
}

.fourD-draw-container .draw-result-container .draw-result-Singapore .result-4D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6plus1D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-5D span.rank-title {
  border-color: #12689b;
}

.fourD-draw-container .draw-result-container .draw-result-Singapore .result-4D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6plus1D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6D .result-title,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-5D .result-title {
  background: #12689b;
}

.fourD-draw-container .draw-result-container .draw-result-Singapore .result-4D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6plus1D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-5D .draw-rank-container {
  border-color: #12689b;
}

.fourD-draw-container .draw-result-container .draw-result-Singapore .result-4D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6plus1D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-5D .draw-rank-container .single-column .number-value {
  border-color: #12689b;
}

.fourD-draw-container .draw-result-container .draw-result-Singapore .result-4D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6plus1D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6D .number-container,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-5D .number-container {
  border-color: #12689b;
}

.fourD-draw-container .draw-result-container .draw-result-Singapore .result-4D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6plus1D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-6D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-Singapore .result-5D .number-container .number-value {
  border-color: #12689b;
}

.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-4D span.rank-title {
  background: #cf4900;
}

.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-4D,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6plus1D,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6D,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-5D {
  border-color: #cf4900;
}

.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-4D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6plus1D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-5D span.rank-title {
  border-color: #cf4900;
}

.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-4D .result-title,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6plus1D .result-title,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6D .result-title,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-5D .result-title {
  background: #cf4900;
}

.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-4D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6plus1D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-5D .draw-rank-container {
  border-color: #cf4900;
}

.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-4D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6plus1D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-5D .draw-rank-container .single-column .number-value {
  border-color: #cf4900;
}

.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-4D .number-container,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6plus1D .number-container,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6D .number-container,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-5D .number-container {
  border-color: #cf4900;
}

.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-4D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6plus1D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-6D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-GrandDragon .result-5D .number-container .number-value {
  border-color: #cf4900;
}

.fourD-draw-container .draw-result-container .draw-result-ToTo .result-4D span.rank-title {
  background: #ed363f;
}

.fourD-draw-container .draw-result-container .draw-result-ToTo .result-4D,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6plus1D,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6D,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-5D {
  border-color: #ed363f;
}

.fourD-draw-container .draw-result-container .draw-result-ToTo .result-4D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6plus1D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-5D span.rank-title {
  border-color: #ed363f;
}

.fourD-draw-container .draw-result-container .draw-result-ToTo .result-4D .result-title,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6plus1D .result-title,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6D .result-title,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-5D .result-title {
  background: #ed363f;
}

.fourD-draw-container .draw-result-container .draw-result-ToTo .result-4D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6plus1D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-5D .draw-rank-container {
  border-color: #ed363f;
}

.fourD-draw-container .draw-result-container .draw-result-ToTo .result-4D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6plus1D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-5D .draw-rank-container .single-column .number-value {
  border-color: #ed363f;
}

.fourD-draw-container .draw-result-container .draw-result-ToTo .result-4D .number-container,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6plus1D .number-container,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6D .number-container,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-5D .number-container {
  border-color: #ed363f;
}

.fourD-draw-container .draw-result-container .draw-result-ToTo .result-4D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6plus1D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-6D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-ToTo .result-5D .number-container .number-value {
  border-color: #ed363f;
}

.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-4D span.rank-title {
  background: #f4861f;
}

.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-4D,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6plus1D,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6D,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-5D {
  border-color: #f4861f;
}

.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-4D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6plus1D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6D span.rank-title,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-5D span.rank-title {
  border-color: #f4861f;
}

.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-4D .result-title,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6plus1D .result-title,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6D .result-title,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-5D .result-title {
  background: #f4861f;
}

.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-4D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6plus1D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6D .draw-rank-container,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-5D .draw-rank-container {
  border-color: #f4861f;
}

.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-4D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6plus1D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6D .draw-rank-container .single-column .number-value,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-5D .draw-rank-container .single-column .number-value {
  border-color: #f4861f;
}

.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-4D .number-container,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6plus1D .number-container,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6D .number-container,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-5D .number-container {
  border-color: #f4861f;
}

.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-4D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6plus1D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-6D .number-container .number-value,
.fourD-draw-container .draw-result-container .draw-result-9Lotto .result-5D .number-container .number-value {
  border-color: #f4861f;
}

.disclaimer-container {
  font-size: .14rem;
}

@media (max-width: 768px) {
  .disclaimer-container {
    width: auto;
  }

  .disclaimer-container .disclaimer-text {
    width: 100%;
    border-radius: 0.04rem;
    background: #fff;
    display: grid;
    grid-template-columns: 95% 5%;
    padding: .25rem .38rem .25rem .28rem;
    font-weight: 600;
  }

  .disclaimer-container .disclaimer-text svg {
    width: .24rem;
    height: .24rem;
    display: flex;
    place-self: center;
    transition: transform .3s ease-in-out;
  }
}

.disclaimer-container p {
  color: #8697a2;
  line-height: 0.21rem;
  margin: .2rem 0;
}

@media (max-width: 768px) {
  .disclaimer-container p {
    margin: 0;
    color: #8697a2;
    background: #fff;
    padding: .2rem .3rem;
    line-height: .35447rem;
    border-radius: 0 0 .04rem .04rem;
  }
}

.disclaimer-container span {
  font-weight: 600;
  font-size: .15rem;
}

@media (max-width: 768px) {
  .disclaimer-container span {
    font-size: .295392rem;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.slider-recaptcha-popup-overlay {
  position: absolute;
  display: flex;
  align-items: center;
}

.slider-recaptcha-popup-overlay .slider-recaptcha-modal-container {
  width: 90%;
  background: #fff;
}

.slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slideHeader {
  text-align: center;
  background: rgba(0,0,0,0);
  color: #3b3b3b;
  padding: .15rem .32rem;
}

.slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slideHeader .standard-modal-title {
  font-size: .18rem;
  width: 100%;
}

.slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha {
  margin: 0 auto 0.3rem auto;
  width: 80%;
  height: 0.5rem;
  position: relative;
  background: gray;
  border: 0.01rem solid #cecece;
  padding: 0;
}

.slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0);
  margin: 0;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  overflow: hidden;
  border-radius: 0;
  -webkit-border-radius: 0;
}

.slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha input::-webkit-slider-thumb {
  -webkit-appearance: none;
  z-index: 3;
  position: relative;
  width: 0.8rem;
  height: 0.5rem;
  background: url("/public/html/images/icon/slider-right-icon.svg"),linear-gradient(to bottom, #fff, #fff);
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
  box-shadow: -3rem 0 0 3rem #39b54a;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: rgba(0,0,0,0);
}

.slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha input::-moz-range-thumb {
  -moz-appearance: none;
  z-index: 3;
  position: relative;
  width: 0.8rem;
  height: 0.5rem;
  background: url("/public/html/images/icon/slider-right-icon.svg"),linear-gradient(to bottom, #fff, #fff);
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
  box-shadow: -3rem 0 0 3rem #39b54a;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: rgba(0,0,0,0);
}

.slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha span {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  text-align: center;
  display: block;
  width: 100%;
  font-size: .16rem;
  color: #cecece;
  height: 100%;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .3rem;
}

.m-slider-recaptcha-popup-overlay {
  display: flex;
  align-items: center;
}

.m-slider-recaptcha-popup-overlay .slider-recaptcha-modal-container {
  width: 95%;
  min-width: 95%;
  max-width: 95%;
  background: #fff;
  margin: 60% auto;
}

.m-slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slideHeader {
  text-align: center;
  background: rgba(0,0,0,0);
  color: #3b3b3b;
  padding: .35rem;
}

.m-slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha {
  height: 1.5rem;
  margin: 0 auto 0.6rem auto;
  width: 90%;
  position: relative;
  background: gray;
  border: 0.01rem solid #cecece;
  padding: 0;
}

.m-slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0);
  margin: 0;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 0;
  -webkit-border-radius: 0;
}

.m-slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha input::-webkit-slider-thumb {
  -webkit-appearance: none;
  z-index: 3;
  position: relative;
  width: 2rem;
  height: 1.5rem;
  background: url("/public/html/images/icon/slider-right-icon.svg"),linear-gradient(to bottom, #fff, #fff);
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
  box-shadow: -8rem 0 0 8rem #39b54a;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: rgba(0,0,0,0);
}

.m-slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha input::-moz-range-thumb {
  -moz-appearance: none;
  z-index: 3;
  position: relative;
  width: 2rem;
  height: 1.5rem;
  background: url("/public/html/images/icon/slider-right-icon.svg"),linear-gradient(to bottom, #fff, #fff);
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
  box-shadow: -8rem 0 0 8rem #39b54a;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: rgba(0,0,0,0);
}

.m-slider-recaptcha-popup-overlay .slider-recaptcha-modal-container .slider-recaptcha span {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 0.45rem;
  display: block;
  width: 100%;
  color: #cecece;
  cursor: default;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .6rem;
  margin: 0 0 0 .4rem;
}

.m-user-verify-popup-overlay .m-user-verify-popup-container {
  width: 95%;
}

.m-user-verify-popup-overlay .m-user-verify-popup-container .user-verify-popup-body {
  padding: 1.2rem 0.55rem 0.5rem;
}

.m-user-verify-popup-overlay .m-user-verify-popup-container .user-verify-popup-body .popup-body-content .verify-dropdown-field {
  margin-bottom: 4rem;
}

.m-user-verify-popup-overlay .m-user-verify-popup-container .user-verify-popup-body .popup-body-content .verify-button-container {
  display: flex;
  justify-content: flex-end;
}

.m-user-verify-popup-overlay .m-user-verify-popup-container .user-verify-popup-body .popup-body-content .verify-button-container .verify-button {
  text-transform: uppercase;
}

.email-verify-popup-overlay .email-verify-popup-container {
  max-width: 8rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body {
  padding: 0.8rem 0.5rem 0.5rem;
  color: #777575;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body.status-body {
  padding: .25rem .35rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .standard-form-field {
  width: 80%;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .standard-button {
  text-transform: uppercase;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper {
  padding-bottom: 0.4rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .verify-email-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .failed-container {
  text-align: center;
  margin-top: 0.4rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .failed-container .icon-wrapper .icon-notice-important {
  width: 0.8rem;
  height: 0.8rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .failed-container .contact-button-container {
  display: flex;
  justify-content: center;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .failed-container .contact-button-container .contact-button {
  text-transform: unset;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper {
  padding-bottom: 0.4rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .otp-field {
  width: 100%;
  justify-content: space-between;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .otp-field .otp-input {
  width: 65%;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .otp-field button {
  width: 30%;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.resend-content-wrapper .send-and-resend-button-container {
  width: 80%;
  display: flex;
  margin-bottom: 0.4rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.resend-content-wrapper .send-and-resend-button-container .standard-button {
  min-width: 2.3rem;
  width: auto;
  margin-right: 0.2rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.resend-content-wrapper .send-and-resend-button-container .standard-button:last-child {
  margin-right: 0;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.resend-content-wrapper .otp-note {
  padding-bottom: 0.2rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.success-content-wrapper .success-title {
  color: #0a974b;
  font-weight: 700;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .success-content-container {
  padding-bottom: 0.4rem;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .success-content-container .success-title {
  color: #0a974b;
  font-weight: 700;
}

.email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .success-content-container strong {
  color: #0a974b;
}

@media (max-width: 1025px) {
  .email-verify-popup-overlay .email-verify-popup-container {
    max-width: 15rem;
  }

  .email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body {
    padding: 1rem 1.2rem 0.5rem;
  }

  .email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper {
    padding-bottom: 0.4rem;
  }

  .email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .verify-email-button-container {
    padding-top: 1.6rem;
  }

  .email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.resend-content-wrapper .send-and-resend-button-container .standard-button {
    min-width: 4rem;
    width: auto;
    margin-right: 0.4rem;
  }

  .email-verify-popup-overlay .email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.resend-content-wrapper .otp-note {
    padding-bottom: 0.8rem;
  }
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body {
  color: #777575;
  padding: 1rem 0.55rem;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .standard-button {
  text-transform: uppercase;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper {
  padding-bottom: 0.8rem;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .verify-email-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.6rem;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .failed-container {
  text-align: center;
  padding-top: 0.8rem;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .failed-container .icon-wrapper .icon-notice-important {
  width: 1rem;
  height: 1rem;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.email-content-wrapper .failed-container .contact-button-container {
  display: flex;
  justify-content: center;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper {
  padding-bottom: 1.2rem;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.otp-content-wrapper .otp-field .otp-input {
  margin-bottom: 0.8rem;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.resend-content-wrapper .otp-field {
  padding-bottom: 0.2rem;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.resend-content-wrapper .send-and-resend-button-container {
  display: flex;
  justify-content: space-between;
}

.m-email-verify-popup-overlay .m-email-verify-popup-container .email-verify-popup-body .popup-body-content .content-wrapper.resend-content-wrapper .otp-note {
  padding-bottom: 0.8rem;
}

.dob-verify-popup-overlay .dob-verify-popup-container {
  max-width: 8rem;
}

.dob-verify-popup-overlay .dob-verify-popup-container .standard-button {
  text-transform: uppercase;
}

.dob-verify-popup-overlay .dob-verify-popup-container .dob-verify-popup-body {
  padding: 0.8rem 0.5rem 1.2rem;
  color: #777575;
}

.dob-verify-popup-overlay .dob-verify-popup-container .dob-verify-popup-body .popup-body-content .dob-field {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.dob-verify-popup-overlay .dob-verify-popup-container .dob-verify-popup-body .popup-body-content .dob-field label {
  width: 30%;
}

.dob-verify-popup-overlay .dob-verify-popup-container .dob-verify-popup-body .popup-body-content .dob-field .select-date-seperate {
  width: 68%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 31% 31% 31%;
}

@media (max-width: 1025px) {
  .dob-verify-popup-overlay .dob-verify-popup-container {
    max-width: 15rem;
  }

  .dob-verify-popup-overlay .dob-verify-popup-container .dob-verify-popup-body {
    padding: 1rem 1.2rem 2rem;
  }
}

.m-dob-verify-popup-overlay .m-dob-verify-popup-container .standard-button {
  text-transform: uppercase;
}

.m-dob-verify-popup-overlay .m-dob-verify-popup-container .dob-verify-popup-body {
  color: #777575;
  padding: 1rem 0.55rem 2.5rem;
}

.m-dob-verify-popup-overlay .m-dob-verify-popup-container .dob-verify-popup-body .popup-body-content .dob-field label {
  width: 100%;
  margin: 0 0 0.6rem;
}

.m-dob-verify-popup-overlay .m-dob-verify-popup-container .dob-verify-popup-body .popup-body-content .dob-field .select-date-seperate {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 30% 30% 30%;
}

.m-dob-verify-popup-overlay .m-dob-verify-popup-container .dob-verify-popup-footer .standard-button-container {
  justify-content: center;
}

.address-popup .popup .popup-body .name-popup .popup-field.otp-field {
  flex-direction: row;
}

.address-popup .popup .popup-body .name-popup .popup-field.otp-field button {
  width: 1.3rem;
  margin-left: 0.2rem;
  font-size: 0.14rem;
  background-color: #005329;
  color: #fff;
  cursor: pointer;
  border: 0;
}

.address-popup .popup .popup-body .name-popup .popup-field.otp-field button[disabled],
.address-popup .popup .popup-body .name-popup .popup-field.otp-field button:disabled {
  background-color: rgba(0,147,73,.4549019608);
  cursor: not-allowed;
}

.address-popup .popup .popup-body .name-popup .popup-field .react-dropdown-select {
  width: 100%;
}

.address-popup .popup .popup-body .name-popup .popup-field .react-dropdown-select input {
  width: 100%;
}

.address-popup .popup .popup-body .name-popup .popup-field.otp-field button {
  font-size: 0.18rem;
}

.floating-freespin-icon-wrapper {
  position: fixed;
  top: 20%;
  left: 0;
  z-index: 200;
}

.floating-freespin-icon-wrapper button {
  background-color: rgba(0,0,0,0);
  outline: none;
  border: none;
  cursor: pointer;
}

.floating-freespin-icon-wrapper .floating-freespin-icon-close {
  position: absolute;
  top: -0.12rem;
  right: -0.03rem;
  height: .28rem;
  width: .28rem;
  background-image: url(/public/html/default_whitelabel/shared-image/angpow-rain/angpow-floating-close-btn.png);
  background-size: cover;
  z-index: 3;
}

.freespin-popup-modal-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  background: unset !important;
  margin: 0 !important;
}

.freespin-popup-modal-container .freespin-close-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  width: .24rem;
  fill: #fff;
  cursor: pointer;
}

.freespin-popup-modal-container .freespin-popup-modal-body {
  position: relative;
  width: 5.8rem;
  line-height: .2rem;
}

.freespin-popup-modal-container .freespin-popup-modal-body img {
  width: 100%;
}

.freespin-popup-modal-container .freespin-title,
.freespin-popup-modal-container .freespin-claim,
.freespin-popup-modal-container .freespin-noticed {
  margin: 0 0 .15rem;
  font-size: .16rem;
}

.freespin-popup-modal-container .freespin-message {
  position: absolute;
  color: #fff;
  text-align: center;
  z-index: 9;
  width: 3rem;
  padding: 0 0 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.freespin-popup-modal-container .freespin-claim-cta {
  color: #0072e2;
  background: rgba(0,0,0,0);
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-size: .16rem;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .floating-freespin-icon-wrapper {
    top: 20%;
    left: 0;
  }

  .floating-freespin-icon-wrapper .floating-freespin-icon-close {
    width: .6rem;
    height: .6rem;
  }

  .floating-freespin-icon-wrapper .floating-freespin-icon-image {
    width: 7rem;
  }

  .floating-freespin-icon-available-claim {
    top: 23%;
    right: 14%;
    font-size: .26rem;
    width: 36%;
    font-weight: 500;
  }

  .floating-freespin-icon-available-claim .highlighted-text {
    font-size: .26rem;
    font-weight: 500;
  }

  .floating-freespin-icon-close {
    height: .4rem;
    width: .4rem;
  }

  .freespin-popup-modal-container .freespin-popup-modal-body {
    width: 12rem;
  }

  .freespin-popup-modal-container .freespin-close-btn {
    width: .7rem;
    right: .4rem;
  }

  .freespin-popup-modal-container .freespin-message {
    font-size: .42rem;
    line-height: .48rem;
    width: 6rem;
    padding: 0 0 3.5rem;
  }
}